import { createReducer } from 'redux-starter-kit';
import { notificationsActions } from '../actions';
import getStdDbModelReduxMapping from './std-db-model-reducers';

export const notificationsInitialState = {};

const notificationsReducer = createReducer(
  notificationsInitialState,
  getStdDbModelReduxMapping(notificationsActions),
);

export default notificationsReducer;
