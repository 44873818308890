import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { withToastManager } from 'react-toast-notifications';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Tooltip } from '@material-ui/core';
import moment from 'moment';
import { connect } from 'react-redux';
import MixedView from './MixedView';
import MobileView from './MobileView';
import { CardLoader } from './Loader';
import { nsOptions } from '../i18n';
import {
  Table, TableHead, TableBody, TableRow, TableCell,
} from './CustomTable';
import FormattedValue from '../utils/FormattedValue';
import {
  ACKNOWLEDGED, ONGOING, PENDING, SENT, STOPPED,
} from '../constants';
import LabelStatus from './LabelStatus';
import { notificationItemsActions, userNotificationSettingActions } from '../redux/actions';
import ErrorUtil from '../utils/ErrorUtil';
import Toast from '../utils/Toast';

const mapDispatchToProps = (dispatch, ownProps) => ({
  handleResend: async (notificationItemId, updatedData) => (
    dispatch(notificationItemsActions.patch(notificationItemId, updatedData))
  ),
  fetchNotificationSettings: async (notificationId, userId) => (
    dispatch(userNotificationSettingActions.list({
      notification: notificationId,
      user: userId,
      admin: ownProps.admin,
    }))
  ),
});

@withToastManager
@connect(null, mapDispatchToProps)
@withTranslation('', nsOptions)
class NotificationsRecipientsTable extends React.Component {
  static propTypes = {
    notificationItems: PropTypes.arrayOf(PropTypes.shape()).isRequired,
    t: PropTypes.func.isRequired,
    notificationToDisplay: PropTypes.shape().isRequired,
    handleResend: PropTypes.func.isRequired,
    user: PropTypes.shape().isRequired,
    fetchNotificationSettings: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      loading: true,
    };
  }

  componentDidMount() {
    this.fetchData();
  }

  groupNotifItemsByInclusion = (notificationItems) => {
    const { notificationToDisplay } = this.props;
    const groupedNotifItems = notificationItems.reduce((acc, item) => {
      if (item.notification.id !== notificationToDisplay.id) {
        return acc;
      }
      const inclusionId = item.inclusion.id;
      if (!acc[inclusionId]) {
        acc[inclusionId] = [];
      }
      acc[inclusionId].push(item);
      return acc;
    }, {});
    return groupedNotifItems;
  };

  fetchNotificationSettingsByNotifItems = async (groupedNotifItems) => {
    const { fetchNotificationSettings } = this.props;
    const fetchPromises = Object.values(groupedNotifItems)
      .map((element) => fetchNotificationSettings(
        element[0].notification.id,
        element[0].inclusion.creator,
      ));
    const results = await Promise.all(fetchPromises);

    const cleanNotifItems = { ...groupedNotifItems };
    Object.keys(cleanNotifItems).forEach((key, index) => {
      const settingsUpdate = results[index].results.length > 0 ? results[index].results[0] : null;
      let newSettingsUpdate = settingsUpdate;
      if (settingsUpdate && settingsUpdate.notification_setting_inclusions) {
        // Filter notification_setting_inclusions by inclusion.id
        const filteredInclusions = settingsUpdate.notification_setting_inclusions.filter(
          (inclusion) => inclusion.inclusion.id === cleanNotifItems[key][0].inclusion.id,
        );
        // Update the settingsUpdate with the filtered inclusions.
        newSettingsUpdate = {
          ...settingsUpdate,
          notification_setting_inclusions: filteredInclusions,
        };
      }
      cleanNotifItems[key] = cleanNotifItems[key]
        .map((item, itemIndex) => (itemIndex === 0 ? {
          ...item, settings: newSettingsUpdate,
        } : item));
    });

    return cleanNotifItems;
  };

  fetchData = async () => {
    this.setState({ loading: true });
    const { notificationItems } = this.props;
    let cleanNotifItems;
    try {
      const groupedNotifItems = this.groupNotifItemsByInclusion(notificationItems);
      cleanNotifItems = await this.fetchNotificationSettingsByNotifItems(groupedNotifItems);
    } catch (error) {
      console.error(error);
      ErrorUtil.handleCatched(this.props, error, false);
    } finally {
      this.setState({ loading: false, cleanNotifItems });
    }
  };

  resend = async (notificationItem) => {
    const { handleResend } = this.props;
    try {
      handleResend(notificationItem.id, {
        resend: true,
      });
      Toast.success(this.props, 'project:notification.table.sent');
    } catch (error) {
      ErrorUtil.handleCatched(this.props, error);
    }
  }

  render() {
    const {
      t,
      user,
    } = this.props;
    const { loading } = this.state;

    // Display sent dates on multiple lines
    const multipleLineTooltipContent = (items) => {
      const sentItems = items.filter((item) => item.status === SENT);
      const plannedItems = items.filter((item) => item.status === PENDING);
      const sentItemsDOM = (sentItems.length === 0 ? (
        <span className="ml-2">{t('project:notification.table.nothing-sent')}</span>
      ) : (
        <ul>
          {sentItems.map((item) => (
            <li key={item.id}>{moment(item.timestamp).locale(user.language || 'en').format('L')}</li>
          ))}
        </ul>
      ));
      const plannedItemsDOM = (plannedItems.length === 0 ? (
        <span className="ml-2">{t('project:notification.table.nothing-planned')}</span>
      ) : (
        <ul>
          {plannedItems.map((item) => (
            <li key={item.id}>{moment(item.timestamp).locale(user.language || 'en').format('L')}</li>
          ))}
        </ul>
      ));

      return (
        <div>
          <p>{t('project:notification.table.sending-dates')}</p>
          {sentItemsDOM}
          {plannedItems.length > 0 && (
            <>
              <br />
              <p className="mt-3">{`${t('project:notification.table.next-notification')} :`}</p>
              {plannedItemsDOM}
            </>
          )}
        </div>
      );
    };

    if (loading || !this.state.cleanNotifItems) {
      return <CardLoader />;
    }

    const getNotifSettingInclusions = (notifItem) => {
      if (notifItem.settings) {
        return notifItem.settings.notification_setting_inclusions[0];
      }
      return null;
    };
    const getStatus = (item) => (getNotifSettingInclusions(item).unsubscribe_status
      ? getNotifSettingInclusions(item).unsubscribe_status
      : getNotifSettingInclusions(item).status || ONGOING);
    const isUnsubscribed = (notifItem) => getNotifSettingInclusions(notifItem)
      .unsubscribe_status === ACKNOWLEDGED
      || getNotifSettingInclusions(notifItem).unsubscribe_status === STOPPED;
    const isSameInclusion = (notifItem) => getNotifSettingInclusions(notifItem)
      .inclusion.id === notifItem.inclusion.id;

    const notificationItemsDOM = Object.values(this.state.cleanNotifItems).map((notifItem) => (
      <MixedView key={notifItem[0].id}>
        <TableRow
          style={{
            height: '67px',
          }}
        >
          <TableCell
            role="presentation"
          >
            <FormattedValue value={notifItem[0].inclusion.per_project_id} />
          </TableCell>
          <TableCell
            role="presentation"
          >
            <Tooltip
              title={notifItem[0].inclusion.creator_email}
              placement="top"
            >
              <span>{notifItem[0].inclusion.creator_name}</span>
            </Tooltip>
          </TableCell>
          <TableCell
            role="presentation"
          >
            <Tooltip
              title={multipleLineTooltipContent(notifItem)}
              placement="top"
            >
              <span>
                <FontAwesomeIcon
                  icon={['fas', 'history']}
                  size="sm"
                />
              </span>
            </Tooltip>
          </TableCell>
          <TableCell
            role="presentation"
          >
            <>
              {(isUnsubscribed(notifItem[0])
                 && isSameInclusion(notifItem[0])) ? (
                // with email of the person who unsubscribed
                   <Tooltip
                     title={notifItem[0]
                       .settings
                       .notification_setting_inclusions[0]
                       .user_who_updated_status_email}
                     placement="top"
                   >
                     <span>
                       <LabelStatus
                         center
                         status={getStatus(notifItem[0])}
                       />
                     </span>
                   </Tooltip>
                ) : (
                  // without email
                  <LabelStatus
                    center
                    status={getStatus(notifItem[0])}
                  />
                )}
            </>
          </TableCell>
          <TableCell
            role="presentation"
          >
            <Button
              className=""
              variant="contained"
              onClick={() => this.resend(notifItem[0])}
              onKeyPress={() => {}}
              onTouchEnd={() => {}}
              size="small"
              color="primary"
            >
              {t('project:notification.table.resend')}
            </Button>
          </TableCell>
        </TableRow>
      </MixedView>
    ));

    return (
      <div className="contains-loader">
        { loading && <CardLoader /> }
        <MixedView>
          <Table extraClassName="mt-3 mb-5 no-pointer">
            <TableHead>
              <TableRow>
                <TableCell tag="th">
                  {t('project:notification.table.inclusion-id')}
                </TableCell>
                <TableCell tag="th">
                  {t('project:notification.table.recipient')}
                </TableCell>
                <TableCell tag="th">
                  {t('project:notification.table.notifications')}
                </TableCell>
                <TableCell tag="th">
                  {t('project:notification.table.progression')}
                </TableCell>
                <TableCell tag="th">
                  {t('project:notification.table.action')}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {notificationItemsDOM.length > 0 ? notificationItemsDOM : (
                <TableRow key="no-one" className="no-one">
                  <TableCell
                    colSpan="8"
                  >
                    {t('project:no.recipient')}
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </MixedView>
        <MobileView>
          <div className="mt-3 mb-0">
            {notificationItemsDOM.length > 0 ? notificationItemsDOM : (
              <div>
                <div
                  className="align-middle text-center no-notification"
                >
                  {t('project:no.recipient')}
                </div>
              </div>
            )}
          </div>
        </MobileView>
      </div>
    );
  }
}


export default NotificationsRecipientsTable;
