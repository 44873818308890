import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { isMobile } from 'react-device-detect';
import {
  Modal, ModalBody, ModalFooter, ModalHeader,
} from 'reactstrap';
import { connect } from 'react-redux';
import { dragAndDropActions } from '../redux/actions';
import '../assets/css/newmodal.css';
import { childrenPropTypes } from '../utils/generic-prop-types';
import BrowserView from './MixedView';
import NewTooltip from './NewTooltip';
import MobileView from './MobileView';


const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch) => ({
  modalOpened: () => dispatch(dragAndDropActions.modalOpened()),
  modalClosed: () => dispatch(dragAndDropActions.modalClosed()),
});


@connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })
class NewModal extends Component {
  static propTypes = {
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    xlHeader: childrenPropTypes(),
    xlHeaderPlacement: PropTypes.string,
    noHeader: PropTypes.bool,
    disableHeaderTooltip: PropTypes.bool,
    action: childrenPropTypes(),
    trigger: childrenPropTypes(),
    triggerFuncName: PropTypes.string,
    triggerCondition: PropTypes.func,
    undoTriggerChange: PropTypes.func,
    alwaysTriggerOverridenFunc: PropTypes.bool,
    children: childrenPropTypes().isRequired,
    footer: childrenPropTypes(),
    type: PropTypes.oneOf([
      1,
      2,
    ]),
    size: PropTypes.oneOf([
      'sm',
      'md',
      'lg',
      'xl',
    ]),
    onLoad: PropTypes.func,
    onClosed: PropTypes.func,
    extraClass: PropTypes.string,
    extraBackdropClass: PropTypes.string,
    absoluteItems: childrenPropTypes(),
    modalOpened: PropTypes.func.isRequired,
    modalClosed: PropTypes.func.isRequired,
    modalZIndex: PropTypes.number,
    modalBodyClassName: PropTypes.string,
  };

  static defaultProps = {
    action: null,
    trigger: null,
    triggerFuncName: 'onClick',
    triggerCondition: () => true,
    undoTriggerChange: () => {},
    alwaysTriggerOverridenFunc: false,
    footer: null,
    title: null,
    xlHeader: null,
    xlHeaderPlacement: 'bottom',
    noHeader: false,
    disableHeaderTooltip: false,
    size: 'md',
    type: 1,
    onLoad: null,
    onClosed: () => {},
    extraClass: '',
    extraBackdropClass: '',
    absoluteItems: null,
    modalZIndex: undefined,
    modalBodyClassName: '',
  };

  constructor(props) {
    super(props);
    this.state = { opened: false };
    this.reduxModalOpened = false;
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.opened !== this.state.opened && (isMobile || this.props.size === 'xl')) {
      if (this.state.opened) {
        document.body.classList.add('no-scroll');
        // document.getElementById('root').style.display = 'none';
      } else {
        document.body.classList.remove('no-scroll');
        // document.getElementById('root').style.display = 'block';
      }
    }
  }

  componentWillUnmount() {
    this.notifyReduxModalClosed();
  }

  onClosed = (e) => {
    this.props.onClosed(e);
    this.notifyReduxModalClosed();
  };

  getHeader() {
    const {
      xlHeader, noHeader, xlHeaderPlacement, disableHeaderTooltip,
    } = this.props;
    if (noHeader) return null;
    return xlHeader ? (
      <div>
        <BrowserView>
          <NewTooltip
            content={xlHeader}
            placement={xlHeaderPlacement}
            disabled={disableHeaderTooltip}
          >
            <ModalHeader toggle={this.hide}>
              {xlHeader}
            </ModalHeader>
          </NewTooltip>
        </BrowserView>
        <MobileView>
          <ModalHeader toggle={this.hide}>
            <NewTooltip
              content={xlHeader}
              placement="top"
              disabled={disableHeaderTooltip}
            >
              <span>
                {xlHeader}
              </span>
            </NewTooltip>
          </ModalHeader>
        </MobileView>
      </div>
    ) : (
      <div className="close-button-box">
        <button
          className="close-button btn btn-link text-primary"
          onClick={this.hide}
        >
          &times;
        </button>
      </div>
    );
  }

  notifyReduxModalOpened = () => {
    if (!this.reduxModalOpened) {
      this.props.modalOpened();
      this.reduxModalOpened = true;
    }
  };

  notifyReduxModalClosed = () => {
    if (this.reduxModalOpened) {
      this.props.modalClosed();
      this.reduxModalOpened = false;
    }
  };

  toggle = () => {
    const opened = !this.state.opened;
    this.setState({
      opened,
    }, this.props.onLoad && this.state.opened ? this.props.onLoad : () => {});
    if (opened) {
      this.notifyReduxModalOpened();
    } else {
      this.notifyReduxModalClosed();
    }
  };

  hide = () => {
    this.setState({
      opened: false,
    });
    this.notifyReduxModalClosed();
  };

  show = () => {
    this.setState({
      opened: true,
    }, this.props.onLoad ? this.props.onLoad : () => {});
    this.notifyReduxModalOpened();
  };

  render() {
    const {
      trigger, triggerFuncName, triggerCondition, undoTriggerChange,
      alwaysTriggerOverridenFunc, size, type, extraClass, action, title,
      footer, absoluteItems, extraBackdropClass, children, modalZIndex, modalBodyClassName,
    } = this.props;

    const cloneProps = { key: 1 };
    if (trigger) {
      cloneProps[triggerFuncName] = (e) => {
        const triggerModal = triggerCondition(e);
        if (alwaysTriggerOverridenFunc || !triggerModal) {
          e.persist();
          trigger.props[triggerFuncName](e);
        }
        if (triggerModal) {
          this.show();
          undoTriggerChange();
        }
      };
    }

    return [
      trigger ? React.cloneElement(trigger, cloneProps) : null,
      (
        <Modal
          key={2}
          isOpen={this.state.opened}
          toggle={this.hide}
          backdrop="static"
          onClosed={this.onClosed}
          className={`modal-dialog-${size} modal-dialog-${type}
          ${extraClass}`}
          backdropClassName={extraBackdropClass}
          zIndex={modalZIndex}
        >
          {this.getHeader()}
          <ModalBody
            className={modalBodyClassName}
          >
            {
              size !== 'xl' && (title || action) ? (
                <div className={`row mb-4 ${isMobile ? 'mt-3' : 'mt-5'}`}>
                  <div className="col">
                    <div className="modal-title">{title}</div>
                  </div>
                  {
                    action ? (
                      <div className="col-auto">
                        {action}
                      </div>
                    ) : null
                  }
                </div>
              ) : null
            }
            {children}
            {
              this.state.allowScroll ? (
                <FontAwesomeIcon
                  className="modal-scroll-icon"
                  icon={['fal', `chevron-circle-${this.state.scrollType}`]}
                  onClick={this.scrollContent}
                />
              ) : null
            }
          </ModalBody>
          {
            footer ? (
              <ModalFooter>
                {footer}
              </ModalFooter>
            ) : null
          }
          {absoluteItems || null}
        </Modal>
      ),
    ];
  }
}


export default NewModal;
