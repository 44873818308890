import { createReducer } from 'redux-starter-kit';
import { miscActions } from '../actions';

const {
  setPendingUrl, clearPendingUrl,
} = miscActions;

export const miscInitialState = {
  pendingUrl: '',
};

const setPendingUrlClbk = (state, action) => ({
  ...state,
  pendingUrl: action.payload,
});

const clearPendingUrlClbk = (state) => ({
  ...state,
  pendingUrl: miscInitialState.pendingUrl,
});


const miscReducer = createReducer(miscInitialState, {
  [setPendingUrl]: setPendingUrlClbk,
  [clearPendingUrl]: clearPendingUrlClbk,
});

export default miscReducer;
