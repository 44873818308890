import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { Elements, StripeProvider } from 'react-stripe-elements';
import { withToastManager } from 'react-toast-notifications';
import { connect } from 'react-redux';
import { Label, Button } from 'reactstrap';
import { Link } from 'react-router-dom';
import {
  userLicensesActions, teamLicensesActions, countriesActions, stripePlansActions, teamsActions,
} from '../redux/actions';
import { nsOptions } from '../i18n';
import api from '../api';
import { CardLoader, MiniLoader } from './Loader';
import LabeledChoice from './LabeledChoice';
import LabeledInput from './LabeledInput';
import LabeledSelect from './LabeledSelect';
import LicenseCheckout from './LicenseCheckout';
import RowContent from './RowContent';
import Help from './Help';
import ErrorUtil from '../utils/ErrorUtil';
import {
  getLicenseColor, getLicenseNextUpgrade, getLicenseUpgradesList,
} from '../utils/license';
import TimeoutHandler from '../utils/TimeoutHandler';
import Toast from '../utils/Toast';
import { deObfuscateEmail } from '../utils/email';
/* import { LicenseDescription as LicDescription } from './LicenseDescription'; */
import FormattedValue from '../utils/FormattedValue';
import { getOffersUrl } from '../utils/urls';
import { USER_TYPE_ASSISTANT, USER_TYPE_INTERN, USER_TYPE_PRACTITIONER } from '../constants';

const STRIPE_API_PUBLIC_KEY_TEST = 'pk_test_Y2F1rtpwruJCmtKo8BqDlS7i';

const getStripePublicKey = () => (
  process.env.STRIPE_API_PUBLIC_KEY || STRIPE_API_PUBLIC_KEY_TEST
);

// eslint-disable-next-line react/prefer-stateless-function
class LicenseCard extends Component {
  static propTypes = {
    id: PropTypes.number.isRequired,
    licenseKey: PropTypes.string.isRequired,
    className: PropTypes.string,
    color: PropTypes.string.isRequired,
    selected: PropTypes.bool.isRequired,
    selectedPlan: PropTypes.shape(),
    selectLicense: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
  };

  static defaultProps = {
    className: undefined,
    selectedPlan: null,
  };

  render() {
    const {
      id, licenseKey, color, selected, selectedPlan, selectLicense, t, className,
    } = this.props;
    const baseClassName = 'col-12 col-sm-12 col-md-5 col-lg-4 col-xl-3 my-3 my-md-0';

    return (
      <div className={className ? `${baseClassName} ${className}` : baseClassName} key={id}>
        <div
          role="button"
          tabIndex="-1"
          onClick={() => selectLicense(id)}
          onKeyPress={() => selectLicense(id)}
          className={`card license-choice-card ${
            selected ? `selected-license-choice-card border-${color}-3 bg-${color}-1-light-alpha`
              : 'border-gray bg-light'}`}
        >
          <div className="card-body">
            <h5
              className={`card-title text-center font-weight-semibold ${selected
                ? `text-${color}-3`
                : 'text-gray-dark'} mb-0`}
            >
              {t(`user:licenses.${licenseKey}`)}
            </h5>
            {
              selectedPlan && (
                <div className="license-choice-price text-center mt-2">
                  <span className="license-choice-price-amount">
                    <FormattedValue
                      value={
                        selectedPlan.amount / (selectedPlan.interval === 'year' ? 12 : 1)
                      }
                      unit="€"
                    />
                  </span>
                  <small>
                    {` / ${t('user:license.plans.month.unit')}`}
                  </small>
                  <div>
                    <small>
                      {`(${t('user:license.vat-excluded')})`}
                    </small>
                  </div>
                </div>
              )
            }
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.auth.authUser,
  userLicenses: state.userLicenses,
  teamLicenses: state.teamLicenses,
  countries: state.countries,
  stripePlans: state.stripePlans,
  teams: state.teams,
});

const mapDispatchToProps = (dispatch) => ({
  fetchUserLicenses: async () => dispatch(userLicensesActions.list()),
  fetchTeamLicenses: async () => dispatch(teamLicensesActions.list()),
  fetchCountries: async () => dispatch(countriesActions.list({ active: true }, {
    pagination: 'no',
  })),
  fetchStripePlans: async () => dispatch(stripePlansActions.list()),
  fetchTeams: async (userId) => dispatch(teamsActions.list({
    owner: userId,
    members__user: userId,
  })),
});


@withToastManager
@connect(mapStateToProps, mapDispatchToProps)
@withTranslation('', nsOptions)
class LicenseManager extends Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
    user: PropTypes.shape().isRequired,
    userLicenses: PropTypes.shape().isRequired,
    teamLicenses: PropTypes.shape().isRequired,
    countries: PropTypes.shape().isRequired,
    stripePlans: PropTypes.shape().isRequired,
    teams: PropTypes.shape().isRequired,
    fetchUserLicenses: PropTypes.func.isRequired,
    fetchTeamLicenses: PropTypes.func.isRequired,
    fetchCountries: PropTypes.func.isRequired,
    fetchStripePlans: PropTypes.func.isRequired,
    fetchTeams: PropTypes.func.isRequired,
    nonPractitionerTeams: PropTypes.arrayOf(PropTypes.shape()),
  };

  static defaultProps = {
    nonPractitionerTeams: undefined,
  };

  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      ready: false,
      showOtherPlans: false,
      selectedLicense: undefined,
      subscriptionInProgress: false,
      checkoutInProgress: false,
      pricingPlanId: undefined,
      billAddr1: '',
      billAddr2: '',
      billCity: '',
      billState: '',
      billZip: '',
      billCountry: props.user.country,
      billAddr1Valid: true, // Optional
      billAddr2Valid: true, // Optional
      billCityValid: true, // Optional
      billStateValid: true, // Optional
      billZipValid: false,
      billCountryValid: Boolean(props.user.country),
      licenseCheckoutValid: false,
      vatNumber: '',
      vatCheckLoading: false,
      vatInfo: null,
      vatRate: null,
      paymentError: false,
    };
    this.timeoutHandler = new TimeoutHandler();
    this.stripe = window.Stripe(getStripePublicKey());
    this.licenseCheckoutRef = null;
  }

  componentDidMount() {
    this.fetchData();
  }

  getVATRate = async (countryCode, vatNumber) => {
    if (countryCode) {
      this.timeoutHandler.doAfterTimeout(async () => {
        this.setState({ vatRateLoading: true });
        try {
          const vatRate = await api.read('stripe/get-vat-rate', undefined, {
            country_code: countryCode,
            vat_code: vatNumber ? `${countryCode}${vatNumber}` : undefined,
          });
          this.setState({ vatRate });
        } catch (error) {
          ErrorUtil.handleCatched(this.props, error, false);
          this.setState({ paymentError: true });
        } finally {
          this.setState({ vatRateLoading: false });
        }
      });
    }
  };

  checkVATCode = async (countryCode, vatNumber) => {
    if (countryCode && vatNumber) {
      this.timeoutHandler.doAfterTimeout(async () => {
        this.setState({ vatCheckLoading: true, vatInfo: null });
        try {
          const vatInfo = await api.read('stripe/check-vat-code', `${countryCode}${vatNumber}`);
          this.setState({ vatInfo });
        } catch (error) {
          ErrorUtil.handleCatched(this.props, error, false);
          this.setState({ paymentError: true });
        } finally {
          this.setState({ vatCheckLoading: false });
        }
      });
    }
  };

  updateVAT = async (countryCode, vatNumber) => Promise.all([
    this.checkVATCode(countryCode, vatNumber),
    this.getVATRate(countryCode, vatNumber),
  ]);

  fetchData = async () => {
    this.setState({ loading: true });

    try {
      const {
        fetchUserLicenses, fetchTeamLicenses, fetchCountries, fetchStripePlans, fetchTeams, user,
      } = this.props;
      await Promise.all([
        fetchUserLicenses(),
        fetchTeamLicenses(),
        fetchCountries(),
        fetchStripePlans(),
        fetchTeams(user.id),
      ]);
      this.setState({ ready: true });
    } catch (error) {
      ErrorUtil.handleCatched(this.props, error, false);
    } finally {
      this.setState({ loading: false });
    }
  };

  checkout = async (selectedPlan, countryCode) => {
    this.setState({ checkoutInProgress: true });

    const { countries } = this.props;
    const {
      billAddr1, billAddr2, billCity, billState, billZip, billCountry, vatNumber,
    } = this.state;

    try {
      const result = await this.licenseCheckoutRef.getPaymentMethod({
        name: this.licenseCheckoutRef.cardHolderNameValue,
        address: {
          line1: billAddr1,
          line2: billAddr2,
          city: billCity,
          state: billState,
          postal_code: billZip,
          country: countries[Number(billCountry)].code,
        },
      });

      if (!result.paymentMethod) {
        if (result.error.code === 'validation_error') {
          Toast.error(this.props, 'error:error.invalid-card-fields');
        } else {
          Toast.error(this.props, 'error:error.internal-error');
          this.setState({ paymentError: true });
        }
        return;
      }

      const res = await api.create('stripe/subscribe', {
        payment_method: result.paymentMethod.id,
        plan: selectedPlan.id,
        country_code: countryCode,
        vat_code: vatNumber ? `${countryCode}${vatNumber}` : null,
      });

      // TODO: manage here 3D secure case
      console.log(res);
      // FIXME
      // const checkoutResult = {}; // await feathers.service('stripe/checkout').create({
      //   tokenId: result.token.id,
      //   planId: this.state.choosenPlan.id,
      //   owner,
      // });
      // this.setState({ checkoutInProgress: false });
      // if (!checkoutResult.subscribed && checkoutResult.redirect) {
      //   window.location.replace(checkoutResult.redirect_url);
      // } else {
      //   this.props.history.push(`${this.props.match.url}/license/result/${this.state.choosenPlan.
      // id}`);
      // }
    } catch (error) {
      ErrorUtil.handleCatched(this.props, error);
      if (!error.message.includes('payment__')) {
        this.setState({ paymentError: true });
      }
    } finally {
      this.setState({ checkoutInProgress: false });
    }
  };

  selectLicense = (licenseId) => {
    const { userLicenses } = this.props;
    const { selectedLicense } = this.state;

    if (!selectedLicense || licenseId !== this.state.selectedLicense.id) {
      this.setState({ selectedLicense: userLicenses[licenseId] });
    }
  };

  render() {
    const {
      loading, ready, pricingPlanId, subscriptionInProgress, billAddr1, billAddr2, billState,
      billZip, billCity, billCountry, billAddr1Valid, billAddr2Valid, billStateValid,
      billZipValid, billCityValid, billCountryValid, licenseCheckoutValid, vatNumber,
      vatCheckLoading, vatRateLoading, vatInfo, vatRate, paymentError, showOtherPlans,
      checkoutInProgress,
    } = this.state;
    let { selectedLicense } = this.state;
    const {
      t, user, userLicenses: uLicenses, teamLicenses: tLicenses, countries, stripePlans: stpPlans,
      teams: tms, nonPractitionerTeams,
    } = this.props;
    const userLicenses = Object.values(uLicenses);
    const teamLicenses = Object.values(tLicenses);
    const stripePlans = Object.values(stpPlans);
    const teams = Object.values(tms).filter((tm) => tm.owner.id === user.id);
    const isPractitioner = user.type === USER_TYPE_PRACTITIONER;
    let currentLicense;
    let upgradeLicenses;
    let plans = [];
    let selectedPlan;
    let countryCode;
    const upgradeLicenseEmail = deObfuscateEmail('support', 'doqboard.com');

    const findMatchingPlan = (currentPlanId) => {
      if (!currentPlanId) return undefined;

      const currentPlan = stripePlans.find((plan) => plan.id === currentPlanId);
      const matchingPlan = stripePlans.find((plan) => (
        plan.id !== currentPlan.id && plan.interval === currentPlan.interval
      ));

      return matchingPlan ? matchingPlan.id : currentPlan.id;
    };

    if (ready) {
      currentLicense = userLicenses.find((license) => user.groups.includes(license.id));
      upgradeLicenses = getLicenseUpgradesList(currentLicense, userLicenses);

      if (!selectedLicense) {
        selectedLicense = getLicenseNextUpgrade(currentLicense, userLicenses);
      }

      if (selectedLicense) {
        plans = stripePlans.filter((plan) => plan.product === selectedLicense.product);
      }

      if (pricingPlanId) {
        const selectedPlanId = plans.find((plan) => plan.id === pricingPlanId)
          ? pricingPlanId : findMatchingPlan(pricingPlanId);
        selectedPlan = plans.find((plan) => plan.id === selectedPlanId);
      } else if (plans.length) [selectedPlan] = plans;

      if (billCountry) {
        const currentCountry = countries[Number(billCountry)];
        if (currentCountry) countryCode = currentCountry.code;
      }
    }

    const subscriptionReady = selectedLicense && billAddr1Valid && billAddr2Valid && billStateValid
      && billZipValid && billCityValid && billCountryValid && licenseCheckoutValid
      && !vatCheckLoading && subscriptionInProgress;

    const getTeamLicenseKey = (team) => (
      teamLicenses.find((lic) => lic.id === team.licence).key
    );

    const getMaxInvestigatorsInfo = (model) => (
      <div className="text-gray mb-2">
        {t('user:license.max-investigators', {
          count: model.limitations.max_investigators_per_project,
        })}
      </div>
    );

    return (
      ![USER_TYPE_ASSISTANT, USER_TYPE_INTERN].includes(user.type) ? (
        <StripeProvider stripe={this.stripe}>
          <>
            {
              ready ? (
                <div className="new-settings license-settings mx-4">
                  <RowContent
                    label={t(`user:license.${isPractitioner ? 'your-plan' : 'plan'}`)}
                  >
                    <div>
                      <div>
                        {
                          isPractitioner && (
                            <>
                              {t('user:license.your-account-is')}
                              {' '}
                            </>
                          )
                        }
                        <span
                          className={`license-name font-weight-bold text-${getLicenseColor(currentLicense.key)}-2`}
                        >
                          {t(`user:licenses.${currentLicense.key}-license`)}
                        </span>
                      </div>
                      {getMaxInvestigatorsInfo(user)}
                    </div>
                    {
                      isPractitioner && (
                        <div>
                          <a
                            className="text-newblue-1 small font-italic"
                            href={getOffersUrl(user.language)}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {t('user:license.view-features')}
                          </a>
                        </div>
                      )
                    }
                    {/* <div> */}
                    {/*   { !showOtherPlans && upgradeLicenses  */}
                    {/* && Boolean(upgradeLicenses.length) && ( */}
                    {/*     <a */}
                    {/*       className="text-gray" */}
                    {/*       role="button" */}
                    {/*       onClick={() => this.setState({ showOtherPlans: true })} */}
                    {/*       onKeyPress={() => {}} */}
                    {/*       tabIndex="-1" */}
                    {/*     > */}
                    {/*       {t('user:license.plans.show-others')} */}
                    {/*     </a> */}
                    {/*   )} */}
                    {/* </div> */}
                  </RowContent>
                  {/* <RowContent
                     label={t('user:license.your-features')}
                     className="d-none"
                     >
                     <LicDescription currentLicense={currentLicense} />
                     </RowContent>
                   */}
                  <RowContent
                    show={isPractitioner}
                    label={t('user:license.team-plans')}
                  >
                    <div>
                      {
                        teams.length ? (
                          <>
                            {(teams.length === 1) && (
                              <>
                                <div>
                                  {t('user:license.your-team-account-is')}
                                  &nbsp;
                                  <span className={`font-weight-bold text-${getLicenseColor(getTeamLicenseKey(teams[0]))}-2`}>
                                    {t(`user:licenses.team-${getTeamLicenseKey(teams[0])}-license`)}
                                  </span>
                                </div>
                                {getMaxInvestigatorsInfo(teams[0])}
                              </>
                            )}
                            {(teams.length > 1) && (
                              <ul className="mb-1">
                                {teams.map((team) => (
                                  <li key={`team-${team.id}`}>
                                    <div>
                                      {t(
                                        'user:license.your-team-account-is',
                                        { name: `"${team.name}"` },
                                      )}
                                      &nbsp;
                                      <span className={`font-weight-bold text-${getLicenseColor(getTeamLicenseKey(team))}-2`}>
                                        {t(`user:licenses.team-${getTeamLicenseKey(team)}-license`)}
                                      </span>
                                    </div>
                                    {getMaxInvestigatorsInfo(team)}
                                  </li>
                                ))}
                              </ul>
                            )}
                          </>
                        ) : t('user:license.no-team-plan')
                      }
                    </div>
                    <div>
                      {teams.length > 0 && (
                        <a
                          className="text-newblue-1 small font-italic"
                          href={getOffersUrl(user.language)}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {t('user:license.view-features')}
                        </a>
                      )}
                      {user.verified && (
                        <>
                          {teams.length > 0 && (
                            <span className="text-newblue-1 small font-italic mx-1">-</span>
                          )}
                          <Link
                            to="/dashboard/settings/teams/my-team"
                            className="text-newblue-1 small font-italic d-inline"
                          >
                            {t('user:license.view-teams')}
                          </Link>
                        </>
                      )}
                    </div>
                  </RowContent>
                  <RowContent contentClassName="col-12">
                    <Label className="">
                      {t(`user:license.${isPractitioner ? 'request-upgrade-plural' : 'request-upgrade-singular'}`)}
                      <span className="font-weight-semibold">
                        {upgradeLicenseEmail}
                      </span>
                      .
                    </Label>
                  </RowContent>
                  <RowContent
                    show={showOtherPlans}
                    label={t('user:license.plans.available')}
                  >
                    <div className="row">
                      <div className="col-12">
                        <LabeledChoice
                          name="pricing-plans"
                          value={selectedPlan ? selectedPlan.id : null}
                          className="mb-0"
                          orientation="horizontal"
                          choices={plans.map((plan) => ({
                            text: t(`user:license.plans.${plan.interval}.name`),
                            value: plan.id,
                          }))}
                          onChange={(e) => {
                            this.setState({ pricingPlanId: e.target.value });
                          }}
                        />
                      </div>
                      <div className="col-12 mt-5 d-flex">
                        {
                          upgradeLicenses.map((license, index) => {
                            const selected = license.id === selectedLicense.id;
                            let matchingPlan;

                            if (selectedPlan) {
                              const matchingPlanId = selected
                                ? selectedPlan.id
                                : findMatchingPlan(selectedPlan.id);
                              matchingPlan = matchingPlanId
                                ? stripePlans.find((plan) => plan.id === matchingPlanId)
                                : null;
                            }

                            let className = 'px-5 px-md-4';
                            if (index === 0) className = 'pl-0 pr-5 pr-md-4';
                            else if (index === upgradeLicenses.length - 1) className = 'pr-0 pl-5 pl-md-4';

                            return (
                              <LicenseCard
                                id={license.id}
                                key={license.id}
                                licenseKey={license.key}
                                className={className}
                                color={getLicenseColor(license.key)}
                                selected={selected}
                                selectedPlan={matchingPlan}
                                selectLicense={this.selectLicense}
                                t={t}
                              />
                            );
                          })
                        }
                      </div>
                    </div>
                  </RowContent>
                  <RowContent
                    show={showOtherPlans && selectedLicense}
                    label={t('user:license.subscription')}
                  >
                    <Button
                      color="primary"
                      onClick={() => {
                        if (!subscriptionInProgress && billCountry && billCountryValid) {
                          this.updateVAT(billCountry, vatNumber);
                        }
                        this.setState({
                          subscriptionInProgress: !subscriptionInProgress,
                          paymentError: false,
                          checkoutInProgress: false,
                        });
                      }}
                    >
                      {t(subscriptionInProgress
                        ? 'user:license.subscribe-cancel'
                        : 'user:license.subscribe')}
                    </Button>
                  </RowContent>
                  <RowContent
                    show={showOtherPlans && subscriptionInProgress}
                    label={t('user:license.billing.info')}
                    contentClassName="col-12 col-sm-6 col-md-4 mt-2"
                  >
                    <LabeledInput
                      type="text"
                      name="address1"
                      label={t('user:license.billing.address-line-1')}
                      validation="address"
                      placeholder={t('error:placeholder.address-line-1')}
                      value={billAddr1}
                      onChange={(e) => this.setState({ billAddr1: e.target.value })}
                      onValidityChange={(valid) => this.setState({ billAddr1Valid: valid })}
                    />
                    <LabeledInput
                      type="text"
                      name="address2"
                      label={t('user:license.billing.address-line-2')}
                      validation="address"
                      placeholder={t('error:placeholder.address-line-2')}
                      value={billAddr2}
                      onChange={(e) => this.setState({ billAddr2: e.target.value })}
                      onValidityChange={(valid) => this.setState({ billAddr2Valid: valid })}
                    />
                    <LabeledInput
                      type="text"
                      name="city"
                      label={t('user:license.billing.city')}
                      validation="city"
                      placeholder={t('error:placeholder.city')}
                      value={billCity}
                      onChange={(e) => this.setState({ billCity: e.target.value })}
                      onValidityChange={(valid) => this.setState({ billCityValid: valid })}
                    />
                    <LabeledInput
                      type="text"
                      name="state"
                      label={t('user:license.billing.state')}
                      validation="state"
                      placeholder={t('error:placeholder.state')}
                      value={billState}
                      onChange={(e) => this.setState({ billState: e.target.value })}
                      onValidityChange={(valid) => this.setState({ billStateValid: valid })}
                    />
                    <LabeledInput
                      type="text"
                      name="zip"
                      label={t('user:license.billing.zip')}
                      validation="zip"
                      required
                      placeholder={t('error:placeholder.zip')}
                      value={billZip}
                      onChange={(e) => this.setState({ billZip: e.target.value })}
                      onValidityChange={(valid) => this.setState({ billZipValid: valid })}
                    />
                    <LabeledSelect
                      type="text"
                      name="country"
                      label={(
                        <div>
                          <span>{t('user:license.billing.country')}</span>
                          <Help iconClassName="ml-2">
                            {t('user:license.billing.country-help')}
                          </Help>
                        </div>
                      )}
                      required
                      placeholder={t('error:placeholder.country')}
                      value={billCountry}
                      disabled={billCountryValid}
                      onChange={(e) => {
                        this.setState({ billCountry: e.target.value });
                        this.updateVAT(e.target.value, vatNumber);
                      }}
                      onValidityChange={(valid) => this.setState({ billCountryValid: valid })}
                    >
                      <option value="" disabled>{`-- ${t('user:license.billing.country-select')} --`}</option>
                      {Object.values(countries).map((country) => (
                        <option value={country.id} key={country.id}>
                          {t(`country:${country.name}`)}
                        </option>
                      ))}
                    </LabeledSelect>
                  </RowContent>
                  <RowContent
                    show={showOtherPlans && subscriptionInProgress && billCountryValid}
                    label={t('user:license.vat-number')}
                    contentClassName="col-12 col-sm-6 col-md-4 mt-2"
                  >
                    <LabeledInput
                      type="text"
                      name="vat"
                      label={t('user:license.vat-number')}
                      validation="vat"
                      colInputClassName="col-12"
                      prependClassName="position-absolute"
                      inputClassName="labeled-input pl-4"
                      prepend={(
                        <span className="vat-prepend">
                          {countryCode}
                        </span>
                      )}
                      placeholder={t('error:placeholder.vat')}
                      value={vatNumber}
                      onChange={(e) => this.setState({ vatNumber: e.target.value })}
                      onValidChange={(e) => this.updateVAT(countryCode, e.target.value)}
                    />
                    {
                      (vatInfo || vatCheckLoading) && (
                        <div className="vat-info">
                          {
                            vatCheckLoading && (
                              <span className="text-newred-2">
                                <span className="mr-2">
                                  {t('user:license.verifying-your-vat-number')}
                                </span>
                                <MiniLoader />
                              </span>
                            )
                          }
                          {
                            !vatCheckLoading && (
                              vatInfo && vatInfo.valid ? (
                                <span className="text-newturquoise-1">
                                  {t('user:license.company-confirmed')}
                                  {' '}
                                  <strong>
                                    {vatInfo.name}
                                  </strong>
                                </span>
                              ) : (
                                <span className="text-newred-2">
                                  {t('user:license.invalid-vat-number')}
                                </span>
                              )
                            )
                          }
                        </div>
                      )
                    }
                  </RowContent>
                  <RowContent
                    show={showOtherPlans && subscriptionInProgress}
                    label={t('user:license.payment-method')}
                    contentClassName="col-12 col-sm-6 col-md-4 mt-2"
                  >
                    <Elements
                      fonts={[
                        { cssSrc: 'https://fonts.googleapis.com/css?family=Source+Sans+Pro' },
                      ]}
                    >
                      <LicenseCheckout
                        onValidityChange={(valid) => (
                          this.setState({ licenseCheckoutValid: valid })
                        )}
                        ref={(ref) => { this.licenseCheckoutRef = ref; }}
                      />
                    </Elements>
                  </RowContent>
                  <RowContent
                    show={showOtherPlans && subscriptionReady}
                    label={t('user:license.confirm-subscription')}
                  >
                    {
                      ((vatRateLoading && (!vatInfo || !vatInfo.valid)) || !selectedLicense
                        || !selectedPlan) ? (
                          <span>
                            Loading...
                          </span>
                        ) : (
                          <>
                            <div>
                              <p>
                                {t('user:license.you-are-about-to-subscribe')}
                              </p>
                              <p className={`text-bigger font-weight-semibold text-${getLicenseColor(selectedLicense.key)}-3`}>
                                {`${t(`user:licenses.${selectedLicense.key}-license`)} – ${t(`user:license.plans.${selectedPlan.interval}.name`)}`}
                              </p>
                              <p>
                                {`${t('user:license.pricing-vat-excluded')} `}
                                <strong className="text-bigger">
                                  <FormattedValue value={selectedPlan.amount} unit="€" />
                                </strong>
                                {` (${t(`user:license.plans.${selectedPlan.interval}.name`)})`}
                              </p>
                              {
                                vatRate ? (
                                  <p>
                                    {`${t('user:license.pricing-vat-included')} `}
                                    <strong className="text-bigger">
                                      <FormattedValue
                                        value={selectedPlan.amount * (1 + vatRate / 100)}
                                        unit="€"
                                      />
                                    </strong>
                                    {` (${t(`user:license.plans.${selectedPlan.interval}.name`)})`}
                                  </p>
                                ) : (
                                  <p>
                                    {t('user:license.vat-not-charged')}
                                  </p>
                                )
                              }
                            </div>
                            <div className="mt-4">
                              <button
                                onClick={() => this.checkout(selectedPlan, countryCode)}
                                className="btn btn-lg btn-newblue-1"
                                disabled={checkoutInProgress || paymentError}
                              >
                                {
                                  checkoutInProgress ? (
                                    <>
                                      <span className="mr-2">
                                        {t('user:license.checkout-in-progress')}
                                      </span>
                                      <MiniLoader />
                                    </>
                                  ) : (
                                    <span>
                                      {t('user:license.confirm-button')}
                                    </span>
                                  )
                                }
                              </button>
                            </div>
                          </>
                        )
                    }
                  </RowContent>
                  {
                    paymentError && (
                      <div className="text-red mt-3">
                        <b>{t('user:license.payment-error')}</b>
                      </div>
                    )
                  }
                </div>
              ) : (
                loading && <CardLoader />
              )
            }
          </>
        </StripeProvider>
      ) : (
        <>
          {ready && (
            <div className="pt-2 px-4">
              <div className="py-2">
                <div>
                  {t('user:license.your-account-is')}
                  &nbsp;
                  <span
                    className={`license-name font-weight-bold text-${getLicenseColor(currentLicense.key)}-2`}
                  >
                    {t(`user:licenses.${currentLicense.key}-license`)}
                  </span>
                </div>
                {getMaxInvestigatorsInfo(user)}
              </div>
              {
                [USER_TYPE_ASSISTANT, USER_TYPE_INTERN].includes(user.type)
                  && nonPractitionerTeams
                  && nonPractitionerTeams.length === 0
                  && (
                    <div className="font-weight-semibold mb-5">
                      {t('user:team.no-joined-team.non-practitioner')}
                    </div>
                  )
              }
              <div className="py-2">
                <Label className="">
                  {t('user:license.request-upgrade-singular')}
                  <span className="font-weight-semibold">
                    {upgradeLicenseEmail}
                  </span>
                  .
                </Label>
              </div>
            </div>
          )}
          {
            loading && <CardLoader />
          }
        </>
      )
    );
  }
}

export default LicenseManager;
