import React, {
  useState, useEffect, useRef, useMemo,
} from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Mutex } from 'async-mutex';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { usersActions, countriesActions } from '../redux/actions';
import history from '../history';
import { CardLoader } from './Loader';
import Pagination from './Pagination';
import Head from './DashboardHead';
import NewTooltip from './NewTooltip';
import Checkbox from './Checkbox';
import MixedView, { isMixedView } from './MixedView';
import MobileView from './MobileView';
import {
  Table, TableHead, TableBody, TableRow, TableCell,
} from './CustomTable';
import TruncatedBadgeList from './TruncatedBadgeList';
import useError from '../utils/HookErrorUtil';
import useToast from '../utils/HookToast';
import { ORGANIZATION_TYPES } from '../constants';

const MobileOrgaItem = (props) => {
  const { label, bold } = props;
  return (
    <div className="col-sm-6 text-center">
      <div className={`d-inline ${bold ? 'font-weight-semibold' : ''}`}>
        {label}
      </div>
    </div>
  );
};

MobileOrgaItem.propTypes = {
  label: PropTypes.string.isRequired,
  bold: PropTypes.bool,
};

MobileOrgaItem.defaultProps = {
  bold: false,
};

function UserCommunity(props) {
  const { admin } = props;
  const { handleCatched } = useError();
  const { success } = useToast();
  const mutex = useRef(null);
  const { t } = useTranslation();

  const users = useSelector((state) => state.users);
  const user = useSelector((state) => state.auth.authUser);
  const countries = useSelector((state) => state.countries);

  const organizations = useMemo(() => Object.values(users).filter(
    (usr) => ORGANIZATION_TYPES.includes(usr.type),
  ), [users]);

  const dispatch = useDispatch();

  const fetchUsers = async (data) => dispatch(usersActions.list(data, { pagination: 'short' }));
  const fetchCountries = async () => dispatch(countriesActions.list({}, { pagination: 'no' }));
  const patchUser = async (id, data) => dispatch(usersActions.patch(id, data));

  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(1);
  const [organizationsCount, setOrganizationsCount] = useState(0);
  const [displayFavorites, setDisplayFavorites] = useState(false);

  const fetchPaginatedData = async (newPage, initialization = false) => {
    try {
      setLoading(true);

      if (initialization) {
        await fetchCountries();
      }

      const options = {};
      if (displayFavorites) options.id__in = user.favorite_organizations;
      const res = await fetchUsers({
        page: newPage,
        type__in: ORGANIZATION_TYPES,
        public_profile: true,
        verified: true,
        ordering: 'institution',
        annotate_public_projects_count: true,
        ...options,
      });

      setPage(newPage);
      setOrganizationsCount(res.count);
      setPageSize(Math.max(res.results.length, pageSize));
    } catch (error) {
      handleCatched(props, error);
    } finally {
      setLoading(false);
    }
  };

  const fetchData = async () => fetchPaginatedData(1, true);

  const updateFavorites = async (id) => {
    if (!mutex.current.isLocked()) {
      const release = await mutex.current.acquire();
      const { favorite_organizations: favorites } = user;
      const newFavorites = [...favorites];
      if (newFavorites.includes(id)) {
        const index = newFavorites.findIndex((fav) => fav === id);
        if (index > -1) newFavorites.splice(index, 1);
      } else newFavorites.push(id);
      try {
        await patchUser(user.id, { favorite_organizations: newFavorites });
        success('error:valid.success');
      } catch (error) {
        handleCatched(props, error);
      } finally {
        release();
      }
    }
  };

  const mixedView = isMixedView();

  const organizationsDOM = organizations.sort((a, b) => {
    const aLabel = a.username || a.institution;
    const bLabel = b.username || b.institution;
    return aLabel.localeCompare(bLabel);
  }).map((organization) => {
    const isFavorite = user.favorite_organizations.includes(organization.id);
    const country = countries[organization.country];

    return mixedView ? (
      <tr
        key={`organization-${organization.id}`}
        onClick={() => history.push(admin ? `organization/${organization.id}/my-profile` : `profile/${organization.id}`)}
        className={isFavorite ? 'favorite-organization-item' : ''}
      >
        <td className="align-middle text-center">
          <NewTooltip
            content={organization.label}
            disabled={!organization.username}
          >
            <span className="font-weight-semibold">
              {organization.username ? organization.username : organization.label}
            </span>
          </NewTooltip>
        </td>
        <td className="align-middle text-center">
          {t(`user:types.${organization.type}`)}
        </td>
        <td className="align-middle text-center">
          {organization.public_projects_count}
        </td>
        <td
          className="align-middle text-center"
          style={{ maxWidth: '15rem' }}
        >
          <TruncatedBadgeList items={organization.topics.map((topic) => t(`topic:${topic}`))} />
        </td>
        <td className="align-middle text-center">
          {organization.specialty ? (
            <span>
              {t(`specialty:${organization.specialty}`)}
            </span>
          ) : ''}
        </td>
        <td className="align-middle text-center">
          {country ? t(`country:${countries[organization.country].name}`) : ''}
        </td>
        <td className="align-middle text-center">
          {displayFavorites ? (
            <span className={isFavorite ? 'text-newyellow-1' : 'text-gray-light'}>
              <FontAwesomeIcon
                icon={['fas', 'star']}
              />
            </span>
          ) : (
            <button
              className={`no-button-style ${isFavorite ? 'text-newyellow-1' : 'text-gray-light'}`}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                updateFavorites(organization.id);
              }}
            >
              <FontAwesomeIcon icon={['fas', 'star']} />
            </button>
          )}
        </td>
      </tr>
    ) : (
      <ol
        key={organization.id}
        className="list-group"
      >
        <li
          className={`list-group-item mobile-list-item organization-mobile-item ${isFavorite ? 'favorite-organization-item' : ''}`}
          role="menuitem"
          tabIndex={0}
          onClick={() => history.push(admin ? `organization/${organization.id}/projects` : `profile/${organization.id}`)}
          onKeyUp={() => {}}
        >
          <MobileOrgaItem
            label={organization.username || organization.label}
            bold
          />
          <MobileOrgaItem
            label={t(`user:types.${organization.type}`)}
          />
          <MobileOrgaItem
            label={organization.specialty ? t(`specialty:${organization.specialty}`) : ''}
          />
        </li>
      </ol>
    );
  });

  useEffect(() => {
    mutex.current = new Mutex();
    fetchData();
  }, []);

  useEffect(() => fetchPaginatedData(1), [displayFavorites]);

  return (
    <div>
      <Head
        {...props}
        title={(
          <span>
            {t('community:organization-plural')}
          </span>
        )}
      />
      <div className="card bg-white mt-3">
        <div className="card-body">
          <div className="py-3 community-card-subtitle">
            {t(`community:user-community.subtitle${mixedView ? '' : '-mobile'}`)}
          </div>
          <div>
            <Checkbox
              id="display-favorites-only"
              checked={displayFavorites}
              onChange={(e) => setDisplayFavorites(e.target.checked)}
              className="pt-2 font-italic"
            >
              {t('community:user-community.only-favorites')}
            </Checkbox>
          </div>
          <div
            className="contains-loader"
            style={{ overflowX: 'auto' }}
          >
            {loading && <CardLoader />}
            <MixedView>
              <Table extraClassName="mt-2 mb-0">
                <TableHead>
                  <TableRow>
                    <TableCell>
                      {t('community:organization')}
                    </TableCell>
                    <TableCell>
                      {t('community:type')}
                    </TableCell>
                    <TableCell>
                      {t('community:public-projects')}
                    </TableCell>
                    <TableCell>
                      {t('community:topics')}
                    </TableCell>
                    <TableCell>
                      {t('community:specialty')}
                    </TableCell>
                    <TableCell>
                      {t('community:country')}
                    </TableCell>
                    <TableCell>
                      {t('community:favorite')}
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {organizations.length > 0 && !loading ? organizationsDOM : (
                    <tr className="no-one">
                      <TableCell
                        colSpan="6"
                        className="align-middle text-center text-muted small font-italic"
                      >
                        {loading ? (
                          <span>
                            &nbsp;
                          </span>
                        ) : (
                          <span>
                            {t('community:no-organizations')}
                          </span>
                        )}
                      </TableCell>
                    </tr>
                  )}
                </TableBody>
              </Table>
            </MixedView>
            <MobileView>
              <div className="mt-3 mb-0">
                {organizations.length > 0 && !loading ? organizationsDOM : (
                  <div
                    className="align-middle text-center no-inclusion"
                  >
                    {t('community:no-organizations')}
                  </div>
                )}
              </div>
            </MobileView>
            {organizationsCount > 10 && (
              <nav className="mt-5 mb-0">
                <Pagination
                  page={page}
                  count={organizationsCount}
                  pageSize={pageSize}
                  action={fetchPaginatedData}
                />
              </nav>
            )}
          </div>
          <div className="text-center organization-list-footer">
            {t('community:user-community.contact-us')}
          </div>
        </div>
      </div>
    </div>
  );
}

UserCommunity.propTypes = {
  admin: PropTypes.bool,
};

UserCommunity.defaultProps = {
  admin: false,
};

export default UserCommunity;
