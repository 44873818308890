export const LIFE_STATE_UNDER_CONCTRUCTION = 'under_contruction';
export const LIFE_STATE_MOUNTED = 'mounted';
export const LIFE_STATE_UNMOUNTED = 'unmounted';

class ComponentLifeTracker {
  constructor(state = LIFE_STATE_UNDER_CONCTRUCTION) {
    this.lifeState = state;
  }

  changeState = (state) => {
    this.lifeState = state;
  }

  setMounted = () => this.changeState(LIFE_STATE_MOUNTED);

  setUnmounted = () => this.changeState(LIFE_STATE_UNMOUNTED);

  getState = () => this.lifeState;

  isMounted = () => this.lifeState === LIFE_STATE_MOUNTED;

  isUnmounted = () => this.lifeState === LIFE_STATE_UNMOUNTED;
}

export default ComponentLifeTracker;
