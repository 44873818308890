import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { withToastManager } from 'react-toast-notifications';
import { connect } from 'react-redux';
import { elementsActions } from '../redux/actions';
import { nsOptions } from '../i18n';
import ElementBase from './ElementBase';
import Editor from './Editor';
import ElementLinksInfo from './ElementLinksInfo';
import TextUtil from '../utils/TextUtil';
import Toast from '../utils/Toast';
import ErrorUtil from '../utils/ErrorUtil';
import TimeoutHandler from '../utils/TimeoutHandler';
import { TARGET_TYPE_ELEMENT } from '../constants';


const mapStateToProps = (state, ownProps) => ({
  element: state.elements[ownProps.elementId],
  projectElement: ownProps.projectElementId
    ? state.projectElements[ownProps.projectElementId] : null,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  patchElement: async (id, data) => dispatch(elementsActions.patch(id, data, {
    admin: ownProps.admin,
  })),
});


@withToastManager
@connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })
@withTranslation('', nsOptions)
class ElementInformation extends Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
    elementId: PropTypes.number.isRequired,
    element: PropTypes.shape().isRequired,
    projectElementId: PropTypes.number,
    projectElement: PropTypes.shape(),
    moduleInstanceId: PropTypes.number,
    inclusion: PropTypes.shape(),
    parent: PropTypes.shape({
      content: PropTypes.shape(),
    }),
    methods: PropTypes.shape({
      move: PropTypes.func,
      reload: PropTypes.func.isRequired,
      remove: PropTypes.func,
    }).isRequired,
    isEditMode: PropTypes.bool,
    isEntryMode: PropTypes.bool,
    isReadOnly: PropTypes.bool,
    admin: PropTypes.bool,
    isModule: PropTypes.bool,
    patchElement: PropTypes.func.isRequired,
  };

  static defaultProps = {
    projectElementId: null,
    projectElement: null,
    moduleInstanceId: null,
    inclusion: null,
    parent: null,
    isEditMode: false,
    isEntryMode: false,
    isReadOnly: false,
    admin: false,
    isModule: false,
  };

  constructor(props) {
    super(props);
    this.timeoutHandler = new TimeoutHandler();
  }

  checkLinks = () => {
    if (this.elBaseRef) this.elBaseRef.checkLinks();
  };

  updateElementText = (text) => {
    this.timeoutHandler.doAfterTimeout(async () => {
      try {
        await this.props.patchElement(this.props.elementId, { text }, {
          admin: this.props.admin,
        });
        Toast.success(this.props, 'error:valid.saved');
      } catch (error) {
        ErrorUtil.handleCatched(this.props, error);
      }
    });
  };

  render() {
    const {
      t, isReadOnly, projectElement, parent, isEntryMode,
    } = this.props;

    return (
      <ElementBase
        {...this.props}
        elementCategory="editable"
        subClassName="information-element-sub-card"
        noComment
        noLabel
        ref={(ref) => { this.elBaseRef = ref; }}
      >
        <div className="card-text information-text">
          <div readOnly={isReadOnly} className="element-info-icon">
            i
          </div>
          <div readOnly={isReadOnly} className="element-info-title">
            {t('project:information-info')}
            {projectElement && parent && !isEntryMode && (
              <span style={{ paddingLeft: '30px' }}>
                <ElementLinksInfo
                  {...this.props}
                  target={projectElement}
                  targetType={TARGET_TYPE_ELEMENT}
                />
              </span>
            )}
          </div>
          <div>
            {
              this.props.isEditMode ? (
                <Editor
                  data={this.props.element.text}
                  onChange={(data) => {
                    this.updateElementText(data);
                  }}
                />
              ) : (
                <span
                  readOnly={isReadOnly}
                  className="editor-result"
                  dangerouslySetInnerHTML={{
                    __html: TextUtil.parseLinks(this.props.element.text),
                  }}
                />
              )
            }
          </div>
        </div>
      </ElementBase>
    );
  }
}


export default ElementInformation;
