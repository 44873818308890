import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { Label, NavLink } from 'reactstrap';
import { Provider } from 'react-redux';
import memoize from 'memoize-one';
import { nsOptions } from '../i18n';
import store from '../redux/store';
import {
  CAN_INCLUDE, ADMIN_EDITABLE_PERMS, ADVANCED_EDITABLE_PERMS, BASIC_EDITABLE_PERMS,
  CAN_MANAGE_ADVANCED_PERMISSIONS, CAN_INVITE_EXTERNAL, CAN_VIEW_EXTERNAL_INCLUSIONS,
  CAN_INVITE_TEAM_MEMBER, CAN_EDIT_FORM_AND_DOCUMENTATIONS, CAN_VIEW_TEAM_RESULTS,
} from '../constants';
import LabeledChoice, { eventToMultipleChoicesValue } from './LabeledChoice';
import Help from './Help';
import { getFAQUrl } from '../utils/urls';
import LicenseChecker from './LicenseChecker';


@withTranslation('', nsOptions)
class ProjectUserPermissionSettings extends Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
    className: PropTypes.string,
    labelClassName: PropTypes.string,
    value: PropTypes.arrayOf(PropTypes.string),
    defaultValue: PropTypes.arrayOf(PropTypes.string),
    onChange: PropTypes.func,
    showTeamInvitationsPerm: PropTypes.bool,
    showOnlyTypePerms: PropTypes.bool,
    readOnly: PropTypes.bool,
    type: PropTypes.oneOf(['basic', 'advanced', 'full']),
    writablePermissions: PropTypes.arrayOf(PropTypes.string),
    i18n: PropTypes.shape().isRequired,
    project: PropTypes.shape().isRequired,
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.shape()]),
  };

  static defaultProps = {
    className: '',
    labelClassName: '',
    value: [],
    defaultValue: undefined,
    onChange: async () => true,
    showTeamInvitationsPerm: true,
    showOnlyTypePerms: true,
    readOnly: false,
    type: 'basic',
    writablePermissions: [],
    title: 'project:invitations.base-permissions',
  };

  static getPermissionList = (type) => {
    switch (type) {
      case 'full': return [...ADMIN_EDITABLE_PERMS, ...ADVANCED_EDITABLE_PERMS, ...BASIC_EDITABLE_PERMS];
      case 'advanced': return [...ADVANCED_EDITABLE_PERMS, ...BASIC_EDITABLE_PERMS];
      case 'basic': default: return BASIC_EDITABLE_PERMS;
    }
  };

  static filterBasePerms = (perms) => (perms ? perms.filter((perm) => (
    BASIC_EDITABLE_PERMS.includes(perm)
  )) : undefined);

  static filterAdvancedPerms = (perms) => (perms ? perms.filter((perm) => (
    !BASIC_EDITABLE_PERMS.includes(perm)
  )) : undefined);

  static filterBaseChoices = (choices) => (choices ? choices.filter((choice) => (
    BASIC_EDITABLE_PERMS.includes(choice.value)
  )) : undefined);

  static filterAdvancedChoices = (choices) => (choices ? choices.filter((choice) => (
    !BASIC_EDITABLE_PERMS.includes(choice.value)
  )) : undefined);

  constructor(props) {
    super(props);
    this.state = {
      baseInternalValue: ProjectUserPermissionSettings.filterBasePerms(props.defaultValue),
      advancedInternalValue: ProjectUserPermissionSettings.filterAdvancedPerms(props.defaultValue),
    };
    this.memoizedGetBaseChoices = memoize(this.getBaseChoices);
    this.memoizedGetAdvancedChoices = memoize(this.getAdvancedChoices);
    this.memoizedGetBaseDisabledChoices = memoize(this.getDisabledChoices);
    this.memoizedGetAdvancedDisabledChoices = memoize(this.getDisabledChoices);
  }

  onPermissionChange = async (e, internalValueName) => {
    e.persist();

    const internalValue = this.state[internalValueName];
    const prevInternalValue = internalValue;

    if (internalValue) {
      let tempInternalValue = [...internalValue];
      if (e.target.value === CAN_INVITE_EXTERNAL && !e.target.checked) {
        tempInternalValue = tempInternalValue.filter((item) => (
          item !== CAN_MANAGE_ADVANCED_PERMISSIONS
        ));
      }
      this.setState({ [internalValueName]: eventToMultipleChoicesValue(e, tempInternalValue) });
    }

    const success = await this.props.onChange(e);

    if (!success && prevInternalValue) {
      // Undo change
      this.setState({ [internalValueName]: prevInternalValue });
    }
  };

  onBasePermissionChange = async (e) => {
    this.onPermissionChange(e, 'baseInternalValue');
  }

  onAdvancedPermissionChange = async (e) => {
    this.onPermissionChange(e, 'advancedInternalValue');
  }

  clearInternalValue = () => {
    this.setState({
      baseInternalValue: [],
      advancedInternalValue: [],
    });
  };

  getChoices = (type, showOnlyTypePerms, showTeamInvitationsPerm) => {
    const { t } = this.props;
    const teamPermChoices = showTeamInvitationsPerm
      ? [
        {
          text: t('project:permissions.can-manage-team-invitations'),
          value: CAN_INVITE_TEAM_MEMBER,
        },
        {
          text: t('project:permissions.can-view-team-results'),
          value: CAN_VIEW_TEAM_RESULTS,
        },
      ] : [];
    const permList = ProjectUserPermissionSettings.getPermissionList(type);
    const choices = [
      {
        text: t('project:permissions.can-include-patients'),
        value: CAN_INCLUDE,
      },
      ...teamPermChoices,
      {
        text: (
          <span>
            {t('project:permissions.can-view-external-inclusions')}
            <Help
              iconClassName="ml-2 align-middle"
            >
              {t('project:permissions.can-view-external-inclusions-help')}
            </Help>
          </span>
        ),
        value: CAN_VIEW_EXTERNAL_INCLUSIONS,
      }, {
        text: t('project:permissions.can-edit-form-and-docs'),
        value: CAN_EDIT_FORM_AND_DOCUMENTATIONS,
      }, {
        text: t('project:permissions.can-invite-external'),
        value: CAN_INVITE_EXTERNAL,
      }, {
        text: t('project:permissions.can-manage-advanced-permissions'),
        value: CAN_MANAGE_ADVANCED_PERMISSIONS,
      },
    ].filter((choice) => !showOnlyTypePerms || permList.includes(choice.value));
    return choices;
  };

  getBaseChoices = (type, showOnlyTypePerms, showTeamInvitationsPerm) => {
    const choices = this.getChoices(type, showOnlyTypePerms, showTeamInvitationsPerm);
    return ProjectUserPermissionSettings.filterBaseChoices(choices);
  };

  getAdvancedChoices = (type, showOnlyTypePerms, showTeamInvitationsPerm) => {
    const choices = this.getChoices(type, showOnlyTypePerms, showTeamInvitationsPerm);
    return ProjectUserPermissionSettings.filterAdvancedChoices(choices);
  };

  getDisabledChoices = (
    choices, type, writablePermissions, effectiveValue, advancedEffectiveValue,
  ) => {
    const permList = ProjectUserPermissionSettings.getPermissionList(type);
    const disabledChoices = choices.map((choice) => choice.value).filter((choice) => (
      !writablePermissions.includes(choice) || !permList.includes(choice)
    ));
    if (effectiveValue && !effectiveValue.includes(CAN_INVITE_EXTERNAL)) {
      disabledChoices.push(CAN_MANAGE_ADVANCED_PERMISSIONS);
    }
    if (advancedEffectiveValue && advancedEffectiveValue.includes(CAN_VIEW_EXTERNAL_INCLUSIONS)) {
      disabledChoices.push(CAN_VIEW_TEAM_RESULTS);
    }
    return disabledChoices;
  };

  render() {
    const {
      t, value, className, readOnly, writablePermissions, type, showTeamInvitationsPerm,
      showOnlyTypePerms, i18n, project, title, labelClassName,
    } = this.props;

    const { baseInternalValue, advancedInternalValue } = this.state;

    const baseEffectiveValue = baseInternalValue
      || ProjectUserPermissionSettings.filterBasePerms(value);
    const advancedEffectiveValue = advancedInternalValue
      || ProjectUserPermissionSettings.filterAdvancedPerms(value);

    const baseChoices = this.memoizedGetBaseChoices(
      type,
      showOnlyTypePerms,
      showTeamInvitationsPerm,
    );
    const advancedChoices = this.memoizedGetAdvancedChoices(
      type,
      showOnlyTypePerms,
      showTeamInvitationsPerm,
    );

    const baseDisabledChoices = this.memoizedGetBaseDisabledChoices(
      baseChoices,
      type,
      writablePermissions,
      baseEffectiveValue,
      advancedEffectiveValue,
    );
    const advancedDisabledChoices = this.memoizedGetAdvancedDisabledChoices(
      advancedChoices,
      type,
      writablePermissions,
      advancedEffectiveValue,
    );

    const commonProps = {
      rowClassName: 'row text-left d-block',
      colLabelClassName: 'col-12',
      colInputClassName: 'col-12 text-left',
      multipleChoices: true,
      orientation: 'vertical',
      hideOptionalLabel: true,
      disabled: readOnly,
    };

    return (
      <div className={className}>
        {
          Boolean(baseChoices.length) && (
            <LabeledChoice
              {...commonProps}
              className="mt-4 mb-0"
              label={(
                <>
                  <Label className={`mb-0 align-middle ${labelClassName ? ` ${labelClassName}` : ''}`}>
                    {(typeof title === 'string') ? (
                      t(title)
                    ) : (
                      title
                    )}
                  </Label>
                  <Help
                    iconClassName="ml-2 align-middle"
                    interactive
                  >
                    {!readOnly && (
                      <>
                        {t('project:invitations.base-permissions-info.part-1')}
                        <br />
                      </>
                    )}
                    <NavLink
                      href={getFAQUrl(i18n.language)}
                      className={`text-newblue-1${readOnly ? '' : ' m-0 p-0'}`}
                      style={{ textDecorationLine: 'underline' }}
                      target="_blank"
                    >
                      {t('project:invitations.base-permissions-info.part-2')}
                    </NavLink>
                  </Help>
                </>
              )}
              name="permissions-base"
              labelClassName="mb-2"
              value={baseEffectiveValue}
              choices={baseChoices}
              disabledChoices={baseDisabledChoices}
              onChange={this.onBasePermissionChange}
            />
          )
        }
        {
          Boolean(advancedChoices.length) && (
            <Provider store={store}>
              <LicenseChecker
                limName="can_give_advanced_project_rights"
                limitations={project.limitations}
                triggerType="checkbox"
                modalZIndex={10000}
              >
                <LabeledChoice
                  {...commonProps}
                  label={t('project:new-invitation.advanced-permissions')}
                  name="permissions-advanced"
                  labelClassName="mb-1 small text-gray font-talic"
                  value={advancedEffectiveValue}
                  choices={advancedChoices}
                  disabledChoices={advancedDisabledChoices}
                  onChange={this.onAdvancedPermissionChange}
                />
              </LicenseChecker>
            </Provider>
          )
        }
      </div>
    );
  }
}


export default ProjectUserPermissionSettings;
