import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import React, { Component, useState } from 'react';
import { withTranslation, useTranslation, Trans } from 'react-i18next';
import { withToastManager } from 'react-toast-notifications';
import { connect } from 'react-redux';
import { Input } from 'reactstrap';
import { Mutex } from 'async-mutex';
import {
  inclusionsActions, projectUsersActions, projectsActions, teamsActions,
} from '../redux/actions';
import {
  CAN_VIEW_EXTERNAL_INCLUSIONS, CAN_INCLUDE, POOL_PROJECT_MUTEXES,
  CAN_EDIT_FORM_AND_DOCUMENTATIONS, SORTING_BY_DATE, ASCENDING_PROGRESSION, DESCENDING_PROGRESSION,
  ASCENDING_PER_PROJECT_ID, DESCENDING_PER_PROJECT_ID, SORTING_BY_INCONSISTENT_DATA,
  SORTING_BY_QUALIFIED_MISSING_DATA, PROJECT_ON_HOLD, PROJECT_USER_USUAL, PROJECT_USER_OWNER_ONLY,
  CAN_INCLUDE_TEST_DATA,
} from '../constants';
import '../assets/css/loader.css';
import { nsOptions } from '../i18n';
import Toast from '../utils/Toast';
import ComponentLifeTracker from '../utils/ComponentLifeTracker';
import ErrorUtil from '../utils/ErrorUtil';
import TimeoutHandler from '../utils/TimeoutHandler';
import { hasPermission, isProjectDisabled } from '../utils/data-util';
import {
  TEAM_CENTER_TYPE, USER_CENTER_TYPE, MEMBER_CENTER_TYPE, MISC_CENTER_TYPE, getCenterId,
  getRawIdFromCenterId, isTeamCenterId, isUserCenterId, isMemberCenterId, DELETED_USER_CENTER_TYPE,
  isDeletedUserCenterId, DELETED_MEMBER_CENTER_TYPE, isDeletedMemberCenterId, getSuffixFromCenterId,
  getRawIdAndSuffix,
} from '../utils/centers';
import { deletePersistentMutexes } from '../utils/persistent-mutex';
import history from '../history';
import MixedView from './MixedView';
import { CardLoader, InputLoader } from './Loader';
import MobileView, { isMobileView } from './MobileView';
import Pagination from './Pagination';
import InclusionForm from './InclusionForm';
import LicenseChecker from './LicenseChecker';
import DropdownMenu from './NewDropdownMenu';
import InclusionsTable from './InclusionsTable';
import NewTooltip from './NewTooltip';
import LabeledSelect from './LabeledSelect';
import { isBrowserView } from './BrowserView';
import Help from './Help';
import { childrenPropTypes } from '../utils/generic-prop-types';
import fromReduxState from '../utils/redux';

const ID_SEARCH_CODE = '#';

const ALL_CENTER_ID = 0;

const AllInclusionsTooltip = (props) => {
  const { hide, children } = props;
  const { t } = useTranslation();
  return hide ? children : (
    <NewTooltip content={t('inclusion:inclusions.all-tooltip')} placement="right">
      {children}
    </NewTooltip>
  );
};

AllInclusionsTooltip.propTypes = {
  hide: PropTypes.bool,
  children: childrenPropTypes().isRequired,
};

AllInclusionsTooltip.defaultProps = {
  hide: false,
};

const SelectCenter = (props) => {
  const {
    user, projectUsers, value, className, showUserFilter, showAllInclusionsTooltip,
    teams, onChange, deletedProjectUsers, projectUser,
  } = props;

  const { t } = useTranslation();
  const [dropDownMenuRef, setDropDownMenuRef] = useState(null);
  const [teamUnfolded, setTeamUnfolded] = useState({});
  const [search, setSearch] = useState('');

  const idLabelMap = {
    [getCenterId(USER_CENTER_TYPE, user.id)]: t('inclusion:inclusions.mine'),
    [getCenterId(MISC_CENTER_TYPE, ALL_CENTER_ID)]: t('inclusion:inclusions.all'),
  };

  const displayItem = (label) => {
    if (!search) return true;
    const labelToLowerCase = label.toLowerCase();
    const searchToLowerCase = search.toLowerCase();
    if (!labelToLowerCase.includes(searchToLowerCase)) return false;
    return true;
  };

  const removeDuplicateUsers = (users) => (
    users.filter((usr, index) => (
      users.map((u) => u.id).indexOf(usr.id) === index
    ))
  );
  let externalParticipants = projectUsers.filter((pUser) => (
    !pUser.team_members.length
  )).map((pUser) => pUser.user);
  externalParticipants = removeDuplicateUsers(externalParticipants);

  externalParticipants.forEach((usr) => {
    idLabelMap[getCenterId(USER_CENTER_TYPE, usr.id)] = usr.label;
  });

  // Filter the deleted external participants
  const externalParticipantsDeleted = deletedProjectUsers.filter((pUser) => (
    !pUser.teams || !pUser.teams.length));
  externalParticipantsDeleted.forEach((deletedUsr) => {
    idLabelMap[getCenterId(DELETED_USER_CENTER_TYPE, deletedUsr.email)] = deletedUsr.label;
  });

  // Get the deleted members and their teams
  const deletedTeamMemberTeams = [];
  const deletedTeamMembers = deletedProjectUsers.filter((pUser) => (
    pUser.teams && pUser.teams.length));

  deletedTeamMembers.forEach((pUser) => {
    pUser.teams.forEach((teamId) => {
      if (!deletedTeamMemberTeams.includes(teamId)) deletedTeamMemberTeams.push(teamId);
    });
  });

  const canViewExternalInclusions = hasPermission(
    projectUser,
    CAN_VIEW_EXTERNAL_INCLUSIONS,
  );

  const effectiveTeams = teams.filter((tm) => (
    projectUsers.find((pUser) => (pUser.teams.includes(tm.id)))
      || deletedTeamMemberTeams.includes(tm.id))
      && (projectUser && (canViewExternalInclusions || projectUser.teams.includes(tm.id))));

  effectiveTeams.forEach((tm) => {
    idLabelMap[getCenterId(TEAM_CENTER_TYPE, tm.id)] = tm.name;

    tm.members.filter((memberId) => (
      projectUsers.find((pUser) => (
        pUser.team_members.find((member) => member.id === memberId)
      ))
    )).forEach((memberId) => {
      const memberProjectUser = projectUsers.find((pUser) => (
        pUser.team_members.find((member) => member.id === memberId)
      ));
      if (memberProjectUser) {
        idLabelMap[getCenterId(MEMBER_CENTER_TYPE, memberId)] = memberProjectUser.user.label;
      } else {
        console.error(`Unable to retrieve project user with member id ${memberId}.`);
      }
    });

    deletedTeamMembers.filter(
      (deletedUsr) => deletedUsr.teams.includes(tm.id),
    ).forEach((deletedTmMember) => {
      idLabelMap[
        getCenterId(DELETED_MEMBER_CENTER_TYPE, deletedTmMember.email, tm.id)
      ] = deletedTmMember.label;
    });
  });

  const onTeamFoldingToggle = (event, teamId) => {
    event.stopPropagation();
    setTeamUnfolded((prevState) => ({
      ...prevState,
      [teamId]: !prevState[teamId],
    }));
  };

  const onItemClick = (event, centerId) => {
    dropDownMenuRef.hide();
    onChange(centerId);
  };

  const renderItem = (id, type = MISC_CENTER_TYPE, children = null, itemClassName = '', userInProject = true) => {
    const centerId = getCenterId(type, id);
    return (
      <a
        key={centerId}
        className={`dropdown-item inclusion-creator-item${itemClassName ? ` ${itemClassName}` : ''}`}
        role="link"
        tabIndex={0}
        onClick={(event) => onItemClick(event, centerId)}
        onKeyPress={() => {}}
        style={centerId === value ? { backgroundColor: 'rgba(0, 0, 0, 0.08)' } : {}}
      >
        <span>
          {idLabelMap[centerId]}
          {!userInProject && (
            <Help
              iconClassName="ml-2"
              tooltipTheme="dark"
            >
              {t('project:user-has-left-the-project')}
            </Help>
          )}
        </span>
        {children}
      </a>
    );
  };

  const teamAndMemberOptions = effectiveTeams.filter(
    (tm) => displayItem(tm.name)
      || (projectUsers.find((pUser) => pUser.teams.includes(tm.id)
        && displayItem(pUser.user.label)))
      || (deletedTeamMembers.find((deletedMember) => deletedMember.teams.includes(tm.id)
        && displayItem(deletedMember.label))),
  ).map((tm) => (
    <div key={getCenterId(TEAM_CENTER_TYPE, tm.id)}>
      {renderItem(tm.id, TEAM_CENTER_TYPE, (
        <NewTooltip
          content={t(`common:centers.${teamUnfolded[tm.id] ? 'hide-team-members' : 'show-team-members'}`)}
        >
          <button
            className="btn btn-link py-0"
            type="button"
            onClick={(event) => onTeamFoldingToggle(event, tm.id)}
          >
            <FontAwesomeIcon
              icon={['far', teamUnfolded[tm.id] ? 'chevron-down' : 'chevron-right']}
              transform="shrink-3"
            />
          </button>
        </NewTooltip>
      ))}
      {teamUnfolded[tm.id] && tm.members.filter((memberId) => (
        projectUsers.find((pUser) => (
          pUser.team_members.find((member) => member.id === memberId)
            && (displayItem(pUser.user.label) || displayItem(tm.name))
        ))
      )).map((memberId) => renderItem(memberId, MEMBER_CENTER_TYPE, null, 'pl-5'))}
      {teamUnfolded[tm.id] && deletedTeamMembers.filter((deletedMember) => (
        deletedMember.teams.includes(tm.id) && displayItem(deletedMember.label)
      )).map((deletedMember) => renderItem(
        getRawIdAndSuffix(deletedMember.email, tm.id),
        DELETED_MEMBER_CENTER_TYPE,
        null,
        'pl-5 font-italic text-gray-dark',
        false,
      ))}
    </div>
  ));

  return (
    <div className={`select-creator${className ? ` ${className}` : ''}`}>
      <DropdownMenu
        type=""
        mainClass="d-inline-flex"
        beforeElement={(
          <div className="dropdown">
            <AllInclusionsTooltip
              hide={!showAllInclusionsTooltip
                || value !== getCenterId(MISC_CENTER_TYPE, ALL_CENTER_ID)}
            >
              <span>
                { idLabelMap[value].toUpperCase() }
              </span>
            </AllInclusionsTooltip>
          </div>
        )}
        triggerElement={(
          <span>
            <FontAwesomeIcon
              icon={['fal', 'angle-down']}
              className="ml-3 text-gray-dark cursor-pointer"
            />
          </span>
        )}
        ref={(ref) => { setDropDownMenuRef(ref); }}
      >
        <AllInclusionsTooltip hide={!showAllInclusionsTooltip}>
          {renderItem(ALL_CENTER_ID, MISC_CENTER_TYPE)}
        </AllInclusionsTooltip>
        {showUserFilter && renderItem(user.id, USER_CENTER_TYPE)}
        {
          (externalParticipants.length || effectiveTeams.length) && (
            <>
              <div className="px-4 select-creator-group">
                {t('inclusion:inclusions.others')}
              </div>
              <div className="px-4 pb-2">
                <Input
                  type="text"
                  value={search}
                  id="search-in-select-center"
                  placeholder={t('inclusion:inclusions.search-a-center')}
                  onChange={(e) => setSearch(e.target.value)}
                  className="center-search-input"
                />
              </div>
            </>
          )
        }
        {teamAndMemberOptions}
        {externalParticipants.filter((p) => displayItem(p.label)).map(
          (usr) => renderItem(usr.id, USER_CENTER_TYPE),
        )}
        {externalParticipantsDeleted.filter((p) => displayItem(p.label)).map(
          (usr) => renderItem(usr.email, DELETED_USER_CENTER_TYPE, null, 'font-italic text-gray-dark', false),
        )}
      </DropdownMenu>
    </div>
  );
};

SelectCenter.propTypes = {
  className: PropTypes.string,
  value: PropTypes.string.isRequired,
  user: PropTypes.shape().isRequired,
  projectUser: PropTypes.shape(),
  projectUsers: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  teams: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  showUserFilter: PropTypes.bool,
  showAllInclusionsTooltip: PropTypes.bool,
  onChange: PropTypes.func,
  deletedProjectUsers: PropTypes.arrayOf(PropTypes.shape()),
};

SelectCenter.defaultProps = {
  className: '',
  projectUser: undefined,
  showUserFilter: true,
  showAllInclusionsTooltip: true,
  onChange: () => {},
  deletedProjectUsers: [],
};

@withTranslation('', nsOptions)
class AddInclusionBtn extends Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
    project: PropTypes.shape().isRequired,
    teams: PropTypes.arrayOf(PropTypes.shape()).isRequired,
    inclusionsCount: PropTypes.number.isRequired,
    canIncludeMembers: PropTypes.arrayOf(PropTypes.number).isRequired,
    addInclusion: PropTypes.func.isRequired,
    canAddInclusion: PropTypes.func.isRequired,
    showTestInclusions: PropTypes.bool.isRequired,
  };

  renderButton = (onClick = () => {}) => {
    const { t, canAddInclusion, showTestInclusions } = this.props;

    const buttonColor = showTestInclusions ? 'btn-newyellow-1' : 'btn-newturquoise-1';

    const btn = (
      <button
        className={`btn text-white ${buttonColor} ${isMobileView() ? 'rounded btn-inclusion-search' : ''}`}
        onClick={onClick}
        disabled={!canAddInclusion()}
      >
        <FontAwesomeIcon
          icon={['fal', 'plus']}
          transform="grow-3"
          className="mr-2"
          {...(isMobileView() ? { id: 'plus-icon-inclusion-search' } : {})}
        />
        { !isMobileView() && (
          <span className="ml-1">
            {t(showTestInclusions ? 'project:button.include-test' : 'project:button.include')}
          </span>
        )}
      </button>
    );

    return showTestInclusions ? (
      <NewTooltip content={(<Trans i18nKey="error:warning.test-data" />)}>{btn}</NewTooltip>
    ) : btn;
  };

  render() {
    const {
      project, inclusionsCount, canIncludeMembers, addInclusion, teams, canAddInclusion,
    } = this.props;

    return (
      <LicenseChecker
        limitations={project.limitations}
        limName="max_inclusions_per_project"
        currentCount={inclusionsCount}
      >
        {
          canAddInclusion() && canIncludeMembers.length >= 2 ? (
            <DropdownMenu
              type={isMobileView() ? 'dropleft' : 'dropup'}
              mainClass="d-inline-block"
              triggerElement={(
                this.renderButton()
              )}
              ref={(ref) => { this.dropDownMenuRef = ref; }}
            >
              {
                canIncludeMembers.map((member) => {
                  const team = teams.find((tm) => tm.members.includes(member));
                  return (
                    <a
                      key={member}
                      className="dropdown-item"
                      role="link"
                      onClick={() => {
                        if (this.dropDownMenuRef) this.dropDownMenuRef.hide();
                        addInclusion(team.id);
                      }}
                      tabIndex={0}
                      onKeyPress={() => {}}
                    >
                      {team.name}
                    </a>
                  );
                })
              }
            </DropdownMenu>
          ) : (
            this.renderButton(() => {
              let team;
              if (canIncludeMembers.length) {
                team = teams.find((tm) => tm.members.includes(canIncludeMembers[0]));
              }
              addInclusion(team ? team.id : null);
            })
          )
        }
      </LicenseChecker>
    );
  }
}

const mapStateToProps = (state) => ({
  pages: state.projectPages,
  projectElements: state.projectElements,
  elements: state.elements,
  user: state.auth.authUser,
  projectUser: Object.values(state.projectUsers).find((pUser) => (
    pUser.user.id === state.auth.authUser.id
  )),
  projectUsers: state.projectUsers,
  teams: state.teams,
  inclusions: state.inclusions,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  fetchInclusions: async (params, compLifeTracker) => (
    dispatch(inclusionsActions.list({
      ...(ownProps.project ? { project: ownProps.project.id } : {}),
      ...params,
      admin: ownProps.admin,
    }, { pagination: 'short' }, () => compLifeTracker.isUnmounted()))
  ),
  addInclusion: async (data) => dispatch(inclusionsActions.create(data)),
  fetchProjectUsers: async (compLifeTracker) => dispatch(projectUsersActions.list({
    ...(ownProps.project ? { project: ownProps.project.id } : {}),
    confirmed: true,
    type__in: [PROJECT_USER_USUAL, PROJECT_USER_OWNER_ONLY],
    admin: ownProps.admin,
  }, { pagination: 'no' }, () => compLifeTracker.isUnmounted())),
  fetchProjects: async (projectIds, compLifeTracker) => dispatch(projectsActions.list({
    id__in: projectIds,
  }, { pagination: 'no' }, () => compLifeTracker.isUnmounted())),
  fetchTeams: async () => dispatch(teamsActions.list({
    project: ownProps.project ? ownProps.project.id : undefined,
  }, { pagination: 'no' })),
});


@withToastManager
@connect(mapStateToProps, mapDispatchToProps)
@withTranslation('', nsOptions)
class ProjectInclusions extends Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
    project: PropTypes.shape(),
    user: PropTypes.shape().isRequired,
    projectUser: PropTypes.shape(),
    projectUsers: PropTypes.shape().isRequired,
    teams: PropTypes.shape().isRequired,
    inclusions: PropTypes.shape().isRequired,
    fetchInclusions: PropTypes.func.isRequired,
    fetchProjectUsers: PropTypes.func.isRequired,
    fetchProjects: PropTypes.func.isRequired,
    fetchTeams: PropTypes.func.isRequired,
    addInclusion: PropTypes.func.isRequired,
    showTestInclusions: PropTypes.bool,
    resetInclusionMode: PropTypes.func,
  };

  static defaultProps = {
    project: null,
    projectUser: null,
    showTestInclusions: false,
    resetInclusionMode: () => {},
  };

  static initCenterFilterId = (props) => {
    if (props.showTestInclusions) {
      return getCenterId(USER_CENTER_TYPE, props.user.id);
    }
    // The project is shared
    // OR
    // it was shared with one user, they made inclusions and then were deleted (not shared anymore)
    if (!props.project || props.project.is_shared
      || props.project.deleted_users_with_inclusions.length) {
      return getCenterId(MISC_CENTER_TYPE, ALL_CENTER_ID);
    }
    return getCenterId(USER_CENTER_TYPE, props.user.id);
  }

  static initFilters = (props) => ({
    searchValue: new URLSearchParams(history.location.search).get('searchValue') || '',
    centerFilterId: ProjectInclusions.initCenterFilterId(props),
    ordering: props.project ? DESCENDING_PER_PROJECT_ID : SORTING_BY_DATE,
  })

  constructor(props) {
    super(props);
    this.state = {
      dataLoading: false,
      searchLoading: false,
      scrollType: 'down',
      inclusionsCount: 0,
      pageSize: 1,
      page: 1,
      showFilters: false,
      searchTooltipVisible: false,
      ...ProjectInclusions.initFilters(props),
    };
    this.timeoutHandler = new TimeoutHandler();
    this.mutex = new Mutex();
    this.compLifeTracker = new ComponentLifeTracker();
    this.toPUsersArray = fromReduxState((pUsers) => (
      Object.values(pUsers).filter((pUser) => pUser.confirmed && pUser.type === PROJECT_USER_USUAL)
    ));
    this.toTeamsArray = fromReduxState((teams) => Object.values(teams));
    this.toInclusionsArray = fromReduxState((inclusions) => Object.values(inclusions));
  }

  componentDidMount() {
    this.compLifeTracker.setMounted();
    this.fetchData();
  }

  async componentDidUpdate(prevProps) {
    if (history.location.search !== '') {
      history.replace({ search: '' });
    }
    if (prevProps.showTestInclusions !== this.props.showTestInclusions) {
      await this.resetFilters();
      await this.fetchData();
    }
  }

  componentWillUnmount() {
    this.compLifeTracker.setUnmounted();
    deletePersistentMutexes(POOL_PROJECT_MUTEXES);
    // Restore inclusion mode when leaving the inclusions tab
    const { project, resetInclusionMode } = this.props;
    if (project && project.status !== PROJECT_ON_HOLD) {
      resetInclusionMode();
    }
  }

  resetFilters = () => {
    this.setState({
      ...ProjectInclusions.initFilters(this.props),
    });
  }

  fetchData = async () => this.fetchPaginatedData(1, true, true);

  fetchPaginatedData = async (page, manageLoader = true, initialization = false) => {
    if (manageLoader) this.setState({ dataLoading: true });
    const {
      fetchInclusions, fetchProjectUsers, projectUsers: pUsers, fetchProjects, project,
      fetchTeams, showTestInclusions,
    } = this.props;
    const { searchValue, centerFilterId, ordering } = this.state;
    const projectUsers = this.toPUsersArray(pUsers);
    let params;
    const regex = new RegExp(`^${ID_SEARCH_CODE}[0-9]+$`);
    if (regex.test(searchValue)) {
      params = {
        ordering,
        page,
      };
      params.per_project_id = searchValue.replace(ID_SEARCH_CODE, '');
    } else {
      params = {
        ordering,
        page,
        search: searchValue,
      };
    }
    params.is_test = showTestInclusions;
    const isDeletedUserId = isDeletedUserCenterId(centerFilterId);
    const isDeletedMemberId = isDeletedMemberCenterId(centerFilterId);
    const isDeletedCenterId = isDeletedUserId || isDeletedMemberId;
    const centerId = getRawIdFromCenterId(centerFilterId, isDeletedCenterId ? String : Number);

    try {
      if (!project) {
        await fetchTeams();
      }

      if (isUserCenterId(centerFilterId)) {
        params.creator = centerId;
      } else if (isTeamCenterId(centerFilterId)) {
        params.team = centerId;
      } else if (isMemberCenterId(centerFilterId)) {
        const projectUser = projectUsers.find((pUser) => (
          pUser.team_members.find((mb) => mb.id === centerId)
        ));
        const { teams } = this.props;
        const team = this.toTeamsArray(teams).find((tm) => tm.members.includes(centerId));
        try {
          params.creator = projectUser.user.id;
          params.team = team.id;
        } catch (error) {
          console.error(error);
        }
      } else if (isDeletedCenterId) {
        params.creator_email = centerId;
        if (isDeletedMemberId && project) {
          const teamId = getSuffixFromCenterId(centerFilterId);
          params.team = teamId;
        }
      }

      const res = await fetchInclusions(params, this.compLifeTracker);
      const inclusions = res.results;
      const userIds = [
        ...new Set(inclusions.map((inclusion) => inclusion.creator)),
      ];
      if (initialization || userIds.some((user) => (
        !projectUsers.find((pUser) => pUser.user.id === user)
      ))) {
        await fetchProjectUsers(this.compLifeTracker);
      }

      if (!project) {
        await fetchProjects(
          [...new Set(inclusions.map((inclusion) => inclusion.project))],
          this.compLifeTracker,
        );
      }

      const showFilters = res.count > 0 || searchValue
        || (centerFilterId !== getCenterId(MISC_CENTER_TYPE, ALL_CENTER_ID)
          && (!project || project.is_shared));

      if (this.compLifeTracker.isMounted()) {
        this.setState({
          page,
          inclusionsCount: res.count,
          pageSize: Math.max(res.results.length, this.state.pageSize),
          showFilters,
        });
      }
    } catch (error) {
      ErrorUtil.handleCatched(this.props, error, false);
    } finally {
      if (this.compLifeTracker.isMounted()) {
        this.setState({ searchLoading: false });
        if (manageLoader) this.setState({ dataLoading: false });
      }
    }
  };

  handleSearch = (event) => {
    this.setState(
      { searchLoading: true, searchValue: event.target.value },
      () => this.timeoutHandler.doAfterTimeout(() => this.fetchPaginatedData(1), 250),
    );
  };

  handleCenterFilter = (value) => {
    this.setState({ centerFilterId: value }, () => this.fetchPaginatedData(1));
  };

  getTeamMembersWhichCanInclude = () => {
    const {
      projectUser, teams: tms, showTestInclusions,
    } = this.props;
    const teams = this.toTeamsArray(tms);
    if (projectUser && teams.length) {
      return projectUser.team_members.filter((member) => {
        const team = teams.find((tm) => tm.members.includes(member.id));
        return hasPermission(projectUser, CAN_INCLUDE, team.id)
          || (showTestInclusions
            && hasPermission(projectUser, CAN_EDIT_FORM_AND_DOCUMENTATIONS, team.id));
      }).map((member) => member.id);
    }
    return [];
  }

  canAddInclusion = () => {
    const { projectUser, project, showTestInclusions } = this.props;
    if (!projectUser || !project || isProjectDisabled(project) || project.is_paused) {
      return false;
    }
    // Test mode enabled
    if (showTestInclusions) {
      return (hasPermission(projectUser, CAN_INCLUDE_TEST_DATA)
        || hasPermission(projectUser, CAN_EDIT_FORM_AND_DOCUMENTATIONS)
        || hasPermission(projectUser, CAN_INCLUDE));
    }
    // Classic mode: external participant
    if (!projectUser.team_members.length) {
      return hasPermission(projectUser, CAN_INCLUDE);
    }
    // Classic mode: participant in one or several teams
    return this.getTeamMembersWhichCanInclude().length !== 0;
  }

  addInclusion = async (teamId = null) => {
    const {
      project, addInclusion, user, showTestInclusions,
    } = this.props;
    const { centerFilterId } = this.state;

    if (project === null) {
      return;
    }
    // Do not stack multiple addInclusion() calls
    if (!this.mutex.isLocked()) {
      this.setState({ dataLoading: true });
      const release = await this.mutex.acquire();
      try {
        const inclusion = await addInclusion({
          project: project.id,
          team: teamId,
          is_test: showTestInclusions,
        });
        Toast.success(this.props, 'error:valid.saved');
        // Clear search / filters in order to find the new inclusion in state.inclusions
        await this.setState({
          searchValue: '',
          centerFilterId: ProjectInclusions.initCenterFilterId(this.props),
          ordering: project ? DESCENDING_PER_PROJECT_ID : SORTING_BY_DATE,
        });
        await this.fetchPaginatedData(1, false);
        await this.inclusionTable.fetchProjectData(project);
        await this.inclusionForm.init(inclusion.id, false);
        if (![
          getCenterId(USER_CENTER_TYPE, user.id),
          getCenterId(MISC_CENTER_TYPE, ALL_CENTER_ID),
        ].includes(centerFilterId)) {
          // Ensure new inclusion will be visible in the list
          this.handleCenterFilter(getCenterId(USER_CENTER_TYPE, user.id));
        }
      } catch (error) {
        ErrorUtil.handleCatched(this.props, error);
      } finally {
        release();
        this.setState({ dataLoading: false });
      }
    }
  };

  scrollContent = () => {
    const duration = 500;
    if (this.state.scrollType === 'up') {
      this.scrollTop(duration);
    } else {
      this.scrollBottom(duration);
    }
  };

  handleSortInclusions = async (event) => {
    const newSorting = event.target.value;
    await this.setState({ ordering: newSorting });
    await this.fetchPaginatedData(1);
  }

  render() {
    const {
      t, project, projectUsers: pUsers, user, inclusions: incs, teams: tms, showTestInclusions,
      projectUser,
    } = this.props;
    const {
      dataLoading, centerFilterId, searchValue, page, inclusionsCount, pageSize, searchLoading,
      showFilters, ordering, searchTooltipVisible,
    } = this.state;

    const projectUsers = this.toPUsersArray(pUsers);
    const teams = this.toTeamsArray(tms);
    const inclusions = this.toInclusionsArray(incs);
    const isManager = project && (project.manager.id === user.id);

    const {
      can_include_as_project_manager: canIncludeAsProjectManager,
      can_join_project_as_external: canJoinProjectAsExternal,
      can_join_project_in_team: canJoinProjectInTeam,
    } = user.limitations;
    const canEditFormAndDocs = hasPermission(projectUser, CAN_EDIT_FORM_AND_DOCUMENTATIONS);
    const canIncludeTestData = hasPermission(projectUser, CAN_INCLUDE_TEST_DATA);

    const canHaveInclusions = canIncludeAsProjectManager || canJoinProjectAsExternal
      || canJoinProjectInTeam;
    // Organizations should be able to make test inclusions
    const canTestIncludeAsProjectManager = isManager && canEditFormAndDocs;

    const showIdentification = canHaveInclusions || canIncludeAsProjectManager
      || canTestIncludeAsProjectManager;

    let filteredProjectUsers = [];
    let deletedProjectUsersWithInclusions = [];
    let showSelectCenter = project === null;

    const canIncludeMembers = this.getTeamMembersWhichCanInclude();

    const inclusionsCountInfo = (
      <div className="text-bigger font-weight-semibold">
        { inclusionsCount && <span>{inclusionsCount}</span> }
        {' '}
        {t(`common:dashboard.${showTestInclusions ? 'test-' : ''}inclusions`)}
      </div>
    );

    if (projectUsers && projectUsers.length) {
      const canViewExternalInclusions = hasPermission(
        projectUser,
        CAN_VIEW_EXTERNAL_INCLUSIONS,
      );
      filteredProjectUsers = projectUsers.filter((pUser) => pUser.confirmed
        && (pUser.user.id !== user.id || pUser.team_members.length > 1 /* Multi-teams case */)
        && (canViewExternalInclusions
          || pUser.teams.find((tm) => projectUser && projectUser.teams.includes(tm))));
      deletedProjectUsersWithInclusions = project ? project.deleted_users_with_inclusions.filter(
        (deletedUser) => (
          canViewExternalInclusions || deletedUser.teams.find((teamId) => (
            projectUser && projectUser.teams.includes(teamId)))),
      ) : [];
      if (project) {
        showSelectCenter = Boolean(
          (project.is_shared || project.deleted_users_with_inclusions.length)
            && (canViewExternalInclusions || projectUser.teams.length),
        );
      }
    }

    const cannotSeeInclusionTable = project && project.status === PROJECT_ON_HOLD
      && !(canEditFormAndDocs || canIncludeTestData);

    const inclusionsTableParams = showTestInclusions ? { noInclusionMsg: 'project:no.test-inclusion' } : {};

    return (
      <>
        <InclusionForm
          ref={(inclusionForm) => {
            this.inclusionForm = inclusionForm;
          }}
          onDelete={this.fetchData}
          showDelete
        />
        {dataLoading && <CardLoader />}
        {cannotSeeInclusionTable ? (
          <div className="test-inclusions-access-denied">
            <h6>
              {t('project:inclusions-not-open')}
            </h6>
            <div className="text-muted text-center mt-1">
              {t('project:inclusions-not-open-info')}
              <Help iconClassName="ml-2">
                {t('project:inclusions-not-open-help')}
                &quot;
                <span className="font-italic">
                  {t('project:permissions.can-edit-form-and-docs')}
                </span>
                &quot;
              </Help>
            </div>
          </div>
        ) : (
          <>
            <div className={`row ${isMobileView() ? 'justify-content-center' : 'justify-content-between'} mt-3`}>
              <MixedView>
                <div className="col-12 col-xl-auto col-md-12 mb-2">
                  <div className="row align-items-end">
                    {
                      user && showSelectCenter && !showTestInclusions && (
                        <div className="col-12 col-md-auto">
                          <NewTooltip
                            content={t('common:tooltip.filter')}
                          >
                            <FontAwesomeIcon
                              icon={['fas', 'filter']}
                              className="mr-2 text-gray-dark"
                            />
                          </NewTooltip>
                          <SelectCenter
                            className="pt-2"
                            value={centerFilterId}
                            t={t}
                            user={user}
                            projectUser={projectUser}
                            projectUsers={filteredProjectUsers}
                            teams={teams}
                            showUserFilter={canHaveInclusions}
                            showAllInclusionsTooltip={Boolean(project)}
                            onChange={this.handleCenterFilter}
                            deletedProjectUsers={deletedProjectUsersWithInclusions.map(
                              (deletedUser) => ({
                                label: deletedUser.creator_name || deletedUser.creator_email,
                                email: deletedUser.creator_email,
                                teams: deletedUser.teams,
                              }),
                            )}
                          />
                        </div>
                      )
                    }
                    {
                      project && !showTestInclusions && (
                        <div className="col-12 col-md-auto">
                          <div className="row align-items-center ml-2 mr-1">
                            <NewTooltip
                              content={t('common:tooltip.sort')}
                            >
                              <FontAwesomeIcon
                                icon={['fas', 'sort-alt']}
                                className="mr-2 text-gray-dark"
                                transform="grow-4"
                              />
                            </NewTooltip>
                            {
                              isBrowserView() ? (
                                <LabeledSelect
                                  className="mb-0 d-inline-block"
                                  rowClassName="row align-items-center"
                                  selectClassName="custom-select progression-sorting-select"
                                  name="sort-inclusions"
                                  hideOptionalLabel
                                  onChange={this.handleSortInclusions}
                                  value={ordering}
                                >
                                  <option
                                    key="descending-per-project-id"
                                    value={DESCENDING_PER_PROJECT_ID}
                                  >
                                    {t('project:sort-inclusions.by-descending-per-project-id')}
                                  </option>
                                  <option
                                    key="ascending-per-project-id"
                                    value={ASCENDING_PER_PROJECT_ID}
                                  >
                                    {t('project:sort-inclusions.by-ascending-per-project-id')}
                                  </option>
                                  <option
                                    key="sorting-by-date"
                                    value={SORTING_BY_DATE}
                                  >
                                    {t('project:sort-inclusions.by-date')}
                                  </option>
                                  <option
                                    key="descending-progression"
                                    value={DESCENDING_PROGRESSION}
                                  >
                                    {t('project:sort-inclusions.by-decreasing-progression')}
                                  </option>
                                  <option
                                    key="ascending-progression"
                                    value={ASCENDING_PROGRESSION}
                                  >
                                    {t('project:sort-inclusions.by-increasing-progression')}
                                  </option>
                                  <option
                                    key="sorting-by-inconsistent-data"
                                    value={SORTING_BY_INCONSISTENT_DATA}
                                  >
                                    {t('project:sort-inclusions.by-inconsistent-data-first')}
                                  </option>
                                  <option
                                    key="sorting-by-qualified-missing-data"
                                    value={SORTING_BY_QUALIFIED_MISSING_DATA}
                                  >
                                    {t('project:sort-inclusions.by-qualified-missing-data')}
                                  </option>
                                </LabeledSelect>
                              ) : (
                                <LabeledSelect
                                  className="mb-0 d-inline-block"
                                  rowClassName="row align-items-center"
                                  selectClassName="custom-select progression-sorting-select"
                                  name="sort-inclusions"
                                  hideOptionalLabel
                                  onChange={this.handleSortInclusions}
                                >
                                  <option
                                    key="descending-per-project-id"
                                    value={DESCENDING_PER_PROJECT_ID}
                                  >
                                    {t('project:sort-inclusions.by-descending-per-project-id')}
                                  </option>
                                  <option
                                    key="ascending-per-project-id"
                                    value={ASCENDING_PER_PROJECT_ID}
                                  >
                                    {t('project:sort-inclusions.by-ascending-per-project-id')}
                                  </option>
                                  <option
                                    key="sorting-by-date"
                                    value={SORTING_BY_DATE}
                                  >
                                    {t('project:sort-inclusions.by-date')}
                                  </option>
                                  <option
                                    key="descending-progression"
                                    value={DESCENDING_PROGRESSION}
                                  >
                                    {t('project:sort-inclusions.by-decreasing-progression')}
                                  </option>
                                  <option
                                    key="ascending-progression"
                                    value={ASCENDING_PROGRESSION}
                                  >
                                    {t('project:sort-inclusions.by-increasing-progression')}
                                  </option>
                                  <option
                                    key="sorting-by-inconsistent-data"
                                    value={SORTING_BY_INCONSISTENT_DATA}
                                  >
                                    {t('project:sort-inclusions.by-inconsistent-data-first')}
                                  </option>
                                  <option
                                    key="sorting-by-qualified-missing-data"
                                    value={SORTING_BY_QUALIFIED_MISSING_DATA}
                                  >
                                    {t('project:sort-inclusions.by-qualified-missing-data')}
                                  </option>
                                </LabeledSelect>
                              )
                            }
                          </div>
                        </div>
                      )
                    }
                    {
                      showFilters && !showTestInclusions && (
                        <div className="col-12 col-md-auto">
                          <NewTooltip
                            content={t('common:tooltip.search')}
                          >
                            <FontAwesomeIcon
                              icon={['fas', 'search']}
                              transform="grow-4"
                              className="mr-2 text-gray-dark"
                            />
                          </NewTooltip>
                          <NewTooltip
                            content={t('project:inclusions.search-inclusion-tooltip')}
                            visible={searchTooltipVisible}
                            contentClassName="inclusions-search-tooltip"
                          >
                            <Input
                              type="text"
                              id="inclusion-search-input"
                              className="d-inline-block"
                              placeholder={t('project:inclusions.search-inclusion')}
                              style={{ width: 'auto' }}
                              value={searchValue}
                              onChange={this.handleSearch}
                              onFocus={() => this.setState({ searchTooltipVisible: true })}
                              onBlur={() => this.setState({ searchTooltipVisible: false })}
                            />
                          </NewTooltip>
                          {
                            searchLoading ? (
                              <div className="d-none d-md-block">
                                <InputLoader />
                              </div>
                            ) : null
                          }
                        </div>
                      )
                    }
                    <div className="col-12 col-md-auto pt-2">
                      {inclusionsCountInfo}
                    </div>
                  </div>
                </div>
              </MixedView>
              {
                project !== null
                  && (canIncludeAsProjectManager || !isManager
                    || showTestInclusions) && (
                    <div className="col-12 col-md-auto py-4 py-md-0">
                      <div className="row">
                        <div className={`col-12 col-md-auto ${isMobileView() ? 'text-center' : ''}`}>
                          <AddInclusionBtn
                            project={project}
                            teams={teams}
                            inclusionsCount={inclusionsCount}
                            canIncludeMembers={canIncludeMembers}
                            addInclusion={this.addInclusion}
                            canAddInclusion={this.canAddInclusion}
                            showTestInclusions={showTestInclusions}
                          />
                        </div>
                      </div>
                    </div>
                )
              }
              <MobileView>
                <div className="col-12 col-md-auto contains-loader">
                  {
                    user && showSelectCenter && !showTestInclusions && (
                      <div className="row justify-content-center mb-3">
                        <div className="col-12">
                          <div className="d-block m-0 text-center">
                            <FontAwesomeIcon icon={['fas', 'filter']} className="mr-2 text-gray-dark" />
                            <SelectCenter
                              value={centerFilterId}
                              t={t}
                              user={user}
                              projectUsers={filteredProjectUsers}
                              teams={teams}
                              showUserFilter={canHaveInclusions}
                              showAllInclusionsTooltip={Boolean(project)}
                              onChange={this.handleCenterFilter}
                              deletedProjectUsers={deletedProjectUsersWithInclusions.map(
                                (deletedUser) => ({
                                  label: deletedUser.creator_name,
                                  email: deletedUser.creator_email,
                                  teams: deletedUser.teams,
                                }),
                              )}
                            />
                          </div>
                        </div>
                      </div>
                    )
                  }
                  {
                    project && !showTestInclusions && (
                      <div className="row justify-content-center align-items-center mb-2">
                        <div className="col-12">
                          <div className="row m-0 mb-2 justify-content-center align-items-center">
                            <FontAwesomeIcon
                              icon={['fas', 'sort-alt']}
                              className="text-gray-dark mr-2 mt-1"
                              transform="grow-5"
                            />
                            <LabeledSelect
                              className="mb-0 d-inline-block"
                              rowClassName="row align-items-center"
                              selectClassName="custom-select progression-sorting-select"
                              name="sort-inclusion"
                              hideOptionalLabel
                              onChange={this.handleSortInclusions}
                            >
                              <option
                                key="descending-per-project-id"
                                value={DESCENDING_PER_PROJECT_ID}
                              >
                                {t('project:sort-inclusions.by-descending-per-project-id')}
                              </option>
                              <option
                                key="ascending-per-project-id"
                                value={ASCENDING_PER_PROJECT_ID}
                              >
                                {t('project:sort-inclusions.by-ascending-per-project-id')}
                              </option>
                              <option
                                key="sorting-by-date"
                                value={SORTING_BY_DATE}
                              >
                                {t('project:sort-inclusions.by-date')}
                              </option>
                              <option
                                key="descending-progression"
                                value={DESCENDING_PROGRESSION}
                              >
                                {t('project:sort-inclusions.by-decreasing-progression')}
                              </option>
                              <option
                                key="ascending-progression"
                                value={ASCENDING_PROGRESSION}
                              >
                                {t('project:sort-inclusions.by-increasing-progression')}
                              </option>
                              <option
                                key="sorting-by-inconsistent-data"
                                value={SORTING_BY_INCONSISTENT_DATA}
                              >
                                {t('project:sort-inclusions.by-inconsistent-data-first')}
                              </option>
                              <option
                                key="sorting-by-qualified-missing-data"
                                value={SORTING_BY_QUALIFIED_MISSING_DATA}
                              >
                                {t('project:sort-inclusions.by-qualified-missing-data')}
                              </option>
                            </LabeledSelect>
                          </div>
                        </div>
                      </div>
                    )
                  }
                  {
                    searchLoading ? (
                      <div className="d-none d-md-block">
                        <InputLoader />
                      </div>
                    ) : null
                  }
                  {!showTestInclusions && (
                    <div className="row justify-content-center mb-3">
                      <div className="col-12">
                        <div className="d-block m-0 text-center ml-5">
                          <FontAwesomeIcon
                            icon={['fas', 'search']}
                            transform="grow-5"
                            className="mr-2 text-gray-dark"
                          />
                          <Input
                            type="text"
                            id="inclusion-search-input"
                            className="d-inline-block  ml-1"
                            placeholder={t('project:inclusions.search-inclusion')}
                            onKeyUp={this.handleSearch}
                          />
                        </div>
                      </div>
                    </div>
                  )}
                </div>
                <div className="row justify-content-center">
                  <div className="col-12">
                    <div className="d-block m-0 text-center">
                      { inclusionsCountInfo }
                    </div>
                  </div>
                </div>
              </MobileView>
            </div>
            <InclusionsTable
              inclusions={dataLoading ? [] : inclusions}
              inclusionForm={this.inclusionForm}
              showProject={project === null}
              showLastChangeDate
              showIdentification={showIdentification}
              showDataMonitoring
              showTestInclusions={showTestInclusions}
              ref={(ref) => { this.inclusionTable = ref; }}
              sorting={ordering}
              {...inclusionsTableParams}
            />
            <nav className="my-5">
              <Pagination
                page={page}
                count={inclusionsCount}
                pageSize={pageSize}
                action={this.fetchPaginatedData}
              />
            </nav>
          </>
        )}
      </>
    );
  }
}


export default ProjectInclusions;
