import { createReducer } from 'redux-starter-kit';
import { userNotificationSettingActions } from '../actions';
import getStdDbModelReduxMapping from './std-db-model-reducers';

export const userNotificationSettingInitialState = {};

const userNotificationSettingReducer = createReducer(
  userNotificationSettingInitialState,
  getStdDbModelReduxMapping(userNotificationSettingActions),
);

export default userNotificationSettingReducer;
