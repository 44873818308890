import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { I18nextProvider } from 'react-i18next';
import Tippy from '@tippyjs/react';
import '../assets/css/tooltip.css';
import 'tippy.js/dist/tippy.css';
import 'tippy.js/themes/light.css';
import i18n from '../i18n';
import { childrenPropTypes } from '../utils/generic-prop-types';
import DomUtil from '../utils/DomUtil';

class NewTooltip extends Component {
  static propTypes = {
    contentClassName: PropTypes.string,
    children: childrenPropTypes().isRequired,
    content: childrenPropTypes(),
    placement: PropTypes.oneOf([
      'top',
      'top-start',
      'top-end',
      'right',
      'right-start',
      'right-end',
      'bottom',
      'bottom-start',
      'bottom-end',
      'left',
      'left-start',
      'left-end',
    ]),
    trigger: PropTypes.oneOf([
      'mouseenter',
      'focus',
      'click',
      'manual',
      'mouseenter focus',
      'mouseenter click',
      'mouseenter focus click',
      'focus click',
    ]),
    offset: PropTypes.number,
    distance: PropTypes.number,
    theme: PropTypes.string,
    arrow: PropTypes.bool,
    onClick: PropTypes.func,
    visible: PropTypes.bool,
    interactive: PropTypes.bool,
    showTooltipEvenIfEmpty: PropTypes.bool,
    hideOnClick: PropTypes.oneOf([true, false, 'persistent']),
    disabled: PropTypes.bool,
    overFlowElement: PropTypes.string,
    containerClassName: PropTypes.string,
    onShow: PropTypes.func,
    onShown: PropTypes.func,
    onHide: PropTypes.func,
    onHidden: PropTypes.func,
  };

  static defaultProps = {
    contentClassName: 'text-left',
    content: undefined,
    placement: 'top',
    trigger: 'mouseenter focus',
    offset: 0,
    distance: 10,
    theme: 'dark',
    arrow: false,
    onClick: null,
    visible: undefined,
    interactive: false,
    showTooltipEvenIfEmpty: false,
    hideOnClick: true,
    disabled: false,
    overFlowElement: 'viewport',
    containerClassName: '',
    onShow: () => {},
    onShown: () => {},
    onHide: () => {},
    onHidden: () => {},
  };

  static list = [];

  constructor(props) {
    super(props);
    this.state = { disabled: false };
    this.tooltip = null;
    NewTooltip.list.push(this);
  }

  componentWillUnmount() {
    const index = NewTooltip.list.indexOf(this);
    if (index > -1) {
      NewTooltip.list.splice(index, 1);
    }
  }

  block = () => {
    this.setState({ disabled: true });
  };

  unblock = () => {
    this.setState({ disabled: false });
  };

  render() {
    const {
      content, showTooltipEvenIfEmpty, contentClassName, hideOnClick, disabled,
      placement, offset, trigger, distance, arrow, theme, visible, interactive,
      onShow, onShown, onHide, onHidden, overFlowElement, children, onClick,
      containerClassName,
    } = this.props;
    const childrenProps = { ...children.props };
    const showable = content || showTooltipEvenIfEmpty;

    if (onClick && !children.props.onClick) {
      childrenProps.onClick = onClick;
    }
    const controlled = visible !== undefined;
    const effectiveChildren = React.cloneElement(children, { ...childrenProps });

    return (
      <Tippy
        // I18nextProvider seems to be required here because of portal usage ?
        content={(
          <I18nextProvider i18n={i18n}>
            <div className={contentClassName}>
              {content}
            </div>
          </I18nextProvider>
        )}
        appendTo={document.body}
        placement={placement}
        trigger={controlled ? undefined : trigger}
        offset={[offset, distance]}
        arrow={arrow}
        theme={theme}
        visible={visible}
        interactive={interactive}
        disabled={this.state.disabled || disabled || !showable}
        duration={250}
        hideOnClick={controlled ? undefined : hideOnClick}
        inertia
        onShow={onShow}
        onShown={onShown}
        onHide={onHide}
        onHidden={onHidden}
        popperOptions={{
          modifiers: [{
            name: 'preventOverflow',
            options: {
              boundary: overFlowElement,
            },
          }],
        }}
        ref={(tooltip) => {
          this.tooltip = tooltip;
        }}
        className={containerClassName}
      >
        {
          // Functional component needs to be wrap to be able to receive a ref.
          // As this wrapping may have side effects (layout), apply it only when
          // its absolutely necessary.
          DomUtil.isFunctionalComponent(effectiveChildren) ? (
            <span>
              {effectiveChildren}
            </span>
          ) : effectiveChildren
        }
      </Tippy>
    );
  }
}


export default NewTooltip;
