import {
  LINK_OPERATOR_AND, ELEMENT_TYPE_MULTIPLE_CHOICES, ELEMENT_TYPE_UNIQUE_CHOICE, LINK_OPERATOR_OR,
  TARGET_TYPE_ELEMENT, TARGET_TYPE_PAGE,
} from '../constants';

export const checkLinks = (elements, elementLinks, projectElements, rootProjectElements,
  projectEntries, pages, target, targetType, inclusionId, moduleInstanceId) => {
  try {
    const links = elementLinks.filter((link) => {
      if (targetType === TARGET_TYPE_PAGE) {
        return link.page_target === target.id;
      }
      return link.element_target === target.id
        || (link.page_target === target.project_page
          && rootProjectElements.find((pEl) => pEl.id === target.id));
    });

    if (links.length < 1) return true;

    const pageOperator = targetType === TARGET_TYPE_PAGE ? target.link_operator
      : pages[target.project_page].link_operator;
    const elementOperator = target.link_operator;
    const mergeResults = (results, op) => {
      if (!results.length) return true;
      return op === LINK_OPERATOR_OR ? results.includes(true) : !results.includes(false);
    };
    const pageLinkResults = [];
    const elementLinkResults = [];

    // Check links
    links.forEach((link) => {
      const linkProjectElement = projectElements.find((pEl) => pEl.id === link.source);
      const linkEntries = projectEntries.filter((entry) => (
        entry.project_element === link.source
        && entry.inclusion === inclusionId
        && entry.module === (linkProjectElement.module ? moduleInstanceId : null)
      ));
      const isPageLink = !link.element_target;
      let res = false;

      if (linkEntries.length && linkEntries.some((le) => !le.pending)) {
        const linkElement = elements[linkProjectElement.element];
        const { type } = linkElement;

        if ([ELEMENT_TYPE_UNIQUE_CHOICE, ELEMENT_TYPE_MULTIPLE_CHOICES].includes(type)) {
          res = linkEntries.findIndex((entry) => entry.value === link.choice) >= 0;
        } else {
          const [linkEntry] = linkEntries;
          const { value } = linkEntry;
          if (value || value === 0) {
            const expectedValue = Number(link.value.replace(',', '.'));
            if (expectedValue || expectedValue === 0) {
              const { action } = link;
              switch (action) {
                case 'eq':
                  res = value === expectedValue;
                  break;
                case 'gt':
                  res = value > expectedValue;
                  break;
                case 'lt':
                  res = value < expectedValue;
                  break;
                case 'gteq':
                  res = value >= expectedValue;
                  break;
                case 'lteq':
                  res = value <= expectedValue;
                  break;
                default:
                  res = false;
                  break;
              }
            }
          }
        }
      }
      // Check parent links if needed
      const source = projectElements.find((pEl) => pEl.id === link.source);
      const finalRes = res && checkLinks(
        elements, elementLinks, projectElements, rootProjectElements, projectEntries, pages,
        source, TARGET_TYPE_ELEMENT, inclusionId, moduleInstanceId,
      );
      if (isPageLink) pageLinkResults.push(finalRes);
      else elementLinkResults.push(finalRes);
    });

    const pagesResult = mergeResults(pageLinkResults, pageOperator);
    const elementsResult = mergeResults(elementLinkResults, elementOperator);
    // Do final merging between page links and variable links knowing that page links are
    // always in AND mode regarding element links.
    return mergeResults([pagesResult, elementsResult], LINK_OPERATOR_AND);
  } catch (error) {
    console.error(error);
    return false;
  }
};

/**
 * @param source Link Source Element identifier
 * @param childrenRefs Table of form elements identifiers
 */
export const checkChildrenLinks = (elementLinks, source, childrenRefs, rootProjectElements) => {
  const links = elementLinks.filter((link) => link.source === source);
  const checkCurrentAndNextLinks = (pElId) => {
    const childRef = childrenRefs[pElId];
    if (childRef) childRef.checkLinks();
    checkChildrenLinks(elementLinks, pElId, childrenRefs, rootProjectElements);
  };
  links.forEach((link) => {
    if (link.element_target) {
      // Element link
      checkCurrentAndNextLinks(link.element_target);
    } else if (link.page_target) {
      // Page link : check each root element of the page
      rootProjectElements.filter((pEl) => (
        pEl.project_page === link.page_target
      )).forEach((pEl) => checkCurrentAndNextLinks(pEl.id));
    }
  });
};

/**
 * @param projectElements Redux dict of project elements
 * @param elementLinks Array of element links.
 * @return Array of root project elements (root means that the project elements are not targetted
 * by a link of their own page).
 */
export const getRootProjectElements = (projectElements, elementLinks) => {
  const getElementPageId = (pElId) => {
    const pEl = projectElements[pElId];
    return pEl ? pEl.project_page : undefined;
  };
  const internalLinks = elementLinks.filter((link) => {
    if (!link.element_target) return false;
    const sourcePage = getElementPageId(link.source);
    const targetPage = getElementPageId(link.element_target);
    return sourcePage && targetPage && sourcePage === targetPage;
  });
  const childrenPElementIds = [...new Set(internalLinks.map((link) => link.element_target))];
  return Object.values(projectElements).filter((pEl) => !childrenPElementIds.includes(pEl.id));
};
