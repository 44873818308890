import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Input, Label } from 'reactstrap';
import { withTranslation, useTranslation } from 'react-i18next';
import { withToastManager } from 'react-toast-notifications';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import memoize from 'memoize-one';
import isEqual from 'react-fast-compare';
import { Link } from 'react-router-dom';
import {
  CAN_MANAGE_EXTERNAL_INVITATIONS, CAN_INCLUDE, ADMIN_EDITABLE_PERMS, ADVANCED_EDITABLE_PERMS,
  BASIC_EDITABLE_PERMS, CAN_MANAGE_TEAM_INVITATIONS, CAN_MANAGE_ADVANCED_PERMISSIONS,
  CAN_INVITE_EXTERNAL, CAN_VIEW_EXTERNAL_INCLUSIONS, CAN_INVITE_TEAM_MEMBER,
  CAN_EDIT_FORM_AND_DOCUMENTATIONS, LOCK_MODEL_PROJECT_USER, INDIVIDUAL_TYPES,
  CAN_DELEGATE_ADVANCED_PERMISSIONS_MANAGEMENT, CAN_VIEW_TEAM_RESULTS, PROJECT_USER_USUAL,
} from '../constants';
import api from '../api';
import { nsOptions } from '../i18n';
import { InvitationAvatar, UserAvatar } from './avatar';
import InvitationTooltipBase from './InvitationTooltipBase';
import ProjectUserPermissionSettings from './ProjectUserPermissionSettings';
import LabeledSelect from './LabeledSelect';
import Help from './Help';
import { MessageModal } from './MessageModal';
import DropdownMenu from './NewDropdownMenu';
import NewTooltip from './NewTooltip';
import { childrenPropTypes } from '../utils/generic-prop-types';
import { hasPermission, isProjectDisabled } from '../utils/data-util';
import Toast from '../utils/Toast';
import ErrorUtil from '../utils/ErrorUtil';
import FAQLink from './FAQLink';

const MIN_GUESTS_TO_SHOW_FILTERS = 1;

export const EXTERNAL_INVITATION = 'external_invitation';
export const TEAM_INVITATION = 'team_invitation';
export const CREATE_TEAM = 0;
export const INDIVIDUALS = 0;

export const getPermissionType = (projectUser) => {
  if (hasPermission(projectUser, CAN_DELEGATE_ADVANCED_PERMISSIONS_MANAGEMENT)) {
    return 'full';
  } if (hasPermission(projectUser, CAN_MANAGE_ADVANCED_PERMISSIONS)) {
    return 'advanced';
  }
  return 'basic';
};

export const getWritablePermissions = (
  projectUser, project, teamId, invitationType, admin = false,
) => {
  if (admin) {
    return [...ADMIN_EDITABLE_PERMS, ...ADVANCED_EDITABLE_PERMS, ...BASIC_EDITABLE_PERMS];
  }
  const isManager = projectUser.user.id === project.manager.id;
  const doesNotParticipateWithThisTeamYet = invitationType === TEAM_INVITATION
    && ((teamId && !projectUser.teams.includes(teamId)) || teamId === CREATE_TEAM);
  // - Filter permissions by team if team invitation and the project user already participates
  // with the team
  // - Do not filter by team if it's the manager
  // - Do not filter by team if external invitation
  const permissions = projectUser.permissions
    .filter((perm) => invitationType === EXTERNAL_INVITATION
      || isManager || doesNotParticipateWithThisTeamYet
      || (invitationType === TEAM_INVITATION && perm.team === teamId))
    .map((perm) => perm.name);
  const extraPermissions = [];

  if (doesNotParticipateWithThisTeamYet) {
    extraPermissions.push(CAN_INVITE_TEAM_MEMBER);
    extraPermissions.push(CAN_VIEW_TEAM_RESULTS);
  }
  if (isManager) {
    // The manager can always give basic permissions
    extraPermissions.push(...BASIC_EDITABLE_PERMS);
  }
  return [...new Set([...permissions, ...extraPermissions])];
};

// eslint-disable-next-line react/prefer-stateless-function
@withTranslation('', nsOptions)
class InvitationSelect extends Component {
  static propTypes = {
    className: PropTypes.string,
    selectTextColor: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    teams: PropTypes.arrayOf(PropTypes.shape()).isRequired,
    projectUser: PropTypes.shape().isRequired,
    teamId: PropTypes.number.isRequired,
    defaultSelectValue: PropTypes.number,
    t: PropTypes.func.isRequired,
    selectClassName: PropTypes.string,
  };

  static defaultProps = {
    className: '',
    selectTextColor: undefined,
    defaultSelectValue: null,
    selectClassName: '',
  };

  render() {
    const {
      t, className, selectTextColor, teams, projectUser, teamId, onChange,
      defaultSelectValue, selectClassName,
    } = this.props;

    return (
      <LabeledSelect
        name="invitation-select"
        className={className}
        selectClassName={`${selectClassName} custom-select${selectTextColor ? ` ${selectTextColor}` : ''}`}
        defaultValue={defaultSelectValue || teamId}
        onChange={onChange}
        hideOptionalLabel
        label={t('project:participation')}
      >
        {
          teams.map((team) => (
            <option
              value={team.id}
              key={`${projectUser.id}-team-${team.id}`}
            >
              {team.name}
            </option>
          ))
        }
      </LabeledSelect>
    );
  }
}

// eslint-disable-next-line react/prefer-stateless-function
@withTranslation('', nsOptions)
class TypeSelect extends Component {
  static propTypes = {
    value: PropTypes.string.isRequired,
    rowClassName: PropTypes.string,
    colSelectClassName: PropTypes.string,
    selectClassName: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
    accountTypes: PropTypes.shape().isRequired,
  };

  static defaultProps = {
    selectClassName: '',
    rowClassName: '',
    colSelectClassName: '',
  };

  render() {
    const {
      t, selectClassName, onChange, accountTypes, rowClassName, value,
      colSelectClassName,
    } = this.props;

    return (
      <LabeledSelect
        value={value}
        rowClassName={rowClassName}
        colSelectClassName={colSelectClassName}
        name="type-select"
        onChange={onChange}
        hideOptionalLabel
        selectClassName={selectClassName}
      >
        <option value="">
          {t('project:filters.search-account-types')}
        </option>
        {
          Object.keys(accountTypes).map((type) => (
            Boolean(accountTypes[type]) && (
              <option
                value={type}
                key={type}
              >
                {t(`user:types.${type}`)}
              </option>
            )
          ))
        }
      </LabeledSelect>
    );
  }
}

function FormerParticipantAvatar(props) {
  const { formerProjectUser, teams } = props;
  const { t } = useTranslation();

  return (
    <NewTooltip
      theme="invitation"
      placement="top"
      overFlowElement="clippingParents"
      content={(
        <div className="px-1">
          <div className="font-weight-bold mb-1">
            {formerProjectUser.label}
          </div>
          {formerProjectUser.label !== formerProjectUser.email && (
            <div className="font-italic mb-1">
              {formerProjectUser.email}
            </div>
          )}
          <div className="mb-2 font-italic">
            {
              (teams.length > 1) ? (
                <>
                  <span>
                    {t('project:multi-team-participation')}
                  </span>
                  <ul className="m-0">
                    {teams.map((team) => (
                      <li
                        key={`${formerProjectUser.email}-team-${team.id}`}
                      >
                        {team.name}
                      </li>
                    ))}
                  </ul>
                </>
              ) : (
                <span>
                  {(teams.length === 1) && `${t('project:team-participation')} (${teams[0].name})`}
                  {(teams.length === 0) && t('project:individual')}
                </span>
              )
            }
          </div>
          <div className="pb-1">
            {t('project:user-has-left-the-project')}
          </div>
        </div>
      )}
    >
      <div className="mr-2 mt-1 position-relative d-inline-block" key={formerProjectUser.email}>
        <UserAvatar
          user={formerProjectUser}
          avatarProps={{ className: 'mr-1 former-participant-avatar' }}
        />
      </div>
    </NewTooltip>
  );
}

FormerParticipantAvatar.propTypes = {
  formerProjectUser: PropTypes.shape().isRequired,
  teams: PropTypes.arrayOf(PropTypes.shape()),
};

FormerParticipantAvatar.defaultProps = {
  teams: [],
};


// eslint-disable-next-line react/prefer-stateless-function
@withTranslation('', nsOptions)
class InvitationTooltip extends Component {
  static propTypes = {
    children: childrenPropTypes().isRequired,
    projectUser: PropTypes.shape().isRequired,
    project: PropTypes.shape().isRequired,
    onPermissionToggle: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
    resendInvitation: PropTypes.func.isRequired,
    cancelInvitation: PropTypes.func.isRequired,
    blockGuest: PropTypes.func.isRequired,
    removeGuest: PropTypes.func.isRequired,
    copyInvitationLink: PropTypes.func.isRequired,
    readOnly: PropTypes.bool,
    admin: PropTypes.bool,
    permissionType: PropTypes.string,
    writablePermissions: PropTypes.arrayOf(PropTypes.string),
    teamId: PropTypes.number,
    teams: PropTypes.arrayOf(PropTypes.shape()).isRequired,
    onChange: PropTypes.func.isRequired,
  };

  static defaultProps = {
    readOnly: false,
    admin: false,
    permissionType: 'basic',
    writablePermissions: [],
    teamId: undefined,
  };

  constructor(props) {
    super(props);
    this.pUserPermsRef = null;
  }

  blockGuest = async (invitation) => {
    const { blockGuest, teamId } = this.props;
    await blockGuest(invitation, teamId);
    this.pUserPermsRef.clearInternalValue();
  };

  render() {
    const {
      t, projectUser, onPermissionToggle, resendInvitation, cancelInvitation, admin,
      removeGuest, readOnly, children, permissionType, writablePermissions,
      project, teams, teamId, onChange, copyInvitationLink,
    } = this.props;
    const { confirmed, user } = projectUser;
    const permissions = projectUser.permissions.filter((perm) => (
      teamId ? perm.team === teamId : !perm.team
    )).map((perm) => perm.name);

    const isManager = project && projectUser && project.manager.id === projectUser.user.id;

    return (
      <InvitationTooltipBase
        dataLockerType={LOCK_MODEL_PROJECT_USER}
        invitation={projectUser}
        showManagement={!readOnly || admin}
        admin={admin}
        resendInvitation={resendInvitation}
        cancelInvitation={cancelInvitation}
        cancelInvitationWarning={t('project:remove-guest-warning')}
        copyInvitationLink={copyInvitationLink}
        blockGuest={this.blockGuest}
        removeGuest={removeGuest}
        removeGuestWarning={t('project:remove-guest-warning')}
        role={isManager ? t('project:manager') : null}
        extraContent={(
          <>
            {confirmed && projectUser.email && (
              <div className="font-italic mb-2">
                {projectUser.email}
              </div>
            )}
            {(!confirmed || !user) && (
              <div className="font-italic mb-2">
                {t('project:invitations.pending')}
              </div>
            )}
            {
              (teams.length > 1) && (
                <InvitationSelect
                  selectTextColor="text-gray-dark"
                  onChange={onChange}
                  teams={teams}
                  projectUser={projectUser}
                  teamId={teamId}
                  selectClassName="filters-select"
                />
              )
            }
            <span>
              {(teams.length === 1) && `${t('project:team-participation')} (${teams[0].name})`}
              {(teams.length === 0) && t('project:individual')}
            </span>
            <ProjectUserPermissionSettings
              className="project-user-permissions-tooltip"
              value={permissions}
              showTeamInvitationsPerm={Boolean(projectUser.teams && projectUser.teams.length)}
              showOnlyTypePerms={false}
              readOnly={readOnly && !admin}
              writablePermissions={writablePermissions}
              type={permissionType}
              project={project}
              onChange={onPermissionToggle}
              ref={(ref) => { this.pUserPermsRef = ref; }}
            />
          </>
        )}
      >
        { children }
      </InvitationTooltipBase>
    );
  }
}

// eslint-disable-next-line react/prefer-stateless-function
@withTranslation('', nsOptions)
class ParticipantAvatar extends Component {
  static propTypes = {
    projectUser: PropTypes.shape().isRequired,
    userProjectUser: PropTypes.shape().isRequired,
    project: PropTypes.shape().isRequired,
    teamMemberId: PropTypes.number,
    teamId: PropTypes.number,
    admin: PropTypes.bool,
    onPermissionToggle: PropTypes.func.isRequired,
    resendInvitation: PropTypes.func.isRequired,
    cancelInvitation: PropTypes.func.isRequired,
    blockGuest: PropTypes.func.isRequired,
    removeGuest: PropTypes.func.isRequired,
    copyInvitationLink: PropTypes.func.isRequired,
    teams: PropTypes.arrayOf(PropTypes.shape()).isRequired,
    teamMembers: PropTypes.arrayOf(PropTypes.shape()),
  };

  static defaultProps = {
    teamMemberId: null,
    teamId: null,
    admin: false,
    teamMembers: [],
  };

  constructor(props) {
    super(props);
    this.state = {
      effectiveTeamId: props.teamId,
      effectiveTeamMemberId: props.teamMemberId,
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.teamId !== prevProps.teamId) {
      this.fetchTeamData();
    }
  }

  onPermissionToggle = async (e) => {
    const {
      onPermissionToggle, projectUser,
    } = this.props;
    const { effectiveTeamId, effectiveTeamMemberId } = this.state;
    return onPermissionToggle(
      projectUser,
      effectiveTeamMemberId,
      effectiveTeamId,
      e.target.value,
    );
  };

  onChange = (e) => {
    const { projectUser, teamMembers } = this.props;
    const value = (e.target.value !== '0') ? Number(e.target.value) : null;
    this.setState({ effectiveTeamId: value });
    let tMberId = null;
    if (value && projectUser.team_members.length) {
      const teamMember = projectUser.team_members.find((tmMember) => (
        teamMembers.findIndex((member) => (
          member.id === tmMember.id && member.team === value
        )) >= 0
      ));
      if (teamMember) tMberId = teamMember.id;
    }
    this.setState({ effectiveTeamMemberId: tMberId });
  }

  fetchTeamData = () => {
    const { teamId, teamMemberId } = this.props;
    this.setState({
      effectiveTeamId: teamId,
      effectiveTeamMemberId: teamMemberId,
    });
  }

  removeGuest = async (invitation, teamMemberId) => {
    const { removeGuest } = this.props;
    await removeGuest(invitation, teamMemberId);
    this.fetchTeamData();
  }

  render() {
    const {
      projectUser, resendInvitation, cancelInvitation, userProjectUser, project, admin,
      blockGuest, teams, copyInvitationLink,
    } = this.props;

    const { effectiveTeamId, effectiveTeamMemberId } = this.state;

    let invitor;
    if (effectiveTeamMemberId) {
      const member = projectUser.team_members.find((mb) => mb.id === effectiveTeamMemberId);
      if (member) invitor = member.invitor;
    } else {
      invitor = projectUser.creator;
    }
    const canEditPermissions = admin || (projectUser.user.id !== project.manager.id
      && ((invitor && invitor === userProjectUser.user.id)
        || (effectiveTeamId
          && hasPermission(userProjectUser, CAN_MANAGE_TEAM_INVITATIONS, effectiveTeamId))
        || (hasPermission(userProjectUser, CAN_MANAGE_EXTERNAL_INVITATIONS))
      )
    );
    let readOnly = !admin && isProjectDisabled(project);
    if (effectiveTeamId) {
      readOnly = readOnly || (!hasPermission(
        userProjectUser,
        CAN_INVITE_TEAM_MEMBER,
        effectiveTeamId,
      ) && !hasPermission(
        userProjectUser,
        CAN_MANAGE_TEAM_INVITATIONS,
        effectiveTeamId,
      ) && !hasPermission(
        userProjectUser,
        CAN_MANAGE_EXTERNAL_INVITATIONS,
      ));
    }

    const writablePermissions = getWritablePermissions(userProjectUser,
      project, effectiveTeamId, effectiveTeamId ? TEAM_INVITATION : EXTERNAL_INVITATION, admin);

    return (
      <div className="mr-2 mt-1 position-relative d-inline-block" key={projectUser.id}>
        <InvitationTooltip
          onPermissionToggle={this.onPermissionToggle}
          projectUser={projectUser}
          project={project}
          resendInvitation={resendInvitation}
          cancelInvitation={cancelInvitation}
          blockGuest={(invitation) => blockGuest(invitation, effectiveTeamId)}
          removeGuest={(invitation) => this.removeGuest(invitation, effectiveTeamMemberId)}
          copyInvitationLink={copyInvitationLink}
          readOnly={readOnly || !canEditPermissions}
          admin={admin}
          permissionType={getPermissionType(userProjectUser)}
          writablePermissions={writablePermissions}
          teamId={effectiveTeamId}
          teams={teams}
          onChange={this.onChange}
        >
          <InvitationAvatar
            user={projectUser.user}
            email={projectUser.email}
            confirmed={projectUser.confirmed}
          />
        </InvitationTooltip>
      </div>
    );
  }
}

// eslint-disable-next-line react/prefer-stateless-function
@withTranslation('', nsOptions)
class TeamSelect extends Component {
  static propTypes = {
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    t: PropTypes.func.isRequired,
    teams: PropTypes.arrayOf(PropTypes.shape()),
    projectUsersByteam: PropTypes.shape().isRequired,
    showIndividuals: PropTypes.bool,
    onChange: PropTypes.func.isRequired,
  };

  static defaultProps = {
    teams: [],
    showIndividuals: false,
  };

  constructor(props) {
    super(props);
    this.state = {
      search: '',
    };
  }

  render() {
    const {
      t, teams, showIndividuals, onChange, projectUsersByteam, value,
    } = this.props;
    const { search } = this.state;
    const searchToLowerCase = search.toLowerCase();

    const individualsCount = (projectUsersByteam[INDIVIDUALS] || []).length;

    const noValueLabel = t('project:filters.search-teams');

    const renderItem = (id, val, label) => (
      <a
        key={id}
        className="dropdown-item"
        role="link"
        tabIndex="-1"
        onClick={() => onChange({ target: { value: val } })}
        onKeyPress={() => {}}
      >
        {label}
      </a>
    );

    const getCurrentValue = () => {
      if (value === '') return noValueLabel;
      if (value === INDIVIDUALS) return t('project:individuals');
      return teams.find((tm) => tm.id === value).name;
    };

    const renderTeam = (name) => {
      if (!search) return true;
      const nameToLowerCase = name.toLowerCase();
      if (!nameToLowerCase.includes(searchToLowerCase)) return false;
      return true;
    };

    return (
      <DropdownMenu
        type="dropdown"
        mainClass="d-inline-flex center-filter-dropdown"
        triggerElement={(
          <div className={`custom-select-sm cursor-default ${value === '' ? '' : 'active'}`}>
            {getCurrentValue()}
            <FontAwesomeIcon
              icon={['fas', 'angle-down']}
              className="text-gray cursor-pointer"
              transform="grow-3"
            />
          </div>
        )}
      >
        {renderItem('', '', noValueLabel)}
        {teams.length > 1 && (
          <div className="py-1 pr-1">
            <Input
              type="text"
              value={search}
              id="search-in-centers"
              placeholder={t('project:filters.search-teams-search-bar')}
              onChange={(e) => this.setState({ search: e.target.value })}
              className="center-filter-search-bar"
              autoComplete="off"
            />
          </div>
        )}
        {teams.filter((team) => renderTeam(team.name)).map((team) => (
          renderItem(team.id, team.id, team.name)
        ))}
        {
          showIndividuals && individualsCount && (
            renderItem(INDIVIDUALS, INDIVIDUALS, t('project:individuals'))
          )
        }
      </DropdownMenu>
    );
  }
}

@withToastManager
@withTranslation('', nsOptions)
class InvitationList extends Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
    className: PropTypes.string,
    projectUsers: PropTypes.arrayOf(PropTypes.shape()).isRequired,
    userProjectUser: PropTypes.shape(),
    user: PropTypes.shape().isRequired,
    project: PropTypes.shape().isRequired,
    teams: PropTypes.arrayOf(PropTypes.shape()),
    teamMembers: PropTypes.arrayOf(PropTypes.shape()),
    show: PropTypes.bool,
    showIndividuals: PropTypes.bool,
    canInvite: PropTypes.bool,
    admin: PropTypes.bool,
    onPermissionToggle: PropTypes.func.isRequired,
    resendInvitation: PropTypes.func.isRequired,
    cancelInvitation: PropTypes.func.isRequired,
    blockGuest: PropTypes.func.isRequired,
    removeGuest: PropTypes.func.isRequired,
    copyInvitationLink: PropTypes.func.isRequired,
  };

  static defaultProps = {
    className: '',
    teams: [],
    teamMembers: [],
    show: true,
    showIndividuals: false,
    canInvite: false,
    userProjectUser: undefined,
    admin: false,
  };

  constructor(props) {
    super(props);
    this.state = {
      search: '',
      searchRights: '',
      filteredTeams: props.teams,
      showFilters: false,
      selectedTeam: '',
      searchType: '',
    };
    this.memoizedGetProjectUsersByTeam = memoize(this.getProjectUsersByTeam);
    this.memoizedGetProjectUsers = memoize(this.getProjectUsers);
    this.memoizedGetAccountTypes = memoize(this.getAccountTypes);
    this.memoizedFilterInvitationsByLabel = memoize(this.filterInvitationsByLabel);
    this.memoizedFilterInvitationsByRights = memoize(this.filterInvitationsByRights, isEqual);
    this.memoizedFilterInvitationsByType = memoize(this.filterInvitationsByType);
  }

  componentDidUpdate(prevProps) {
    let resetSelectedTeam = null;
    if (prevProps.teams.length < this.props.teams.length) {
      resetSelectedTeam = false;
    } else {
      const prevProjectUsersByTeams = this.memoizedGetProjectUsersByTeam(
        prevProps.teams,
        prevProps.projectUsers,
        prevProps.userProjectUser,
        [],
      );
      const projectUsersByTeams = this.memoizedGetProjectUsersByTeam(
        this.props.teams,
        this.props.projectUsers,
        this.props.userProjectUser,
        [],
      );
      if ((prevProps.teams.length > this.props.teams.length)
        || ((prevProjectUsersByTeams[INDIVIDUALS].length > 0)
          && (projectUsersByTeams[INDIVIDUALS].length === 0))) {
        resetSelectedTeam = true;
      }
    }
    if (resetSelectedTeam !== null) this.fetchTeamsData(resetSelectedTeam);
    if (prevProps.projectUsers.length > this.props.projectUsers.length) {
      const prevAccountTypes = this.memoizedGetAccountTypes(
        this.memoizedGetProjectUsers(
          prevProps.teams,
          prevProps.projectUsers,
          prevProps.userProjectUser,
          [],
          '',
        ),
      );
      const accountTypes = this.memoizedGetAccountTypes(
        this.memoizedGetProjectUsers(
          this.props.teams,
          this.props.projectUsers,
          this.props.userProjectUser,
          [],
          '',
        ),
      );
      const resetSelectedType = Object.keys(accountTypes).some(
        (type) => ((accountTypes[type] === 0) && (prevAccountTypes[type] > 0)),
      );
      if (resetSelectedType) this.fetchTypeData();
    }
  }

  fetchTeamsData = (resetSelectedTeam) => {
    const { teams } = this.props;
    if (resetSelectedTeam) {
      this.setState({ filteredTeams: teams, selectedTeam: '' });
    } else {
      this.setState({ filteredTeams: teams });
    }
  }

  fetchTypeData = () => {
    this.setState({ searchType: '' });
  }

  getProjectUsersByTeam = (teams, projectUsers, projectUser, formerParticipants) => {
    const { project, admin } = this.props;
    const res = {};

    const isOwner = projectUser && project && project.owner.id === projectUser.user.id;

    if (teams && projectUsers) {
      // Individuals
      const canManageExternalInvitations = hasPermission(
        projectUser,
        CAN_MANAGE_EXTERNAL_INVITATIONS,
      );
      res[INDIVIDUALS] = projectUsers.filter((pUser) => (
        pUser.teams.length === 0 && (
          canManageExternalInvitations || (projectUser && pUser.creator === projectUser.user.id)
          || isOwner || admin
        )
      ));
      if (canManageExternalInvitations) {
        res[INDIVIDUALS] = res[INDIVIDUALS].concat(
          formerParticipants.filter((part) => !part.teams || !part.teams.length),
        );
      }

      // Teams
      teams.forEach((team) => {
        res[team.id] = projectUsers.filter((pUser) => (
          pUser.teams.find((tmId) => tmId === team.id)
        ));
        res[team.id] = res[team.id].concat(
          formerParticipants.filter((part) => part.teams && part.teams.includes(team.id)),
        );
      });
    }

    return res;
  };

  getProjectUsers = (teams, projectUsers, projectUser, formerParticipants, selectedTeam) => {
    const { project, admin } = this.props;
    let res = {};

    const isOwner = projectUser && project && project.owner.id === projectUser.user.id;

    if (projectUsers) {
      const canManageExternalInvitations = hasPermission(
        projectUser,
        CAN_MANAGE_EXTERNAL_INVITATIONS,
      );
      const teamFilter = (pUser, teamsIds) => (
        Boolean(pUser.teams.filter((tm) => teamsIds.includes(tm)).length)
      );
      const individualFilter = (pUser) => (
        pUser.teams.length === 0 && (
          canManageExternalInvitations || (projectUser && pUser.creator === projectUser.user.id)
            || isOwner || admin
        )
      );
      const evaluateFormerParticipants = Boolean(formerParticipants.length);
      if (teams && teams.length && (selectedTeam !== INDIVIDUALS.toString())) {
        const teamsIds = teams.map((team) => team.id);
        if (selectedTeam === '') {
          res = projectUsers ? projectUsers.filter((pUser) => (
            teamFilter(pUser, teamsIds) || individualFilter(pUser)
          )) : [];
          if (evaluateFormerParticipants) {
            res = res.concat(formerParticipants.filter((part) => (
              teamFilter(part, teamsIds) || individualFilter(part)
            )));
          }
        } else {
          res = projectUsers ? projectUsers.filter((pUser) => teamFilter(pUser, teamsIds)) : [];
          if (evaluateFormerParticipants) {
            res = res.concat(formerParticipants.filter((part) => teamFilter(part, teamsIds)));
          }
        }
      } else {
        res = projectUsers.filter((pUser) => individualFilter(pUser));
        if (evaluateFormerParticipants) {
          res = res.concat(formerParticipants.filter((part) => individualFilter(part)));
        }
      }
    }
    return res;
  }

  getAccountTypes = (projectUsers) => {
    const res = {};
    INDIVIDUAL_TYPES.forEach((type) => {
      res[type] = projectUsers.filter(
        (pUser) => (pUser.confirmed && pUser.user.type === type),
      ).length;
    });
    return res;
  }

  handleSearch = (event) => {
    const { value } = event.target;
    this.setState({ search: value });
    clearTimeout(this.searchDelay);
    this.searchDelay = setTimeout(() => {
    }, 250);
  };

  handleSearchRights = (event) => {
    const { value } = event.target;
    this.setState({ searchRights: value });
    clearTimeout(this.searchDelay);
    this.searchDelay = setTimeout(() => {
    }, 250);
  };

  handleSearchTeams = (event) => {
    const { teams } = this.props;
    const { value } = event.target;
    switch (value) {
      case '':
        this.setState({ filteredTeams: teams, selectedTeam: value });
        break;
      case (INDIVIDUALS):
        this.setState({ filteredTeams: null, selectedTeam: value });
        break;
      default:
        this.setState({
          filteredTeams: [teams.find((tm) => tm.id === Number(value))],
          selectedTeam: value,
        });
    }
    clearTimeout(this.searchDelay);
    this.searchDelay = setTimeout(() => {
    }, 250);
  }

  handleSearchTypes = (event) => {
    const { value } = event.target;
    this.setState({ searchType: value });
    clearTimeout(this.searchDelay);
    this.searchDelay = setTimeout(() => {
    }, 250);
  }

  filterInvitationsByLabel = (filteredProjectUsers, search) => {
    const newFilteredPUsers = filteredProjectUsers.filter((pUser) => {
      if (pUser.formerProjectUser) {
        return pUser.label.toLowerCase().includes(search.toLowerCase());
      }
      const matchEmail = pUser.email.toLowerCase().includes(search.toLowerCase());
      return matchEmail || (pUser.user.type && (
        pUser.user.label.toLowerCase().includes(search.toLowerCase()))
      );
    });
    return newFilteredPUsers;
  }

  filterInvitationsByRights = (perms, filteredProjectUsers, searchRights) => {
    let newFilteredPUsers = [];

    if (searchRights === 'none') {
      newFilteredPUsers = filteredProjectUsers.filter((pUser) => !pUser.formerProjectUser
        && ((pUser.teams && pUser.teams.length > 1) || (
          !perms.some((right) => (
            Object.values(pUser.permissions.map((perm) => perm.name)).includes(right))))));
    } else {
      newFilteredPUsers = filteredProjectUsers.filter((pUser) => !pUser.formerProjectUser && (
        Object.values(pUser.permissions.map((perm) => perm.name)).includes(searchRights)
      ));
    }
    return newFilteredPUsers;
  }

  filterInvitationsByType = (filteredProjectUsers, searchType) => {
    const newFilteredPUsers = filteredProjectUsers.filter(
      (pUser) => (!pUser.formerProjectUser && pUser.confirmed && pUser.user.type === searchType),
    );
    return newFilteredPUsers;
  }

  getActiveFiltersCount = () => {
    const {
      searchRights, search, searchType, selectedTeam,
    } = this.state;
    let filters = [searchRights, search, searchType, selectedTeam];
    filters = filters.map((filter) => {
      if (filter || filter !== '') {
        return 1;
      }
      return 0;
    });
    const res = filters.reduce((a, b) => a + b, 0);
    return res;
  }

  resetFilters = () => {
    const { teams } = this.props;
    this.setState({
      search: '',
      searchRights: '',
      selectedTeam: '',
      searchType: '',
      filteredTeams: teams,
    });
  }

  reinviteAll = async () => {
    const { project, admin, t } = this.props;
    try {
      const res = await api.create('bulk-reinvite-in-project', { project: project.id }, { admin });
      Toast.success(
        this.props,
        t('project:invitations.reinvitation-success', {
          count: res.successed.length,
          ignoredInfo: res.ignored.length ? res.ignored.join(', ') : '0',
        }),
        8000,
        true,
      );
    } catch (error) {
      ErrorUtil.handleCatched(this.props, error);
    }
  };

  render() {
    const {
      t, teams, resendInvitation, cancelInvitation, projectUsers,
      showIndividuals, show, className, onPermissionToggle, userProjectUser,
      project, admin, blockGuest, removeGuest, teamMembers, canInvite, user, copyInvitationLink,
    } = this.props;
    const {
      filteredTeams, showFilters, search, searchRights, selectedTeam, searchType,
    } = this.state;
    const individuals = projectUsers.filter((pUser) => pUser.teams.length === 0);

    const formerParticipants = project ? project.deleted_users_with_inclusions.map(
      (part) => ({
        email: part.creator_email,
        label: part.creator_name || part.creator_email,
        teams: part.teams,
        formerProjectUser: true,
      }),
    ) : [];
    const deletedIndividuals = project ? formerParticipants.filter(
      (part) => !part.teams || !part.teams.length,
    ) : [];

    const perms = [...ADMIN_EDITABLE_PERMS, ...BASIC_EDITABLE_PERMS, ...ADVANCED_EDITABLE_PERMS];

    const writablePerms = [
      {
        text: t('project:permissions.can-include-patients'),
        value: CAN_INCLUDE,
      }, {
        text: t('project:permissions.can-manage-team-invitations-filter'),
        value: CAN_INVITE_TEAM_MEMBER,
      }, {
        text: t('project:permissions.can-view-team-results'),
        value: CAN_VIEW_TEAM_RESULTS,
      }, {
        text: t('project:permissions.can-view-external-inclusions'),
        value: CAN_VIEW_EXTERNAL_INCLUSIONS,
      }, {
        text: t('project:permissions.can-edit-form-and-docs'),
        value: CAN_EDIT_FORM_AND_DOCUMENTATIONS,
      }, {
        text: t('project:permissions.can-invite-external'),
        value: CAN_INVITE_EXTERNAL,
      }, {
        text: t('project:permissions.can-manage-advanced-permissions'),
        value: CAN_MANAGE_ADVANCED_PERMISSIONS,
      },
    ];

    const accountTypes = this.memoizedGetAccountTypes(
      this.memoizedGetProjectUsers(teams, projectUsers, userProjectUser, ''),
    );

    let filteredProjectUsers = this.memoizedGetProjectUsers(
      filteredTeams,
      projectUsers,
      userProjectUser,
      formerParticipants,
      selectedTeam,
    );

    const projectUsersByteam = this.memoizedGetProjectUsersByTeam(
      teams,
      projectUsers,
      userProjectUser,
      formerParticipants,
    );

    if (search !== '') {
      filteredProjectUsers = this.memoizedFilterInvitationsByLabel(
        filteredProjectUsers, search,
      );
    }
    if (searchRights !== '') {
      filteredProjectUsers = this.memoizedFilterInvitationsByRights(
        perms,
        filteredProjectUsers,
        searchRights,
      );
    }
    if (searchType !== '') {
      filteredProjectUsers = this.memoizedFilterInvitationsByType(
        filteredProjectUsers,
        searchType,
      );
    }

    const isOwner = project && userProjectUser && project.owner.id === userProjectUser.user.id;

    const countTeamSelect = teams.length + (showIndividuals ? 1 : 0);
    const invitationsCount = Object.values(projectUsersByteam).reduce((acc, val) => (
      acc + val.length), 0);
    let displayedUsers = filteredProjectUsers.length;
    const showTeamsFilter = teams && (teams.length > 1 || (teams.length > 0
      && showIndividuals && (Boolean(individuals.length) || Boolean(deletedIndividuals.lenght))));
    const showTypesFilter = Object.keys(accountTypes).filter(
      (type) => accountTypes[type] > 0,
    ).length > 1;
    const activeFiltersCount = this.getActiveFiltersCount();
    const investigatorsCount = project.investigators_count;
    const maxInvestigators = project.limitations.max_investigators_per_project;
    const showInvestigatorsInfo = (canInvite || isOwner || admin)
      && Boolean(investigatorsCount || maxInvestigators);

    return show && (countTeamSelect > 1 || invitationsCount > 0) && (
      <div className={className}>
        {showInvestigatorsInfo && (
          <div className="row">
            <div className="col">
              <Label className="mb-2">
                <span className="font-weight-bold">
                  {`${t('project:invitations.remaining')}${t('common:colon')} `}
                </span>
                <span className={`font-weight-bold${investigatorsCount <= maxInvestigators ? '' : ' text-red'}`}>
                  {`${investigatorsCount}/${maxInvestigators}`}
                </span>
                <Help iconClassName="ml-2" interactive>
                  <span>
                    {t('project:invitations.remaining-help-1')}
                  </span>
                  <FAQLink
                    articleId={4}
                    text={t('common:help-center')}
                    linkClassName="classical-link text-newblue-1"
                  />
                  .
                  {user.id === project.manager.id && (
                    <>
                      <br />
                      <span>
                        {t('project:invitations.remaining-help-2')}
                      </span>
                      <Link
                        className="classical-link text-newblue-1"
                        to="/dashboard/settings/license/"
                      >
                        {t('project:invitations.remaining-help-3')}
                      </Link>
                      <span>
                        {t('project:invitations.remaining-help-4')}
                      </span>
                    </>
                  )}
                </Help>
              </Label>
            </div>
          </div>
        )}
        <div className="row">
          <div className="col">
            <Label className="mb-2">
              <span>
                {t('project:invitations.list', { count: invitationsCount })}
              </span>
              <Help iconClassName="ml-2">
                {t('project:invitations.list-help')}
              </Help>
            </Label>
          </div>
        </div>
        {
          (invitationsCount > MIN_GUESTS_TO_SHOW_FILTERS) && (
            <div className="row">
              <div className={`col ${!showFilters ? 'pb-2' : ''}`}>
                <a
                  className="small text-newblue-1 font-italic"
                  key="show-filters"
                  role="button"
                  onClick={() => {
                    this.setState({ showFilters: !showFilters });
                  }}
                  onKeyPress={() => {}}
                  tabIndex={0}
                >
                  {t(`project:filters.${!showFilters ? 'toggle-on' : 'toggle-off'}`)}
                </a>
                {
                  showFilters && Boolean(activeFiltersCount) && (
                    <a
                      className="small text-newblue-1 font-italic ml-2"
                      key="reset-filters"
                      role="button"
                      onClick={() => {
                        this.resetFilters();
                      }}
                      onKeyPress={() => {}}
                      tabIndex={0}
                    >
                      {t('project:filters.reset')}
                    </a>
                  )
                }
                {
                  !showFilters && (
                    <span className="small ml-2">
                      {t('project:filters.active-filters-count', { count: activeFiltersCount })}
                    </span>
                  )
                }
              </div>
            </div>
          )
        }
        {
          showFilters && (invitationsCount > MIN_GUESTS_TO_SHOW_FILTERS) && (
            <div className="row">
              <div className="col-auto pt-2">
                <Input
                  value={search}
                  type="text"
                  id="projectse-input"
                  className={`d-inline-block text-gray-dark font-italic ${search ? 'filter-active' : ''}`}
                  placeholder={t('project:filters.search-guest')}
                  style={{
                    width: '200px',
                    fontSize: '75%',
                    height: '30px',
                    borderRadius: 0,
                  }}
                  onChange={this.handleSearch}
                  bsSize="sm"
                />
              </div>
              <div className="col-auto pt-2">
                <LabeledSelect
                  value={searchRights}
                  rowClassName="row"
                  colSelectClassName="col-auto"
                  selectClassName={`font-italic text-gray-dark custom-select-sm filters-select ${searchRights ? 'filter-active' : ''}`}
                  name="rights"
                  hideOptionalLabel
                  onChange={this.handleSearchRights}
                >
                  <option value="">
                    {t('project:filters.search-rights.all')}
                  </option>
                  <option value="none">
                    {t('project:filters.search-rights.none')}
                  </option>
                  {
                    writablePerms.map((perm) => (
                      <option value={perm.value} key={perm.value}>
                        {perm.text}
                      </option>
                    ))
                  }
                </LabeledSelect>
              </div>
              <div className={(showTeamsFilter && showTypesFilter) ? 'w-100' : 'd-none'} />
              {
                showTeamsFilter && (
                  <div className="col-auto pt-2">
                    <TeamSelect
                      value={selectedTeam}
                      teams={teams}
                      projectUsersByteam={projectUsersByteam}
                      showIndividuals={showIndividuals
                        && (Boolean(individuals.length) || Boolean(deletedIndividuals.length))}
                      onChange={this.handleSearchTeams}
                    />
                  </div>
                )
              }
              {
                showTypesFilter && (
                  <div className="col-auto pt-2">
                    <TypeSelect
                      value={searchType}
                      rowClassName="row"
                      colSelectClassName="col-auto"
                      selectClassName={`font-italic text-gray-dark custom-select-sm filters-select ${searchType ? 'filter-active' : ''}`}
                      accountTypes={accountTypes}
                      onChange={this.handleSearchTypes}
                    />
                  </div>
                )
              }
            </div>
          )
        }
        <div className="row">
          <div className="col-12 col-sm">
            {
              filteredProjectUsers.length > 0 ? filteredProjectUsers.map((pUser) => {
                /* We only keep the projectUser teams that the userProjectUser is
                  authorized to see */
                const { formerProjectUser } = pUser;
                let displayUser = true;
                let viewableTeams = (filteredTeams && pUser.teams
                  && filteredTeams.filter((tm) => pUser.teams.includes(tm.id))) || [];
                // Handle multi-team filtering by rights
                if (searchRights !== '' && viewableTeams.length > 0) {
                  if (!formerProjectUser) {
                    let filteredTeamsByRights = [];
                    if (searchRights !== 'none') {
                      filteredTeamsByRights = pUser.permissions.filter(
                        (perm) => perm.name === searchRights,
                      ).map((perm) => perm.team);
                      viewableTeams = viewableTeams.filter(
                        (team) => filteredTeamsByRights.includes(team.id),
                      );
                    } else {
                      viewableTeams = viewableTeams.filter(
                        (team) => perms.every(
                          (right) => !pUser.permissions.filter(
                            (perm) => perm.team === team.id,
                          ).map((perm) => perm.name).includes(right),
                        ),
                      );
                    }
                    displayUser = !(viewableTeams.length === 0);
                  } else {
                    // Cannot filter a former project user on their rights
                    // They doesn't have rights anymore
                    displayUser = false;
                  }
                }
                const teamId = (viewableTeams.length > 0) ? viewableTeams[0].id : null;

                let teamMemberId = null;
                if (teamId && !formerProjectUser) {
                  const teamMember = pUser.team_members.find((tmMember) => (
                    teamMembers.findIndex((member) => (
                      member.id === tmMember.id && member.team === teamId
                    )) >= 0
                  ));
                  if (teamMember) teamMemberId = teamMember.id;
                }
                if (!displayUser) {
                  displayedUsers -= 1;
                }
                // moved readOnly calculation so that it's calculated for all invitations
                return (
                  (displayedUsers > 0)
                    ? (
                      <span
                        key={pUser.formerProjectUser ? pUser.email : pUser.id}
                      >
                        {(displayUser && !formerProjectUser) && (
                          <ParticipantAvatar
                            key={pUser.id}
                            projectUser={pUser}
                            userProjectUser={userProjectUser}
                            project={project}
                            teamMemberId={teamMemberId}
                            teamId={teamId}
                            teamMembers={teamMembers}
                            admin={admin}
                            onPermissionToggle={onPermissionToggle}
                            resendInvitation={resendInvitation}
                            cancelInvitation={cancelInvitation}
                            blockGuest={blockGuest}
                            removeGuest={removeGuest}
                            copyInvitationLink={copyInvitationLink}
                            teams={viewableTeams}
                          />
                        )}
                        {(displayUser && formerProjectUser) && (
                          <FormerParticipantAvatar
                            key={`former-participant-${pUser.email}`}
                            formerProjectUser={pUser}
                            teams={viewableTeams}
                          />
                        )}
                      </span>
                    ) : (
                      <div className="font-italic text-gray pt-1 small" key={`${pUser.id}-hidden`}>
                        {t('project:invitations.none')}
                      </div>
                    )
                );
              }) : (
                <div className="font-italic text-gray pt-1 small">
                  {t('project:invitations.none')}
                </div>
              )
            }
          </div>
        </div>
        {(admin || (userProjectUser && userProjectUser.type === PROJECT_USER_USUAL)) && (
          <div className="row">
            <div className="col-12 pt-2 pb-2">
              <MessageModal
                message={(
                  <span>
                    <b>
                      {t('common:caution')}
                      &nbsp;!
                    </b>
                    <br />
                    <br />
                    {t('error:warning.reinvite-all')}
                  </span>
                )}
                validateLabel="common:button.confirm"
                validateBtnBgColorClass="btn-newred-2"
                showCancelButton
                modalBodyClassName="text-center"
                onValidate={this.reinviteAll}
              >
                <a
                  className="small font-italic"
                  disabled={projectUsers.every((projectUser) => projectUser.confirmed)}
                >
                  {t('project:invitations:reinvite')}
                </a>
              </MessageModal>
            </div>
          </div>
        )}
      </div>
    );
  }
}


export default InvitationList;
