import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { Mutex } from 'async-mutex';
import '../assets/css/confirm.css';
import { nsOptions } from '../i18n';
import { childrenPropTypes } from '../utils/generic-prop-types';


@withTranslation('', nsOptions)
class ButtonConfirm extends Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
    children: childrenPropTypes().isRequired,
    onClick: PropTypes.func.isRequired,
    onFirstClick: PropTypes.func,
    onTimeout: PropTypes.func,
    className: PropTypes.string,
  };

  static defaultProps = {
    onFirstClick: () => {},
    onTimeout: () => {},
    className: 'button-confirm',
  };

  constructor(props) {
    super(props);
    this.child = null;
    this.mutex = new Mutex();
    this.confirmed = false;
    this.firstChild = null;
  }

  componentDidMount() {
    this.firstChild = this.child.firstChild;
  }

  confirm = async (e) => {
    if (this.mutex.isLocked()) return;

    const {
      t, onFirstClick, onTimeout, className,
    } = this.props;
    const target = e.currentTarget;
    const release = await this.mutex.acquire();

    if (onFirstClick) onFirstClick();

    target.textContent = t('common:button.click-again');
    target.classList.add(className);
    target.removeEventListener('click', this.confirm);
    target.addEventListener('click', this.perform);
    this.confirmed = true;

    setTimeout(() => {
      this.confirmed = false;
      target.textContent = '';
      target.appendChild(this.firstChild);
      target.classList.remove(className);
      target.removeEventListener('click', this.perform);
      target.addEventListener('click', this.confirm);
      if (onTimeout) onTimeout();
      release();
    }, 3000);
  };

  perform = (e) => {
    if (this.confirmed) this.props.onClick(e);
  }

  render() {
    return React.cloneElement(this.props.children, {
      onClick: this.confirm,
      ref: (d) => { this.child = d; },
    });
  }
}


export default ButtonConfirm;
