import { Component } from 'react';
import { isBrowser, isTablet } from 'react-device-detect';
import { withTranslation } from 'react-i18next';
import { nsOptions } from '../i18n';
import { childrenPropTypes } from '../utils/generic-prop-types';

export const isMixedView = () => isBrowser || isTablet;

@withTranslation('', nsOptions)
class MixedView extends Component {
  static propTypes = {
    children: childrenPropTypes().isRequired,
  };

  render() {
    return isMixedView() ? this.props.children : null;
  }
}


export default MixedView;
