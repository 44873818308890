import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { useTranslation, Trans } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import NewModal from './NewModal';
import { childrenPropTypes } from '../utils/generic-prop-types';
import { PROJECT_ON_HOLD } from '../constants';
import NewTooltip from './NewTooltip';


export function RegulatoryConfirmationIcon(props) {
  const { projectStatus, confirmed, className } = props;
  const { t } = useTranslation();

  let iconColor;
  let tooltipText = t('project:regulatory-confirmation.open-modal');
  if (projectStatus === PROJECT_ON_HOLD) {
    iconColor = 'text-gray';
    tooltipText = t('project:regulatory-confirmation.project-on-hold-help');
  } else if (confirmed) {
    iconColor = 'text-green';
  } else {
    iconColor = 'text-red';
  }

  return (
    <NewTooltip
      content={tooltipText}
      theme="light"
    >
      <FontAwesomeIcon
        icon={['fas', 'file-certificate']}
        className={`${iconColor} ${className}`}
        transform="grow-2"
      />
    </NewTooltip>
  );
}

RegulatoryConfirmationIcon.propTypes = {
  projectStatus: PropTypes.string.isRequired,
  confirmed: PropTypes.bool.isRequired,
  className: PropTypes.string,
};

RegulatoryConfirmationIcon.defaultProps = {
  className: '',
};

function RegulatoryConfirmationModal(props) {
  const {
    confirmed, children, onValidate, triggerFuncName, triggerCondition,
  } = props;
  const { t } = useTranslation();

  const modalRef = useRef(undefined);

  const onCancel = () => {
    if (modalRef.current) modalRef.current.hide();
  };

  return (
    <NewModal
      ref={modalRef}
      title={(
        <div>
          <FontAwesomeIcon
            icon={['fas', 'file-certificate']}
            className="mr-2"
          />
          {t(`project:regulatory-confirmation.${confirmed ? 'confirmed' : 'must-confirm'}-title`)}
        </div>
      )}
      size="sm"
      trigger={children}
      footer={confirmed ? null : (
        <div className="row">
          <button
            type="button"
            className="btn btn-newblue-1 text-white px-3"
            onClick={() => {
              onValidate();
              if (modalRef.current) modalRef.current.hide();
            }}
          >
            {t('common:button.i-confirm')}
          </button>
          <button
            type="button"
            className="btn btn-link ml-2"
            onClick={onCancel}
          >
            {t('common:button.cancel')}
          </button>
        </div>
      )}
      triggerFuncName={triggerFuncName}
      triggerCondition={triggerCondition}
      extraClass={`regulatory-approval-modal ${confirmed ? 'confirmed' : ''}`}
    >
      <div>
        <Trans i18nKey={`project:regulatory-confirmation.${confirmed ? 'confirmed' : 'must-confirm'}`} />
      </div>
    </NewModal>
  );
}

RegulatoryConfirmationModal.propTypes = {
  confirmed: PropTypes.bool.isRequired,
  children: childrenPropTypes().isRequired,
  onValidate: PropTypes.func,
  triggerFuncName: PropTypes.string,
  triggerCondition: PropTypes.func,
};

RegulatoryConfirmationModal.defaultProps = {
  onValidate: () => {},
  triggerFuncName: 'onClick',
  triggerCondition: () => true,
};

export default RegulatoryConfirmationModal;
