import { createReducer } from 'redux-starter-kit';
import { inclusionsActions } from '../actions';
import getStdDbModelReduxMapping from './std-db-model-reducers';

export const inclusionsInitialState = {};

const inclusionsReducer = createReducer(
  inclusionsInitialState,
  getStdDbModelReduxMapping(inclusionsActions),
);

export default inclusionsReducer;
