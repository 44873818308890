import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import Select from 'react-select';
import memoize from 'memoize-one';
import { nsOptions } from '../i18n';
import { MessageModal } from './MessageModal';
import LabeledChoice from './LabeledChoice';
import NewTooltip from './NewTooltip';
import Validator, { GUI_EFFECT_PARTIAL } from './Validator';
import { USER_TYPE_PRACTITIONER } from '../constants';

export const PERSONAL_PROJECT = 0;
const TEAM_PROJECT = 1;

@withTranslation('', nsOptions)
class ProjectTeamZone extends Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
    teams: PropTypes.arrayOf(PropTypes.shape()),
    teamLicenses: PropTypes.arrayOf(PropTypes.shape()).isRequired,
    userLicenses: PropTypes.arrayOf(PropTypes.shape()).isRequired,
    user: PropTypes.shape().isRequired,
    admin: PropTypes.bool,
    canEdit: PropTypes.bool,
    setLicenseIncompatibleModalRef: PropTypes.func,
    value: PropTypes.number,
    creation: PropTypes.bool,
    project: PropTypes.shape(),
    onChange: PropTypes.func,
    onCancel: PropTypes.func,
    disabled: PropTypes.bool,
  };

  static defaultProps = {
    teams: undefined,
    admin: false,
    canEdit: false,
    value: undefined,
    creation: false,
    project: undefined,
    disabled: false,
    setLicenseIncompatibleModalRef: () => {},
    onChange: () => {},
    onCancel: () => {},
  };

  static toRawValue = (value) => {
    const val = Number(value);
    return val || null;
  }

  constructor(props) {
    super(props);
    this.state = {
      projectType: null,
    };
    this.typeSelect = null;
    this.teamSelect = null;
    this.getTeamOptionsDOM = memoize(this.loadTeamOptions);
    this.memoizedFilterTeams = memoize(this.filterTeams);
    this.memoizedGetEffectiveUser = memoize(this.getEffectiveUser);
  }

  componentDidUpdate(prevProps) {
    const { admin, value } = this.props;
    if (admin && prevProps.value && !value) {
      if (this.selectRef) {
        this.selectRef.clearValue();
      }
    }
  }

  get effectiveTeams() {
    const { teams, value } = this.props;
    if (teams && teams.length) {
      return this.memoizedFilterTeams(teams, value);
    }
    return [];
  }

  filterTeams = (teams, value) => (
    teams.filter((tm) => (
      tm.id === value || (Number(tm.limitations.max_project_creations) > 0 && !tm.is_frozen)))
  );

  getEffectiveUser = (user, project, creation) => {
    if (!creation && project) return project.owner;
    return user;
  };

  loadTeamOptions = (teams) => {
    const { t, teamLicenses } = this.props;
    return teams ? teams
      .map((tm) => {
        const license = teamLicenses.find((lic) => lic.id === tm.licence);
        let licenseNameInfo = '';
        if (license) {
          licenseNameInfo = ` (${t(`user:licenses.team-${license.key}-license`)})`;
        }
        return {
          label: `${tm.name}${licenseNameInfo}`,
          value: tm.id,
        };
      }) : [];
  };

  onTypeChange = async (e) => {
    const { onChange } = this.props;
    const value = Number(e.target.value);
    await new Promise((resolve) => this.setState({ projectType: value }, () => resolve()));
    if (value === PERSONAL_PROJECT) await onChange(null);
    else if (this.effectiveTeams.length === 1) await onChange(this.effectiveTeams[0].id);
    this.validate(this.props.value);
  };

  onTeamChange = async (e) => {
    const { onChange } = this.props;
    const newValue = ProjectTeamZone.toRawValue(e.target.value);
    await onChange(newValue);
    if (!newValue) this.setState({ projectType: PERSONAL_PROJECT });
    this.validate(newValue);
  };

  validate = (value) => {
    if (this.getEffectiveProjectType(value) === PERSONAL_PROJECT) return true;
    const typeValid = this.typeSelect && this.typeSelect.validate(value, GUI_EFFECT_PARTIAL);
    const teamValid = this.teamSelect && this.teamSelect.validate(value, GUI_EFFECT_PARTIAL);
    return typeValid && teamValid;
  }

  getEffectiveProjectType = (value) => {
    const { user, project, creation } = this.props;
    const { projectType } = this.state;

    const effectiveUser = this.memoizedGetEffectiveUser(user, project, creation);

    const {
      can_join_team: canJoinTeam, can_create_team: canCreateTeam,
      can_manage_project: canManageProject,
    } = effectiveUser.limitations;

    if (!project) {
      // Cannot create a team project
      if (!canCreateTeam && !canJoinTeam) return PERSONAL_PROJECT;

      // Cannot create a personal project
      // (for the moment, a user cannot delegate management in a personal project)
      if (!canManageProject) return TEAM_PROJECT;
    }

    let effectiveProjectType = projectType;

    if (!projectType || value) {
      switch (value) {
        case undefined:
          break;

        case null:
          effectiveProjectType = PERSONAL_PROJECT;
          break;

        default:
          effectiveProjectType = TEAM_PROJECT;
          break;
      }
    }

    return effectiveProjectType;
  }

  renderViewAccountLink = (isPersonalProject) => {
    const { t } = this.props;

    return (
      <Link
        className={`small text-newblue-1 font-italic ${isPersonalProject ? 'row mt-2 ml-1' : ''}`}
        to="/dashboard/settings/account/"
      >
        {t('project:view-account')}
      </Link>
    );
  }

  getLabeledChoiceClassName = (isPersonalProject, creation) => {
    let className = '';
    if (isPersonalProject) {
      className += ' mb-0';
    }

    if (!creation) {
      className += ' col-auto pr-0';
    }

    return className;
  }

  getLicenseInfo = () => {
    const {
      userLicenses, user, project, creation,
    } = this.props;
    const effectiveUser = this.memoizedGetEffectiveUser(user, project, creation);
    const license = userLicenses.find((lic) => effectiveUser.groups.includes(lic.id));
    const res = license && effectiveUser.type === USER_TYPE_PRACTITIONER ? ` (${license.key.toUpperCase()})` : '';
    return res;
  }

  render() {
    const {
      t, admin, canEdit, setLicenseIncompatibleModalRef, value, onCancel,
      onChange, creation, user, project, disabled,
    } = this.props;
    const effectiveProjectType = this.getEffectiveProjectType(value);
    const isPersonalProject = effectiveProjectType !== TEAM_PROJECT;

    const effectiveUser = this.memoizedGetEffectiveUser(user, project, creation);
    const {
      can_join_team: canJoinTeam, can_create_team: canCreateTeam,
      can_manage_project: canManageProject, can_own_licence_products: canOwnLicenceProducts,
    } = effectiveUser.limitations;

    const teamOptions = this.getTeamOptionsDOM(this.effectiveTeams);

    const defaultTeam = value ? teamOptions.find((teamOption) => Number(teamOption.value) === value)
      : undefined;

    const disableProjectTypeChoice = (!canCreateTeam && !canJoinTeam) || !canManageProject;

    // Do not hide for other accounts event if disableProjectTypeChoice === true
    const hideProjectType = !admin && canOwnLicenceProducts && disableProjectTypeChoice;

    return (
      <>
        <MessageModal
          message={(
            <span>
              <b>
                {t('common:caution')}
                &nbsp;!
              </b>
              <br />
              <br />
              {t('project:license-incompatible')}
            </span>
          )}
          validateLabel="common:button.confirm"
          showCancelButton
          onValidate={() => onChange(value, true)}
          onCancel={onCancel}
          ref={(ref) => setLicenseIncompatibleModalRef(ref)}
          modalBodyClassName="text-center"
        />
        {/* Visually hide the project type select if the user is a facility account */}
        <div
          className={`${creation ? '' : 'row align-items-center'} ${hideProjectType ? 'visually-hidden' : ''}`}
        >
          <LabeledChoice
            className={this.getLabeledChoiceClassName(isPersonalProject, creation)}
            name="project-type"
            value={effectiveProjectType}
            disabled={disabled
              || (!admin && ((!canCreateTeam && !canJoinTeam) || !canManageProject))}
            required
            validatorGuiEffect={GUI_EFFECT_PARTIAL}
            choices={[
              {
                text: (
                  <NewTooltip
                    interactive
                    content={(
                      <div>
                        {t('project:type.personal-info.part-1')}
                        <strong>
                          {t('project:type.personal-info.plan-1')}
                        </strong>
                        {t('project:type.personal-info.part-2')}
                        <br />
                        {t('project:type.personal-info.part-3')}
                        <strong>
                          {t('project:type.personal-info.plan-2')}
                        </strong>
                        {t('project:type.personal-info.part-4')}
                        <strong>
                          {t('project:type.personal-info.plan-3')}
                        </strong>
                        {t('project:type.personal-info.part-5')}
                      </div>
                    )}
                    theme="light"
                  >
                    <span className="dotted-underline-gray">
                      { `${t('project:type.personal')}${this.getLicenseInfo()}` }
                    </span>
                  </NewTooltip>
                ),
                value: PERSONAL_PROJECT,
              },
              {
                text: (
                  <NewTooltip
                    interactive
                    content={(
                      <div>
                        {t('project:type.team-info.part-1')}
                        <strong>
                          {t('project:type.team-info.plan-1')}
                        </strong>
                        {t('project:type.team-info.part-2')}
                        <br />
                        {t('project:type.team-info.part-3')}
                        <strong>
                          {t('project:type.team-info.plan-2')}
                        </strong>
                        {t('project:type.team-info.part-4')}
                        <strong>
                          {t('project:type.team-info.plan-3')}
                        </strong>
                        {t('project:type.team-info.part-5')}
                      </div>
                    )}
                    theme="light"
                  >
                    <span className="dotted-underline-gray">
                      { t('project:type.team') }
                    </span>
                  </NewTooltip>
                ),
                value: TEAM_PROJECT,
              },
            ]}
            onChange={this.onTypeChange}
            ref={(ref) => { this.typeSelect = ref; }}
          />
        </div>
        {
          isPersonalProject && (
            <Link
              className="small text-newblue-1 font-italic row mt-2 ml-1"
              to="/dashboard/settings/account/"
            >
              {t('project:view-account')}
            </Link>
          )
        }
        {!isPersonalProject && (
          <>
            <Validator
              required
              validation="string"
              validatorGuiEffect={GUI_EFFECT_PARTIAL}
              ref={(ref) => { this.teamSelect = ref; }}
            >
              <Select
                className="react-select team-react-select"
                classNamePrefix="react-select"
                placeholder={t('project:choose-team')}
                name="team"
                onChange={(e) => this.onTeamChange({ target: { ...e } })}
                disabled={!admin && !canEdit}
                value={defaultTeam || undefined}
                noOptionsMessage={() => t('user:team.no-teams')}
                options={teamOptions}
              />
            </Validator>
            <div className="help-text">
              {t('user:team.displayed-teams-help')}
            </div>
          </>
        )}
      </>
    );
  }
}

export default ProjectTeamZone;
