import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { withToastManager } from 'react-toast-notifications';
import jsPDF from 'jspdf';
import { connect } from 'react-redux';
import { nsOptions } from '../i18n';
import ElementUtil from '../utils/ElementUtil';
import { childrenPropTypes } from '../utils/generic-prop-types';
import SortUtil from '../utils/SortUtil';
import Toast from '../utils/Toast';
import { toMoment } from '../utils/date';
import { CardLoader } from './Loader';
import NewModal from './NewModal';
import LabeledChoice from './LabeledChoice';
import ErrorUtil from '../utils/ErrorUtil';
import { formatPageTitle } from '../utils/data-util';
import downloadBlobAsFile from '../utils/downloadBlobAsFile';
import doqboardLogo from '../assets/img/pdf_logo.png';
import Formatter from '../utils/Formatter';
import {
  ELEMENT_DIRECT_IDENTIFIER, ELEMENT_TYPE_CALCULATION, ELEMENT_TYPE_DATE, ELEMENT_TYPE_MEASUREMENT,
  ELEMENT_TYPE_MODULE, ELEMENT_TYPE_MULTIPLE_CHOICES, ELEMENT_TYPE_TEXT, ELEMENT_TYPE_TIME,
  ELEMENT_TYPE_UNIQUE_CHOICE, TARGET_TYPE_ELEMENT, TIME,
} from '../constants';
import { checkLinks } from '../utils/links';
import fromReduxState from '../utils/redux';

const EXPORT_CURRENT_PAGE = 'current_page';
const EXPORT_ALL_PAGES = 'all_pages';

const PROJECT_SEPARATOR = '-------------------------';
const PAGE_SEPARATOR = '--------';

const mapStateToProps = (state) => ({
  pages: state.projectPages,
  projectElements: state.projectElements,
  elements: state.elements,
  elementLinks: state.elementLinks,
  projectEntries: state.projectEntries,
  elementModalities: state.elementModalities,
});


@withToastManager
@connect(mapStateToProps)
@withTranslation('', nsOptions)
class FormExportManager extends Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
    children: childrenPropTypes().isRequired,
    project: PropTypes.shape(),
    moduleInstanceId: PropTypes.number,
    inclusion: PropTypes.shape().isRequired,
    pageId: PropTypes.number,
    projectElements: PropTypes.shape().isRequired,
    elements: PropTypes.shape().isRequired,
    elementModalities: PropTypes.shape().isRequired,
    elementLinks: PropTypes.shape().isRequired,
    projectEntries: PropTypes.shape().isRequired,
    pages: PropTypes.shape().isRequired,
  };

  static defaultProps = {
    project: null,
    moduleInstanceId: null,
    pageId: null,
  };

  constructor(props) {
    super(props);
    this.state = { value: null, exportMode: EXPORT_CURRENT_PAGE };
    this.modal = null;
    this.textarea = null;
    this.toPElementsArray = fromReduxState((projectElements) => Object.values(projectElements));
    this.toElLinksArray = fromReduxState((elementLinks) => Object.values(elementLinks));
    this.toPEntriesArray = fromReduxState((projectEntries) => Object.values(projectEntries));
  }

  getFormattedValue = (projectElement, moduleInstanceId = null, pre = 1) => {
    const {
      t, elements, elementLinks: elLinks, inclusion, projectElements: pEls,
      projectEntries: pEntries, elementModalities,
      pages,
    } = this.props;
    const element = elements[projectElement.element];
    const projectElements = this.toPElementsArray(pEls);
    const elementLinks = this.toElLinksArray(elLinks);
    const projectEntries = this.toPElementsArray(pEntries);

    try {
      const elProjectEntries = projectEntries.filter((entry) => (
        entry.inclusion === inclusion.id
        && entry.project_element === projectElement.id
          && entry.module === moduleInstanceId
      ));
      if (!elProjectEntries) return null;
      const links = checkLinks(elements, elementLinks, projectElements,
        projectEntries, pages, projectElement, TARGET_TYPE_ELEMENT, inclusion.id, moduleInstanceId);
      if (!links) return null;
      if (elProjectEntries.length < 1) return null;
      const entry = elProjectEntries[0];
      const modalities = element.type === ELEMENT_TYPE_MULTIPLE_CHOICES ? (
        elProjectEntries.map((projectEntry) => projectEntry.value)) : [];
      const emptyMessage = t('common:empty');
      const value = [];
      const nameSpace = ' '.repeat((pre - 1) * 8);
      const valueSpace = ' '.repeat(pre * 10);
      const elementName = ElementUtil.formatElementName(element, t);
      const title = `${nameSpace}- ${elementName}`;
      let formatter;

      const getMissingCode = (missingValue) => (
        `##${t(`inclusion:qualified-missing-data.${missingValue.replace('_', '-')}-code`)}##`
      );

      if (!inclusion.is_anonymized || element.identification_level !== ELEMENT_DIRECT_IDENTIFIER) {
        switch (element.type) {
          case ELEMENT_TYPE_DATE:
            value.push(title);
            if (entry.missing) {
              value.push(`${valueSpace}${getMissingCode(entry.missing)}`);
            } else {
              formatter = new Formatter(element.format);
              value.push(`${valueSpace}${entry.value
                ? formatter.formatDateTime(toMoment(entry.value, element.format))
                : emptyMessage}`);
            }
            break;
          case ELEMENT_TYPE_TIME:
            value.push(title);
            if (entry.missing) {
              value.push(`${valueSpace}${getMissingCode(entry.missing)}`);
            } else {
              formatter = new Formatter(TIME);
              value.push(`${valueSpace}${entry.value
                ? formatter.formatDateTime(toMoment(entry.value, TIME))
                : emptyMessage}`);
            }
            break;
          case ELEMENT_TYPE_MEASUREMENT:
          case ELEMENT_TYPE_CALCULATION:
            value.push(title);
            if (entry.missing) {
              value.push(`${valueSpace}${getMissingCode(entry.missing)}`);
            } else {
              formatter = new Formatter(
                null, element.unit, null, null,
                true, false, emptyMessage,
              );
              value.push(
                `${valueSpace}${formatter.format(entry.value)}`,
              );
            }
            break;
          case ELEMENT_TYPE_MODULE:
          {
            const res = projectElements.filter((pEl) => pEl.module === projectElement.id);
            if (res.length >= 1) {
              const moduleProjectElements = res.sort(SortUtil.sortArray);
              value.push(title);
              elProjectEntries.forEach((instance) => {
                value.push(`${valueSpace}- ${instance.value}`);
                const values = [];
                moduleProjectElements.forEach((moduleProjectElement) => {
                  if (!ElementUtil.isElementStatic(moduleProjectElement.element)) {
                    values.push(this.getFormattedValue(moduleProjectElement, instance.id, 4));
                  }
                });
                value.push(...values.filter((val) => val !== null && val !== undefined));
                value.join('\n');
              });
              value.join('\n');
            } else {
              value.push(title);
              value.push(`${valueSpace}${emptyMessage}`);
            }
            break;
          }
          case ELEMENT_TYPE_MULTIPLE_CHOICES:
            value.push(title);
            if (entry.missing) {
              value.push(`${valueSpace}${getMissingCode(entry.missing)}`);
            } else if (modalities && modalities.length > 0) {
              element.values.filter((modality) => modalities.includes(modality))
                .forEach((modality) => {
                  value.push(`${valueSpace}${ElementUtil.formatModalityName(elementModalities[modality], t)}`);
                });
            } else {
              value.push(`${valueSpace}${emptyMessage}`);
            }
            break;
          case ELEMENT_TYPE_TEXT:
            value.push(title);
            if (entry.missing) {
              value.push(`${valueSpace}${getMissingCode(entry.missing)}`);
            } else {
              value.push(`${valueSpace}${entry.value ? entry.value : emptyMessage}`);
            }
            break;
          case ELEMENT_TYPE_UNIQUE_CHOICE:
            value.push(title);
            if (entry.missing) {
              value.push(`${valueSpace}${getMissingCode(entry.missing)}`);
            } else if (entry.value) {
              const modality = element.values.find((elementValue) => elementValue === entry.value);
              if (!modality) return null;
              value.push(`${valueSpace}${ElementUtil.formatModalityName(elementModalities[modality], t)}`);
            } else {
              value.push(`${valueSpace}${emptyMessage}`);
            }
            break;
          default:
            return null;
        }
      } else {
        value.push(title);
        value.push(`${valueSpace}${t('common:elements.anonymized')}`);
      }
      return value.join('\n');
    } catch (error) {
      ErrorUtil.handleCatched(this.props, error, false);
      return null;
    }
  };

  load = async () => {
    const {
      t, projectElements: pElements, elements, moduleInstanceId, pageId, pages, project,
      projectEntries,
    } = this.props;
    const projectElements = this.toPElementsArray(pElements);

    this.setState({ value: null });

    let projectModule;
    let moduleInstanceName;

    if (moduleInstanceId) {
      const projectEntry = projectEntries[moduleInstanceId];
      projectModule = pElements[projectEntry.project_element];
      moduleInstanceName = projectEntry.value;
    }

    const pageName = formatPageTitle(pages[pageId], t);

    try {
      const getFormattedPageName = (pgName) => (
        `${t('inclusion:modal.export-entries.form')} ${pgName}`
      );

      const getFormattedValues = (pEls) => (
        pEls.sort(SortUtil.sortArray).filter((projectElement) => (
          !ElementUtil.isElementStatic(elements[projectElement.element].type)
        )).map((projectElement) => (
          this.getFormattedValue(projectElement, moduleInstanceId)
        ))
      );

      const value = [];
      value.push(`${t('inclusion:modal.export-entries.project')} ${project.name}`);
      value.push(PROJECT_SEPARATOR);
      value.push('');

      let values = [];

      if (moduleInstanceId) {
        // Module export
        const moduleElement = elements[projectModule.element];
        values.push(
          getFormattedPageName(pageName),
          PAGE_SEPARATOR,
          `${t('inclusion:modal.export-entries.module')} ${ElementUtil.formatElementName(moduleElement, t)}`,
          `${t('inclusion:modal.export-entries.instance')} ${moduleInstanceName}`,
          ...getFormattedValues(projectElements.filter((pEl) => (
            pEl.module === projectModule.id
          ))),
        );
      } else {
        const getPageFormattedValues = (pgId, pgName) => [
          getFormattedPageName(pgName),
          PAGE_SEPARATOR,
          ...getFormattedValues(projectElements.filter((pEl) => pEl.project_page === pgId)),
        ];

        const { exportMode } = this.state;

        if (exportMode === EXPORT_ALL_PAGES) {
          // All pages export
          Object.values(pages).sort(SortUtil.sortArray).forEach((page, index) => {
            if (index !== 0) values.push('\n');
            values.push(...getPageFormattedValues(page.id, formatPageTitle(pages[page.id], t)));
          });
        } else {
          // Current page export
          values = getPageFormattedValues(pageId, pageName);
        }
      }

      value.push(...values.filter((val) => val !== null && val !== undefined)
        .map((val) => `${val}`));
      this.setState({ value });
    } catch (error) {
      ErrorUtil.handleCatched(this.props, error);
    }
  };

  copyToClipboard = () => {
    if (this.textarea) {
      this.textarea.select();
      document.execCommand('copy');
      document.getSelection().removeAllRanges();
    }
  };

  exportToPDF = async () => {
    const { inclusion, t } = this.props;
    const { value } = this.state;
    if (!this.state.value) return;

    // Create pdf document
    // eslint-disable-next-line new-cap
    const doc = new jsPDF({ unit: 'px', format: 'a4', hotfixes: ['px_scaling'] });

    const { width, height } = doc.internal.pageSize;
    const lMargin = 65;
    const rMargin = lMargin;
    const uMargin = 65;
    const bMargin = uMargin;
    const headerHeight = 60;
    const footerHeight = 60;

    const fontList = doc.getFontList();
    const font = doc.internal.getFont();
    const contentFontSize = 12;
    const headerFontSize = 14;
    const contentFontStyle = doc.internal.getFont().fontStyle;
    const headerFontStyle = fontList[font.fontName].includes('bold') ? 'bold' : contentFontStyle;

    const logoWidth = 160;
    const logoHeight = 45;

    const headerOnlyFirstPage = true;

    const loadImage = async (src) => new Promise((resolve, reject) => {
      const img = new Image();
      img.onload = () => resolve(img);
      img.onerror = reject;
      img.src = src;
    });

    const setFooter = (logo) => {
      doc.addImage(
        logo,
        'PNG',
        (width - logoWidth) / 2,
        height - bMargin - logoHeight,
        logoWidth,
        logoHeight,
      );
    };

    const setHeader = () => {
      if (!headerOnlyFirstPage || doc.internal.getNumberOfPages() <= 1) {
        const fontSize = doc.getFontSize();
        const { fontStyle } = doc.internal.getFont();

        doc.setFontSize(headerFontSize);
        doc.setFontStyle(headerFontStyle);

        doc.text(t('inclusion:modal.export-entries.header'), width / 2, uMargin, { align: 'center' });

        doc.setFontSize(fontSize);
        doc.setFont(font.fontName, fontStyle);
      }
    };

    const addPage = (logo) => {
      doc.addPage();
      setHeader();
      setFooter(logo);
    };

    // Load logo
    const logo = await loadImage(doqboardLogo);

    // Set header and footer
    setHeader();
    setFooter(logo);

    // Content
    doc.setFontSize(contentFontSize);
    doc.setFontStyle(contentFontStyle);

    // Wrap lines if needed
    const content = [];
    value.forEach((line) => (
      content.push(...doc.splitTextToSize(line, width - lMargin - rMargin))));

    // Add lines to document
    const lineHeight = doc.internal.getLineHeight() + 7;
    const x = lMargin;
    let y = uMargin + headerHeight;

    content.forEach((line) => {
      if (y > height - bMargin - footerHeight - lineHeight) {
        // Current page is full, add a new one
        addPage(logo);
        y = uMargin + (headerOnlyFirstPage ? 0 : headerHeight);
      }
      // FIXME: Change the PDF renderer so that we don’t have to replace
      //        narrow non-breaking spaces with non-breaking spaces.
      doc.text(line.replace(' ', ' '), x, y);
      y += lineHeight;
    });

    // Export to file
    const blob = doc.output('blob');
    const fileName = `DOQBOARDFILE_${inclusion.per_project_id}_${moment().format('YYYYMMDD')}.pdf`;
    downloadBlobAsFile(blob, fileName, 'pdf-export');
  };

  render() {
    const { t, children, moduleInstanceId } = this.props;
    const { value, exportMode } = this.state;
    const MenuButton = (props) => (
      <button
        type="button"
        className={`btn btn-newturquoise-1 text-white px-3 ${props.className ? props.className : ''}`}
        onClick={props.onClick}
        disabled={props.disabled}
      >
        <FontAwesomeIcon
          icon={['fal', props.iconName]}
          transform="grow-3"
          className="mr-2"
        />
        <span className="ml-1">
          {props.label}
        </span>
      </button>
    );

    return (
      <NewModal
        trigger={children}
        title={t('inclusion:modal.export-entries.title')}
        size="md"
        type={2}
        onLoad={this.load}
        footer={(
          <div className="row">
            <MenuButton
              iconName="copy"
              label={t('inclusion:modal.export-entries.copy')}
              disabled={!value}
              onClick={this.copyToClipboard}
            />
            <MenuButton
              iconName="file-pdf"
              label={t('inclusion:modal.export-entries.pdf')}
              className="ml-5"
              disabled={!value}
              onClick={this.exportToPDF}
            />
          </div>
        )}
        ref={(modal) => {
          this.modal = modal;
        }}
      >
        <div className="entry-context">
          { !moduleInstanceId && (
            <LabeledChoice
              name="type"
              value={exportMode}
              choices={[
                {
                  text: t('inclusion:modal.export-entries.current-page'),
                  value: EXPORT_CURRENT_PAGE,
                }, {
                  text: t('inclusion:modal.export-entries.all-pages'),
                  value: EXPORT_ALL_PAGES,
                },
              ]}
              onChange={(e) => {
                this.setState({ exportMode: e.target.value }, this.load);
              }}
            />
          )}
          {
            value ? (
              <textarea
                className="w-100 no-resize export-textarea"
                style={{ height: '400px' }}
                value={value.join('\n')}
                onCopy={() => {
                  Toast.success(this.props, 'error:valid.copied');
                }}
                readOnly
                ref={(textarea) => {
                  this.textarea = textarea;
                }}
              />
            ) : (
              <div style={{ height: '200px' }}>
                <CardLoader />
              </div>
            )
          }
        </div>
      </NewModal>
    );
  }
}


export default FormExportManager;
