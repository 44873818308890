import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { childrenPropTypes } from '../utils/generic-prop-types';
import NewTooltip from './NewTooltip';


class OverMenuItem extends Component {
  static propTypes = {
    active: PropTypes.bool,
    tooltip: PropTypes.string,
    onClick: PropTypes.func.isRequired,
    children: childrenPropTypes().isRequired,
    className: PropTypes.string,
  };

  static defaultProps = {
    active: false,
    tooltip: null,
    className: '',
  };

  handleClick = (e) => {
    e.persist();
    this.props.onClick(e);
  };

  render() {
    const {
      active, className, children, tooltip,
    } = this.props;
    const content = (
      <button
        type="button"
        className={`overmenu-item ${className} ${active && 'active'}`}
        onClick={this.handleClick}
        onKeyPress={this.handleClick}
        onTouchEnd={this.handleClick}
      >
        {children}
      </button>
    );
    return tooltip ? (
      <NewTooltip
        content={tooltip}
        distance={6}
        arrow
      >
        {content}
      </NewTooltip>
    ) : content;
  }
}


export default OverMenuItem;
