/* eslint-disable no-underscore-dangle */
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { components } from 'react-select';
import AsyncSelect from 'react-select/async';
import { withToastManager } from 'react-toast-notifications';
import api from '../api';
import { nsOptions } from '../i18n';
import ErrorUtil from '../utils/ErrorUtil';
import Validator, { GUI_EFFECT_FULL } from './Validator';

const Input = (props) => <components.Input {...props} />;

Input.propTypes = {
  isHidden: PropTypes.bool,
};

Input.defaultProps = {
  isHidden: false,
};

const styles = {
  multiValue: (base, state) => (state.data.isFixed
    ? { ...base, backgroundColor: '#c3c3c3 !important' }
    : base),
  multiValueLabel: (base, state) => (state.data.isFixed ? {
    ...base, fontStyle: 'normal', paddingRight: 6,
  } : base),
  multiValueRemove: (base, state) => (state.data.isFixed ? { ...base, display: 'none' } : base),
};

@withToastManager
@withTranslation('', nsOptions)
class SpecialtySelect extends Component {
  static propTypes = {
    i18n: PropTypes.shape().isRequired,
    onChange: PropTypes.func.isRequired,
    values: PropTypes.arrayOf(PropTypes.string),
    disabled: PropTypes.bool,
    required: PropTypes.bool,
    admin: PropTypes.bool,
    isMulti: PropTypes.bool,
    placeholder: PropTypes.string.isRequired,
  };

  static defaultProps = {
    values: [],
    disabled: false,
    required: false,
    admin: false,
    isMulti: false,
  };

  constructor(props) {
    super(props);
    this.state = {
      value: [],
      loading: false,
    };
    this.translations = this.props.i18n.getResourceBundle(this.props.i18n.language, 'specialty');
  }

  componentDidMount() {
    this.fetchSpecialtiesLabels();
  }

  updateTranslations = () => {
    this.props.i18n.reloadResources(null, 'specialty');
    this.translations = this.props.i18n.getResourceBundle(this.props.i18n.language, 'specialty');
  };

  fetchSpecialtiesLabels = async () => {
    const { values, admin, i18n } = this.props;
    if (values.length > 0) {
      this.setState({ loading: true });

      try {
        const specialties = await api.list('specialties', {
          key__in: values,
          admin,
        }, { pagination: 'no' });

        const results = specialties.map((specialty) => ({
          value: specialty.key,
          label: specialty[i18n.language],
        }));

        this.setState({ value: results });
      } catch (error) {
        ErrorUtil.handleCatched(this.props, error);
      } finally {
        this.setState({ loading: false });
      }
    }
  };

  fetchSpecialties = async (search = null) => {
    const { admin } = this.props;
    try {
      return await api.list('specialties', { search, active: true, admin }, { pagination: 'no' });
    } catch (error) {
      ErrorUtil.handleCatched(this.props, error);
    }
    return [];
  };

  load = async (search) => {
    const { i18n } = this.props;
    try {
      const specialties = await this.fetchSpecialties(search);
      return specialties.map((specialty) => ({
        value: specialty.key,
        label: specialty[i18n.language],
      }));
    } catch (error) {
      ErrorUtil.handleCatched(this.props, error);
    }
    return [];
  };

  handleChange = (results) => {
    const { onChange } = this.props;
    const { value } = this.state;
    const promises = [];
    const newValue = [...value];

    const resultIsArray = Array.isArray(results);

    // Validation
    let valid = false;
    if (resultIsArray) {
      valid = this.validate(results.length > 0 ? results : null);
    } else {
      valid = this.validate(results);
    }

    // Handle new selected specialties
    if (results) {
      if (resultIsArray) {
        // Select isMulti === true
        results.forEach((specialty) => {
          if (!value.includes(specialty)) {
            promises.push(specialty);
          }
        });
      } else if (!value.includes(results)) {
        // Select isMulti === false
        promises.push(results);
      }
    }

    // Remove deleted specialties
    newValue.forEach((specialty) => {
      if (!results || (resultIsArray && !results.includes(specialty))
        || (!resultIsArray && results !== specialty)) {
        const index = newValue.indexOf(specialty);
        if (index > -1) newValue.splice(index, 1);
      }
    });

    Promise.all(promises).then(async (specialties) => {
      specialties.forEach((specialty) => {
        if (specialty) newValue.push(specialty);
      });
      if (valid) {
        await onChange(newValue);
      }
      this.setState({ value: newValue });
      this.updateTranslations();
    }).catch((error) => {
      ErrorUtil.handleCatched(this.props, error);
    });
  };

  validate = (value, guiEffect = GUI_EFFECT_FULL) => (
    this.validator ? this.validator.validate(value, guiEffect) : true
  );

  render() {
    const {
      required, disabled, isMulti, placeholder,
    } = this.props;
    const { loading } = this.state;

    return (
      <Validator
        required={required}
        ref={(validator) => { this.validator = validator; }}
      >
        <AsyncSelect
          isMulti={isMulti}
          isClearable={false}
          noOptionsMessage={(value) => `No specialty found${value.inputValue
            ? ` with name "${value.inputValue}"`
            : ''}.`}
          className="react-select specialty-select"
          classNamePrefix="react-select"
          placeholder={placeholder}
          loadOptions={this.load}
          components={{ Input }}
          menuPlacement="auto"
          onChange={this.handleChange}
          openMenuOnFocus
          backspaceRemovesValue={false}
          value={isMulti ? this.state.value : this.state.value[0]}
          defaultOptions
          styles={styles}
          isDisabled={disabled}
          isLoading={loading}
        />
      </Validator>
    );
  }
}


export default SpecialtySelect;
