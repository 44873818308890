import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import {
  CardCVCElement,
  CardExpiryElement,
  CardNumberElement,
  injectStripe,
} from 'react-stripe-elements';
import { Label } from 'reactstrap';
import '../assets/css/animation.css';
import { nsOptions } from '../i18n';
import LabeledInput from './LabeledInput';
import { childrenPropTypes } from '../utils/generic-prop-types';


@withTranslation('', nsOptions)
class CheckoutEntry extends React.Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
    label: PropTypes.string.isRequired,
    className: PropTypes.string,
    children: childrenPropTypes().isRequired,
  };

  static defaultProps = {
    className: 'mb-3',
  };

  render() {
    const {
      t, label, className, children,
    } = this.props;
    return (
      <div className={className}>
        <Label className="mb-1">
          {t(label)}
        </Label>
        {children}
      </div>
    );
  }
}


@injectStripe
@withTranslation('', nsOptions)
class LicenseCheckout extends Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
    stripe: PropTypes.shape().isRequired,
    elements: PropTypes.shape().isRequired,
    onValidityChange: PropTypes.func,
  };

  static defaultProps = {
    onValidityChange: () => {},
  };

  constructor(props) {
    super(props);
    this.cardHolderNameValue = '';
    this.cardNumberComplete = false;
    this.cardExpiryComplete = false;
    this.cardCVCComplete = false;
    this.formValid = false;
  }

  getPaymentMethod = async (billingInfo) => {
    const { stripe, elements } = this.props;
    const cardElement = elements.getElement('cardNumber');
    return stripe.createPaymentMethod({
      type: 'card',
      card: cardElement,
      billing_details: {
        ...billingInfo,
      },
    });
  };

  checkFormValid = () => {
    const valid = this.cardHolderNameValue && this.cardNumberComplete && this.cardExpiryComplete
      && this.cardCVCComplete;

    if (valid !== this.formValid) {
      this.formValid = valid;
      this.props.onValidityChange(valid);
    }
  };

  render() {
    const { t } = this.props;

    return (
      <>
        <LabeledInput
          type="text"
          name="name"
          label={t('user:license.cardholder-name')}
          placeholder={t('error:placeholder.name')}
          validation="human_fullname"
          required
          onValidChange={(e) => { this.cardHolderNameValue = e.target.value; }}
          onChange={() => { this.checkFormValid(); }}
        />
        <CheckoutEntry label="user:license.card-number">
          <CardNumberElement
            className="license-card-element"
            onChange={(e) => { this.cardNumberComplete = e.complete; this.checkFormValid(); }}
          />
        </CheckoutEntry>
        <CheckoutEntry label="user:license.card-expiry">
          <CardExpiryElement
            className="license-card-element"
            onChange={(e) => { this.cardExpiryComplete = e.complete; this.checkFormValid(); }}
          />
        </CheckoutEntry>
        <CheckoutEntry label="user:license.card-cvc" className="mb-1">
          <CardCVCElement
            className="license-card-element"
            onChange={(e) => { this.cardCVCComplete = e.complete; this.checkFormValid(); }}
          />
        </CheckoutEntry>
      </>
    );
  }
}

export default LicenseCheckout;
