import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { components } from 'react-select';
import AsyncSelect from 'react-select/async';
import { withToastManager } from 'react-toast-notifications';
import { Mutex } from 'async-mutex';
import { Label } from 'reactstrap';
import {
  projectResourcesActions, resourceTopicsActions, projectsActions,
} from '../redux/actions';
import api from '../api';
import { nsOptions } from '../i18n';
import ErrorUtil from '../utils/ErrorUtil';
import { getSetRelativeComplement } from '../utils/math';
import LabeledInput from './LabeledInput';
import NewModal from './NewModal';
import { isTabletView } from './TabletView';
import TopicSelect from './TopicSelect';
import { childrenPropTypes } from '../utils/generic-prop-types';


// eslint-disable-next-line react/prefer-stateless-function
class Option extends React.Component {
  static propTypes = {
    data: PropTypes.shape().isRequired,
    children: childrenPropTypes().isRequired,
  };

  render() {
    const { children, data } = this.props;
    return (
      <components.Option className="resource-select-option" {...this.props}>
        <div className="resource-item resource-select-item">
          <div className="row justify-content-between align-items-center">
            <div className="col-12">
              <div className="resource-name">
                {children}
              </div>
              {data.url && (
                <span
                  className="mt-1 resource-url"
                >
                  <div className="resource-url-div">
                    <img
                      src={`https://s2.googleusercontent.com/s2/favicons?domain_url=${data.external_url.replace(/^http(s?):\/\/(.*?)\/(.*)$/gi, 'http$1://$2')}`}
                      width="16"
                      height="16"
                      alt=""
                      className="mr-1"
                    />
                    {data.url}
                  </div>
                </span>
              )}
            </div>
            <div className="col-12">
              {data.topics && (
                <div className="mt-1 resource-topics">
                  {
                    data.topics.map((topic) => (
                      <div key={topic} className="badge badge-topic text-white mr-1 mt-1">
                        {topic}
                      </div>
                    ))
                  }
                </div>
              )}
            </div>
          </div>
        </div>
      </components.Option>
    );
  }
}


const mapStateToProps = (state) => ({
  projectResources: state.projectResources,
  resourceTopics: state.resourceTopics,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  addProjectResource: async (data) => dispatch(projectResourcesActions.create(data, {
    admin: ownProps.admin,
  })),
  patchProjectResource: async (id, data) => dispatch(projectResourcesActions.patch(id, data, {
    admin: ownProps.admin,
  })),
  readProjectResource: async (id) => dispatch(projectResourcesActions.read(id, {
    admin: ownProps.admin,
  })),
  addResourceTopic: async (data) => dispatch(resourceTopicsActions.create(data, {
    admin: ownProps.admin,
  })),
  removeResourceTopic: async (id) => dispatch(resourceTopicsActions.remove(id, {
    admin: ownProps.admin,
  })),
  patchProject: async (data) => dispatch(projectsActions.patch(
    ownProps.project.id,
    data,
    { admin: ownProps.admin, annotate_investigators_count: true },
  )),
});


@withToastManager
@connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })
@withTranslation('', nsOptions)
class ResourceManager extends Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
    onProjectResourceSelect: PropTypes.func,
    project: PropTypes.shape().isRequired,
    projectResources: PropTypes.shape().isRequired,
    resourceTopics: PropTypes.shape().isRequired,
    loading: PropTypes.bool,
    addProjectResource: PropTypes.func.isRequired,
    addResourceTopic: PropTypes.func.isRequired,
    readProjectResource: PropTypes.func.isRequired,
    patchProject: PropTypes.func.isRequired,
    patchProjectResource: PropTypes.func.isRequired,
    removeResourceTopic: PropTypes.func.isRequired,
  };

  static defaultProps = {
    onProjectResourceSelect: () => {},
    loading: false,
  };

  constructor(props) {
    super(props);
    this.state = {
      id: null,
      name: '',
      nameValid: false,
      url: '',
      urlValid: false,
      topics: [],
      check: false,
    };
    this.modal = null;
    this.topicManager = null;
    this.mutex = new Mutex();
  }

  async setProjectResource(id) {
    const { projectResources } = this.props;

    try {
      const projectResource = projectResources[id];
      const { name, external_url: url, topics } = projectResource;
      await new Promise((resolve) => this.setState({
        id, name, nameValid: true, url, urlValid: true, topics,
      }, () => resolve()));
    } catch (error) {
      ErrorUtil.handleCatched(this.props, error, false);
    }
  }

  resetProjectResource = () => {
    this.setState({
      id: null,
      name: '',
      url: '',
      topics: [],
      check: false,
    }, () => {
      this.topicManager.reset();
    });
  };

  load = async (search = null) => this.loadProjectResources(search);

  loadProjectResources = async (search = '') => {
    const { t } = this.props;
    try {
      const projectResources = await api.list('project-resources', {
        search,
      }, {
        pagination: 'no',
      });
      return projectResources.map((projectResource) => ({
        label: projectResource.name,
        value: projectResource.id,
        url: projectResource.external_url,
        topics: projectResource.topics.map((resourceTopic) => t(`topic:${resourceTopic}`)),
      }));
    } catch (err) {
      console.error(err);
      return [];
    }
  };

  isFormValid = () => {
    const { nameValid, urlValid } = this.state;
    return nameValid && urlValid;
  }

  addProjectResource = async () => {
    const { name, url, topics } = this.state;
    const {
      addProjectResource, addResourceTopic, readProjectResource, project,
    } = this.props;

    if (this.mutex.isLocked()) return;

    const release = await this.mutex.acquire();

    try {
      const projectResource = await addProjectResource({
        name,
        external_url: url,
        project: project.id,
      });

      await Promise.all(topics.map((topic) => addResourceTopic({
        resource: projectResource.id,
        topic,
      })));

      await readProjectResource(projectResource.id);

      this.modal.hide();
    } catch (error) {
      ErrorUtil.handleCatched(this.props, error);
    } finally {
      release();
    }
  };

  async patchProjectResource() {
    const {
      id, name, url, topics: newTopics,
    } = this.state;
    const {
      addResourceTopic, removeResourceTopic, resourceTopics: rscTopics,
    } = this.props;
    const resourceTopics = Object.values(rscTopics);

    try {
      const currentTopics = resourceTopics.filter((resourceTopic) => (
        resourceTopic.resource === id
      )).map((resourceTopic) => resourceTopic.topic);

      const resourceTopicsToRemove = [...getSetRelativeComplement(
        new Set(currentTopics),
        new Set(newTopics),
      )];

      const resourceTopicsToAdd = [...getSetRelativeComplement(
        new Set(newTopics),
        new Set(currentTopics),
      )];

      await Promise.all([
        ...resourceTopicsToRemove.map((key) => (
          removeResourceTopic(resourceTopics.find((rTopic) => (
            rTopic.resource === id && rTopic.topic === key
          )).id)
        )),
        ...resourceTopicsToAdd.map((key) => addResourceTopic({ resource: id, topic: key })),
      ]);

      await this.props.patchProjectResource(id, {
        name,
        external_url: url,
      });

      this.modal.hide();
    } catch (error) {
      ErrorUtil.handleCatched(this.props, error);
    }
  }

  show() {
    try {
      this.modal.show();
    } catch (error) {
      ErrorUtil.handleCatched(this.props, error, false);
    }
  }

  render() {
    const { t } = this.props;
    const {
      id, name, url, topics, check,
    } = this.state;
    const isEditMode = !!id;

    return (
      <div>
        <NewModal
          {...this.props}
          title={t('project:modal.resource.title')}
          subTitle={t('project:modal.resource.sub-title')}
          size="md"
          dialogClass="contains-form"
          trigger={(<div />)}
          onClosed={this.resetProjectResource}
          footer={(
            <div>
              <button
                type="button"
                className="btn btn-newblue-1 text-white"
                disabled={!this.isFormValid()}
                onClick={() => (isEditMode ? this.patchProjectResource()
                  : this.addProjectResource())}
              >
                {
                  isEditMode ? null : (
                    <FontAwesomeIcon
                      icon={['fal', 'plus']}
                      transform="grow-3"
                      className="mr-2"
                    />
                  )
                }
                <span className="ml-1">
                  {isEditMode ? t('common:button.validate') : t('project:button.attach')}
                </span>
              </button>
            </div>
          )}
          ref={(modal) => {
            this.modal = modal;
          }}
        >
          <form
            noValidate
            onSubmit={(event) => event.preventDefault()}
            ref={(form) => {
              this.form = form;
            }}
          >
            <LabeledInput
              type="text"
              label={t('project:resources.short-name')}
              name="resource-name"
              placeholder={t('error:placeholder.resource-name')}
              validation="shortname"
              feedback="Incorrect short name"
              className="mb-4"
              required
              value={name}
              onValidChange={(e) => this.setState({ name: e.target.value })}
              onValidityChange={(valid) => this.setState({ nameValid: valid })}
            />
            <LabeledInput
              type="text"
              label={t('project:resources.url')}
              name="resource-url"
              placeholder={t('error:placeholder.resource-url')}
              validation="url"
              feedback="Incorrect URL"
              className="mb-4"
              required
              value={url}
              onChange={(e) => this.setState({ url: e.target.value })}
              onValidityChange={(valid) => this.setState({ urlValid: valid })}
            />
            <Label className="d-block mb-0">
              {t('project:resources.topics')}
            </Label>
            <div className="mb-4">
              <TopicSelect
                {...this.props}
                values={topics}
                onChange={(e) => {
                  this.setState({ topics: e.map((topic) => topic.value) });
                }}
                canCreate
                disabled={false}
                ref={(topicManager) => {
                  if (topicManager) this.topicManager = topicManager;
                }}
                showPlaceholder
              />
            </div>
            <div className="custom-control custom-checkbox d-none">
              <input
                type="checkbox"
                className="custom-control-input"
                id="resource-check"
                onChange={(e) => {
                  this.setState({ check: e.target.checked });
                }}
                checked={check}
              />
              <label className="custom-control-label" htmlFor="resource-check">
                {t('project:resources.i-certify')}
              </label>
            </div>
          </form>
        </NewModal>
        <div className="row align-items-center justify-content-between">
          <div className={`col${isTabletView() ? ' my-3' : ''}`}>
            <span className="resources-help">
              {t('project:resources.attach-resources')}
            </span>
          </div>
          <div className="col-12 col-xl-auto">
            <div className="row align-items-center justify-content-start justify-content-xl-end">
              <div className="col-auto pr-0 d-none">
                <FontAwesomeIcon
                  icon={['far', 'search']}
                  transform="grow-5"
                  className="text-gray mr-3"
                />
              </div>
              <div className="col-auto pl-0 d-none">
                <AsyncSelect
                  key={JSON.stringify(this.props.project.resources)}
                  isClearable={false}
                  noOptionsMessage={() => t('project:resources.cannot-find-any-resource')}
                  loadOptions={this.load}
                  createOptionPosition="first"
                  className="react-select resource-select mb-1"
                  classNamePrefix="react-select"
                  placeholder={t('project:resources.search-resources')}
                  menuPlacement="auto"
                  minMenuHeight={200}
                  openMenuOnFocus
                  blurInputOnSelect
                  controlShouldRenderValue={false}
                  components={{ Option }}
                  onChange={(value) => {
                    this.props.onProjectResourceSelect(value.value);
                  }}
                  filterOption={(option) => !this.props.project.resources.includes(option.value)}
                  defaultOptions
                  isDisabled={this.props.loading}
                />
              </div>
              <div className={`col-auto${isTabletView() ? ' pr-2' : ''}`}>
                <button
                  type="button"
                  className="btn btn-newblue-1 text-white"
                  onClick={() => this.modal.show()}
                >
                  <FontAwesomeIcon
                    icon={['fal', 'plus']}
                    transform="grow-3"
                    className="mr-2"
                  />
                  <span className="ml-1">
                    {t('project:button.add-a-resource')}
                  </span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}


export default ResourceManager;
