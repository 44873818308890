import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Validator from './Validator';
import { childrenPropTypes } from '../utils/generic-prop-types';

class Checkbox extends Component {
  constructor(props) {
    super(props);
    this.props = props;
  }

  handleInput = (e) => {
    this.props.onChange(e);
    if (this.validator) this.validate(e.target.value);
  };

  validate(value) {
    return this.validator ? this.validator.validate(value) : true;
  }

  render() {
    const {
      required, checked, onChange, id, children, className, disabled,
    } = this.props;
    return (
      // Note 09/12/2019: workaround removed, the issue seems not happen anymore.
      // ml-2 -> Workaround to fix checkbox left and botton edge cropped
      <div className={`custom-control custom-checkbox mt-32${className ? ` ${className}` : ''}`}>
        <Validator
          required={required}
          ref={(validator) => { this.validator = validator; }}
        >
          <input
            type="checkbox"
            id={id}
            className="custom-control-input"
            checked={checked}
            onChange={(e) => onChange(e)}
            onInput={this.handleInput}
            disabled={disabled}
          />
          <label
            className="custom-control-label"
            htmlFor={id}
          >
            {children}
          </label>
        </Validator>
      </div>
    );
  }
}

Checkbox.propTypes = {
  id: PropTypes.string,
  className: PropTypes.string,
  checked: PropTypes.bool,
  children: childrenPropTypes(),
  onChange: PropTypes.func,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
};

Checkbox.defaultProps = {
  id: 'checkbox-input',
  className: '',
  checked: null,
  children: null,
  onChange: () => {},
  required: false,
  disabled: false,
};

export default Checkbox;
