import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Trans, withTranslation } from 'react-i18next';
import { withToastManager } from 'react-toast-notifications';
import memoize from 'memoize-one';
import moment from 'moment';
import isEqual from 'react-fast-compare';
import { Label } from 'reactstrap';
import { connect } from 'react-redux';
import {
  usersActions, languagesActions, specialtiesActions, countriesActions,
  projectUsersActions,
} from '../redux/actions';
import { nsOptions } from '../i18n';
import {
  USER_TYPE_ASSISTANT, USER_TYPE_PRACTITIONER, USER_SUBTYPE_DOCTOR, USER_SUBTYPE_PROFESSOR,
  USER_SUBTYPE_NURSE, USER_SUBTYPE_SECRETARY, USER_SUBTYPE_CRA, USER_SUBTYPE_OTHER,
  USER_TYPE_INTERN, INDIVIDUAL_TYPES, ORGANIZATION_TYPES, ORGANIZATION_ASSOCIATION,
  USER_CIVILITY_MR, USER_CIVILITY_MRS, USER_CIVILITY_OTHER, USER_CIVILITY_UNKNOWN,
  USER_TYPE_HEALTHCARE_FACILITY,
  INSTITUTION_TYPES,
  ORGANIZATION_HEALTHCARE_FACILITY,
} from '../constants';
import LabeledInput from './LabeledInput';
import LabeledSelect from './LabeledSelect';
import LabeledChoice from './LabeledChoice';
import { CardLoader } from './Loader';
import PasswordForm from './PasswordForm';
import Checkbox from './Checkbox';
import NewTooltip from './NewTooltip';
import ButtonConfirm from './ButtonConfirm';
import { DelMsgModal } from './MessageModal';
import { isMobileView } from './MobileView';
import history from '../history';
import ErrorUtil from '../utils/ErrorUtil';
import Toast from '../utils/Toast';
import { getCgsuUrl } from '../utils/urls';
import { childrenPropTypes } from '../utils/generic-prop-types';
import { deObfuscateEmail } from '../utils/email';
import Help from './Help';

const INDIVIDUAL = 'individual';
const ORGANIZATION = 'organization';

export const Section = (props) => (
  props.show && (
    <div className={`row${props.className ? ` ${props.className}` : ''}${props.editionMode ? ' field-row' : ''}`}>
      <div className={props.colClassName}>
        {props.children}
      </div>
    </div>
  )
);

Section.propTypes = {
  show: PropTypes.bool,
  className: PropTypes.string,
  colClassName: PropTypes.string,
  editionMode: PropTypes.bool,
  children: childrenPropTypes().isRequired,
};

Section.defaultProps = {
  show: true,
  className: '',
  colClassName: 'col-12',
  editionMode: false,
};

const Divider = (props) => props.show && <hr className="mt-5 mb-4" />;

Divider.propTypes = {
  show: PropTypes.bool,
};

Divider.defaultProps = {
  show: true,
};

export const SectionLabel = (props) => {
  const { children, help } = props;

  return (
    <>
      <span>
        {children}
      </span>
      {
        help && (
          <Help iconClassName="ml-2" transform="shrink-2" className="text-left text-break">
            {help}
          </Help>
        )
      }
    </>
  );
};

SectionLabel.propTypes = {
  children: childrenPropTypes().isRequired,
  help: PropTypes.oneOfType([PropTypes.string, childrenPropTypes()]),
};

SectionLabel.defaultProps = {
  help: undefined,
};

const OptionHelp = (props) => {
  const { children } = props;
  return (
    <Help
      iconColor="text-white"
      tooltipTheme="dark"
      iconClassName="ml-2"
    >
      {children}
    </Help>
  );
};

OptionHelp.propTypes = {
  children: childrenPropTypes([PropTypes.string]).isRequired,
};


const mapStateToProps = (state, ownProps) => ({
  user: ownProps.match.params.id ? state.users[Number(ownProps.match.params.id)]
    : state.auth.authUser,
  projectUsers: state.projectUsers,
  languages: state.languages,
  specialties: state.specialties,
  countries: state.countries,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  fetchUser: async () => dispatch(usersActions.read(ownProps.user.id)),
  deleteUser: async (id) => dispatch(usersActions.remove(id, { admin: ownProps.admin })),
  fetchProjectUsers: async (userId) => dispatch(projectUsersActions.list({
    user: userId,
    confirmed: true,
  })),
  fetchLanguages: async () => dispatch(languagesActions.list({}, {
    pagination: 'no',
  })),
  fetchCountries: async () => dispatch(countriesActions.list({}, {
    pagination: 'no',
  })),
  fetchSpecialties: async () => dispatch(specialtiesActions.list({ active: true }, {
    pagination: 'no',
  })),
});


@withToastManager
@connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })
@withTranslation('', nsOptions)
class AccountForm extends Component {
  static propTypes = {
    admin: PropTypes.bool,
    editionMode: PropTypes.bool,
    i18n: PropTypes.shape().isRequired,
    t: PropTypes.func.isRequired,
    tReady: PropTypes.bool,
    user: PropTypes.shape(),
    projectUsers: PropTypes.shape().isRequired,
    languages: PropTypes.shape().isRequired,
    specialties: PropTypes.shape().isRequired,
    countries: PropTypes.shape().isRequired,
    onFormChange: PropTypes.func,
    onFormValidChange: PropTypes.func,
    onFormValidityChange: PropTypes.func,
    fetchUser: PropTypes.func.isRequired,
    deleteUser: PropTypes.func.isRequired,
    fetchProjectUsers: PropTypes.func.isRequired,
    fetchLanguages: PropTypes.func.isRequired,
    fetchCountries: PropTypes.func.isRequired,
    fetchSpecialties: PropTypes.func.isRequired,
  };

  static defaultProps = {
    admin: false,
    tReady: false,
    editionMode: false,
    user: undefined,
    onFormChange: () => {},
    onFormValidChange: () => {},
    onFormValidityChange: () => {},
  };

  static getCategory = (type) => {
    if (INDIVIDUAL_TYPES.includes(type)) return INDIVIDUAL;
    if (ORGANIZATION_TYPES.includes(type)) return ORGANIZATION;
    console.error(`Unexpected type (${type}).`);
    return undefined;
  }

  constructor(props) {
    super(props);
    this.state = {
      dataLoading: props.editionMode,
      category: '',
      values: {
        typeInputValue: '',
        civilityInputValue: '',
        subtypeInputValue: '',
        lastNameInputValue: '',
        firstNameInputValue: '',
        emailInputValue: '',
        passwordInputValue: '',
        specialtyInputValue: '',
        institutionInputValue: '',
        cityInputValue: '',
        countryInputValue: '',
        proNumberInputValue: '',
        languageInputValue: '',
        acronymInputValue: '',
        addressInputValue: '',
        zipCodeInputValue: '',
        phoneInputValue: '',
        websiteInputValue: '',
        emailAltInputValue: '',
        phoneAltInputValue: '',
        gcsuInputValue: false,
        commitmentInputValue: false,
      },
    };
    this.inputRefs = {};
    this.languageOptions = null;
    this.getLanguagesDOM = memoize((languages, trans) => {
      if (!isEqual(trans, {}) || !this.languageOptions) {
        this.languageOptions = this.getLanguages(languages, trans || {});
      }
      return this.languageOptions;
    });
    this.getSpecialtiesDOM = memoize(this.getSpecialties);
    this.getCountriesDOM = memoize(this.getCountries);
  }

  componentDidMount() {
    this.fetchData();
    this.props.i18n.on('languageChanged', this.fetchData);
  }

  componentWillUnmount() {
    this.props.i18n.off('languageChanged', this.fetchData);
  }

  getTypes(types) {
    const { t } = this.props;
    const { category } = this.state;
    return types.map((type) => (
      {
        text: (
          <span>
            {t(`user:types.${type}`, { context: category === ORGANIZATION ? 'pascal_case' : '' })}
            <OptionHelp>
              {category === ORGANIZATION ? (
                <span>
                  {t(`user:types.${type}-help`)}
                </span>
              ) : (
                <span>
                  {t(`user:types.${type}-help-1`)}
                  <br />
                  {t(`user:types.${type}-help-2`)}
                </span>
              )}
            </OptionHelp>
          </span>
        ),
        value: type,
      }
    ));
  }

  getSubtypesKeys(type) {
    switch (type) {
      case USER_TYPE_PRACTITIONER:
        return [USER_SUBTYPE_DOCTOR, USER_SUBTYPE_PROFESSOR];
      case USER_TYPE_ASSISTANT:
        return [USER_SUBTYPE_SECRETARY, USER_SUBTYPE_NURSE, USER_SUBTYPE_CRA,
          USER_SUBTYPE_OTHER];
      default:
        return [];
    }
  }

  getSubtypes(type, subtype) {
    const { t, editionMode } = this.props;
    const subtypes = this.getSubtypesKeys(type);

    const res = subtypes.map((stype) => (
      <option
        key={stype}
        value={stype}
      >
        {t(`user:subtypes.${stype}`)}
      </option>
    ));
    if (editionMode && !subtype) {
      res.unshift(
        <option
          aria-label="Empty"
          key="0"
          value=""
        />,
      );
    }
    return res;
  }

  getDefaultSubtype = (type) => {
    const subtypes = this.getSubtypesKeys(type);
    return subtypes.length ? subtypes[0] : '';
  };

  getSortedAndTranslatedOptions(entities, trans, idLabel = 'key', keyLabel = 'key',
    enableEmptyChoice = false) {
    const res = entities.map((entity) => ({
      key: entity[idLabel],
      label: trans[entity[keyLabel]] || entity[keyLabel],
    })).sort((a, b) => a.label.localeCompare(b.label)).map((entity) => (
      <option
        key={entity.key}
        value={entity.key}
      >
        {entity.label}
      </option>
    ));
    res.unshift(
      <option
        key="0"
        aria-label="Empty"
        disabled={!enableEmptyChoice}
      />,
    );
    return res;
  }

  getLanguages(languages, trans) {
    return this.getSortedAndTranslatedOptions(languages, trans);
  }

  getSpecialties(specialties, trans, enableEmptyChoice) {
    return this.getSortedAndTranslatedOptions(specialties, trans, 'key', 'key', enableEmptyChoice);
  }

  getCountries(countries, trans) {
    return this.getSortedAndTranslatedOptions(Object.values(countries), trans, 'id', 'name');
  }

  handleFormChange = (field, value) => {
    this.setState(
      (prevState) => {
        const newValues = {
          values: {
            ...prevState.values,
            [field]: value,
          },
          valuesValidity: {
            ...prevState.valuesValidity,
          },
        };
        if (field === 'typeInputValue') {
          const defaultSubtype = this.getDefaultSubtype(value);
          newValues.values.subtypeInputValue = defaultSubtype;
          newValues.valuesValidity.subtypeInputValid = Boolean(defaultSubtype);
        }
        return newValues;
      },
      () => {
        const { onFormChange, onFormValidityChange } = this.props;
        onFormChange({ ...this.state.values });
        onFormValidityChange({ ...this.state.valuesValidity });
      },
    );
  };

  handleFormValidChange = (field, value) => {
    this.props.onFormValidChange({ [field]: value });
  };

  fetchData = async () => {
    this.setState({ dataLoading: true });

    const {
      editionMode, fetchUser, fetchProjectUsers, fetchLanguages, fetchSpecialties,
      fetchCountries, admin,
    } = this.props;
    const dataList = [fetchLanguages(), fetchSpecialties(), fetchCountries()];

    if (editionMode && !admin) dataList.push(fetchUser());
    if (admin) dataList.push(fetchProjectUsers(this.props.user.id));

    try {
      await Promise.all(dataList);
      const { user } = this.props;
      if (editionMode) {
        this.setState({
          category: AccountForm.getCategory(user.type),
          values: {
            typeInputValue: user.type,
            civilityInputValue: user.civility || '',
            subtypeInputValue: user.subtype,
            lastNameInputValue: user.last_name,
            firstNameInputValue: user.first_name,
            emailInputValue: user.email,
            emailValidatedInputValue: user.email_validated,
            specialtyInputValue: user.specialty,
            institutionInputValue: user.institution,
            cityInputValue: user.city,
            countryInputValue: `${user.country}`,
            proNumberInputValue: user.professional_number,
            languageInputValue: user.language,
            acronymInputValue: user.username,
            addressInputValue: user.address,
            zipCodeInputValue: user.zip_code,
            phoneInputValue: user.phone_number,
            websiteInputValue: user.website,
            emailAltInputValue: user.email_alt,
            phoneAltInputValue: user.phone_number_alt,
          },
        });
      }
    } catch (error) {
      ErrorUtil.handleCatched(this.props, error, false);
    } finally {
      this.setState({ dataLoading: false });
    }
  };

  deleteUser = async () => {
    const { deleteUser, t, user } = this.props;
    try {
      await deleteUser(user.id);
      Toast.success(this.props, t('user:deletion-success'), 4000);
      history.push('/admin/users');
    } catch (error) {
      ErrorUtil.handleCatched(this.props, error);
    }
  };

  validate() {
    const {
      type, subtype, civility, lastName, firstName, email, password, specialty, institution,
      proNumber, language, city, country, address, phone, website, zipCode, emailAlt, phoneAlt,
      commitment, gcsu,
    } = this.inputRefs;
    const { values, category } = this.state;
    const { typeInputValue } = values;
    let fields = {};

    switch (category) {
      case INDIVIDUAL:
        fields = {
          type,
          civility,
          subtype,
          firstName,
          lastName,
          email,
          password,
          specialty,
          institution,
          proNumber,
          language,
          city,
          country,
          commitment,
          gcsu,
        };
        break;

      case ORGANIZATION:
        fields = {
          type,
          institution,
          email,
          password,
          specialty,
          proNumber,
          address,
          zipCode,
          country,
          city,
          phone,
          website,
          language,
          civility,
          firstName,
          lastName,
          emailAlt,
          phoneAlt,
          commitment,
          gcsu,
        };
        break;

      default:
        console.error(`Unexpected category: ${category}.`);
    }

    switch (typeInputValue) {
      case USER_TYPE_ASSISTANT:
        delete fields.specialty;
        delete fields.proNumber;
        delete fields.institution;
        break;

      case USER_TYPE_INTERN:
        delete fields.subtype;
        break;

      case ORGANIZATION_HEALTHCARE_FACILITY:
        delete fields.specialty;
        break;

      default:
        break;
    }

    let res = true;
    Object.entries(fields).forEach(([name, ref]) => {
      if (!ref.validate(this.state.values[`${name}InputValue`])) {
        res = false;
      }
    });

    return res;
  }

  renderCategory = (inputClassNames) => {
    const { editionMode, t, admin } = this.props;
    const { category } = this.state;

    return (
      <LabeledChoice
        label={t('user:category')}
        name="category"
        value={category}
        choices={[{
          text: (
            <span>
              {t('user:individual')}
              <OptionHelp>
                {t('user:individual-help')}
              </OptionHelp>
            </span>
          ),
          value: INDIVIDUAL,
        }, {
          text: (
            <span>
              {t('user:organization-or-healthcare-facility')}
              <OptionHelp>
                <Trans i18nKey="user:organization-help" />
              </OptionHelp>
            </span>
          ),
          value: ORGANIZATION,
        }]}
        {...inputClassNames}
        required
        disabled={editionMode && !admin}
        onChange={(e) => {
          this.handleFormChange('typeInputValue', '');
          this.setState({ category: e.target.value });
        }}
        labelClassName="field-label font-weight-semibold text-underline"
        orientation="vertical"
      />
    );
  };

  renderType = (inputClassNames, types) => {
    const { editionMode, t, admin } = this.props;
    const { category } = this.state;
    const { typeInputValue } = this.state.values;
    const extraProps = {};
    if (!editionMode) {
      extraProps.labelClassName = 'field-label font-weight-semibold text-underline';
    }
    let labelKey;

    if (editionMode) {
      labelKey = 'user:type.edition';
    } else if (category === INDIVIDUAL) {
      labelKey = 'user:type.creation.individual';
    } else {
      labelKey = 'user:type.creation.organization';
    }

    return (
      <LabeledChoice
        label={(
          <SectionLabel>
            {t(labelKey)}
          </SectionLabel>
        )}
        name="type"
        value={typeInputValue}
        choices={this.getTypes(types)}
        {...inputClassNames}
        required
        disabled={editionMode && !admin}
        onChange={(e) => {
          const { value } = e.target;
          this.handleFormChange('typeInputValue', value);
          this.handleFormValidChange('typeInputValue', value);
        }}
        ref={(ref) => { this.inputRefs.type = ref; }}
        orientation="vertical"
        {...extraProps}
      />
    );
  };

  renderCivility = (selectClassNames) => {
    const { t, editionMode } = this.props;
    const { civilityInputValue } = this.state.values;

    return (
      <LabeledSelect
        label={t('user:civility')}
        name="civility"
        value={civilityInputValue || ''}
        {...selectClassNames}
        required
        onChange={(e) => {
          const value = e.target.value || '';
          this.handleFormChange('civilityInputValue', value);
          this.handleFormValidChange('civilityInputValue', value);
        }}
        ref={(ref) => { this.inputRefs.civility = ref; }}
      >
        {(!editionMode || !civilityInputValue) && (
          <option
            aria-label="Empty"
            key="unknown"
            value={USER_CIVILITY_UNKNOWN}
          />
        )}
        <option
          key={USER_CIVILITY_MR}
          value={USER_CIVILITY_MR}
        >
          {t('user:mister')}
        </option>
        <option
          key={USER_CIVILITY_MRS}
          value={USER_CIVILITY_MRS}
        >
          {t('user:misses')}
        </option>
        <option
          key={USER_CIVILITY_OTHER}
          value={USER_CIVILITY_OTHER}
        >
          {t('user:other-civility')}
        </option>

      </LabeledSelect>
    );
  };

  renderSubtype = (selectClassNames) => {
    const { t } = this.props;
    const { typeInputValue, subtypeInputValue } = this.state.values;

    return (
      <LabeledSelect
        label={t(`user:subtype.${typeInputValue === USER_TYPE_PRACTITIONER ? 'practitioner' : 'other'}`)}
        name="subtype"
        value={subtypeInputValue || ''}
        {...selectClassNames}
        required
        onChange={(e) => {
          const { value } = e.target;
          this.handleFormChange('subtypeInputValue', value);
          this.handleFormValidChange('subtypeInputValue', value);
        }}
        ref={(ref) => { this.inputRefs.subtype = ref; }}
      >
        {this.getSubtypes(typeInputValue, subtypeInputValue)}
      </LabeledSelect>
    );
  };

  renderFirstNameBase = (inputClassNames, label, name, disabled = false) => {
    const { t } = this.props;
    const { firstNameInputValue } = this.state.values;

    return (
      <LabeledInput
        type="text"
        label={label}
        name={name}
        placeholder={t('error:placeholder.first-name')}
        validation="human_name"
        {...inputClassNames}
        required
        disabled={disabled}
        value={firstNameInputValue}
        onChange={(e) => this.handleFormChange('firstNameInputValue', e.target.value)}
        onValidChange={(e) => this.handleFormValidChange('firstNameInputValue', e.target.value)}
        ref={(ref) => { this.inputRefs.firstName = ref; }}
      />
    );
  };

  renderFirstName = (inputClassNames) => {
    const { t, editionMode, admin } = this.props;
    const label = (
      <SectionLabel>
        {t('user:first-name')}
      </SectionLabel>
    );

    return this.renderFirstNameBase(inputClassNames, label, 'firstname',
      editionMode && !admin);
  };

  renderContactFirstName = (inputClassNames) => {
    const { t } = this.props;

    return this.renderFirstNameBase(inputClassNames, t('user:first-name'),
      'contact-firstname');
  };

  renderLastNameBase = (inputClassNames, label, name, disabled = false) => {
    const { t } = this.props;
    const { dataLoading } = this.state;
    const { lastNameInputValue } = this.state.values;

    return (
      <LabeledInput
        type="text"
        label={label}
        name={name}
        placeholder={t('error:placeholder.last-name')}
        validation="human_name"
        {...inputClassNames}
        required
        disabled={disabled}
        readOnly={dataLoading}
        value={lastNameInputValue}
        onChange={(e) => this.handleFormChange('lastNameInputValue', e.target.value)}
        onValidChange={(e) => this.handleFormValidChange('lastNameInputValue', e.target.value)}
        ref={(ref) => { this.inputRefs.lastName = ref; }}
      />
    );
  }

  renderLastName = (inputClassNames) => {
    const { t, editionMode, admin } = this.props;
    const label = (
      <SectionLabel>
        {t('user:last-name')}
      </SectionLabel>
    );

    return this.renderLastNameBase(inputClassNames, label, 'lastname', editionMode && !admin);
  };

  renderContactLastName = (inputClassNames) => {
    const { t } = this.props;

    return this.renderLastNameBase(inputClassNames, t('user:last-name'), 'contact-lastname');
  };

  renderEmailBase = (
    inputClassNames, label, name, disabled, valueName, refName, needsValidation = false,
  ) => {
    const { t, admin } = this.props;
    const {
      [valueName]: value, typeInputValue, emailValidatedInputValue,
    } = this.state.values;

    let emailPlaceholder = t('error:placeholder.email');
    if (INSTITUTION_TYPES.includes(typeInputValue)) {
      emailPlaceholder = t('error:placeholder.organization-email');
      if (typeInputValue === USER_TYPE_HEALTHCARE_FACILITY) {
        emailPlaceholder = t('error:placeholder.healthcare-facility-email');
      }
    }

    return (
      <LabeledInput
        type="email"
        label={label}
        name={name}
        placeholder={emailPlaceholder}
        validation="email"
        feedback={t('error:feedback.email')}
        {...inputClassNames}
        inputClassName="labeled-input mw-350px"
        required
        disabled={disabled}
        value={value}
        onChange={(e) => this.handleFormChange(valueName, e.target.value)}
        onValidChange={(e) => this.handleFormValidChange(valueName, e.target.value)}
        ref={(ref) => { this.inputRefs[refName] = ref; }}
        append={admin && needsValidation ? (
          <div className="mt-2">
            {emailValidatedInputValue ? (
              <span className="text-green">
                Adresse email confirmée
              </span>
            ) : (
              <Checkbox
                id="userVerifiedCheckbox"
                checked={emailValidatedInputValue}
                disabled={disabled}
                onChange={(e) => {
                  const { checked } = e.target;
                  this.handleFormChange('emailValidatedInputValue', checked);
                  this.handleFormValidChange('emailValidatedInputValue', checked);
                }}
              >
                Forcer la confirmation de l&apos;adresse email
              </Checkbox>
            )}
          </div>
        ) : null}
      />
    );
  };

  renderEmail = (inputClassNames, label) => {
    const { editionMode, admin } = this.props;
    const effectiveLabel = (
      <SectionLabel>
        {label}
      </SectionLabel>
    );

    return this.renderEmailBase(inputClassNames, effectiveLabel, 'email', editionMode && !admin,
      'emailInputValue', 'email', true);
  };

  renderContactEmail = (inputClassNames) => {
    const { t } = this.props;

    return this.renderEmailBase(inputClassNames, t('user:email'), 'contact-email', false,
      'emailAltInputValue', 'emailAlt');
  };

  renderPassword = () => {
    const { t, i18n, editionMode } = this.props;
    const passwordZone = (
      <PasswordForm
        editionMode={editionMode}
        i18n={i18n}
        onFormChange={(value) => this.handleFormChange('passwordInputValue', value)}
        ref={(ref) => {
          if (ref && ref.inputRefs) {
            this.inputRefs.password = ref.inputRefs.newPassword;
          }
        }}
      />
    );

    return editionMode ? (
      <div className="row">
        <div className="col-12 col-sm-12 col-md-4 col-lg-3 col-xl-2">
          <Label className="field-label">
            {t('user:password')}
          </Label>
        </div>
        <div className="col-12 col-sm-12 col-md-6">
          {passwordZone}
        </div>
      </div>
    ) : passwordZone;
  };

  renderSpecialty = (selectClassNames, getTranslations, required) => {
    const {
      t, i18n, editionMode, specialties, admin,
    } = this.props;
    const { language } = i18n;
    const { specialtyInputValue } = this.state.values;

    return (
      <LabeledSelect
        label={(
          <SectionLabel>
            {t('user:specialty')}
          </SectionLabel>
        )}
        name="specialty"
        required={required}
        {...selectClassNames}
        disabled={editionMode && !admin}
        value={specialtyInputValue || ''}
        onChange={(e) => {
          const { value } = e.target;
          this.handleFormChange('specialtyInputValue', value);
          this.handleFormValidChange('specialtyInputValue', value);
        }}
        ref={(ref) => { this.inputRefs.specialty = ref; }}
      >
        {this.getSpecialtiesDOM(
          Object.values(specialties),
          getTranslations(language, 'specialty'),
          !required,
        )}
      </LabeledSelect>
    );
  }

  renderCountry = (selectClassNames, getTranslations) => {
    const {
      t, i18n, countries, editionMode, admin,
    } = this.props;
    const { language } = i18n;
    const { countryInputValue } = this.state.values;

    return (
      <LabeledSelect
        label={(
          <SectionLabel>
            {t('user:country')}
          </SectionLabel>
        )}
        name="country"
        {...selectClassNames}
        required
        disabled={editionMode && !admin}
        value={countryInputValue || ''}
        onChange={(e) => {
          const { value } = e.target;
          this.handleFormChange('countryInputValue', value);
          this.handleFormValidChange('countryInputValue', value);
        }}
        ref={(ref) => { this.inputRefs.country = ref; }}
      >
        {this.getCountriesDOM(countries, getTranslations(language, 'country'))}
      </LabeledSelect>
    );
  };

  renderCity = (inputClassNames) => {
    const { t, editionMode, admin } = this.props;
    const { cityInputValue } = this.state.values;

    return (
      <LabeledInput
        type="text"
        label={(
          <SectionLabel>
            {t('user:city')}
          </SectionLabel>
        )}
        name="city"
        placeholder={t('error:placeholder.city')}
        validation="city"
        feedback={t('error:feedback.city')}
        {...inputClassNames}
        required
        disabled={editionMode && !admin}
        value={cityInputValue}
        onChange={(e) => this.handleFormChange('cityInputValue', e.target.value)}
        onValidChange={(e) => this.handleFormValidChange('cityInputValue', e.target.value)}
        ref={(ref) => { this.inputRefs.city = ref; }}
      />
    );
  };

  renderProfessionalNumber = (inputClassNames, label, placeholder) => {
    const { proNumberInputValue } = this.state.values;

    return (
      <LabeledInput
        type="text"
        label={label}
        name="professional-number"
        validation="professional_number"
        placeholder={placeholder}
        {...inputClassNames}
        value={proNumberInputValue}
        onChange={(e) => this.handleFormChange('proNumberInputValue', e.target.value)}
        onValidChange={(e) => this.handleFormValidChange('proNumberInputValue', e.target.value)}
        ref={(ref) => { this.inputRefs.proNumber = ref; }}
      />
    );
  };

  renderInstitution = (inputClassNames, label, placeholder, feedback, required, disabled) => {
    const { institutionInputValue } = this.state.values;

    return (
      <LabeledInput
        type="text"
        label={label}
        name="institution"
        placeholder={placeholder}
        validation="institution"
        feedback={feedback}
        required={required}
        disabled={disabled}
        {...inputClassNames}
        value={institutionInputValue}
        onChange={(e) => this.handleFormChange('institutionInputValue', e.target.value)}
        onValidChange={(e) => this.handleFormValidChange('institutionInputValue', e.target.value)}
        ref={(ref) => { this.inputRefs.institution = ref; }}
      />
    );
  };

  renderLanguage = (selectClassNames, getTranslations) => {
    const {
      t, i18n, editionMode, languages,
    } = this.props;
    const { language } = i18n;
    const { languageInputValue } = this.state.values;

    return (
      <LabeledSelect
        label={t(`user:language.${editionMode ? 'edition' : 'creation'}`)}
        name="language"
        {...selectClassNames}
        required
        value={languageInputValue || ''}
        onChange={(e) => {
          const { value } = e.target;
          this.handleFormChange('languageInputValue', value);
          this.handleFormValidChange('languageInputValue', value);
        }}
        ref={(ref) => { this.inputRefs.language = ref; }}
      >
        { this.getLanguagesDOM(Object.values(languages), getTranslations(language, 'locale')) }
      </LabeledSelect>
    );
  };

  renderAgreements = () => {
    const { t, i18n } = this.props;
    const { commitmentInputValue, gcsuInputValue } = this.state.values;

    return (
      <>
        <Checkbox
          id="commitmentCheckbox"
          checked={commitmentInputValue || false}
          required
          onChange={(e) => {
            this.handleFormChange('commitmentInputValue', e.target.checked);
          }}
          ref={(ref) => { this.inputRefs.commitment = ref; }}
        >
          {t('user:accurate-informations')}
        </Checkbox>
        <Checkbox
          className="mt-2"
          id="gcsuCheckbox"
          checked={gcsuInputValue || false}
          required
          onChange={(e) => {
            this.handleFormChange('gcsuInputValue', e.target.checked);
          }}
          ref={(ref) => { this.inputRefs.gcsu = ref; }}
        >
          {t('user:i-have-read-and-accept')}
          &nbsp;
          <NewTooltip
            content={t('user:gcsu-expanded')}
          >
            <a
              className="cursor-pointer classical-link text-newturquoise-1"
              href={getCgsuUrl(i18n.language)}
              target="_blank"
              rel="noopener noreferrer"
            >
              {t('user:gcsu')}
            </a>
          </NewTooltip>
        </Checkbox>
      </>
    );
  };

  renderAcronym = (inputClassNames) => {
    const { t } = this.props;
    const { acronymInputValue } = this.state.values;

    return (
      <LabeledInput
        type="text"
        label={t('user:acronym')}
        name="city"
        placeholder={t('error:placeholder.acronym')}
        validation="city"
        feedback={t('error:feedback.acronym')}
        {...inputClassNames}
        value={acronymInputValue}
        onChange={(e) => this.handleFormChange('acronymInputValue', e.target.value)}
        onValidChange={(e) => this.handleFormValidChange('acronymInputValue',
          e.target.value)}
        ref={(ref) => { this.inputRefs.acronym = ref; }}
      />
    );
  };

  renderAddress = (inputClassNames) => {
    const { t, editionMode } = this.props;
    const { addressInputValue } = this.state.values;

    return (
      <LabeledInput
        type="textarea"
        label={t('user:address')}
        name="city"
        placeholder={t('error:placeholder.address')}
        validation="address"
        required
        feedback={t('error:feedback.address')}
        {...inputClassNames}
        value={addressInputValue}
        onChange={(e) => this.handleFormChange('addressInputValue', e.target.value)}
        onValidChange={(e) => this.handleFormValidChange('addressInputValue',
          e.target.value)}
        ref={(ref) => { this.inputRefs.address = ref; }}
        inputClassName={`labeled-input${editionMode ? '' : ' text-white'}`}
      />
    );
  }

  renderZipCode = (inputClassNames) => {
    const { t, editionMode, admin } = this.props;
    const { zipCodeInputValue } = this.state.values;

    return (
      <LabeledInput
        type="text"
        label={(
          <SectionLabel>
            {t('user:zip-code')}
          </SectionLabel>
        )}
        name="city"
        placeholder={t('error:placeholder.zip')}
        validation="zip"
        required
        disabled={editionMode && !admin}
        {...inputClassNames}
        value={zipCodeInputValue}
        onChange={(e) => this.handleFormChange('zipCodeInputValue', e.target.value)}
        onValidChange={(e) => this.handleFormValidChange('zipCodeInputValue',
          e.target.value)}
        ref={(ref) => { this.inputRefs.zipCode = ref; }}
      />
    );
  }

  renderPhoneBase = (inputClassNames, label, name, valueName, refName, required) => {
    const { t } = this.props;
    const { [valueName]: value } = this.state.values;

    return (
      <LabeledInput
        type="text"
        label={label}
        name={name}
        placeholder={t('error:placeholder.phone')}
        validation="phone"
        required={required}
        {...inputClassNames}
        value={value}
        onChange={(e) => this.handleFormChange(valueName, e.target.value)}
        onValidChange={(e) => this.handleFormValidChange(valueName, e.target.value)}
        ref={(ref) => { this.inputRefs[refName] = ref; }}
      />
    );
  };

  renderPhone = (inputClassNames) => {
    const { t } = this.props;

    return this.renderPhoneBase(inputClassNames, t('user:phone'), 'phone', 'phoneInputValue',
      'phone', true);
  };

  renderContactPhone = (inputClassNames) => {
    const { t } = this.props;

    return this.renderPhoneBase(inputClassNames, t('user:phone'), 'contact-phone',
      'phoneAltInputValue', 'phoneAlt', false);
  };

  renderContactPerson = () => {
    const { t, editionMode } = this.props;
    const inputClassNames = {
      colInputClassName: editionMode ? 'col-auto' : undefined,
    };
    const selectClassNames = {
      colInputClassName: undefined,
      colSelectClassName: inputClassNames.colInputClassName,
    };
    const topMargin = 'mt-4';
    const contactForm = (
      <div className={`mt-2${editionMode ? '' : 'ml-3'}`}>
        <div>
          {this.renderCivility(selectClassNames)}
        </div>
        <div>
          {this.renderContactFirstName(inputClassNames)}
        </div>
        <div className={`${topMargin}`}>
          {this.renderContactLastName(inputClassNames)}
        </div>
        <div className={`${topMargin}`}>
          {this.renderContactEmail(inputClassNames)}
        </div>
        <div className={`${topMargin}`}>
          {this.renderContactPhone(inputClassNames)}
        </div>
      </div>
    );

    return (
      <div className={`row${editionMode ? ' field-row' : ''}`}>
        <div className={`col-12${editionMode ? ' col-sm-12 col-md-4 col-lg-3 col-xl-2' : ''}`}>
          <Label className={editionMode ? 'field-label' : 'font-weight-semibold text-underline'}>
            {t('user:contact-person')}
          </Label>
        </div>
        <div className={`col-12${editionMode ? ' col-sm-12 col-md-6' : ' mt-2'}`}>
          {contactForm}
        </div>
      </div>
    );
  }

  renderWebsite = (inputClassNames) => {
    const { t } = this.props;
    const { websiteInputValue } = this.state.values;

    return (
      <LabeledInput
        type="text"
        label={t('user:website')}
        name="website"
        placeholder={t('error:placeholder.website')}
        validation="url"
        {...inputClassNames}
        value={websiteInputValue}
        onChange={(e) => this.handleFormChange('websiteInputValue', e.target.value)}
        onValidChange={(e) => this.handleFormValidChange('websiteInputValue',
          e.target.value)}
        ref={(ref) => { this.inputRefs.website = ref; }}
      />
    );
  }

  renderSignUpDate = () => {
    const { t, editionMode, user } = this.props;
    return user ? (
      <div className={`row${editionMode ? ' field-row' : ''}`}>
        <div className={`col-12${editionMode ? ' col-sm-12 col-md-4 col-lg-3 col-xl-2' : ''}`}>
          <Label className={editionMode ? 'field-label' : ''}>
            {t('user:date-joined')}
          </Label>
        </div>
        <div className={`mt-2 col-12${editionMode ? ' col-sm-12 col-md-6' : ''}`}>
          {moment(user.date_joined).format('DD/MM/YYYY HH:mm:ss')}
        </div>
      </div>
    ) : <span />;
  }

  renderDelete = () => {
    const { t, projectUsers } = this.props;
    const { dataLoading } = this.state;
    const projectCount = projectUsers.length;

    return (
      <div className="row">
        <div className="col-12 col-sm-12 col-md-4 col-lg-3 col-xl-2">
          <Label className="field-label">
            {t('user:deletion')}
          </Label>
        </div>
        <div className="col-12 col-sm-12 col-md-6">
          <DelMsgModal
            message={(
              <span>
                <b>
                  {t('common:caution')}
                </b>
                <br />
                <br />
                {t('user:deletion-warning.part-1')}
                <br />
                <br />
                {t('user:deletion-warning.part-2')}
                <br />
                <br />
                <strong>
                  {projectCount
                    ? t('user:participates-in-projects', { count: projectCount })
                    : t('user:does-not-participate-in-project')}
                </strong>
                <br />
                <br />
                {t('common:confirm-deletion')}
              </span>
            )}
            onValidate={this.deleteUser}
          >
            <ButtonConfirm>
              <button
                type="button"
                className="btn btn-primary"
                disabled={dataLoading}
              >
                {t('user:delete-button')}
              </button>
            </ButtonConfirm>
          </DelMsgModal>
        </div>
      </div>
    );
  };


  renderIndividual = (commonClassName, inputClassNames, selectClassNames, getTranslations) => {
    const { t, editionMode, admin } = this.props;
    const { typeInputValue } = this.state.values;

    return (
      <>
        <Section show={!editionMode || admin} className={commonClassName} editionMode={editionMode}>
          {this.renderType(inputClassNames, INDIVIDUAL_TYPES)}
        </Section>
        {
          Boolean(editionMode || typeInputValue) && (
            <>
              <Section className={commonClassName} editionMode={editionMode}>
                {this.renderCivility(selectClassNames)}
              </Section>
              <Section
                className={commonClassName}
                show={typeInputValue !== USER_TYPE_INTERN}
                editionMode={editionMode}
              >
                {this.renderSubtype(selectClassNames)}
              </Section>
              <Section className={commonClassName} editionMode={editionMode}>
                {this.renderFirstName(inputClassNames)}
              </Section>
              <Section className={commonClassName} editionMode={editionMode}>
                {this.renderLastName(inputClassNames)}
              </Section>
              <Section className={commonClassName} editionMode={editionMode}>
                {this.renderEmail(inputClassNames, t('user:email'))}
              </Section>
              <Section show={!admin} editionMode={editionMode}>
                {this.renderPassword()}
              </Section>
              <Divider show={!editionMode} />
              <Section
                className={commonClassName}
                show={typeInputValue !== USER_TYPE_ASSISTANT}
                editionMode={editionMode}
              >
                {this.renderSpecialty(
                  selectClassNames,
                  getTranslations,
                  typeInputValue !== USER_TYPE_ASSISTANT,
                )}
              </Section>
              <Section className={commonClassName} editionMode={editionMode}>
                {this.renderCountry(selectClassNames, getTranslations)}
              </Section>
              <Section className={commonClassName} editionMode={editionMode}>
                {this.renderCity(inputClassNames)}
              </Section>
              <Section
                className={commonClassName}
                show={typeInputValue !== USER_TYPE_ASSISTANT}
                editionMode={editionMode}
              >
                {
                  this.renderProfessionalNumber(
                    inputClassNames,
                    t('user:pro-number'),
                    t('error:placeholder.professional-number'),
                  )
                }
              </Section>
              <Section
                className={commonClassName}
                show={typeInputValue !== USER_TYPE_ASSISTANT}
                editionMode={editionMode}
              >
                {
                  this.renderInstitution(
                    inputClassNames,
                    t('user:institution'),
                    t('error:placeholder.institution'),
                    t('error:feedback.institution'),
                    false,
                  )
                }
              </Section>
              <Section className={commonClassName} editionMode={editionMode}>
                {this.renderLanguage(selectClassNames, getTranslations)}
              </Section>
            </>
          )
        }
      </>
    );
  }

  renderOrganization = (commonClassName, inputClassNames, selectClassNames, getTranslations) => {
    const { t, editionMode, admin } = this.props;
    const { typeInputValue } = this.state.values;

    const translationTag = typeInputValue === USER_TYPE_HEALTHCARE_FACILITY ? 'healthcare-facility' : 'organization';

    return (
      <>
        <Section show={!editionMode || admin} className={commonClassName} editionMode={editionMode}>
          {this.renderType(inputClassNames, ORGANIZATION_TYPES)}
        </Section>
        {
          Boolean(editionMode || typeInputValue) && (
            <>
              <Section className={commonClassName} editionMode={editionMode}>
                {
                  this.renderInstitution(
                    inputClassNames,
                    <SectionLabel>
                      {t('user:organization-name')}
                    </SectionLabel>,
                    t(`error:placeholder.${translationTag}-name`),
                    t('error:feedback.organization-name'),
                    true,
                    editionMode && !admin,
                  )
                }
              </Section>
              <Section className={commonClassName} editionMode={editionMode}>
                {this.renderAcronym(inputClassNames)}
              </Section>
              <Section className={commonClassName} editionMode={editionMode}>
                {this.renderEmail(inputClassNames, t(`user:${translationTag}-email`))}
              </Section>
              <Section show={!admin} editionMode={editionMode}>
                {this.renderPassword()}
              </Section>
              <Divider show={!editionMode} />
              <Section
                className={commonClassName}
                show={typeInputValue !== USER_TYPE_HEALTHCARE_FACILITY}
                editionMode={editionMode}
              >
                {
                  this.renderSpecialty(
                    selectClassNames,
                    getTranslations,
                    typeInputValue === ORGANIZATION_ASSOCIATION,
                  )
                }
              </Section>
              <Section className={commonClassName} editionMode={editionMode}>
                {
                  this.renderProfessionalNumber(
                    inputClassNames,
                    t('user:official-number'),
                    t('error:placeholder.official-number'),
                  )
                }
              </Section>
              <Section className={commonClassName} editionMode={editionMode}>
                {this.renderAddress(inputClassNames)}
              </Section>
              <Section className={commonClassName} editionMode={editionMode}>
                {this.renderZipCode(inputClassNames)}
              </Section>
              <Section className={commonClassName} editionMode={editionMode}>
                {this.renderCity(inputClassNames)}
              </Section>
              <Section className={commonClassName} editionMode={editionMode}>
                {this.renderCountry(selectClassNames, getTranslations)}
              </Section>
              <Section className={commonClassName} editionMode={editionMode}>
                {this.renderPhone(inputClassNames)}
              </Section>
              <Section className={commonClassName} editionMode={editionMode}>
                {this.renderWebsite(inputClassNames)}
              </Section>
              <Section className={commonClassName} editionMode={editionMode}>
                {this.renderLanguage(selectClassNames, getTranslations)}
              </Section>
              <Divider show={!editionMode} />
              <Section className={commonClassName} editionMode={editionMode}>
                {this.renderContactPerson()}
              </Section>
            </>
          )
        }
      </>
    );
  };

  render() {
    const {
      t, tReady, editionMode, i18n, admin,
    } = this.props;
    const { getResourceBundle } = i18n;
    const { dataLoading, category, values } = this.state;
    const { typeInputValue } = values;
    const commonClassName = editionMode ? '' : 'pt-3';
    const inputClassNames = {
      rowClassName: `${editionMode ? 'row field-row' : undefined}`,
      colLabelClassName: `${editionMode ? 'col-12 col-sm-12 col-md-4 col-lg-3 col-xl-2' : undefined}`,
      labelClassName: `${editionMode ? 'field-label' : undefined}`,
      colInputClassName: `${editionMode ? 'col-auto' : undefined}`,
      autoComplete: 'new-password', // Weird fix that prevents some Chrome autocomplete issues...
    };
    const selectClassNames = {
      ...inputClassNames,
      colInputClassName: undefined,
      colSelectClassName: inputClassNames.colInputClassName,
    };
    const getTranslations = (lang, ns) => {
      let trans;
      if (tReady && !dataLoading) trans = getResourceBundle(lang, ns);
      return trans || {};
    };

    const supportEmail = deObfuscateEmail('support', 'doqboard.com');

    return (
      <div>
        {editionMode && (
          <div
            className="py-3 account-tab-subtitle"
            style={isMobileView() ? { padding: '1.5em' } : {}}
          >
            {t('user:account-form-subtitle', { email: supportEmail })}
          </div>
        )}
        {dataLoading && <CardLoader />}
        <div className={`${editionMode ? 'new-settings inclusion-settings' : ''}`}>
          <Section show={!editionMode} editionMode={editionMode}>
            {this.renderCategory(inputClassNames)}
          </Section>
          {category === INDIVIDUAL && this.renderIndividual(commonClassName,
            inputClassNames, selectClassNames, getTranslations)}
          {category === ORGANIZATION && this.renderOrganization(commonClassName,
            inputClassNames, selectClassNames, getTranslations)}
          <Section show={!dataLoading && admin}>
            {this.renderSignUpDate()}
          </Section>
          <Section show={!dataLoading && admin} editionMode={editionMode}>
            {this.renderDelete()}
          </Section>
          {
            Boolean(typeInputValue) && (
              <>
                <Divider show={!editionMode} />
                <Section
                  className={commonClassName}
                  colClassName="col-12 mt-2"
                  show={!editionMode}
                  editionMode={editionMode}
                >
                  {this.renderAgreements()}
                </Section>
              </>
            )
          }
        </div>
      </div>
    );
  }
}


export default AccountForm;
