import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { withTranslation, useTranslation } from 'react-i18next';
import { withToastManager } from 'react-toast-notifications';
import { Label, FormGroup, Input } from 'reactstrap';
import { Mutex } from 'async-mutex';
import memoize from 'memoize-one';
import { connect } from 'react-redux';
import Select from 'react-select';
import {
  projectUsersActions, projectsActions, projectPermissionsActions, teamMembersActions,
  teamLicensesActions, userLicensesActions, teamsActions, usersActions,
} from '../redux/actions';
import api from '../api';
import history from '../history';
import { nsOptions } from '../i18n';
import {
  CAN_MANAGE_EXTERNAL_INVITATIONS, CAN_INCLUDE, ADMIN_EDITABLE_PERMS, ADVANCED_EDITABLE_PERMS,
  BASIC_EDITABLE_PERMS, CAN_MANAGE_ADVANCED_PERMISSIONS, CAN_INVITE_EXTERNAL,
  CAN_INVITE_EXTERNAL_TEAM, CAN_INVITE_PROJECT_TEAM, CAN_INVITE_TEAM_MEMBER, ORGANIZATION_TYPES,
  PROJECT_STATS_ACCESS_ALL, PROJECT_STATS_ACCESS_RESTRICTED, PROJECT_STATS_ACCESS_NONE,
  PROJECT_ON_HOLD, PROJECT_IN_PROGRESS, PROJECT_CLOSED, PROJECT_USER_USUAL, PROJECT_PUBLIC,
  PROJECT_NOT_PUBLIC, PROJECT_PUBLIC_STATUS_REQUESTED, CAN_RENAME_PROJECT,
  CAN_CHANGE_PROJECT_CONNECTED_ACCOUNT, CAN_CHANGE_PROJECT_STATUS, CAN_MODIFY_PROJECT_TOPICS,
  CAN_CHANGE_PROJECT_STATS_ACCESS, CAN_CHANGE_PROJECT_VISIBILITY_STATUS, CAN_CLONE_PROJECT,
  CAN_DELETE_PROJECT, PROJECT_USER_OWNER_ONLY, CAN_NAME_MANAGER, CAN_PAUSE_PROJECT,
  CAN_CONFIRM_REGULATORY_APPROVAL, PROJECT_CLOSED_WITH_NO_MODIFICATIONS,
  PROJECT_CLOSED_WITH_MODIFICATIONS,
} from '../constants';
import Toast from '../utils/Toast';
import { isBrowserView } from './BrowserView';
import { isTabletView } from './TabletView';
import SortUtil from '../utils/SortUtil';
import ErrorUtil from '../utils/ErrorUtil';
import TextUtil from '../utils/TextUtil';
import { hasPermission, isProjectDisabled } from '../utils/data-util';
import LabeledChoice, { eventToMultipleChoicesValue } from './LabeledChoice';
import LabeledInput from './LabeledInput';
import LabeledSelect from './LabeledSelect';
import { CardLoader, MiniLoader } from './Loader';
import TopicSelect from './TopicSelect';
import UserSelect from './UserSelect';
import { validators } from './Validator';
import ButtonConfirm from './ButtonConfirm';
import { DelMsgModal, MessageModal } from './MessageModal';
import { LabeledUserAvatar } from './avatar';
import NewTooltip from './NewTooltip';
import Help from './Help';
import ProjectTeamZone, { PERSONAL_PROJECT } from './ProjectTeamZone';
import DropdownMenu from './NewDropdownMenu';
import CustomAsyncSelect, { defaultStyles } from './CustomAsyncSelect';
import ProjectUserPermissionSettings from './ProjectUserPermissionSettings';
import InvitationList, {
  EXTERNAL_INVITATION, TEAM_INVITATION, CREATE_TEAM, INDIVIDUALS, getPermissionType,
  getWritablePermissions,
} from './InvitationList';
import { VISIBILITY_OPTIONS } from './OrganizationProjects';
import { ProjectStatus } from './ProjectStatus';
import PauseProjectZone from './PauseProjectZone';
import RegulatoryConfirmationModal, { RegulatoryConfirmationIcon } from './RegulatoryConfirmationModal';
import { childrenPropTypes } from '../utils/generic-prop-types';
import { emailListToArray, formatPasteEmailList } from '../utils/email';
import downloadEndpoint from '../utils/downloadEndpoint';
import FAQLink from './FAQLink';
import TimeoutHandler from '../utils/TimeoutHandler';
import fromReduxState from '../utils/redux';

const QUIT_ALL_TEAMS = 0;

const AdminSelectTeam = (props) => {
  const {
    disabled, onChange, options, defaultValue,
  } = props;

  return (
    <Select
      className="react-select team-react-select"
      classNamePrefix="react-select"
      isDisabled={disabled}
      onChange={onChange}
      options={options}
      required
      defaultValue={defaultValue}
      styles={{
        control: (styles) => ({
          padding: '0 0.75rem 0.1rem 0 !important',
          ...styles,
        }),
      }}
    />
  );
};

AdminSelectTeam.propTypes = {
  options: PropTypes.arrayOf(PropTypes.shape()),
  onChange: PropTypes.func,
  defaultValue: PropTypes.shape(),
  disabled: PropTypes.bool,
};

AdminSelectTeam.defaultProps = {
  options: [],
  onChange: () => {},
  defaultValue: undefined,
  disabled: true,
};

const InvitationStepLabel = (props) => (
  <div className="container">
    <div className="row align-items-center">
      <FontAwesomeIcon
        icon={['far', 'angle-double-right']}
        className="text-primary mr-2"
        transform="grow-1"
      />
      {props.label}
      {props.helpText && (
        <Help
          iconClassName="ml-2"
        >
          {props.helpText}
        </Help>
      )}
    </div>
  </div>
);

InvitationStepLabel.propTypes = {
  label: PropTypes.string.isRequired,
  helpText: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
};

InvitationStepLabel.defaultProps = {
  helpText: undefined,
};

const SelectParticipants = (props) => {
  const {
    value, teamMembers, teamId, onChange, creatable, label,
  } = props;
  const { t } = useTranslation();

  const loadOptions = async (search) => teamMembers.filter((member) => (
    TextUtil.localeContains(member.user.label, search)
      || TextUtil.localeContains(member.email, search)
      || TextUtil.localeContains(member.user.email, search)
  )).map((member) => ({
    value: member.id,
    label: member.user.label,
  }));

  const styles = {
    ...defaultStyles,
    container: (provided, state) => ({
      ...provided,
      marginTop: (state.hasValue || state.selectProps.inputValue) && 13,
    }),
    placeholder: (provided, state) => ({
      ...provided,
      position: 'absolute',
      top: (state.hasValue || state.selectProps.inputValue) ? -9 : '50%',
      transition: 'top 0.1s, font-size 0.1s',
      fontSize: (state.hasValue || state.selectProps.inputValue) && 13,
      width: (state.hasValue || state.selectProps.inputValue) && 'max-content',
      color: 'var(--gray-dark)',
    }),
    control: (provided, state) => ({
      ...provided,
      borderColor: state.isFocused ? 'var(--newblue-1) !important' : 'var(--gray)',
    }),
  };

  const showNewInvitationMessage = value.filter((email) => email.__isNew__).length > 0;

  return (
    <div className="row">
      { label && (
        <div className="col-12">
          <Label className="project-invitation-step">
            {label}
          </Label>
        </div>
      )}
      <div className="col-12">
        <CustomAsyncSelect
          id="invite-react-select"
          creatable={creatable}
          placeholder={t(teamMembers.length || !creatable ? 'project:new-invitation.add-or-create'
            : 'project:new-invitation.create')}
    /* Needed to force to refresh options in case of team change */
    /* (see https://github.com/JedWatson/react-select/issues/1581 */
          key={teamId}
          className={`react-select creatable-select ${creatable ? 'team-invite-email' : ''}`}
          isClearable
          isMulti
          loadOptions={loadOptions}
          defaultOptions
          hideSelectedOptions
          noOptionsMessage={(inputValue) => {
            if (!inputValue) return t('project:new-invitation.no-member');
            if (!creatable) return t('project:new-invitation.contact-team-leader');
            return t('project:new-invitation.invalid-email');
          }}
          formatCreateLabel={(email) => (
            <span className="text-primary">
              {t('project:new-invitation.invite-email.part-1')}
              &nbsp;
              {email && email !== '' ? (
                <span className="badge badge-newblue-1">
                  {email}
                </span>
              ) : ''}
              &nbsp;
              {t('project:new-invitation.invite-email.part-2')}
            </span>
          )}
          isValidNewOption={(inputValue, val, options) => (
            options.length === 0 && validators.email(inputValue) === true
          )}
          openMenuOnFocus
          onChange={onChange}
          value={value}
          styles={styles}
        />
        {showNewInvitationMessage && (
          <div className="text-newgreen-2 pt-1">
            {t('project:new-invitation.new-guests')}
          </div>
        )}
      </div>
    </div>
  );
};

SelectParticipants.propTypes = {
  creatable: PropTypes.bool,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.shape()]),
  value: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  teamMembers: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  teamId: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
};

SelectParticipants.defaultProps = {
  creatable: false,
  label: undefined,
};

const Section = (props) => (
  props.show !== false && (
    <div id={props.id} className={props.className}>
      {props.children}
    </div>
  )
);

Section.propTypes = {
  show: PropTypes.bool,
  className: PropTypes.string,
  id: PropTypes.string,
  children: childrenPropTypes().isRequired,
};

Section.defaultProps = {
  show: true,
  className: 'row',
  id: '',
};

const SectionTitle = (props) => (
  <div className={props.className}>
    {props.children}
  </div>
);

SectionTitle.propTypes = {
  className: PropTypes.string,
  children: childrenPropTypes().isRequired,
};

SectionTitle.defaultProps = {
  className: 'col-12 col-sm-12 col-md-4 col-lg-3 col-xl-2',
};

const SectionContent = (props) => (
  <div className={props.className}>
    {props.children}
  </div>
);

SectionContent.propTypes = {
  className: PropTypes.string,
  children: childrenPropTypes().isRequired,
};

SectionContent.defaultProps = {
  className: 'col-auto col-md-8 col-lg-7 col-xl-6',
};

const mapStateToProps = (state, ownProps) => ({
  projectUsers: state.projectUsers,
  projectUser: Object.values(state.projectUsers).find((pUser) => (
    pUser.user.id === (ownProps.admin ? ownProps.project.manager.id : state.auth.authUser.id)
  )),
  teams: state.teams,
  teamMembers: state.teamMembers,
  teamLicenses: state.teamLicenses,
  userLicenses: state.userLicenses,
  user: state.auth.authUser,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  addProjectUser: async (data) => (
    dispatch(projectUsersActions.create(data, { admin: ownProps.admin }))
  ),
  patchProjectUser: async (id, data) => dispatch(projectUsersActions.patch(id, data, {
    admin: ownProps.admin,
  })),
  deleteProjectUser: async (id) => dispatch(projectUsersActions.remove(id, {
    admin: ownProps.admin,
  })),
  resyncProjectUsers: async () => dispatch(projectUsersActions.list({
    project: ownProps.project.id,
    type__in: [PROJECT_USER_USUAL, PROJECT_USER_OWNER_ONLY],
    admin: ownProps.admin,
  })),
  resyncProjectUser: async (id) => dispatch(projectUsersActions.read(id, {
    admin: ownProps.admin,
  })),
  patchProject: async (projectId, data, params = {}) => dispatch(projectsActions.patch(
    projectId,
    data,
    { annotate_investigators_count: true, ...params },
  )),
  deleteProject: async () => dispatch(projectsActions.remove(ownProps.project.id, {
    admin: ownProps.admin,
  })),
  resyncProject: async () => dispatch(projectsActions.read(ownProps.project.id, {
    admin: ownProps.admin,
    annotate_investigators_count: true,
  })),
  fetchProjectUsers: async () => dispatch(projectUsersActions.list({
    project: ownProps.project.id,
    type__in: [PROJECT_USER_USUAL, PROJECT_USER_OWNER_ONLY],
    admin: ownProps.admin,
  }, { pagination: 'no' })),
  fetchTeamMembers: async () => dispatch(teamMembersActions.list({
    project: ownProps.project.id,
    admin: ownProps.admin,
  }, { pagination: 'no' })),
  addProjectPermission: async (projectUserId, teamMemberId, name, creatorId) => dispatch(
    projectPermissionsActions.create({
      project_user: projectUserId,
      team_member: teamMemberId,
      name,
      creator: creatorId,
    }, {
      admin: ownProps.admin,
    }),
  ),
  deleteProjectPermission: async (id) => dispatch(projectPermissionsActions.remove(id, {
    admin: ownProps.admin,
  })),
  fetchTeamLicenses: async () => dispatch(teamLicensesActions.list({}, { pagination: 'no' })),
  fetchUserLicenses: async () => dispatch(userLicensesActions.list({}, { pagination: 'no' })),
  fetchUser: async (userId) => dispatch(usersActions.read(userId, { admin: ownProps.admin })),
  addTeam: async (data) => dispatch(teamsActions.create(data)),
});

const projectSettingsInvitationInitialState = {
  invitationEmail: '',
  invitationEmailValid: false,
  invitationType: undefined,
  invitationTeam: CREATE_TEAM,
  invitationTeamMembers: [],
  invitationListTeam: undefined,
  invitationPermissions: [CAN_INCLUDE],
  processingInvitation: false,
  invitationCreationInProgress: false,
  invitationErrorMsg: '',
};

@withToastManager
@connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })
@withTranslation('', nsOptions)
class ProjectSettings extends Component {
  static propTypes = {
    project: PropTypes.shape().isRequired,
    saveInput: PropTypes.func.isRequired,
    saveTopics: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
    admin: PropTypes.bool,
    projectUsers: PropTypes.shape().isRequired,
    projectUser: PropTypes.shape(),
    ownerProjectUser: PropTypes.shape(),
    user: PropTypes.shape().isRequired,
    teams: PropTypes.shape().isRequired,
    teamMembers: PropTypes.shape().isRequired,
    teamLicenses: PropTypes.shape().isRequired,
    userLicenses: PropTypes.shape().isRequired,
    patchProjectUser: PropTypes.func.isRequired,
    addProjectUser: PropTypes.func.isRequired,
    deleteProjectUser: PropTypes.func.isRequired,
    patchProject: PropTypes.func.isRequired,
    deleteProject: PropTypes.func.isRequired,
    resyncProject: PropTypes.func.isRequired,
    resyncProjectUsers: PropTypes.func.isRequired,
    resyncProjectUser: PropTypes.func.isRequired,
    fetchProjectUsers: PropTypes.func.isRequired,
    fetchTeamMembers: PropTypes.func.isRequired,
    addProjectPermission: PropTypes.func.isRequired,
    deleteProjectPermission: PropTypes.func.isRequired,
    fetchTeamLicenses: PropTypes.func.isRequired,
    fetchUserLicenses: PropTypes.func.isRequired,
    fetchUser: PropTypes.func.isRequired,
    addTeam: PropTypes.func.isRequired,
    scrollToInvitations: PropTypes.bool,
    scrollToProjectStatus: PropTypes.bool,
    scrollEventCallback: PropTypes.func,
  };

  static defaultProps = {
    admin: false,
    projectUser: null,
    ownerProjectUser: null,
    scrollToInvitations: false,
    scrollToProjectStatus: false,
    scrollEventCallback: () => {},
  };

  static getInitialState = (project) => ({
    ready: false,
    projectName: project.name,
    projectTeam: project.team,
    languages: [],
    permissionsTeam: null,
    teamMemberToDetach: undefined,
    processingCloning: false,
    processingExport: false,
    ownerUpdateInProgress: false,
    ownerUpdateParams: {
      newOwner: undefined,
      newProjectTeam: undefined,
    },
    mustManagerBeIntern: false,
    ...projectSettingsInvitationInitialState,
  });

  constructor(props) {
    super(props);
    this.state = {
      scrollToInvitations: props.scrollToInvitations,
      scrollToProjectStatus: props.scrollToProjectStatus,
      ...ProjectSettings.getInitialState(props.project),
      showNotifiedManagerMessage: false,
    };
    this.mutex = new Mutex();
    this.timeoutHandler = new TimeoutHandler();
    this.licenseIncompatibleModal = null;
    this.topicManager = null;
    this.dropDownRef = null;
    this.activeTimeout = -1;
    this.adminProjectTeamZone = null;
    this.getAllTeamMembers = fromReduxState((teamMembers) => Object.values(teamMembers));
    this.getParticipants = fromReduxState((projectUsers, project) => (
      Object.values(projectUsers).filter((pUser) => (
        pUser.confirmed && pUser.user.id !== project.manager.id
      ))
    ));
    this.getProjectUsers = fromReduxState((projectUsers, user, admin) => (
      Object.values(projectUsers).filter((pUser) => (
        pUser.type === PROJECT_USER_USUAL && (pUser.user.id !== user.id || admin)
      ))
    ));
    this.getTeams = fromReduxState((teams) => Object.values(teams));
    this.getTeamMembers = fromReduxState((teamMembers) => (
      Object.values(teamMembers).filter((tmMember) => tmMember.confirmed
        && tmMember.can_join_project_in_team)
    ));
    this.getTeamLicenses = fromReduxState((teamLicenses) => Object.values(teamLicenses));
    this.getUserLicenses = fromReduxState((userLicenses) => Object.values(userLicenses));
    this.memoizedGetInvitableUserTeams = memoize(this.getInvitableUserTeams);
    this.memoizedGetProjectUserTeams = memoize(this.getProjectUserTeams);
    this.memoizedGetManageableProjectUserTeams = memoize(this.getManageableProjectUserTeams);
    this.memoizedGetOwnerTeams = memoize(this.getOwnerTeams);
    this.memoizedGetExternalTeams = memoize(this.getExternalTeams);
  }

  async componentDidMount() {
    await this.fetchData();
    const { scrollToInvitations, scrollToProjectStatus } = this.state;
    if (scrollToInvitations) {
      this.scrollToInvitations();
      this.updateScrollTo({ scrollToInvitations: false });
    }
    if (scrollToProjectStatus) {
      this.scrollToProjectStatus();
      this.updateScrollTo({ scrollToProjectStatus: false });
    }
  }

  componentDidUpdate(prevProps) {
    const { project, scrollToInvitations } = this.props;
    if (project.id !== prevProps.project.id) {
      this.reload();
    }
    if (scrollToInvitations !== prevProps.scrollToInvitations) {
      this.updateScrollTo({ scrollToInvitations });
    }
  }

  onNewInvitation = (canInviteExternal, canInviteTeam, canInviteTeamMember, userAllowedTeams) => {
    const { project, user } = this.props;
    const { invitationTeam } = this.state;
    let invitationType = TEAM_INVITATION;

    if (!canInviteExternal || !canInviteTeam) {
      if (!canInviteExternal) invitationType = TEAM_INVITATION;
      else if (!canInviteTeam) invitationType = EXTERNAL_INVITATION;
    }

    let newInvitationTeam = invitationTeam;
    if (userAllowedTeams && userAllowedTeams.length && invitationTeam === CREATE_TEAM) {
      if (userAllowedTeams.length === 1) {
        newInvitationTeam = userAllowedTeams[0].id;
      } else if (project.team && userAllowedTeams.find((tm) => tm.id === project.team)) {
        newInvitationTeam = project.team;
      } else {
        const firstUserTeam = userAllowedTeams.find((tm) => tm.owner.id === user.id);
        newInvitationTeam = firstUserTeam ? firstUserTeam.id : userAllowedTeams[0].id;
      }
    }

    this.setState({
      invitationCreationInProgress: true,
      // We don't want to select a default invitation type when the user has the choice
      // so that it draws attention to the area (on the helper tooltips etc)
      invitationType: (canInviteExternal && (canInviteTeam || canInviteTeamMember)) ? undefined
        : invitationType,
      invitationTeam: newInvitationTeam,
    });
  };

  onTeamChange = async (target, forceTeamUpdate = false) => {
    try {
      const { saveInput, resyncProjectUsers, resyncProject } = this.props;
      this.setState({ projectTeam: target.value });
      const params = forceTeamUpdate ? { force_team_update: true } : {};
      await saveInput(target, 'team', (val) => (val === '0' ? null : val), 'value', params, false);
      await resyncProjectUsers();
      await resyncProject();
      this.setState({ ...projectSettingsInvitationInitialState });
    } catch (error) {
      const { project } = this.props;
      try {
        const errorData = JSON.parse(error.message);
        if (new RegExp('^project__.*$').test(errorData.detail.code)) {
          this.licenseIncompatibleModal.show();
          return;
        }
      } catch (err) {
        // Nothing to do
      }
      this.setState({ projectTeam: project.team || PERSONAL_PROJECT });
      ErrorUtil.handleCatched(this.props, error);
    }
  };

  onTypeInvitationChange = (e) => {
    this.setState({ invitationType: e.target.value });
  };

  onEmailInvitationChange = (e) => {
    const value = e.target ? e.target.value : '';
    this.setState({
      invitationEmail: value,
      invitationEmailValid: this.emailValidator.validate(value),
    });
  };

  onEmailInvitationPaste = (e) => {
    formatPasteEmailList(e);
    this.onEmailInvitationChange(e);
  }

  onPermissionsChange = (e) => {
    let filterPermissions = () => true;

    if (e.target.value === CAN_INVITE_EXTERNAL && !e.target.checked) {
      filterPermissions = (perm) => (
        perm !== CAN_MANAGE_ADVANCED_PERMISSIONS
      );
    }

    this.setState((prevState) => (
      {
        invitationPermissions: eventToMultipleChoicesValue(
          e,
          prevState.invitationPermissions,
        ).filter(filterPermissions),
      }
    ));
  };

  onPermissionToggle = async (projectUser, teamMember, team, name) => {
    const {
      addProjectPermission, deleteProjectPermission, resyncProjectUser, user, resyncProject,
    } = this.props;

    if (this.mutex.isLocked()) return false;

    const release = await this.mutex.acquire();

    try {
      const permission = projectUser.permissions.find((perm) => (
        perm.name === name && perm.team === team
      ));
      if (permission) {
        await deleteProjectPermission(permission.id);
      } else {
        await addProjectPermission(projectUser.id, teamMember, name, user.id);
      }
      await Promise.all([resyncProject(), resyncProjectUser(projectUser.id)]);
      Toast.success(this.props, 'error:valid.success');
      return true;
    } catch (error) {
      ErrorUtil.handleCatched(this.props, error);
      return false;
    } finally {
      release();
    }
  };

  isInvitationFormValid = () => {
    const {
      invitationCreationInProgress, invitationType, invitationEmailValid,
      invitationTeamMembers,
    } = this.state;

    if (!invitationCreationInProgress) return false;

    if (invitationType === EXTERNAL_INVITATION) {
      return invitationEmailValid;
    }

    return invitationTeamMembers && invitationTeamMembers.length;
  };

  mustManagerBeIntern = (teamId) => {
    if (teamId) {
      const { teams } = this.props;
      const team = teams[teamId];
      if (team) return !team.limitations.can_other_than_intern_be_manager;
    }
    return false;
  };

  fetchData = async () => {
    try {
      const {
        fetchProjectUsers, fetchTeamMembers, fetchTeamLicenses, fetchUserLicenses, t,
        admin,
      } = this.props;
      const res = await Promise.all([
        this.fetchLanguages(),
        fetchProjectUsers(),
        fetchTeamMembers(),
        fetchTeamLicenses(),
        fetchUserLicenses(),
      ]);

      if (!admin && !this.props.projectUser) {
        Toast.error(this.props, t('error:error.internal-error'));
      }

      this.setState({
        languages: res[0],
        ready: true,
      });
    } catch (error) {
      ErrorUtil.handleCatched(this.props, error, false);
      this.setState({ languages: [], ready: true });
    }
  }

  reload = async () => {
    try {
      const { project } = this.props;
      this.setState(ProjectSettings.getInitialState(project));
      this.fetchData();
    } catch (error) {
      ErrorUtil.handleCatched(this.props, error, false);
    }
  }

  fetchLanguages = async () => {
    const results = await api.list('languages', {}, { pagination: 'no' });
    return results;
  };

  getInvitableUserTeams = (projectUser, teams, teamMembers) => {
    const { project, admin } = this.props;
    if (admin) return teams;
    if (!projectUser) return [];

    const userMembers = teamMembers.filter((member) => member.user.id === projectUser.user.id);

    return teams.filter((team) => (
      team.members.find((memberId) => userMembers.find((member) => member.id === memberId))
        && ((
          projectUser.teams.includes(team.id)
            && hasPermission(projectUser, CAN_INVITE_TEAM_MEMBER, team.id)
        ) || (
          !projectUser.teams.includes(team.id)
            && hasPermission(projectUser, CAN_INVITE_EXTERNAL_TEAM)
            && (team.id !== project.team || hasPermission(projectUser, CAN_INVITE_PROJECT_TEAM))
        ))
    ));
  };

  getProjectUserTeams = (projectUser, teams, teamMembers) => (
    teams.filter((team) => teamMembers.find((member) => (
      projectUser && projectUser.team_members.find((mb) => mb.id === member.id)
        && member.team === team.id
    )))
  );

  getManageableProjectUserTeams = (projectUser, projectUsers, teams, teamMembers) => {
    const { admin, project } = this.props;
    if (admin) return teams;
    const isOwner = projectUser && project && projectUser.user.id === project.owner.id;
    return teams.filter((team) => teamMembers.find((member) => (
      projectUser && projectUser.team_members.find((mb) => mb.id === member.id)
        && member.team === team.id
        && (hasPermission(projectUser, CAN_INVITE_TEAM_MEMBER, team.id) || isOwner)
    ))).filter((tm) => projectUsers.find((pUser) => (
      pUser.teams.includes(tm.id)
    )));
  }

  getOwnerTeams = (owner, teams) => (
    teams.filter((team) => owner && team.owner.id === owner.id)
  );

  getExternalTeams = (projectUser, projectUsers, teams, teamMembers) => {
    const { project, user, admin } = this.props;
    if (admin) return [];
    const formerParticipants = project.deleted_users_with_inclusions;
    let res = teams.filter((team) => !teamMembers.find((member) => (
      projectUser && member.team === team.id && projectUser.team_members.find((mb) => (
        mb.id === member.id
      ))
    )) && (teamMembers.find((member) => (
      member.team === team.id && projectUsers.find((pUser) => (
        (!projectUser || pUser.id !== projectUser.id) && pUser.team_members.find((mb) => (
          mb.id === member.id
        ))
      ))
    )) || formerParticipants.find((part) => part.teams && part.teams.includes(team.id))
    ));
    const isManager = projectUser && project && project.manager && project.manager.id === user.id;
    if (isManager) {
      // Forgotten teams are the teams from projectUsers added in the project from admin mode
      // They are invited in the project and in a team that is not represented in the project
      // yet, at the same time (on-the-fly invitation).
      // Their invitations are not confirmed yet, otherwise they would be in TeamMembers
      const externalTeamsIds = res.map((team) => team.id);
      const forgottenTeams = teams.filter((team) => projectUsers.find((pUser) => (
        projectUser && pUser.teams.includes(team.id)
        // manager not in this team, otherwise this function is useless
          && !projectUser.teams.includes(team.id)
        // not confirmed, otherwise it would be on teamMembers
          && !pUser.confirmed
          && !externalTeamsIds.includes(team.id))));
      res = [
        ...forgottenTeams,
        ...res,
      ];
    }
    return res;
  };

  getEffectiveInvitationPermissions = (permissions) => {
    const { projectUser, project, admin } = this.props;
    const { invitationTeam, invitationType } = this.state;
    const writablePermissions = getWritablePermissions(projectUser, project, invitationTeam,
      invitationType, admin);
    return permissions.filter((perm) => writablePermissions.includes(perm));
  };

  checkCanInviteInTeam = (team) => {
    const {
      projectUser, projectUsers: pUsers, user, admin,
    } = this.props;
    const projectUsers = this.getProjectUsers(pUsers, user, admin);
    const res = hasPermission(projectUser, CAN_INVITE_PROJECT_TEAM)
      || projectUser.team.find((teamId) => teamId === team.id)
      || !projectUsers.find((pUser) => pUser.teams.find((teamId) => teamId === team.id));
    if (!res) Toast.error(this.props, 'error:error.cannot-invite');
    return res;
  }

  invite = async () => {
    const { invitationType, invitationPermissions } = this.state;
    const {
      t, project, resyncProjectUsers, user, addTeam, fetchTeamMembers, admin, resyncProject,
      projectUsers: pUsers,
    } = this.props;
    const projectUsers = this.getProjectUsers(pUsers, user, admin);
    if (this.isInvitationFormValid()) {
      this.setState({ processingInvitation: true, invitationErrorMsg: '' });
      let emails = [];
      let users = [];
      try {
        let team;
        let teamCreation = false;
        let reloadTeamMembers = false;
        if (invitationType === EXTERNAL_INVITATION) {
          const { invitationEmail } = this.state;
          emails = emailListToArray(invitationEmail);
        } else if (invitationType === TEAM_INVITATION) {
          const { invitationTeamMembers, invitationTeam } = this.state;
          const teamMembers = this.getTeamMembers(this.props.teamMembers);
          emails = invitationTeamMembers.filter((invitation) => (
            !Number(invitation.value)
          )).map((invitation) => invitation.value);
          reloadTeamMembers = emails.length !== 0;
          if (invitationTeam === CREATE_TEAM) {
            // Should never happen in admin mode as the admin user has to choose among existing
            // teams. We can assume that there should never have no teams in the database in the
            // future.
            teamCreation = true;
            reloadTeamMembers = true;
            const res = await addTeam({
              owner: user.id,
              name: t('user:team.default-name', { label: user.label }),
            });
            team = res.id;
          } else {
            users = invitationTeamMembers.filter((invitation) => (
              Number(invitation.value)
            )).map((invitation) => (
              teamMembers.find((mb) => mb.id === Number(invitation.value)).user.id
            ));
            team = invitationTeam;
          }
        } else {
          console.error('Unknown invitation type');
        }
        const permissions = this.getEffectiveInvitationPermissions(
          invitationPermissions, teamCreation,
        );
        const res = await api.create('bulk-invite-in-project', {
          project: project.id,
          emails,
          users,
          permissions,
          team,
        }, { admin });
        const ignoredEmails = res.ignored_emails;
        if (reloadTeamMembers) await fetchTeamMembers();
        await Promise.all([resyncProject(), resyncProjectUsers()]);
        if (ignoredEmails.length) {
          const ownerEmail = project.owner.email;
          // Check if the owner email is one of the ignored emails.
          // If yes, check if the owner participates in the project or not.
          // If not, use a specific error message to inform the invitor that they cannot invite the
          // project owner as an external participant.
          const ownerIgnored = ignoredEmails.includes(ownerEmail)
            && !projectUsers.find((pUser) => pUser.user.email === ownerEmail);
          // Warning message for ignored emails
          let warningMessage;
          if (ownerIgnored) {
            if (ignoredEmails.length === 1) {
              warningMessage = t('project:new-invitation.warnings.owner-warning',
                { context: team ? 'as_team_member' : 'as_external' });
            } else {
              warningMessage = t(
                'project:new-invitation.warnings.warning-with-owner',
                {
                  emails: ignoredEmails.filter((email) => email !== ownerEmail).join(', '),
                  email: ownerEmail,
                  context: team ? 'as_team_member' : 'as_external',
                },
              );
            }
          } else {
            warningMessage = t('project:new-invitation.warnings.warning', { emails: ignoredEmails.join(', ') });
          }
          Toast.warning(
            this.props,
            warningMessage,
            undefined,
            true,
          );
        } else if (emails.length === 1 && users.length === 0) {
          Toast.success(this.props, t('project:successfully-invited-email',
            { email: emails[0] }), 4000, true);
        } else {
          Toast.success(this.props, 'error:valid.saved');
        }
        const {
          invitationListTeam, ...initialState
        } = projectSettingsInvitationInitialState;
        this.setState({ ...initialState });
      } catch (error) {
        const errorMsg = `${t(emails.length + users.length <= 1 ? 'project:new-invitation.error.single' : 'project:new-invitation.error.multiple')} ${t('project:new-invitation.error.common')}`;
        this.setState({ invitationErrorMsg: t(errorMsg), processingInvitation: false });
        ErrorUtil.handleCatched(this.props, error);
      }
    }
  };

  resendInvitation = async (invitation) => {
    try {
      const { addProjectUser, project } = this.props;
      await addProjectUser({
        email: invitation.email,
        project: project.id,
      });
      Toast.success(this.props, 'error:valid.success');
    } catch (error) {
      ErrorUtil.handleCatched(this.props, error);
    }
  };

  cancelInvitation = async (invitation) => {
    try {
      const { deleteProjectUser, resyncProject } = this.props;
      await deleteProjectUser(invitation.id);
      await resyncProject();
      Toast.success(this.props, 'error:valid.success');
    } catch (error) {
      ErrorUtil.handleCatched(this.props, error);
    }
  };

  blockParticipant = async (participant, teamId) => {
    const permNamesToRemove = [
      ...ADMIN_EDITABLE_PERMS,
      ...ADVANCED_EDITABLE_PERMS,
      ...BASIC_EDITABLE_PERMS,
    ];
    const permsToRemove = participant.permissions.filter((perm) => (
      permNamesToRemove.includes(perm.name) && perm.team === teamId
    ));
    const { deleteProjectPermission, resyncProjectUser } = this.props;
    try {
      await Promise.all(permsToRemove.map((perm) => deleteProjectPermission(perm.id)));
      await resyncProjectUser(participant.id);
      Toast.success(this.props, 'error:valid.success');
    } catch (error) {
      ErrorUtil.handleCatched(this.props, error);
    }
  };

  removeParticipant = async (participant, teamMemberId) => {
    let isMultiTeam = false;

    if (teamMemberId) {
      if (!participant.team_members.find((mb) => mb.id === teamMemberId)) {
        Toast.error(this.props, 'error:error.user-unknown-team');
        return;
      }
      isMultiTeam = participant.team_members.length !== 1;
    }

    const {
      patchProjectUser, deleteProjectUser, resyncProject, project, resyncProjectUser,
    } = this.props;

    const isOwner = project && participant.user && participant.user.id === project.owner.id;
    const isManager = project && participant.user && participant.user.id === project.manager.id;

    try {
      if (isMultiTeam || (isOwner && !isManager)) {
        await patchProjectUser(participant.id, {
          team_members: participant.team_members.filter((member) => (
            member.id !== teamMemberId
          )).map((member) => member.id),
        });
        // Owner became owner_only (does not participate in the project anymore)
        if (isOwner && !isMultiTeam) {
          await resyncProjectUser(participant.id);
        }
      } else {
        await deleteProjectUser(participant.id);
        await resyncProject();
      }
      Toast.success(this.props, 'error:valid.success');
    } catch (error) {
      ErrorUtil.handleCatched(this.props, error);
    }
  };

  copyInvitationLink = async (invitation) => {
    const { admin } = this.props;
    TextUtil.asyncCopyTextToClipboard(
      this.props,
      api.requestData(
        `project-users/${invitation.id}/invitation-url`,
        null, 'get', { admin },
      ),
      (response) => response.url,
    );
  }

  deleteProject = async () => {
    try {
      const { deleteProject, t, admin } = this.props;
      await deleteProject();
      Toast.success(this.props, t('project:deletion-success'), 4000);
      history.push(`/${admin ? 'admin' : 'dashboard'}/projects`);
    } catch (error) {
      ErrorUtil.handleCatched(this.props, error);
    }
  };

  quitProject = async (teamMemberId = QUIT_ALL_TEAMS) => {
    const {
      deleteProjectUser, patchProjectUser, projectUser, t, project, resyncProjectUser,
    } = this.props;

    const isOwner = project && projectUser.user && projectUser.user.id === project.owner.id;

    // Calling deleteProjectUser on the project owner will always fail
    // Always call patchDeleteUser to update/delete its team participations
    let ownerLastTeamMemberId;
    if (isOwner && projectUser.team_members.length === 1) {
      ownerLastTeamMemberId = projectUser.team_members[0].id;
    }

    try {
      if ((!teamMemberId && teamMemberId !== QUIT_ALL_TEAMS && !ownerLastTeamMemberId)
        || (teamMemberId === QUIT_ALL_TEAMS && !isOwner)) {
        await deleteProjectUser(projectUser.id);
        Toast.success(this.props, t('project:leaving-project-success'), 4000);
        history.push('/dashboard/projects');
      } else {
        let teamMemberIds = [];
        if (teamMemberId || ownerLastTeamMemberId) {
          teamMemberIds = projectUser.team_members.filter((member) => (
            member.id !== (teamMemberId || ownerLastTeamMemberId))).map((member) => member.id);
        }
        await patchProjectUser(projectUser.id, {
          team_members: teamMemberIds,
        });
        if (isOwner && (ownerLastTeamMemberId || teamMemberId === QUIT_ALL_TEAMS)) {
          await resyncProjectUser(projectUser.id);
        }
        Toast.success(this.props, t('project:leaving-team-success'), 4000);
      }
    } catch (error) {
      ErrorUtil.handleCatched(this.props, error);
    }
  };

  cloneProject = async () => {
    const { admin } = this.props;
    this.setState({ processingCloning: true });
    try {
      // Request cloning to the api
      await api.create('clone-project', {
        project_id: this.props.project.id,
        related_objects: true,
      }, { admin });
      Toast.success(this.props, 'error:valid.cloned');
    } catch (error) {
      ErrorUtil.handleCatched(this.props, error);
    } finally {
      this.setState({ processingCloning: false });
    }
  };

  exportProject = async () => {
    const { project, admin } = this.props;
    this.setState({ processingExport: true });
    try {
      await downloadEndpoint(
        'export-project',
        'post',
        { admin },
        {
          project_id: project.id,
          related_objects: true,
        },
      );
      Toast.success(this.props, 'error:valid.exported');
    } catch (error) {
      ErrorUtil.handleCatched(this.props, error);
    } finally {
      this.setState({ processingExport: false });
    }
  }

  renderName = (canEdit) => {
    const {
      t, project, saveInput, admin,
    } = this.props;
    const { projectName } = this.state;

    return (
      <LabeledInput
        type="text"
        id="input-project-name"
        name="name"
        placeholder={t('error:placeholder.project-name')}
        validation="project_name"
        required
        value={projectName}
        onChange={(e) => this.setState({ projectName: e.target.value })}
        onValidChange={(e) => {
          saveInput(e.target, 'name');
        }}
        className="text-bigger mb-0"
        disabled={!admin && (!canEdit || isProjectDisabled(project))}
      />
    );
  };

  renderTeam = (canEdit) => {
    const {
      teams: tms, project, admin, teamLicenses: tmLicenses, userLicenses: usrLicenses, user,
    } = this.props;
    const { projectTeam, ownerUpdateInProgress } = this.state;
    const teams = this.getTeams(tms);
    const teamLicenses = this.getTeamLicenses(tmLicenses);
    const userLicenses = this.getUserLicenses(usrLicenses);
    const ownerTeams = this.memoizedGetOwnerTeams(project.owner, teams);
    let effectiveProjectTeam = projectTeam;

    if (!ownerTeams.length) effectiveProjectTeam = null;

    if (canEdit) {
      return (
        <ProjectTeamZone
          key="project-team-zone"
          teams={ownerTeams}
          teamLicenses={teamLicenses}
          userLicenses={userLicenses}
          user={user}
          admin={admin}
          canEdit={canEdit}
          project={project}
          setLicenseIncompatibleModalRef={(ref) => { this.licenseIncompatibleModal = ref; }}
          value={effectiveProjectTeam}
          onChange={async (value, forceTeamUpdate) => this.onTeamChange({ value }, forceTeamUpdate)}
          onCancel={() => this.setState({ projectTeam: project.team })}
          disabled={ownerUpdateInProgress}
        />
      );
    }

    let team;
    if (projectTeam) team = teams.find((tm) => tm.id === Number(projectTeam));

    return (
      <div
        style={{ marginTop: '0.4rem' }}
      >
        {team ? team.name : '-'}
      </div>
    );
  };

  updateProjectOwner = async (forceTeamUpdate = false) => {
    const {
      patchProject, project, admin, resyncProjectUsers, resyncProject,
    } = this.props;
    const { ownerUpdateParams } = this.state;
    const { newOwner, newTeam } = ownerUpdateParams;

    const teamOk = this.adminProjectTeamZone.validate(newTeam);

    const extraParams = forceTeamUpdate ? { force_team_update: true } : {};

    if (newOwner && teamOk) {
      try {
        await patchProject(
          project.id,
          {
            owner: newOwner.id,
            team: newTeam,
          },
          { admin, ...extraParams },
        );
        await resyncProjectUsers();
        await resyncProject();
        this.setState({
          ownerUpdateInProgress: false,
          ownerUpdateParams: {
            newOwner: undefined,
            newTeam: undefined,
          },
        });
      } catch (error) {
        try {
          const errorData = JSON.parse(error.message);
          if (new RegExp('^project__.*$').test(errorData.detail.code)) {
            this.licenseIncompatibleModal.show();
            return;
          }
        } catch (err) {
          // Nothing to do
        }
        ErrorUtil.handleCatched(this.props, error);
      }
      this.setState({ projectTeam: this.props.project.team });
    }
  };

  cancelOwnerUpdate = () => {
    this.setState({
      ownerUpdateInProgress: false,
      ownerUpdateParams: {
        newOwner: undefined,
        newTeam: undefined,
      },
    });
  };

  renderOwner = () => {
    const {
      t, admin, project, teams: tms, teamLicenses: tmLicenses, userLicenses: usrLicenses, user,
      fetchUser,
    } = this.props;
    const { ownerUpdateInProgress, ownerUpdateParams } = this.state;
    const teams = this.getTeams(tms);
    const teamLicenses = this.getTeamLicenses(tmLicenses);
    const userLicenses = this.getUserLicenses(usrLicenses);
    const { newOwner, newTeam } = ownerUpdateParams;

    const isOwner = project && project.owner.id === user.id;

    if (!admin) {
      return (
        <>
          <div className="pt-1">
            <LabeledUserAvatar user={project.owner} />
          </div>
          {isOwner && (
            <div className="mt-3 text-gray">
              <div className="mb-1">
                {t('project:user-type.owner-help.title')}
              </div>
              <ul className="mb-0 owner-rights-list">
                {t('project:user-type.owner-help.list', { returnObjects: true })
                  .map((item) => (
                    <li
                      key={item}
                    >
                      {item}
                    </li>
                  ))}
              </ul>
            </div>
          )}
        </>
      );
    }

    const newOwnerTeams = newOwner ? this.memoizedGetOwnerTeams(newOwner, teams) : [];

    return (
      <>
        <UserSelect
          admin={admin}
          value={newOwner ? newOwner.id : project.owner.id}
          onChange={async (e) => {
            const res = await fetchUser(e.value);
            this.setState({
              ownerUpdateInProgress: true,
              ownerUpdateParams: {
                newTeam: undefined,
                newOwner: res,
              },
            });
          }}
        />
        {ownerUpdateInProgress && (
          <>
            <div>
              <ProjectTeamZone
                key={`admin-project-team-zone-${newOwner.id}`}
                teams={newOwnerTeams}
                teamLicenses={teamLicenses}
                userLicenses={userLicenses}
                user={newOwner || user}
                admin
                canEdit
                creation
                project={project}
                value={newTeam}
                onChange={(value) => {
                  this.setState({
                    ownerUpdateParams: {
                      ...ownerUpdateParams,
                      newTeam: value,
                    },
                  });
                }}
                ref={(ref) => { this.adminProjectTeamZone = ref; }}
                setLicenseIncompatibleModalRef={(ref) => { this.licenseIncompatibleModal = ref; }}
              />
            </div>
            <div className="mt-3">
              <MessageModal
                message={(
                  <span>
                    <b>
                      {t('common:caution')}
                      &nbsp;!
                    </b>
                    <br />
                    <br />
                    {t('project:license-incompatible')}
                  </span>
                )}
                validateLabel="common:button.confirm"
                showCancelButton
                onValidate={() => this.updateProjectOwner(true)}
                onCancel={this.cancelOwnerUpdate}
                ref={(ref) => { this.licenseIncompatibleModal = ref; }}
                modalBodyClassName="text-center"
              />
              <button
                type="button"
                className="btn btn-newblue-1 text-white px-3"
                onClick={() => this.updateProjectOwner()}
                disabled={!newOwner}
              >
                {t('common:button.validate')}
              </button>
              <button
                type="button"
                className="btn btn-link ml-2"
                onClick={() => this.setState({
                  ownerUpdateInProgress: false,
                  ownerUpdateParams: {
                    newOwner: undefined,
                    newTeam: undefined,
                  },
                })}
              >
                {t('common:button.cancel')}
              </button>
            </div>
          </>
        )}
      </>
    );
  };

  renderManager = (canEdit) => {
    const {
      t, admin, project, saveInput, resyncProjectUsers, teamMembers: tmMembers,
      projectUsers: pUsers, user,
    } = this.props;
    const projectUsers = this.getProjectUsers(pUsers, user, admin);
    const teamMembers = this.getTeamMembers(tmMembers);
    const mustBeIntern = this.mustManagerBeIntern(project.team);
    const isManagerNamed = Boolean(project.manager.id);

    const NoManager = () => (
      <div style={{ marginTop: '0.5rem' }}>
        {t('project:no.manager')}
      </div>
    );

    // If the user cannot name the manager
    if (!canEdit) {
      return isManagerNamed ? (
        <LabeledUserAvatar user={project.manager} />
      ) : (
        <NoManager />
      );
    }

    const fetchUsers = async (search) => {
      let res = [];
      if (project) {
        if (project.team) {
          // The manager can be named among the team members
          res = teamMembers.filter((teamMember) => (
            teamMember.team === project.team && teamMember.confirmed
              && TextUtil.localeContains(teamMember.user.label, search)
          )).map((teamMember) => teamMember.user);
        } else {
          // The manager can be named among the confirmed participants
          res = projectUsers.filter((pUser) => pUser.confirmed).map((pUser) => pUser.user);
        }
        // The owner is not necessarily in the projectUsers (if owner_only, for instance)
        const ownerCanManageProject = project.owner.limitations.can_manage_project;
        if (!res.find((usr) => usr.id === project.owner.id) && ownerCanManageProject) {
          res.push(project.owner);
        }
      }
      return res;
    };

    const disableSelect = project
      && !(project.team || project.owner.limitations.can_delegate_individual_project_management);

    const canDelegateIndividualProjectManagement = Boolean(project
      && project.owner.limitations.can_delegate_individual_project_management);
    const projectTeam = project ? project.team : null;

    return (
      <div className="d-block mb-3">
        <div>
          <UserSelect
            // Force component to fetch users and update its value
            key={`manager-select${project.manager.id || ''}-${project.team || ''}`}
            admin={admin}
            value={project.manager.id}
            onChange={async (e) => {
              await saveInput(e, 'manager');
              await resyncProjectUsers();
            }}
            fetchUsers={fetchUsers}
            disabled={disableSelect}
            required
          />
        </div>
        {!disableSelect && (
          <div className="help-text">
            {mustBeIntern ? t('project:displayed-managers-help') : t(
              'project:you-can-delegate-management',
              {
                context: projectTeam || !canDelegateIndividualProjectManagement ? 'team_project' : 'individual_project',
              },
            )}
          </div>
        )}
      </div>
    );
  };

  onRegulatoryConfirm = async () => {
    const { patchProject, project, admin } = this.props;
    try {
      await patchProject(
        project.id,
        {
          has_regulatory_approval: true,
          status: PROJECT_IN_PROGRESS,
        },
        { admin },
      );
      Toast.success(this.props, 'error:valid.success');
    } catch (error) {
      ErrorUtil.handleCatched(this.props, error);
    }
  };

  renderStatus = (canEdit) => {
    const {
      t, project, admin, user, saveInput,
    } = this.props;

    const isManager = project && project.manager.id === user.id;

    const canPauseProject = this.hasPermission(CAN_PAUSE_PROJECT);
    const canConfirmRegulatoryApproval = this.hasPermission(CAN_CONFIRM_REGULATORY_APPROVAL);

    const projectDisabled = isProjectDisabled(project);
    const projectPaused = project.is_paused;

    const hasRegulatoryApproval = project && project.has_regulatory_approval;
    const showRegulatoryIcon = (status) => canConfirmRegulatoryApproval
      && status === PROJECT_IN_PROGRESS;

    const getOption = (status) => ({
      text: (
        <div className="d-flex">
          <ProjectStatus
            status={status}
            noTooltip
          />
          {(status !== PROJECT_IN_PROGRESS) && (
            <Help iconClassName="ml-2">
              {t(`project:statuses.${status}-help`)}
            </Help>
          )}
          {showRegulatoryIcon(status) && (
            <>
              {project.status === PROJECT_ON_HOLD ? (
                <div
                  role="button"
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                  }}
                  onKeyDown={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                  }}
                  tabIndex={0}
                  className="cursor-default"
                >
                  <RegulatoryConfirmationIcon
                    className="mx-2"
                    projectStatus={project.status}
                    confirmed={hasRegulatoryApproval}
                  />
                </div>
              ) : (
                <RegulatoryConfirmationModal
                  confirmed={hasRegulatoryApproval}
                  onValidate={() => this.props.saveInput({ value: true }, 'has_regulatory_approval')}
                >
                  <button className="btn no-button-style py-0 px-2">
                    <RegulatoryConfirmationIcon
                      projectStatus={project.status}
                      confirmed={hasRegulatoryApproval}
                    />
                  </button>
                </RegulatoryConfirmationModal>
              )}
            </>
          )}
        </div>
      ),
      value: status,
    });

    return (
      <>
        {canEdit ? (
          <>
            <RegulatoryConfirmationModal
              triggerFuncName="onChange"
              confirmed={hasRegulatoryApproval}
              onValidate={this.onRegulatoryConfirm}
              triggerCondition={(e) => {
                const { value } = e.target;
                return value === PROJECT_IN_PROGRESS && !hasRegulatoryApproval;
              }}
            >
              <LabeledChoice
                name="status-labeled-choice"
                value={project.status}
                className="mb-0"
                choices={[
                  getOption(PROJECT_ON_HOLD),
                  getOption(PROJECT_IN_PROGRESS),
                  getOption(PROJECT_CLOSED),
                ]}
                onChange={(e) => {
                  this.props.saveInput(e.target, 'status');
                }}
                disabled={!admin && (!canEdit || projectDisabled)}
                orientation="vertical"
              />
            </RegulatoryConfirmationModal>
            {project.status === PROJECT_CLOSED && (
              <div className="closed-project-options pb-2">
                <FormGroup
                  className="custom-control custom-checkbox pb-1"
                  check
                >
                  <Input
                    id="inclusion-creation-blocked"
                    name="inclusion-creation-blocked"
                    type="checkbox"
                    className="custom-control-input"
                    checked
                    disabled
                  />
                  <Label
                    for="inclusion-creation-blocked"
                    className="custom-control-label"
                  >
                    {t('project:closed-status-options.inclusion-creation-blocked')}
                  </Label>
                </FormGroup>
                <FormGroup
                  className="custom-control custom-checkbox"
                  check
                >
                  <Input
                    id="inclusion-modification-blocked"
                    name="inclusion-modification-blocked"
                    type="checkbox"
                    className="custom-control-input"
                    checked={project.closed_status_option === PROJECT_CLOSED_WITH_NO_MODIFICATIONS}
                    disabled={!admin && (!canEdit || projectDisabled || projectPaused)}
                    onChange={(e) => {
                      const { checked } = e.target;
                      const value = checked ? PROJECT_CLOSED_WITH_NO_MODIFICATIONS
                        : PROJECT_CLOSED_WITH_MODIFICATIONS;
                      saveInput({ value }, 'closed_status_option');
                    }}
                  />
                  <Label
                    for="inclusion-modification-blocked"
                    className="custom-control-label"
                  >
                    {t('project:closed-status-options.inclusion-modification-blocked')}
                  </Label>
                </FormGroup>
              </div>
            )}
          </>
        ) : (
          <div
            className="d-flex"
            style={{ marginTop: '0.4rem' }}
          >
            <ProjectStatus
              status={project.status}
              noTooltip
            />
            <Help
              iconClassName="ml-2"
            >
              {t('project:statuses.disabled-help')}
            </Help>
          </div>
        )}
        {(isManager || canPauseProject) && (
          <PauseProjectZone
            project={project}
            admin={admin}
            canPauseProject={canPauseProject}
          />
        )}
      </>
    );
  };

  renderSharingSettings = (canEdit) => {
    const { t, project, admin } = this.props;
    const { languages } = this.state;
    const currentLang = this.state.languages.find((v) => v.key === project.language);

    return (
      <>
        <div className="mb-4 d-none">
          <Label className="">
            {t('project:language')}
          </Label>
          <div className="row">
            <div className="col-auto">
              <LabeledSelect
                name="lang"
                validation="string"
                required
                onChange={(e) => {
                  this.props.saveInput(e.target, 'language');
                }}
                className="mb-0"
                defaultValue={t(`locale:${currentLang ? currentLang.key : undefined}`)}
                disabled={!admin && (!canEdit || isProjectDisabled(project))}
                ref={(select) => {
                  this.langSelect = select;
                }}
              >
                {
                  languages.map((language) => (
                    <option
                      key={language.key}
                      value={language.key}
                    >
                      { t(`locale:${language.key}`) }
                    </option>
                  ))
                }
              </LabeledSelect>
            </div>
            <div className="col-12">
              <div className="help-text">
                {t('project:language-help')}
              </div>
            </div>
          </div>
        </div>
        <div className={`row${project.is_public ? ' mb-4' : ''}`}>
          {
            admin || (canEdit && !isProjectDisabled(project)) ? (
              <>
                <div className="col-12 col-sm-12 col-md-11 col-lg-10 col-xl-9">
                  <Label className="">
                    {t('project:sharing-settings')}
                  </Label>
                  <TopicSelect
                    {...this.props}
                    onChange={this.props.saveTopics}
                    values={project.topics}
                    canCreate
                    disabled={!admin && (!canEdit || isProjectDisabled(project))}
                    required
                    ref={(topicManager) => {
                      if (topicManager) this.topicManager = topicManager;
                    }}
                  />
                </div>
                <div className="col-12">
                  <div className="help-text">
                    {t('project:topics-help')}
                  </div>
                </div>
              </>
            ) : (
              <div className="col-12">
                {project.topics.length > 0 ? project.topics.map((topic) => (
                  <span className="badge badge-turquoise mr-1 mb-1" key={topic}>
                    {t(`topic:${topic}`)}
                  </span>
                )) : (
                  <span className="font-italic">
                    {t('project:no.topic')}
                  </span>
                )}
              </div>
            )
          }
        </div>
      </>
    );
  };

  renderOwnPermissions = () => {
    const {
      t, projectUser, teams: tms, teamMembers: tmMembers, project,
    } = this.props;
    const { permissionsTeam } = this.state;
    const teams = this.getTeams(tms);
    const teamMembers = this.getTeamMembers(tmMembers);
    const projectUserTeams = this.memoizedGetProjectUserTeams(projectUser, teams, teamMembers);
    let effectivePermissionsTeam = permissionsTeam;
    if (projectUserTeams.length && (!effectivePermissionsTeam
      || !projectUserTeams.find((team) => team.id === parseInt(effectivePermissionsTeam, 10)))) {
      effectivePermissionsTeam = projectUserTeams[0].id;
    }
    const permissions = projectUser ? projectUser.permissions.filter((perm) => (
      perm.team === (effectivePermissionsTeam ? Number(effectivePermissionsTeam) : null)
    )).map((perm) => (
      perm.name
    )) : [];

    return (
      <>
        <div className="row align-items-center">
          <div className="col-12 col-md-auto">
            <span>
              {t('project:participation-type')}
              {' '}
            </span>
            <span>
              {t(`project:${projectUserTeams.length ? 'in-team' : 'individual-participation'}`)}
            </span>
            {
              effectivePermissionsTeam && projectUserTeams.length === 1 && (
                <span>
                  {` (${projectUserTeams.find((team) => team.id === effectivePermissionsTeam).name})`}
                </span>
              )
            }
          </div>
          {
            effectivePermissionsTeam && projectUserTeams.length > 1 && (
              <LabeledSelect
                className="py-0 mb-1 pl-md-0 col-auto"
                name="permissions-team-select"
                value={effectivePermissionsTeam}
                hideOptionalLabel
                onChange={(e) => this.setState({ permissionsTeam: e.target.value })}
              >
                {
                  projectUserTeams.map((team) => (
                    <option
                      key={team.id}
                      value={team.id}
                    >
                      {team.name}
                    </option>
                  ))
                }
              </LabeledSelect>
            )
          }
        </div>
        <ProjectUserPermissionSettings
          value={permissions}
          showTeamInvitationsPerm={Boolean(projectUser && projectUser.teams.length)}
          readOnly
          type="full"
          project={project}
        />
      </>
    );
  };

  scrollToInvitations = async () => {
    const { ready, invitationCreationInProgress, invitationType } = this.state;
    const {
      project, projectUser, teams: tms, teamMembers: tmMembers, admin, scrollEventCallback,
    } = this.props;
    const teams = this.getTeams(tms);
    const teamMembers = this.getTeamMembers(tmMembers);
    if (ready && project && projectUser) {
      const userAllowedTeams = this.memoizedGetInvitableUserTeams(projectUser, teams, teamMembers);
      const canInviteExternal = Boolean(admin
        || (projectUser && hasPermission(projectUser, CAN_INVITE_EXTERNAL)));
      const canInviteExternalTeam = admin || hasPermission(projectUser, CAN_INVITE_EXTERNAL_TEAM);
      const canInviteTeamMember = Boolean(userAllowedTeams.findIndex((tm) => (
        hasPermission(projectUser, CAN_INVITE_TEAM_MEMBER, tm.id)
      )) >= 0);
      const button = document.getElementById('new-invitation-button');
      if (button) {
        if (!invitationCreationInProgress) {
          await this.onNewInvitation(canInviteExternal, canInviteExternalTeam, canInviteTeamMember,
            userAllowedTeams);
        }
        if (invitationType === EXTERNAL_INVITATION && canInviteExternal) {
          await this.setState({ invitationType: TEAM_INVITATION });
        }
        await Promise.all([button.scrollIntoView({ behavior: 'smooth', block: 'center' })]).then(() => scrollEventCallback());
      }
    }
  }

  scrollToProjectStatus = async () => {
    const { scrollEventCallback } = this.props;
    const target = document.getElementById('project-status-section');
    await Promise.all([target.scrollIntoView({ behavior: 'smooth', block: 'center' })]).then(() => scrollEventCallback());
  }

  updateScrollTo = (value) => {
    this.setState(value);
  }

  renderInvitations = (canManageExternalInvitations, canInviteExternal, canInviteExternalTeam) => {
    const {
      t, projectUsers: pUsers, teams: tms, projectUser, project, user, teamMembers: tmMembers,
      admin,
    } = this.props;
    const {
      invitationCreationInProgress, invitationEmail, invitationType, invitationTeam,
      processingInvitation, invitationPermissions, invitationTeamMembers, invitationListTeam,
      invitationErrorMsg,
    } = this.state;
    const projectUsers = this.getProjectUsers(pUsers, user, admin);
    const allTeamMembers = this.getAllTeamMembers(tmMembers);
    const participants = this.getParticipants(projectUsers, project);
    const teams = this.getTeams(tms);
    const teamMembers = this.getTeamMembers(tmMembers);
    const userAllowedTeams = this.memoizedGetInvitableUserTeams(projectUser, teams, teamMembers);
    const userExternalTeams = this.memoizedGetExternalTeams(projectUser, projectUsers, teams,
      teamMembers);
    const canCreateTeam = !admin && user.limitations.can_create_team && hasPermission(projectUser,
      CAN_INVITE_EXTERNAL_TEAM);
    const manageableProjectUserTeams = this.memoizedGetManageableProjectUserTeams(
      projectUser,
      projectUsers,
      teams,
      teamMembers,
    );

    const isOwner = project && project.owner.id === user.id;
    const isManager = project && project.manager.id === user.id;
    const isOrganization = ORGANIZATION_TYPES.includes(user.type);

    const renderExternalInvitation = () => (
      <div className="row">
        <div className="col-12">
          <LabeledInput
            type="textarea"
            label={<InvitationStepLabel label={t('project:new-invitation.email')} />}
            labelClassName="project-invitation-step"
            name="email"
            placeholder={t('error:placeholder.email-list')}
            validation="email_list"
            required
            className="mt-2 mb-0"
            colInputClassName="col-12"
            validatorTooltipDistance={35}
            value={invitationEmail}
            onChange={this.onEmailInvitationChange}
            onPaste={this.onEmailInvitationPaste}
            ref={(validator) => { this.emailValidator = validator; }}
          />
        </div>
        {!ORGANIZATION_TYPES.includes(user.type) && (
          <div className="col-12">
            <div className="help-text mb-2">
              {t('project:new-invitation.enter-emails-info')}
            </div>
          </div>
        )}
      </div>
    );

    const renderTeamInvitation = () => {
      const team = invitationTeam && invitationTeam !== CREATE_TEAM
        ? teams.find((tm) => tm.id === Number(invitationTeam)) : undefined;
      const userTeams = admin ? teams : userAllowedTeams.filter((tm) => tm.owner.id === user.id);
      const leaderTeamIds = teamMembers.filter((member) => (
        member.user.id === user.id && member.confirmed && member.leader
      )).map((member) => member.team);
      const creatableTeams = admin ? teams : userAllowedTeams.filter((tm) => (
        tm.owner.id === user.id || leaderTeamIds.includes(tm.id)
      ));

      const creatable = invitationTeam === CREATE_TEAM || (team
        && Boolean(creatableTeams.find((tm) => tm.id === invitationTeam)));

      const getTeamOption = (tm) => ({ label: tm.name, value: tm.id });

      // Team options
      const teamOptions = [
        ...((!userTeams.length && canCreateTeam)
          ? [{ label: t('project:new-invitation.create-team'), value: CREATE_TEAM }] : []),
        ...userAllowedTeams.map((tm) => getTeamOption(tm)),
      ];


      return (
        <div className="row">
          <div className="col-12 col-sm-12 col-md-11 col-lg-10 col-xl-9 mt-2">
            <div className="row">
              {(!userAllowedTeams.length
                || (userTeams.length === 1 && userAllowedTeams.length === 1))
                && (
                  <div className="col-12 mb-2">
                    <Label className="project-invitation-step">
                      <InvitationStepLabel label={t('project:new-invitation.select-team')} />
                    </Label>
                  </div>
                )}
              <div className="col-12 mb-1">
                {!userAllowedTeams.length && (
                  <Label
                    className={canCreateTeam ? 'text-newgreen-2' : ''}
                  >
                    {t(canCreateTeam ? 'project:new-invitation.create-team-help'
                      : 'project:new-invitation.should-join-team')}
                  </Label>
                )}
                {(userTeams.length === 1 && userAllowedTeams.length === 1) && (
                  <Label
                    className="text-newgreen-2"
                  >
                    {`${t('project:new-invitation.selected-team')}${t('common:colon')} ${userTeams[0].name}`}
                  </Label>
                )}
                {((userTeams.length > 0 && userAllowedTeams.length > 1)
                  || (userTeams.length === 0 && userAllowedTeams.length > 0)) && (
                    <>
                      {admin ? (
                        <>
                          <Label className="project-invitation-step">
                            <InvitationStepLabel label={t('project:new-invitation.select-team')} />
                          </Label>
                          <AdminSelectTeam
                            defaultValue={teamOptions.find((option) => (
                              option.value === invitationTeam
                            ))}
                            disabled={!userAllowedTeams || !userAllowedTeams.length}
                            options={teamOptions}
                            onChange={(e) => {
                              this.setState({
                                invitationTeam: Number(e.value),
                                invitationTeamMembers: [],
                              });
                            }}
                          />
                        </>
                      ) : (
                        <LabeledSelect
                          className="mb-0"
                          colSelectClassName="col-12"
                          name="teams"
                          label={<InvitationStepLabel label={t('project:new-invitation.select-team')} />}
                          labelClassName="project-invitation-step"
                          required
                          value={invitationTeam}
                          disabled={!userAllowedTeams || !userAllowedTeams.length}
                          onChange={(e) => {
                            this.setState({
                              invitationTeam: Number(e.target.value),
                              invitationTeamMembers: [],
                            });
                          }}
                        >
                          {!userTeams.length && canCreateTeam && (
                            <option key={CREATE_TEAM} value={CREATE_TEAM}>
                              {t('project:new-invitation.create-team')}
                            </option>
                          )}
                          {userAllowedTeams.map((tm) => (
                            <option key={tm.id} value={tm.id}>
                              {tm.name}
                            </option>
                          ))}
                        </LabeledSelect>
                      )}
                      {(invitationTeam === CREATE_TEAM) && (
                        <div className="mt-2 text-newgreen-2">
                          {t('project:new-invitation.create-team-help')}
                        </div>
                      )}
                    </>
                )}
              </div>
            </div>
            {(Boolean(userAllowedTeams.length) || canCreateTeam) && (
              <div className="row mt-3">
                <div className="col-12">
                  <SelectParticipants
                    creatable={creatable}
                    label={(
                      <InvitationStepLabel
                        label={t(`project:new-invitation.invite-${creatable ? 'or-add-' : ''}in-team`)}
                        helpText={creatable ? (
                          <span>
                            <strong>
                              {t('project:new-invitation.invite-in-team-help.part-1')}
                            </strong>
                            {t('project:new-invitation.invite-in-team-help.part-2')}
                            <strong>
                              {t('project:new-invitation.invite-in-team-help.part-3')}
                            </strong>
                            {t('project:new-invitation.invite-in-team-help.part-4')}
                          </span>
                        ) : null}
                      />
                    )}
                    value={invitationTeamMembers}
                    teamMembers={invitationTeam !== CREATE_TEAM ? teamMembers.filter((member) => (
                      team && member.team === team.id
                      // Exclude the current user
                        && member.user.id !== user.id
                      // Exclude the project manager
                        && member.user.id !== project.manager.id
                      // Exclude team member who have already joined the project in that team
                        && !projectUsers.find((pUser) => (
                          pUser.user && pUser.user.id === member.user.id
                            && pUser.teams.includes(team.id)
                        ))
                    )) : []}
                    teamId={team ? team.id : CREATE_TEAM}
                    onChange={(value) => { this.setState({ invitationTeamMembers: value }); }}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      );
    };

    const canInviteTeamMember = Boolean(admin || (userAllowedTeams.findIndex((tm) => (
      hasPermission(projectUser, CAN_INVITE_TEAM_MEMBER, tm.id)
    )) >= 0));
    const showNewInvitationBtn = (!isProjectDisabled(project) || admin) && (canInviteExternal
      || canInviteExternalTeam || canInviteTeamMember);
    const invitationFormValid = this.isInvitationFormValid();
    const canViewExternalInvitations = isOwner || canManageExternalInvitations || canInviteExternal;
    const canViewTeamInvitations = Boolean(
      manageableProjectUserTeams && manageableProjectUserTeams.length,
    );
    let invitationListTeams;
    if (admin) invitationListTeams = teams;
    else if (isOwner) {
      invitationListTeams = [
        ...manageableProjectUserTeams,
        ...userExternalTeams,
      ];
    } else {
      invitationListTeams = [
        ...(canViewTeamInvitations ? manageableProjectUserTeams : []),
        ...(canManageExternalInvitations ? userExternalTeams : []),
      ];
    }
    const hasAlreadyInvitedIndividuals = projectUser && projectUsers.filter((pUser) => (
      pUser.teams.length === 0 && pUser.creator === projectUser.user.id
    ));
    const showInvitationList = Boolean(isOwner || canViewExternalInvitations
      || invitationListTeams.length || hasAlreadyInvitedIndividuals);
    let invitationListValue = invitationListTeam;
    if (!Number.isInteger(invitationListValue) || (
      invitationListValue !== INDIVIDUALS && !invitationListTeams.find((tm) => (
        tm.id === invitationListValue
      )))) {
      if (invitationListTeams.length) {
        invitationListValue = invitationListTeams[0].id;
      } else if (canViewExternalInvitations) {
        invitationListValue = INDIVIDUALS;
      }
    }

    const canOnlyInviteLocalTeam = !canInviteExternal && canInviteExternalTeam;

    return (
      <>
        <div className="mb-2 mt-1">
          { (isManager || admin) && !isOrganization && (
            <div className="text-gray mb-4">
              {t('project:new-invitation.info')}
            </div>
          )}
          <span className={participants.length ? 'font-weight-bold' : 'font-italic'}>
            {
              participants.length ? t('project:x-participants', {
                count: participants.length,
              }) : t('project:no.participant')
            }
          </span>
        </div>
        <InvitationList
          show={showInvitationList}
          showIndividuals={canViewExternalInvitations}
          canInvite={showNewInvitationBtn}
          teams={invitationListTeams}
          teamMembers={allTeamMembers}
          onChange={(e) => this.setState({ invitationListTeam: Number(e.target.value) })}
          className="mb-5"
          projectUsers={projectUsers.sort(SortUtil.sortInvitations)}
          userProjectUser={projectUser}
          user={user}
          project={project}
          admin={admin}
          onPermissionToggle={this.onPermissionToggle}
          resendInvitation={this.resendInvitation}
          cancelInvitation={this.cancelInvitation}
          blockGuest={this.blockParticipant}
          removeGuest={this.removeParticipant}
          copyInvitationLink={this.copyInvitationLink}
        />
        {
          showNewInvitationBtn && (
            <div className="row mb-3 col-md-8">
              <NewTooltip
                disabled={!canOnlyInviteLocalTeam}
                content={t('project:invite-my-team-to-participate-tooltip')}
                arrow
                containerClassName="invite-your-local-team-tooltip"
                placement="right"
              >
                <span>
                  <button
                    className="btn btn-newblue-1 text-white"
                    disabled={invitationCreationInProgress}
                    onClick={() => this.onNewInvitation(
                      canInviteExternal, canInviteExternalTeam, canInviteTeamMember,
                      userAllowedTeams,
                    )}
                    id="new-invitation-button"
                  >
                    <FontAwesomeIcon
                      icon={['far', 'envelope']}
                      className="mr-2"
                    />
                    {t(`project:new-invitation.title${canOnlyInviteLocalTeam ? '-alt' : ''}`)}
                  </button>
                </span>
              </NewTooltip>
            </div>
          )
        }
        {
          invitationCreationInProgress && (
            <div className="col-12">
              {
                canInviteExternal && (canInviteExternalTeam || canInviteTeamMember) && (
                  <LabeledChoice
                    name="invitation-type"
                    label={<InvitationStepLabel label={t('project:invitation.choose-type')} />}
                    labelClassName="project-invitation-step mt-3"
                    choices={[{
                      text: (
                        <NewTooltip
                          content={(
                            <span>
                              {t('project:invitation.team-info.part-1')}
                              <b>
                                {t('project:invitation.team-info.part-2')}
                              </b>
                              {t('project:invitation.team-info.part-3')}
                              <br />
                              {t('project:invitation.team-info.part-4')}
                            </span>
                          )}
                          theme="light"
                        >
                          <span className="dotted-underline-gray">
                            { t('project:invitation.team') }
                          </span>
                        </NewTooltip>
                      ),
                      value: TEAM_INVITATION,
                    }, {
                      text: (
                        <NewTooltip
                          content={(
                            <span>
                              {t('project:invitation.external-info.part-1')}
                              <b>
                                {t('project:invitation.external-info.part-2')}
                              </b>
                              .
                              <br />
                              {t('project:invitation.external-info.part-3')}
                            </span>
                          )}
                          theme="light"
                        >
                          <span className="dotted-underline-gray">
                            { t('project:invitation.external') }
                          </span>
                        </NewTooltip>
                      ),
                      value: EXTERNAL_INVITATION,
                    },
                    ]}
                    value={invitationType}
                    required
                    onChange={this.onTypeInvitationChange}
                  />
                )
              }
              {
                invitationType && (
                  <>
                    {
                      invitationType === EXTERNAL_INVITATION
                        ? renderExternalInvitation()
                        : renderTeamInvitation()
                    }
                    {(invitationType === EXTERNAL_INVITATION
                      || Boolean(userAllowedTeams.length) || canCreateTeam) && (
                      <ProjectUserPermissionSettings
                        className="mt-4 mb-4 mx-0"
                        labelClassName="project-invitation-step"
                        value={this.getEffectiveInvitationPermissions(invitationPermissions)}
                        showTeamInvitationsPerm={invitationType === TEAM_INVITATION}
                        writablePermissions={getWritablePermissions(
                          projectUser, project, invitationTeam, invitationType, admin,
                        )}
                        type={getPermissionType(projectUser)}
                        project={project}
                        onChange={this.onPermissionsChange}
                        title={<InvitationStepLabel label={t('project:new-invitation.permissions-title')} />}
                      />
                    )}
                    <div className="mt-3" />
                    <MessageModal
                      message={(
                        <span>
                          <b>
                            {t('common:caution')}
                            &nbsp;!
                          </b>
                          <br />
                          <br />
                          {t('error:warning.identifying-data-shared-1')}
                          <br />
                          {t('error:warning.identifying-data-shared-2')}
                        </span>
                      )}
                      validateLabel="common:button.confirm"
                      validateBtnBgColorClass="btn-newred-2"
                      showCancelButton
                      triggerCondition={() => invitationType === TEAM_INVITATION}
                      triggerFuncName="onClick"
                      onValidate={this.invite}
                      modalBodyClassName="text-center"
                    >
                      <button
                        className={`btn btn-newblue-1 text-white mt-1 mr-4 ${invitationFormValid
                          ? '' : 'cursor-default'}`}
                        disabled={!invitationFormValid}
                        onClick={this.invite}
                      >
                        {t('common:button.invite')}
                      </button>
                    </MessageModal>
                  </>
                )
              }
              <button
                disabled={processingInvitation}
                className="btn btn-link pl-0 mr-2"
                type="button"
                onClick={() => { this.setState({ ...projectSettingsInvitationInitialState }); }}
              >
                {t('common:button.cancel')}
              </button>
              { processingInvitation && <MiniLoader /> }
              { invitationErrorMsg && <span className="text-red">{invitationErrorMsg}</span> }
            </div>
          )
        }
      </>
    );
  };

  renderStatsAccess = (canEdit) => {
    const { t, project, saveInput } = this.props;
    const statsAccessChoices = [
      {
        text: t('project:stats-access.all'),
        value: PROJECT_STATS_ACCESS_ALL,
      },
      {
        text: (
          <span>
            {t('project:stats-access.restricted')}
            &nbsp;
            <span className="font-italic">
              &quot;
              {t('project:permissions.can-view-external-inclusions')}
              &quot;
            </span>
          </span>
        ),
        value: PROJECT_STATS_ACCESS_RESTRICTED,
      },
      {
        text: t('project:stats-access.none'),
        value: PROJECT_STATS_ACCESS_NONE,
      },
    ];
    return (
      <LabeledChoice
        label={t('project:stats-access.label')}
        labelClassName="mb-2 mt-1"
        name="stats-access"
        value={project.stats_access}
        className="mb-0"
        choices={statsAccessChoices}
        onChange={(e) => { saveInput(e.target, 'stats_access'); }}
        orientation="vertical"
        hideOptionalLabel
        disabled={!canEdit || isProjectDisabled(project)}
      />
    );
  };

  renderDelete = () => {
    const { t } = this.props;

    return (
      <DelMsgModal
        message={(
          <span>
            <b>
              {t('common:caution')}
              &nbsp;!
            </b>
            <br />
            <br />
            {t('project:deletion-warning.part-1')}
            <strong>
              {t('project:deletion-warning.part-2')}
            </strong>
            {t('project:deletion-warning.part-3')}
            <br />
            <br />
            {t('common:confirm-deletion')}
          </span>
        )}
        onValidate={this.deleteProject}
      >
        <ButtonConfirm>
          <button
            type="button"
            className="btn btn-primary"
          >
            <span>
              <FontAwesomeIcon
                icon={['far', 'trash-alt']}
                className="mr-2"
              />
              {t('project:button.delete')}
            </span>
          </button>
        </ButtonConfirm>
      </DelMsgModal>
    );
  };

  renderQuit = () => {
    const { t, projectUser, teams: tms } = this.props;
    const { teamMemberToDetach } = this.state;
    const teams = this.getTeams(tms);
    const renderButton = () => (
      <ButtonConfirm>
        <button
          type="button"
          className="btn btn-red"
        >
          <span>
            <FontAwesomeIcon
              icon={['far', 'times']}
              className="mr-2"
            />
            {t('project:button.leave')}
          </span>
        </button>
      </ButtonConfirm>
    );
    const renderItem = (id, name) => (
      <a
        key={id}
        className="dropdown-item"
        role="link"
        onClick={() => {
          if (this.dropDownRef) this.dropDownRef.hide();
          this.setState({ teamMemberToDetach: id });
        }}
        tabIndex={0}
        onKeyPress={() => {}}
      >
        {name}
      </a>
    );
    const multiTeams = projectUser && projectUser.team_members.length > 1;

    return (
      <>
        <DelMsgModal
          message={(
            <span>
              <b>
                {t('common:caution')}
                &nbsp;!
              </b>
              <br />
              <br />
              {t('project:leaving-warning')}
            </span>
          )}
          onValidate={() => this.quitProject(multiTeams ? teamMemberToDetach : undefined)}
        >
          {
            multiTeams ? (
              <DropdownMenu
                type="dropup"
                mainClass="d-inline-block"
                triggerElement={renderButton()}
                ref={(ref) => { this.dropDownRef = ref; }}
              >
                <div className="px-3 text-nowrap quit-team">
                  {t('project:select-team-to-quit')}
                </div>
                {
                  teams.length && projectUser.team_members.map((teamMember) => {
                    const team = teams.find((tm) => tm.members.includes(teamMember.id));
                    return renderItem(teamMember.id, team.name);
                  })
                }
                {renderItem(QUIT_ALL_TEAMS, t('project:quit-all-team'))}
              </DropdownMenu>
            ) : renderButton()
          }
        </DelMsgModal>
        <Help
          iconClassName="ml-2 align-middle"
        >
          {t('project:leaving-project-help')}
        </Help>
      </>
    );
  };

  renderClone = (canEdit) => {
    const { t, project, admin } = this.props;
    const { processingCloning } = this.state;

    return (
      <>
        { processingCloning && <CardLoader /> }
        <DelMsgModal
          message={(
            <span>
              <b>
                {t('common:caution')}
                &nbsp;!
              </b>
              <br />
              <br />
              {t('project:cloning-warning')}
            </span>
          )}
          onValidate={this.cloneProject}
        >
          <ButtonConfirm>
            <button
              type="button"
              className="btn btn-yellow"
              disabled={!canEdit || (!admin && isProjectDisabled(project))}
            >
              <span>
                <FontAwesomeIcon
                  icon={['far', 'clone']}
                  className="mr-2"
                />
                {t('project:button.clone')}
              </span>
            </button>
          </ButtonConfirm>
        </DelMsgModal>
      </>
    );
  };

  renderExport = (canEdit) => {
    const { t, project, admin } = this.props;
    const { processingExport } = this.state;

    return (
      <>
        {processingExport && <CardLoader />}
        <DelMsgModal
          message={(
            <span>
              <b>
                {t('common:caution')}
                &nbsp;!
              </b>
              <br />
              <br />
              {t('project:export-warning')}
            </span>
          )}
          onValidate={this.exportProject}
        >
          <ButtonConfirm>
            <button
              type="button"
              className="btn btn-yellow"
              disabled={!canEdit || (!admin && isProjectDisabled(project))}
            >
              <span>
                <FontAwesomeIcon
                  icon={['far', 'arrow-to-bottom']}
                  className="mr-2"
                />
                {t('project:export-this-project')}
              </span>
            </button>
          </ButtonConfirm>
        </DelMsgModal>
      </>
    );
  };

  renderVisibilityStatus = (canEdit) => {
    const {
      t, admin, project, saveInput,
    } = this.props;
    const { showNotifiedManagerMessage } = this.state;

    const visibility = VISIBILITY_OPTIONS[project.visibility_status];

    const action = admin ? visibility.adminAction : visibility.action;
    const nextStatus = admin ? visibility.adminNextStatus : visibility.nextStatus;

    return (
      <>
        <div className="mb-1 d-flex align-items-center">
          <div className={`${visibility.class} mr-2 pt-1`}>
            {t(`project:visibility-status.${project.visibility_status}`)}
          </div>
          {canEdit && (
            <DelMsgModal
              message={(
                <span>
                  <strong>
                    {t('common:caution')}
                    &nbsp;!
                  </strong>
                  <br />
                  <br />
                  {t('project:depublication-warning.part-1')}
                  <strong>
                    {t('project:depublication-warning.part-2')}
                  </strong>
                  {t('project:depublication-warning.part-3')}
                </span>
              )}
              onValidate={() => saveInput({ value: PROJECT_NOT_PUBLIC }, 'visibility_status')}
              triggerCondition={() => project.visibility_status === PROJECT_PUBLIC}
              triggerFuncName="onClick"
            >
              <button
                className={`btn ${(nextStatus === PROJECT_PUBLIC_STATUS_REQUESTED || nextStatus === PROJECT_PUBLIC) ? 'btn-make-project-public' : 'btn-visibility-status-actions'}`}
                onClick={async () => {
                  try {
                    await saveInput(
                      { value: nextStatus }, 'visibility_status', null, 'value', {}, false,
                    );
                    if (nextStatus === PROJECT_PUBLIC) {
                      this.setState({ showNotifiedManagerMessage: true });
                      clearTimeout(this.activeTimeout);
                      this.activeTimeout = setTimeout(() => {
                        this.activeTimeout = -1;
                        this.setState({ showNotifiedManagerMessage: false });
                      }, 5000);
                    }
                  } catch (error) {
                    ErrorUtil.handleCatched(this.props, error);
                  }
                }}
                disabled={!canEdit}
              >
                {nextStatus === PROJECT_PUBLIC_STATUS_REQUESTED && (
                  <FontAwesomeIcon
                    icon={['far', 'globe']}
                    className="mr-2"
                  />
                )}
                {t(`community:projects.${action}`)}
              </button>
            </DelMsgModal>
          )}
        </div>
        {showNotifiedManagerMessage && admin && (
          <div className="text-green">
            {t('project:visibility-status:manager-notified')}
          </div>
        )}
      </>
    );
  };

  hasPermission = (permName) => {
    const { admin, projectUser } = this.props;
    if (admin) return true;
    if (permName && projectUser) return hasPermission(projectUser, permName);
    return false;
  };

  render() {
    const {
      t, admin, project, projectUser, user,
    } = this.props;
    const { ready, ownerUpdateInProgress, projectTeam } = this.state;
    const owner = project ? project.owner : undefined;
    const isManager = project && project.manager.id === user.id;
    const isOwner = owner && owner.id === user.id;
    const canManageExternalInvitations = Boolean(admin
      || (projectUser && hasPermission(projectUser, CAN_MANAGE_EXTERNAL_INVITATIONS)));
    const canInviteExternal = Boolean(admin
      || (projectUser && hasPermission(projectUser, CAN_INVITE_EXTERNAL)));
    const canInviteExternalTeam = Boolean(admin || (
      hasPermission(projectUser, CAN_INVITE_EXTERNAL_TEAM) && (project.team || !isManager)));
    const isShared = project.is_shared;

    const canMakeTheProjectPublic = admin
      || Boolean(owner && owner.limitations.can_make_the_project_public);

    // Project Edition Permissions
    const canRenameProject = this.hasPermission(CAN_RENAME_PROJECT);
    const canChangeConnectedAccount = this.hasPermission(CAN_CHANGE_PROJECT_CONNECTED_ACCOUNT);
    const canChangeVisibilityStatus = this.hasPermission(CAN_CHANGE_PROJECT_VISIBILITY_STATUS);
    const canChangeProjectStatus = this.hasPermission(CAN_CHANGE_PROJECT_STATUS);
    const canModifyTopics = this.hasPermission(CAN_MODIFY_PROJECT_TOPICS);
    const canChangeStatsAccess = this.hasPermission(CAN_CHANGE_PROJECT_STATS_ACCESS);
    const canCloneProject = this.hasPermission(CAN_CLONE_PROJECT);
    const canDeleteProject = this.hasPermission(CAN_DELETE_PROJECT);
    const canNameManager = this.hasPermission(CAN_NAME_MANAGER);
    const canDelegateIndividualProjectManagement = Boolean(owner
      && owner.limitations.can_delegate_individual_project_management);

    const projectOwnerCanOwnTeamProjects = Boolean(owner && owner.limitations.can_own_project
      && owner.limitations.max_team_creations);

    // No need to display the team section if the project owner can only own individual projects
    const showTeam = Boolean(admin || isOwner || (isManager && projectTeam))
      && !ownerUpdateInProgress && projectOwnerCanOwnTeamProjects;

    return ready && (admin || projectUser) && project ? (
      <div className="new-settings project-settings">
        <Section show={canRenameProject}>
          <SectionTitle>
            <Label className="field-label">
              {t('project:name')}
            </Label>
          </SectionTitle>
          <SectionContent>
            { this.renderName(canRenameProject) }
          </SectionContent>
        </Section>
        <Section>
          <SectionTitle>
            <Label className="field-label">
              {t('project:owner')}
            </Label>
          </SectionTitle>
          <SectionContent className="col-auto">
            { this.renderOwner() }
          </SectionContent>
        </Section>
        <Section show={showTeam}>
          <SectionTitle>
            <Label className="field-label mr-2">
              {t('project:team')}
            </Label>
            <Help interactive>
              {t('project:select-team-help')}
              <br />
              <FAQLink
                articleId={7}
                text={t('common:discover')}
              />
            </Help>
          </SectionTitle>
          <SectionContent>
            { this.renderTeam(canChangeConnectedAccount) }
          </SectionContent>
        </Section>
        <Section>
          <SectionTitle>
            <Label className="field-label">
              {t('project:manager')}
            </Label>
          </SectionTitle>
          <SectionContent className="col-auto">
            {this.renderManager(admin
              || (canNameManager && (projectTeam || canDelegateIndividualProjectManagement)))}
          </SectionContent>
        </Section>
        <Section id="project-status-section">
          <SectionTitle>
            <Label className="field-label mr-2">
              {t('project:phase')}
            </Label>
            <Help
              interactive
            >
              <FAQLink
                articleId={2}
              />
            </Help>
          </SectionTitle>
          <SectionContent className="col-auto col-md-8 col-lg-7 col-xl-6">
            { this.renderStatus(canChangeProjectStatus) }
          </SectionContent>
        </Section>
        <Section show={isTabletView() || isBrowserView()}>
          <SectionTitle>
            <Label className="field-label">
              {t('project:topics')}
            </Label>
          </SectionTitle>
          <SectionContent className={isTabletView() ? 'col-6' : 'col  col-md-8'}>
            { this.renderSharingSettings(canModifyTopics) }
          </SectionContent>
        </Section>
        <Section
          show={!isManager && !admin && projectUser && projectUser.type === PROJECT_USER_USUAL}
        >
          <SectionTitle>
            <Label className="field-label">
              {t('project:own-permissions')}
            </Label>
          </SectionTitle>
          <SectionContent className="col-auto">
            { this.renderOwnPermissions() }
          </SectionContent>
        </Section>
        <Section>
          <SectionTitle>
            <Label className="field-label">
              {t('project:guests')}
            </Label>
          </SectionTitle>
          <SectionContent className="col-12 col-md-8">
            {
              this.renderInvitations(canManageExternalInvitations, canInviteExternal,
                canInviteExternalTeam)
            }
          </SectionContent>
        </Section>
        <Section show={(canChangeStatsAccess || isOwner) && isShared}>
          <SectionTitle>
            <Label className="field-label mr-2">
              {t('project:stats-access.title')}
            </Label>
            <Help
              interactive
            >
              <FAQLink
                articleId={5}
              />
            </Help>
          </SectionTitle>
          <SectionContent className="col-12 col-md-8">
            {this.renderStatsAccess(canChangeStatsAccess)}
          </SectionContent>
        </Section>
        {canMakeTheProjectPublic && (
          <Section show={canChangeVisibilityStatus || admin || isManager}>
            <SectionTitle>
              <Label className="field-label mr-2">
                {t('project:visibility')}
              </Label>
              <Help>
                {t('project:visibility-help')}
                {!canChangeVisibilityStatus && (
                  <>
                    <br />
                    <br />
                    {t('project:visibility-help_read_only')}
                  </>
                )}
              </Help>
            </SectionTitle>
            <SectionContent>
              { this.renderVisibilityStatus(canChangeVisibilityStatus) }
            </SectionContent>
          </Section>
        )}
        <Section show={canCloneProject}>
          <SectionTitle>
            <Label className="field-label">
              {t('project:cloning')}
            </Label>
          </SectionTitle>
          <SectionContent>
            { this.renderClone(canCloneProject) }
          </SectionContent>
        </Section>
        <Section show={admin}>
          <SectionTitle>
            <Label className="field-label">
              {t('project:export')}
            </Label>
          </SectionTitle>
          <SectionContent>
            { this.renderExport(admin) }
          </SectionContent>
        </Section>
        <Section show={canDeleteProject}>
          <SectionTitle>
            <Label className="field-label">
              {t('project:deletion')}
            </Label>
          </SectionTitle>
          <SectionContent>
            { this.renderDelete() }
          </SectionContent>
        </Section>
        <Section
          show={!canDeleteProject && !isManager && projectUser
            && projectUser.type === PROJECT_USER_USUAL}
        >
          <SectionTitle>
            <Label className="field-label">
              {t('project:leaving')}
            </Label>
          </SectionTitle>
          <SectionContent>
            { this.renderQuit() }
          </SectionContent>
        </Section>
      </div>
    ) : (
      <CardLoader />
    );
  }
}


export default ProjectSettings;
