import { createReducer } from 'redux-starter-kit';
import { sortingHandlerActions } from '../actions';
import { getSortingItemId, extractSortingItemDigitalId } from '../actions/sorting-handler';

const {
  setItems, addItems, removeItem, removeHandler, moveItem, insertItem,
} = sortingHandlerActions;

export const sortingHandlerInitialState = {};

const sortingHandlerReducer = createReducer(sortingHandlerInitialState, {
  [setItems]: (state, action) => ({
    ...state,
    [action.payload.handlerId]: [...action.payload.items],
  }),
  [addItems]: (state, action) => ({
    ...state,
    [action.payload.handlerId]: [...state[action.payload.handlerId], ...action.payload.items],
  }),
  [removeItem]: (state, action) => {
    const { handlerId, item } = action.payload;
    const items = [...state[handlerId]];
    const index = items.findIndex((it) => it === item);
    if (index >= 0 && index < items.length) {
      items.splice(index, 1);
    } else {
      console.error(`Not able to retrieve the item to remove (index: ${index}).`);
    }
    return {
      ...state,
      [action.payload.handlerId]: items,
    };
  },
  [removeHandler]: (state, action) => {
    const { [action.payload.handlerId]: _, ...newState } = state;
    return newState;
  },
  [moveItem]: (state, action) => {
    const {
      sourceIndex, sourceHandlerId, destinationIndex, destinationHandlerId,
    } = action.payload;
    const item = state[sourceHandlerId][sourceIndex];
    let newItem = item;
    // Handle moving item in another page
    if (sourceHandlerId !== destinationHandlerId) {
      const itemIndex = extractSortingItemDigitalId(item);
      newItem = getSortingItemId(destinationHandlerId, itemIndex);
    }
    const sourceItems = [...state[sourceHandlerId]];
    const destinationItems = sourceHandlerId === destinationHandlerId ? sourceItems
      : [...(state[destinationHandlerId] || [])];
    sourceItems.splice(sourceIndex, 1);
    destinationItems.splice(destinationIndex, 0, newItem);
    return {
      ...state,
      [sourceHandlerId]: sourceItems,
      [destinationHandlerId]: destinationItems,
    };
  },
  [insertItem]: (state, action) => {
    const { handlerId, previousItem, item } = action.payload;
    let items = [...state[handlerId]];
    // Look for the previous item index
    const index = items.findIndex((it) => it === previousItem);
    if (index >= 0 && index < (items.length - 1)) {
      // insert right after the previous item
      const itemsEnd = items.slice(index + 1);
      const itemsStart = items.slice(0, index + 1);
      itemsStart.push(item);
      items = itemsStart.concat(itemsEnd);
    } else if (index === (items.length - 1)) {
      // insert at the end of array
      items.push(item);
    } else {
      console.error(`Not able to insert the item (index: ${index}).`);
    }
    return {
      ...state,
      [action.payload.handlerId]: items,
    };
  },
});

export default sortingHandlerReducer;
