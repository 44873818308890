import React, { Component } from 'react';
import Avatar from 'react-avatar';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { INSTITUTION_TYPES } from '../constants';
import TextUtil from '../utils/TextUtil';

const AvatarBase = (props) => (
  <Avatar
    size="30px"
    round
    {...props}
  />
);

// eslint-disable-next-line react/prefer-stateless-function
export class UserAvatar extends Component {
  static propTypes = {
    className: PropTypes.string,
    user: PropTypes.shape(),
    toLabel: PropTypes.func,
    toValue: PropTypes.func,
    avatarProps: PropTypes.shape(),
  }

  static defaultProps = {
    className: '',
    user: {},
    toLabel: (user) => (!INSTITUTION_TYPES.includes(user.type) ? user.label : undefined),
    toValue: (user) => {
      if (user.type && INSTITUTION_TYPES.includes(user.type)) {
        return user.username || user.label;
      }
      return undefined;
    },
    avatarProps: {},
  };

  truncateLabel = (label) => {
    const { user } = this.props;

    if (label) {
      if (INSTITUTION_TYPES.includes(user.type)) {
        return TextUtil.truncate(label, 5);
      }
      return label;
    }
    return undefined;
  };

  render() {
    const {
      className, user, toLabel, toValue, avatarProps,
    } = this.props;
    const label = toLabel(user);
    const value = toValue(user);

    return (
      <AvatarBase
        className={className}
        name={this.truncateLabel(label)}
        value={this.truncateLabel(value)}
        title={label || value}
        textSizeRatio={2}
        round={!user.type || !INSTITUTION_TYPES.includes(user.type)}
        {...avatarProps}
      />
    );
  }
}

// eslint-disable-next-line react/prefer-stateless-function
export class EmailAvatar extends Component {
  static propTypes = {
    className: PropTypes.string,
    email: PropTypes.string.isRequired,
    avatarProps: PropTypes.shape(),
  }

  static defaultProps = {
    className: '',
    avatarProps: {},
  };

  render() {
    const { className, email, avatarProps } = this.props;

    return (
      <>
        <AvatarBase
          className={className}
          name={email}
          textSizeRatio={1.75}
          {...avatarProps}
        />
        <FontAwesomeIcon
          className="confirmed-status-icon text-muted"
          icon={['fas', 'envelope']}
        />
      </>
    );
  }
}

// eslint-disable-next-line react/prefer-stateless-function
export class LabeledUserAvatar extends Component {
  static propTypes = {
    user: PropTypes.shape().isRequired,
    toLabel: PropTypes.func,
    toAvatarLabel: PropTypes.func,
    showLabel: PropTypes.bool,
    showAvatar: PropTypes.bool,
    avatarProps: PropTypes.shape(),
  };

  static defaultProps = {
    toLabel: (user) => user.label,
    toAvatarLabel: undefined,
    showAvatar: true,
    showLabel: true,
    avatarProps: {},
  };

  render() {
    const {
      user, toLabel, toAvatarLabel, showAvatar, showLabel, avatarProps,
    } = this.props;

    return (
      <>
        {
          showAvatar && (
            <UserAvatar user={user} toLabel={toAvatarLabel} avatarProps={avatarProps} />
          )
        }
        {
          showLabel && (
            <span className={showAvatar ? 'ml-2' : ''}>
              {toLabel(user)}
            </span>
          )
        }
      </>
    );
  }
}


// eslint-disable-next-line react/prefer-stateless-function
export class InvitationAvatar extends Component {
  static propTypes = {
    user: PropTypes.shape(),
    email: PropTypes.string,
    confirmed: PropTypes.bool.isRequired,
  };

  static defaultProps = {
    user: {},
    email: '',
  };

  render() {
    const { user, email, confirmed } = this.props;
    const avatarProps = { className: 'mr-1' };

    return confirmed ? (
      <UserAvatar
        user={user}
        {...avatarProps}
      />
    ) : (
      <EmailAvatar
        email={email}
        {...avatarProps}
      />
    );
  }
}
