import buildStdDbModelActions from './std-db-model-actions';
import authActions from './auth';

const stdDbModelActions = buildStdDbModelActions('users');

const resyncAuthUser = (dispatch, getState, users) => {
  if (users) {
    const { authUser } = getState().auth;
    const user = authUser ? users.find((usr) => usr.id === authUser.id) : null;
    if (user) {
      dispatch(authActions.resyncAuthUser(user));
    }
  }
};

const list = (params = {}, options = {}, ignoreData = () => false) => (
  async (dispatch, getState) => {
    const res = await dispatch(stdDbModelActions.list(params, options, ignoreData));
    resyncAuthUser(dispatch, getState, res.results);
    return res;
  }
);

const read = (id, params = {}, ignoreData = (
) => false, resync = true) => async (dispatch, getState) => {
  const res = await dispatch(stdDbModelActions.read(id, params, ignoreData));
  if (resync) {
    resyncAuthUser(dispatch, getState, [res]);
  }
  return res;
};

const patch = (id, data, params = {}) => async (dispatch, getState) => {
  const res = await dispatch(stdDbModelActions.patch(id, data, params));
  resyncAuthUser(dispatch, getState, [res]);
  return res;
};

export default {
  ...stdDbModelActions,
  list,
  read,
  patch,
};
