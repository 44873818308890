import React from 'react';
import PropTypes from 'prop-types';
import { childrenPropTypes } from '../utils/generic-prop-types';

// table, body, head, cell, row

function TableCell(props) {
  const { className, children, tag } = props;

  const CellTag = tag;

  return (
    <CellTag
      className={className}
      {...props}
    >
      {children}
    </CellTag>
  );
}

TableCell.propTypes = {
  className: PropTypes.string,
  tag: PropTypes.string,
  children: childrenPropTypes([PropTypes.string]).isRequired,
};

TableCell.defaultProps = {
  className: 'text-center align-middle',
  tag: 'td',
};

function TableRow(props) {
  const { className, children } = props;
  return (
    <tr
      className={className}
      {...props}
    >
      {children}
    </tr>
  );
}

TableRow.propTypes = {
  className: PropTypes.string,
  children: childrenPropTypes([PropTypes.string]).isRequired,
};

TableRow.defaultProps = {
  className: 'text-center align-middle',
};

function TableHead(props) {
  const { className, children } = props;
  return (
    <thead className={className}>
      {children}
    </thead>
  );
}

TableHead.propTypes = {
  className: PropTypes.string,
  children: childrenPropTypes([PropTypes.string]).isRequired,
};

TableHead.defaultProps = {
  className: 'table-head',
};

function TableBody(props) {
  const { className, children } = props;
  return (
    <tbody className={className}>
      {children}
    </tbody>
  );
}

TableBody.propTypes = {
  className: PropTypes.string,
  children: childrenPropTypes([PropTypes.string]).isRequired,
};

TableBody.defaultProps = {
  className: '',
};

function Table(props) {
  const { className, extraClassName, children } = props;
  return (
    <table className={`${className} ${extraClassName}`}>
      {children}
    </table>
  );
}

Table.propTypes = {
  className: PropTypes.string,
  extraClassName: PropTypes.string,
  children: childrenPropTypes([PropTypes.string]).isRequired,
};

Table.defaultProps = {
  className: 'table custom-table bg-white',
  extraClassName: '',
};

export {
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
};
