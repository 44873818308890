import axios from 'axios';
import { cacheAdapterEnhancer } from 'axios-extensions';
import querystring from 'querystring';
import i18n from './i18n';
import ErrorUtil from './utils/ErrorUtil';

const { sessionStorage } = window;
const getApiDevUrl = () => {
  const url = new URL(window.location);
  return `http://${url.hostname}:8000`;
};

export const API_URL = process.env.REACT_APP_API_URL || getApiDevUrl();
const STORAGE_DOQBOARD_TOKEN = 'doqboard-token';
const STORAGE_USER_ID = 'user-id';

const paramsSerializer = (params) => {
  const serializedParams = { ...params };
  Object.entries(serializedParams).forEach(([key, value]) => {
    if (value instanceof Array) {
      serializedParams[key] = value.join(',');
    }
  });
  return querystring.stringify(serializedParams);
};

const http = axios.create({
  baseURL: API_URL,
  paramsSerializer,
  adapter: cacheAdapterEnhancer(axios.defaults.adapter, {
    enabledByDefault: false,
    cacheFlag: 'useCache',
  }),
});

const request = async (endpoint, id = undefined, method, params = {}, data = {},
  cacheOptions = { useCache: false }, otherOptions = {}) => {
  const token = sessionStorage.getItem(STORAGE_DOQBOARD_TOKEN);
  const headers = { 'Accept-Language': i18n.language };
  if (token) {
    headers.Authorization = `Token ${token}`;
  }
  try {
    return await http.request({
      url: `/${endpoint}/${id ? `${id}/` : ''}`,
      method,
      params,
      data,
      headers,
      ...cacheOptions,
      ...otherOptions,
    });
  } catch (error) {
    if (error.response) {
      // 500 error handling
      if (error.response.status === 500) {
        throw new Error(i18n.t('common:server-error'));
      }

      // Specific error handling
      let codes;
      if (error.response.data.detail) {
        codes = [error.response.data.detail.code];
      } else if (Array.isArray(error.response.data) && error.response.data.length) {
        codes = error.response.data.map((err) => err.code);
      }
      if (codes && codes.length) {
        const codeOverride = codes.find((code) => ErrorUtil.matchOneErrorOverride(code));
        if (codeOverride) throw new Error(`__${codeOverride}`);
      }

      // General error handling
      throw new Error(JSON.stringify(error.response.data));
    }
    throw error;
  }
};


const requestData = async (...args) => (
  (await request(...args)).data
);


const api = {
  request,
  requestData,

  auth: async (username, password) => {
    const result = await requestData('api-auth-token', undefined, 'post', undefined, {
      username,
      password,
    });
    sessionStorage.setItem(STORAGE_DOQBOARD_TOKEN, result.token);
    sessionStorage.setItem(STORAGE_USER_ID, result.user.id);
    return result.user;
  },

  tryAutoReAuth: async () => {
    const token = sessionStorage.getItem(STORAGE_DOQBOARD_TOKEN);
    const userId = sessionStorage.getItem(STORAGE_USER_ID);
    if (token && userId) {
      const user = await requestData('users', userId, 'get');
      return user;
    }
    throw new Error('Not able to process automatic reauth');
  },

  logout: async () => {
    sessionStorage.removeItem(STORAGE_DOQBOARD_TOKEN);
    sessionStorage.removeItem(STORAGE_USER_ID);
  },

  preflight: async (endpoint, data = {}, params = {}) => (
    requestData(endpoint, undefined, 'options', params, data)
  ),

  count: async (endpoint, params = {}, options = { cache: { useCache: false } }) => {
    try {
      const res = await requestData(endpoint, undefined, 'get', { ...params, page_size: 1 }, options.cache);
      return res.count;
    } catch (error) {
      console.error(error);
      return 0;
    }
  },

  // pagination values : undefined, 'short' or 'no'
  list: async (endpoint, params = {}, options = {
    pagination: undefined,
    cache: { useCache: false },
  }) => {
    const updatedParams = { ...params };

    switch (options.pagination) {
      case 'short':
        updatedParams.page_size = 10;
        break;
      case 'no':
        updatedParams.no_pagination = true;
        break;
      default:
        updatedParams.page_size = 10000;
    }

    // Skip the request if a parameter matches this pattern : xxx__in: [].
    // Else Django response will select all records wheareas we currently expect an empty response.
    let skipRequest = false;
    Object.entries(params).forEach(([key, value]) => {
      if (!skipRequest && key.split('__').pop() === 'in' && Array.isArray(value) && value.length === 0) {
        skipRequest = true;
      }
    });

    if (skipRequest) {
      return options.pagination === 'no' ? [] : {
        count: 0, next: null, previous: null, results: [],
      };
    }

    const res = await requestData(
      endpoint,
      undefined,
      'get',
      updatedParams,
      {},
      options.cache,
    );

    return res;
  },

  create: async (endpoint, data = {}, params = {}) => (
    requestData(endpoint, undefined, 'post', params, data)
  ),

  read: async (endpoint, id, params = {}, options = { cache: { useCache: false } }) => (
    requestData(endpoint, id, 'get', params, {}, options.cache)
  ),

  update: async (endpoint, id, data = {}, params = {}) => (
    requestData(endpoint, id, 'put', params, data)
  ),

  partial_update: async (endpoint, id, data = {}, params = {}) => (
    requestData(endpoint, id, 'patch', params, data)
  ),

  delete: async (endpoint, id, params = {}) => (
    requestData(endpoint, id, 'delete', params)
  ),
};

export default api;
