import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import '../assets/css/comment.css';
import TextUtil from '../utils/TextUtil';
import NewTooltip from './NewTooltip';
import lightBulb from '../assets/img/light_bulb.png';

class ElementComment extends Component {
  static propTypes = {
    comment: PropTypes.string.isRequired,
    className: PropTypes.string,
  };

  static defaultProps = {
    className: '',
  };

  static list = [];

  constructor(props) {
    super(props);
    this.tooltip = null;
    this.state = { visible: undefined };
    ElementComment.list.push(this);
  }

  componentWillUnmount() {
    const index = ElementComment.list.indexOf(this);
    if (index > -1) {
      ElementComment.list.splice(index, 1);
    }
  }

  show = () => {
    this.setState({ visible: true });
  };

  hide = async () => {
    await this.setState({ visible: false });
    await this.setState({ visible: undefined });
  };

  render() {
    const { comment, className } = this.props;
    const { visible } = this.state;

    return (
      <NewTooltip
        theme="comment"
        content={(
          <div>
            {
              visible && (
                <div className="close-button-box modality-comment">
                  <button
                    className="close-button-sm btn btn-link text-primary"
                    onClick={() => this.hide()}
                  >
                    &times;
                  </button>
                </div>
              )
            }
            <div className="comment-icon" style={{ backgroundImage: `url(${lightBulb})` }} />
            <div
              className="comment-content"
              dangerouslySetInnerHTML={{
                __html: TextUtil.parseLinks(comment),
              }}
            />
          </div>
        )}
        trigger="mouseenter"
        visible={visible}
        placement="top-start"
        interactive
        arrow={false}
        ref={(tooltip) => { this.tooltip = tooltip; }}
      >
        <button
          type="button"
          className={`comment-button no-button-style ${className}`}
          ref={(container) => { this.container = container; }}
        >
          <FontAwesomeIcon
            icon={['fas', 'comment-alt-exclamation']}
            className="comment-icon"
          />
        </button>
      </NewTooltip>
    );
  }
}


export default ElementComment;
