import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';
import '../assets/css/head.css';


class AdminHead extends React.Component {
  static propTypes = {
    hideHeader: PropTypes.bool,
    breadcrumbPrevious: PropTypes.arrayOf(PropTypes.shape({
      link: PropTypes.string,
      icon: PropTypes.string,
      title: PropTypes.string,
    })),
    icon: PropTypes.string,
    title: PropTypes.string,
    subTitle: PropTypes.string,
    admin: PropTypes.bool,
    helpButton: PropTypes.func,
  };

  static defaultProps = {
    hideHeader: false,
    breadcrumbPrevious: null,
    icon: null,
    title: null,
    subTitle: null,
    admin: false,
    helpButton: null,
  };

  componentDidMount() {
    this.breadcrumb = [];
    let i = 1;
    this.hideHeader = this.props.hideHeader;
    if (this.props.hideHeader !== true) {
      this.hideHeader = false;
    }
    if (this.props.breadcrumbPrevious
      && this.props.breadcrumbPrevious.length > 0) {
      this.props.breadcrumbPrevious.forEach((bc) => {
        this.breadcrumb.push(
          <li key={i} className="breadcrumb-item">
            <Link to={bc.link}>
              <FontAwesomeIcon icon={bc.icon} className="mr-2" />
              {bc.title}
            </Link>
          </li>,
        );
        i += 1;
      });
    }
    this.breadcrumb.push(
      <li key={i} className="breadcrumb-item active">
        {
          this.props.icon ? (
            <FontAwesomeIcon icon={this.props.icon} className="mr-2" />
          ) : null
        }
        {this.props.title}
      </li>,
    );
    if (this.props.helpButton) this.props.helpButton(this.helpButton);
  }

  componentWillUnmount() {
    delete this.breadcrumb;
    delete this.hideHeader;
    delete this.helpButton;
  }

  render() {
    const {
      hideHeader, admin, title, subTitle,
    } = this.props;
    return (
      <div
        id={hideHeader ? '' : 'head'}
        className={`mt-1${hideHeader ? '' : ' mb-3'}`}
      >
        {
          !this.hideHeader ? (
            <div className="row">
              <div className="col-12">
                <div className="card bg-transparent border-0 py-5 mt-2">
                  <h2
                    className={`m-0 ${
                      admin && admin === true
                        ? 'text-red'
                        : 'text-blue-4'}`}
                    style={{ fontWeight: 600 }}
                  >
                    {/* this.props.icon
                      ? (
                        <FontAwesomeIcon icon={this.props.icon} className="mr-2" />)
                      : null */}
                    {title}
                  </h2>
                  <span
                    className={`mt-2 ${
                      admin && admin === true
                        ? 'text-red'
                        : 'text-gray-700'}`}
                  >
                    {subTitle}
                  </span>
                </div>
              </div>
            </div>
          ) : null
        }
      </div>
    );
  }
}


export default AdminHead;
