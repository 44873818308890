import Autoformat from '@ckeditor/ckeditor5-autoformat/src/autoformat';
import Bold from '@ckeditor/ckeditor5-basic-styles/src/bold';
import Italic from '@ckeditor/ckeditor5-basic-styles/src/italic';
import Strikethrough from '@ckeditor/ckeditor5-basic-styles/src/strikethrough';
import Underline from '@ckeditor/ckeditor5-basic-styles/src/underline';
import InlineEditor from '@ckeditor/ckeditor5-editor-inline/src/inlineeditor';
import Essentials from '@ckeditor/ckeditor5-essentials/src/essentials';
import Heading from '@ckeditor/ckeditor5-heading/src/heading';
import Link from '@ckeditor/ckeditor5-link/src/link';
import List from '@ckeditor/ckeditor5-list/src/list';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import Table from '@ckeditor/ckeditor5-table/src/table';
import TableToolbar from '@ckeditor/ckeditor5-table/src/tabletoolbar';
import PropTypes from 'prop-types';
import React, { Component } from 'react';


class Editor extends Component {
  static propTypes = {
    data: PropTypes.string,
    onInit: PropTypes.func,
    onChange: PropTypes.func,
    disabled: PropTypes.bool,
    placeholder: PropTypes.string,
  };

  static defaultProps = {
    data: '',
    onInit: null,
    onChange: null,
    disabled: false,
    placeholder: '',
  };

  constructor(props) {
    super(props);
    this.state = {
      content: props.data,
    };
    this.config = {
      plugins: [
        Heading,
        Bold,
        Italic,
        Underline,
        Strikethrough,
        Link,
        List,
        Table,
        TableToolbar,
        Essentials,
        Autoformat,
      ],
      toolbar: [
        'heading',
        'bold',
        'italic',
        'underline',
        'strikethrough',
        'link',
        'bulletedList',
        'numberedList',
        'insertTable',
        'undo',
        'redo',
      ],
      table: {
        contentToolbar: [
          'tableColumn',
          'tableRow',
          'mergeTableCells',
        ],
      },
      placeholder: props.placeholder,
    };
  }

  render() {
    return !this.props.disabled ? (
      <CKEditor
        editor={InlineEditor}
        config={this.config}
        data={this.state.content}
        onReady={this.props.onInit}
        onChange={(event, editor) => {
          this.setState({ content: editor.getData() });
          if (this.props.onChange) this.props.onChange(editor.getData());
        }}
        disabled={this.props.disabled}
      />
    ) : (
      <div
        dangerouslySetInnerHTML={{ __html: this.state.content }}
        className="p-2"
      />
    );
  }
}


export default Editor;
