import React, { Component } from 'react';
import PropTypes from 'prop-types';


const withMsgModalBase = (ComponentBase, MsgModal, msgModalManagerName) => {
  class ComponentWithLicMsgModal extends Component {
    static propTypes = {
      user: PropTypes.shape().isRequired,
    };

    constructor(props) {
      super(props);
      this.msgModalRef = React.createRef();
      this.componentRef = null;
    }

    onValidate = () => {
      if (this.componentRef && this.componentRef.onRedirection) {
        this.componentRef.onRedirection();
      }
    }

    render() {
      const { forwardedRef, ...rest } = this.props;
      const modalManagerProp = { [msgModalManagerName]: () => this.msgModalRef.current };
      return (
        <>
          <ComponentBase
            {...rest}
            {...modalManagerProp}
            key={1}
            ref={(ref) => {
              this.componentRef = ref;
              if (forwardedRef) forwardedRef(ref);
            }}
          />
          <MsgModal
            key={2}
            innerRef={this.msgModalRef}
            onValidate={this.onValidate}
          />
        </>
      );
    }
  }

  ComponentWithLicMsgModal.propTypes = {
    forwardedRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape()]),
  };

  ComponentWithLicMsgModal.defaultProps = {
    forwardedRef: undefined,
  };

  return ComponentWithLicMsgModal;
};

export default withMsgModalBase;
