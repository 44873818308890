import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { nsOptions } from '../i18n';
import FormattedValue from '../utils/FormattedValue';


const mapStateToProps = (state, ownProps) => ({
  elements: state.elements,
  projectElement: state.projectElements[ownProps.projectElementId],
  projectEntry: Object.values(state.projectEntries).find((pEntry) => (
    pEntry.project_element === ownProps.projectElementId
        && pEntry.inclusion === ownProps.inclusionId
        && pEntry.module === ownProps.moduleInstanceId
  )),
});


@connect(mapStateToProps)
@withTranslation('', nsOptions)
class ElementCalculationResult extends Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
    projectElement: PropTypes.shape().isRequired,
    projectEntry: PropTypes.shape(),
    elements: PropTypes.shape().isRequired,
  };

  static defaultProps = {
    projectEntry: null,
  };

  render() {
    const {
      t, projectElement, projectEntry, elements,
    } = this.props;
    let content;
    let className;
    let inconsistentWarning;
    let inconsistentClassName;

    if (projectElement) {
      const { unit, formula } = elements[projectElement.element];

      if (projectEntry && (projectEntry.value || projectEntry.value === 0)) {
        const { value } = projectEntry;
        content = <FormattedValue value={value} unit={unit} />;
        if (!projectEntry.consistent) {
          inconsistentWarning = t('project:calculations.inconsistent-value');
          inconsistentClassName = 'calculation-info pl-3 text-red';
        }
        className = 'measure-calculation';
      } else if (formula && formula.length > 0) {
        content = t('project:calculations.missing-values');
        className = 'calculation-info';
      } else {
        content = t('project:calculations.missing-formula');
        className = 'calculation-info';
      }
    }

    if (!content) {
      return null;
    }

    return (
      <div className={className}>
        {content}
        {
          inconsistentWarning && (
            <span
              className={inconsistentClassName}
            >
              {inconsistentWarning}
            </span>
          )
        }
      </div>
    );
  }
}

export default ElementCalculationResult;
