import { Mutex } from 'async-mutex';
import store from '../redux/store';
import { localIdManagerActions } from '../redux/actions';

const { getPoolUniqueId, removePoolAllUniqueIds } = localIdManagerActions;
const mutexes = {};

export const getPersistentMutex = (pool, associatedIds) => {
  const id = store.dispatch(getPoolUniqueId(pool, associatedIds));
  let mutex = mutexes[id];
  if (!mutex) {
    mutex = new Mutex();
    mutexes[id] = mutex;
  }
  return mutex;
};

export const deletePersistentMutexes = (pool) => {
  const existingIds = store.getState().localIdManager[pool];
  if (existingIds) {
    Object.values(existingIds).forEach((id) => {
      delete mutexes[id];
    });
    store.dispatch(removePoolAllUniqueIds(pool));
  }
};
