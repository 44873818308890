import PropTypes from 'prop-types';
import React from 'react';
import { withRouter } from 'react-router';
import { Route, Switch, Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import OrganizationProfile from './OrganizationProfile';
import Head from './DashboardHead';
import { deObfuscateEmail } from '../utils/email';
import OrganizationProjects from './OrganizationProjects';
import OrganizationRequests from './OrganizationRequests';
import OrganizationNetwork from './OrganizationNetwork';
import BrowserView from './BrowserView';
import MobileUnsupported from './MobileUnsupported';
import TabletUnsupported from './TabletUnsupported';
import { childrenPropTypes } from '../utils/generic-prop-types';

function LinkItem(props) {
  const { url, children, active } = props;

  return (
    <li className="nav-item">
      <Link
        className={`nav-link community-nav-link ${active ? 'active' : ''}`}
        to={url}
      >
        {children}
      </Link>
    </li>
  );
}

LinkItem.propTypes = {
  url: PropTypes.string.isRequired,
  children: childrenPropTypes().isRequired,
  active: PropTypes.bool,
};

LinkItem.defaultProps = {
  active: false,
};

function OrganizationCommunity(props) {
  const {
    admin, publicProfile, location, organization,
  } = props;
  const { url } = props.match;
  const { t } = useTranslation();

  const supportEmail = deObfuscateEmail('support', 'doqboard.com');

  const isActive = (to) => {
    const res = new RegExp(`^${to}`, 'i').test(location.pathname);
    return res;
  };

  return (
    <div>
      <BrowserView>
        {publicProfile ? (
          <div>
            <Head
              {...props}
              title={(
                <span>
                  {t('community:organizations.tabs.community')}
                </span>
              )}
              subtitle={t('community:organizations.tabs.community-subtitle')}
            />
            <div className="card bg-white mt-3">
              <div className="card-header">
                <ul className="nav nav-tabs card-header-tabs">
                  <LinkItem
                    url={`${url}/my-profile`}
                    active={isActive(`${url}/my-profile`)}
                  >
                    {t('community:organizations.tabs.profile')}
                  </LinkItem>
                  <LinkItem
                    url={`${url}/projects`}
                    active={isActive(`${url}/projects`)}
                  >
                    {t('community:organizations.tabs.visibility-of-the-projects')}
                  </LinkItem>
                  <LinkItem
                    url={`${url}/requests`}
                    active={isActive(`${url}/requests`)}
                  >
                    {t('community:organizations.tabs.participation-requests')}
                  </LinkItem>
                  <LinkItem
                    url={`${url}/followers`}
                    active={isActive(`${url}/followers`)}
                  >
                    {t('community:organizations.tabs.followers')}
                  </LinkItem>
                </ul>
              </div>
              <div className="card-body">
                <div className="p-4">
                  <Switch>
                    <Route
                      {...props}
                      path={`${url}/followers`}
                    >
                      <OrganizationNetwork
                        organization={organization}
                        admin={admin}
                      />
                    </Route>
                    <Route
                      {...props}
                      path={`${url}/requests`}
                    >
                      <OrganizationRequests
                        organization={organization}
                        admin={admin}
                      />
                    </Route>
                    <Route
                      {...props}
                      path={`${url}/projects`}
                    >
                      <OrganizationProjects
                        organization={organization}
                        admin={admin}
                      />
                    </Route>
                    <Route
                      {...props}
                      path={`${url}/my-profile`}
                    >
                      <OrganizationProfile
                        key="editable-profile"
                        organization={organization}
                        isEditMode
                        admin={admin}
                      />
                    </Route>
                  </Switch>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div
            className="dashboard-content"
          >
            <div className="card bg-white card-shadow p-4 mt-3 mobile-card disable-scroll-x">
              <div className="community-access-denied">
                <h6>
                  {t('community:organizations.request-to-activate-public-profile.main')}
                </h6>
                <p className="text-muted text-center mt-3">
                  {t('community:organizations.request-to-activate-public-profile.contact-us',
                    { email: supportEmail })}
                </p>
              </div>
            </div>
          </div>
        )}
      </BrowserView>
      <TabletUnsupported />
      <MobileUnsupported>
        common:mobile-and-tablet-unsupported
      </MobileUnsupported>
    </div>
  );
}

OrganizationCommunity.propTypes = {
  organization: PropTypes.shape().isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
  match: PropTypes.shape().isRequired,
  publicProfile: PropTypes.bool,
  admin: PropTypes.bool,
};

OrganizationCommunity.defaultProps = {
  publicProfile: false,
  admin: false,
};

export default withRouter(OrganizationCommunity);
