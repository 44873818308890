import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Button } from 'reactstrap';


class NavButtons extends Component {
  static propTypes = {
    validateBtnLabel: PropTypes.string.isRequired,
    cancelBtnLabel: PropTypes.string,
    validateBtnClbk: PropTypes.func,
    cancelBtnClbk: PropTypes.func,
    disableValidateBtn: PropTypes.bool,
    disableCancelBtn: PropTypes.bool,
    className: PropTypes.string,
  };

  static defaultProps = {
    cancelBtnLabel: null,
    validateBtnClbk: null,
    cancelBtnClbk: null,
    disableValidateBtn: false,
    disableCancelBtn: false,
    className: '',
  };

  constructor(props) {
    super(props);
    this.props = props;
  }

  render() {
    const {
      className, disableValidateBtn, cancelBtnClbk, cancelBtnLabel, validateBtnClbk,
      disableCancelBtn,
    } = this.props;
    return (
      <div className={`row justify-content-center${className ? ` ${className}` : ''}`}>
        <div className="col-auto">
          <Button
            color="newturquoise-1"
            type="button"
            disabled={disableValidateBtn}
            onClick={validateBtnClbk}
          >
            {this.props.validateBtnLabel}
          </Button>
        </div>
        {
          cancelBtnLabel ? (
            <div className="col-auto">
              <button
                className="btn btn-link"
                type="button"
                disabled={disableCancelBtn}
                onClick={cancelBtnClbk}
              >
                {cancelBtnLabel}
              </button>
            </div>
          ) : null
        }
      </div>
    );
  }
}


export default NavButtons;
