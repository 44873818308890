import buildStdDbModelActions, { resyncWithoutDispatch } from './std-db-model-actions';
import elementModalitiesActions from './element-modalities';
import api from '../../api';
import { ELEMENT_TYPE_MULTIPLE_CHOICES, ELEMENT_TYPE_UNIQUE_CHOICE } from '../../constants';

const endpoint = 'elements';
const stdDbModelActions = buildStdDbModelActions(endpoint);

const resyncElementModalities = (elementsIds, getState, params) => resyncWithoutDispatch(
  'element-modalities',
  getState,
  () => ({ element__in: elementsIds }),
  params,
);

const create = (data, params = {}) => async (dispatch, getState) => {
  const res = await api.create(endpoint, data, params);

  if ([ELEMENT_TYPE_UNIQUE_CHOICE, ELEMENT_TYPE_MULTIPLE_CHOICES].includes(data.type)) {
    const resyncRes = await resyncElementModalities(
      [...Object.values(getState().elements).map((el) => el.id), res.id],
      getState,
      params,
    );
    dispatch(elementModalitiesActions.resyncSuccess(resyncRes));
  }

  dispatch(stdDbModelActions.createSuccess(res));
  return res;
};

export default {
  ...stdDbModelActions,
  create,
};
