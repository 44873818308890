import moment from 'moment';

export const alphaNumericSort = (a, b) => {
  let options;
  if (!Number.isNaN(Number(a)) && !Number.isNaN(Number(b))) options = { numeric: true };
  return a.localeCompare(b, undefined, options);
};

export default class SortUtil {
  static sortArray(a, b) {
    if (a.sorting < b.sorting) return -1;
    if (a.sorting > b.sorting) return 1;
    return 0;
  }

  static sortElement(a, b) {
    if (a.props['data-sorting'] < b.props['data-sorting']) return -1;
    if (a.props['data-sorting'] > b.props['data-sorting']) return 1;
    return 0;
  }

  static sortUsers(a, b) {
    if (a.id === b.id) return 0;
    if (a.id === '000000000000000000000000') return -1;
    if (b.id === '000000000000000000000000') return 1;
    return 0;
  }

  // We want to display the confirmed invitations first in the invitations queue
  static sortInvitations(a, b) {
    if (a.confirmed && !b.confirmed) return -1;
    /* Returns -1 because invitation avatars are displayed one by one so the 1st invitation
      in the array is displayed first thus it appears at the end of the queue on the screen. */
    if (!a.confirmed && b.confirmed) return 1;
    return 0;
  }

  static sortInclusionsByProgression(a, b, ascending = true) {
    if (ascending) {
      if (a.progression < b.progression) return -1;
      if (a.progression > b.progression) return 1;
      return 0;
    }
    if (a.progression > b.progression) return -1;
    if (a.progression < b.progression) return 1;
    return 0;
  }

  static sortInclusionsByInconsistentData(a, b, ascending = false) {
    if (ascending) {
      if (a.inconsistent_data_count < b.inconsistent_data_count) return -1;
      if (a.inconsistent_data_count > b.inconsistent_data_count) return 1;
      return 0;
    }
    if (a.inconsistent_data_count > b.inconsistent_data_count) return -1;
    if (a.inconsistent_data_count < b.inconsistent_data_count) return 1;
    return 0;
  }

  static sortById(a, b) {
    if (a.id < b.id) return -1;
    if (a.id > b.id) return 1;
    return 0;
  }

  static sortInclusionsByDate(a, b) {
    if (moment(a.updated_at).isBefore(moment(b.updated_at))) {
      return 1;
    }
    return -1;
  }

  static sortByCreationDate(a, b) {
    if (moment(a.created_at).isBefore(moment(b.created_at))) {
      return 1;
    }
    return -1;
  }

  static sortInclusionsByPerProjectId(a, b, ascending = true) {
    if (ascending) {
      if (a.per_project_id < b.per_project_id) return -1;
      if (a.per_project_id > b.per_project_id) return 1;
      return 0;
    }
    if (a.per_project_id > b.per_project_id) return -1;
    if (a.per_project_id < b.per_project_id) return 1;
    return 0;
  }

  static sortInclusionsByQualifiedMissingData(a, b, ascending = true) {
    if (ascending) {
      if (a.qualified_missing_data_count < b.qualified_missing_data_count) return -1;
      if (a.qualified_missing_data_count > b.qualified_missing_data_count) return 1;
      return 0;
    }
    if (a.qualified_missing_data_count > b.qualified_missing_data_count) return -1;
    if (a.qualified_missing_data_count < b.qualified_missing_data_count) return 1;
    return 0;
  }

  static sortTriggerVariables(a, b) {
    if (a.tab_sorting < b.tab_sorting) return -1;
    if (a.tab_sorting > b.tab_sorting) return 1;
    let aSorting = a.sorting;
    let bSorting = b.sorting;
    // One is in a module and the other isn't
    if (a.module_sorting !== b.module_sorting) {
      aSorting = a.module_sorting || a.sorting;
      bSorting = b.module_sorting || b.sorting;
    }
    if (aSorting < bSorting) return -1;
    if (aSorting > bSorting) return 1;
    return 0;
  }

  static sortModalities(a, b) {
    return alphaNumericSort(a.label, b.label);
  }
}
