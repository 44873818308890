import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import React from 'react';
import NewTooltip from './NewTooltip';
import { childrenPropTypes } from '../utils/generic-prop-types';

const Help = (props) => (
  <NewTooltip
    content={props.children}
    contentClassName={props.className}
    containerClassName={props.tooltipContainerClassName}
    interactive={props.interactive}
    placement={props.tooltipPlacement}
    theme={props.tooltipTheme}
  >
    <FontAwesomeIcon
      icon={props.icon}
      className={`${props.iconClassName} ${props.iconColor}`}
      transform={props.transform}
    />
  </NewTooltip>
);

Help.propTypes = {
  className: PropTypes.string,
  icon: PropTypes.arrayOf(PropTypes.string),
  iconClassName: PropTypes.string,
  iconColor: PropTypes.string,
  children: childrenPropTypes([PropTypes.string]).isRequired,
  transform: PropTypes.string,
  interactive: PropTypes.bool,
  tooltipContainerClassName: PropTypes.string,
  tooltipPlacement: PropTypes.string,
  tooltipTheme: PropTypes.string,
};

Help.defaultProps = {
  className: '',
  icon: ['fal', 'question-circle'],
  iconClassName: '',
  iconColor: 'text-muted',
  transform: undefined,
  interactive: false,
  tooltipContainerClassName: '',
  tooltipPlacement: 'right',
  tooltipTheme: 'light',
};

export default Help;
