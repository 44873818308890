import { createReducer } from 'redux-starter-kit';
import { usersActions } from '../actions';
import getStdDbModelReduxMapping from './std-db-model-reducers';

export const usersInitialState = {};

const usersReducer = createReducer(
  usersInitialState,
  getStdDbModelReduxMapping(usersActions),
);

export default usersReducer;
