import { Component } from 'react';
import { isTablet, isBrowser } from 'react-device-detect';
import { childrenPropTypes } from '../utils/generic-prop-types';
import { isBigTablet } from '../utils/viewport';

export const isTabletView = () => isTablet && !isBrowser && !isBigTablet();

class TabletView extends Component {
  render() {
    return isTabletView() ? this.props.children : null;
  }
}

TabletView.propTypes = {
  children: childrenPropTypes().isRequired,
};

export default TabletView;
