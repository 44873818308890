/**
 * Compare 2 sets for shallow equality
 * @param {Set} a
 * @param {Set} b
 */
export const areSetsEqual = (a, b) => a.size === b.size && [...a].every((value) => b.has(value));

/**
 * Return the relative complement of b in a (a - b)
 * @param {Set} a
 * @param {Set} b
 */
export const getSetRelativeComplement = (a, b) => {
  const result = new Set();
  a.forEach((item) => { if (!b.has(item)) result.add(item); });
  return result;
};
