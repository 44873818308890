import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Trans, withTranslation } from 'react-i18next';
import '../assets/css/animation.css';
import { nsOptions } from '../i18n';
import NewTooltip from './NewTooltip';
import DropdownMenu from './NewDropdownMenu';
import { childrenPropTypes } from '../utils/generic-prop-types';
import { isMobileView } from './MobileView';
import {
  DATE_TIME, DATE_FULL, DATE_MONTH_YEAR, DATE_YEAR, ELEMENT_TYPE_CALCULATION, ELEMENT_TYPE_DATE,
  ELEMENT_TYPE_DIVIDER, ELEMENT_TYPE_INFORMATION, ELEMENT_TYPE_MEASUREMENT, ELEMENT_TYPE_MODULE,
  ELEMENT_TYPE_MULTIPLE_CHOICES, ELEMENT_TYPE_SPACER, ELEMENT_TYPE_TEXT, ELEMENT_TYPE_TIME,
  ELEMENT_TYPE_TITLE, ELEMENT_TYPE_UNIQUE_CHOICE, ELEMENT_INDIRECT_IDENTIFIER,
} from '../constants';
import { MessageModal } from './MessageModal';
import Help from './Help';
import { UpdatedBadge } from './Badges';


@withTranslation('', nsOptions)
class AddElementButton extends React.Component {
  static propTypes = {
    data: PropTypes.shape().isRequired,
    children: PropTypes.element,
    addElement: PropTypes.func.isRequired,
    iconName: PropTypes.string,
    iconClassName: PropTypes.string,
    iconTransform: PropTypes.string,
    // FIXME: onClick is set dynamically by NewDropdownMenu.
    onClick: PropTypes.func,
    t: PropTypes.func.isRequired,
    i18n: PropTypes.shape().isRequired,
    style: PropTypes.string,
  };

  static defaultProps = {
    children: null,
    iconName: null,
    iconClassName: '',
    iconTransform: null,
    onClick: null,
    style: undefined,
  };

  render() {
    const {
      data, t, i18n, addElement, iconName, iconClassName, iconTransform, onClick, style,
    } = this.props;
    let { children } = this.props;
    const helpKey = `project:form.${data.type}-tooltip-help`;
    if (iconName) {
      children = (
        <FontAwesomeIcon
          icon={['far', iconName]}
          className={iconClassName}
          style={style}
          transform={iconTransform}
        />
      );
    }
    return (
      <NewTooltip
        content={(
          <div>
            <div>
              {t(`project:form.${data.type}-tooltip`)}
            </div>
            {i18n.translator.exists(helpKey) && (
              <div>
                <small>
                  <i>
                    {t(helpKey)}
                  </i>
                </small>
              </div>
            )}
          </div>
        )}
        distance={-10}
      >
        <div
          className="element-add-item"
          role="button"
          tabIndex="-1"
          onClick={onClick || (() => addElement(data))}
          onKeyPress={() => {}}
        >
          {children}
        </div>
      </NewTooltip>
    );
  }
}

const TitleItem = (props) => (
  <div className="px-4 text-nowrap add-element-choices-title">
    {props.children}
  </div>
);

TitleItem.propTypes = {
  children: childrenPropTypes().isRequired,
};


const GroupItem = (props) => (
  <div className="px-4">
    <div
      className="text-nowrap"
      style={{ padding: '0.25rem 0' }}
    >
      {props.label}
    </div>
    {props.children}
  </div>
);

GroupItem.propTypes = {
  label: PropTypes.string.isRequired,
  children: childrenPropTypes().isRequired,
};


@withTranslation('', nsOptions)
class ChoiceItem extends React.Component {
  static propTypes = {
    data: PropTypes.shape().isRequired,
    tooltip: PropTypes.string,
    warning: childrenPropTypes(),
    showWarningModal: PropTypes.bool,
    addElement: PropTypes.func.isRequired,
    getDropDownRef: PropTypes.func.isRequired,
    children: childrenPropTypes().isRequired,
    t: PropTypes.func.isRequired,
  };

  static defaultProps = {
    tooltip: null,
    warning: undefined,
    showWarningModal: false,
  };

  render() {
    const {
      data, tooltip, addElement, getDropDownRef, children, t, warning, showWarningModal,
    } = this.props;
    const onClick = () => {
      const dropDownRef = getDropDownRef();
      if (dropDownRef) dropDownRef.hide();
      addElement(data);
    };
    const item = (
      <NewTooltip
        content={tooltip && <small><i>{t(tooltip)}</i></small>}
      >
        <span className="dropdown-item add-element-item">
          <a
            role="link"
            onClick={showWarningModal && warning ? undefined : onClick}
            tabIndex={0}
            onKeyPress={() => {}}
          >
            {children}
          </a>
          {warning && (
            <NewTooltip
              content={warning}
              theme="light"
            >
              <FontAwesomeIcon
                icon={['fas', 'exclamation-triangle']}
                transform="grow-2"
                className="ml-2 text-red identifying-warning-icon"
              />
            </NewTooltip>
          )}
        </span>
      </NewTooltip>
    );

    return showWarningModal && warning ? (
      <MessageModal
        message={(
          <div>
            <div className="text-center">
              <b>
                {t('common:caution')}
              </b>
            </div>
            <br />
            <br />
            <p>
              <Trans i18nKey="error:warning.identifying-date" />
            </p>
          </div>
        )}
        validateLabel="common:button.confirm-action"
        validateBtnBgColorClass="btn-newred-2"
        showCancelButton
        onValidate={onClick}
      >
        {item}
      </MessageModal>
    ) : item;
  }
}


@withTranslation('', nsOptions)
class ElementAddBox extends Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
    addElement: PropTypes.func.isRequired,
    noModule: PropTypes.bool,
    i18n: PropTypes.shape().isRequired,
  };

  static defaultProps = {
    noModule: false,
  };

  constructor(props) {
    super(props);
    this.choicesDropDownMenuRef = null;
    this.patientDropDownMenuRef = null;
    this.implDropDownMenuRef = null;
    this.titleDropDownMenuRef = null;
  }

  renderPatientIdentifierData = () => {
    const { addElement, t } = this.props;
    return (

      <DropdownMenu
        type={isMobileView() ? 'dropleft' : 'dropup'}
        mainClass="d-inline-block"
        triggerElement={(
          <AddElementButton
            data={{ type: 'predefined' }}
            addElement={addElement}
            iconName="user-shield"
            iconClassName="text-newblue-1"
          />
          )}
        ref={(ref) => { this.patientDropDownMenuRef = ref; }}
      >
        <TitleItem>
          <>
            <span>
              {t('project:form.add-element-identifier-title')}
            </span>
            <Help iconClassName="ml-2">
              <Trans i18nKey="error:warning.identifying-element" />
            </Help>
          </>
        </TitleItem>
        <GroupItem label={t('project:form.add-birth-date-group')}>
          <ChoiceItem
            data={{
              type: ELEMENT_TYPE_DATE,
              format: DATE_YEAR,
              template: 'birth_date',
              identification_level: ELEMENT_INDIRECT_IDENTIFIER,
            }}
            getDropDownRef={() => this.patientDropDownMenuRef}
            addElement={addElement}
          >
            {t('project:form.add-birth-year')}
          </ChoiceItem>
          <ChoiceItem
            data={{
              type: ELEMENT_TYPE_DATE,
              format: DATE_MONTH_YEAR,
              template: 'birth_date',
              identification_level: ELEMENT_INDIRECT_IDENTIFIER,
            }}
            getDropDownRef={() => this.patientDropDownMenuRef}
            addElement={addElement}
          >
            {t('project:form.add-birth-month-year')}
          </ChoiceItem>
          <ChoiceItem
            data={{
              type: ELEMENT_TYPE_DATE,
              format: DATE_FULL,
              template: 'birth_date',
              identification_level: ELEMENT_INDIRECT_IDENTIFIER,
            }}
            getDropDownRef={() => this.patientDropDownMenuRef}
            addElement={addElement}
            warning={<Trans i18nKey="error:warning.identifying-date" />}
            showWarningModal
          >
            {t('project:form.add-birth-date')}
          </ChoiceItem>
        </GroupItem>
        <ChoiceItem
          data={{
            type: ELEMENT_TYPE_TEXT,
            template: 'id_code',
            identification_level: ELEMENT_INDIRECT_IDENTIFIER,
          }}
          getDropDownRef={() => this.patientDropDownMenuRef}
          addElement={addElement}
          warning={<Trans i18nKey="error:warning.identification-number" />}
        >
          {t('project:form.add-id-number')}
        </ChoiceItem>
      </DropdownMenu>
    );
  }

  render() {
    const {
      t, addElement, noModule, i18n,
    } = this.props;
    return (
      <div className="row">
        <div className="col">
          <div className="element-add-row">
            <span className="element-add-row-title">
              {t('project:form.add-box-help')}
            </span>
            <div className="element-add-bloc-common element-add-bloc-entries">
              {this.renderPatientIdentifierData()}
              <DropdownMenu
                type={isMobileView() ? 'dropleft' : 'dropup'}
                mainClass="d-inline-block"
                triggerElement={(
                  <AddElementButton
                    data={{ type: ELEMENT_TYPE_MULTIPLE_CHOICES }}
                    iconName="check-square"
                    iconClassName="text-newblue-1"
                    addElement={addElement}
                  />
                )}
                ref={(ref) => { this.choicesDropDownMenuRef = ref; }}
              >
                <TitleItem>
                  {t('project:form.add-element-choices-title')}
                </TitleItem>
                <ChoiceItem
                  data={{ type: ELEMENT_TYPE_UNIQUE_CHOICE }}
                  tooltip="project:form.add-unique-choice-tooltip"
                  getDropDownRef={() => this.choicesDropDownMenuRef}
                  addElement={addElement}
                >
                  {t('project:form.add-unique-choice')}
                </ChoiceItem>
                <ChoiceItem
                  data={{ type: ELEMENT_TYPE_MULTIPLE_CHOICES }}
                  tooltip="project:form.add-multiple-choices-tooltip"
                  getDropDownRef={() => this.choicesDropDownMenuRef}
                  addElement={addElement}
                >
                  {t('project:form.add-multiple-choices')}
                </ChoiceItem>
              </DropdownMenu>
              <DropdownMenu
                type={isMobileView() ? 'dropleft' : 'dropup'}
                mainClass="d-inline-block"
                triggerElement={(
                  <AddElementButton
                    data={{ type: 'implementablechoices' }}
                    addElement={addElement}
                  >
                    <span className="fa-layers fa-fw">
                      <FontAwesomeIcon
                        icon={['far', 'list-alt']}
                        transform="shrink-2 down-1"
                        className="text-newblue-1"
                      />
                      <FontAwesomeIcon
                        icon="plus"
                        transform="shrink-9 right-10 up-6"
                        className="text-newblue-1"
                      />
                    </span>
                  </AddElementButton>
                )}
                ref={(ref) => { this.implDropDownMenuRef = ref; }}
              >
                <TitleItem>
                  {t('project:form.add-element-impl-choices-title')}
                </TitleItem>
                <ChoiceItem
                  data={{ type: ELEMENT_TYPE_UNIQUE_CHOICE, implementable: true }}
                  tooltip="project:form.add-implementable-unique-choice-tooltip"
                  getDropDownRef={() => this.implDropDownMenuRef}
                  addElement={addElement}
                >
                  {t('project:form.add-implementable-unique-choice')}
                </ChoiceItem>
                <ChoiceItem
                  data={{ type: ELEMENT_TYPE_MULTIPLE_CHOICES, implementable: true }}
                  tooltip="project:form.add-implementable-multiple-choices-tooltip"
                  getDropDownRef={() => this.implDropDownMenuRef}
                  addElement={addElement}
                >
                  {t('project:form.add-implementable-multiple-choices')}
                </ChoiceItem>
              </DropdownMenu>
              <UpdatedBadge className={`date-badge-${i18n.language}`}>
                <DropdownMenu
                  type={isMobileView() ? 'dropleft' : 'dropup'}
                  mainClass="d-inline-block"
                  triggerElement={(
                    <AddElementButton
                      data={{ type: ELEMENT_TYPE_DATE }}
                      addElement={addElement}
                    >
                      <span className="fa-layers fa-fw">
                        <FontAwesomeIcon
                          icon={['far', 'calendar-alt']}
                          transform="shrink-2 down-1"
                          className="text-newblue-1"
                        />
                        <FontAwesomeIcon
                          icon={['fas', 'clock']}
                          transform="shrink-7 right-10 up-6"
                          className="text-newblue-1"
                        />
                      </span>
                    </AddElementButton>
                  )}
                  ref={(ref) => { this.dateDropDownMenuRef = ref; }}
                >
                  <TitleItem>
                    {t('project:form.add-element-date-time-title')}
                  </TitleItem>
                  <ChoiceItem
                    data={{ type: ELEMENT_TYPE_DATE, format: DATE_YEAR }}
                    getDropDownRef={() => this.dateDropDownMenuRef}
                    addElement={addElement}
                  >
                    {t('project:form.add-date-year')}
                  </ChoiceItem>
                  <ChoiceItem
                    data={{ type: ELEMENT_TYPE_DATE, format: DATE_MONTH_YEAR }}
                    getDropDownRef={() => this.dateDropDownMenuRef}
                    addElement={addElement}
                  >
                    {t('project:form.add-date-month-year')}
                  </ChoiceItem>
                  <ChoiceItem
                    data={{ type: ELEMENT_TYPE_DATE, format: DATE_FULL }}
                    getDropDownRef={() => this.dateDropDownMenuRef}
                    addElement={addElement}
                  >
                    {t('project:form.add-date')}
                  </ChoiceItem>
                  <ChoiceItem
                    data={{ type: ELEMENT_TYPE_DATE, format: DATE_TIME }}
                    getDropDownRef={() => this.dateDropDownMenuRef}
                    addElement={addElement}
                  >
                    {t('project:form.add-date-time')}
                  </ChoiceItem>
                  <ChoiceItem
                    data={{ type: ELEMENT_TYPE_TIME }}
                    getDropDownRef={() => this.dateDropDownMenuRef}
                    addElement={addElement}
                  >
                    {t('project:form.add-time')}
                  </ChoiceItem>
                </DropdownMenu>
              </UpdatedBadge>
              <AddElementButton
                data={{ type: ELEMENT_TYPE_MEASUREMENT }}
                iconName="prescription-bottle"
                iconClassName="text-newblue-1"
                addElement={addElement}
              />
              <UpdatedBadge className={`calculation-badge-${i18n.language}`}>
                <AddElementButton
                  data={{ type: ELEMENT_TYPE_CALCULATION }}
                  iconName="calculator-alt"
                  iconClassName="text-newblue-1"
                  addElement={addElement}
                />
              </UpdatedBadge>
              {!noModule && (
                <AddElementButton
                  data={{ type: ELEMENT_TYPE_MODULE }}
                  iconName="sitemap"
                  iconClassName="text-newblue-1"
                  addElement={addElement}
                />
              )}
              <AddElementButton
                data={{ type: ELEMENT_TYPE_TEXT }}
                iconName="edit"
                iconClassName="text-newblue-1"
                addElement={addElement}
              />
            </div>
            <div className="element-spacer-bloc" />
            <div className="element-add-bloc-common element-add-bloc-edition">
              <AddElementButton
                data={{ type: ELEMENT_TYPE_INFORMATION }}
                addElement={addElement}
              >
                <div className="element-toolbar-info">
                  <div className="element-toolbar-info-icon">
                    i
                  </div>
                </div>
              </AddElementButton>
            </div>
            <div className="element-spacer-bloc" />
            <div className="element-add-bloc-common element-add-bloc-static">
              <DropdownMenu
                type={isMobileView() ? 'dropleft' : 'dropup'}
                mainClass="d-inline-block"
                triggerElement={(
                  <AddElementButton
                    data={{ type: ELEMENT_TYPE_TITLE }}
                    iconName={i18n.language === 'en' ? 'heading' : 'text'}
                    iconClassName="text-newturquoise-1"
                    addElement={addElement}
                  />
                )}
                ref={(ref) => { this.titleDropDownMenuRef = ref; }}
              >
                <TitleItem>
                  {t('project:form.add-element-title-title')}
                </TitleItem>
                <ChoiceItem
                  data={{ type: ELEMENT_TYPE_TITLE, level: 1 }}
                  getDropDownRef={() => this.titleDropDownMenuRef}
                  addElement={addElement}
                >
                  {t('project:form.add-title-level-1')}
                </ChoiceItem>
                <ChoiceItem
                  data={{ type: ELEMENT_TYPE_TITLE, level: 2 }}
                  getDropDownRef={() => this.titleDropDownMenuRef}
                  addElement={addElement}
                >
                  {t('project:form.add-title-level-2')}
                </ChoiceItem>
              </DropdownMenu>
              <AddElementButton
                data={{ type: ELEMENT_TYPE_DIVIDER }}
                iconName="minus"
                iconClassName="text-newturquoise-1"
                addElement={addElement}
              />
              <AddElementButton
                data={{ type: ELEMENT_TYPE_SPACER }}
                iconName="level-down-alt"
                iconClassName="text-newturquoise-1"
                iconTransform="rotate-90"
                addElement={addElement}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}


export default ElementAddBox;
