import he from 'he';
import Toast from './Toast';
import ErrorUtil from './ErrorUtil';
import { TRUNCATED_TEXT_SUFFIX } from '../constants';

export default class TextUtil {
  /**
   *
   * @param {any} text
   * @param {any} search
   * @returns
   */
  static localeContains(text, search) {
    // Inspired by https://stackoverflow.com/questions/39548303/javascript-string-prototype-contains-with-locale
    if (search === '') return true;
    if (!search || !text || !text.length) return false;
    if (search.length > text.length) return false;
    const ascii = (s) => s.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase();
    return ascii(text).includes(ascii(search));
  }

  static escape(text) {
    return he.escape(text);
  }

  static parseLinks(html) {
    return html ? html.replace(/<a href="javascript:(.+?)">/gi, '<a href="javascript:void(0);">')
      .replace(/<a href="(.+?)">/gi, '<a href="$1" target="_blank">') : null;
  }

  static truncate(text, length, suffix = TRUNCATED_TEXT_SUFFIX) {
    if (text.length <= length) return text;
    return `${text.substr(0, length - suffix.length)}${suffix}`;
  }

  /**
   * @param {Object} props
   * @param {Promise} request
   * @param {function} formatResponse
   */
  static async asyncCopyTextToClipboard(props, request, formatResponse) {
    if (typeof window.ClipboardItem !== 'undefined' && navigator.clipboard.write) {
      // Safari locks down the clipboard API to only work when triggered by a direct user
      // interaction. You can't use it async in a promise. But you can wrap the promise in
      // a ClipboardItem, and give that to the clipboard API.
      // Found this on https://wolfgangrittner.dev/how-to-use-clipboard-api-in-firefox/
      try {
        const text = new window.ClipboardItem({
          'text/plain': request
            .then((response) => formatResponse(response))
            .then((textResponse) => new Blob([textResponse], { type: 'text/plain' }))
            .catch((error) => ErrorUtil.handleCatched(props, error)),
        });
        await navigator.clipboard.write([text]);
        Toast.success(props, 'error:valid.success');
      } catch (error) {
        console.log(error);
      }
    } else {
      // Firefox has support for ClipboardItem and navigator.clipboard.write,
      // but those are behind `dom.events.asyncClipboard.clipboardItem` preference.
      // Good news is that other than Safari, Firefox does not care about Clipboard API
      // being used async in a Promise.
      request
        .then((response) => formatResponse(response))
        .then((textResponse) => navigator.clipboard.writeText(textResponse))
        .then(() => Toast.success(props, 'error:valid.success'))
        .catch((error) => ErrorUtil.handleCatched(props, error));
    }
  }
}
