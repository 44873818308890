import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { withToastManager } from 'react-toast-notifications';
import {
  projectResourcesActions, resourceTopicsActions,
} from '../redux/actions';
import { nsOptions } from '../i18n';
import IconConfirm from './IconConfirm';
import NewTooltip from './NewTooltip';
import ResourceManager from './ResourceManager';
import ErrorUtil from '../utils/ErrorUtil';
import Toast from '../utils/Toast';
import fromReduxState from '../utils/redux';


const mapStateToProps = (state) => ({
  projectResources: state.projectResources,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  fetchProjectResources: async (projectId) => dispatch(projectResourcesActions.list(
    { project: projectId, admin: ownProps.admin }, { pagination: 'no' },
  )),
  fetchResourceTopics: async (resourceIds) => dispatch(resourceTopicsActions.list(
    { resource__in: resourceIds, admin: ownProps.admin }, { pagination: 'no' },
  )),
  removeProjectResource: async (resourceId) => dispatch(projectResourcesActions.remove(resourceId, {
    admin: ownProps.admin,
  })),
});


@withToastManager
@connect(mapStateToProps, mapDispatchToProps)
@withTranslation('', nsOptions)
class ResourceList extends Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
    project: PropTypes.shape().isRequired,
    projectResources: PropTypes.shape().isRequired,
    saveResources: PropTypes.func.isRequired,
    canEdit: PropTypes.bool,
    fetchProjectResources: PropTypes.func.isRequired,
    fetchResourceTopics: PropTypes.func.isRequired,
    removeProjectResource: PropTypes.func.isRequired,
  };

  static defaultProps = {
    canEdit: false,
  };

  constructor(props) {
    super(props);
    this.state = { loading: false };
    this.resourceManager = null;
    this.toPrjResourcesArray = fromReduxState((projectResources, projectId) => (
      Object.values(projectResources).filter((projectResource) => (
        projectResource.project === projectId
      ))
    ));
  }

  componentDidMount() {
    this.loadProjectResources();
  }

  async loadProjectResources() {
    const { project, fetchProjectResources, fetchResourceTopics } = this.props;
    const { resources } = project;

    if (resources.length) {
      this.setState({ loading: true });

      try {
        await Promise.all([
          fetchProjectResources(project.id),
          fetchResourceTopics(resources),
        ]);
      } catch (error) {
        ErrorUtil.handleCatched(this.props, error, false);
      } finally {
        this.setState({ loading: false });
      }
    }
  }

  async editProjectResource(id) {
    try {
      await this.resourceManager.setProjectResource(id);
      this.resourceManager.show();
    } catch (error) {
      ErrorUtil.handleCatched(this.props, error, false);
    }
  }

  async removeProjectResource(id) {
    if (!id) return;

    this.setState({ loading: true });

    const { removeProjectResource } = this.props;

    try {
      await removeProjectResource(id);
      Toast.success(this.props, 'error:valid.saved');
    } catch (error) {
      ErrorUtil.handleCatched(this.props, error);
    }

    this.setState({ loading: false });
  }

  render() {
    const {
      t, canEdit, projectResources: prjResources, project,
    } = this.props;
    const projectResources = this.toPrjResourcesArray(prjResources, project.id);

    return (
      <div>
        <div>
          {
            canEdit && (
              <ResourceManager
                {...this.props}
                loading={this.state.loading}
                ref={(ref) => { this.resourceManager = ref; }}
              />
            )
          }
          <div className="resource-list">
            {
              projectResources.length > 0
                ? projectResources.map((projectResource) => (
                  <div key={projectResource.id} className="resource-item">
                    <div className="row justify-content-between align-items-center">
                      <div className="col">
                        <a
                          href={projectResource.external_url}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="resource-name"
                        >
                          {projectResource.name}
                        </a>
                        {
                          projectResource.external_url ? (
                            <a
                              className="mt-1 resource-url"
                              href={projectResource.external_url}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <div className="resource-url-div">
                                <img
                                  src={`https://s2.googleusercontent.com/s2/favicons?domain_url=${projectResource.external_url.replace(/^http(s?):\/\/(.*?)\/(.*)$/gi, 'http$1://$2')}`}
                                  width="16" // TODO: Store favicon in DB
                                  height="16"
                                  alt=""
                                  className="mr-1"
                                />
                                {projectResource.external_url}
                              </div>
                            </a>
                          ) : null
                        }
                      </div>
                      <div className="col">
                        {
                          projectResource.topics.length > 0 ? (
                            <div>
                              <div className="font-weight-semibold">
                                {t('project:resources.topics')}
                              </div>
                              <div className="resource-topics mb-1">
                                {
                                  projectResource.topics
                                    .map((topic) => (
                                      <div
                                        key={topic}
                                        className="badge badge-topic text-white mr-1 mt-1"
                                      >
                                        {t(`topic:${topic}`)}
                                      </div>
                                    ))
                                }
                              </div>
                            </div>
                          ) : null
                        }
                      </div>
                      {
                        canEdit && (
                          <div className="col-auto">
                            <NewTooltip content={t('project:button.edit-resource')}>
                              <button
                                className="no-button-style text-gray-light mr-2"
                                disabled={this.state.loading}
                                onClick={() => this.editProjectResource(projectResource.id)}
                              >
                                <FontAwesomeIcon
                                  icon="pen"
                                  transform="grow-3"
                                />
                              </button>
                            </NewTooltip>
                            <IconConfirm
                              tooltipContent={t('project:button.delete-resource')}
                              onClick={() => this.removeProjectResource(projectResource.id)}
                            >
                              <button
                                className="no-button-style text-gray-light mr-2"
                                disabled={this.state.loading}
                              >
                                <FontAwesomeIcon
                                  icon="trash-alt"
                                  transform="grow-5"
                                />
                              </button>
                            </IconConfirm>
                          </div>
                        )
                      }
                    </div>
                  </div>
                ))
                : (
                  <div className="no-resource-item">
                    {t('project:no.resource')}
                  </div>
                )
            }
          </div>
        </div>
      </div>
    );
  }
}

export default ResourceList;
