import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import api from '../api';
import { childrenPropTypes } from '../utils/generic-prop-types';
import NewModal from './NewModal';
import { MiniLoader } from './Loader';
import useError from '../utils/HookErrorUtil';
import useToast from '../utils/HookToast';
import downloadEndpoint from '../utils/downloadEndpoint';
import FileUtil from '../utils/FileUtil';
import history from '../history';

function MaxSizeReachedMessage() {
  return (
    <span className="text-red">
      Max size reached for a file (10 MB)
    </span>
  );
}

function MaxFilesReachedMessage() {
  return (
    <span className="text-red">
      Too much files selected for modules (limited to 10 files)
    </span>
  );
}

function FileInput(props) {
  const {
    inputId, label, accept, onChange, multiple, disabled,
  } = props;

  return (
    <div className="py-2">
      <label
        htmlFor={inputId}
        className="mr-3"
      >
        {label}
      </label>
      <input
        id={inputId}
        type="file"
        accept={accept}
        onChange={onChange}
        multiple={multiple}
        disabled={disabled}
      />
    </div>
  );
}

FileInput.propTypes = {
  inputId: PropTypes.string.isRequired,
  label: PropTypes.oneOfType([PropTypes.string, childrenPropTypes()]).isRequired,
  accept: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  multiple: PropTypes.bool,
  disabled: PropTypes.bool,
};

FileInput.defaultProps = {
  onChange: () => {},
  multiple: false,
  disabled: false,
};

function CsvImportManager(props) {
  const { admin, children } = props;
  const modalRef = useRef(null);
  const { handleCatched } = useError();
  const { success } = useToast();
  const { t } = useTranslation();

  const nullFiles = { pages: null, modules: null, config: null };

  const [generateConfigFile, setGenerateConfigFile] = useState(false);
  const [files, setFiles] = useState(nullFiles);
  const [loading, setLoading] = useState(false);
  const [maxSizeReached, setMaxSizeReached] = useState(false);
  const [maxFilesReached, setMaxFilesReached] = useState(false);

  const validate = (csvFiles = [], jsonFiles = []) => {
    if (!Array.isArray(csvFiles) || !Array.isArray(jsonFiles)) return false;
    if (!csvFiles.reduce((prevValue, csvFile) => prevValue && FileUtil.isCsvFile(csvFile), true)) return [false, 'csv'];
    if (!jsonFiles.reduce((prevValue, jsonFile) => prevValue && FileUtil.isJsonFile(jsonFile), true)) return [false, 'json'];
    return [true, ''];
  };

  const resetForm = (id) => {
    const form = document.getElementById(id);
    if (form) form.reset();
  };

  const resetForms = () => {
    resetForm('import-data-form');
    resetForm('generate-config-file-form');
  };

  const addFilesToFormData = (formData, key, newFiles) => {
    if (newFiles && Array.isArray(newFiles)) {
      newFiles.forEach((newFile) => formData.append(key, newFile));
    }
  };

  const onGenerateConfigFile = async () => {
    const pages = files.pages || [];
    const modules = files.modules || [];

    if (pages.length) {
      try {
        setLoading(true);

        // Validate
        const [validFile, code] = validate([...pages, ...modules]);
        if (!validFile) throw new Error(t(`error:error.invalid-${code}-upload`));

        // Form data
        const formData = new FormData();

        // Add page csv files to the form data
        addFilesToFormData(formData, 'page_csv_files', pages);

        // Add module csv files to the form data
        addFilesToFormData(formData, 'module_csv_files', modules);

        // Generate and download config file
        await downloadEndpoint(
          'generate-csv-import-config-file',
          'post',
          { admin },
          formData,
        );
        setGenerateConfigFile(false);
        success('error:valid.success');
      } catch (error) {
        handleCatched(props, error);
        setFiles(nullFiles);
        resetForm('generate-config-file-form');
      } finally {
        setLoading(false);
      }
    }
  };

  const onCsvImport = async () => {
    const pages = files.pages || [];
    const { config } = files;
    const modules = files.modules || [];

    if (pages.length && config) {
      try {
        setLoading(true);

        // Validate
        const [validFiles, code] = validate([...pages, ...modules], [files.config]);
        if (!validFiles) throw new Error(t(`error:error.invalid-${code}-upload`));

        // Form data
        const formData = new FormData();

        // Add page csv files to the form data
        addFilesToFormData(formData, 'page_csv_files', pages);

        // Add module csv files to the form data
        addFilesToFormData(formData, 'module_csv_files', modules);

        // Add config csv file to form data
        formData.append('json_config_file', config);

        // Import Data
        const res = await api.create('import-data-from-csv', formData, { admin });
        success('error:valid.success');

        // Redirection
        const baseUrl = `${admin ? '/admin' : '/dashboard'}/project`;
        history.push(`${baseUrl}/${res.project_id}/details`);
      } catch (error) {
        handleCatched(props, error);
        setFiles(nullFiles);
        resetForm('import-data-form');
      } finally {
        setLoading(false);
      }
    }
  };

  const hide = async () => {
    setFiles(nullFiles);
    setGenerateConfigFile(false);
    setMaxFilesReached(false);
    setMaxSizeReached(false);
    if (modalRef.current) modalRef.current.hide();
  };

  const onFileInputChange = (e, saveFiles = () => {}, maxFiles = undefined) => {
    const newFiles = Object.values(e.target.files);
    if (maxFiles !== undefined && newFiles.length > maxFiles) {
      setMaxFilesReached(true);
    }
    if (newFiles.length) {
      const correctFileSizes = newFiles.reduce((prevValue, file) => {
        const hasValidSize = FileUtil.checkFileSize(file);
        return prevValue && hasValidSize;
      }, true);
      setMaxSizeReached(!correctFileSizes);
    }
    saveFiles();
  };

  const requestsDisabled = !admin || loading || maxSizeReached || maxFilesReached;

  return (
    <NewModal
      trigger={children}
      title="Import a CSV"
      ref={(ref) => { modalRef.current = ref; }}
      onClosed={hide}
      extraClass="csv-import-modal"
    >
      <div>
        <button
          onClick={() => {
            setGenerateConfigFile(!generateConfigFile);
            setFiles(nullFiles);
            resetForms();
          }}
          className="btn btn-primary"
          disabled={!admin || loading}
        >
          Generate a configuration file
        </button>
        {generateConfigFile && (
          <div className="pt-3">
            <form
              id="generate-config-file-form"
            >
              <FileInput
                inputId="pages-csv-input"
                label="Select the CSV files of the form pages"
                accept=".csv"
                onChange={(e) => onFileInputChange(
                  e, () => setFiles({ ...files, pages: Object.values(e.target.files) }), 10,
                )}
                multiple
              />
              <FileInput
                inputId="module-csv-files-input"
                label={(
                  <span>
                    Select the module CSV files
                    &nbsp;
                    <small className="font-italic">
                      (optional)
                    </small>
                  </span>
                )}
                accept=".csv"
                multiple
                onChange={(e) => onFileInputChange(
                  e, () => setFiles({ ...files, modules: Object.values(e.target.files) }), 10,
                )}
              />
            </form>
            {files.pages && files.pages.length && (
              <span>
                <button
                  className="btn btn-primary mr-2"
                  onClick={onGenerateConfigFile}
                  disabled={requestsDisabled}
                >
                  Generate
                </button>
                {maxSizeReached && (
                  <MaxSizeReachedMessage />
                )}
                {maxFilesReached && (
                  <MaxFilesReachedMessage />
                )}
                {loading && <MiniLoader />}
              </span>
            )}
          </div>
        )}
      </div>
      <hr />
      <div>
        <form
          id="import-data-form"
        >
          <FileInput
            inputId="pages-csv-import-input"
            label="Select the CSV files of the form pages"
            accept=".csv"
            onChange={(e) => onFileInputChange(
              e, () => setFiles({ ...files, pages: Object.values(e.target.files) }), 10,
            )}
            disabled={generateConfigFile}
            multiple
          />
          <FileInput
            inputId="module-csv-files-import-input"
            multiple
            label={(
              <span>
                Select the module CSV files
                &nbsp;
                <small className="font-italic">
                  (optional)
                </small>
              </span>
            )}
            accept=".csv"
            onChange={(e) => onFileInputChange(
              e, () => setFiles({ ...files, modules: Object.values(e.target.files) }), 10,
            )}
            disabled={generateConfigFile}
          />
          <FileInput
            inputId="config-json-file-input"
            label="Select the configuration file (JSON)"
            accept=".json,application/json"
            onChange={(e) => onFileInputChange(
              e, () => setFiles({ ...files, config: e.target.files[0] }),
            )}
            disabled={generateConfigFile}
          />
        </form>
        {files.pages && files.pages.length && files.config && (
          <span>
            <button
              className="btn btn-primary mr-2"
              onClick={onCsvImport}
              disabled={requestsDisabled || generateConfigFile}
            >
              Import
            </button>
            {maxSizeReached && (
              <MaxSizeReachedMessage />
            )}
            {maxFilesReached && (
              <MaxFilesReachedMessage />
            )}
            {loading && <MiniLoader />}
          </span>
        )}
      </div>
    </NewModal>
  );
}

CsvImportManager.propTypes = {
  admin: PropTypes.bool,
  children: childrenPropTypes().isRequired,
};

CsvImportManager.defaultProps = {
  admin: false,
};

export default CsvImportManager;
