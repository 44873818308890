import { createReducer } from 'redux-starter-kit';
import { projectPermissionsActions } from '../actions';
import getStdDbModelReduxMapping from './std-db-model-reducers';

export const projectPermissionsInitialState = {};

const projectPermissionsReducer = createReducer(
  projectPermissionsInitialState,
  getStdDbModelReduxMapping(projectPermissionsActions),
);

export default projectPermissionsReducer;
