import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { Route, Switch } from 'react-router-dom';
import { withToastManager } from 'react-toast-notifications';
import { connect } from 'react-redux';
import NotFound from './NotFound';
import Head from '../components/DashboardHead';
import DashboardMenu from '../components/DashboardMenu';
import DashboardMenuItem from '../components/DashboardMenuItem';
import Page from '../components/Page';
import DashboardProjects from '../components/DashboardProjects';
import DashboardInclusions from '../components/DashboardInclusions';
import history from '../history';
import { nsOptions } from '../i18n';
import api from '../api';
import ErrorUtil from '../utils/ErrorUtil';
import { miscActions } from '../redux/actions';
import { PROJECT_USER_USUAL } from '../constants';


const mapStateToProps = (state) => ({
  user: state.auth.authUser,
});

const mapDispatchToProps = (dispatch) => ({
  setPendingUrl: (url) => dispatch(miscActions.setPendingUrl(url)),
});

@withToastManager
@connect(mapStateToProps, mapDispatchToProps)
@withTranslation('', nsOptions)
class Dashboard extends Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
    location: PropTypes.shape().isRequired,
    match: PropTypes.shape().isRequired,
    user: PropTypes.shape().isRequired,
    setPendingUrl: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      projectsCount: undefined,
      inclusionsCount: undefined,
    };
  }

  componentDidMount() {
    const { location } = this.props;
    if (/(\/dashboard\/home)$/.test(location.pathname)) {
      history.push(`${location.pathname}/projects`);
    }
    this.fetchData();
  }

  checkPendingInvitations = async (endpoint, pendingUrl, otherParams = {}) => {
    const { setPendingUrl, user } = this.props;

    const pendingInvitations = await api.list(endpoint, {
      user: user.id,
      confirmed: false,
      ...otherParams,
    });

    if (pendingInvitations.count > 0) {
      setPendingUrl(`${pendingUrl}${pendingInvitations.results[0].confirmation_token}`);
      history.push('/');
      return true;
    }

    return false;
  }

  async fetchData() {
    try {
      if (await this.checkPendingInvitations(
        'project-users',
        '/dashboard/join-project/',
        { type: PROJECT_USER_USUAL },
      )) return;
      if (await this.checkPendingInvitations('team-members', '/dashboard/join-team/')) return;

      const { user } = this.props;
      const res = await Promise.all([
        api.count('projects'),
        api.count('inclusions', { creator: user.id, is_test: false }),
      ]);

      this.setState({ projectsCount: res[0], inclusionsCount: res[1] });
    } catch (error) {
      ErrorUtil.handleCatched(this.props, error, false);
    }
  }

  render() {
    const { t } = this.props;
    const { url } = this.props.match;
    const { projectsCount, inclusionsCount } = this.state;

    return (
      <Page
        {...this.props}
        id="dashboard"
        title={t('common:nav.dashboard')}
      >
        <Head
          {...this.props}
          title={t('common:nav.dashboard')}
        />
        <div className="dashboard-content mt-2">
          <div className="mb-3">
            <DashboardMenu>
              <DashboardMenuItem
                {...this.props}
                to={`${url}/projects`}
                icon="ballot-check"
                colorClass="projects"
                primaryContent={(
                  <>
                    { projectsCount && <span className="font-weight-semibold">{projectsCount}</span> }
                    {' '}
                    {t('common:dashboard.project', { count: projectsCount })}
                  </>
                )}
                secondaryContent={(
                  <span>
                    {t('common:dashboard.your-projects')}
                  </span>
                )}
              />
              <DashboardMenuItem
                {...this.props}
                to={`${url}/inclusions`}
                icon="user-injured"
                colorClass="inclusions"
                primaryContent={(
                  <>
                    { inclusionsCount && <span className="font-weight-semibold">{inclusionsCount}</span> }
                    {' '}
                    {t('common:dashboard.inclusions')}
                  </>
                )}
                secondaryContent={(
                  <span>
                    {t('common:dashboard.your-inclusions')}
                  </span>
                )}
              />
            </DashboardMenu>
          </div>
          <div className="card bg-white card-shadow p-4 mobile-card">
            <Switch>
              <Route
                {...this.props}
                path={`${url}/projects`}
              >
                <DashboardProjects />
              </Route>
              <Route
                {...this.props}
                path={`${url}/inclusions`}
              >
                <DashboardInclusions />
              </Route>
              <Route component={NotFound} {...this.props} />
            </Switch>
          </div>
        </div>
      </Page>
    );
  }
}


export default Dashboard;
