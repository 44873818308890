import PropTypes from 'prop-types';
import React, { Component } from 'react';
import '../assets/css/bigmenu.css';

// eslint-disable-next-line react/prefer-stateless-function
class BigMenu extends Component {
  render() {
    return (
      <div className="big-menu">
        {this.props.children}
      </div>
    );
  }
}

BigMenu.propTypes = {
  children: PropTypes.node.isRequired,
};

export default BigMenu;
