import React from 'react';
import PropTypes from 'prop-types';
import NewTooltip from '../components/NewTooltip';
import TextUtil from './TextUtil';

function TruncatedText(props) {
  const { text, maxLength } = props;
  const truncatedText = TextUtil.truncate(text, maxLength);

  return (
    <>
      <NewTooltip
        content={text}
        disabled={text === truncatedText}
      >
        <span>
          {truncatedText}
        </span>
      </NewTooltip>
    </>
  );
}

TruncatedText.propTypes = {
  text: PropTypes.string.isRequired,
  maxLength: PropTypes.number,
};

TruncatedText.defaultProps = {
  maxLength: 30,
};

export default TruncatedText;
