import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { withToastManager } from 'react-toast-notifications';
import { withRouter } from 'react-router';
import { Route, Switch } from 'react-router-dom';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { nsOptions } from '../i18n';
import BigMenu from './BigMenu';
import BigMenuDivider from './BigMenuDivider';
import BigMenuItem from './BigMenuItem';
import { teamsActions } from '../redux/actions';
import Toast from '../utils/Toast';
import ErrorUtil from '../utils/ErrorUtil';
import history from '../history';
import TeamForms from './TeamForms';

const mapStateToProps = (state) => ({
  userId: state.auth.authUser.id,
  user: state.auth.authUser,
});

const mapDispatchToProps = (dispatch) => ({
  addTeam: async (data) => dispatch(teamsActions.create(data)),
});

@withToastManager
@connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })
@withTranslation('', nsOptions)
@withRouter
class PractionerTeamForms extends Component {
  static propTypes = {
    match: PropTypes.shape().isRequired,
    t: PropTypes.func.isRequired,
    userId: PropTypes.number.isRequired,
    user: PropTypes.shape().isRequired,
    addTeam: PropTypes.func.isRequired,
    ownTeams: PropTypes.arrayOf(PropTypes.shape()).isRequired,
    otherTeams: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      newTeamIds: [],
    };
  }

  componentDidMount() {
    const { url, isExact } = this.props.match;
    if (url === '/dashboard/settings/teams' && isExact) {
      history.push(`${url}/my-team`);
    }
  }

  createTeam = async () => {
    try {
      const {
        addTeam, user, t, userId,
      } = this.props;
      const { newTeamIds } = this.state;
      const res = await addTeam({
        name: t('user:team.default-name', { label: user.label }),
        owner: userId,
      });
      await this.setState({ newTeamIds: [...newTeamIds, res.id] });
      Toast.success(this.props, 'error:valid.success');
    } catch (error) {
      ErrorUtil.handleCatched(this.props, error);
    }
  };

  renderTeamAccount = (ownTeams) => {
    const { t } = this.props;
    const { newTeamIds } = this.state;

    return (
      ownTeams.length ? (
        <TeamForms
          key="user-own-teams"
          userOwnTeams
          {...this.props}
          newTeamIds={newTeamIds}
          // Reset the newTeamIds after focusing on the new team name in TeamForm
          onTeamFormOpen={() => this.setState({ newTeamIds: [] })}
        />
      ) : (
        <div className="text-center">
          <div className="practitioner-no-team">
            <h6>
              {t('user:team.practitioner-no-team.part-1')}
            </h6>
            <h5 className="p-0">
              {t('user:team.practitioner-no-team.part-2')}
            </h5>
          </div>
          <div>
            <button
              className="btn btn-newturquoise-1"
              onClick={this.createTeam}
            >
              <FontAwesomeIcon
                icon={['fal', 'plus']}
                transform="grow-3"
                className="mr-2"
              />
              <span className="ml-1">
                {t('user:team.create')}
              </span>
            </button>
          </div>
          <div className="small mt-3">
            {t('user:team.create-help')}
          </div>
        </div>
      )
    );
  }

  renderJoinedTeams = (otherTeams) => {
    const { t } = this.props;
    return (
      otherTeams.length ? (
        <TeamForms
          key="joined-teams"
          {...this.props}
        />
      ) : (
        <div className="text-center practitioner-no-team">
          <h6>
            {t('user:team.no-joined-team.practitioner')}
          </h6>
        </div>
      )
    );
  }

  render() {
    const { t, ownTeams, otherTeams } = this.props;
    const { url } = this.props.match;

    return (
      <div>
        <div>
          <BigMenu divider="none" {...this.props}>
            <BigMenuItem to={`${url}/my-team`} {...this.props}>
              {t('user:team.nav.my-team')}
            </BigMenuItem>
            <BigMenuDivider />
            <BigMenuItem
              to={`${url}/joined-teams`}
              {...this.props}
            >
              {t('user:team.nav.joined-teams')}
            </BigMenuItem>
          </BigMenu>
        </div>
        <div className="p-4 mt-1">
          <Switch>
            <Route
              {...this.props}
              path={`${url}/my-team/:id`}
            >
              { this.renderTeamAccount(ownTeams) }
            </Route>
            <Route
              {...this.props}
              path={`${url}/my-team`}
            >
              { this.renderTeamAccount(ownTeams) }
            </Route>
            <Route
              {...this.props}
              path={`${url}/joined-teams/:id`}
            >
              { this.renderJoinedTeams(otherTeams) }
            </Route>
            <Route
              {...this.props}
              path={`${url}/joined-teams`}
            >
              { this.renderJoinedTeams(otherTeams) }
            </Route>
          </Switch>
        </div>
      </div>
    );
  }
}

export default PractionerTeamForms;
