import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { Route, Switch } from 'react-router-dom';
import { withToastManager } from 'react-toast-notifications';
import { Card } from 'reactstrap';
import memoize from 'memoize-one';
import { connect } from 'react-redux';
import api from '../api';
import {
  projectPagesActions, elementsActions, projectElementsActions, elementLinksActions,
  elementModalitiesActions, sortingHandlerActions, projectUsersActions,
} from '../redux/actions';
import {
  getSortingHandlerId, getSortingItemId, extractSortingItemDigitalId,
} from '../redux/actions/sorting-handler';
import history from '../history';
import { nsOptions } from '../i18n';
import SortUtil from '../utils/SortUtil';
import ErrorUtil from '../utils/ErrorUtil';
import Toast from '../utils/Toast';
import TimeoutHandler from '../utils/TimeoutHandler';
import { CardLoader } from './Loader';
import ProjectForm from './ProjectForm';
import withLicenseMsgModal from './withLicenseMsgModal';
import { formatPageTitle, DataLocker } from '../utils/data-util';
import {
  LOCK_SECTION, LOCK_SECTION_FORM, LOCK_TOAST_TIMEOUT_S, LOCK_MODEL_PROJECT,
} from '../constants';

const PROJECT_FORM_HANDLER_NAME = 'project-form';
const PROJECT_PAGES_HANDLER_NAME = 'project-pages';


const mapStateToProps = (state, ownProps) => ({
  pages: state.projectPages,
  elements: state.elements,
  projectElements: state.projectElements,
  sortedPagesIds: state.sortingHandler[getSortingHandlerId('project-pages', ownProps.project.id)],
  projectUser: Object.values(state.projectUsers).find((pUser) => (
    pUser.user && pUser.user.id === state.auth.authUser.id
      && pUser.project === ownProps.project.id
  )),
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  fetchProjectUsers: async () => dispatch(projectUsersActions.list({
    project: ownProps.project.id,
    admin: ownProps.admin,
  }, { pagination: 'no' })),
  fetchProjectElements: async (pages) => dispatch(projectElementsActions.list({
    project_page__in: Object.keys(pages),
    admin: ownProps.admin,
  }, { pagination: 'no' })),
  resyncProjectElements: async () => dispatch(projectElementsActions.resync({
    admin: ownProps.admin,
  })),
  fetchElements: async (projectElements) => dispatch(elementsActions.list({
    id__in: Object.values(projectElements).map((pEl) => pEl.element),
    admin: ownProps.admin,
  }, { pagination: 'no' })),
  resyncElements: async () => dispatch(elementsActions.resync({
    admin: ownProps.admin,
  })),
  fetchElementModalities: async (elements) => dispatch(elementModalitiesActions.list({
    element__in: Object.values(elements).map((el) => el.id),
    admin: ownProps.admin,
  }, { pagination: 'no' })),
  fetchElementLinks: async (projectElements) => dispatch(elementLinksActions.listByProjectElements(
    Object.keys(projectElements), { admin: ownProps.admin }, { pagination: 'no' },
  )),
  resyncElementLinks: async () => dispatch(elementLinksActions.resync({
    admin: ownProps.admin,
  })),
  fetchPages: async () => dispatch(projectPagesActions.list({
    project: ownProps.project.id,
    admin: ownProps.admin,
  }, { pagination: 'no' })),
  addPage: async (data) => dispatch(projectPagesActions.create(data, {
    admin: ownProps.admin,
  })),
  removePage: async (id) => dispatch(projectPagesActions.remove(id, {
    admin: ownProps.admin,
  })),
  patchPage: async (id, data) => dispatch(projectPagesActions.patch(id, data, {
    admin: ownProps.admin,
  })),
  resyncPages: async () => dispatch(projectPagesActions.resync({
    admin: ownProps.admin,
  })),
  setSortedItems: (handlerId, items) => dispatch(sortingHandlerActions.setItems({
    handlerId,
    items,
  })),
  removeSortingHandler: (handlerId) => dispatch(sortingHandlerActions.removeHandler({
    handlerId,
  })),
  addSortedItems: (handlerId, items) => dispatch(sortingHandlerActions.addItems({
    handlerId,
    items,
  })),
  removeSortedItem: (handlerId, item) => dispatch(sortingHandlerActions.removeItem({
    handlerId,
    item,
  })),
});


@withToastManager
@connect(mapStateToProps, mapDispatchToProps)
@withTranslation('', nsOptions)
@withLicenseMsgModal()
class ProjectForms extends Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
    project: PropTypes.shape().isRequired,
    projectUser: PropTypes.shape(),
    match: PropTypes.shape({
      url: PropTypes.string.isRequired,
    }).isRequired,
    location: PropTypes.shape({
      pathname: PropTypes.string,
    }).isRequired,
    admin: PropTypes.bool,
    pages: PropTypes.shape().isRequired,
    elements: PropTypes.shape().isRequired,
    projectElements: PropTypes.shape().isRequired,
    sortedPagesIds: PropTypes.arrayOf(PropTypes.string),
    fetchProjectUsers: PropTypes.func.isRequired,
    fetchProjectElements: PropTypes.func.isRequired,
    resyncProjectElements: PropTypes.func.isRequired,
    fetchElements: PropTypes.func.isRequired,
    resyncElements: PropTypes.func.isRequired,
    fetchElementModalities: PropTypes.func.isRequired,
    fetchElementLinks: PropTypes.func.isRequired,
    resyncElementLinks: PropTypes.func.isRequired,
    fetchPages: PropTypes.func.isRequired,
    addPage: PropTypes.func.isRequired,
    removePage: PropTypes.func.isRequired,
    patchPage: PropTypes.func.isRequired,
    resyncPages: PropTypes.func.isRequired,
    setSortedItems: PropTypes.func.isRequired,
    removeSortingHandler: PropTypes.func.isRequired,
    addSortedItems: PropTypes.func.isRequired,
    removeSortedItem: PropTypes.func.isRequired,
  };

  static defaultProps = {
    projectUser: undefined,
    admin: false,
    sortedPagesIds: [],
  };

  constructor(props) {
    super(props);
    this.state = {
      tabLoading: false,
      edition: false,
    };
    this.form = null;
    this.projectPagesHandlerId = getSortingHandlerId(PROJECT_PAGES_HANDLER_NAME, props.project.id);
    this.getPages = memoize((pages) => (pages ? Object.values(pages)
      .sort(SortUtil.sortArray)
      .map((page) => ({ key: page.id, value: formatPageTitle(page, props.t) })) : []));
    this.timeoutHandler = new TimeoutHandler();
    this.editionLocker = new DataLocker(LOCK_MODEL_PROJECT, LOCK_SECTION, props.admin);
  }

  async componentDidMount() {
    this.fetchData(true);
    const { projectUser, fetchProjectUsers } = this.props;
    // Most of the time, fetching projectUsers is not necessary (fetched in another project tab)
    if (!projectUser) {
      await fetchProjectUsers();
    }
  }

  componentWillUnmount() {
    if (this.editionLocker.isLocked()) this.editionLocker.unlock();
  }

  onEditionToggle = async () => {
    const { t, project } = this.props;

    try {
      const res = await this.editionLocker.toggleLock(project.id, LOCK_SECTION_FORM);
      const { locked, success } = res;
      if (success) {
        this.setState({ edition: locked });
      } else {
        Toast.warning(this.props, DataLocker.lockUserMessage(res, t), LOCK_TOAST_TIMEOUT_S);
      }
    } catch (error) {
      ErrorUtil.handleCatched(this.props, error);
    }
  }

  fetchData = async (fetchTabPages) => {
    this.setState({ tabLoading: true });
    const {
      fetchPages, fetchProjectElements, fetchElements, fetchElementLinks, fetchElementModalities,
      match, location, setSortedItems,
    } = this.props;
    try {
      if (fetchTabPages) {
        await fetchPages();
      }

      const tabs = this.getPages(this.props.pages);
      if (`${match.url}/pages` === location.pathname) {
        history.push(`${match.url}/pages/${tabs[0].key}`);
      }

      await fetchProjectElements(this.props.pages);

      if (Object.keys(this.props.projectElements).length > 0) {
        await Promise.all([
          fetchElements(this.props.projectElements),
          fetchElementLinks(this.props.projectElements),
        ]);
        await fetchElementModalities(this.props.elements);
      }

      const pages = Object.values(this.props.pages);
      setSortedItems(
        this.projectPagesHandlerId,
        pages
          .sort(SortUtil.sortArray)
          .map((page) => getSortingItemId(this.projectPagesHandlerId, page.id)),
      );

      pages.forEach((page) => {
        const handlerId = getSortingHandlerId(PROJECT_FORM_HANDLER_NAME, page.id);
        setSortedItems(
          handlerId,
          Object.values(this.props.projectElements).filter((pEl) => (
            pEl.project_page === page.id && pEl.module === null))
            .sort(SortUtil.sortArray).map((pEl) => getSortingItemId(handlerId, pEl.id)),
        );
      });
    } catch (error) {
      ErrorUtil.handleCatched(this.props, error, false);
    } finally {
      this.setState({ tabLoading: false });
    }
  };

  addTab = async () => {
    const {
      addPage, project, pages, addSortedItems, setSortedItems, match,
    } = this.props;

    this.setState({ tabLoading: true });

    try {
      const page = await addPage({
        title: `Page ${this.getPages(pages).length + 1}`,
        project: project.id,
        project_elements: [],
      });

      addSortedItems(
        this.projectPagesHandlerId,
        [getSortingItemId(this.projectPagesHandlerId, page.id)],
      );
      setSortedItems(getSortingHandlerId(PROJECT_FORM_HANDLER_NAME, page.id), []);

      Toast.success(this.props, 'error:valid.saved');
      history.push(`${match.url}/pages/${page.id}`);
    } catch (error) {
      ErrorUtil.handleCatched(this.props, error);
    } finally {
      this.setState({ tabLoading: false });
    }
  };

  cloneTab = async (tabId) => {
    this.setState({ tabLoading: true });
    const { match, admin } = this.props;
    try {
      const res = await api.create('clone-page', {
        project_page_id: tabId,
        related_objects: true,
      }, { admin });

      const clonedPage = res.page_id;

      await this.fetchData(true);

      Toast.success(this.props, 'error:valid.saved');
      history.push(`${match.url}/pages/${clonedPage}`);
    } catch (error) {
      ErrorUtil.handleCatched(this.props, error);
    } finally {
      this.setState({ tabLoading: false });
    }
  };

  removeTab = async (tabId) => {
    const {
      removeSortingHandler, removeSortedItem, removePage, resyncPages, match, pages,
      sortedPagesIds, resyncElements, resyncElementLinks, resyncProjectElements,
    } = this.props;

    this.setState({ tabLoading: true });

    try {
      const currentPageSorting = pages[tabId].sorting - 1;
      const redirectPageId = extractSortingItemDigitalId(
        sortedPagesIds[currentPageSorting ? currentPageSorting - 1 : 1],
      );

      removeSortingHandler(getSortingHandlerId(PROJECT_FORM_HANDLER_NAME, tabId));
      removeSortedItem(
        this.projectPagesHandlerId,
        getSortingHandlerId(this.projectPagesHandlerId, tabId),
      );

      await removePage(tabId);
      await Promise.all([
        resyncPages(), //  Sorting value of other pages may have changed
        resyncElements(), // Consistency check fields may have changed
        resyncProjectElements(),
        resyncElementLinks(), // Links could have been removed in cascade
      ]);
      Toast.success(this.props, 'error:valid.saved');
      history.push(`${match.url}/pages/${redirectPageId}`);
    } catch (error) {
      ErrorUtil.handleCatched(this.props, error, false);
    } finally {
      this.setState({ tabLoading: false });
    }
  };

  renameTab = async (text, tabId) => {
    this.timeoutHandler.doAfterTimeout(async () => {
      try {
        await this.props.patchPage(tabId, { title: text });
        Toast.success(this.props, 'error:valid.saved');
      } catch (error) {
        ErrorUtil.handleCatched(this.props, error);
      }
    });
  };

  render() {
    const { pages, project, match } = this.props;
    const { tabLoading, edition } = this.state;

    return (
      <div id="page-content">
        <Card
          className="bg-transparent border-0"
          id="project-tabcontent"
        >
          {
            (
              !tabLoading ? (
                <Switch>
                  {this.getPages(pages).map((tab) => (
                    <Route
                      key={tab.key}
                      path={`${match.url}/pages/${tab.key}`}
                      {...this.props}
                    >
                      <ProjectForm
                        {...this.props}
                        id={tab.key}
                        formSortingHandlerId={
                          getSortingHandlerId(PROJECT_FORM_HANDLER_NAME, tab.key)
                        }
                        editionMode={edition}
                        onEditionToggle={this.onEditionToggle}
                        project={project}
                        pages={this.getPages(pages)}
                        addTab={this.addTab}
                        renameTab={this.renameTab}
                        removeTab={this.removeTab}
                        cloneTab={this.cloneTab}
                        ref={(form) => {
                          this.form = form;
                        }}
                      />
                    </Route>
                  ))}
                </Switch>
              )
                : <CardLoader />
            )
          }
        </Card>
      </div>
    );
  }
}


export default ProjectForms;
