import api from '../../api';
import buildStdDbModelActions from './std-db-model-actions';

const stdDbModelActions = buildStdDbModelActions('groups');
const { listSuccess } = stdDbModelActions;

const list = (params = {}) => async (dispatch) => {
  const res = await api.preflight('users', params);
  dispatch(listSuccess(res.actions.POST.type.choices));
  return res;
};

export default {
  ...stdDbModelActions,
  list,
};
