import { createReducer } from 'redux-starter-kit';
import { projectUsersActions } from '../actions';
import getStdDbModelReduxMapping from './std-db-model-reducers';

export const projectUsersInitialState = {};

const projectUsersReducer = createReducer(
  projectUsersInitialState,
  getStdDbModelReduxMapping(projectUsersActions),
);

export default projectUsersReducer;
