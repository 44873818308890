import { createReducer } from 'redux-starter-kit';
import { teamsActions } from '../actions';
import getStdDbModelReduxMapping from './std-db-model-reducers';

export const teamsInitialState = {};

const teamsReducer = createReducer(
  teamsInitialState,
  getStdDbModelReduxMapping(teamsActions),
);

export default teamsReducer;
