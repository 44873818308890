import React from 'react';
import PropTypes from 'prop-types';
import {
  Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Typography, makeStyles,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { useTranslation } from 'react-i18next';
import { childrenPropTypes } from '../../utils/generic-prop-types';

const styles = (theme) => ({
  header: {
    margin: 0,
    padding: theme.spacing(2),
  },
  title: {
    marginRight: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    padding: 0,
    color: theme.palette.grey[500],
    '&:focus': {
      outline: 'none',
    },
  },
  actions: {
    justifyContent: 'center',
    '& .MuiButton-root': {
      color: theme.palette.common.white,
      backgroundColor: theme.palette.turquoise.light,
      '&:hover': {
        backgroundColor: theme.palette.turquoise.main,
      },
      '&:disabled': {
        color: theme.palette.action.disabled,
        backgroundColor: theme.palette.action.disabledBackground,
      },
    },
  },
});

const useStyles = makeStyles(styles);

function Modal(props) {
  const {
    children, contentClassName, hideValidateButton, onClickClose, onClose, open, onClickValidate,
    paperProps, title, validateButtonDisabled, ...other
  } = props;
  const { t } = useTranslation();
  const classes = useStyles();
  return (
    <Dialog
      aria-labelledby="modal-title"
      open={open}
      onClose={onClose}
      PaperProps={paperProps}
    >
      <DialogTitle disableTypography className={classes.header} {...other}>
        <Typography id="modal-title" variant="h6" className={classes.title}>{title}</Typography>
        {onClickClose ? (
          <IconButton aria-label="close" className={classes.closeButton} onClick={onClickClose}>
            <CloseIcon />
          </IconButton>
        ) : null}
      </DialogTitle>
      <DialogContent className={contentClassName}>
        {children}
      </DialogContent>
      {!hideValidateButton && (
        <DialogActions className={classes.actions}>
          <Button
            onClick={onClickValidate}
            color="primary"
            disabled={validateButtonDisabled}
          >
            {t('common:button.validate')}
          </Button>
        </DialogActions>
      )}
    </Dialog>
  );
}

Modal.propTypes = {
  open: PropTypes.bool.isRequired,
  contentClassName: PropTypes.string,
  children: childrenPropTypes().isRequired,
  hideValidateButton: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  onClickClose: PropTypes.func,
  onClickValidate: PropTypes.func,
  paperProps: PropTypes.shape().isRequired,
  title: childrenPropTypes().isRequired,
  validateButtonDisabled: PropTypes.bool,
};

Modal.defaultProps = {
  contentClassName: '',
  hideValidateButton: false,
  onClickClose: undefined,
  onClickValidate: () => {},
  validateButtonDisabled: false,
};

export default Modal;
