import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { components } from 'react-select';
import { withToastManager } from 'react-toast-notifications';
import api from '../api';
import { nsOptions } from '../i18n';
import { arraysEqual } from '../utils/arrays';
import ErrorUtil from '../utils/ErrorUtil';
import Validator, { GUI_EFFECT_FULL } from './Validator';
import CustomAsyncSelect from './CustomAsyncSelect';


// eslint-disable-next-line react/prefer-stateless-function
class Input extends React.Component {
  static propTypes = {
    isHidden: PropTypes.bool,
  };

  static defaultProps = {
    isHidden: false,
  };

  render() {
    const { isHidden } = this.props;
    if (isHidden) {
      return <components.Input {...this.props} />;
    }
    return <components.Input {...this.props} />;
  }
}


@withToastManager
@withTranslation('', nsOptions)
class TopicSelect extends Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
    i18n: PropTypes.shape().isRequired,
    willChange: PropTypes.func,
    onChange: PropTypes.func.isRequired,
    canCreate: PropTypes.bool,
    values: PropTypes.arrayOf(PropTypes.string),
    onlyValidated: PropTypes.bool,
    autoValidate: PropTypes.bool,
    disabled: PropTypes.bool,
    maxSelectedTopics: PropTypes.number,
    admin: PropTypes.bool,
    required: PropTypes.bool,
    showPlaceholder: PropTypes.bool,
  };

  static defaultProps = {
    willChange: null,
    canCreate: false,
    values: [],
    onlyValidated: false,
    autoValidate: false,
    disabled: false,
    maxSelectedTopics: null,
    admin: false,
    required: false,
    showPlaceholder: false,
  };

  constructor(props) {
    super(props);
    this.state = { value: [], loading: false };
    this.translations = null;
    this.newTopics = {};
  }

  componentDidMount() {
    this.fetchTopicsLabels();
  }

  componentDidUpdate(prevProps) {
    const { values, t } = this.props;
    if (
      (t !== prevProps.t) // Language changed.
      || (
        !arraysEqual(prevProps.values, values)
        && !arraysEqual(this.state.value.map((topic) => topic.value), values)
      )
    ) {
      this.fetchTopicsLabels();
    }
  }

  fetchTopicsLabels = async () => {
    const {
      values, admin, i18n,
    } = this.props;

    if (values.length > 0) {
      this.setState({ loading: true });

      try {
        const topics = await api.list('topics', {
          key__in: values,
          admin,
        }, { pagination: 'no' });

        const results = topics.map((topic) => ({
          value: topic.key,
          label: topic[i18n.language],
        }));

        this.setState({ value: results });
      } catch (error) {
        ErrorUtil.handleCatched(this.props, error, false);
      } finally {
        this.setState({ loading: false });
      }
    }
  };

  fetchTopics = async (search = null) => {
    const query = {
      search,
      admin: this.props.admin,
    };
    if (this.props.onlyValidated) query.validated = true;
    try {
      return await api.list('topics', query, { pagination: 'no' });
    } catch (error) {
      ErrorUtil.handleCatched(this.props, error, false);
    }
    return [];
  };

  load = async (inputValue) => {
    const { maxSelectedTopics, i18n } = this.props;
    if (!maxSelectedTopics || this.state.value.length < maxSelectedTopics) {
      try {
        const topics = await this.fetchTopics(inputValue);
        return topics.map((topic) => ({
          value: topic.key,
          label: topic[i18n.language],
        }));
      } catch (error) {
        ErrorUtil.handleCatched(this.props, error, false);
      }
    }
    return [];
  };

  handleChange = async (results) => {
    const { willChange, onChange, i18n } = this.props;
    const { value } = this.state;
    const valid = this.validate(results);
    const promises = [];
    let reloadTranslations = false;

    if (willChange) willChange();

    if (results) {
      results.forEach((topic) => {
        if (!value.includes(topic)) {
          let promise = topic;

          if (topic.__isNew__) {
            promise = this.saveNew(topic);
            reloadTranslations = true;
          }

          promises.push(promise);
        }
      });
    }

    value.forEach((topic) => {
      if (!results || !results.includes(topic)) {
        this.setState(
          (prevState) => ({
            value: prevState.value.filter((item) => item.value !== topic.value),
          }),
          () => { if (valid) onChange(this.state.value); },
        );

        if (topic.__isNew__) {
          promises.push(this.deleteNew(topic));
          reloadTranslations = true;
        }
      }
    });

    try {
      const topics = await Promise.all(promises);

      topics.forEach((topic) => {
        if (topic) {
          this.setState(
            (prevState) => ({ value: [...prevState.value, topic] }),
            () => { if (valid) onChange(this.state.value); },
          );
        }
      });

      if (reloadTranslations) i18n.reloadResources(null, 'topic');
    } catch (error) {
      ErrorUtil.handleCatched(this.props, error);
    }
  };

  noOptionsMessage = ({ inputValue }) => (
    this.props.maxSelectedTopics && this.state.value.length >= this.props.maxSelectedTopics
      ? `You can only select ${this.props.maxSelectedTopics} topics...`
      : `No topics matching "${inputValue}"`
  );

  saveNew = async (topic) => {
    const { i18n, autoValidate } = this.props;
    const { language } = i18n;

    try {
      const result = await api.create('topics', { [language]: topic.value });

      // FIXME: check & test
      const newTopic = topic;
      newTopic.value = result.key;
      newTopic.label = result[language];

      if (!autoValidate) {
        this.newTopics[newTopic.label] = result.key;
        return newTopic;
      }

      return await api.partial_update('topics', result.key, { validated: true });
    } catch (error) {
      ErrorUtil.handleCatched(this.props, error, false);
      return null;
    }
  };

  deleteNew = (topic) => {
    const id = this.newTopics[topic.value];
    if (!id) return null;
    return api.delete('topics', id);
  };

  reset = () => {
    this.setState({ value: [] });
  };

  validate = (value, guiEffect = GUI_EFFECT_FULL) => (
    this.validator ? this.validator.validate(value, guiEffect) : true
  );

  render() {
    const { t, showPlaceholder } = this.props;
    return (
      <Validator
        required={this.props.required}
        ref={(validator) => { this.validator = validator; }}
      >
        <CustomAsyncSelect
          creatable={this.props.canCreate}
          isMulti
          isValidNewOption={(inputValue, selectValue, selectOptions) => {
            const test1 = !/^(\s*)$/.test(inputValue.trim());
            const test2 = !/^.$/.test(inputValue.trim());
            const test3 = selectOptions.filter((option) => option.label.toLowerCase()
              === inputValue.toLowerCase()).length < 1;
            return test1 && test2 && test3;
          }}
          formatCreateLabel={(inputValue) => (
            <span
              dangerouslySetInnerHTML={{ __html: t('common:topic.add-new-topic', { name: `<span class="badge badge-topic">${inputValue}</span>` }) }}
            />
          )}
          noOptionsMessage={this.noOptionsMessage}
          className="react-select creatable-select topics"
          placeholder={t('project:resources.topics-help')}
          loadOptions={this.load}
          components={{ Input }}
          onChange={this.handleChange}
          value={this.state.value}
          defaultOptions
          isDisabled={this.props.disabled}
          isLoading={this.state.loading}
          styles={showPlaceholder ? (
            {
              container: (provided, state) => ({
                ...provided,
                marginTop: (state.hasValue || state.selectProps.inputValue) && 8,
              }),
              valueContainer: (provided) => ({
                ...provided,
                overflow: 'visible',
              }),
              placeholder: (provided, state) => ({
                ...provided,
                position: 'absolute',
                top: (state.hasValue || state.selectProps.inputValue) ? -6 : '50%',
                transition: 'top 0.1s, font-size 0.1s',
                fontSize: (state.hasValue || state.selectProps.inputValue) && 13,
                width: (state.hasValue || state.selectProps.inputValue) && 'max-content',
              }),
            }
          ) : (
            {
              placeholder: (provided, state) => ({
                ...provided,
                display: (state.hasValue || state.selectProps.inputValue) && 'none',
              }),
            }
          )}
        />
      </Validator>
    );
  }
}


export default TopicSelect;
