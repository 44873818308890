import PropTypes from 'prop-types';
import React, { Component } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import cellEditFactory, { Type } from 'react-bootstrap-table2-editor';
import { withTranslation } from 'react-i18next';
import { withToastManager } from 'react-toast-notifications';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import '../assets/css/table.css';
import { usersActions } from '../redux/actions';
import api from '../api';
import {
  USER_TYPE_ASSISTANT, USER_SUBTYPE_DOCTOR, USER_SUBTYPE_PROFESSOR, USER_SUBTYPE_SECRETARY,
  USER_SUBTYPE_NURSE, USER_SUBTYPE_CRA, USER_SUBTYPE_OTHER, USER_TYPE_INDUSTRIALIST,
  USER_TYPE_HEALTHCARE_FACILITY, INSTITUTION_TYPES, INDIVIDUAL_TYPES,
} from '../constants';
import Head from '../components/AdminHead';
import { CardLoader } from '../components/Loader';
import Pagination from '../components/Pagination';
import LabeledInput from '../components/LabeledInput';
import LabeledSelect from '../components/LabeledSelect';
import NewTooltip from '../components/NewTooltip';
// import { validators } from '../components/Validator';
import { nsOptions } from '../i18n';
import history from '../history';
import Toast from '../utils/Toast';
import TimeoutHandler from '../utils/TimeoutHandler';
import ErrorUtil from '../utils/ErrorUtil';
import { isProjectDisabled } from '../utils/data-util';
import canRequestFreeTrial from '../utils/free-trial';
import downloadEndpoint from '../utils/downloadEndpoint';

const VERIFIED_STR = 'Verified';
const NOT_VERIFIED_STR = 'Not Verified';

const STATUS_ALL = 'all';
const STATUS_VERIFIED = 'verified';
const STATUS_NOT_VERIFIED = 'not-verified';
const STATUS_EMAIL_NOT_VALIDATED = 'email-not-validated';
const STATUS_USER_NO_PROJECT = 'no-project';
const STATUS_USER_WITH_NO_TEAM_AND_NO_PROJECT = 'no-team-and-no-project';

const COLUMN_PROJECTS = 'projects';
const COLUMN_ACTIVE_PROJECTS = 'active-projects';
const COLUMN_TEAMS = 'teams';

const PUBLIC_PROFILE_STR = 'Yes';
const NOT_PUBLIC_PROFILE_STR = 'No';

const TRIAL_ENABLED = 'Yes';
const TRIAL_DISABLED = 'No';

const TYPES_WITH_NO_SPE_REQUIRED = [
  USER_TYPE_ASSISTANT, USER_TYPE_INDUSTRIALIST, USER_TYPE_HEALTHCARE_FACILITY,
];

const statusToFilter = (status) => {
  switch (status) {
    case STATUS_VERIFIED: return { verified: true };
    case STATUS_NOT_VERIFIED: return {
      verified: false,
      email_validated: true,
    };
    case STATUS_EMAIL_NOT_VALIDATED: return { email_validated: false };
    case STATUS_USER_NO_PROJECT: return { user_projects__isnull: true };
    case STATUS_USER_WITH_NO_TEAM_AND_NO_PROJECT: return {
      team_members__isnull: true,
      user_projects__isnull: true,
    };
    default: return {};
  }
};

const viewUser = (id) => {
  history.push(`/admin/user/${id}/account`);
};

const mapStateToProps = (state) => ({ user: state.auth.authUser });

const mapDispatchToProps = (dispatch) => ({
  patchUser: async (id, data) => dispatch(usersActions.patch(id, data, { admin: true })),
});


@withToastManager
@connect(mapStateToProps, mapDispatchToProps)
@withTranslation('', nsOptions)
class Users extends Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
    patchUser: PropTypes.func.isRequired,
    history: PropTypes.shape().isRequired,
  };

  static getTableColumns(t, countries = [], specialties = []) {
    const countryEditor = {
      type: Type.SELECT,
      options: [],
    };

    if (Array.isArray(countries)) {
      countries.forEach((country) => countryEditor.options.unshift({
        value: country.id,
        label: t(`country:${country.name}`),
      }));
    }

    const specialtyEditor = {
      type: Type.SELECT,
      options: [],
    };

    if (Array.isArray(specialties)) {
      specialties.forEach((specialty) => specialtyEditor.options.unshift({
        value: specialty.key,
        label: t(`specialty:${specialty.key}`),
      }));
    }

    return [{
      dataField: 'id',
      text: 'Identifier',
      hidden: true,
    }, {
      dataField: 'type',
      text: 'Type',
      sort: true,
      editor: {
        type: Type.SELECT,
        getOptions: (setOpions, params) => {
          const { row } = params;
          let res = [];
          if (INSTITUTION_TYPES.includes(row.type)) res = INSTITUTION_TYPES;
          else res = INDIVIDUAL_TYPES;
          return res.map((type) => ({
            value: type,
            label: t(`user:types.${type}`),
          }));
        },
      },
      formatter: (cellContent) => (cellContent ? t(`user:types.${cellContent}`) : ''),
      needsPostUpdate: true,
      validate: (oldValue, newValue, row) => {
        if (TYPES_WITH_NO_SPE_REQUIRED.includes(newValue)) return true;
        return row.specialty;
      },
      showSpecificToast: (props, oldUser, newUser) => {
        if (oldUser[COLUMN_ACTIVE_PROJECTS].length > newUser.limitations.max_project_creations) {
          Toast.warning(
            props,
            'Saved. Some projects have been automatically disabled to match the new user\'s license. Check the licenses tab to review active projects.',
            10000,
            true,
          );
          return true;
        }
        return false;
      },
    }, {
      dataField: 'subtype',
      text: 'Function',
      sort: true,
      editor: {
        type: Type.SELECT,
        options: [
          {
            value: USER_SUBTYPE_DOCTOR,
            label: t(`user:subtypes.${USER_SUBTYPE_DOCTOR}`),
          }, {
            value: USER_SUBTYPE_PROFESSOR,
            label: t(`user:subtypes.${USER_SUBTYPE_PROFESSOR}`),
          }, {
            value: USER_SUBTYPE_SECRETARY,
            label: t(`user:subtypes.${USER_SUBTYPE_SECRETARY}`),
          }, {
            value: USER_SUBTYPE_NURSE,
            label: t(`user:subtypes.${USER_SUBTYPE_NURSE}`),
          }, {
            value: USER_SUBTYPE_CRA,
            label: t(`user:subtypes.${USER_SUBTYPE_CRA}`),
          }, {
            value: USER_SUBTYPE_OTHER,
            label: t(`user:subtypes.${USER_SUBTYPE_OTHER}`),
          },
        ],
      },
      formatter: (cellContent) => (cellContent ? t(`user:subtypes.${cellContent}`) : ''),
    }, {
      dataField: 'last_name',
      text: 'Last name',
      sort: true,
    }, {
      dataField: 'first_name',
      text: 'First name',
      sort: true,
    }, {
      dataField: 'email',
      text: 'Email',
      sort: true,
    }, {
      // dataField: 'email',
    //   text: 'Email',
    //   sort: true,
    //   validate: (oldValue, newValue) => validators.email(newValue),
    // }, {
      dataField: 'city',
      text: 'City',
      sort: true,
    }, {
      dataField: 'country',
      text: 'Country',
      sort: true,
      editor: countryEditor,
      formatter: (cellContent) => {
        let classNameValue;
        const country = countries.find((cnty) => cnty.id === Number(cellContent));
        if (country) {
          if (country.active) {
            classNameValue = 'verified';
          } else {
            classNameValue = 'not-verified';
          }
          return (
            <span className={classNameValue}>
              {t(`country:${country.name}`)}
            </span>
          );
        }
        return cellContent;
      },
    }, {
      dataField: 'specialty',
      text: 'Specialty',
      sort: true,
      editor: specialtyEditor,
      formatter: (cellContent) => t(`specialty:${cellContent}`),
    }, {
      dataField: COLUMN_PROJECTS,
      text: 'Projects',
      sort: true,
      formatter: (cellContent) => String(cellContent.length),
      title: (cell) => cell.reduce((acc, val) => `${acc ? `${acc}, ` : ''}${val.name}`, ''),
      editable: false,
    }, {
      dataField: COLUMN_ACTIVE_PROJECTS,
      text: 'Active Projects',
      formatter: (cellContent) => cellContent.length,
      editable: false,
      hidden: true,
    }, {
      dataField: COLUMN_TEAMS,
      text: 'Teams',
      sort: true,
      formatter: (cellContent) => String(cellContent.length),
      title: (cell) => cell.reduce((acc, val) => `${acc ? `${acc}, ` : ''}${val.name}`, ''),
      editable: false,
    }, {
      dataField: 'verified',
      text: 'Verification status',
      sort: true,
      editor: {
        type: Type.SELECT,
        options: [{
          value: true,
          label: VERIFIED_STR,
        }, {
          value: false,
          label: NOT_VERIFIED_STR,
        },
        ],
      },
      formatter: (cellContent, row) => { // eslint-disable-line no-unused-vars
        let classNameValue;
        let value;
        if (cellContent === true || cellContent === 'true') {
          classNameValue = 'verified';
          value = VERIFIED_STR;
        } else {
          classNameValue = 'not-verified';
          value = NOT_VERIFIED_STR;
        }
        return (<span className={classNameValue}>{value}</span>);
      },
    }, {
      dataField: 'public_profile',
      text: 'Public profile',
      sort: true,
      editor: {
        type: Type.SELECT,
        getOptions: (setOptions, params) => {
          const { row } = params;
          const { can_have_a_public_profile: canHavePublicProfile } = row.limitations;
          return canHavePublicProfile ? [{
            value: true,
            label: PUBLIC_PROFILE_STR,
          }, {
            value: false,
            label: NOT_PUBLIC_PROFILE_STR,
          }] : [];
        },
      },
      formatter: (cellContent, row) => {
        const { can_have_a_public_profile: canHavePublicProfile } = row.limitations;
        if (!canHavePublicProfile) return '';
        if (cellContent === true || cellContent === 'true') {
          return PUBLIC_PROFILE_STR;
        }
        return NOT_PUBLIC_PROFILE_STR;
      },
    }, {
      dataField: 'limitations',
      hidden: true,
    }, {
      dataField: 'disable_free_trial',
      text: 'Trial button',
      sort: true,
      editor: {
        type: Type.SELECT,
        getOptions: (setOptions, params) => {
          const { row } = params;
          const canRequestFT = canRequestFreeTrial(row, row.teams);
          return canRequestFT ? [{
            value: true,
            label: TRIAL_DISABLED,
          }, {
            value: false,
            label: TRIAL_ENABLED,
          }] : [];
        },
      },
      formatter: (cellContent, row) => {
        const canRequestFT = canRequestFreeTrial(row, row.teams);
        if (!canRequestFT) return '';
        if (cellContent === true || cellContent === 'true') {
          return TRIAL_DISABLED;
        }
        return TRIAL_ENABLED;
      },
    }, {
      dataField: 'edit',
      text: 'Edit',
      isDummyField: true,
      sort: false,
      headerStyle: {
        width: '60px',
      },
      editable: false,
      align: 'center',
      formatter: (cellContent, row) => (
        <button
          type="button"
          className="overmenu-item p-0"
          onClick={() => viewUser(row.id)}
        >
          <FontAwesomeIcon icon="eye" />
        </button>
      ),
    }];
  }

  constructor(props) {
    super(props, 'Doqboard users');
    this.state = {
      columns: Users.getTableColumns(this.props.t),
      users: [],
      totalUserCount: 0,
      pageSize: 1,
      dataLoading: false,
      search: '',
      status: STATUS_ALL,
      displaySearchTooltip: false,
    };
    this.currentPage = 1;
    this.countries = [];
    this.specialties = [];
    this.timeoutHandler = new TimeoutHandler();
  }

  componentDidMount() {
    this.fetchData();
  }

  updateTableColumns = () => {
    this.setState({
      columns: Users.getTableColumns(this.props.t, this.countries, this.specialties),
    });
  };

  fetchData = async () => {
    try {
      this.setState({ dataLoading: true });
      await Promise.all([this.fetchCountries(), this.fetchSpecialties()]);
      this.updateTableColumns();
      await this.fetchUsers(this.currentPage);
    } catch (error) {
      ErrorUtil.handleCatched(error, false);
    } finally {
      this.setState({ dataLoading: false });
    }
  };

  fetchCountries = async () => {
    try {
      const res = await api.list('countries', {}, { pagination: 'no' });
      this.countries = res;
    } catch (err) {
      ErrorUtil.handleCatched(err, false);
    }
  };

  fetchSpecialties = async () => {
    try {
      this.specialties = await api.list('specialties', { active: true });
    } catch (err) {
      ErrorUtil.handleCatched(err, false);
    }
  };

  formatUser = (user, projectUsers, projects, teams, teamMembers) => {
    const userProjects = projectUsers.filter((pUser) => (
      pUser.user.id === user.id
    )).map((pUser) => projects.find((project) => pUser.project === project.id));
    const userTeamsIds = teamMembers.filter((teamMember) => (
      teamMember.user.id === user.id)).map((teamMember) => teamMember.team);
    const userTeams = teams.filter((team) => userTeamsIds.includes(team.id));
    const extendedUser = {
      ...user,
      [COLUMN_PROJECTS]: userProjects,
      [COLUMN_ACTIVE_PROJECTS]: userProjects.filter((project) => (
        project.manager.id === user.id && !isProjectDisabled(project)
      )),
      [COLUMN_TEAMS]: userTeams,
    };
    const formattedUser = {};
    this.state.columns.forEach((col) => {
      const value = extendedUser[col.dataField];
      let formatFromAPI = (val) => val;
      if (col && col.formatFromAPI) {
        ({ formatFromAPI } = col);
      }
      formattedUser[col.dataField] = formatFromAPI(value) || '';
    });
    return formattedUser;
  };

  getFilters = () => {
    const { search, status } = this.state;
    return {
      admin: true,
      search,
      ordering: '-date_joined',
      ...statusToFilter(status),
    };
  };

  fetchUsers = async (page) => {
    this.currentPage = page;

    try {
      this.setState({ dataLoading: true });
      const resUsers = await api.list('users', {
        page,
        ...this.getFilters(),
      });
      const resProjectUsers = await api.list('project-users', {
        user__in: resUsers.results.map((user) => user.id),
        admin: true,
      }, { pagination: 'no' });
      const resProject = await api.list('projects', {
        id__in: resProjectUsers.map((pUser) => pUser.project),
        admin: true,
      }, { pagination: 'no' });
      const resTeams = await api.list('teams', {
        members__user__in: resUsers.results.map((user) => user.id),
        admin: true,
      }, { pagination: 'no' });
      const restTeamMembers = await api.list('team-members', {
        user__in: resUsers.results.map((user) => user.id),
        confirmed: true,
        admin: true,
      }, { pagination: 'no' });

      this.setState({
        totalUserCount: resUsers.count,
        pageSize: Math.max(resUsers.results.length, this.state.pageSize),
      });

      const users = resUsers.results.map((user) => this.formatUser(
        user,
        resProjectUsers,
        resProject,
        resTeams,
        restTeamMembers,
      ));

      this.setState({ users });
    } catch (err) {
      ErrorUtil.handleCatched(err, false);
    } finally {
      this.setState({ dataLoading: false });
    }
  };

  fetchUser = async (id) => {
    try {
      this.setState({ dataLoading: true });
      const updatedUser = await api.read('users', id, { admin: true });
      const resProjectUsers = await api.list('project-users', {
        user__in: updatedUser.id,
        admin: true,
      }, { pagination: 'no' });
      const resProject = await api.list('projects', {
        id__in: resProjectUsers.map((pUser) => pUser.project),
        admin: true,
      }, { pagination: 'no' });
      const resTeams = await api.list('teams', {
        members__user__in: updatedUser.id,
        admin: true,
      }, { pagination: 'no' });
      const resTeamMembers = await api.list('team-members', {
        user__in: updatedUser.id,
        confirmed: true,
        admin: true,
      }, { pagination: 'no' });
      const { users } = this.state;
      const newUsers = users.map((user) => (
        user.id === updatedUser.id ? this.formatUser(
          updatedUser,
          resProjectUsers,
          resProject,
          resTeams,
          resTeamMembers,
        ) : user
      ));
      this.setState({ users: newUsers });
    } catch (err) {
      ErrorUtil.handleCatched(err, false);
    } finally {
      this.setState({ dataLoading: false });
    }
  };

  saveUser = async (userId, field, value) => {
    try {
      const { users } = this.state;
      let formatToAPI = (val) => val;
      const column = this.state.columns.find((col) => col.dataField === field);
      if (column && column.formatToAPI) {
        ({ formatToAPI } = column);
      }
      const oldUser = users.find((user) => user.id === userId);
      const newUser = await this.props.patchUser(
        userId,
        { [field]: formatToAPI(value) },
      );
      let showSuccessToast = true;
      if (column && column.showSpecificToast) {
        showSuccessToast = !column.showSpecificToast(this.props, oldUser, newUser);
      }
      if (showSuccessToast) Toast.success(this.props, 'error:valid.saved');
      return true;
    } catch (error) {
      ErrorUtil.handleCatched(this.props, error);
      return false;
    }
  };

  handleSearch = async (event) => {
    const { value } = event.target;
    this.timeoutHandler.doAfterTimeoutWithClbk((startTimeout) => {
      this.setState({ search: value }, () => startTimeout(() => {
        this.fetchUsers(1);
      }));
    }, 'default', 500);
  };

  exportUsersToCsv = async () => {
    try {
      await downloadEndpoint('users/export', 'post', this.getFilters(), {});
      Toast.success(this.props, 'error:valid.success');
    } catch (error) {
      ErrorUtil.handleCatched(this.props, error);
    }
  };

  render() {
    const {
      dataLoading, search, users, columns, status, totalUserCount, pageSize, displaySearchTooltip,
    } = this.state;
    const cellEdit = cellEditFactory({
      mode: 'dbclick',
      blurToSave: true,
      // eslint-disable-next-line no-unused-vars
      beforeSaveCell: (oldValue, newValue, row, column, done) => {
        let { validate } = column;
        if (!validate) validate = () => true;
        const needUpdate = String(oldValue) !== String(newValue);
        if (needUpdate && validate(oldValue, newValue, row, column)) {
          this.saveUser(row.id, column.dataField, newValue)
            .then((ret) => { done(ret); });
        } else {
          if (needUpdate) Toast.error(this.props, 'The new value cannot be set.');
          done(false);
        }
        return { async: true };
      },
      afterSaveCell: (oldValue, newValue, row, column) => {
        if (column.needsPostUpdate) {
          // Get updated user
          this.fetchUser(row.id);
        }
      },
    });

    return (
      <div>
        <Head
          title="Doqboard users"
          subTitle="Find here Doqboard users."
          admin
          {...this.props}
        />
        <div className="dashboard-content">
          <div className="row">
            <div className="col-auto">
              <NewTooltip
                content="Search users by name, email, city or country."
                visible={displaySearchTooltip}
              >
                <LabeledInput
                  type="text"
                  label="Search users"
                  name="search"
                  labelClassName="mb-2"
                  placeholder="Enter your pattern..."
                  value={search}
                  hideOptionalLabel
                  onChange={this.handleSearch}
                  onFocus={() => { this.setState({ displaySearchTooltip: true }); }}
                  onBlur={() => { this.setState({ displaySearchTooltip: false }); }}
                />
              </NewTooltip>
            </div>
            <div className="col-auto">
              <LabeledSelect
                label="Status"
                name="status"
                value={status}
                hideOptionalLabel
                onChange={(e) => {
                  this.setState(
                    { status: e.target.value },
                    () => this.fetchUsers(this.currentPage),
                  );
                }}
              >
                <option value={STATUS_ALL}>
                  All
                </option>
                <option value={STATUS_VERIFIED}>
                  Verified
                </option>
                <option value={STATUS_NOT_VERIFIED}>
                  Under verification
                </option>
                <option value={STATUS_EMAIL_NOT_VALIDATED}>
                  Email not validated
                </option>
                <option value={STATUS_USER_NO_PROJECT}>
                  No project
                </option>
                <option value={STATUS_USER_WITH_NO_TEAM_AND_NO_PROJECT}>
                  No team and no project
                </option>
              </LabeledSelect>
            </div>
            <div className="col-auto mt-4 pt-2 font-weight-semibold">
              {`Total number of filtered users: ${totalUserCount}`}
            </div>
            <div className="col-auto mt-4">
              <button
                type="button"
                className="btn btn-newblue-1"
                onClick={this.exportUsersToCsv}
              >
                Export users to a CSV
              </button>
            </div>
          </div>
          <div className="contains-loader mt-5">
            { dataLoading && <CardLoader /> }
            <BootstrapTable
              keyField="id"
              data={users}
              columns={columns}
              cellEdit={cellEdit}
              headerClasses="admin_table"
              wrapperClasses="admin_table"
              bootstrap4
              hover
            />
          </div>
          <nav className="my-5">
            <Pagination
              page={this.currentPage}
              count={totalUserCount}
              pageSize={pageSize}
              action={this.fetchUsers}
            />
          </nav>
        </div>
      </div>
    );
  }
}


export default Users;
