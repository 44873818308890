import { createReducer } from 'redux-starter-kit';
import { elementModalitiesActions } from '../actions';
import getStdDbModelReduxMapping from './std-db-model-reducers';

export const elementModalitiesInitialState = {};

const elementModalitiesReducer = createReducer(
  elementModalitiesInitialState,
  getStdDbModelReduxMapping(elementModalitiesActions),
);

export default elementModalitiesReducer;
