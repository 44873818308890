import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableHead from '@material-ui/core/TableHead';
import TableFooter from '@material-ui/core/TableFooter';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import withStyles from '@material-ui/core/styles/withStyles';
import FilteredIcon from './FilteredIcon';
import { isQuantitative } from '../../utils/stats';
import { nsOptions } from '../../i18n';
import FormattedValue from '../../utils/FormattedValue';
import DataOverviewModal from './DataOverviewModal';


const styles = (theme) => ({
  headCell: {
    fontWeight: 'bold',
    color: theme.palette.text.primary,
    fontSize: theme.typography.body2.fontSize,
    borderBottom: 0,
    paddingLeft: 0,
    paddingRight: 0,
  },
  cell: {
    borderBottom: 0,
    '&:first-child': {
      paddingLeft: 0,
    },
    '&:last-child': {
      paddingRight: 0,
    },
  },
  filterIcon: {
    marginLeft: theme.spacing(0.5),
    marginBottom: theme.spacing(0.1),
    color: theme.palette.text.primary,
  },
  filteredIcon: {
    marginLeft: theme.spacing(0.5),
  },
  container: {
    ...theme.typography.body2,
    borderBottom: 0,
    paddingLeft: 0,
    paddingRight: 0,
    width: 'fit-content',
    marginLeft: 0,
  },
});


@withTranslation('', nsOptions)
@withStyles(styles)
class TextStats extends React.Component {
  static propTypes = {
    // eslint-disable-next-line react/no-unused-prop-types
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    variableName: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    elementType: PropTypes.string,
    unit: PropTypes.string,
    count: PropTypes.number.isRequired,
    min: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    max: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    median: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    mean: PropTypes.number,
    variance: PropTypes.number,
    stddev: PropTypes.number,
    interquartileRange: PropTypes.number,
    seriesNamesCounts: PropTypes.shape(),
    inModule: PropTypes.bool,
    t: PropTypes.func.isRequired,
    classes: PropTypes.shape().isRequired,
    filtersParams: PropTypes.shape(),
    isModule: PropTypes.bool,
    isLinkTarget: PropTypes.bool,
    project: PropTypes.shape().isRequired,
    showLoader: PropTypes.func,
    canUseAdvancedTools: PropTypes.bool.isRequired,
  };

  static defaultProps = {
    elementType: null,
    unit: '',
    min: null,
    max: null,
    median: null,
    mean: null,
    variance: null,
    stddev: null,
    interquartileRange: null,
    seriesNamesCounts: {},
    inModule: false,
    filtersParams: undefined,
    isModule: false,
    isLinkTarget: false,
    showLoader: () => {},
  };

  render() {
    const {
      type, unit, count, min, max, median, mean, variance, stddev, interquartileRange, t, classes,
      seriesNamesCounts, inModule, variableName, isModule, isLinkTarget, id, filtersParams, project,
      elementType, showLoader, canUseAdvancedTools,
    } = this.props;

    let title;
    const stats = [];
    if (isQuantitative(type)) {
      title = t('stats:cross-entries-results');
      if (min !== null) {
        stats.push([t('stats:minimum'), <FormattedValue value={min} unit={unit} />]);
      }
      if (max !== null) {
        stats.push([t('stats:maximum'), <FormattedValue value={max} unit={unit} />]);
      }
      if (median !== null) {
        stats.push([t('stats:median'), <FormattedValue value={median} unit={unit} />]);
      }
      if (mean !== null) {
        stats.push([t('stats:mean'), <FormattedValue value={mean} unit={unit} />]);
      }
      if (variance !== null) {
        stats.push([t('stats:variance'), <FormattedValue value={variance} unit={unit ? `${unit}²` : null} />]);
      }
      if (stddev !== null) {
        stats.push([t('stats:standard-deviation'), <FormattedValue value={stddev} unit={unit} />]);
      }
      if (interquartileRange !== null) {
        stats.push([t('stats:interquartile-range'), <FormattedValue value={interquartileRange} unit={unit} />]);
      }
    } else {
      title = t('stats:entries-occurences');
      const sortedSeriesNamesCounts = Object.entries(seriesNamesCounts).sort(
        ([, aCount], [, bCount]) => {
          if (aCount < bCount) return 1;
          if (aCount > bCount) return -1;
          return 0;
        },
      );
      sortedSeriesNamesCounts.forEach(([seriesName, seriesCount]) => {
        const name = seriesName || t('common:elements.default-labels.answer');
        stats.push([name, <FormattedValue value={seriesCount / count} isRatio />]);
      });
    }

    if (stats.length === 0) {
      return null;
    }

    return (
      <Table size="small">
        { inModule ? (
          <TableHead>
            <TableRow>
              <TableCell colSpan={2} className={classes.headCell}>
                {title}
              </TableCell>
            </TableRow>
          </TableHead>
        ) : null }
        <TableBody>
          {stats.map(([name, value, filtered]) => (
            <TableRow key={name}>
              <TableCell className={classes.cell}>{name}</TableCell>
              <TableCell className={classes.cell} align="right">
                { value }
                { filtered ? <FilteredIcon className={classes.filteredIcon} /> : null }
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TableCell colSpan={2} className={classes.cell}>
              <DataOverviewModal
                id={id}
                name={variableName}
                isModule={isModule}
                count={count}
                filtersParams={filtersParams}
                project={project}
                isLinkTarget={isLinkTarget}
                elementType={elementType}
                showLoader={showLoader}
                canUseAdvancedTools={canUseAdvancedTools}
              />
            </TableCell>
          </TableRow>
        </TableFooter>
      </Table>
    );
  }
}

export default TextStats;
