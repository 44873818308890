import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { withToastManager } from 'react-toast-notifications';
import AsyncSelect from 'react-select/async';
import isEqual from 'react-fast-compare';
import { connect } from 'react-redux';
import { elementsActions } from '../redux/actions';
import { nsOptions } from '../i18n';
import { childrenPropTypes } from '../utils/generic-prop-types';
import ErrorUtil from '../utils/ErrorUtil';
import LabeledInput from './LabeledInput';
import { CardLoader } from './Loader';
import NewModal from './NewModal';
import LicenseChecker from './LicenseChecker';
import CalculationEditor from './CalculationEditor';
import { formatPageTitle } from '../utils/data-util';
import ElementUtil from '../utils/ElementUtil';
import SortUtil from '../utils/SortUtil';
import {
  isElementSupportedInCalculations, getInvolvedCalculations, isVariable, VAR_ERROR,
  formulaVariableToProjectElementId,
} from '../utils/calculations';
import LabeledSelect from './LabeledSelect';
import api from '../api';
import FormattedValue from '../utils/FormattedValue';
import {
  CALCULATION_VARIABLE_PREFIX, DATE_FULL, DATE_MONTH_YEAR, DATE_TIME, DATE_YEAR, ELEMENT_TYPE_DATE,
  ELEMENT_TYPE_TIME,
} from '../constants';
import TimeoutHandler from '../utils/TimeoutHandler';
import { getNestedValue } from '../utils/object-util';
import Help from './Help';

const mapStateToProps = (state, ownProps) => ({
  element: state.elements[ownProps.projectElement.element],
  elements: state.elements,
  projectElements: state.projectElements,
  elementCalculations: [],
  pages: state.projectPages,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  patchElement: async (id, data) => dispatch(elementsActions.patch(id, data, {
    admin: ownProps.admin,
  })),
});

@withToastManager
@connect(mapStateToProps, mapDispatchToProps)
@withTranslation('', nsOptions)
class ElementCalculationManager extends Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
    children: childrenPropTypes().isRequired,
    element: PropTypes.shape().isRequired,
    elements: PropTypes.shape().isRequired,
    projectElement: PropTypes.shape(),
    projectElements: PropTypes.shape().isRequired,
    project: PropTypes.shape().isRequired,
    pages: PropTypes.shape().isRequired,
    patchElement: PropTypes.func.isRequired,
  };

  static defaultProps = {
    projectElement: null,
  };

  static getDateTimeUnits = (format1, format2) => {
    const units = ['seconds', 'minutes', 'hours', 'weeks', 'days', 'months', 'years'];
    const getUnits = (lowestUnit, highestUnit) => {
      const lowestIndex = units.findIndex((unit) => unit === lowestUnit);
      const highestIndex = units.findIndex((unit) => unit === highestUnit);
      return units.slice(lowestIndex, highestIndex + 1);
    };
    const getUnitsByFormat = (format) => {
      switch (format) {
        case ELEMENT_TYPE_TIME:
          return getUnits('seconds', 'hours');
        case DATE_YEAR:
          return getUnits('years', 'years');
        case DATE_MONTH_YEAR:
          return getUnits('months', 'years');
        case DATE_FULL:
          return getUnits('days', 'years');
        case DATE_TIME:
          return getUnits('minutes', 'years');
        default:
          throw new Error(`Unsupported element format (${format})`);
      }
    };
    // Intersection of units allowed by each format
    return getUnitsByFormat(format1).filter((unit) => getUnitsByFormat(format2).includes(unit));
  };

  constructor(props) {
    super(props);
    this.state = {
      ready: false,
      calculation: {},
      data: [],
      example: null,
      dateUnitsToShow: [],
    };
    this.calculationEditor = null;
    this.timeoutHandler = new TimeoutHandler();
  }

  get formula() {
    const { data } = this.state;
    if (!data.length) {
      return [];
    }
    return data.map((v) => (isVariable(v, true) ? v.split('|')[0] : v));
  }

  updateExample = async () => {
    this.timeoutHandler.doAfterTimeout(async () => {
      const { calculation, dateUnitsToShow } = this.state;
      const { formula } = this;
      if (formula.length === 0) {
        this.setState({
          example: null,
        });
        return;
      }
      try {
        const example = await api.requestData(
          'calculation-example', null, 'post', null, {
            formula,
            date_units: this.getDateUnitsState(dateUnitsToShow, calculation.dateUnits, false),
          },
        );
        this.setState({ example });
      } catch (error) {
        this.setState({
          example: { formula: JSON.parse(error.message).detail.message },
        });
      }
    });
  };

  getDateUnitsToShowState = (data) => {
    const { elements, projectElements } = this.props;
    const dateDifferences = {};

    const toFormat = (pEl) => {
      switch (pEl.type) {
        case ELEMENT_TYPE_DATE:
          return pEl.format;
        case ELEMENT_TYPE_TIME:
          return ELEMENT_TYPE_TIME;
        default:
          throw new Error(`Unsupported element type (${pEl.type})`);
      }
    };

    let countIterationsToIgnore = 0;
    data.forEach((item, index) => {
      if (countIterationsToIgnore) {
        countIterationsToIgnore -= 1;
        return;
      }
      const operand1 = item;
      const operator = index + 1 < data.length ? data[index + 1] : '';
      const operand2 = index + 2 < data.length ? data[index + 2] : '';
      if (operator === '-' && isVariable(operand1, true) && isVariable(operand2, true)) {
        const pElId1 = formulaVariableToProjectElementId(operand1);
        const pElId2 = formulaVariableToProjectElementId(operand2);
        try {
          const pEl1 = elements[projectElements[pElId1].element];
          const pEl2 = elements[projectElements[pElId2].element];
          if ([ELEMENT_TYPE_DATE, ELEMENT_TYPE_TIME].includes(pEl1.type)) {
            const key = toFormat(pEl1);
            const value = toFormat(pEl2);
            dateDifferences[key] = [...new Set([value, ...(dateDifferences[key] || [])])];
            countIterationsToIgnore = 2; // Go after the difference that we just have detected.
          }
        } catch (error) {
          // Formula can reference a deleted element
        }
      }
    });

    const dateUnitsToShow = [];
    Object.entries(dateDifferences).forEach(([key, values]) => {
      dateUnitsToShow.push(...values.map((value) => [key, value]));
    });
    return dateUnitsToShow;
  }

  getDateUnitsState = (dateUnitsToShow, prevDateUnits, keepUnusedUnits = true) => {
    const dateUnits = keepUnusedUnits
      ? JSON.parse(JSON.stringify(prevDateUnits)) // Deep copy
      : {};
    dateUnitsToShow.forEach(([format1, format2]) => {
      dateUnits[format1] = {
        ...(dateUnits[format1] || {}),
        [format2]: getNestedValue(prevDateUnits, format1, format2)
          || ElementCalculationManager.getDateTimeUnits(format1, format2)[0],
      };
    });
    return dateUnits;
  };

  setData = (data) => {
    this.hideError();
    const dateUnitsToShow = this.getDateUnitsToShowState(data);

    this.setState((prevState) => ({
      data,
      dateUnitsToShow,
      calculation: {
        ...prevState.calculation,
        dateUnits: this.getDateUnitsState(dateUnitsToShow, prevState.calculation.dateUnits),
      },
    }), this.updateExample);
  };

  getVariables = async (search) => {
    const {
      elements, projectElements, projectElement, pages, t,
    } = this.props;

    // Filter project elements
    const filteredProjectElements = Object.values(projectElements)
      .filter((pEl) => pEl.id !== projectElement.id
        && isElementSupportedInCalculations(elements[pEl.element])
        && ((search
          && ElementUtil.formatElementName(elements[pEl.element], t).toLowerCase().includes(
            search.toLowerCase(),
          ))
            || !search)
            && (pEl.module === null || pEl.module === projectElement.module));

    // Order project elements by page and sorting
    const pElementsByPage = {};
    filteredProjectElements.forEach((pEl) => {
      const id = pages[pEl.project_page].sorting;
      if (pElementsByPage[id]) pElementsByPage[id].push(pEl);
      else pElementsByPage[id] = [pEl];
    });

    const orderedProjectElements = [];
    Object.values(pElementsByPage).forEach((pElements) => {
      orderedProjectElements.push(...pElements.sort((a, b) => {
        if (a.module !== b.module) return b.module ? -1 : 1;
        return SortUtil.sortArray(a, b);
      }));
    });

    // Format results
    return orderedProjectElements.map((pEl) => {
      const elementName = ElementUtil.formatElementName(elements[pEl.element], t);
      const pageName = formatPageTitle(pages[pEl.project_page], t);
      const moduleName = pEl.module
        ? ElementUtil.formatElementName(elements[projectElements[pEl.module].element], t)
        : '';

      return {
        value: pEl.id,
        label: `${elementName} (${pageName}${moduleName ? ` - ${moduleName}` : ''})`,
      };
    });
  };

  showError = (text, autoDismiss = true, outline = false) => {
    if (this.calculationEditor) this.calculationEditor.showError(text, autoDismiss, outline);
  };

  hideError = () => {
    if (this.calculationEditor) this.calculationEditor.hideError();
  };

  addVariable = (variable, params) => {
    if (params.action === 'select-option') {
      // const { data } = this.state;
      const {
        projectElements, projectElement, elements, t,
      } = this.props;

      // Check for circular dependency
      const pElements = getInvolvedCalculations(
        projectElement,
        Object.values(projectElements),
        Object.values(elements),
      );
      if (pElements.find((pEl) => pEl.id === variable.value)) {
        this.showError(t('project:calculations.circular-dependency'));
        return;
      }

      // Syntax check
      /* if (data.length > 0 && (!Number.isNaN(Number.parseFloat(data[data.length - 1]))
        || isVariable(data[data.length - 1], true))) {
        this.showError(t('project:calculations.syntax-error'));
        return;
      }
      // on formate le nom de la nouvelle variable
      /* data.push(
        `${CALCULATION_VARIABLE_PREFIX}${variable.value}|${variable.label}`,
      );
      this.setData(data); */
      const newVar = `${CALCULATION_VARIABLE_PREFIX}${variable.value}|${variable.label}`;
      if (this.calculationEditor) this.calculationEditor.addVariable(newVar);
    }
  };

  load = async () => {
    this.setState({ ready: false });
    let state = {
      data: [],
      calculation: {},
      ready: true,
      invalid: false,
    };
    try {
      const {
        element, elements, projectElements, t,
      } = this.props;
      const {
        formula, unit, date_units: dateUnits,
      } = element;
      const data = [];
      if (formula) {
        for (let i = 0; i < formula.length; i += 1) {
          const v = formula[i];
          if (!isVariable(v)) {
            data[i] = v;
          } else {
            try {
              const id = parseInt(v.replace(CALCULATION_VARIABLE_PREFIX, ''), 10);
              const projectElement = projectElements[id];
              // on formate les noms de variables
              data[i] = `${v}|${ElementUtil.formatElementName(elements[projectElement.element], t)}`;
            } catch (err) {
              data[i] = VAR_ERROR;
            }
          }
        }
      }

      state = {
        data,
        calculation: {
          formula,
          unit,
          dateUnits: dateUnits || {},
        },
        ready: true,
        invalid: false,
        dateUnitsToShow: this.getDateUnitsToShowState(data),
      };
      this.setState(state, () => {
        this.setData(this.state.data);
      });
    } catch (error) {
      ErrorUtil.handleCatched(this.props, error);
    }
  };

  save = async () => {
    const { t, element, patchElement } = this.props;
    const { data, calculation, dateUnitsToShow } = this.state;
    try {
      if (data.includes(VAR_ERROR)) {
        this.showError(t('project:calculations.remove-unknown-variables'), false, true);
        return;
      }
      const { id } = element;
      const { unit, dateUnits } = calculation;
      // Calculation.dateUnits can contain unused units
      const effectiveDateUnits = this.getDateUnitsState(dateUnitsToShow, dateUnits, false);

      if (element.unit !== unit || !isEqual(element.date_units, effectiveDateUnits)
          || !isEqual(element.formula, this.formula)) {
        await patchElement(id, {
          formula: this.formula,
          unit,
          date_units: effectiveDateUnits,
        });
      }
      this.modal.hide();
    } catch (error) {
      switch (error.name) {
        case 'SyntaxError':
          this.showError(t('project:calculations.invalid-expression'), false, true);
          break;
        case 'DateSyntaxError':
          this.showError(t('project:calculations.date-invalid-expression'), false, true);
          break;
        default:
          ErrorUtil.handleCatched(this.props, error);
          break;
      }
    }
  };

  formatExample = () => {
    const { t } = this.props;
    const { example, calculation } = this.state;
    const { unit } = calculation;
    if (!example) {
      return t('project:no.formula');
    }
    if (example.value === undefined) {
      return <span className="text-red font-weight-bold">{example.formula}</span>;
    }
    return (
      <>
        {example.formula.map((item, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <React.Fragment key={index}>
            {' '}
            {
              item.type === 'variable'
                ? <strong><FormattedValue value={item.value} /></strong>
                : item.value
            }
            {' '}
          </React.Fragment>
        ))}
        {' = '}
        <strong><FormattedValue value={example.value} /></strong>
        {unit && ` ${unit}`}
      </>
    );
  };

  onChangeDateUnit = (e, format1, format2) => {
    const { data } = this.state;
    const { value } = e.target;
    this.setState((prevState) => {
      const { calculation: prevCalculation } = prevState;
      const { dateUnits: prevDateUnits } = prevCalculation;
      return {
        calculation: {
          ...prevCalculation,
          dateUnits: {
            ...prevDateUnits,
            [format1]: {
              ...(prevDateUnits[format1] || {}),
              [format2]: value,
            },
          },
        },
      };
    }, () => this.setData(data));
  };

  renderDateTimeUnitSelector = (format1, format2) => {
    const { t } = this.props;
    const { calculation, dateUnitsToShow } = this.state;
    const key = `${format1}-${format2}`;
    const availableUnits = ElementCalculationManager.getDateTimeUnits(format1, format2);

    const getFormatStr = (format) => {
      const baseKey = 'project:form';
      let specificKey = '';
      switch (format) {
        case ELEMENT_TYPE_TIME:
          specificKey = 'add-time';
          break;
        case DATE_TIME:
          specificKey = 'add-date-time';
          break;
        case DATE_YEAR:
          specificKey = 'add-date-year';
          break;
        case DATE_MONTH_YEAR:
          specificKey = 'add-date-month-year';
          break;
        case DATE_FULL:
        default:
          specificKey = 'add-date';
          break;
      }
      return `${baseKey}.${specificKey}`;
    };
    const label = dateUnitsToShow.length > 1 ? t('project:modal.calculation.unit.date-differences', {
      format1: t(getFormatStr(format1)),
      format2: t(getFormatStr(format2)),
    }) : undefined;
    const marginTop = label ? 'mt-2' : 'mt-1';

    return (
      <div key={key} className={`row ${marginTop} mb-2`}>
        <div className="col-12">
          <LabeledSelect
            label={label}
            name={`${key}-unit`}
            required
            disabled={availableUnits.length < 2}
            value={getNestedValue(calculation, 'dateUnits', format1, format2)}
            colSelectClassName="col-auto"
            onChange={(e) => this.onChangeDateUnit(e, format1, format2)}
          >
            {
              availableUnits.map((unit) => (
                <option
                  key={unit}
                  value={unit}
                >
                  {t(`project:modal.calculation.unit.${unit}`)}
                </option>
              ))
            }
          </LabeledSelect>
        </div>
      </div>
    );
  };

  render() {
    const {
      t, children, element, project,
    } = this.props;
    const {
      ready, calculation, data, dateUnitsToShow,
    } = this.state;

    return (
      <LicenseChecker
        limName="can_add_calculation"
        limitations={project.limitations}
        licMsgModalChildren={children}
      >
        <NewModal
          trigger={children}
          title={t('project:modal.calculation.title')}
          xlHeader={ElementUtil.formatElementName(element, t)}
          size="md"
          type={2}
          onLoad={this.load}
          footer={(
            <button
              type="button"
              className="btn btn-newblue-1 text-white px-3"
              onClick={this.save}
            >
              {t('common:button.validate')}
            </button>
          )}
          ref={(modal) => {
            this.modal = modal;
          }}
        >
          {
            ready ? (
              <div>
                <div className="row justify-content-between align-items-baseline">
                  <div className="col-7 mb-0 pb-0">
                    {t('project:modal.calculation.formula')}
                  </div>
                  <div className="col-5">
                    <div className="calculation-toolbar">
                      <AsyncSelect
                        className="react-select calculation-variable-select"
                        classNamePrefix="react-select"
                        placeholder={t('project:modal.calculation.insert-variable')}
                        noOptionsMessage={() => t('project:modal.calculation.there-is-no-variable')}
                        loadOptions={this.getVariables}
                        onChange={this.addVariable}
                        menuPlacement="auto"
                        hideSelectedOption
                        openMenuOnFocus
                        closeMenuOnSelect
                        defaultOptions
                        value={null}
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12 align-middle pt-1">
                    <CalculationEditor
                      formula={data}
                      onChange={(value) => { this.setData(value); }}
                      t={t}
                      ref={(ref) => { this.calculationEditor = ref; }}
                    />
                  </div>
                </div>
                <div className="row mt-2 font-italic text-gray">
                  <div className="col-12">
                    {t('project:modal.calculation.example')}
                    &nbsp;
                    <span className="calculation-tex-result">
                      {this.formatExample()}
                    </span>
                  </div>
                </div>
                {Boolean(dateUnitsToShow.length) && (
                  <div className="row mt-3">
                    <div className="col-12">
                      <span>
                        {dateUnitsToShow.length === 1 && t('project:modal.calculation.unit.single-date-diff')}
                        {dateUnitsToShow.length > 1 && t('project:modal.calculation.unit.multiple-date-diff')}
                      </span>
                      <Help iconClassName="ml-2">
                        {t('project:modal.calculation.unit.help')}
                      </Help>
                    </div>
                  </div>
                )}
                {dateUnitsToShow.map(([format1, format2]) => (
                  this.renderDateTimeUnitSelector(format1, format2)
                ))}
                <div className="row mt-4 mb-2">
                  <div className="col-12">
                    <LabeledInput
                      className="mb-0"
                      label={t('project:modal.calculation.unit.main')}
                      name="calculation-unit"
                      type="text"
                      placeholder={t('error:placeholder.calculation-unit')}
                      colInputClassName="col-auto"
                      defaultValue={calculation ? calculation.unit : ''}
                      onChange={(e) => {
                        const { value } = e.target;
                        this.setState((prevState) => ({
                          calculation: {
                            ...prevState.calculation,
                            unit: value,
                          },
                        }));
                      }}
                    />
                    <div className="help-text">
                      {t('project:modal.calculation.unit.main-info')}
                    </div>
                  </div>
                </div>
                <div className="mb-4" />
              </div>
            ) : (
              <CardLoader />
            )
          }
        </NewModal>
      </LicenseChecker>
    );
  }
}


export default ElementCalculationManager;
