import { createReducer } from 'redux-starter-kit';
import { resourceTopicsActions } from '../actions';
import getStdDbModelReduxMapping from './std-db-model-reducers';

export const resourceTopicsInitialState = {};

const resourceTopicsReducer = createReducer(
  resourceTopicsInitialState,
  getStdDbModelReduxMapping(resourceTopicsActions),
);

export default resourceTopicsReducer;
