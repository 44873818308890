import React, {
  useEffect, useState, useRef, useMemo,
} from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Mutex } from 'async-mutex';
import api from '../api';
import { userFilesActions, usersActions } from '../redux/actions';
import ButtonConfirm from './ButtonConfirm';
import { DelMsgModal } from './MessageModal';
import NewTooltip from './NewTooltip';
import { CardLoader } from './Loader';
import Help from './Help';
import FAQLink from './FAQLink';
import useError from '../utils/HookErrorUtil';
import useToast from '../utils/HookToast';
import FileUtil from '../utils/FileUtil';
import downloadEndpoint from '../utils/downloadEndpoint';

const UPLOAD_LIMIT_EXCEEDED = 'upload-limit-exceeded';
const UPLOAD_CSV_ONLY = 'upload-csv-only';

function CsvFileIcon(props) {
  const {
    userFile, fileName, onClick, selectedFileIds, disabled,
  } = props;

  const [isSelected, setIsSelected] = useState(false);

  const checkIfSelected = () => setIsSelected(selectedFileIds.includes(userFile.id));

  useEffect(() => {
    checkIfSelected();
  }, [selectedFileIds]);

  const getSelectedFileIds = () => {
    const userFileId = userFile.id;
    let newSelectedFileIds = [];
    const index = selectedFileIds.indexOf(userFileId);
    if (index > -1) {
      newSelectedFileIds = selectedFileIds.filter((id) => id !== userFileId);
      setIsSelected(false);
    } else {
      newSelectedFileIds = [...selectedFileIds, userFileId];
      setIsSelected(true);
    }
    return newSelectedFileIds;
  };

  const onButtonClick = () => {
    const newSelectedFileIds = getSelectedFileIds(userFile);
    onClick(newSelectedFileIds);
  };

  return (
    <button
      className="btn no-button-style no-shadow"
      disabled={disabled}
      onClick={() => onButtonClick(userFile)}
    >
      <div className={`text-center user-file px-3 py-1 ${isSelected ? 'selected-user-file' : ''}`}>
        <div className="py-1">
          <FontAwesomeIcon
            icon={['fal', 'file-csv']}
            className="fa-2x"
          />
        </div>
        <div className={`small ${isSelected ? '' : 'text-gray-dark'}`}>
          {fileName}
        </div>
      </div>
    </button>
  );
}

CsvFileIcon.propTypes = {
  userFile: PropTypes.shape().isRequired,
  fileName: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  selectedFileIds: PropTypes.arrayOf(PropTypes.number).isRequired,
  disabled: PropTypes.bool,
};

CsvFileIcon.defaultProps = {
  onClick: () => {},
  disabled: false,
};

function UploadsManager(props) {
  const { user, admin } = props;
  const { t } = useTranslation();
  const { handleCatched } = useError();
  const { success } = useToast();
  const dispatch = useDispatch();
  const mutex = useRef(null);

  const [files, setFiles] = useState([]);
  const [selectedFileIds, setSelectedFileIds] = useState([]);
  const [maxSizeReached, setMaxSizeReached] = useState(false);
  const [showFileSelect, setShowFileSelect] = useState(false);
  const [loading, setLoading] = useState(false);

  const usrFls = useSelector((state) => state.userFiles);
  const userFiles = useMemo(() => Object.values(usrFls), [usrFls]);

  const addUserFile = async (data) => dispatch(userFilesActions.create(data, { admin }));
  const deleteUserFile = async (id) => dispatch(userFilesActions.remove(id, { admin }));
  const fetchUserFiles = async (userId) => dispatch(userFilesActions.list(
    { user: userId, admin },
    { pagination: 'no' },
  ));
  const patchUser = async (id, data) => dispatch(usersActions.patch(id, data, { admin }));

  let maxFileUploads = 0;
  if (user) maxFileUploads = user.limitations.max_file_uploads;

  const fetchData = async () => {
    try {
      setLoading(true);
      await fetchUserFiles(user.id);
    } catch (error) {
      handleCatched(props, error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
    mutex.current = new Mutex();
  }, []);

  const submitFile = async (newFile) => {
    const formData = new FormData();
    formData.append('file', newFile);
    formData.append('user', user.id);
    await addUserFile(formData);
  };

  const submitFiles = async (newFiles) => {
    const formData = new FormData();
    formData.append('user', user.id);
    newFiles.forEach((file) => formData.append('files', file));
    await api.create('bulk-upload-user-files', formData, { admin });
    await fetchUserFiles(user.id);
  };

  const validate = () => {
    if (Array.isArray(files)) {
      if (files.length > maxFileUploads) return [false, UPLOAD_LIMIT_EXCEEDED];
      if (!files.reduce((prevValue, file) => prevValue && FileUtil.isCsvFile(file), true)) {
        return [false, UPLOAD_CSV_ONLY];
      }
    }
    return [true, ''];
  };

  const onSubmit = async () => {
    if (!mutex.current.isLocked()) {
      const release = await mutex.current.acquire();
      try {
        setLoading(true);
        const [isValid, error] = validate();
        if (!isValid) throw new Error(t(`error:error.${error}`, { limit: maxFileUploads }));
        if (files.length === 1) {
          await submitFile(files[0]);
        } else {
          await submitFiles(files);
        }
        success('error:valid.success');
      } catch (error) {
        handleCatched(props, error);
      } finally {
        setFiles([]);
        setShowFileSelect(false);
        release();
        setLoading(false);
      }
    }
  };

  const deleteFiles = async (fileIds) => {
    await api.create('bulk-delete-user-files', { ids: fileIds }, { admin });
    await fetchUserFiles(user.id);
  };

  const onDelete = async () => {
    if (!mutex.current.isLocked()) {
      const release = await mutex.current.acquire();
      try {
        setLoading(true);
        if (selectedFileIds.lenght === 1) {
          await deleteUserFile(selectedFileIds[0]);
        } else {
          await deleteFiles(selectedFileIds);
        }
        success('error:valid.deleted');
      } catch (error) {
        handleCatched(props, error);
      } finally {
        setSelectedFileIds([]);
        release();
        setLoading(false);
      }
    }
  };

  const onDownload = async () => {
    if (selectedFileIds.length > 0 && !mutex.current.isLocked()) {
      const release = await mutex.current.acquire();
      try {
        setLoading(true);
        const responseType = selectedFileIds.length === 1 ? '' : 'arraybuffer';
        await downloadEndpoint(
          'download-user-files', 'post', { admin }, { ids: selectedFileIds }, '', responseType,
        );
        success('error:valid.success');
      } catch (error) {
        handleCatched(error, props);
      } finally {
        setSelectedFileIds([]);
        release();
        setLoading(false);
      }
    }
  };

  const onFileInputChange = (e) => {
    const newFiles = Object.values(e.target.files);
    const correctFileSizes = newFiles.reduce((prevValue, file) => {
      const hasValidSize = FileUtil.checkFileSize(file);
      return prevValue && hasValidSize;
    }, true);
    setFiles(newFiles);
    setMaxSizeReached(!correctFileSizes);
  };

  const onSwitchChange = async (e) => {
    if (!mutex.current.isLocked()) {
      const release = await mutex.current.acquire();
      try {
        const value = !e.target.checked;
        await patchUser(user.id, { can_use_files_storage: value });
        success('error:valid.success');
      } catch (error) {
        handleCatched(props, error);
      } finally {
        release();
      }
    }
  };

  const { can_use_files_storage: canUseFilesStorage } = user;

  return (
    <div>
      {admin ? (
        <div className="custom-control custom-switch py-3">
          <input
            className="custom-control-input"
            type="checkbox"
            id={`toggle-right-to-upload-user-${user.id}`}
            checked={canUseFilesStorage}
            disabled={!admin}
            onChange={onSwitchChange}
          />
          <label
            className="custom-control-label"
            htmlFor={`toggle-right-to-upload-user-${user.id}`}
          >
            {t('user:uploads.enable-uploads')}
          </label>
        </div>
      ) : (
        <div className="py-3 uploads-tab-subtitle">
          {t(`user:uploads.${canUseFilesStorage ? 'subtitle' : 'contact-us'}`)}
        </div>
      )}
      {loading && (<CardLoader />)}
      {(canUseFilesStorage || admin) && (
        <div>
          <div>
            <button
              className="btn btn-newblue-1"
              onClick={() => setShowFileSelect(true)}
            >
              {t('user:uploads.upload-new-files')}
            </button>
          </div>
          {showFileSelect && (
            <>
              <div className="mt-3 py-2 text-gray-dark">
                {t('user:uploads.upload-csv-utf-8')}
                <Help
                  interactive
                  iconClassName="ml-1"
                >
                  <FAQLink
                    text="common:discover"
                    articleId={14}
                  />
                </Help>
              </div>
              <div className="d-inline-flex">
                <form
                  id="file-upload-form"
                  className={files.length ? 'mr-3' : ''}
                >
                  <label
                    htmlFor="file_input"
                    className="mr-3"
                  >
                    {t('user:uploads.select-files')}
                  </label>
                  <input
                    type="file"
                    id="file_input"
                    name="file-input"
                    multiple
                    accept=".csv"
                    onChange={onFileInputChange}
                    disabled={!canUseFilesStorage && !admin}
                  />
                </form>
                {files.length ? (
                  <div className="d-flex align-items-center">
                    <button
                      disabled={!files.length || maxSizeReached}
                      onClick={onSubmit}
                      className="btn btn-newturquoise-1"
                    >
                      {t('user:uploads.upload')}
                    </button>
                    {maxSizeReached ? (
                      <div className="text-red ml-2">
                        {t('user:uploads.max-size-reached')}
                      </div>
                    ) : null}
                  </div>
                ) : null}
              </div>
            </>
          )}
        </div>
      )}
      {userFiles.length ? (
        <div className="my-4">
          <h5 className="mb-2">
            {t('user:uploads.uploaded-files')}
          </h5>
          <div
            className="d-inline-flex py-3 border w-100"
            style={{ flexWrap: 'wrap' }}
          >
            {userFiles.map((userFile) => (
              <CsvFileIcon
                key={userFile.id}
                userFile={userFile}
                fileName={userFile.file_name}
                onClick={(newValues) => setSelectedFileIds(newValues)}
                selectedFileIds={selectedFileIds}
                disabled={!canUseFilesStorage && !admin}
              />
            ))}
          </div>
          {(canUseFilesStorage || admin) && (
            <div className="d-flex mt-2">
              <NewTooltip
                key="delete-files-tooltip"
                content={t('user:uploads.no-file-selected')}
                disabled={Boolean(selectedFileIds.length)}
              >
                <DelMsgModal
                  message={(
                    <span>
                      <b>
                        {t('common:caution')}
                        &nbsp;!
                      </b>
                      <br />
                      <br />
                      {t('user:uploads.delete-warning')}
                    </span>
                  )}
                  onValidate={onDelete}
                >
                  <ButtonConfirm>
                    <button
                      className="btn btn-primary btn-selected-files mr-1"
                      disabled={!selectedFileIds.length}
                    >
                      {t('user:uploads.delete-selected-files')}
                    </button>
                  </ButtonConfirm>
                </DelMsgModal>
              </NewTooltip>
              <NewTooltip
                key="download-files-tooltip"
                content={t('user:uploads.no-file-selected')}
                disabled={Boolean(selectedFileIds.length)}
              >
                <span>
                  <button
                    className="btn btn-primary btn-selected-files ml-1"
                    onClick={onDownload}
                    disabled={!selectedFileIds.length}
                  >
                    {t('user:uploads.download-selected-files')}
                  </button>
                </span>
              </NewTooltip>
            </div>
          )}
        </div>
      ) : null}
    </div>
  );
}

UploadsManager.propTypes = {
  user: PropTypes.shape().isRequired,
  admin: PropTypes.bool,
};

UploadsManager.defaultProps = {
  admin: false,
};

export default UploadsManager;
