import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import memoize from 'memoize-one';
import { nsOptions } from '../i18n';
import CalculationPreview from './CalculationPreview';
import NewTooltip from './NewTooltip';
import ElementUtil from '../utils/ElementUtil';
import { isVariable } from '../utils/calculations';
import { CALCULATION_VARIABLE_PREFIX } from '../constants';


const mapStateToProps = (state, ownProps) => ({
  element: state.elements[ownProps.projectElement.element],
  elements: state.elements,
  projectElements: state.projectElements,
});

@connect(mapStateToProps)
@withTranslation('', nsOptions)
class ElementCalculationInfo extends Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
    element: PropTypes.shape().isRequired,
    elements: PropTypes.shape().isRequired,
    projectElements: PropTypes.shape().isRequired,
  };

  constructor(props) {
    super(props);
    this.loadDOM = memoize((element, projectElements, elements) => (
      this.load(element, Object.values(projectElements), elements)
    ));
  }

  load(element, projectElements, elements) {
    let res = {};
    try {
      const { t } = this.props;
      const { formula } = element;
      if (formula && formula.length > 0) {
        const variables = projectElements.filter((pEl) => formula.filter((v) => isVariable(v)).map((v) => v.replace(CALCULATION_VARIABLE_PREFIX, '').includes(pEl.id)));
        const content = formula.map((v) => (isVariable(v)
          ? `${v}|${ElementUtil.formatElementName(elements[variables.find((val) => val.id === Number(v.replace(CALCULATION_VARIABLE_PREFIX, ''))).element], t)}` : v));
        res = {
          show: true,
          theme: undefined,
          content,
        };
      } else {
        res = { show: false };
      }
    } catch (error) {
      if (error.name === 'NotFound') {
        res = {
          show: true,
          theme: 'red',
          content: ['Error: please fix your calculation.'],
        };
      } else {
        res = { show: false };
      }
    }
    return res;
  }

  render() {
    const {
      t, projectElements, elements, element,
    } = this.props;
    const res = this.loadDOM(element, projectElements, elements);
    return res.show ? (
      <div className="mr-3">
        <NewTooltip
          content={(
            <CalculationPreview formula={res.content} t={t} />
          )}
          theme={res.theme ? res.theme : undefined}
          distance={0}
        >
          <div className="calculation-info">
            {t('project:calculations.your')}
            &nbsp;
            <span className="text-underline">
              {t('project:calculations.calculation')}
            </span>
            &nbsp;
            {t('project:calculations.will-appear-here')}
          </div>
        </NewTooltip>
      </div>
    ) : null;
  }
}

export default ElementCalculationInfo;
