import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Label } from 'reactstrap';
import { elementsActions } from '../redux/actions';
import NewModal from './NewModal';
import LabeledChoice from './LabeledChoice';
import { childrenPropTypes } from '../utils/generic-prop-types';
import {
  ELEMENT_DIRECT_IDENTIFIER, ELEMENT_INDIRECT_IDENTIFIER, ELEMENT_NOT_IDENTIFIER,
} from '../constants';
import useError from '../utils/HookErrorUtil';
import Checkbox from './Checkbox';
import { DataDisclosureMsgModal } from './MessageModal';
import NewTooltip from './NewTooltip';
import IdentifierIcon from './IdentifierIcon';

const IdentifierChoiceLi = (props) => {
  const { t } = useTranslation();
  const { first, transKey, selected } = props;
  return (
    <li className={`mb-2${selected ? ' text-newblue-1' : ''} ${first ? ' mt-1' : ''}`}>
      {selected && (
        <span>
          {t('project:identifier-modal.selection-tag')}
        </span>
      )}
      <u>
        {t(`project:identifier-modal.${transKey}.choice`)}
      </u>
      <Trans i18nKey={`project:identifier-modal.${transKey}.explanation`} />
    </li>
  );
};

IdentifierChoiceLi.propTypes = {
  first: PropTypes.bool,
  transKey: PropTypes.string.isRequired,
  selected: PropTypes.bool.isRequired,
};

IdentifierChoiceLi.defaultProps = {
  first: false,
};

const initialState = {
  confirmChoice: true,
  validateBtnDisabled: false,
  confirmChoiceDisabled: true,
};

const ElementIdentifierManager = (props) => {
  const { element, children, admin } = props;
  const { t } = useTranslation();
  const { handleCatched } = useError();

  const [identifierChoice, setIdentifierChoice] = useState(element.identification_level);
  const [confirmChoice, setConfirmChoice] = useState(initialState.confirmChoice);
  const [validateBtnDisabled, setValidateBtnDisabled] = useState(initialState.validateBtnDisabled);
  const [confirmChoiceDisabled, setConfirmChoiceDisabled] = useState(
    initialState.confirmChoiceDisabled,
  );

  const modal = useRef();
  const dataDisclosureModal = useRef();

  const dispatch = useDispatch();
  const patchElement = async (id, data) => dispatch(elementsActions.patch(id, data, { admin }));

  const save = async () => {
    if (!validateBtnDisabled) {
      try {
        if (element.identification_level !== identifierChoice) {
          await patchElement(element.id, { identification_level: identifierChoice });
        }
        if (modal.current) {
          await modal.current.hide();
        }
      } catch (error) {
        handleCatched(
          { ...props, getDiscMsgModalManager: () => dataDisclosureModal.current },
          error,
        );
      }
    }
  };

  const resetState = () => {
    setIdentifierChoice(element.identification_level);
    setConfirmChoice(initialState.confirmChoice);
    setValidateBtnDisabled(initialState.validateBtnDisabled);
    setConfirmChoiceDisabled(initialState.confirmChoiceDisabled);
  };

  const getChoice = (text, value) => ({
    text: (
      <>
        <span className={identifierChoice === value ? 'font-weight-bold' : ''}>
          {text}
        </span>
        {value !== ELEMENT_NOT_IDENTIFIER && (
          <IdentifierIcon
            identificationLevel={value}
            className="identifier-icon ml-2 text-primary"
          />
        )}
      </>
    ),
    value,
  });

  const validateBtn = (
    <button
      type="button"
      className="btn btn-newblue-1 text-white"
      onClick={save}
    >
      {t('common:button.validate')}
    </button>
  );

  return (
    <>
      <NewModal
        trigger={children}
        title={t('project:identifier-modal.title')}
        xlHeader={element.name}
        xlHeaderPlacement="top"
        type={2}
        onLoad={resetState}
        footer={confirmChoice ? validateBtn : (
          <NewTooltip content={t('project:identifier-modal.validation-tooltip')}>
            {validateBtn}
          </NewTooltip>
        )}
        ref={modal}
      >
        <LabeledChoice
          name="identifier-status"
          label={t('project:identifier-modal.choose-status')}
          className="mb-4"
          colLabelClassName="col-12 pb-2"
          required
          orientation="vertical"
          choices={[
            getChoice(
              t('project:identifier-modal.status-not-identifier.choice'),
              ELEMENT_NOT_IDENTIFIER,
            ),
            getChoice(
              t('project:identifier-modal.status-indirect-identifier.choice'),
              ELEMENT_INDIRECT_IDENTIFIER,
            ),
            getChoice(
              t('project:identifier-modal.status-direct-identifier.choice'),
              ELEMENT_DIRECT_IDENTIFIER,
            ),
          ]}
          value={identifierChoice}
          onChange={(e) => {
            const { value } = e.target;
            setIdentifierChoice(value);
            setConfirmChoiceDisabled(false);
            setConfirmChoice(false);
            setValidateBtnDisabled(true);
          }}
        />
        <Label className="mb-1">
          {t('project:identifier-modal.explanation')}
        </Label>
        <ul className="mb-5 pl-5 text-gray identifier-explanation">
          <IdentifierChoiceLi
            first
            transKey="status-not-identifier"
            selected={identifierChoice === ELEMENT_NOT_IDENTIFIER}
          />
          <IdentifierChoiceLi
            transKey="status-indirect-identifier"
            selected={identifierChoice === ELEMENT_INDIRECT_IDENTIFIER}
          />
          <IdentifierChoiceLi
            transKey="status-direct-identifier"
            selected={identifierChoice === ELEMENT_DIRECT_IDENTIFIER}
          />
        </ul>
        <Checkbox
          className="text-red font-weight-bold identifier-confirm"
          required
          checked={confirmChoice}
          disabled={confirmChoiceDisabled}
          onChange={(e) => {
            const { checked } = e.target;
            setConfirmChoice(checked);
            setValidateBtnDisabled(!checked);
          }}
        >
          {t('project:identifier-modal.confirm-choice')}
        </Checkbox>
      </NewModal>
      <DataDisclosureMsgModal
        t={t}
        innerRef={dataDisclosureModal}
      />
    </>
  );
};

ElementIdentifierManager.propTypes = {
  admin: PropTypes.bool,
  children: childrenPropTypes().isRequired,
  element: PropTypes.shape().isRequired,
};

ElementIdentifierManager.defaultProps = {
  admin: true,
};

export default ElementIdentifierManager;
