import { createReducer } from 'redux-starter-kit';
import { stripePlansActions } from '../actions';
import getStdDbModelReduxMapping from './std-db-model-reducers';

export const stripePlansInitialState = {};

const stripePlansReducer = createReducer(
  stripePlansInitialState,
  getStdDbModelReduxMapping(stripePlansActions),
);

export default stripePlansReducer;
