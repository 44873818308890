import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { withTranslation, Trans } from 'react-i18next';
import history from '../history';
import { nsOptions } from '../i18n';
import { childrenPropTypes } from '../utils/generic-prop-types';
import NewModal from './NewModal';
import { isBrowserView } from './BrowserView';
import StdLicenseMessage from './StdLicenseMessage';


@withTranslation('', nsOptions)
class MessageModal extends Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
    children: childrenPropTypes(),
    message: childrenPropTypes().isRequired,
    showCancelButton: PropTypes.bool,
    onValidate: PropTypes.func,
    onCancel: PropTypes.func,
    validateLabel: PropTypes.string,
    cancelLabel: PropTypes.string,
    validateBtnBgColorClass: PropTypes.string,
    validateBtnTextColorClass: PropTypes.string,
    triggerType: PropTypes.oneOf(['button', 'checkbox', 'radio']),
    triggerValue: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
    triggerCondition: PropTypes.func,
    triggerFuncName: PropTypes.string,
    undoTriggerChange: PropTypes.func,
    alwaysTriggerOverridenFunc: PropTypes.bool,
    size: PropTypes.oneOf([
      'sm',
      'md',
      'lg',
      'xl',
    ]),
    modalZIndex: PropTypes.number,
    modalBodyClassName: PropTypes.string,
  };

  static defaultProps = {
    children: null,
    showCancelButton: false,
    onValidate: () => {},
    onCancel: () => {},
    validateLabel: 'common:button.ok',
    cancelLabel: 'common:button.cancel',
    validateBtnBgColorClass: 'btn-newred-2',
    validateBtnTextColorClass: 'text-white',
    triggerType: 'button',
    triggerValue: undefined,
    triggerCondition: undefined,
    triggerFuncName: undefined,
    undoTriggerChange: undefined,
    alwaysTriggerOverridenFunc: null,
    size: isBrowserView() ? 'sm' : 'md',
    modalZIndex: undefined,
    modalBodyClassName: '',
  };

  static getTriggerFuncName(triggerType) {
    switch (triggerType) {
      case 'checkbox': return 'onChange';
      case 'radio': return 'onChange';
      default: return undefined;
    }
  }

  static getTriggerCondition(triggerType, triggerValue) {
    switch (triggerType) {
      case 'checkbox': return (e) => e.target.checked;
      case 'radio': return (e) => (Array.isArray(triggerValue)
        ? triggerValue.includes(e.target.value) : e.target.value === triggerValue);
      default: return undefined;
    }
  }

  constructor(props) {
    super(props);
    this.modal = null;
  }

  show = () => {
    if (this.modal) this.modal.show();
  };

  hide = () => {
    if (this.modal) this.modal.hide();
  };

  validate = () => {
    if (this.modal) this.modal.hide();
    this.props.onValidate();
  };

  cancel = () => {
    if (this.modal) this.modal.hide();
    this.props.onCancel();
  };

  render() {
    const {
      t, children, message, showCancelButton, validateLabel, cancelLabel, validateBtnBgColorClass,
      validateBtnTextColorClass, triggerType, triggerValue, triggerCondition, triggerFuncName,
      undoTriggerChange, alwaysTriggerOverridenFunc, size, modalZIndex, modalBodyClassName,
    } = this.props;
    const effectiveTriggerFuncName = triggerFuncName
      || MessageModal.getTriggerFuncName(triggerType);
    const effectiveTriggerCondition = triggerCondition
      || MessageModal.getTriggerCondition(triggerType, triggerValue);

    return (
      <NewModal
        trigger={children}
        triggerFuncName={effectiveTriggerFuncName}
        triggerCondition={effectiveTriggerCondition}
        undoTriggerChange={undoTriggerChange}
        alwaysTriggerOverridenFunc={alwaysTriggerOverridenFunc}
        size={size}
        type={2}
        noHeader
        extraClass="message-modal"
        extraBackdropClass="message-modal"
        modalZIndex={modalZIndex}
        modalBodyClassName={modalBodyClassName}
        footer={(
          <div className="row">
            <button
              type="button"
              className={`btn ${validateBtnBgColorClass} ${validateBtnTextColorClass} px-3`}
              onClick={this.validate}
            >
              { t(validateLabel) }
            </button>
            {
              showCancelButton ? (
                <button
                  type="button"
                  className="btn btn-link ml-2"
                  onClick={this.cancel}
                >
                  { t(cancelLabel) }
                </button>
              ) : null
            }
          </div>
        )}
        ref={(modal) => {
          this.modal = modal;
        }}
      >
        { message }
      </NewModal>
    );
  }
}


// eslint-disable-next-line react/prefer-stateless-function
class DelMsgModal extends React.Component { // For deletion warning messages
  static propTypes = {
    children: childrenPropTypes().isRequired,
  };

  render() {
    const { children } = this.props;
    return (
      <MessageModal
        validateLabel="common:button.confirm"
        validateBtnBgColorClass="btn-newred-2"
        showCancelButton
        modalBodyClassName="text-center"
        {...this.props}
      >
        {children}
      </MessageModal>
    );
  }
}


class LicMsgModal extends Component { // For license warning messages
  static propTypes = {
    children: childrenPropTypes(),
    innerRef: PropTypes.shape(),
    onValidate: PropTypes.func,
    onCancel: PropTypes.func,
  };

  static defaultProps = {
    children: null,
    innerRef: undefined,
    onValidate: () => {},
    onCancel: () => {},
  };

  onValidate = () => {
    this.props.onValidate();
    if (history) history.push('/dashboard/settings/license');
  }

  onCancel = () => {
    this.props.onCancel();
  }

  show = () => {
    const { innerRef } = this.props;
    if (innerRef) innerRef.show();
  };

  render() {
    const { children, innerRef } = this.props;

    return (
      <MessageModal
        {...this.props}
        message={<StdLicenseMessage />}
        validateLabel={`common:button.${history ? 'show-my-plan' : 'ok'}`}
        validateBtnBgColorClass="btn-newblue-1"
        onValidate={this.onValidate}
        cancelLabel="common:button.no-thanks"
        onCancel={this.onCancel}
        showCancelButton={Boolean(history)}
        size="md"
        ref={innerRef}
      >
        { children }
      </MessageModal>
    );
  }
}


@withTranslation('', nsOptions)
class DataDisclosureMsgModal extends Component { // For data disclosure message
  static propTypes = {
    t: PropTypes.func.isRequired,
    children: childrenPropTypes(),
    innerRef: PropTypes.shape(),
  };

  static defaultProps = {
    children: null,
    innerRef: undefined,
  };

  show = () => {
    const { innerRef } = this.props;
    if (innerRef) innerRef.show();
  };

  render() {
    const { t, children, innerRef } = this.props;

    return (
      <MessageModal
        {...this.props}
        message={(
          <div>
            <div className="text-center">
              <b>
                {`${t('common:caution')}${t('common:colon')} ${t('error:warning.data-disclosure-risk-1')}`}
              </b>
            </div>
            <br />
            <br />
            <p>
              {t('error:warning.data-disclosure-risk-2')}
            </p>
            <p>
              <Trans i18nKey="error:warning.data-disclosure-risk-3" />
            </p>
          </div>
        )}
        size="md"
        ref={innerRef}
      >
        { children }
      </MessageModal>
    );
  }
}

export {
  MessageModal,
  DelMsgModal,
  LicMsgModal,
  DataDisclosureMsgModal,
};
