import PropTypes from 'prop-types';
import React from 'react';
import TabletView from './TabletView';
import DeviceUnsupported from './DeviceUnsupported';

const TabletUnsupported = (props) => (
  <TabletView>
    <DeviceUnsupported className="py-5">
      { props.children || 'common:mobile-and-tablet-unsupported' }
    </DeviceUnsupported>
  </TabletView>
);

TabletUnsupported.propTypes = {
  children: PropTypes.string,
};

TabletUnsupported.defaultProps = {
  children: '',
};

export default TabletUnsupported;
