import buildStdDbModelActions from './std-db-model-actions';
import { inclusionsActions } from './index';
import api from '../../api';
import { ENTRY_TYPE_DATE, ENTRY_TYPE_MEASUREMENT, ENTRY_TYPE_TIME } from '../../constants';

const endpoint = 'project-entries';
const stdDbModelActions = buildStdDbModelActions(endpoint);

const shouldUpdateEntries = (type, projectElementId, getState) => (
  // In theses cases, a calculation result might have changed.
  [ENTRY_TYPE_MEASUREMENT, ENTRY_TYPE_DATE, ENTRY_TYPE_TIME].includes(type)
    || Object.values(getState().elementLinks).findIndex((link) => (
      link.source === projectElementId
    )) >= 0
);

const updateProjectEntries = async (dispatch, inclusionId, type, projectElementId, getState) => {
  if (!shouldUpdateEntries(type, projectElementId, getState)) return;
  try {
    const response = await api.list(
      'project-entries',
      {
        inclusion: inclusionId,
      },
    );
    dispatch(stdDbModelActions.listSuccess(response.results));
  } catch (error) {
    console.error(error);
  }
};

const resynchInclusion = async (dispatch, inclusionId, params = {}) => (
  dispatch(inclusionsActions.read(inclusionId, params))
);

const updateInclusionAndEntries = async (dispatch, inclusionId, type, projectElementId,
  getState) => (
  Promise.all([
    resynchInclusion(dispatch, inclusionId),
    updateProjectEntries(dispatch, inclusionId, type, projectElementId, getState),
  ])
);

const create = (data, params = {}) => async (dispatch, getState) => {
  const res = await api.create(endpoint, data, params);
  const { inclusion, project_element: projectElementId } = res;
  dispatch(stdDbModelActions.createSuccess(res));
  await updateInclusionAndEntries(dispatch, inclusion, res.type, projectElementId, getState);
  return res;
};

const patch = (id, data, params = {}) => async (dispatch, getState) => {
  const res = await api.partial_update(endpoint, id, data, params);
  const { inclusion, project_element: projectElementId } = res;
  dispatch(stdDbModelActions.patchSuccess(res));
  await updateInclusionAndEntries(dispatch, inclusion, res.type, projectElementId, getState);
  return res;
};

const remove = (id, params = {}) => async (dispatch, getState) => {
  const { projectEntries } = getState();
  const projectEntry = projectEntries[id];
  const { inclusion, type, project_element: projectElementId } = projectEntry;
  await api.delete(endpoint, id, params);
  dispatch(stdDbModelActions.removeSuccess(id));
  await updateInclusionAndEntries(dispatch, inclusion, type, projectElementId, getState);
};

export default {
  ...stdDbModelActions,
  create,
  patch,
  remove,
};
