import { createReducer } from 'redux-starter-kit';
import { projectResourcesActions } from '../actions';
import getStdDbModelReduxMapping from './std-db-model-reducers';

export const projectResourcesInitialState = {};

const projectResourcesReducer = createReducer(
  projectResourcesInitialState,
  getStdDbModelReduxMapping(projectResourcesActions),
);

export default projectResourcesReducer;
