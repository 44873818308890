import { createAction } from 'redux-starter-kit';
import buildStdDbModelActions, { resyncWithoutDispatch, getQueryOnId } from './std-db-model-actions';
import api from '../../api';

const endpoint = 'project-pages';
const stdDbModelActions = buildStdDbModelActions(endpoint);
const moveSuccess = createAction(`${endpoint}/move_success`);

const move = (pageId, newPos, params = {}) => async (dispatch, getState) => {
  const patch = { sorting: newPos };
  await api.partial_update(endpoint, pageId, patch, params);

  const res = await resyncWithoutDispatch(
    endpoint, getState, getQueryOnId, params,
  );

  dispatch(moveSuccess(res));

  return res;
};

export default {
  ...stdDbModelActions,
  moveSuccess,
  move,
};
