import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { nsOptions } from '../i18n';
import { childrenPropTypes } from '../utils/generic-prop-types';
import { checkLimitation } from '../utils/license';
import { LicMsgModal } from './MessageModal';
import StdLicenseMessage from './StdLicenseMessage';


const mapStateToProps = (state, ownProps) => ({
  user: ownProps.user || state.auth.authUser,
});


@connect(mapStateToProps)
@withTranslation('', nsOptions)
class LicenseChecker extends Component {
  static propTypes = {
    limName: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.arrayOf(PropTypes.string),
    ]).isRequired,
    currentCount: PropTypes.number,
    bypassChecking: PropTypes.bool,
    children: childrenPropTypes().isRequired,
    limitations: PropTypes.shape().isRequired,
    licenseId: PropTypes.number,
    licMsgModalChildren: childrenPropTypes(),
    triggerType: PropTypes.oneOf(['button', 'checkbox', 'radio']),
    triggerValue: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
    undoTriggerChange: PropTypes.func,
    alwaysTriggerOverridenFunc: PropTypes.bool,
    modalZIndex: PropTypes.number,
  };

  static defaultProps = {
    currentCount: undefined,
    licenseId: undefined,
    licMsgModalChildren: null,
    bypassChecking: false,
    triggerType: undefined,
    triggerValue: undefined,
    undoTriggerChange: undefined,
    alwaysTriggerOverridenFunc: null,
    modalZIndex: undefined,
  };

  constructor(props) {
    super(props);
    this.props = props;
  }

  getEffectiveTriggerValue() {
    const {
      triggerValue, limName, limitations, currentCount,
    } = this.props;

    if (triggerValue) {
      if (Array.isArray(triggerValue)) {
        if (Array.isArray(limName)) {
          if (triggerValue.length === limName.length) {
            // Remove trigger values when there limitation is allowed
            return triggerValue.filter((val, index) => (
              !checkLimitation(limitations, limName[index], currentCount)
            ));
          }
        }
      }
    }

    return triggerValue;
  }

  checkLimitations() {
    const { limitations, limName, currentCount } = this.props;

    if (Array.isArray(limName)) {
      return limName.every((lim) => checkLimitation(limitations, lim, currentCount));
    }
    return checkLimitation(limitations, limName, currentCount);
  }

  render() {
    const {
      bypassChecking, children, licMsgModalChildren, triggerType, modalZIndex,
      undoTriggerChange, alwaysTriggerOverridenFunc, limitations, licenseId,
    } = this.props;
    return bypassChecking || this.checkLimitations() ? children : (
      <LicMsgModal
        triggerType={triggerType}
        triggerValue={this.getEffectiveTriggerValue()}
        undoTriggerChange={undoTriggerChange}
        alwaysTriggerOverridenFunc={alwaysTriggerOverridenFunc}
        modalZIndex={modalZIndex}
        message={
          (
            <StdLicenseMessage
              limitations={limitations}
              licenseId={licenseId}
            />
          )
        }
      >
        { licMsgModalChildren || children }
      </LicMsgModal>
    );
  }
}


export default LicenseChecker;
