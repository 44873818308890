import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { withToastManager } from 'react-toast-notifications';
import { connect } from 'react-redux';
import { projectEntriesActions } from '../redux/actions';
import { nsOptions } from '../i18n';
import TimePicker from './TimePicker';
import ElementBase, { ENTRY_LATENCY_TIMEOUT_MS, retrieveProjectEntry, getMutex } from './ElementBase';
import Toast from '../utils/Toast';
import ErrorUtil from '../utils/ErrorUtil';
import TimeoutHandler from '../utils/TimeoutHandler';
import { toMoment } from '../utils/date';
import { ENTRY_TYPE_TIME, TIME } from '../constants';


const mapStateToProps = (state, ownProps) => ({
  element: state.elements[ownProps.elementId],
  inclusion: state.inclusions[ownProps.inclusionId],
  projectElement: state.projectElements[ownProps.projectElementId],
  projectEntry: retrieveProjectEntry(
    Object.values(state.projectEntries),
    ownProps.inclusionId,
    ownProps.projectElementId,
    ownProps.moduleInstanceId,
  ),
});

const mapDispatchToProps = (dispatch) => ({
  addEntry: async (data) => dispatch(projectEntriesActions.create(
    { type: ENTRY_TYPE_TIME, ...data },
  )),
  removeEntry: async (id) => dispatch(projectEntriesActions.remove(id)),
  patchEntry: async (id, data) => dispatch(projectEntriesActions.patch(id, data)),
});


@withToastManager
@connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })
@withTranslation('', nsOptions)
class ElementTime extends Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
    elementId: PropTypes.number.isRequired,
    element: PropTypes.shape().isRequired,
    projectElementId: PropTypes.number,
    moduleInstanceId: PropTypes.number,
    inclusion: PropTypes.shape(),
    inclusionId: PropTypes.number,
    parent: PropTypes.shape({
      content: PropTypes.shape(),
    }),
    methods: PropTypes.shape({
      move: PropTypes.func,
      reload: PropTypes.func.isRequired,
      remove: PropTypes.func,
      checkLinks: PropTypes.func,
    }).isRequired,
    isEditMode: PropTypes.bool,
    isEntryMode: PropTypes.bool,
    admin: PropTypes.bool,
    isModule: PropTypes.bool,
    isReadOnly: PropTypes.bool,
    projectEntry: PropTypes.shape(),
    projectElement: PropTypes.shape().isRequired,
    patchEntry: PropTypes.func.isRequired,
    addEntry: PropTypes.func.isRequired,
    removeEntry: PropTypes.func.isRequired,
  };

  static defaultProps = {
    projectElementId: null,
    moduleInstanceId: null,
    inclusion: null,
    inclusionId: null,
    parent: null,
    isEditMode: false,
    isEntryMode: false,
    admin: false,
    isModule: false,
    isReadOnly: false,
    projectEntry: null,
  };

  constructor(props) {
    super(props);
    this.timeoutHandler = new TimeoutHandler();
  }

  checkLinks = () => {
    if (this.elBaseRef) this.elBaseRef.checkLinks();
  };

  showLoader = (show = true) => {
    if (this.elBaseRef) this.elBaseRef.showLoader(show);
  };

  updateEntry = (value) => {
    this.timeoutHandler.doAfterTimeout(async () => {
      const mutex = getMutex(this.props);
      const release = await mutex.acquire();
      const {
        projectEntry, inclusion, projectElement, moduleInstanceId, patchEntry,
        removeEntry, addEntry, methods,
      } = this.props;
      try {
        this.showLoader();
        if (projectEntry) {
          if (value) {
            await patchEntry(projectEntry.id, { value });
          } else {
            await removeEntry(projectEntry.id);
          }
        } else {
          addEntry({
            inclusion: inclusion.id,
            project_element: projectElement.id,
            module: moduleInstanceId,
            value,
          });
        }
        Toast.success(this.props, 'error:valid.saved');
        if (methods.checkLinks) methods.checkLinks();
        if (this.elBaseRef) this.elBaseRef.checkForCorruptedMissingDataCount();
      } catch (error) {
        ErrorUtil.handleCatched(this.props, error);
      } finally {
        release();
        this.showLoader(false);
      }
    }, 0, ENTRY_LATENCY_TIMEOUT_MS);
  };

  render() {
    const {
      t, isEntryMode, isEditMode, isReadOnly, projectEntry,
    } = this.props;
    const options = {};

    if (isEditMode || isReadOnly) {
      options.disabled = true;
    }

    if (isEntryMode) {
      options.onChange = this.updateEntry;
      if (projectEntry) {
        options.defaultValue = projectEntry.value
          ? toMoment(projectEntry.value, TIME).toDate()
          : null;
      }
    }

    const missingData = isEntryMode && !projectEntry ? 'empty' : '';
    const qualifiedMissingData = isEntryMode && projectEntry && projectEntry.missing ? 'qualified-missing' : '';

    return (
      <ElementBase
        {...this.props}
        elementCategory="entry"
        className="element-date"
        ref={(ref) => { this.elBaseRef = ref; }}
      >
        <div className={`col-auto ${missingData}${qualifiedMissingData}`}>
          <TimePicker
            placeholder={t('error:placeholder.time')}
            {...options}
          />
        </div>
      </ElementBase>
    );
  }
}


export default ElementTime;
