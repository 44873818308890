import { createReducer } from 'redux-starter-kit';
import { teamLicensesActions } from '../actions';
import getStdDbModelReduxMapping from './std-db-model-reducers';

export const teamLicensesInitialState = {};

const teamLicensesReducer = createReducer(
  teamLicensesInitialState,
  getStdDbModelReduxMapping(teamLicensesActions),
);

export default teamLicensesReducer;
