import { createAction } from 'redux-starter-kit';

const storeReset = createAction('misc/reset_store');

const setPendingUrl = createAction('misc/set_pending_url');
const clearPendingUrl = createAction('misc/clear_pending_url');

export default {
  storeReset,
  setPendingUrl,
  clearPendingUrl,
};
