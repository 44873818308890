import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import memoize from 'memoize-one';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { nsOptions } from '../i18n';
import { childrenPropTypes } from '../utils/generic-prop-types';
import IconConfirm from './IconConfirm';
import LabeledSelect from './LabeledSelect';


@withTranslation('', nsOptions)
class ElementBaseLink extends Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
    className: PropTypes.string,
    divSelectClassName: PropTypes.string,
    selectClassName: PropTypes.string,
    defaultElementChoice: PropTypes.string,
    id: PropTypes.number.isRequired,
    identifier: PropTypes.node.isRequired,
    elementOptions: PropTypes.arrayOf(PropTypes.shape()).isRequired,
    children: childrenPropTypes(),
    highlightMissing: PropTypes.bool,
    source: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    remove: PropTypes.func.isRequired,
    onChange: PropTypes.func,
  };

  static defaultProps = {
    className: 'element-link element-qualitative-link',
    divSelectClassName: 'col-auto',
    selectClassName: 'custom-control custom-select',
    defaultElementChoice: 'common:form.select-trigger-variable',
    children: null,
    highlightMissing: false,
    source: 'none',
    onChange: null,
  };

  constructor(props) {
    super(props);
    this.state = {
      source: props.source,
    };
    this.memoizeValidation = memoize((highlightMissing, ref, value) => {
      if (ref && highlightMissing) {
        ref.validate(value === 'none' ? null : value);
      }
    });
  }

  componentDidUpdate() {
    const { highlightMissing } = this.props;
    const { source } = this.state;
    this.memoizeValidation(highlightMissing, this.ref, source);
  }

  callParentHandler = () => {
    if (this.props.onChange) {
      this.props.onChange({
        id: this.props.id,
        source: Number(this.state.source),
      });
    }
  };

  handleSourceChange = (e) => {
    this.setState({ source: e.target.value }, this.callParentHandler);
  };

  setRef = (ref) => {
    const { highlightMissing } = this.props;
    const { source } = this.state;
    this.ref = ref;
    this.memoizeValidation(highlightMissing, this.ref, source);
  };

  render() {
    const {
      t, className, divSelectClassName, selectClassName, elementOptions, children,
      defaultElementChoice,
    } = this.props;
    const { source } = this.state;

    return (
      <div className={className}>
        <div className="row align-items-center">
          <div className="col-auto form-inline font-weight-semibold">
            {this.props.identifier}
          </div>
          <div className="col-auto form-inline font-italic">
            {t('project:modal.link.answer-to')}
          </div>
          <div className={divSelectClassName}>
            <LabeledSelect
              name="element"
              required
              className={selectClassName}
              value={source}
              onChange={this.handleSourceChange}
              ref={this.setRef}
            >
              <option value="none" disabled>
                {t(defaultElementChoice)}
              </option>
              {elementOptions}
            </LabeledSelect>
          </div>
          {children}
          <div className="col-auto form-inline cursor-pointer">
            <IconConfirm
              tooltipContent={t('common:button.remove')}
              onClick={() => this.props.remove(this.props.id)}
            >
              <FontAwesomeIcon icon="times" transform="grow-4" />
            </IconConfirm>
          </div>
        </div>
      </div>
    );
  }
}


export default ElementBaseLink;
