import React from 'react';
import ReactIs from 'react-is';

export default class DomUtil {
  static getValueFromTarget(target, format = null, key = 'value') {
    let value = target[key];
    if (target.value === '') {
      value = null;
    } else if (format) {
      value = format(target.value);
    }
    return value;
  }

  static cloneChildren(children, props) {
    return Array.isArray(children) ? React.Children.map(children, (child) => {
      if (React.isValidElement(child)) {
        return React.cloneElement(child, props);
      }
      return child;
    }) : React.cloneElement(children, props);
  }

  static nodeWithProps(node, props) {
    if (ReactIs.isFragment(node)) {
      const children = DomUtil.cloneChildren(node.props.children, props);
      return React.cloneElement(node, { children });
    }
    return DomUtil.cloneChildren(node, props);
  }

  static isFunctionalComponent(component) {
    return typeof component === 'object' && typeof component.type === 'function';
  }
}
