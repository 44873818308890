import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import '../assets/css/confirm.css';
import { nsOptions } from '../i18n';
import { childrenPropTypes } from '../utils/generic-prop-types';
import ComponentLifeTracker from '../utils/ComponentLifeTracker';
import NewTooltip from './NewTooltip';


@withTranslation('', nsOptions)
class IconConfirm extends Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
    children: childrenPropTypes().isRequired,
    onClick: PropTypes.func.isRequired,
    onPendingConfirmationStart: PropTypes.func,
    onPendingConfirmationEnd: PropTypes.func,
    tooltipContent: PropTypes.string,
    tooltipDistance: PropTypes.number,
    tooltipArrow: PropTypes.bool,
    keepTooltipBlockedAfterAction: PropTypes.bool,
  };

  static defaultProps = {
    onPendingConfirmationStart: () => {},
    onPendingConfirmationEnd: () => {},
    tooltipContent: undefined,
    tooltipDistance: undefined,
    tooltipArrow: false,
    keepTooltipBlockedAfterAction: true,
  };

  constructor(props) {
    super(props);
    this.tooltip = null;
    this.state = {
      tooltipContent: props.tooltipContent,
      tooltipColor: undefined,
      tooltipTrigger: undefined,
      tooltipForceOpen: undefined,
      onClick: this.confirm,
    };
    this.compLifeTracker = new ComponentLifeTracker();
  }

  componentDidMount() {
    this.compLifeTracker.setMounted();
  }

  componentWillUnmount() {
    this.compLifeTracker.setUnmounted();
  }

  reset = (target, onPendingConfirmationEnd = null) => {
    const { tooltipContent, keepTooltipBlockedAfterAction } = this.props;
    if (this.compLifeTracker.isMounted()) {
      this.setState({
        tooltipContent,
        tooltipColor: undefined,
        tooltipTrigger: undefined,
        tooltipForceOpen: undefined,
        onClick: this.confirm,
      }, () => {
        if (onPendingConfirmationEnd) onPendingConfirmationEnd();
        target.classList.remove('icon-confirm');
        if (!keepTooltipBlockedAfterAction) this.tooltip.unblock();
      });
    }
  }

  action = (e) => {
    if (e.persist) e.persist();
    this.tooltip.block();
    this.props.onClick(e);
    this.reset(e.currentTarget);
  };

  confirm = (e) => {
    const { t, onPendingConfirmationEnd, onPendingConfirmationStart } = this.props;
    const target = e.currentTarget;
    this.setState({
      tooltipContent: t('common:button.click-again'),
      tooltipColor: 'red',
      tooltipTrigger: 'manual',
      tooltipForceOpen: true,
      onClick: () => {}, // First do nothing (to prevent accidental double click)
    }, () => {
      onPendingConfirmationStart();
      target.classList.add('icon-confirm');
    });
    // After a timeout, action can be performed
    setTimeout(() => { this.setState({ onClick: this.action }); }, 500);
    setTimeout(() => { this.reset(target, onPendingConfirmationEnd); }, 3000);
  };

  render() {
    const {
      tooltipContent, tooltipColor, tooltipTrigger, tooltipForceOpen, onClick,
    } = this.state;
    const { tooltipDistance, children, tooltipArrow } = this.props;

    return (
      <NewTooltip
        content={tooltipContent}
        disabled={!tooltipContent}
        theme={tooltipColor}
        trigger={tooltipTrigger}
        visible={tooltipForceOpen}
        distance={tooltipDistance}
        ref={(tooltip) => {
          this.tooltip = tooltip;
        }}
        arrow={tooltipArrow}
      >
        <this.props.children.type
          {...children.props}
          onClick={onClick}
          onKeyPress={onClick}
          /* ref={(child) => { */
          /*   this.child = child; */
          /* }} */
        />
      </NewTooltip>
    );
  }
}


export default IconConfirm;
