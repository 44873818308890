import moment from 'moment';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { withToastManager } from 'react-toast-notifications';
import AsyncSelect from 'react-select/async';
import { connect } from 'react-redux';
import Select from 'react-select';
import memoize from 'memoize-one';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Mutex } from 'async-mutex';
import { nsOptions } from '../i18n';
import { childrenPropTypes } from '../utils/generic-prop-types';
import NewModal from './NewModal';
import ButtonConfirm from './ButtonConfirm';
import LabeledInput from './LabeledInput';
import LabeledChoice from './LabeledChoice';
import FAQLink from './FAQLink';
import {
  ELEMENT_TYPE_MEASUREMENT, ELEMENT_TYPE_CALCULATION, ELEMENT_TYPE_DATE, DATE_TIME, DATE_FULL,
  DATE_MONTH_YEAR, DATE_YEAR, DATE_FORMATS,
} from '../constants';
import { checkSettingsValidity, checkDateValidity } from '../utils/consistency-check';
import ErrorUtil from '../utils/ErrorUtil';
import Toast from '../utils/Toast';
import ElementUtil from '../utils/ElementUtil';
import SortUtil from '../utils/SortUtil';
import { API_DATE_TIME_FORMAT } from '../utils/date';
import Checkbox from './Checkbox';
import DatePicker from './DatePicker';
import NewTooltip from './NewTooltip';
import { getDateInputPlaceholder } from './ElementDate';
import { CardLoader } from './Loader';

const SPECIFIC_VALUE = 'value';
const SPECIFIC_DATE = 'date';
const INTERVAL = 'interval';
const NO_TYPE_CHOSEN = 'none';
const CUSTOM_DATE = 'custom-date';
const FORM_DATE = 'form-date';
const INCLUSION_CREATION_DATE = 'inclusion-creation-date';

const mapStateToProps = (state) => ({
  elements: state.elements,
  projectElements: state.projectElements,
  pages: state.projectPages,
  user: state.auth.authUser,
});

@withTranslation('', nsOptions)
class LimitValueField extends Component {
  static propTypes = {
    label: PropTypes.string,
    name: PropTypes.string.isRequired,
    placeholder: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    type: PropTypes.string.isRequired,
    defaultValue: PropTypes.string,
    className: PropTypes.string,
    onKeyPress: PropTypes.func,
    labelClassName: PropTypes.string,
  };

  static defaultProps = {
    label: '',
    placeholder: '',
    defaultValue: null,
    className: '',
    labelClassName: '',
    onKeyPress: () => {},
  };

  render() {
    const {
      label, name, placeholder, type, onChange, defaultValue, className, onKeyPress,
      labelClassName,
    } = this.props;

    return (
      <div className={className}>
        <LabeledInput
          label={label}
          name={name}
          type={type}
          placeholder={placeholder}
          onChange={onChange}
          defaultValue={defaultValue}
          className="mb-0"
          onKeyPress={onKeyPress}
          inputClassName="labeled-input limit-value"
          labelClassName={labelClassName}
          hideOptionalLabel
        />
      </div>
    );
  }
}

@withTranslation('', nsOptions)
class DateInput extends Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
    elements: PropTypes.shape().isRequired,
    projectElement: PropTypes.shape(),
    projectElements: PropTypes.shape().isRequired,
    pages: PropTypes.shape().isRequired,
    onChange: PropTypes.func.isRequired,
    customDate: PropTypes.string,
    inclusionDate: PropTypes.bool,
    onDateTypeChange: PropTypes.func.isRequired,
    formDate: PropTypes.number,
    format: PropTypes.oneOf([
      DATE_TIME,
      DATE_FULL,
      DATE_MONTH_YEAR,
      DATE_YEAR,
    ]),
    castToDate: PropTypes.bool,
    onCastChange: PropTypes.func.isRequired,
    checkboxId: PropTypes.string,
  };

  static defaultProps = {
    projectElement: null,
    customDate: null,
    inclusionDate: false,
    formDate: null,
    castToDate: false,
    format: DATE_FULL,
    checkboxId: 'cast-value-to-date',
  };

  constructor(props) {
    super(props);
    this.state = {
      dateType: DateInput.getInitialState(props),
    };
  }

  static getInitialState = (props) => {
    if (props.inclusionDate) {
      return INCLUSION_CREATION_DATE;
    }
    if (props.formDate) {
      return FORM_DATE;
    }
    return CUSTOM_DATE;
  }

  getVariables = async (search, elements, projectElements, projectElement, pages) => {
    const { t } = this.props;

    // Filter project elements
    const filteredProjectElements = Object.values(projectElements)
      .filter((pEl) => pEl.id !== projectElement.id
        && (elements[pEl.element].type === ELEMENT_TYPE_DATE)
        && ((search
          && ElementUtil.formatElementName(elements[pEl.element], t).toLowerCase().includes(
            search.toLowerCase(),
          ))
            || !search)
            && (pEl.module === null || pEl.module === projectElement.module));

    // Order project elements by page and sorting
    const pElementsByPage = {};
    filteredProjectElements.forEach((pEl) => {
      const id = pages[pEl.project_page].sorting;
      if (pElementsByPage[id]) pElementsByPage[id].push(pEl);
      else pElementsByPage[id] = [pEl];
    });

    const orderedProjectElements = [];
    Object.values(pElementsByPage).forEach((pElements) => {
      orderedProjectElements.push(...pElements.sort((a, b) => {
        if (a.module !== b.module) return b.module ? -1 : 1;
        return SortUtil.sortArray(a, b);
      }));
    });

    // Format results
    return orderedProjectElements.map((pEl) => this.formatProjectElement(pEl));
  }

  formatProjectElement = (pEl) => {
    const {
      t, elements, pages, projectElements,
    } = this.props;
    const label = ElementUtil.formatProjectElementName(pEl, projectElements, elements, pages, t);
    return {
      value: pEl.id,
      label,
    };
  }

  onChange = (e) => {
    const { projectElement, projectElements, elements } = this.props;
    const { dateType } = this.state;
    if (dateType === CUSTOM_DATE) {
      this.props.onChange(CUSTOM_DATE, e);
    } else if (dateType === FORM_DATE) {
      const pId = e ? e.value : null;
      let truncTo = null;
      if (pId) {
        // The element that is being configured
        const parentElement = elements[projectElement.element];
        // The element that has been selected in the AsyncSelect
        const childrenPrjElement = Object.values(projectElements).find((pEl) => pEl.id === pId);
        const childrenElement = elements[childrenPrjElement.element];
        if (parentElement.format !== childrenElement.format) {
          // Saves the API from having to calculate wether to cast to date or not
          truncTo = DATE_FORMATS.find((format) => (
            [parentElement.format, childrenElement.format].includes(format)
          ));
        }
      }
      this.props.onChange(FORM_DATE, pId, truncTo);
    }
  }

  onDateTypeChange = (option) => {
    let newDateType;
    if (option) {
      newDateType = option.value;
    } else {
      // When we delete the INCLUSION_CREATION_DATE option
      newDateType = CUSTOM_DATE;
    }
    this.props.onDateTypeChange(newDateType);
    this.setState({ dateType: newDateType });
  }

  render() {
    const {
      t, elements, projectElements, projectElement, pages, customDate, inclusionDate,
      formDate, castToDate, format, onCastChange, checkboxId,
    } = this.props;
    const { dateType } = this.state;

    const selectOptions = [
      { value: CUSTOM_DATE, label: t('project:consistency-check.custom-date') },
      { value: FORM_DATE, label: t('project:consistency-check.form-date') },
      { value: INCLUSION_CREATION_DATE, label: t('project:consistency-check.inclusion-date') },
    ];
    const [customOption, formOption, IncOption] = selectOptions;
    let selectOption;
    if (dateType === CUSTOM_DATE) {
      selectOption = customOption;
    } else if (dateType === FORM_DATE) {
      selectOption = formOption;
    } else {
      selectOption = IncOption;
    }

    let defaultCustomDate = null;
    if (customDate) {
      defaultCustomDate = moment(customDate);
    }

    let defaultFormDate = null;
    if (formDate) {
      const prjElement = Object.values(projectElements).find((pEl) => pEl.id === formDate);
      defaultFormDate = this.formatProjectElement(prjElement);
    }

    let formElement;
    if (formDate) {
      const pElement = Object.values(projectElements).find((pEl) => pEl.id === formDate);
      formElement = elements[pElement.element];
    }

    const showWarning = (formElement && formElement.format !== format)
          || (inclusionDate && inclusionDate.format !== format);

    return (
      <>
        <div className="row align-items-end">
          <div className="col-auto">
            <Select
              name="date-type-of-control"
              id=""
              className="react-select date-type-select"
              classNamePrefix="react-select"
              value={selectOption}
              isClearable={dateType === INCLUSION_CREATION_DATE}
              onChange={this.onDateTypeChange}
              styles={{
                singleValue: (provided) => ({
                  ...provided,
                  maxWidth: 'unset',
                  minWidth: '120px',
                }),
              }}
              options={selectOptions}
              isSearchable={false}
            />
          </div>
          {
            (dateType === CUSTOM_DATE) && (
              <div className="consistency-check">
                <div className="col-auto">
                  <DatePicker
                    placeholder={getDateInputPlaceholder(t, format)}
                    defaultValue={defaultCustomDate}
                    onChange={this.onChange}
                    format={format}
                  />
                </div>
              </div>
            )
          }
          {
            (dateType === FORM_DATE) && (
              <div className="col-5">
                <AsyncSelect
                  className="react-select date-variable-select"
                  classNamePrefix="react-select"
                  placeholder={t('project:consistency-check.choose-variable')}
                  noOptionsMessage={() => t('project:consistency-check.no-more-variables')}
                  loadOptions={(search) => this.getVariables(
                    search, elements, projectElements, projectElement, pages,
                  )}
                  onChange={this.onChange}
                  menuPlacement="auto"
                  hideSelectedOption
                  closeMenuOnSelect
                  defaultOptions
                  defaultValue={defaultFormDate}
                  size="25"
                  isClearable
                />
              </div>
            )
          }
        </div>
        {
          showWarning && (
            <div className="pt-2 text-red">
              <FontAwesomeIcon
                icon={['fas', 'exclamation-triangle']}
                transform="grow-2"
                style={{
                  '--fa-primary-opacity': 1,
                  '--fa-secondary-opacity': 0.4,
                }}
              />
              <span className="ml-2">
                {t('error:warning.comparison-on-date-with-mismatching-types')}
              </span>
            </div>
          )
        }
        {
          format === DATE_TIME && dateType === FORM_DATE && formElement
            && formElement.format === DATE_TIME && (
            <div className="pt-2">
              <Checkbox
                id={checkboxId}
                checked={castToDate}
                onChange={(e) => onCastChange(e.target.checked)}
              >
                {t('project:consistency-check.dont-take-time-into-account')}
              </Checkbox>
            </div>
          )
        }
      </>
    );
  }
}

@withToastManager
@connect(mapStateToProps)
@withTranslation('', nsOptions)
class ConsistencyCheckManager extends Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
    children: childrenPropTypes().isRequired,
    elementName: PropTypes.string.isRequired,
    element: PropTypes.shape().isRequired,
    methods: PropTypes.shape().isRequired,
    elements: PropTypes.shape().isRequired,
    hideValidationToast: PropTypes.bool,
    projectElement: PropTypes.shape(),
    projectElements: PropTypes.shape().isRequired,
    pages: PropTypes.shape().isRequired,
    user: PropTypes.shape().isRequired,
  };

  static defaultProps = {
    hideValidationToast: false,
    projectElement: null,
  };

  static getInitialState = (element) => {
    const loading = false;
    if (element.type === ELEMENT_TYPE_DATE) {
      const { before, after } = element;
      const beforeVar = element.before_var;
      const afterVar = element.after_var;
      const lessThanIncDate = element.less_than_inc_date;
      const greaterThanIncDate = element.greater_than_inc_date;
      const truncMin = element.trunc_min;
      const truncMax = element.trunc_max;
      let typeOfSettings = INTERVAL;
      if ((before && after && before === after)
        || (greaterThanIncDate && lessThanIncDate)
        || (beforeVar && afterVar && beforeVar === afterVar)) {
        typeOfSettings = SPECIFIC_DATE;
      } else if (!(before || after || greaterThanIncDate || lessThanIncDate || beforeVar
        || afterVar)) {
        typeOfSettings = null;
      }
      return {
        loading,
        before,
        after,
        beforeVar,
        afterVar,
        lessThanIncDate,
        greaterThanIncDate,
        typeOfSettings,
        truncMin,
        truncMax,
      };
    }
    const lowBound = (element.min !== undefined) ? element.min.toString() : '';
    const highBound = (element.max !== undefined) ? element.max.toString() : '';
    const excludeLimits = element.strict || false;
    const forbidDecimals = element.integer || null;
    let typeOfSettings = NO_TYPE_CHOSEN;
    if (element.type === ELEMENT_TYPE_CALCULATION) {
      if ((lowBound !== '') && (highBound !== '') && (lowBound === highBound)) {
        typeOfSettings = SPECIFIC_VALUE;
      }
      if (lowBound !== highBound) {
        typeOfSettings = INTERVAL;
      }
    }
    return {
      loading,
      lowBound,
      highBound,
      excludeLimits,
      forbidDecimals,
      typeOfSettings,
    };
  };

  constructor(props) {
    super(props);
    this.state = ConsistencyCheckManager.getInitialState(props.element);
    this.memoizedCheckSettingsValidity = memoize(checkSettingsValidity);
    this.mutex = new Mutex();
  }

  componentDidUpdate(prevProps) {
    if (this.props.element.strict !== prevProps.element.strict) {
      this.resetExcludeLimits(this.props.element.strict);
    }
    if (this.props.element.integer !== prevProps.element.integer) {
      this.resetForbidDecimals(this.props.element.integer);
    }
  }

  getCalculationTypeOfSettings = () => {
    const { min, max } = this.props.element;
    // For a specific value, min and max are equal in BDD
    if ((min !== undefined) && (max !== undefined) && (min === max)) {
      return SPECIFIC_VALUE;
    }
    if (min !== max) {
      return INTERVAL;
    }
    return NO_TYPE_CHOSEN;
  }

  resetSettingsParameters = () => {
    this.setState({
      lowBound: '',
      highBound: '',
      excludeLimits: false,
      forbidDecimals: false,
    });
  }

  resetExcludeLimits = (strict) => {
    this.setState({ excludeLimits: strict || false });
  }

  resetForbidDecimals = (integer) => {
    this.setState({ forbidDecimals: integer || null });
  }

  resyncSettingsParameters = () => {
    const { element } = this.props;
    this.setState(ConsistencyCheckManager.getInitialState(element));
  }

  deleteSettings = async () => {
    const { methods, hideValidationToast, element } = this.props;
    if (methods.patchElement && !this.mutex.isLocked()) {
      const release = await this.mutex.acquire();
      this.setState({ loading: true });
      let settings = {};
      if (element.type === ELEMENT_TYPE_DATE) {
        settings = {
          before: null,
          after: null,
          before_var: null,
          after_var: null,
          less_than_inc_date: null,
          greater_than_inc_date: null,
          truncMin: null,
          truncMax: null,
        };
      } else {
        settings = {
          min: null,
          max: null,
          strict: null,
          integer: null,
        };
      }
      try {
        await methods.patchElement({ ...settings });
        this.resetSettingsParameters();
        this.modal.hide();
        if (!hideValidationToast) {
          Toast.success(this.props, 'error:valid.saved');
        }
      } catch (error) {
        ErrorUtil.handleCatched(this.props, error);
      } finally {
        release();
        this.setState({ loading: false });
      }
    }
  }

  preventIncorrectChars = (e) => {
    if (!['1', '2', '3', '4', '5', '6', '7', '8', '9', '0', '.', ',', '-'].includes(e.key)) {
      e.preventDefault();
      e.stopPropagation();
      return false;
    }
    // Workaround for dot issue in french locale in Firefox (only comma is
    // working so don't add it and inform the user)
    // See https://bugzilla.mozilla.org/show_bug.cgi?id=1253606
    // Function from ElementMeasurement.jsx
    if (this.props.user.language === 'fr' && e.key === '.') {
      // Try to detect Firefox to not alter right behaviour of others browers
      // (Safari and Chrome)
      if (navigator.userAgent.toLowerCase().indexOf('firefox') > -1) {
        e.preventDefault();
        e.stopPropagation();
        Toast.error(this.props, 'error:error.use-comma-only-for-float');
        return false;
      }
    }

    return true;
  }

  updateConsistency = async () => {
    const { t, methods, hideValidationToast } = this.props;
    const {
      lowBound, highBound, excludeLimits, forbidDecimals, typeOfSettings,
    } = this.state;
    if (methods.patchElement && !this.mutex.isLocked()) {
      const release = await this.mutex.acquire();
      this.setState({ loading: true });
      const min = lowBound || null;
      const max = highBound || null;
      const integer = forbidDecimals || null;
      // When max and min are null, we don't need strict
      const strict = (min || max) ? excludeLimits : null;
      try {
        this.memoizedCheckSettingsValidity(
          t, lowBound, highBound, forbidDecimals || false, typeOfSettings === SPECIFIC_VALUE,
        );
        await methods.patchElement({
          min,
          max,
          strict,
          integer,
        });
        this.modal.hide();
        if (!hideValidationToast) {
          Toast.success(this.props, 'error:valid.saved');
        }
      } catch (error) {
        ErrorUtil.handleCatched(this.props, error);
      } finally {
        release();
        this.setState({ loading: false });
      }
    }
  }

  updateDateConsistency = async () => {
    const {
      t, methods, hideValidationToast,
    } = this.props;
    const {
      before, after, lessThanIncDate, greaterThanIncDate, beforeVar, afterVar,
      typeOfSettings, truncMin, truncMax,
    } = this.state;
    const minDate = after ? moment(after) : null;
    const maxDate = before ? moment(before) : null;
    const isIncDate = lessThanIncDate && greaterThanIncDate
      && lessThanIncDate === greaterThanIncDate;
    const isAVariable = beforeVar && afterVar && beforeVar === afterVar;

    if (methods.patchElement && !this.mutex.isLocked()) {
      const release = await this.mutex.acquire();
      this.setState({ loading: true });
      try {
        checkDateValidity(
          t, minDate, maxDate, isIncDate, isAVariable,
          typeOfSettings === SPECIFIC_DATE,
        );
        await methods.patchElement({
          before: maxDate ? maxDate.format(API_DATE_TIME_FORMAT) : null,
          after: minDate ? minDate.format(API_DATE_TIME_FORMAT) : null,
          before_var: beforeVar,
          after_var: afterVar,
          less_than_inc_date: lessThanIncDate || null,
          greater_than_inc_date: greaterThanIncDate || null,
          trunc_min: truncMin || null,
          trunc_max: truncMax || null,
        });
        this.modal.hide();
        if (!hideValidationToast) {
          Toast.success(this.props, 'error:valid.saved');
        }
      } catch (error) {
        ErrorUtil.handleCatched(this.props, error);
      } finally {
        release();
        this.setState({ loading: false });
      }
    }
  }

  getMin = () => {
    const { element } = this.props;
    return (element.min !== undefined) ? element.min.toString() : '';
  }

  getMax = () => {
    const { element } = this.props;
    return (element.max !== undefined) ? element.max.toString() : '';
  }

  getCalculationExcludeLimits = (typeOfSettings) => {
    const { element } = this.props;
    if (typeOfSettings === SPECIFIC_VALUE) {
      return false;
    }
    return element.strict || false;
  };

  renderQuantitativeCheck = () => {
    const { elementName, t } = this.props;
    const {
      excludeLimits, forbidDecimals, lowBound, highBound,
    } = this.state;

    return (
      <div className="row pb-3">
        <LimitValueField
          label={t('project:consistency-check.minimum')}
          labelClassName="small font-italic m-0 pb-1"
          name={`${elementName}-minimum`}
          type="number"
          placeholder={t('project:consistency-check.minimum-short')}
          onChange={(e) => {
            this.setState({ lowBound: e.target.value });
          }}
          defaultValue={this.getMin()}
          className="col-12"
          onKeyPress={this.preventIncorrectChars}
        />
        <LimitValueField
          label={t('project:consistency-check.maximum')}
          labelClassName="small font-italic m-0 pb-1"
          name={`${elementName}-maximum`}
          type="number"
          placeholder={t('project:consistency-check.maximum-short')}
          onChange={(e) => {
            this.setState({ highBound: e.target.value });
          }}
          defaultValue={this.getMax()}
          className="col-12 py-2"
          onKeyPress={this.preventIncorrectChars}
        />
        <div className="col-12 pt-3 pb-2">
          <Checkbox
            id="exclude-limits"
            checked={excludeLimits || false}
            onChange={(e) => {
              this.setState({ excludeLimits: e.target.checked });
            }}
            disabled={!(lowBound || highBound)}
          >
            {t('project:consistency-check.exclude-bounds')}
          </Checkbox>
        </div>
        <div className="col-12">
          <Checkbox
            id="do-not-allow-decimals"
            checked={forbidDecimals || false}
            onChange={(e) => {
              this.setState({ forbidDecimals: e.target.checked });
            }}
          >
            {t('project:consistency-check.forbid-decimals')}
          </Checkbox>
        </div>
      </div>
    );
  }

  renderCalculationCheck = () => {
    const { t, elementName } = this.props;
    const {
      typeOfSettings, lowBound, highBound, excludeLimits,
    } = this.state;

    const defaultValues = { value: '', min: '', max: '' };
    const defaultTypeOfSettings = this.getCalculationTypeOfSettings(typeOfSettings);
    if (defaultTypeOfSettings === SPECIFIC_VALUE) {
      defaultValues.value = this.getMin();
    } else if (defaultTypeOfSettings === INTERVAL) {
      defaultValues.min = this.getMin();
      defaultValues.max = this.getMax();
    }

    const calculationTypeChoices = [
      {
        text: t('project:consistency-check.interval'),
        value: INTERVAL,
      },
      {
        text: t('project:consistency-check.a-specific-value'),
        value: SPECIFIC_VALUE,
      },
    ];

    return (
      <div>
        <div>
          <LabeledChoice
            name="calculation-type-of-control"
            label={t('project:consistency-check.response-must-be')}
            value={typeOfSettings}
            onChange={(e) => {
              const newTypeOfSettings = e.target.value;
              this.setState({
                typeOfSettings: newTypeOfSettings,
                lowBound: (newTypeOfSettings === defaultTypeOfSettings) ? this.getMin() : '',
                highBound: (newTypeOfSettings === defaultTypeOfSettings) ? this.getMax() : '',
                excludeLimits: this.getCalculationExcludeLimits(newTypeOfSettings),
              });
            }}
            choices={calculationTypeChoices}
            hideOptionalLabel
            colLabelClassName="col-12 mb-2"
          />
        </div>
        {
          (typeOfSettings === SPECIFIC_VALUE) && (
            <div className="py-3">
              <div className="mb-2">
                {t('project:consistency-check.specific-value')}
              </div>
              <LimitValueField
                name={`${elementName}-specific-value`}
                type="number"
                onChange={(e) => {
                  this.setState({ lowBound: e.target.value, highBound: e.target.value });
                }}
                defaultValue={defaultValues.value}
                placeholder={t('project:consistency-check.value')}
                onKeyPress={this.preventIncorrectChars}
                className="pt-2"
              />
            </div>
          )
        }
        {
          (typeOfSettings === INTERVAL) && (
            <div className="pt-3">
              <div className="pb-2 row align-items-center">
                <div className="col-auto pr-1">
                  {t('project:consistency-check.limit-values')}
                </div>
                <div className="col-auto px-0">
                  <small className="font-italic ml-1">
                    {t('project:consistency-check.at-least-one-limit')}
                  </small>
                </div>
              </div>
              <LimitValueField
                name={`${elementName}-minimum`}
                type="number"
                onChange={(e) => {
                  this.setState({ lowBound: e.target.value });
                }}
                defaultValue={defaultValues.min}
                placeholder={t('project:consistency-check.minimum-short')}
                className="pt-2"
                onKeyPress={this.preventIncorrectChars}
                label={t('project:consistency-check.minimum')}
                labelClassName="small font-italic m-0 pb-1"
              />
              <LimitValueField
                name={`${elementName}-maximum`}
                type="number"
                onChange={(e) => {
                  this.setState({ highBound: e.target.value });
                }}
                defaultValue={defaultValues.max}
                placeholder={t('project:consistency-check.maximum-short')}
                className="py-2"
                onKeyPress={this.preventIncorrectChars}
                label={t('project:consistency-check.maximum')}
                labelClassName="small font-italic m-0 pb-1"
              />
            </div>
          )
        }
        {
          (typeOfSettings === INTERVAL) && (
            <div className="py-3">
              <Checkbox
                id="exclude-limits"
                checked={excludeLimits || false}
                onChange={(e) => {
                  this.setState({ excludeLimits: e.target.checked });
                }}
                disabled={!(lowBound || highBound)}
                className="col-auto"
              >
                {t('project:consistency-check.exclude-bounds')}
              </Checkbox>
            </div>
          )
        }
      </div>
    );
  }

  onDateChange = (date, dateType, value, truncTo = null) => {
    const truncValue = {};

    if (date === 'after') {
      if (dateType === CUSTOM_DATE) {
        this.setState({
          after: value,
          afterVar: null,
          greaterThanIncDate: false,
        });
      } else if (dateType === FORM_DATE) {
        if (truncTo) {
          truncValue.truncMin = truncTo;
        }
        this.setState({
          after: null,
          afterVar: value,
          greaterThanIncDate: false,
          ...truncValue,
        });
      }
    } else if (date === 'before') {
      if (dateType === CUSTOM_DATE) {
        this.setState({
          before: value,
          beforeVar: null,
          lessThanIncDate: false,
        });
      } else if (dateType === FORM_DATE) {
        if (truncTo) {
          truncValue.truncMax = truncTo;
        }
        this.setState({
          before: null,
          beforeVar: value,
          lessThanIncDate: false,
          ...truncValue,
        });
      }
    } else if (date === SPECIFIC_DATE) {
      if (dateType === CUSTOM_DATE) {
        this.setState({
          before: value,
          after: value,
          beforeVar: null,
          afterVar: null,
          lessThanIncDate: false,
          greaterThanIncDate: false,
        });
      } else if (dateType === FORM_DATE) {
        if (truncTo) {
          truncValue.truncMin = truncTo;
          truncValue.truncMax = truncTo;
        }
        this.setState({
          before: null,
          after: null,
          beforeVar: value,
          afterVar: value,
          lessThanIncDate: false,
          greaterThanIncDate: false,
          ...truncValue,
        });
      }
    }
  }

  onDateTypeChange = (date, newDateType) => {
    const { element } = this.props;
    const specificDate = element.before && element.after && element.before === element.after;
    const specificFormDate = element.before_var && element.after_var
      && element.before_var === element.after_var;

    if (date === 'after') {
      if (newDateType === CUSTOM_DATE) {
        this.setState({
          after: !specificDate ? element.after : null,
          afterVar: null,
          greaterThanIncDate: null,
          truncMin: null,
        });
      } else if (newDateType === INCLUSION_CREATION_DATE) {
        this.setState({
          after: null,
          afterVar: null,
          greaterThanIncDate: true,
          truncMin: null,
        });
      } else {
        this.setState({
          after: null,
          afterVar: !specificFormDate ? element.after_var : null,
          greaterThanIncDate: null,
          truncMin: !specificFormDate ? element.trunc_min : null,
        });
      }
    } else if (date === 'before') {
      if (newDateType === CUSTOM_DATE) {
        this.setState({
          before: !specificDate ? element.before : null,
          beforeVar: null,
          lessThanIncDate: null,
          truncMax: null,
        });
      } else if (newDateType === INCLUSION_CREATION_DATE) {
        this.setState({
          before: null,
          beforeVar: null,
          lessThanIncDate: true,
          truncMax: null,
        });
      } else {
        this.setState({
          before: null,
          beforeVar: !specificFormDate ? element.before_var : null,
          lessThanIncDate: null,
          truncMax: !specificFormDate ? element.trunc_max : null,
        });
      }
    } else if (date === SPECIFIC_DATE) {
      if (newDateType === CUSTOM_DATE) {
        this.setState({
          before: specificDate ? element.before : null,
          after: specificDate ? element.after : null,
          beforeVar: null,
          afterVar: null,
          greaterThanIncDate: null,
          lessThanIncDate: null,
          truncMin: null,
          truncMax: null,
        });
      } else if (newDateType === INCLUSION_CREATION_DATE) {
        this.setState({
          before: null,
          after: null,
          beforeVar: null,
          afterVar: null,
          greaterThanIncDate: true,
          lessThanIncDate: true,
          truncMin: null,
          truncMax: null,
        });
      } else {
        this.setState({
          before: null,
          after: null,
          beforeVar: specificFormDate ? element.before_var : null,
          afterVar: specificFormDate ? element.after_var : null,
          greaterThanIncDate: null,
          lessThanIncDate: null,
          truncMin: specificFormDate ? element.trunc_min : null,
          truncMax: specificFormDate ? element.trunc_max : null,
        });
      }
    }
  }

  onSettingsChange = (e) => {
    const { element } = this.props;
    const newSettings = e.target.value;
    const specificDate = element.before && element.after && element.before === element.after;
    const incDate = element.less_than_inc_date && element.greater_than_inc_date;
    const specificFormDate = element.before_var && element.after_var
      && element.before_var === element.after_var;

    if (newSettings === SPECIFIC_DATE) {
      this.setState({
        typeOfSettings: e.target.value,
        before: specificDate ? element.before : null,
        after: specificDate ? element.after : null,
        beforeVar: specificFormDate ? element.before_var : null,
        afterVar: specificFormDate ? element.after_var : null,
        lessThanIncDate: incDate,
        greaterThanIncDate: incDate,
        truncMin: specificFormDate ? element.trunc_min : null,
        truncMax: specificFormDate ? element.trunc_max : null,
      });
    } else if (newSettings === INTERVAL) {
      this.setState({
        typeOfSettings: e.target.value,
        before: !specificDate ? element.before : null,
        after: !specificDate ? element.after : null,
        beforeVar: !specificFormDate ? element.before_var : null,
        afterVar: !specificFormDate ? element.after_var : null,
        lessThanIncDate: !incDate ? element.less_than_inc_date : null,
        greaterThanIncDate: !incDate ? element.greater_than_inc_date : null,
        truncMin: !specificFormDate ? element.trunc_min : null,
        truncMax: !specificFormDate ? element.trunc_max : null,
      });
    }
  }

  onCastChange = (date, newValue) => {
    if (date === 'after' || date === SPECIFIC_DATE) {
      this.setState({ truncMin: newValue ? DATE_FULL : null });
    }
    if (date === 'before' || date === SPECIFIC_DATE) {
      this.setState({ truncMax: newValue ? DATE_FULL : null });
    }
  }

  renderDateCheck = () => {
    const { t, element } = this.props;
    const {
      typeOfSettings, before, after, greaterThanIncDate, lessThanIncDate, beforeVar,
      afterVar, truncMin, truncMax,
    } = this.state;
    const { format } = element;
    const withTime = format === DATE_TIME;

    const dateTypeChoices = [
      {
        text: t(`project:consistency-check.interval${withTime ? '' : '-date'}`),
        value: INTERVAL,
      },
      {
        text: t('project:consistency-check.a-specific-date'),
        value: SPECIFIC_DATE,
      },
    ];

    return (
      <div>
        <div className="pb-2">
          <LabeledChoice
            name="date-type-of-control"
            label={t('project:consistency-check.response-must-be')}
            value={typeOfSettings}
            onChange={this.onSettingsChange}
            choices={dateTypeChoices}
            hideOptionalLabel
            colLabelClassName="col-12 pb-1"
          />
        </div>
        {
          (typeOfSettings === SPECIFIC_DATE) && (
            <div className="pt-3 form-group">
              <div className="mb-2">
                {t(`project:consistency-check.specific-${withTime ? 'value' : 'date'}`)}
              </div>
              <DateInput
                {...this.props}
                customDate={after}
                inclusionDate={greaterThanIncDate}
                formDate={afterVar}
                onChange={(dateType, value, truncTo) => this.onDateChange(
                  SPECIFIC_DATE, dateType, value, truncTo,
                )}
                onDateTypeChange={(dateType) => this.onDateTypeChange(
                  SPECIFIC_DATE, dateType,
                )}
                format={format}
                castToDate={truncMin === DATE_FULL}
                onCastChange={(value) => this.onCastChange(SPECIFIC_DATE, value)}
              />
            </div>
          )
        }
        {
          (typeOfSettings === INTERVAL) && (
            <div className="pt-3 form-group">
              <div className="mb-2 row align-items-center">
                <div className="col-auto pr-1">
                  {t(`project:consistency-check.limit-${withTime ? 'values' : 'dates'}`)}
                </div>
                <div className="col-auto px-0">
                  <small className="font-italic ml-1">
                    {t('project:consistency-check.at-least-one-limit')}
                  </small>
                </div>
              </div>
              <div className="py-2">
                <div className="font-italic small">
                  {t('project:consistency-check.minimum')}
                </div>
                <DateInput
                  {...this.props}
                  customDate={after}
                  inclusionDate={greaterThanIncDate}
                  formDate={afterVar}
                  onChange={(dateType, value, truncTo) => this.onDateChange(
                    'after', dateType, value, truncTo,
                  )}
                  onDateTypeChange={(dateType) => this.onDateTypeChange(
                    'after', dateType,
                  )}
                  format={format}
                  castToDate={truncMin === DATE_FULL}
                  onCastChange={(value) => this.onCastChange('after', value)}
                  checkboxId="cast-min-to-date"
                />
              </div>
              <div className="pt-3">
                <div className="font-italic small">
                  {t('project:consistency-check.maximum')}
                </div>
                <DateInput
                  {...this.props}
                  customDate={before}
                  inclusionDate={lessThanIncDate}
                  formDate={beforeVar}
                  onChange={(dateType, value, truncTo) => this.onDateChange(
                    'before', dateType, value, truncTo,
                  )}
                  onDateTypeChange={(dateType) => this.onDateTypeChange(
                    'before', dateType,
                  )}
                  format={format}
                  castToDate={truncMax === DATE_FULL}
                  onCastChange={(value) => this.onCastChange('before', value)}
                  checkboxId="cast-max-to-date"
                />
              </div>
            </div>
          )
        }
      </div>
    );
  }

  renderModalContent = () => {
    const { element } = this.props;
    switch (element.type) {
      case ELEMENT_TYPE_MEASUREMENT:
        return this.renderQuantitativeCheck();
      case ELEMENT_TYPE_CALCULATION:
        return this.renderCalculationCheck();
      case ELEMENT_TYPE_DATE:
        return this.renderDateCheck();
      default: return '';
    }
  }

  canValidate = (type) => {
    if (type === ELEMENT_TYPE_CALCULATION || type === ELEMENT_TYPE_MEASUREMENT) {
      const {
        lowBound, highBound, forbidDecimals,
      } = this.state;
      return (lowBound || highBound || forbidDecimals);
    }
    if (type === ELEMENT_TYPE_DATE) {
      const {
        before, after, beforeVar, afterVar, lessThanIncDate, greaterThanIncDate,
      } = this.state;
      return (before || after || beforeVar || afterVar || lessThanIncDate
        || greaterThanIncDate);
    }
    return false;
  }

  hasSettingsSaved = () => {
    const { element } = this.props;
    if (element.type === ELEMENT_TYPE_CALCULATION || element.type === ELEMENT_TYPE_MEASUREMENT) {
      return (this.getMin() || this.getMax() || element.integer);
    }
    if (element.type === ELEMENT_TYPE_DATE) {
      return (element.before || element.after || element.before_var || element.after_var
        || element.less_than_inc_date || element.greater_than_inc_date);
    }
    return false;
  }

  render() {
    const {
      t, children, elementName, element,
    } = this.props;
    const { loading } = this.state;

    const elementHasSettingsSaved = this.hasSettingsSaved();

    const getModalSize = () => {
      if (element.type === ELEMENT_TYPE_DATE) {
        return 'md';
      }
      return 'sm';
    };

    return (
      <NewModal
        trigger={children}
        title={t('project:consistency-check.modal-title')}
        xlHeader={elementName}
        size={getModalSize()}
        type={2}
        xlHeaderPlacement="top"
        onLoad={this.resyncSettingsParameters}
        footer={(
          <div className="flex-fill d-flex flex-column">
            <div className="row align-self-center">
              <button
                type="button"
                className="btn btn-newblue-1 text-white px-3"
                onClick={(element.type === ELEMENT_TYPE_DATE) ? this.updateDateConsistency
                  : this.updateConsistency}
                disabled={!this.canValidate(element.type) || loading}
              >
                {t('common:button.save')}
              </button>
              {
                elementHasSettingsSaved ? (
                  <ButtonConfirm
                    onClick={() => this.deleteSettings()}
                  >
                    <button
                      type="button"
                      className="btn btn-link ml-2"
                      disabled={loading}
                    >
                      {t('project:consistency-check.delete-settings')}
                    </button>
                  </ButtonConfirm>
                ) : (
                  <NewTooltip
                    content={t('common:tooltip.cancel')}
                  >
                    <button
                      type="button"
                      className="btn btn-link ml-2"
                      disabled={loading}
                      onClick={() => this.modal.hide()}
                    >
                      {t('common:button.cancel')}
                    </button>
                  </NewTooltip>
                )
              }
            </div>
            <div
              className="pt-5"
              style={{ padding: '0 4rem' }}
            >
              <FAQLink
                articleId={11}
              />
            </div>
          </div>
        )}
        ref={(modal) => {
          this.modal = modal;
        }}
      >
        <div className="consistency-check-modal">
          {loading && <CardLoader />}
          {this.renderModalContent()}
        </div>
      </NewModal>
    );
  }
}

export default ConsistencyCheckManager;
