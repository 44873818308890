import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { nsOptions } from '../i18n';
import NewTooltip from './NewTooltip';
import FAQLink from './FAQLink';

@withTranslation('', nsOptions)
class ElementFieldControls extends Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
    controls: PropTypes.arrayOf(PropTypes.any).isRequired,
    isEditMode: PropTypes.bool,
    isEntryMode: PropTypes.bool,
    isReadOnly: PropTypes.bool,
  };

  static defaultProps = {
    isEntryMode: false,
    isEditMode: false,
    isReadOnly: false,
  };

  constructor(props) {
    super(props);
    this.tooltip = null;
  }

  render() {
    const {
      t, controls, isEditMode, isEntryMode, isReadOnly,
    } = this.props;

    let title = t('project:form.consistency-check-tooltip.no-data');
    let tooltipColor;
    let titleColor = '';
    let iconColor;

    // We only display in entry mode if the data is invalid
    if (isEntryMode) {
      titleColor = 'text-red';
      title = t('project:form.consistency-check-tooltip.invalid');
    }

    if (isEditMode) {
      iconColor = 'var(--newblue-1)';
      tooltipColor = 'field-controls-newblue-1';
    } else if (isReadOnly && !isEntryMode) {
      iconColor = 'var(--gray)';
      tooltipColor = 'field-controls-gray';
    } else {
      iconColor = 'var(--gray-200)';
      tooltipColor = 'field-controls-red';
    }

    return (
      <NewTooltip
        theme="field-controls"
        interactive={isEditMode}
        content={(
          <>
            <div
              className="data-control-icon"
            >
              <FontAwesomeIcon
                icon={['fal', 'spell-check']}
                style={{ color: iconColor }}
              />
            </div>
            <div className={`font-weight-semibold mb-3 ${titleColor}`}>
              {title}
            </div>
            <div className="tooltip-content">
              <ul className="field-controls-list p-0">
                {
                  controls.map((setting) => (
                    <li
                      key={`${setting.text}-${setting.value}`}
                      className="pb-2"
                    >
                      <span>
                        {setting.text}
                      </span>
                      {
                        setting.value && (
                          <span className="font-italic">
                            &nbsp;
                            {setting.value}
                          </span>
                        )
                      }
                      {
                        setting.comment && (
                          <>
                            &nbsp;-&nbsp;
                            <small>
                              {setting.comment}
                            </small>
                          </>
                        )
                      }
                    </li>
                  ))
                }
              </ul>
            </div>
            {isEditMode && (
              <div>
                <FAQLink
                  linkClassName="classical-link text-primary small"
                  articleId={11}
                />
              </div>
            )}
          </>
        )}
        ref={(tooltip) => { this.tooltip = tooltip; }}
        containerClassName={tooltipColor}
      >
        <FontAwesomeIcon
          icon={['far', 'spell-check']}
          className={`field-control-icon ${titleColor}`}
        />
      </NewTooltip>
    );
  }
}


export default ElementFieldControls;
