import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { withToastManager } from 'react-toast-notifications';
import { connect } from 'react-redux';
import { Cache } from 'axios-extensions';
import MixedView from './MixedView';
import MobileView from './MobileView';
import { CardLoader } from './Loader';
import InclusionsItem from './InclusionsItem';
import { nsOptions } from '../i18n';
import Help from './Help';
import {
  Table, TableHead, TableBody, TableRow, TableCell,
} from './CustomTable';
import ErrorUtil from '../utils/ErrorUtil';
import SortUtil from '../utils/SortUtil';
import {
  projectElementsActions, elementsActions, elementModalitiesActions, elementLinksActions,
  projectPagesActions,
} from '../redux/actions';
import {
  SORTING_BY_DATE, ASCENDING_PROGRESSION, DESCENDING_PROGRESSION,
  SORTING_BY_INCONSISTENT_DATA, ASCENDING_PER_PROJECT_ID, DESCENDING_PER_PROJECT_ID,
  SORTING_BY_QUALIFIED_MISSING_DATA,
} from '../constants';
import FAQLink from './FAQLink';


const mapStateToProps = (state) => ({
  user: state.auth.authUser,
  pages: state.projectPages,
  elements: state.elements,
  projectElements: state.projectElements,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  fetchProjectElements: async (pages, useCache = false) => dispatch(projectElementsActions.list({
    project_page__in: Object.keys(pages),
    admin: ownProps.admin,
  }, {
    pagination: 'no',
    cache: { useCache },
  })),
  fetchElements: async (projectElements, useCache = false) => dispatch(elementsActions.list({
    id__in: Object.values(projectElements).map((pEl) => pEl.element),
    admin: ownProps.admin,
  }, {
    pagination: 'no',
    cache: { useCache },
  })),
  fetchElementModalities: async (elements, useCache = false) => dispatch(
    elementModalitiesActions.list({
      element__in: Object.values(elements).map((el) => el.id),
      admin: ownProps.admin,
    }, {
      pagination: 'no',
      cache: { useCache },
    }),
  ),
  fetchElementLinks: async (projectElements, useCache = false) => dispatch(
    elementLinksActions.listByProjectElements(
      Object.keys(projectElements), { admin: ownProps.admin }, {
        pagination: 'no',
        cache: { useCache },
      },
    ),
  ),
  fetchPages: async (project, useCache = false) => dispatch(projectPagesActions.list({
    project: project.id,
    admin: ownProps.admin,
  }, {
    pagination: 'no',
    cache: { useCache },
  })),
});

@withToastManager
@connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })
@withTranslation('', nsOptions)
class InclusionsTable extends React.Component {
  static propTypes = {
    inclusions: PropTypes.arrayOf(PropTypes.shape()).isRequired,
    inclusionForm: PropTypes.shape(),
    pages: PropTypes.shape().isRequired,
    elements: PropTypes.shape().isRequired,
    projectElements: PropTypes.shape().isRequired,
    showProject: PropTypes.bool,
    showInclusionDate: PropTypes.bool,
    showLastChangeDate: PropTypes.bool,
    showIdentification: PropTypes.bool,
    showDataMonitoring: PropTypes.bool,
    noInclusionMsg: PropTypes.string,
    t: PropTypes.func.isRequired,
    fetchProjectElements: PropTypes.func.isRequired,
    fetchElements: PropTypes.func.isRequired,
    fetchElementModalities: PropTypes.func.isRequired,
    fetchElementLinks: PropTypes.func.isRequired,
    fetchPages: PropTypes.func.isRequired,
    sorting: PropTypes.string,
    showTestInclusions: PropTypes.bool,
  };

  static defaultProps = {
    inclusionForm: null,
    showProject: false,
    showInclusionDate: false,
    showLastChangeDate: false,
    showIdentification: false,
    showDataMonitoring: false,
    noInclusionMsg: 'project:no.inclusion',
    sorting: undefined,
    showTestInclusions: false,
  };

  constructor(props) {
    super(props);
    this.state = {
      loading: false,
    };
    this.cache = new Cache();
  }

  getSortedInclusions = () => {
    const { inclusions, sorting } = this.props;
    if (sorting === ASCENDING_PROGRESSION) {
      return inclusions.sort(SortUtil.sortInclusionsByProgression);
    }
    if (sorting === DESCENDING_PROGRESSION) {
      return inclusions.sort((i1, i2) => (
        SortUtil.sortInclusionsByProgression(i1, i2, false)
      ));
    }
    if (sorting === SORTING_BY_INCONSISTENT_DATA) {
      return inclusions.sort((i1, i2) => (
        SortUtil.sortInclusionsByInconsistentData(i1, i2, false)
      ));
    }
    if (sorting === SORTING_BY_DATE) {
      return inclusions.sort(SortUtil.sortInclusionsByDate);
    }
    if (sorting === ASCENDING_PER_PROJECT_ID) {
      return inclusions.sort(SortUtil.sortInclusionsByPerProjectId);
    }
    if (sorting === DESCENDING_PER_PROJECT_ID) {
      return inclusions.sort((i1, i2) => (
        SortUtil.sortInclusionsByPerProjectId(i1, i2, false)
      ));
    }
    if (sorting === SORTING_BY_QUALIFIED_MISSING_DATA) {
      return inclusions.sort((i1, i2) => (
        SortUtil.sortInclusionsByQualifiedMissingData(i1, i2, false)
      ));
    }
    return inclusions;
  };

  fetchProjectData = async (project) => {
    try {
      const {
        fetchPages, fetchProjectElements, fetchElements, fetchElementLinks,
        fetchElementModalities,
      } = this.props;

      await fetchPages(project, this.cache);

      const { pages } = this.props;
      await fetchProjectElements(pages, this.cache);

      const { projectElements } = this.props;
      if (Object.keys(projectElements).length > 0) {
        await Promise.all([
          fetchElements(projectElements, this.cache),
          fetchElementLinks(projectElements, this.cache),
        ]);

        const { elements } = this.props;
        await fetchElementModalities(elements, this.cache);
      }
    } catch (error) {
      ErrorUtil.handleCatched(this.props, error, false);
    }
  }

  render() {
    const {
      inclusions, inclusionForm, showProject, showInclusionDate, showLastChangeDate,
      t, noInclusionMsg, showIdentification, showDataMonitoring,
      sorting, showTestInclusions,
    } = this.props;
    const { loading } = this.state;

    const sortedInclusions = this.getSortedInclusions(inclusions, sorting);
    const inclusionsDOM = sortedInclusions.map((inclusion) => (
      <InclusionsItem
        key={inclusion.id}
        inclusion={inclusion}
        inclusionForm={inclusionForm}
        fetchProjectData={this.fetchProjectData}
        showProject={showProject}
        showInclusionDate={showInclusionDate}
        showLastChangeDate={showLastChangeDate}
        showIdentification={showIdentification}
        showDataMonitoring
        showLoader={(show = true) => this.setState({ loading: show })}
      />
    ));

    const ColumnHelp = (props) => (
      <Help
        iconClassName="ml-2"
        icon={['fal', 'question-circle']}
        interactive={props.interactive || false}
      >
        {props.children}
      </Help>
    );

    return (
      <div className="contains-loader">
        { loading && <CardLoader /> }
        <MixedView>
          <Table extraClassName={`mt-3 mb-0${!inclusionForm ? ' no-pointer' : ''}`}>
            <TableHead>
              <TableRow>
                {showLastChangeDate && showTestInclusions && (
                  <TableCell tag="th">
                    {t('project:inclusions.last-change-date')}
                  </TableCell>
                )}
                {!showTestInclusions && (
                  <>
                    <TableCell tag="th">
                      <span>
                        {t('project:inclusions.id')}
                      </span>
                      <ColumnHelp interactive>
                        {t('project:inclusions.id-tooltip')}
                        <br />
                        <FAQLink
                          articleId={3}
                        />
                      </ColumnHelp>
                    </TableCell>
                    <TableCell tag="th">
                      {t('project:inclusions.creator')}
                    </TableCell>
                  </>
                )}
                {showProject && <TableCell tag="th">{t('project:project')}</TableCell>}
                {showDataMonitoring && (
                  <TableCell tag="th">
                    {t('project:inclusions.data-monitoring')}
                  </TableCell>
                )}
                {showIdentification && (
                  <TableCell tag="th">
                    <span>
                      {t('project:inclusions.identification')}
                    </span>
                    <ColumnHelp>
                      {t('project:inclusions.identification-tooltip')}
                    </ColumnHelp>
                  </TableCell>
                )}
                {showInclusionDate && (
                  <TableCell tag="th">
                    {t('project:inclusions.date-of-inclusion')}
                  </TableCell>
                )}
                {showLastChangeDate && !showTestInclusions && (
                  <TableCell tag="th">
                    {t('project:inclusions.last-change-date')}
                  </TableCell>
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {inclusions.length > 0 ? inclusionsDOM : (
                <TableRow key="no-one" className="no-one">
                  <TableCell
                    colSpan="8"
                  >
                    {t(noInclusionMsg)}
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </MixedView>
        <MobileView>
          <div className="mt-3 mb-0">
            {inclusions.length > 0 ? inclusionsDOM : (
              <div>
                <div
                  className="align-middle text-center no-inclusion"
                >
                  {t(noInclusionMsg)}
                </div>
              </div>
            )}
          </div>
        </MobileView>
      </div>
    );
  }
}


export default InclusionsTable;
