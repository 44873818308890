import { createReducer } from 'redux-starter-kit';
import { projectElementsActions } from '../actions';
import getStdDbModelReduxMapping from './std-db-model-reducers';

export const projectElementsInitialState = {};

const mapping = getStdDbModelReduxMapping(projectElementsActions);
mapping[projectElementsActions.moveSuccess] = mapping[projectElementsActions.resyncSuccess];

const projectElementsReducer = createReducer(projectElementsInitialState, mapping);

export default projectElementsReducer;
