import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import '../assets/css/bigmenu.css';
import { nsOptions } from '../i18n';
import { childrenPropTypes } from '../utils/generic-prop-types';


@withTranslation('', nsOptions)
class DashboardMenuItem extends Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
    location: PropTypes.shape({
      pathname: PropTypes.string.isRequired,
    }).isRequired,
    to: PropTypes.string.isRequired,
    className: PropTypes.string,
    primaryContent: childrenPropTypes().isRequired,
    secondaryContent: childrenPropTypes().isRequired,
    icon: PropTypes.string.isRequired,
    colorClass: PropTypes.string.isRequired,
    disabled: PropTypes.bool,
  };

  static defaultProps = {
    className: '',
    disabled: false,
  };

  isActive = () => (
    new RegExp(`^${this.props.to}`, 'i').test(this.props.location.pathname)
  );

  render() {
    const { t } = this.props;
    return (
      <div className="col-12 col-sm dashboard-menu-link">
        <Link
          className={`dashboard-menu-item card bg-white ${this.props.colorClass}-border card-shadow p-4 ${this.isActive()
            ? 'active'
            : ''} ${this.props.disabled
            ? 'disabled'
            : ''} ${this.props.className}`}
          to={!this.props.disabled ? this.props.to : this.props.location.pathname}
        >
          <div className={`row align-items-center dashboard-menu-${this.props.colorClass}-tabs`}>
            <div className="col-auto dashboard-menu-item-icon-column">
              <span className={`fa-layers fa-fw ${this.props.colorClass}-color`}>
                <FontAwesomeIcon icon={['fal', 'circle']} transform="grow-25" />
                <FontAwesomeIcon icon={['fas', this.props.icon]} transform="grow-5" />
              </span>
            </div>
            <div className="col dashboard-menu-item-text-column">
              <div className={`text-bigger ${this.props.colorClass}-color`}>
                {this.props.primaryContent}
              </div>
              <div>
                {this.props.secondaryContent}
              </div>
            </div>
            {
              !this.isActive() && !this.props.disabled ? (
                <div className="col-auto">
                  <span className={`${this.props.colorClass}-color`}>
                    {t('common:button.view')}
                  </span>
                </div>
              ) : null
            }
            {
              this.props.disabled ? (
                <div className="ribbon">
                  <span>{t('common:soon')}</span>
                </div>
              ) : null
            }
          </div>
        </Link>
      </div>
    );
  }
}


export default DashboardMenuItem;
