import React from 'react';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import NewTooltip from './NewTooltip';
import { getFAQArticleUrl, getFAQUrl } from '../utils/urls';

function FAQLink(props) {
  const {
    text, articleId, onlyIcon, iconClassName, linkClassName, tooltipContent, iconTransform,
  } = props;
  const { t, i18n } = useTranslation();

  const link = articleId ? getFAQArticleUrl(i18n.language, articleId) : getFAQUrl(i18n.language);

  return (
    <NewTooltip
      content={tooltipContent}
      disabled={!tooltipContent}
    >
      <a
        className={linkClassName}
        href={link}
        target="_blank"
        rel="noopener noreferrer"
      >
        {onlyIcon ? (
          <FontAwesomeIcon
            icon={['fal', 'question-circle']}
            className={iconClassName}
            transform={iconTransform}
          />
        ) : (
          <span>
            {t(text)}
            <FontAwesomeIcon
              style={{ marginLeft: '0.2rem' }}
              icon={['far', 'external-link']}
              transform="shrink-3 down-1"
            />
          </span>
        )}
      </a>
    </NewTooltip>
  );
}

FAQLink.propTypes = {
  text: PropTypes.string,
  articleId: PropTypes.number,
  onlyIcon: PropTypes.bool,
  iconClassName: PropTypes.string,
  iconTransform: PropTypes.string,
  linkClassName: PropTypes.string,
  tooltipContent: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
};

FAQLink.defaultProps = {
  text: 'common:discover-feature',
  articleId: null,
  onlyIcon: false,
  iconClassName: 'text-white',
  iconTransform: '',
  linkClassName: 'classical-link text-newblue-1 small',
  tooltipContent: undefined,
};

export default FAQLink;
