import React from 'react';
import withMsgModalBase from './withMsgModalBase';
import { DataDisclosureMsgModal } from './MessageModal';


const withDataDisclosureMsgModal = (forwardRef = false) => (ComponentBase) => {
  const ComponentWithDataDisclosureMsgModal = withMsgModalBase(
    ComponentBase,
    DataDisclosureMsgModal,
    'getDiscMsgModalManager',
  );
  if (forwardRef) {
    return React.forwardRef((props, ref) => (
      <ComponentWithDataDisclosureMsgModal {...props} forwardedRef={ref} />
    ));
  }
  return ComponentWithDataDisclosureMsgModal;
};

export default withDataDisclosureMsgModal;
