import PropTypes from 'prop-types';
import React, { Component } from 'react';
import memoize from 'memoize-one';
import { withTranslation } from 'react-i18next';
import { nsOptions } from '../i18n';
import ElementBaseLink from './ElementBaseLink';
import LabeledSelect from './LabeledSelect';
import LabeledInput from './LabeledInput';


@withTranslation('', nsOptions)
class ElementQuantitativeLink extends Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
    id: PropTypes.number.isRequired,
    identifier: PropTypes.node.isRequired,
    elementOptions: PropTypes.arrayOf(PropTypes.shape()).isRequired,
    choosenAction: PropTypes.string,
    choosenValue: PropTypes.string,
    choosenElement: PropTypes.number,
    highlightMissing: PropTypes.bool,
    remove: PropTypes.func.isRequired,
    onChange: PropTypes.func,
  };

  static defaultProps = {
    choosenAction: 'none',
    choosenValue: '',
    choosenElement: undefined,
    highlightMissing: false,
    onChange: null,
  };

  constructor(props) {
    super(props);
    this.state = {
      action: this.props.choosenAction,
      value: this.props.choosenValue,
      source: this.props.choosenElement,
    };
    this.memoizeValidation = memoize((highlightMissing, actionRef, action, valueRef, value) => {
      if (actionRef && highlightMissing) {
        actionRef.validate(action === 'none' ? null : action);
      }
      if (valueRef && highlightMissing) {
        valueRef.validate(value);
      }
    });
  }

  componentDidUpdate() {
    const { highlightMissing } = this.props;
    const { action, value } = this.state;
    this.memoizeValidation(highlightMissing, this.actionRef, action, this.valueRef, value);
  }

  callParentHandler = () => {
    if (this.props.onChange) {
      this.props.onChange({
        id: this.props.id,
        action: this.state.action,
        value: this.state.value,
        source: Number(this.state.source),
      });
    }
  };

  handleActionChange = (e) => {
    this.setState({ action: e.target.value }, this.callParentHandler);
  };

  handleValueChange = (e) => {
    this.setState({ value: e.target.value }, this.callParentHandler);
  };

  handleSourceChange = ({ source }) => {
    this.setState({ source }, this.callParentHandler);
  };

  setActionRef = (ref) => {
    const { highlightMissing } = this.props;
    const { action, value } = this.state;
    this.actionRef = ref;
    this.memoizeValidation(highlightMissing, this.actionRef, action, this.valueRef, value);
  };

  setValueRef = (ref) => {
    const { highlightMissing } = this.props;
    const { action, value } = this.state;
    this.valueRef = ref;
    this.memoizeValidation(highlightMissing, this.actionRef, action, this.valueRef, value);
  };

  render() {
    const {
      t, elementOptions, highlightMissing, id, identifier, remove, choosenElement,
    } = this.props;
    const { action, value } = this.state;
    return (
      <ElementBaseLink
        className="element-link element-quantitative-link"
        divSelectClassName="col-auto"
        selectClassName="custom-control custom-select qtlink-var-select"
        defaultElementChoice="common:form.select-variable"
        id={id}
        identifier={identifier}
        elementOptions={elementOptions}
        highlightMissing={highlightMissing}
        source={choosenElement}
        remove={remove}
        onChange={this.handleSourceChange}
      >
        <div className="col-auto form-inline font-italic">
          {t('project:modal.link.is')}
        </div>
        <div className="col-auto">
          <LabeledSelect
            name="action"
            required
            className="custom-control custom-select qtlink-action-select"
            value={action}
            onChange={this.handleActionChange}
            ref={this.setActionRef}
          >
            {/* TODO: Tooltip to show */}
            <option value="none" disabled>
              {t('common:form.select')}
            </option>
            <option value="eq">
              {t('project:modal.link.operators.eq')}
            </option>
            <option value="gt">
              {t('project:modal.link.operators.gt')}
            </option>
            <option value="lt">
              {t('project:modal.link.operators.lt')}
            </option>
            <option value="gteq">
              {t('project:modal.link.operators.gteq')}
            </option>
            <option value="lteq">
              {t('project:modal.link.operators.lteq')}
            </option>
          </LabeledSelect>
        </div>
        <div className="col-auto">
          <LabeledInput
            name="value"
            required
            type="text"
            className="form-control"
            placeholder={t('project:modal.link.enter-value')}
            value={value}
            onChange={this.handleValueChange}
            ref={this.setValueRef}
          />
        </div>
      </ElementBaseLink>
    );
  }
}


export default ElementQuantitativeLink;
