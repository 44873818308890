import React, { Component } from 'react';
import PropTypes from 'prop-types';
import memoize from 'memoize-one';
import { withTranslation } from 'react-i18next';
import { withToastManager } from 'react-toast-notifications';
import { connect } from 'react-redux';
import { projectEntriesActions, elementModalitiesActions } from '../redux/actions';
import { nsOptions } from '../i18n';
import api from '../api';
import ElementBase, { retrieveProjectEntries } from './ElementBase';
import withChoicesManagement from './withChoicesManagement';
import Help from './Help';
import CustomAsyncSelect, { defaultStyles } from './CustomAsyncSelect';
import ElementUtil from '../utils/ElementUtil';
import ErrorUtil from '../utils/ErrorUtil';
import Toast from '../utils/Toast';
import {
  ELEMENT_TYPE_MULTIPLE_CHOICES, ELEMENT_TYPE_UNIQUE_CHOICE, ENTRY_TYPE_MULTIPLE_CHOICES,
  ENTRY_TYPE_UNIQUE_CHOICE,
} from '../constants';
import SortUtil from '../utils/SortUtil';
import fromReduxState from '../utils/redux';

const mapStateToProps = (state, ownProps) => ({
  element: state.elements[ownProps.elementId],
  inclusion: state.inclusions[ownProps.inclusionId],
  projectElement: state.projectElements[ownProps.projectElementId],
  projectEntries: state.projectEntries,
  projectEntryMissing: ownProps.inclusion ? retrieveProjectEntries(
    Object.values(state.projectEntries),
    ownProps.inclusionId,
    ownProps.projectElementId,
    ownProps.moduleInstanceId,
  ).find((entry) => entry.missing) : null,
  elementModalities: state.elementModalities,
});

const mapDispatchToProps = (dispatch) => ({
  addEntry: async (data) => dispatch(projectEntriesActions.create(data)),
  removeEntry: async (id) => dispatch(projectEntriesActions.remove(id)),
  patchEntry: async (id, data) => dispatch(projectEntriesActions.patch(id, data)),
  fetchElementModality: async (id) => dispatch(elementModalitiesActions.read(id)),
  resyncProjectEntries: async () => dispatch(projectEntriesActions.resync()),
});


@withChoicesManagement
@connect(
  mapStateToProps,
  mapDispatchToProps,
  null,
  { forwardRef: true },
)
@withToastManager
@withTranslation('', nsOptions)
class ElementImplementableChoice extends Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
    elementId: PropTypes.number.isRequired,
    projectElementId: PropTypes.number,
    moduleInstanceId: PropTypes.number,
    inclusion: PropTypes.shape(),
    inclusionId: PropTypes.number,
    element: PropTypes.shape().isRequired,
    projectElement: PropTypes.shape().isRequired,
    projectEntries: PropTypes.shape(),
    projectEntryMissing: PropTypes.shape(),
    elementModalities: PropTypes.shape().isRequired,
    admin: PropTypes.bool,
    methods: PropTypes.shape({
      checkLinks: PropTypes.func,
    }).isRequired,
    choiceMethods: PropTypes.shape({
      addValue: PropTypes.func.isRequired,
    }).isRequired,
    isReadOnly: PropTypes.bool,
    isEntryMode: PropTypes.bool,
    isEditMode: PropTypes.bool,
    isAnonymized: PropTypes.bool,
    sortedItems: PropTypes.arrayOf(PropTypes.string).isRequired,
    user: PropTypes.shape().isRequired,
    elementSortingHandlerId: PropTypes.string.isRequired,
    modalOpened: PropTypes.bool,
    patchEntry: PropTypes.func.isRequired,
    addEntry: PropTypes.func.isRequired,
    removeEntry: PropTypes.func.isRequired,
    fetchElementModality: PropTypes.func.isRequired,
    resyncProjectEntries: PropTypes.func.isRequired,
  };

  static defaultProps = {
    projectElementId: null,
    moduleInstanceId: null,
    inclusion: null,
    inclusionId: null,
    projectEntries: null,
    projectEntryMissing: null,
    admin: false,
    isReadOnly: false,
    isEntryMode: false,
    isEditMode: false,
    isAnonymized: false,
    modalOpened: false,
  };

  constructor(props) {
    super(props);
    this.state = {
      loading: false,
    };
    this.getPEntries = memoize((
      projectEntries,
      inclusionId,
      projectElementId,
      moduleInstanceId,
    ) => (
      inclusionId ? retrieveProjectEntries(
        Object.values(projectEntries), inclusionId, projectElementId, moduleInstanceId,
      ).filter((entry) => !entry.missing) : null
    ));
    this.toElModalitiesArray = fromReduxState((elementModalities, elementId) => (
      Object.values(elementModalities).filter((modality) => (
        modality.element === elementId
      ))
    ));
    this.memoizedToSelectValue = memoize(this.toSelectValue);
  }

  checkLinks = () => {
    if (this.elBaseRef) this.elBaseRef.checkLinks();
  };

  showLoader = (show = true) => {
    if (this.elBaseRef) this.elBaseRef.showLoader(show);
  };

  isMultipleChoice = () => {
    const { element } = this.props;
    return element && element.type === ELEMENT_TYPE_MULTIPLE_CHOICES;
  };

  toSelectValue = (projectEntries, elementModalities) => {
    const { fetchElementModality, t, isAnonymized } = this.props;
    const getLabel = (id) => {
      const modality = elementModalities.find((mod) => mod.id === id);
      if (!modality) {
        // Usefull if a new label has been created by another user while being inside project
        // inclusions. The asynchronous request will end later and trigger a new render.
        fetchElementModality(id);
        return id;
      }
      return ElementUtil.formatModalityName(modality, t);
    };

    if (projectEntries.length && !isAnonymized) {
      return projectEntries.map((entry) => ({ value: entry.value, label: getLabel(entry.value) }));
    }
    return [];
  };

  loadChoices = async (search) => {
    const { elementModalities: elModalities, elementId, t } = this.props;
    const elementModalities = this.toElModalitiesArray(elModalities, elementId);
    let filteredModalities = elementModalities;

    if (search) {
      try {
        const res = await api.list('element-modalities', { element: elementId, search },
          { no_pagination: true });
        filteredModalities = res.results;
      } catch (error) {
        ErrorUtil.handleCatched(this.props, error, false);
      }
    }

    return filteredModalities.map((modality) => ({
      value: modality.id,
      label: ElementUtil.formatModalityName(modality, t),
    })).sort(SortUtil.sortModalities);
  };

  updateEntry = async (newValue) => {
    const {
      isEntryMode, projectEntries: pEntries, choiceMethods, addEntry, patchEntry, removeEntry,
      inclusionId, projectElement, moduleInstanceId, methods, projectEntryMissing,
      resyncProjectEntries, projectElementId,
    } = this.props;
    const projectEntries = this.getPEntries(
      pEntries, inclusionId, projectElementId, moduleInstanceId,
    );

    if (isEntryMode) {
      this.setState({ loading: true });

      const type = this.isMultipleChoice() ? ENTRY_TYPE_MULTIPLE_CHOICES
        : ENTRY_TYPE_UNIQUE_CHOICE;

      const getChoiceId = async (choice) => {
        // Create new modalities if needed
        if (choice.__isNew__) {
          const modality = await choiceMethods.addValue(choice.value);
          return modality.id;
        }
        return choice.value;
      };

      try {
        this.showLoader();
        if (!this.isMultipleChoice()) {
          if (newValue) {
            const value = await getChoiceId(newValue);
            if (projectEntries.length) {
              await patchEntry(projectEntries[0].id, { value });
            } else {
              await addEntry({
                type,
                inclusion: inclusionId,
                project_element: projectElement.id,
                module: moduleInstanceId,
                value,
              });
            }
          } else if (projectEntries.length) {
            await removeEntry(projectEntries[0].id);
          }
        } else {
          const newValues = newValue && newValue.length ? newValue : [];
          const values = await Promise.all(newValues.map((choice) => getChoiceId(choice)));
          await Promise.all(projectEntries.filter((projectEntry) => (
            !values.find((value) => (
              value === projectEntry.value
            )))).map((projectEntry) => removeEntry(projectEntry.id)));
          await Promise.all(values.filter((value) => !projectEntries.find((projectEntry) => (
            projectEntry.value === value
          ))).map((value) => addEntry({
            type,
            inclusion: inclusionId,
            project_element: projectElement.id,
            module: moduleInstanceId,
            value,
          })));
        }
        // The back has deleted this entry so we need to resync
        if (projectEntryMissing) {
          await resyncProjectEntries();
        }
        Toast.success(this.props, 'error:valid.saved');
        if (methods.checkLinks) methods.checkLinks();
        if (this.elBaseRef) this.elBaseRef.checkForCorruptedMissingDataCount();
      } catch (error) {
        ErrorUtil.handleCatched(this.props, error, true);
      } finally {
        this.setState({ loading: false });
        this.showLoader(false);
      }
    }
  };

  render() {
    const {
      isEditMode, isEntryMode, t, projectEntries: pEntries, elementModalities: elModalities,
      isReadOnly, projectEntryMissing, inclusionId, projectElementId, moduleInstanceId, elementId,
    } = this.props;
    const { loading } = this.state;

    const elementModalities = this.toElModalitiesArray(elModalities, elementId);

    const projectEntries = this.getPEntries(
      pEntries, inclusionId, projectElementId, moduleInstanceId,
    );

    const styles = {
      ...defaultStyles,
      placeholder: (provided, state) => ({
        ...provided,
        position: 'absolute',
        top: (state.hasValue || state.selectProps.inputValue) ? -8 : '50%',
        transition: 'top 0.1s, font-size 0.1s',
        fontSize: (state.hasValue || state.selectProps.inputValue) && 13,
        width: (state.hasValue || state.selectProps.inputValue) && 'max-content',
        color: 'var(--gray-dark)',
      }),
    };

    const missingData = (!projectEntries || !projectEntries.length) && !projectEntryMissing ? 'empty' : '';
    const qualifiedMissingData = projectEntryMissing && projectEntryMissing.missing ? 'qualified-missing' : '';

    return (
      <ElementBase
        {...this.props}
        elementCategory="entry"
        subClassName="align-items-center"
        siblingType={this.isMultipleChoice() ? {
          data: { type: ELEMENT_TYPE_UNIQUE_CHOICE },
          name: 'project:form.switch-to-type.uniquechoice-type',
        } : {
          data: { type: ELEMENT_TYPE_MULTIPLE_CHOICES },
          name: 'project:form.switch-to-type.multiplechoices-type',
        }}
        ref={(ref) => { this.elBaseRef = ref; }}
      >
        <div
          className="col-12 col-sm-8 col-md-11 col-lg-10 col-xl-8"
        >
          <CustomAsyncSelect
            creatable
            placeholder={t('project:form.select-or-create-choice')}
      /* Next line enables to reload options when a new one has been */
      /* added (no direct way to force loadOptions to be recalled) */
            key={elementModalities.length}
            className={`react-select creatable-select element-impl-choice ${missingData}${qualifiedMissingData}`}
            isClearable={!this.isMultipleChoice()}
            isLoading={loading}
            isMulti={this.isMultipleChoice()}
            loadOptions={this.loadChoices}
            noOptionsMessage={() => t('project:form.no-response')}
            isDisabled={isEditMode || loading || isReadOnly}
            formatCreateLabel={(value) => (
              <span className="font-weight-semibold text-primary">
                {t('project:form.create-impl-choice')}
                &nbsp;
                &nbsp;
                {value && value !== '' ? (
                  <span className="badge badge-newturquoise-1">{value}</span>
                ) : ''}
              </span>
            )}
            closeMenuOnSelect={!this.isMultipleChoice()}
            blurInputOnSelect={!this.isMultipleChoice()}
            onChange={this.updateEntry}
            value={isEntryMode ? this.memoizedToSelectValue(projectEntries, elementModalities)
              : undefined}
            styles={styles}
            ref={(ref) => { this.creatableSelect = ref; }}
          />
        </div>
        {
          isEditMode ? (
            <div className="col-auto px-0">
              <Help
                transform="grow-2"
              >
                {t('common:elements.help.implementable-choice')}
              </Help>
            </div>
          ) : null
        }
      </ElementBase>
    );
  }
}

export default ElementImplementableChoice;
