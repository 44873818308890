import moment from 'moment';

export default class DbUtil {
  // DEPRECATED
  static generateId() {
    return (moment.utc().valueOf() / 1000 | 0).toString(16)
      + 'xxxxxxxxxxxxxxxx'.replace(/[x]/g, () => (Math.random() * 16 | 0)
        .toString(16)).toLowerCase();
  }

  static getTemporaryId(notIn = []) {
    let id = 1;
    if (notIn.length > 0) id = Math.max(...notIn) + 1;
    return id;
  }
}
