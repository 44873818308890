import { checkLinks } from './links';
import { TARGET_TYPE_ELEMENT } from '../constants';

/* --- MISSING DATA --- */

/**
   Structure of the result :
   {
     pageId: {
       sorting: {
         id: projectElementId,
         type: 'variable',
         sorting: sorting,
       },
       sorting: {
         id: projectElementId,
         type: 'module',
         entries: {
           id: {
             id: projectEntryId,
             type: 'entry',
             childs: {
               sorting: {
                 id: projectElementId,
                 type: 'variable',
                 sorting: sorting,
               },
               ...
             }
           },
           ...
         }
       },
       ...
     },
     ...
   }
*/

const extractFromProjectElements = (
  elementToFillIds, projectEntries, pages, projectElements, rootProjectElements, elements,
  elementLinks, inclusion, filteringCondition = () => false,
) => {
  // Function that checks a variable link conditions
  const checkLks = (prjElement, moduleInstanceId = null) => checkLinks(
    elements, elementLinks, projectElements, rootProjectElements, projectEntries, pages, prjElement,
    TARGET_TYPE_ELEMENT, inclusion.id, moduleInstanceId,
  );

  const initializeModule = (moduleId) => ({ id: moduleId, type: 'module', entries: {} });
  const initializeEntry = (entryId) => ({ id: entryId, type: 'entry', childs: {} });

  // Keep only the desired projectElements
  const filteredProjectElements = projectElements.filter((pElement) => (
    filteringCondition(pElement) && elementToFillIds.includes(pElement.element)));

  // Initialize the final result
  const res = {};

  // Iterate over each filtered projectElement
  filteredProjectElements.forEach((pElement) => {
    const pageId = pElement.project_page;

    // The projectElement is a module or is out of a module
    if (!pElement.module && checkLks(pElement)) {
      // Check if it has entries
      const entries = projectEntries.filter((entry) => entry.project_element === pElement.id);

      // If there are no entries, store the projectElement
      if (!entries.length) {
        if (!res[pageId]) {
          res[pageId] = {};
        }
        res[pageId][pElement.sorting] = {
          id: pElement.id,
          type: 'variable',
          sorting: pElement.sorting,
        };
      }
    } else if (pElement.module) {
      // The projectElement is inside a module
      const module = projectElements.find((pEl) => pEl.id === pElement.module);
      if (checkLks(module)) {
        // Check if the module has entries
        const moduleEntries = projectEntries.filter((entry) => entry.project_element === module.id);
        // For each entry, check if the projectElement has entries
        moduleEntries.forEach((moduleEntry) => {
          const moduleEntryId = moduleEntry.id;
          const entries = projectEntries.filter((entry) => (
            entry.project_element === pElement.id && entry.module === moduleEntryId));

          // First check if it is triggered in the corresponding module entry
          // Then check if there is an entry for the corresponding module entry
          if (checkLks(pElement, moduleEntryId)
            && !entries.length) {
            // If no, store the projectElement and the corresponding module entry
            if (!res[pageId]) {
              res[pageId] = {};
              res[pageId][module.sorting] = initializeModule(module.id);
              res[pageId][module.sorting].entries[moduleEntryId] = initializeEntry(moduleEntryId);
            } else if (!res[pageId][module.sorting]) {
              res[pageId][module.sorting] = initializeModule(module.id);
              res[pageId][module.sorting].entries[moduleEntryId] = initializeEntry(moduleEntryId);
            } else if (!res[pageId][module.sorting].entries[moduleEntryId]) {
              res[pageId][module.sorting].entries[moduleEntryId] = initializeEntry(moduleEntryId);
            }
            res[pageId][module.sorting].entries[moduleEntryId].childs[pElement.sorting] = {
              id: pElement.id,
              type: 'variable',
              sorting: pElement.sorting,
            };
          }
        });
      }
    }
  });
  return res;
};

// Empty required projectElements
export const extractEmptyProjectElements = (
  elementToFillIds, projectEntries, pages, projectElements, rootProjectElements, elements,
  elementLinks, inclusion,
) => extractFromProjectElements(
  elementToFillIds, projectEntries, pages, projectElements, rootProjectElements, elements,
  elementLinks, inclusion, (pEl) => pEl.required,
);

// Empty optional projectElements
export const extractEmptyOptProjectElements = (
  elementToFillIds, projectEntries, pages, projectElements, rootProjectElements, elements,
  elementLinks, inclusion,
) => extractFromProjectElements(
  elementToFillIds, projectEntries, pages, projectElements, rootProjectElements, elements,
  elementLinks, inclusion, (pEl) => !pEl.required,
);


/* -- PROGRESS BAR COLOR -- */


const LOW_LINE_COLOR = 'var(--red)';
const AVERAGE_LINE_COLOR = '#FFC100';
const HIGH_LINE_COLOR = 'var(--newturquoise-2)';

const TOOLTIP_TURQUOISE_THEME = 'data-status-turquoise';
const TOOLTIP_ORANGE_THEME = 'data-status-orange';
const TOOLTIP_RED_THEME = 'data-status-red';

const MIN = 50;
const MAX = 100;

export const getMissingDataColors = (progression) => {
  const res = {};
  if (progression < MIN) {
    res.progressBarColor = LOW_LINE_COLOR;
    res.tooltipColor = TOOLTIP_RED_THEME;
  } else if (progression >= MIN && progression < MAX) {
    res.progressBarColor = AVERAGE_LINE_COLOR;
    res.tooltipColor = TOOLTIP_ORANGE_THEME;
  } else {
    res.progressBarColor = HIGH_LINE_COLOR;
    res.tooltipColor = TOOLTIP_TURQUOISE_THEME;
  }
  return res;
};


/* --- INCONSISTENT DATA & QUALIFIED MISSING DATA --- */

/**
   Structure of the result :
   {
     pageId: {
       sorting: {
         id: projectElementId,
         type: 'variable',
         sorting: sorting,
         ...extraInfo
       },
       sorting: {
         id: projectElementId,
         type: 'module',
         entries: {
           id: {
             id: projectEntryId,
             type: 'entry',
             childs: {
               sorting: {
                 id: projectElementId,
                 type: 'variable',
                 sorting: sorting,
                 ...extraInfo
               },
               ...
             }
           },
           ...
         }
       },
       ...
     },
     ...
   }
*/

const extractFromProjectEntries = (
  projectEntries, pages, projectElements, rootProjectElements, elements, elementLinks, inclusion,
  filterCondition = () => false, extraInfo = () => {},
) => {
  // Function that checks a variable link conditions
  const checkLks = (prjElement, moduleInstanceId = null) => checkLinks(
    elements, elementLinks, projectElements, rootProjectElements, projectEntries, pages, prjElement,
    TARGET_TYPE_ELEMENT, inclusion.id, moduleInstanceId,
  );

  const initializeModule = (moduleId) => ({ id: moduleId, type: 'module', entries: {} });
  const initializeEntry = (entryId) => ({ id: entryId, type: 'entry', childs: {} });

  // Initialize the final result
  const res = {};

  // Keep only the desired projectEntries
  const filteredProjectEntries = projectEntries.filter((en) => filterCondition(en));

  // Iterate over each filtered projectEntry
  filteredProjectEntries.forEach((pEntry) => {
    // Retrieve the corresponding projectElement
    const pElement = projectElements.find((pEl) => pEl.id === pEntry.project_element);

    const pageId = pElement.project_page;

    // The entry corresponds to a projectElement inside a module
    if (pEntry.module) {
      // Retrieve the corresponding module entry
      const moduleId = projectEntries.find((en) => en.id === pEntry.module).project_element;
      const module = projectElements.find((pEl) => pEl.id === moduleId);

      // First, check that the module is triggered
      // Then, check that the projectElement is triggered in the corresponding module entry
      if (checkLks(module) && checkLks(pElement, pEntry.module)) {
        const moduleInstanceId = pEntry.module;

        // Store the result
        if (!res[pageId]) {
          res[pageId] = {};
          res[pageId][module.sorting] = initializeModule(module.id);
          res[pageId][module.sorting].entries[moduleInstanceId] = initializeEntry(moduleInstanceId);
        } else if (!res[pageId][module.sorting]) {
          res[pageId][module.sorting] = initializeModule(module.id);
          res[pageId][module.sorting].entries[moduleInstanceId] = initializeEntry(moduleInstanceId);
        } else if (!res[pageId][module.sorting].entries[moduleInstanceId]) {
          res[pageId][module.sorting].entries[moduleInstanceId] = initializeEntry(moduleInstanceId);
        }
        res[pageId][module.sorting].entries[moduleInstanceId].childs[pElement.sorting] = {
          id: pElement.id,
          type: 'variable',
          sorting: pElement.sorting,
          ...extraInfo(pEntry),
        };
      }
    } else if (checkLks(pElement)) {
      // The entry corresponds to a projectElement outside a module
      if (!res[pageId]) {
        res[pageId] = {};
      }
      res[pageId][pElement.sorting] = {
        id: pElement.id,
        type: 'variable',
        sorting: pElement.sorting,
        ...extraInfo(pEntry),
      };
    }
  });
  return res;
};

// Inconsistent entries
export const extractInconsistentEntries = (
  projectEntries, pages, projectElements, rootProjectElements, elements, elementLinks, inclusion,
) => extractFromProjectEntries(
  projectEntries, pages, projectElements, rootProjectElements, elements, elementLinks, inclusion,
  (en) => !en.consistent,
);

// Qualified missing entries
export const extractQualifiedEmptyEntries = (
  projectEntries, pages, projectElements, rootProjectElements, elements, elementLinks, inclusion,
) => extractFromProjectEntries(
  projectEntries, pages, projectElements, rootProjectElements, elements, elementLinks, inclusion,
  (en) => en.missing, (en) => ({ missing: en.missing }),
);
