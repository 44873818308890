import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import { childrenPropTypes } from '../utils/generic-prop-types';
import api from '../api';
import { nsOptions } from '../i18n';
import NewModal from './NewModal';
import { CardLoader } from './Loader';
import ErrorUtil from '../utils/ErrorUtil';
import Pagination from './Pagination';
import LabeledSelect from './LabeledSelect';
import NewTooltip from './NewTooltip';
import {
  DATE_TIME, TIME, ELEMENT_TYPE_MODULE, ELEMENT_TYPE_MULTIPLE_CHOICES,
  ELEMENT_TYPE_UNIQUE_CHOICE, ELEMENT_TYPE_DATE, ELEMENT_TYPE_TIME, DATE_FULL,
} from '../constants';
import {
  TIME_FORMAT, API_DATE_TIME_FORMAT, toMoment,
} from '../utils/date';
import Formatter from '../utils/Formatter';
import ElementUtil from '../utils/ElementUtil';

const VARIABLE = 'variable';
const CREATE = 'create';
const DELETE = 'delete';
const CREATE_OR_UPDATE = 'create-or-update';

const CHRONOLOGICAL = 'created_at';
const REVERSE_CHRONOLOGICAL = '-created_at';

const mapStateToProps = (state) => ({
  projectUsers: state.projectUsers,
});

const getActionIcon = (action) => {
  let actionIcon = ['fas', 'pencil'];
  let transform = 'grow-2';
  if (action === CREATE) {
    actionIcon = ['fas', 'plus'];
  }
  if (action === DELETE) {
    actionIcon = ['fas', 'times'];
    transform = 'grow-3';
  }
  return (
    <span
      className="text-gray mr-3"
    >
      <FontAwesomeIcon
        className="fa-fw"
        icon={actionIcon}
        transform={transform}
        // The delete icon does not seem vertically centered
        style={(action === DELETE) && { marginTop: '0.2rem' }}
      />
    </span>
  );
};

@connect(mapStateToProps)
@withTranslation('', nsOptions)
class AuditTrailModal extends Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
    title: PropTypes.string.isRequired,
    children: childrenPropTypes().isRequired,
    project: PropTypes.shape(),
    projectId: PropTypes.number,
    projectElement: PropTypes.shape(),
    inclusion: PropTypes.shape(),
    moduleInstanceId: PropTypes.number,
    showInclusion: PropTypes.bool,
    showVariable: PropTypes.bool,
    showModifications: PropTypes.bool,
    showModule: PropTypes.bool,
    showEntry: PropTypes.bool,
    projectUsers: PropTypes.shape().isRequired,
    size: PropTypes.string,
  };

  static defaultProps = {
    project: undefined,
    projectId: null,
    projectElement: undefined,
    inclusion: undefined,
    moduleInstanceId: null,
    showInclusion: false,
    showVariable: false,
    showModifications: false,
    showModule: false,
    showEntry: false,
    size: 'lg',
  };

  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      data: undefined,
      dataCount: 0,
      pageSize: 1,
      page: 1,
      ordering: REVERSE_CHRONOLOGICAL,
    };
  }

  fetchData = async () => this.fetchPaginatedData(1, true);

  fetchPaginatedData = async (page, manageLoader = true) => {
    if (manageLoader) this.setState({ loading: true });
    const {
      project, projectId, projectElement, inclusion, moduleInstanceId,
    } = this.props;
    const { ordering } = this.state;
    if (project || projectId) {
      try {
        const params = {
          object_model: 'ProjectEntry',
          page,
          ordering,
          details__project: projectId || project.id,
        };
        if (inclusion) {
          params.details__inclusion = inclusion.id;
        }
        if (projectElement) {
          params.details__project_element = projectElement.id;
        }
        if (moduleInstanceId) {
          params.details__module = moduleInstanceId;
        }

        const res = await api.requestData('audit-trail', null, 'get', params, undefined);
        this.setState({
          page,
          dataCount: res.count,
          pageSize: Math.max(res.results.length, this.state.pageSize),
          data: res,
        });
      } catch (error) {
        ErrorUtil.handleCatched(this.props, error);
      } finally {
        if (manageLoader) this.setState({ loading: false });
      }
    }
  };

  formatName = (name, isModality = false) => {
    const { t } = this.props;
    if (isModality) {
      return ElementUtil.formatModalityName({ name }, t);
    }
    return ElementUtil.formatElementName({ name }, t);
  }

  renderModalContent = () => {
    const {
      t, showInclusion, showVariable, showModifications, showEntry, projectUsers, showModule,
    } = this.props;
    const { data } = this.state;
    const content = data.results;
    const formatter = new Formatter(DATE_TIME, undefined);

    return (
      <ul
        key="audit-list"
        className="list-group"
      >
        {
          content.map((line) => {
            // General information
            const userId = line.user;
            const projectUser = Object.values(projectUsers).filter((pUser) => (
              pUser.user.id === userId))[0];
            let userName;
            if (userId && projectUser) {
              userName = projectUser.user.label;
            } else {
              // The user is no longer in the project
              userName = line.user_name;
            }
            let action = line.action_type;
            const formattedDate = formatter.format(moment(line.created_at));
            const inclusionId = line.details.inclusion__per_project_id;
            const type = line.details.type === ELEMENT_TYPE_MODULE ? ELEMENT_TYPE_MODULE : VARIABLE;

            // Modification information
            let { value } = line.details;
            const { missing } = line.details;
            // Format value when needed
            if (missing) {
              if (action !== DELETE) {
                action = CREATE_OR_UPDATE;
              }
              const missingCode = missing.replace('_', '-');
              action = `${action}-${missingCode}`;
              value = t(`project:audit-trail.${missingCode}`);
            } else if (showModifications) {
              const valueType = line.details.type;
              if (!value && (valueType === ELEMENT_TYPE_MULTIPLE_CHOICES
                // Handle modalities with no name
                || valueType === ELEMENT_TYPE_UNIQUE_CHOICE)) {
                value = this.formatName(value, true);
              } else if (value && valueType === ELEMENT_TYPE_DATE) {
                const dateType = line.details.element__format || DATE_FULL;
                const jsValue = toMoment(value, dateType, API_DATE_TIME_FORMAT, true);
                // The date is not valid when it's anonymized
                if (jsValue.isValid()) {
                  const valueFormater = new Formatter(dateType, undefined);
                  value = valueFormater.format(jsValue);
                }
              } else if (value && valueType === ELEMENT_TYPE_TIME) {
                const jsValue = toMoment(value, TIME_FORMAT);
                // The time is not valid when it's anonymized
                if (jsValue.isValid()) {
                  const valueFormater = new Formatter(TIME, undefined);
                  value = valueFormater.format(jsValue);
                }
              }
            }

            const variableName = this.formatName(line.details.element__name);
            let moduleName;
            let entryName;
            if (showModule && line.details.module__name) {
              moduleName = this.formatName(line.details.module__name);
              if (showEntry && line.details.module__instance__name) {
                entryName = line.details.module__instance__name;
              }
            }
            return (
              <li
                key={`audit-line-${line.id}`}
                className="list-group-item d-flex align-items-center px-0 border-0"
              >
                {getActionIcon(line.action_type)}
                <span>
                  <b>
                    {userName}
                  </b>
                  &nbsp;
                  {missing ? (
                    <>
                      {t(`project:audit-trail.action${showModifications ? '.detailed' : ''}.${action}`)}
                    </>
                  ) : (
                    <>
                      {t(`project:audit-trail.action${showModifications ? '.detailed' : ''}.${action}-${type}`)}
                    </>
                  )}
                  {
                    showModifications && (
                      <span className="font-weight-semibold">
                        &nbsp;
                        {value}
                      </span>
                    )
                  }
                  { showVariable && (
                    <>
                      &nbsp;
                      {t(`project:audit-trail.object.${type}`)}
                      &nbsp;
                      <b>{variableName}</b>
                    </>
                  )}
                  {
                    (showModule && moduleName) && (
                      <>
                        &nbsp;
                        [
                        {moduleName}
                        {
                          (showEntry && entryName) && (
                            <>
                              &nbsp;-&nbsp;
                              {entryName}
                            </>
                          )
                        }
                        ]
                      </>
                    )
                  }
                  { showInclusion && (
                    <>
                      &nbsp;
                      {t('project:audit-trail.inclusion-in')}
                      <b>
                        {t('project:audit-trail.inclusion', { id: inclusionId })}
                      </b>
                    </>
                  )}
                  &nbsp;
                  {t('project:audit-trail.date', { date: formattedDate })}
                </span>
              </li>
            );
          })
        }
      </ul>
    );
  }

  onOrderingChange = async (event) => {
    await this.setState({ ordering: event.target.value });
    this.fetchPaginatedData(1, true);
  }

  render() {
    const {
      t, children, title, size,
    } = this.props;
    const {
      page, pageSize, data, dataCount, loading, ordering,
    } = this.state;

    return (
      <NewModal
        trigger={children}
        title={(
          <span>
            {title}
            <FontAwesomeIcon
              icon={['far', 'history']}
              className="text-gray ml-3"
              style={{ opacity: 0.7 }}
            />
          </span>
        )}
        size={size}
        type={1}
        ref={(modal) => {
          this.modal = modal;
        }}
        onLoad={this.fetchData}
        extraClass={`audit-trail-modal-${size}`}
      >
        {(data && data.count) ? (
          <div className="container mb-4">
            <div
              className="row align-items-center"
            >
              <NewTooltip
                content={t('common:tooltip.sort')}
              >
                <FontAwesomeIcon
                  icon={['fas', 'sort-alt']}
                  className="mr-2 mt-2 text-gray-dark"
                  transform="grow-4"
                />
              </NewTooltip>
              <LabeledSelect
                className="mb-0 d-inline-block"
                selectClassName="custom-select audit-trail-ordering-select"
                name="sort-by-date"
                hideOptionalLabel
                onChange={this.onOrderingChange}
                value={ordering}
              >
                <option
                  key="reverse-chronological"
                  value={REVERSE_CHRONOLOGICAL}
                >
                  {t('project:audit-trail.reverse-chronological')}
                </option>
                <option
                  key="chronological"
                  value={CHRONOLOGICAL}
                >
                  {t('project:audit-trail.chronological')}
                </option>
              </LabeledSelect>
            </div>
          </div>
        ) : null}
        {
          this.state.loading ? (
            <CardLoader />
          ) : null
        }
        {
          (data && data.count && !loading) ? (
            <div className="audit-trail-modal-content">
              {this.renderModalContent()}
            </div>
          ) : (
            <div
              className="audit-trail-modal-content row justify-content-center align-items-center"
            >
              { !loading ? (
                <h6
                  className="mb-5 text-gray-dark"
                  style={{
                    fontSize: '1.35rem',
                    fontWeight: 500,
                  }}
                >
                  {t('project:audit-trail.nothing-to-display')}
                </h6>
              ) : null}
            </div>
          )
        }
        <nav className="my-5">
          <Pagination
            page={page}
            count={dataCount}
            pageSize={pageSize}
            action={this.fetchPaginatedData}
          />
        </nav>
      </NewModal>
    );
  }
}

export default AuditTrailModal;
