import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { withToastManager } from 'react-toast-notifications';
import { Label } from 'reactstrap';
import { connect } from 'react-redux';
import memoize from 'memoize-one';
import { Mutex } from 'async-mutex';
import AsyncSelect from 'react-select/async';
import api from '../api';
import {
  projectsActions, teamMembersActions, teamLicensesActions, userLicensesActions,
} from '../redux/actions';
import '../assets/css/loader.css';
import { isBrowserView } from './BrowserView';
import LabeledInput from './LabeledInput';
import { CardLoader } from './Loader';
import NewModal from './NewModal';
import LicenseChecker from './LicenseChecker';
import withLicenseMsgModal from './withLicenseMsgModal';
import history from '../history';
import { nsOptions } from '../i18n';
import ErrorUtil from '../utils/ErrorUtil';
import Toast from '../utils/Toast';
import ProjectTeamZone from './ProjectTeamZone';
import Help from './Help';
import Validator from './Validator';
import { childrenPropTypes } from '../utils/generic-prop-types';
import TextUtil from '../utils/TextUtil';
import { USER_TYPE_INTERN } from '../constants';
import fromReduxState from '../utils/redux';

const mapStateToProps = (state) => ({
  teamMembers: state.teamMembers,
  teamLicenses: state.teamLicenses,
  userLicenses: state.userLicenses,
});

const mapDispatchToProps = (dispatch) => ({
  fetchTeamMembers: async () => dispatch(teamMembersActions.list({}, { pagination: 'no' })),
  fetchTeamLicenses: async () => dispatch(teamLicensesActions.list({}, { pagination: 'no' })),
  fetchUserLicenses: async () => dispatch(userLicensesActions.list({}, { pagination: 'no' })),
  addProject: async (data, params) => dispatch(projectsActions.create(data, params)),
});

@withToastManager
@connect(mapStateToProps, mapDispatchToProps)
@withTranslation('', nsOptions)
@withLicenseMsgModal()
class ProjectCreateModal extends Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
    i18n: PropTypes.shape().isRequired,
    admin: PropTypes.bool,
    user: PropTypes.shape().isRequired,
    children: childrenPropTypes().isRequired,
    teamMembers: PropTypes.shape().isRequired,
    teamLicenses: PropTypes.shape().isRequired,
    userLicenses: PropTypes.shape().isRequired,
    fetchTeamMembers: PropTypes.func.isRequired,
    fetchTeamLicenses: PropTypes.func.isRequired,
    fetchUserLicenses: PropTypes.func.isRequired,
    addProject: PropTypes.func.isRequired,
  };

  static defaultProps = {
    admin: false,
  };

  static getInitialState = () => ({
    teams: [],
    projectTeam: undefined,
    loading: true,
    selectedManager: undefined,
    mustManagerBeIntern: false,
  });

  constructor(props) {
    super(props);
    this.state = ProjectCreateModal.getInitialState();
    this.modal = null;
    this.form = null;
    this.nameValue = null;
    this.nameInput = null;
    this.projectTeamZone = null;
    this.managerSelect = null;
    this.projectTeamValid = true;
    this.memoizedGetUserTeams = memoize(this.getUserTeams);
    this.toTmMembersArray = fromReduxState((teamMembers) => (
      Object.values(teamMembers).filter((tmMember) => tmMember.confirmed)
    ));
    this.toTmLicensesArray = fromReduxState((teamLicenses) => Object.values(teamLicenses));
    this.toUsrLicensesArray = fromReduxState((userLicenses) => Object.values(userLicenses));
    this.mutex = new Mutex();
  }

  onCreateProject = async () => {
    const { user } = this.props;
    const { projectTeam, selectedManager } = this.state;
    const {
      can_create_team: canCreateTeam, can_join_team: canJoinTeam,
    } = this.props.user.limitations;
    const nameOk = this.nameInput.validate(this.nameValue);
    const teamOk = (!canCreateTeam && !canJoinTeam) || this.projectTeamZone.validate(projectTeam);
    const { can_manage_project: canManageProject } = user.limitations;
    let managerOk = canManageProject || !projectTeam;
    if (!canManageProject && projectTeam) {
      managerOk = this.managerSelect && this.managerSelect.validate(selectedManager);
    }
    if (nameOk && teamOk && managerOk) {
      await this.projectAddValidate(this.nameValue, projectTeam);
    }
  };

  onTeamChange = (value) => {
    let mustManagerBeIntern = true;
    if (value) {
      const { teams } = this.state;
      const team = teams.find((tm) => tm.id === value);
      mustManagerBeIntern = !team.limitations.can_other_than_intern_be_manager;
    }

    this.setState({
      projectTeam: value,
      selectedManager: undefined,
      mustManagerBeIntern,
    });
  };

  onRedirection = () => {
    if (this.modal) this.modal.hide();
  }

  reset = () => {
    this.setState(ProjectCreateModal.getInitialState());
  };

  projectAddValidate = async (name, team) => {
    // Do not stack multiple addInclusion() calls
    if (!this.mutex.isLocked()) {
      const release = await this.mutex.acquire();
      try {
        const {
          addProject, admin, i18n, user,
        } = this.props;
        const { selectedManager, teams } = this.state;
        const data = {
          name,
          team,
          owner: team ? teams.find((tm) => tm.id === team).owner.id : user.id,
        };
        if (selectedManager instanceof Object) {
          data.manager = selectedManager.userId;
        }
        const project = await addProject(data);
        i18n.reloadResources(null, 'topic');
        Toast.success(this.props, 'error:valid.success');
        const baseUrl = `${admin ? '/admin' : '/dashboard'}/project`;
        history.push(`${baseUrl}/${project.id}/details/settings`);
        if (this.modal) {
          this.modal.hide();
          this.reset();
        }
      } catch (error) {
        ErrorUtil.handleCatched(this.props, error);
      } finally {
        release();
      }
    }
  };

  // Don't store the result in redux bc it would override the teams fetching in the projects list
  fetchTeams = async () => {
    const res = await api.list('teams', {}, { pagination: 'no' });
    this.setState({ teams: res });
  }

  fetchData = async () => {
    const {
      fetchTeamMembers, fetchTeamLicenses, fetchUserLicenses,
    } = this.props;
    try {
      await Promise.all([this.fetchTeams(), fetchTeamMembers(), fetchTeamLicenses(),
        fetchUserLicenses()]);
      const { teams } = this.state;
      if (!teams.length) this.setState({ projectTeam: null });
    } catch (error) {
      ErrorUtil.handleCatched(this.props, error, false);
    } finally {
      this.setState({ loading: false });
    }
  };

  getUserTeams = (userId, teams, teamMembers) => (
    teams.filter((team) => this.toTmMembersArray(teamMembers).find((member) => (
      member.user.id === userId && member.team === team.id
    )))
  );

  loadMembers = async (search) => {
    const { teamMembers, user } = this.props;
    const { projectTeam, mustManagerBeIntern } = this.state;
    if (projectTeam) {
      return this.toTmMembersArray(teamMembers).filter((member) => (
        (!mustManagerBeIntern || member.user.type === USER_TYPE_INTERN)
          && member.team === Number(projectTeam)
          && member.user.id !== user.id
          && (
            TextUtil.localeContains(member.user.label, search)
              || TextUtil.localeContains(member.email, search)
              || TextUtil.localeContains(member.user.email, search)
          )
      )).map((member) => ({
        value: member.id,
        label: member.user.label,
        userId: member.user.id,
      }));
    }
    return [];
  };

  render() {
    const {
      t, user, children, admin, teamMembers, teamLicenses: tLicenses, userLicenses: uLicenses,
    } = this.props;
    const {
      projectTeam, loading, selectedManager, teams, mustManagerBeIntern,
    } = this.state;
    const {
      can_create_team: canCreateTeam, can_join_team: canJoinTeam,
      can_manage_project: canManageProject, can_own_licence_products: canOwnLicenceProducts,
    } = user.limitations;
    const teamLicenses = this.toTmLicensesArray(tLicenses);
    const userLicenses = this.toTmLicensesArray(uLicenses);
    return (
      <LicenseChecker
        limName="can_create_project"
        limitations={user.limitations}
        licMsgModalChildren={children}
      >
        <NewModal
          {...this.props}
          title={t('project:modal.create.title')}
          subTitle={t('project:modal.create.sub-title')}
          size={isBrowserView() ? 'sm' : 'md'}
          trigger={children}
          onLoad={this.fetchData}
          onClosed={this.reset}
          dialogClass="contains-form"
          footer={(
            <div>
              <button
                type="button"
                id="project-validate"
                className="btn btn-newblue-1 text-white px-4 contains-loader font-weight-semibold"
                onClick={this.onCreateProject}
              >
                {t('common:button.create')}
              </button>
            </div>
          )}
          ref={(modal) => {
            this.modal = modal;
          }}
        >
          <form
            noValidate
            ref={(form) => {
              this.form = form;
            }}
            onSubmit={(event) => event.preventDefault()}
          >
            { loading && <CardLoader /> }
            <LabeledInput
              type="text"
              label={t('project:name')}
              className="mb-3"
              name="project-name"
              placeholder={t('error:placeholder.project-name')}
              validation="project_name"
              required
              onChange={(e) => { this.nameValue = e.target.value; }}
              ref={(input) => { this.nameInput = input; }}
            />
            {
              (canCreateTeam || canJoinTeam) && (
                <>
                  <Label className="mt-3">
                    {t('project:select-team')}
                    {!canOwnLicenceProducts && (
                      <Help iconClassName="ml-1 small">
                        {t('project:select-team-help')}
                      </Help>
                    )}
                  </Label>
                  <ProjectTeamZone
                    teams={loading ? undefined
                      : this.memoizedGetUserTeams(user.id, teams, teamMembers)}
                    teamLicenses={teamLicenses}
                    userLicenses={userLicenses}
                    user={user}
                    admin={admin}
                    canEdit
                    creation
                    value={projectTeam}
                    onChange={this.onTeamChange}
                    ref={(ref) => { this.projectTeamZone = ref; }}
                  />
                </>
              )
            }
            {projectTeam && !canManageProject && (
              <div className="mt-3">
                <Label className="mb-0">
                  {t('project:manager')}
                </Label>
                <Validator
                  required
                  ref={(ref) => { this.managerSelect = ref; }}
                >
                  <AsyncSelect
                    className="react-select user-select"
                    classNamePrefix="react-select"
                    key={`manager-select-team-${projectTeam}`}
                    placeholder={t('project:select-manager-placeholder')}
                    noOptionsMessage={() => t('project:new-invitation.no-member')}
                    loadOptions={this.loadMembers}
                    onChange={(value) => { this.setState({ selectedManager: value }); }}
                    defaultOptions
                    value={selectedManager}
                    required
                  />
                </Validator>
                { mustManagerBeIntern && (
                  <div className="help-text">
                    {t('project:displayed-managers-help')}
                  </div>
                )}
              </div>
            )}
          </form>
        </NewModal>
      </LicenseChecker>
    );
  }
}


export default ProjectCreateModal;
