import PropTypes from 'prop-types';
import React, {
  useEffect, useState, useRef, useMemo,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Mutex } from 'async-mutex';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import { projectUsersActions, countriesActions } from '../redux/actions';
import api from '../api';
import NewTooltip from './NewTooltip';
import Pagination from './Pagination';
import ButtonConfirm from './ButtonConfirm';
import { CardLoader } from './Loader';
import {
  Table, TableHead, TableBody, TableRow, TableCell,
} from './CustomTable';
import useError from '../utils/HookErrorUtil';
import useToast from '../utils/HookToast';
import TimeoutHandler from '../utils/TimeoutHandler';
import { PROJECT_USER_REQUESTED_TO_JOIN, PROJECT_PUBLIC } from '../constants';
import { DATE_FORMAT } from '../utils/date';

function OrganizationRequests(props) {
  const { organization, admin } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { handleCatched } = useError();
  const { success } = useToast();

  const timeoutHandler = useRef(null);
  const mutex = useRef(null);

  const prjUsers = useSelector((state) => state.projectUsers);
  const projectUsers = useMemo(() => Object.values(prjUsers).filter(
    (pUser) => pUser.type === PROJECT_USER_REQUESTED_TO_JOIN && !pUser.confirmed,
  ), [prjUsers]);
  const countries = useSelector((state) => state.countries);

  const initButtons = () => {
    const buttons = {};
    projectUsers.forEach((pUser) => {
      buttons[pUser.id] = { acceptVisible: true, rejectVisible: true };
    });
    return buttons;
  };

  const [loading, setLoading] = useState(false);
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(1);
  const [requestButtons, setRequestButtons] = useState(initButtons());

  const fetchProjectUsers = async (data) => dispatch(projectUsersActions.list(
    { ...data, admin }, { pagination: 'short' },
  ));
  const fetchCountries = async () => dispatch(countriesActions.list({}, { pagination: 'no' }));

  const fetchPaginatedProjectUsers = async (newPage, initialization = false) => {
    if (!initialization) setLoading(true);
    try {
      const res = await fetchProjectUsers({
        page: newPage,
        project__owner: organization.id,
        type: PROJECT_USER_REQUESTED_TO_JOIN,
        confirmed: false,
        visibility_status: PROJECT_PUBLIC,
      });
      const buttons = {};
      res.results.forEach((pUser) => {
        buttons[pUser.id] = { acceptVisible: true, rejectVisible: true };
      });
      setRequestButtons(buttons);
      setPage(newPage);
      setCount(res.count);
      setPageSize(Math.max(res.results.length, pageSize));
    } catch (error) {
      handleCatched(props, error);
    } finally {
      if (!initialization) setLoading(false);
    }
  };

  const fetchData = async () => {
    setLoading(true);
    try {
      await Promise.all([fetchPaginatedProjectUsers(1, true), fetchCountries()]);
    } catch (error) {
      handleCatched(props, error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    timeoutHandler.current = new TimeoutHandler();
    mutex.current = new Mutex();
    fetchData();
  }, []);

  const acceptRequest = async (id) => {
    if (!mutex.current.isLocked()) {
      const release = await mutex.current.acquire();
      try {
        await api.create('accept-request-to-join-public-project',
          { project_user: id }, { admin });
        await fetchPaginatedProjectUsers(1);
        success('error:valid.success');
      } catch (error) {
        handleCatched(props, error);
      } finally {
        release();
      }
    }
  };

  const dismissRequest = async (id) => {
    if (!mutex.current.isLocked()) {
      const release = await mutex.current.acquire();
      try {
        await api.create('dismiss-request-to-join-public-project',
          { project_user: id }, { admin });
        await fetchPaginatedProjectUsers(1);
        success('error:valid.success');
      } catch (error) {
        handleCatched(props, error);
      } finally {
        release();
      }
    }
  };

  return (
    <div>
      {loading && (
        <CardLoader />
      )}
      <div className="pb-3 community-card-subtitle">
        {t('community:requests.subtitle')}
      </div>
      <Table extraClassName="no-pointer mt-2 mb-0">
        <TableHead>
          <TableRow>
            <TableCell>
              {t('community:project')}
            </TableCell>
            <TableCell>
              {t('community:user')}
            </TableCell>
            <TableCell>
              {t('community:type')}
            </TableCell>
            <TableCell>
              {t('community:specialty')}
            </TableCell>
            <TableCell>
              {t('community:location')}
            </TableCell>
            <TableCell>
              {t('community:request-date')}
            </TableCell>
            {projectUsers.length > 0 && (
              <TableCell>&nbsp;</TableCell>
            )}
          </TableRow>
        </TableHead>
        <TableBody>
          {projectUsers.length > 0 && !loading ? (
            projectUsers.map((pUser) => (
              <TableRow
                key={`project-user-${pUser.id}`}
              >
                <TableCell>
                  {pUser.project_name}
                </TableCell>
                <TableCell className="align-middle text-center font-weight-semibold">
                  {pUser.user.label}
                </TableCell>
                <TableCell>
                  {t(`user:types.${pUser.user.type}`)}
                </TableCell>
                <TableCell>
                  {pUser.user.specialty ? t(`specialty:${pUser.user.specialty}`) : ''}
                </TableCell>
                <TableCell>
                  {pUser.user.city ? `${pUser.user.city}, ` : ''}
                  {pUser.user.country ? t(`country:${countries[pUser.user.country].name}`) : ''}
                </TableCell>
                <TableCell>
                  {moment(pUser.request_date).format(DATE_FORMAT)}
                </TableCell>
                <TableCell>
                  <NewTooltip
                    content={t('community:requests.accept')}
                  >
                    <span className={requestButtons[pUser.id].acceptVisible ? '' : 'd-none'}>
                      <ButtonConfirm
                        className="button-confirm-green"
                        onClick={() => acceptRequest(pUser.id)}
                        onFirstClick={() => {
                          setRequestButtons((prevState) => ({
                            ...prevState,
                            [pUser.id]: { acceptVisible: true, rejectVisible: false },
                          }));
                        }}
                        onTimeout={() => {
                          setRequestButtons((prevState) => ({
                            ...prevState,
                            [pUser.id]: { acceptVisible: true, rejectVisible: true },
                          }));
                        }}
                      >
                        <button
                          className="btn btn-green text-white mr-1"
                        >
                          <FontAwesomeIcon
                            icon={['fas', 'check']}
                            className="align-middle"
                            transform="shrink-2"
                          />
                        </button>
                      </ButtonConfirm>
                    </span>
                  </NewTooltip>
                  <NewTooltip
                    content={t('community:requests.reject')}
                  >
                    <span className={requestButtons[pUser.id].rejectVisible ? '' : 'd-none'}>
                      <ButtonConfirm
                        onClick={() => dismissRequest(pUser.id)}
                        onFirstClick={() => {
                          setRequestButtons((prevState) => ({
                            ...prevState,
                            [pUser.id]: { acceptVisible: false, rejectVisible: true },
                          }));
                        }}
                        onTimeout={() => {
                          setRequestButtons((prevState) => ({
                            ...prevState,
                            [pUser.id]: { acceptVisible: true, rejectVisible: true },
                          }));
                        }}
                      >
                        <button
                          className="btn btn-red text-white ml-1"
                        >
                          <div>
                            <FontAwesomeIcon
                              icon={['fas', 'times']}
                              className="align-middle reject-request-to-join-public-project"
                              transform="shrink-2"
                            />
                          </div>
                        </button>
                      </ButtonConfirm>
                    </span>
                  </NewTooltip>
                </TableCell>
              </TableRow>
            ))
          ) : (
            <TableRow className="no-one">
              <TableCell
                colSpan="6"
                className="align-middle text-center text-muted small font-italic"
              >
                {loading ? (
                  <span>
                    &nbsp;
                  </span>
                ) : (
                  <span>
                    {t('community:requests.no-requests')}
                  </span>
                )}
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
      {count > 10 && (
        <div className="mt-2">
          <Pagination
            page={page}
            count={count}
            pageSize={pageSize}
            action={fetchPaginatedProjectUsers}
          />
        </div>
      )}
    </div>
  );
}

OrganizationRequests.propTypes = {
  organization: PropTypes.shape().isRequired,
  admin: PropTypes.bool,
};

OrganizationRequests.defaultProps = {
  admin: false,
};

export default OrganizationRequests;
