import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import AuthContainer from '../components/AuthContainer';
import Page, { checkNotAuth } from '../components/Page';
import { nsOptions } from '../i18n';


@withTranslation('', nsOptions)
class PleaseConfirmEmail extends Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
  };

  render() {
    const { t } = this.props;
    return (
      <Page
        {...this.props}
        title={t('auth:please-confirm')}
        checkAuthorizations={[checkNotAuth]}
      >
        <AuthContainer
          onSubmit={this.handleFormSubmit}
          top={{
            info: t('auth:pages.please-confirm.info'),
          }}
          bottom={{}}
        >
          <div className="authentication-form please-confirm-icon">
            <FontAwesomeIcon icon={['fal', 'envelope']} />
          </div>
        </AuthContainer>
      </Page>
    );
  }
}

export default PleaseConfirmEmail;
