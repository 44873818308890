import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { withToastManager } from 'react-toast-notifications';
import { nsOptions } from '../i18n';
import api from '../api';
import { childrenPropTypes } from '../utils/generic-prop-types';
import ErrorUtil from '../utils/ErrorUtil';
import Toast from '../utils/Toast';
import NewModal from './NewModal';
import { CardLoader } from './Loader';
import { isBrowserView } from './BrowserView';
import history from '../history';

@withToastManager
@withTranslation('', nsOptions)
class ProjectImportManager extends Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
    admin: PropTypes.bool,
    children: childrenPropTypes().isRequired,
  }

  static defaultProps = {
    admin: false,
  };

  static getInitialState = () => ({
    jsonFileName: null,
    jsonData: null,
    processingImport: false,
    loadedFile: false,
  });

  constructor(props) {
    super(props);
    this.state = ProjectImportManager.getInitialState();
  }

  reset = () => {
    this.setState(ProjectImportManager.getInitialState());
  }

  handleFile = async (file) => {
    if (!file) {
      this.reset();
      return;
    }
    try {
      const res = await new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () => {
          resolve(reader.result);
        };
        reader.onerror = () => {
          reject(reader.error);
        };
        reader.readAsText(file);
      });
      this.setState({ jsonData: res });
    } catch (error) {
      ErrorUtil.handleCatched(this.props, error);
    }
  }

  importProject = async () => {
    const { admin } = this.props;
    const { jsonData } = this.state;
    this.setState({ processingImport: true });

    try {
      const res = await api.create('import-project', {
        json_data: jsonData,
      }, { admin });
      Toast.success(this.props, 'error:valid.imported');
      const baseUrl = `${admin ? '/admin' : '/dashboard'}/project`;
      history.push(`${baseUrl}/${res.project_id}/details`);
      if (this.modal) {
        this.modal.hide();
        this.reset();
      }
    } catch (error) {
      ErrorUtil.handleCatched(this.props, error);
    } finally {
      this.setState({ processingImport: false });
    }
  }

  render() {
    const { t, children } = this.props;
    const {
      jsonData, processingImport,
    } = this.state;

    return (
      <NewModal
        trigger={children}
        title={t('project:import.modal-title')}
        size={isBrowserView() ? 'sm' : 'md'}
        onClosed={this.reset}
        footer={(
          <div className="row">
            <button
              type="button"
              className="btn btn-newblue-1 text-white px-3"
              onClick={this.importProject}
              disabled={!jsonData || processingImport}
            >
              {t('project:import.validate-import')}
            </button>
          </div>
        )}
        ref={(modal) => {
          this.modal = modal;
        }}
      >
        { processingImport && <CardLoader /> }
        <div className="pb-2 pt-4">
          <input
            className="border-0 form-control-file"
            type="file"
            accept=".json,application/json"
            onChange={(e) => this.handleFile(e.target.files[0])}
          />
        </div>
      </NewModal>
    );
  }
}

export default ProjectImportManager;
