import { combineReducers } from 'redux';
import languages from './languages';
import countries from './countries';
import specialties from './specialties';
import groups from './groups';
import userLicenses from './user-licenses';
import users from './users';
import auth from './auth';
import topics from './topics';
import projectResources from './project-resources';
import resourceTopics from './resource-topics';
import projects from './projects';
import projectPermissions from './project-permissions';
import projectUsers from './project-users';
import projectPages from './project-pages';
import elements from './elements';
import elementModalities from './element-modalities';
import projectElements from './project-elements';
import elementLinks from './element-links';
import inclusions from './inclusions';
import projectEntries from './project-entries';
import teams from './teams';
import teamLicenses from './team-licenses';
import teamLicenseProducts from './team-license-products';
import teamMembers from './team-members';
import stripePlans from './stripe-plans';
import misc from './misc';
import sortingHandler from './sorting-handler';
import dragAndDrop from './drag-and-drop';
import localIdManager from './local-id-manager';
import statsFilters from './stats-filters';
import userFiles from './user-files';
import notifications from './notifications';
import notificationItems from './notification-items';
import userNotificationSetting from './user-notification-setting';

const appReducer = combineReducers({
  languages,
  countries,
  specialties,
  groups,
  userLicenses,
  users,
  auth,
  topics,
  projectResources,
  resourceTopics,
  projects,
  projectPermissions,
  projectUsers,
  projectPages,
  elements,
  elementModalities,
  projectElements,
  elementLinks,
  inclusions,
  projectEntries,
  teams,
  teamLicenses,
  teamLicenseProducts,
  teamMembers,
  stripePlans,
  misc,
  sortingHandler,
  dragAndDrop,
  localIdManager,
  statsFilters,
  userFiles,
  notifications,
  notificationItems,
  userNotificationSetting,
});

const rootReducer = (state, action) => {
  let newState = state;
  if (action.type === 'misc/reset_store') {
    newState = undefined;
  }
  return appReducer(newState, action);
};

export default rootReducer;
