import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { withToastManager } from 'react-toast-notifications';
import memoize from 'memoize-one';
import { connect } from 'react-redux';
import { Mutex } from 'async-mutex';
import {
  projectElementsActions, elementsActions, elementLinksActions, sortingHandlerActions,
  elementModalitiesActions,
} from '../redux/actions';
import api from '../api';
import {
  getSortingHandlerId, getSortingItemId, extractSortingItemDigitalId,
} from '../redux/actions/sorting-handler';
import { nsOptions } from '../i18n';
import {
  ELEMENT_TYPE_MULTIPLE_CHOICES, ELEMENT_TYPE_TITLE, ELEMENT_TYPE_UNIQUE_CHOICE,
  ELEMENT_TYPE_DIVIDER, ELEMENT_TYPE_SPACER,
} from '../constants';
import ElementUtil from '../utils/ElementUtil';
import { childrenPropTypes } from '../utils/generic-prop-types';
import SortUtil from '../utils/SortUtil';
import ErrorUtil from '../utils/ErrorUtil';
import Toast from '../utils/Toast';
import ElementAddBox from './ElementAddBox';
import { CardLoader } from './Loader';
import NewModal from './NewModal';
import withLicenseMsgModal from './withLicenseMsgModal';
import DraggableComponent from './DraggableComponent';
import DroppableComponent from './DroppableComponent';
import fromReduxState from '../utils/redux';


const mapStateToProps = (state, ownProps) => ({
  moduleElements: state.projectElements,
  elements: state.elements,
  sortedItems: state.sortingHandler[ownProps.formSortingHandlerId] || [],
  pages: state.projectPages,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  addElement: async (data) => dispatch(elementsActions.create(data, {
    admin: ownProps.admin,
  })),
  resyncElements: async () => dispatch(elementsActions.resync({
    admin: ownProps.admin,
  })),
  addProjectElement: async (data) => dispatch(projectElementsActions.create(data, {
    admin: ownProps.admin,
  })),
  removeProjectElement: async (id) => dispatch(projectElementsActions.remove(id, {
    admin: ownProps.admin,
  })),
  resyncProjectElements: async () => dispatch(projectElementsActions.resync({
    admin: ownProps.admin,
  })),
  resyncElementLinks: async () => dispatch(elementLinksActions.resync({
    admin: ownProps.admin,
  })),
  setSortedItems: (items) => dispatch(sortingHandlerActions.setItems({
    handlerId: ownProps.formSortingHandlerId,
    items,
  })),
  addSortedItems: (items) => dispatch(sortingHandlerActions.addItems({
    handlerId: ownProps.formSortingHandlerId,
    items,
  })),
  removeSortedItem: (item) => dispatch(sortingHandlerActions.removeItem({
    handlerId: ownProps.formSortingHandlerId,
    item,
  })),
  fetchElement: async (id) => dispatch(elementsActions.read(id, {
    admin: ownProps.admin,
  })),
  insertSortedItem: (previousItem, item) => dispatch(sortingHandlerActions.insertItem({
    handlerId: ownProps.formSortingHandlerId,
    previousItem,
    item,
  })),
  fetchElementModalities: async (elements) => dispatch(
    elementModalitiesActions.list({
      element__in: Object.values(elements).map((el) => el.id),
      admin: ownProps.admin,
    }),
  ),
  fetchElementLinks: async (projectElements) => dispatch(elementLinksActions.listByProjectElements(
    Object.values(projectElements).map((pEl) => pEl.id), { admin: ownProps.admin }, { pagination: 'no' },
  )),
  fetchProjectElements: async (pages) => dispatch(projectElementsActions.list({
    project_page__in: Object.values(pages).map((p) => p.id),
    admin: ownProps.admin,
  }, { pagination: 'no' })),
});


@withToastManager
@connect(mapStateToProps, mapDispatchToProps)
@withTranslation('', nsOptions)
@withLicenseMsgModal()
class ElementModuleModal extends Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
    children: childrenPropTypes().isRequired,
    module: PropTypes.shape().isRequired,
    projectModule: PropTypes.shape(),
    admin: PropTypes.bool,
    project: PropTypes.shape().isRequired,
    formSortingHandlerId: PropTypes.string.isRequired,
    elements: PropTypes.shape().isRequired,
    moduleElements: PropTypes.shape().isRequired,
    sortedItems: PropTypes.arrayOf(PropTypes.string).isRequired,
    pages: PropTypes.shape().isRequired,
    addElement: PropTypes.func.isRequired,
    resyncElements: PropTypes.func.isRequired,
    addProjectElement: PropTypes.func.isRequired,
    removeProjectElement: PropTypes.func.isRequired,
    resyncProjectElements: PropTypes.func.isRequired,
    resyncElementLinks: PropTypes.func.isRequired,
    setSortedItems: PropTypes.func.isRequired,
    addSortedItems: PropTypes.func.isRequired,
    removeSortedItem: PropTypes.func.isRequired,
    fetchElement: PropTypes.func.isRequired,
    insertSortedItem: PropTypes.func.isRequired,
    fetchElementModalities: PropTypes.func.isRequired,
    fetchElementLinks: PropTypes.func.isRequired,
    fetchProjectElements: PropTypes.func.isRequired,
    isEditMode: PropTypes.bool,
    isReadOnly: PropTypes.bool,
  };

  static defaultProps = {
    projectModule: null,
    admin: false,
    isEditMode: false,
    isReadOnly: true,
  };

  static getInitialState() {
    return {
      loading: false,
      contents: [],
    };
  }

  constructor(props) {
    super(props);
    this.state = ElementModuleModal.getInitialState();
    this.loadContent = memoize(this.load);
    this.mutex = new Mutex();
    this.elementRefs = {};
    this.toModuleElementsArray = fromReduxState((moduleElements, projectModuleId) => (
      Object.values(moduleElements).filter((pEl) => (
        pEl.module === projectModuleId
      ))
    ));
  }

  resetState = () => {
    this.setState(ElementModuleModal.getInitialState(this.props));
  };

  modalLoad = async () => {
    const {
      setSortedItems, moduleElements: moduleEls, elements, projectModule, formSortingHandlerId,
    } = this.props;
    const moduleElements = this.toModuleElementsArray(moduleEls, projectModule.id);
    setSortedItems(moduleElements.sort(SortUtil.sortArray).map((pEl) => (
      getSortingItemId(formSortingHandlerId, pEl.id)
    )));
    await this.load(projectModule, moduleElements, elements, this.props.sortedItems);
  };

  hide = () => {
    this.modal.hide();
  };

  addElement = async (data) => {
    this.setState({ loading: true });
    const {
      t, addElement, addProjectElement, addSortedItems, formSortingHandlerId,
    } = this.props;
    try {
      const newData = { ...data };
      if (ElementUtil.isElementStatic(data.type) && data.type !== ELEMENT_TYPE_TITLE) {
        newData.name = ElementUtil.getStaticElementTypeName(data.type, t);
      }
      const element = await addElement(newData);
      const res = await addProjectElement({
        element: element.id,
        module: this.props.projectModule.id,
        project_page: this.props.projectModule.project_page,
      });
      addSortedItems([getSortingItemId(formSortingHandlerId, res.id)]);

      // Try to focus on the variable name
      if (this.elementRefs[res.id]) {
        const elBaseRef = this.elementRefs[res.id].elBaseRef
          || this.elementRefs[res.id].ref.elBaseRef;
        if (elBaseRef && elBaseRef.props && elBaseRef.props.element
          && elBaseRef.props.element.name === '') {
          elBaseRef.focusOnNameInput();
        }
      }
      Toast.success(this.props, 'error:valid.saved');
    } catch (error) {
      ErrorUtil.handleCatched(this.props, error);
    } finally {
      this.setState({ loading: false });
    }
  };

  deleteElement = async (projectElement, formSortingHandlerId) => {
    this.setState({ loading: true });
    const {
      removeProjectElement, resyncProjectElements, resyncElements, removeSortedItem,
      resyncElementLinks,
    } = this.props;
    try {
      removeSortedItem(getSortingItemId(formSortingHandlerId, projectElement.id));
      await removeProjectElement(projectElement.id);
      await Promise.all([
        resyncElements(), // Associated element should have been deleted
        resyncProjectElements(), // Sorting value of other project elements may have changed
        resyncElementLinks(), // Links could have been removed in cascade
      ]);
      Toast.success(this.props, 'error:valid.saved');
    } catch (error) {
      ErrorUtil.handleCatched(this.props, error);
    } finally {
      this.setState({ loading: false });
    }
  };

  cloneElement = async (projectElement, formSortingHandlerId) => {
    if (!this.mutex.isLocked()) {
      this.setState({ loading: true });
      const release = await this.mutex.acquire();
      const {
        fetchElement, fetchElementModalities, insertSortedItem, fetchElementLinks, admin,
        fetchProjectElements,
      } = this.props;
      try {
        const res = await api.create('clone-variable', {
          project_element: projectElement.id,
          related_objects: true,
        }, { admin });

        const clonedProjectElement = JSON.parse(res.project_element);
        await fetchElement(clonedProjectElement.element);
        const type = clonedProjectElement.element_type;
        if ([ELEMENT_TYPE_UNIQUE_CHOICE, ELEMENT_TYPE_MULTIPLE_CHOICES].includes(type)) {
          await fetchElementModalities(this.props.elements);
        }

        const projectElements = await fetchProjectElements(this.props.pages);

        await fetchElementLinks(projectElements);

        const previousItem = getSortingItemId(formSortingHandlerId, projectElement.id);
        const item = getSortingItemId(formSortingHandlerId, clonedProjectElement.id);
        insertSortedItem(previousItem, item);

        Toast.success(this.props, 'error:valid.cloned');
      } catch (error) {
        ErrorUtil.handleCatched(this.props, error);
      } finally {
        release();
        this.setState({ loading: false });
      }
    }
  }

  load = (projectModule, moduleElements, elements, sortedItems) => {
    const {
      formSortingHandlerId, isEditMode, isReadOnly,
    } = this.props;
    this.elementRefs = {};
    const getElement = (index, Type, pElement, ref = null) => {
      const { id } = pElement;
      const sortingItemId = getSortingItemId(formSortingHandlerId, pElement.id);
      return Type ? (
        <DraggableComponent
          key={sortingItemId}
          draggableId={sortingItemId}
          droppableId={formSortingHandlerId}
          index={index}
          usePortal
          isDragDisabled={!isEditMode}
        >
          <Type
            {...this.props}
            key={id}
            isEditMode={isEditMode}
            isReadOnly={isReadOnly}
            isModule
            admin={this.props.admin}
            elementId={pElement.element}
            projectElementId={pElement.id}
            project={this.props.project}
            parent={projectModule}
            formSortingHandlerId={formSortingHandlerId}
            elementSortingHandlerId={getSortingHandlerId('element', pElement.id)}
            methods={{
              reload: () => this.load(projectModule, moduleElements, elements, sortedItems),
              remove: () => this.deleteElement(pElement, formSortingHandlerId),
              clone: () => this.cloneElement(pElement, formSortingHandlerId),
            }}
            ref={ref}
          />
        </DraggableComponent>
      ) : null;
    };

    const content = sortedItems.map((pElId, index) => {
      const dId = extractSortingItemDigitalId(pElId);
      const pElement = moduleElements.find((pEl) => pEl.id === dId);
      if (pElement) {
        const { id, element: elementId } = pElement;
        const element = elements[elementId];
        const Type = ElementUtil.getElementType(elements[pElement.element]);
        const elementType = element.type;
        const refClbk = ![ELEMENT_TYPE_SPACER, ELEMENT_TYPE_DIVIDER].includes(elementType)
          ? (ref) => { this.elementRefs[id] = ref; } : null;
        return getElement(index, Type, pElement, refClbk);
      }
      const error = new Error(`Project element ${dId} not found. Should not append.`);
      ErrorUtil.handleCatched(this.props, error, false);
      return null;
    });

    return content;
  };

  render() {
    const {
      t, projectModule, moduleElements: moduleEls, sortedItems, formSortingHandlerId, module,
      elements, isEditMode,
    } = this.props;
    const moduleElements = this.toModuleElementsArray(moduleEls, projectModule.id);
    return (
      <NewModal
        trigger={this.props.children}
        size="lg"
        type={1}
        xlHeader={`${t('project:module-edition')} — ${ElementUtil.formatElementName(module, t)}`}
        onLoad={this.modalLoad}
        onClosed={this.resetState}
        footer={isEditMode ? (
          <div className="row">
            <button
              type="button"
              className="btn btn-newblue-1 text-white px-3"
              onClick={this.hide}
            >
              {t('common:button.save')}
            </button>
          </div>
        ) : null}
        ref={(modal) => {
          this.modal = modal;
        }}
        extraClass={isEditMode ? '' : 'read-only-module-form'}
      >
        <div className="element-tab module-tab">
          <DroppableComponent
            droppableId={formSortingHandlerId}
            type={formSortingHandlerId}
            isDropDisabled={!isEditMode}
          >
            {this.loadContent(projectModule, moduleElements, elements, sortedItems)}
          </DroppableComponent>
        </div>
        {isEditMode && (
          <ElementAddBox
            addElement={(data) => this.addElement(data, () => (
              this.load(projectModule, moduleElements, elements, sortedItems)
            ))}
            noModule
          />
        )}
        {
          this.state.loading ? (
            <CardLoader />
          ) : null
        }
      </NewModal>
    );
  }
}


export default ElementModuleModal;
