import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { childrenPropTypes } from '../utils/generic-prop-types';

class NewDropdownMenu extends Component {
  static handleOutsideClick(e) {
    NewDropdownMenu.list.forEach((dropdown) => {
      if (!dropdown.main.contains(e.target)) {
        dropdown.hide();
      }
    });
  }

  constructor(props) {
    super(props);
    this.state = { opened: false };
    this.main = null;
    this.menu = null;
    NewDropdownMenu.list.push(this);
  }

  componentDidMount() {
    if (this.props.mainRef) this.props.mainRef(this.main);
    if (this.props.menuRef) this.props.menuRef(this.menu);
  }

  componentWillUnmount() {
    const index = NewDropdownMenu.list.indexOf(this);
    if (index > -1) {
      NewDropdownMenu.list.splice(index, 1);
    }
  }

  show = () => {
    this.setState({ opened: true });
  };

  hide = () => {
    this.setState({ opened: false });
  };

  toggle = () => {
    const { onChange } = this.props;
    this.setState({ opened: !this.state.opened }, () => {
      if (onChange) onChange(this, this.state.opened);
    });
  };

  render() {
    const {
      mainId, type, mainClass, beforeElement, triggerElement, menuId, menuClass, children,
      onClick,
    } = this.props;
    const { opened } = this.state;
    const getOnClick = (originalOnClick) => (e) => {
      if (originalOnClick) originalOnClick(e);
      if (onClick) onClick(e);
    };
    let key = 0;
    const getKey = () => {
      key += 1;
      return key;
    };

    return (
      <div
        id={mainId}
        className={`${type} ${mainClass} ${opened ? 'show' : ''}`}
        ref={(main) => { this.main = main; }}
      >
        {beforeElement}
        {/* FIXME: Remove this hack. */}
        {React.cloneElement(triggerElement, { onClick: this.toggle })}
        <div
          id={menuId}
          className={`dropdown-menu ${menuClass} ${opened ? 'show' : ''}`}
          ref={(menu) => { this.menu = menu; }}
        >
          {
            Array.isArray(children)
              ? children.flat().filter((child) => Boolean(child)).map((child) => (
                React.cloneElement(child, {
                  onClick: getOnClick(child.props.onClick),
                  key: getKey(),
                })))
              : React.cloneElement(children, { onClick: getOnClick(children.props.onClick) })
          }
        </div>
      </div>
    );
  }
}

NewDropdownMenu.propTypes = {
  beforeElement: PropTypes.element,
  triggerElement: PropTypes.element.isRequired,
  children: childrenPropTypes().isRequired,
  type: PropTypes.string,
  mainClass: PropTypes.string,
  menuClass: PropTypes.string,
  mainId: PropTypes.string,
  menuId: PropTypes.string,
  mainRef: PropTypes.func,
  menuRef: PropTypes.func,
  onChange: PropTypes.func,
  onClick: PropTypes.func,
};

NewDropdownMenu.defaultProps = {
  beforeElement: null,
  type: 'dropdown',
  mainClass: '',
  menuClass: '',
  mainId: null,
  menuId: null,
  mainRef: null,
  menuRef: null,
  onChange: null,
  onClick: null,
};

NewDropdownMenu.list = [];

export default NewDropdownMenu;
