import { createReducer } from 'redux-starter-kit';
import { teamLicenseProductsActions } from '../actions';
import getStdDbModelReduxMapping from './std-db-model-reducers';

const teamLicenseProductsInitialState = {};

export const teamLicenseProductsReducer = createReducer(
  teamLicenseProductsInitialState,
  getStdDbModelReduxMapping(teamLicenseProductsActions),
);

export default teamLicenseProductsReducer;
