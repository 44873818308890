import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { useTranslation, withTranslation } from 'react-i18next';
import { withToastManager } from 'react-toast-notifications';
import { connect } from 'react-redux';
import memoize from 'memoize-one';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Mutex } from 'async-mutex';
import { elementLinksActions, projectElementsActions, projectPagesActions } from '../redux/actions';
import { nsOptions } from '../i18n';
import DbUtil from '../utils/DbUtil';
import { childrenPropTypes } from '../utils/generic-prop-types';
import SortUtil from '../utils/SortUtil';
import ErrorUtil from '../utils/ErrorUtil';
import ElementUtil from '../utils/ElementUtil';
import ElementQualitativeLink from './ElementQualitativeLink';
import ElementQuantitativeLink from './ElementQuantitativeLink';
import ElementBaseLink from './ElementBaseLink';
import { CardLoader } from './Loader';
import NewModal from './NewModal';
import FAQLink from './FAQLink';
import { formatPageTitle } from '../utils/data-util';
import {
  ELEMENT_TYPE_MEASUREMENT, ELEMENT_TYPE_MULTIPLE_CHOICES, ELEMENT_TYPE_UNIQUE_CHOICE,
  LINK_OPERATOR_AND,
  LINK_OPERATOR_OR,
  TARGET_TYPE_ELEMENT, TARGET_TYPE_PAGE,
} from '../constants';
import { NewBadge } from './Badges';

const LinkOperator = (props) => {
  const { t, i18n } = useTranslation();
  const {
    className, disabled, value, onChange,
  } = props;

  return (
    <div className={className}>
      <div className="element-link-operator">
        <select
          className="font-weight-semibold"
          disabled={disabled}
          value={value}
          onChange={onChange}
        >
          <option value={LINK_OPERATOR_OR}>
            {t('project:modal.link.logic-operator-or')}
          </option>
          <option value={LINK_OPERATOR_AND}>
            {t('project:modal.link.logic-operator-and')}
          </option>
        </select>
        <NewBadge className={`link-operator-badge-${i18n.language}`}>
          <span />
        </NewBadge>
      </div>
    </div>
  );
};

LinkOperator.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  value: PropTypes.oneOf([LINK_OPERATOR_OR, LINK_OPERATOR_AND]).isRequired,
  onChange: PropTypes.func.isRequired,
};

LinkOperator.defaultProps = {
  className: undefined,
  disabled: false,
};

const mapStateToProps = (state) => ({
  pages: state.projectPages,
  elementLinks: state.elementLinks,
  elements: state.elements,
  projectElements: state.projectElements,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  addLink: async (data, params) => dispatch(elementLinksActions.create(data, {
    ...params, admin: ownProps.admin,
  })),
  removeLink: async (id, params) => dispatch(elementLinksActions.remove(id, {
    ...params, admin: ownProps.admin,
  })),
  patchLink: async (id, data, params) => dispatch(elementLinksActions.patch(id, data, {
    ...params, admin: ownProps.admin,
  })),
  patchProjectElement: async (id, data) => dispatch(projectElementsActions.patch(id, data, {
    admin: ownProps.admin,
  })),
  patchPage: async (id, data) => dispatch(projectPagesActions.patch(id, data, {
    admin: ownProps.admin,
  })),
});


@withToastManager
@connect(mapStateToProps, mapDispatchToProps)
@withTranslation('', nsOptions)
class ElementLinkManager extends Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
    children: childrenPropTypes().isRequired,
    pages: PropTypes.shape().isRequired,
    title: PropTypes.string.isRequired,
    elements: PropTypes.shape().isRequired,
    target: PropTypes.shape().isRequired,
    targetType: PropTypes.oneOf([TARGET_TYPE_ELEMENT, TARGET_TYPE_PAGE]).isRequired,
    projectElements: PropTypes.shape().isRequired,
    elementLinks: PropTypes.shape().isRequired,
    project: PropTypes.shape().isRequired,
    admin: PropTypes.bool,
    addLink: PropTypes.func.isRequired,
    removeLink: PropTypes.func.isRequired,
    patchLink: PropTypes.func.isRequired,
    patchProjectElement: PropTypes.func.isRequired,
    patchPage: PropTypes.func.isRequired,
  };

  static defaultProps = {
    admin: false,
  };

  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      elements: [],
      links: [],
      newLinks: [],
      linkOperator: props.target.link_operator,
      highlightMissing: false,
    };
    this.modal = null;
    this.validation = {
      idRegex: /^[0-9]*$/,
      actions: ['eq', 'gt', 'lt', 'gteq', 'lteq'],
    };
    this.getElement = memoize((id) => this.state.elements.find((element) => element.id === id));
    this.mutex = new Mutex();
  }

  isNew = (link) => !this.getElement(link.source);

  isQualitative = (link) => {
    const el = this.getElement(link.source);
    return el && [ELEMENT_TYPE_UNIQUE_CHOICE, ELEMENT_TYPE_MULTIPLE_CHOICES].includes(el.type);
  };

  isQuantitative = (link) => {
    const el = this.getElement(link.source);
    return el && [ELEMENT_TYPE_MEASUREMENT].includes(el.type);
  };

  fetchProjectElements = () => {
    const {
      pages, projectElements: pEls, elements: elts, t, target, targetType,
    } = this.props;
    const elements = Object.values(elts);
    const projectElements = Object.values(pEls);
    const pElements = [];
    Object.values(pages).forEach((page) => {
      projectElements.filter((pElement) => {
        const condition = pElement.project_page === page.id;
        if (targetType === TARGET_TYPE_ELEMENT) {
          // Element links: ignore current element and elements of mismatching modules
          // (excepted for root variable if inside a module)
          const { id, module } = target;
          return condition && pElement.id !== id
            && (pElement.module === module || (!pElement.module && module));
        }
        // Page links: ignore elements of the current page and in modules.
        return condition && target.id !== page.id && !pElement.module;
      }).forEach((pElement) => {
        const element = elements.find((el) => el.id === pElement.element);
        const { text, type } = element;
        let parentName = formatPageTitle(page, t);
        let pModule;
        if (pElement.module) {
          pModule = projectElements.find((pEl) => pEl.id === pElement.module);
          if (pModule) {
            const moduleElement = elements.find((el) => el.id === pModule.element);
            parentName = ElementUtil.formatElementName(moduleElement, t);
          }
        }
        if (type === ELEMENT_TYPE_MEASUREMENT
          || ([ELEMENT_TYPE_UNIQUE_CHOICE, ELEMENT_TYPE_MULTIPLE_CHOICES].includes(type)
            && !element.implementable)) {
          pElements.push({
            id: pElement.id,
            name: text || ElementUtil.formatElementName(element, t),
            sorting: pElement.sorting,
            tab: parentName,
            tab_sorting: page.sorting,
            type,
            module_sorting: pModule ? pModule.sorting : undefined,
          });
        }
      });
    });
    return pElements;
  };

  load = () => {
    this.setState({ loading: true });
    const { elementLinks: elLinks, target, targetType } = this.props;
    const pElements = this.fetchProjectElements();
    const elementlinks = Object.values(elLinks).filter((link) => (
      target.id === (targetType === TARGET_TYPE_ELEMENT ? link.element_target : link.page_target)
    ));
    const links = [];
    const newLinks = [];
    elementlinks.forEach((link) => {
      links.push(link);
      newLinks.push(link);
    });
    this.setState({
      elements: pElements
        .filter((pEl) => pEl !== undefined && pEl !== null)
        .sort(SortUtil.sortTriggerVariables),
      links,
      newLinks,
      loading: false,
    }, () => {
      if (this.state.links.length < 1 && this.state.newLinks.length < 1) {
        this.add();
      }
    });
  };

  add = () => {
    const { newLinks } = this.state;
    const newLink = {
      id: DbUtil.getTemporaryId(newLinks.map((link) => link.id)),
      source: undefined,
    };
    newLinks.push(newLink);
    this.setState({ newLinks });
  };

  remove = (id) => {
    const { newLinks } = this.state;
    this.setState({ newLinks: newLinks.filter((link) => link.id !== id) });
  };

  handleLinkChange = (eventLink) => {
    const { newLinks } = this.state;
    const index = newLinks.findIndex((link) => link.id === eventLink.id);
    newLinks[index] = eventLink;
    this.setState({ newLinks });
  };

  handleLinkOperatorChange = (event) => {
    const { value } = event.target;
    this.setState({ linkOperator: value });
  }

  checklLink = (link) => {
    if (this.isQualitative(link)) {
      if (!this.validation.idRegex.test(link.source)
        || !this.validation.idRegex.test(link.choice)) {
        return false;
      }
      return true;
    }
    if (!this.validation.actions.includes(link.action)
      || link.value === ''
      || !this.validation.idRegex.test(link.source)) {
      return false;
    }
    return true;
  };

  checkLinks = () => this.state.newLinks.every((link) => this.checklLink(link));

  linkHasChanged = (link, newLink) => (
    Object.entries(newLink).some(([key, value]) => link[key] !== value)
  );

  save = async () => {
    if (!this.mutex.isLocked()) {
      const release = await this.mutex.acquire();
      const { links, newLinks, linkOperator } = this.state;
      const {
        addLink, removeLink, patchLink, project, target, targetType, patchProjectElement,
        patchPage,
      } = this.props;

      if (project.inclusions_count) {
        // There may have some heavy processing
        this.setState({ loading: true });
      }

      if (!this.checkLinks()) {
        release();
        this.setState({
          loading: false,
          highlightMissing: true,
        });
        return;
      }

      const requests = [];

      links.forEach((link) => {
        const newLink = newLinks.find((lk) => lk.id === link.id);
        if (!newLink) {
          requests.push({ func: removeLink, args: [link.id], callback: () => {} });
        } else if (this.linkHasChanged(link, newLink)) {
          const { id, ...patch } = newLink;
          requests.push({ func: patchLink, args: [id, patch], callback: () => {} });
        }
      });

      newLinks.filter((link) => !links.find((lk) => link.id === lk.id)).forEach((link) => {
        const targetName = targetType === TARGET_TYPE_ELEMENT ? 'element_target' : 'page_target';
        requests.push({
          func: addLink,
          args: [{ ...link, [targetName]: target.id }],
          callback: (res) => {
            const { newLinks: nLks, links: lks } = this.state;
            const index = nLks.findIndex((lk) => lk.id === link.id);
            nLks[index] = res;
            lks.push(res);
            this.setState({ newLinks: nLks, links: lks });
          },
        });
      });

      try {
        // Create, patch or delete links
        // The requests must not be done in parallel
        /* eslint-disable-next-line no-restricted-syntax */
        for (let i = 0; i < requests.length; i += 1) {
          const dataUpdate = i === requests.length - 1;
          /* eslint-disable-next-line no-await-in-loop */
          const res = await requests[i].func(...requests[i].args, { data_update: dataUpdate });
          // Necessary in case of failure before end (duplicated links for exemple)
          requests[i].callback(res);
        }
        // Update project element or page operator if necessary
        if (target.link_operator !== linkOperator) {
          const data = { link_operator: linkOperator };
          const { id } = target;
          if (targetType === TARGET_TYPE_ELEMENT) {
            await patchProjectElement(id, data);
          } else {
            await patchPage(id, data);
          }
        }
        await this.modal.hide();
      } catch (error) {
        ErrorUtil.handleCatched(this.props, error);
      } finally {
        release();
        this.setState({ loading: false });
      }
    }
  };

  render() {
    const {
      t, admin, title, targetType,
    } = this.props;
    const { linkOperator, highlightMissing, newLinks } = this.state;
    const elementOptions = this.state.elements.map((element) => (
      <option key={element.id} value={element.id}>
        {`(${element.tab}) ${ElementUtil.formatElementName(element, t)}`}
      </option>
    ));

    const excludedChoices = newLinks.filter((link) => (
      this.isQualitative(link) && link.choice
    )).map((link) => link.choice);
    const existingLinksDOM = newLinks.filter((link) => (
      link.id
    )).map((link, i) => {
      const commonProps = {
        id: link.id,
        identifier: `${i + 1}.`,
        admin,
        elementOptions,
        highlightMissing,
        remove: this.remove,
        onChange: this.handleLinkChange,
      };
      return (
        <div key={link.id}>
          {i > 0 && (
            <LinkOperator
              className="mt-3 mb-3"
              disabled={i > 1}
              value={linkOperator}
              onChange={this.handleLinkOperatorChange}
            />
          )}
          {this.isNew(link) ? (
            <ElementBaseLink {...commonProps} />
          ) : (
            <>
              {this.isQualitative(link) && (
                <ElementQualitativeLink
                  {...commonProps}
                  choosenChoice={link.choice}
                  choosenElement={link.source}
                  excludedChoices={excludedChoices}
                />
              )}
              {this.isQuantitative(link) && (
                <ElementQuantitativeLink
                  {...commonProps}
                  choosenAction={link.action}
                  choosenValue={link.value}
                  choosenElement={link.source}
                />
              )}
            </>
          )}
        </div>
      );
    });

    return (
      <NewModal
        trigger={this.props.children}
        title={t('project:modal.link.title')}
        xlHeader={title}
        size="lg"
        type={2}
        onLoad={this.load}
        footer={(
          <div className="flex-fill d-flex flex-column">
            <div className="align-self-center">
              <button
                type="button"
                className="btn btn-newblue-1 text-white px-3"
                onClick={this.save}
                disabled={this.mutex.isLocked()}
              >
                {t('common:button.validate')}
              </button>
            </div>
            <div
              className="pt-4"
              style={{ padding: '0 6rem' }}
            >
              <FAQLink
                articleId={13}
              />
            </div>
          </div>
        )}
        ref={(modal) => {
          this.modal = modal;
        }}
      >
        <div className="form-group contains-loader">
          <div className="element-links">
            {existingLinksDOM.length > 0 ? (
              <div>
                <h6>
                  {t(`project:modal.link.${targetType === TARGET_TYPE_ELEMENT ? 'variable-will-appear-if' : 'page-will-appear-if'}`)}
                </h6>
                <div>{existingLinksDOM}</div>
              </div>
            ) : (
              <div className="text-center font-italic py-2">
                {t(`project:modal.link.there-is-no-rule.${targetType === TARGET_TYPE_ELEMENT ? 'element' : 'page'}`)}
              </div>
            )}
          </div>
          {this.state.loading && <CardLoader />}
        </div>
        <div className="d-flex justify-content-start">
          <button type="button" className="btn btn-yellow px-3 mt-4" onClick={this.add}>
            <FontAwesomeIcon
              className="mr-2"
              icon={['fal', 'plus']}
              transform="grow-1"
            />
            {t('project:modal.link.add-a-rule')}
          </button>
        </div>
      </NewModal>
    );
  }
}


export default ElementLinkManager;
