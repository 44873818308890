import { USER_TYPE_HEALTHCARE_FACILITY } from '../constants';

export default class UserUtil {
  /**
   * @param user User
   * @returns String
   */

  static getUserHome(user) {
    const showDashboard = user ? user.type !== USER_TYPE_HEALTHCARE_FACILITY : true;

    let res = 'projects';
    if (showDashboard) res = 'home/projects';

    return res;
  }
}
