import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { withToastManager } from 'react-toast-notifications';
import { Provider } from 'react-redux';
import store from '../redux/store';
import { nsOptions } from '../i18n';
import NewTooltip from './NewTooltip';
import { DelMsgModal } from './MessageModal';
import EditButton from './EditButton';
import { childrenPropTypes } from '../utils/generic-prop-types';
import { DataLocker } from '../utils/data-util';
import Toast from '../utils/Toast';
import ErrorUtil from '../utils/ErrorUtil';
import DomUtil from '../utils/DomUtil';
import { LOCK_TOAST_TIMEOUT_S } from '../constants';

const WarningManager = (props) => (
  props.warning ? (
    /* Next line probably required because of portal usage */
    <Provider store={store}>
      <DelMsgModal
        message={
          props.title ? (
            <span>
              <b>
                {props.title}
              </b>
              <br />
              <br />
              {props.warning}
            </span>
          ) : (
            <span>
              {props.warning}
            </span>
          )
        }
        onValidate={props.onClick}
        modalZIndex={10000}
      >
        {props.children}
      </DelMsgModal>
    </Provider>
  ) : props.children
);

WarningManager.propTypes = {
  warning: childrenPropTypes(),
  onClick: PropTypes.func.isRequired,
  children: childrenPropTypes().isRequired,
  title: childrenPropTypes(),
};

WarningManager.defaultProps = {
  warning: undefined,
  title: undefined,
};

// eslint-disable-next-line react/prefer-stateless-function
class ActionButton extends Component {
  static propTypes = {
    buttonName: PropTypes.string.isRequired,
    iconName: PropTypes.string.isRequired,
    warning: childrenPropTypes(),
    title: childrenPropTypes(),
    disabled: PropTypes.bool,
    onClick: PropTypes.func.isRequired,
  };

  static defaultProps = {
    warning: undefined,
    title: undefined,
    disabled: false,
  };

  render() {
    const {
      buttonName, iconName, onClick, warning, title, disabled,
    } = this.props;

    return (
      <div className="col-auto">
        <WarningManager
          warning={warning}
          onClick={onClick}
          title={title}
        >
          <button
            className={`no-button-style ${disabled ? 'text-gray' : ''}`}
            disabled={disabled}
            onClick={warning ? undefined : onClick}
          >
            <div className="invited-accounts-icon">
              <FontAwesomeIcon
                icon={['fal', iconName]}
              />
            </div>
            <div className="invited-accounts-label">
              {buttonName}
            </div>
          </button>
        </WarningManager>
      </div>
    );
  }
}

@withToastManager
@withTranslation('', nsOptions)
class InvitationTooltipBase extends Component {
  static propTypes = {
    children: childrenPropTypes().isRequired,
    t: PropTypes.func.isRequired,
    invitation: PropTypes.shape().isRequired,
    extraContent: childrenPropTypes(),
    showManagement: PropTypes.bool,
    admin: PropTypes.bool,
    dataLockerType: PropTypes.string.isRequired,
    resendInvitation: PropTypes.func.isRequired,
    cancelInvitation: PropTypes.func.isRequired,
    copyInvitationLink: PropTypes.func,
    blockGuest: PropTypes.func,
    removeGuest: PropTypes.func.isRequired,
    removeGuestLabel: PropTypes.string,
    removeGuestWarning: childrenPropTypes(),
    cancelInvitationWarning: childrenPropTypes(),
    role: PropTypes.string,
  };

  static defaultProps = {
    extraContent: null,
    showManagement: false,
    admin: false,
    blockGuest: undefined,
    copyInvitationLink: undefined,
    removeGuestLabel: 'common:button.take-out',
    removeGuestWarning: undefined,
    cancelInvitationWarning: undefined,
    role: undefined,
  };

  constructor(props) {
    super(props);
    this.state = { editing: false };
    this.editionLocker = new DataLocker(props.dataLockerType, undefined, props.admin);
  }

  onEditionToggle = async () => {
    const { t, invitation } = this.props;

    try {
      const res = await this.editionLocker.toggleLock(invitation.id);
      const { locked, success } = res;
      if (success) {
        this.setState({ editing: locked });
      } else {
        Toast.warning(this.props, DataLocker.lockUserMessage(res, t), LOCK_TOAST_TIMEOUT_S);
      }
    } catch (error) {
      ErrorUtil.handleCatched(this.props, error);
    }
  }

  onHide = async () => {
    if (this.editionLocker.isLocked()) {
      try {
        await this.editionLocker.unlock();
        this.setState({ editing: false });
      } catch (error) {
        ErrorUtil.handleCatched(this.props, error, false);
      }
    }
  }

  render() {
    const {
      t, children, invitation, extraContent, showManagement, resendInvitation, cancelInvitation,
      blockGuest, removeGuest, removeGuestLabel, removeGuestWarning, role, admin,
      cancelInvitationWarning, copyInvitationLink,
    } = this.props;
    const { editing } = this.state;
    const disabled = !editing;
    let extraContentWithProps = extraContent;

    if (disabled) {
      extraContentWithProps = DomUtil.nodeWithProps(extraContent, { readOnly: true });
    }

    const { confirmed, user } = invitation;

    return (
      <NewTooltip
        content={(
          <div>
            {role ? (
              <div className="px-1 pb-2">{role.toUpperCase()}</div>
            ) : null}
            <div className="row mx-0">
              <div className="col px-1">
                <div className="font-weight-bold mb-1">
                  {invitation.user.label || invitation.email}
                  {confirmed && user && (
                    <span className="font-italic font-weight-normal">
                      &nbsp;
                      -
                      &nbsp;
                      {t(`user:types.${user.type}`)}
                    </span>
                  )}
                </div>
              </div>
              { showManagement && (
                <div className="col-auto px-0">
                  <EditButton
                    transform="grow-5"
                    active={editing}
                    onClick={this.onEditionToggle}
                  />
                </div>
              )}
            </div>
            <div className="row mx-0">
              <div className="col px-1">
                {extraContentWithProps}
              </div>
            </div>
            { showManagement && (
              <div className="row justify-content-center invited-accounts-action mt-3">
                { invitation.confirmed ? (
                  <>
                    { blockGuest && (
                      <ActionButton
                        buttonName={t('common:button.block')}
                        iconName="pause"
                        disabled={disabled}
                        onClick={() => blockGuest(invitation)}
                      />
                    )}
                    <ActionButton
                      buttonName={t(removeGuestLabel)}
                      iconName="sign-out"
                      disabled={disabled}
                      onClick={() => removeGuest(invitation)}
                      warning={removeGuestWarning}
                      title={(
                        <span>
                          {t('common:caution')}
                          &nbsp;!
                        </span>
                      )}
                    />
                  </>
                ) : (
                  <>
                    <ActionButton
                      buttonName={t('project:button.re-send')}
                      iconName="paper-plane"
                      disabled={disabled}
                      onClick={() => resendInvitation(invitation)}
                    />
                    <ActionButton
                      buttonName={t('common:button.cancel')}
                      iconName="ban"
                      disabled={disabled}
                      onClick={() => cancelInvitation(invitation)}
                      warning={cancelInvitationWarning}
                      title={(
                        <span>
                          {t('common:caution')}
                          &nbsp;!
                        </span>
                      )}
                    />
                    {admin && copyInvitationLink && (
                      <NewTooltip
                        content={t('common:button.copy-invitation-link')}
                        disabled={disabled}
                      >
                        <ActionButton
                          buttonName={t('common:button.copy-link')}
                          iconName="link"
                          disabled={disabled}
                          onClick={() => copyInvitationLink(invitation)}
                        />
                      </NewTooltip>
                    )}
                  </>
                )}
              </div>
            )}
          </div>
        )}
        theme="invitation"
        placement="top"
        overFlowElement="clippingParents"
        interactive
        onHide={this.onHide}
        /* visible={invitation.email === 'aa@aa.com'} */
      >
        { children }
      </NewTooltip>
    );
  }
}

export default InvitationTooltipBase;
