import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import InlineEditor from '@ckeditor/ckeditor5-editor-inline/src/inlineeditor';
import FontColor from '@ckeditor/ckeditor5-font/src/fontcolor';
import FontBackgroundColor from '@ckeditor/ckeditor5-font/src/fontbackgroundcolor';
import Autoformat from '@ckeditor/ckeditor5-autoformat/src/autoformat';
import Bold from '@ckeditor/ckeditor5-basic-styles/src/bold';
import Strikethrough from '@ckeditor/ckeditor5-basic-styles/src/strikethrough';
import Essentials from '@ckeditor/ckeditor5-essentials/src/essentials';
import Heading from '@ckeditor/ckeditor5-heading/src/heading';
import Placeholder from '../utils/calculation-placeholders/placeholder';
import NewTooltip from './NewTooltip';
import { isVariable } from '../utils/calculations';
import {
  CKEDITOR_VARIABLE_BEGINNING, CKEDITOR_VARIABLE_END, CALCULATION_VARIABLE_PREFIX,
} from '../constants';


class CalculationEditor extends Component {
  constructor(props) {
    super(props);

    this.state = {
      invalid: false,
      invalidContent: '',
      invalidOutline: false,
      content: this.toHtmlFormula(props.formula),
      variables: props.formula.filter((v) => v.includes(CALCULATION_VARIABLE_PREFIX)),
      addingVariable: false,
    };

    this.actionKeys = ['Shift', 'Backspace', 'ArrowLeft', 'ArrowRight', 'Delete'];

    this.operatorKeys = [
      '+',
      '-',
      '*',
      'x',
      '/',
      '(',
      ')',
      '.',
      ',',
    ];

    this.numberKeys = [
      '1',
      '2',
      '3',
      '4',
      '5',
      '6',
      '7',
      '8',
      '9',
      '0',
    ];

    this.charKeys = [...this.operatorKeys, ...this.numberKeys];
    this.whitelistedKeys = [...this.charKeys, ...this.actionKeys];

    this.replaceKeys = {
      x: '*',
      ',': '.',
    };

    this.disabled = false;

    this.config = {
      plugins: [
        Heading,
        Bold,
        Strikethrough,
        Essentials,
        Autoformat,
        FontColor,
        FontBackgroundColor,
        Placeholder,
      ],
      removePlugins: ['toolbar'],
    };
  }

  toHtmlFormula = (formula) => {
    const formattedFormula = formula.map((v) => {
      if (isVariable(v, true)) {
        // Format variables
        const id = v.split('|')[0].split(CALCULATION_VARIABLE_PREFIX)[1];
        const newV = `${CKEDITOR_VARIABLE_BEGINNING}"placeholder" id="${id}">${v.split('|')[1]}${CKEDITOR_VARIABLE_END}`;
        return newV;
      }
      return v;
    }).join('');
    return formattedFormula;
  }

  getSpanId = (documentElement) => {
    const spanId = documentElement.getElementsByTagName('span').item(0).id;
    return spanId;
  }

  stringFormulaToArray = (data) => {
    const { variables } = this.state;
    const n = data.length;
    const x = CKEDITOR_VARIABLE_BEGINNING;
    const y = CKEDITOR_VARIABLE_END;
    let index = 0;
    const parser = new DOMParser();
    const formula = [];
    if (data === '&nbsp;') {
      return formula;
    }
    while (index < n) {
      // A variable is enclosed in CKEDITOR_VARIABLE tags
      if (data.substring(index, index + x.length) === x) {
        const endIndex = data.indexOf(y, index) + y.length;
        const varName = data.substring(index, endIndex);
        // Remove HTML tags and the surrounding spaces in the variable name
        const doc = parser.parseFromString(varName, 'text/html').documentElement;
        const varId = this.getSpanId(doc);
        // Find the corresponding variable (with its id)
        const nameWithoutTags = variables.find(
          (v) => v.split('|')[0].split(CALCULATION_VARIABLE_PREFIX)[1] === varId,
        );
        if (nameWithoutTags) {
          formula.push(nameWithoutTags);
        }
        index = endIndex;
      } else {
        const key = data.charAt(index);
        if (!Number.isNaN(Number.parseFloat(key))) {
          if (index > 0 && !Number.isNaN(Number.parseFloat(formula[formula.length - 1]))) {
            // Gather digits of numbers in the formula
            const lastKey = formula[formula.length - 1];
            formula[formula.length - 1] = `${lastKey}${key}`;
          } else {
            formula.push(data.charAt(index));
          }
        } else if (index > 0 && key === '.') {
          const lastKey = formula[formula.length - 1];
          if (!Number.isNaN(Number.parseFloat(lastKey))) {
            // lastKey is a number
            if (!lastKey.includes('.')) {
              formula[formula.length - 1] = `${lastKey}${key}`;
            } else {
              formula.push(key);
            }
          } else {
            // lastKey is not a number
            formula.push(key);
          }
        } else {
          formula.push(data.charAt(index));
        }
        index += 1;
      }
    }
    return formula;
  };


  onChangeFormula = (newData) => {
    const { addingVariable } = this.state;
    if (!addingVariable) {
      const { onChange } = this.props;
      // Transform string formula from the editor into an array for the API
      const data = this.stringFormulaToArray(newData, this.state.variables, this.replaceKeys);
      onChange(data);
    }
  }

  showError = (text, autoDismiss = true, outline = false) => {
    this.setState({ invalid: true, invalidContent: text, invalidOutline: outline }, () => {
      if (autoDismiss) {
        setTimeout(() => {
          this.setState({ invalid: false, invalidOutline: false });
        }, 4000);
      }
    });
  };

  hideError = () => {
    this.setState({ invalid: false, invalidOutline: false });
  };

  calcInputWidth = () => {
    const input = document.querySelector('.input-wrap .calculation-input');
    const widthMachine = document.querySelector('.calculation-input');
    widthMachine.innerHTML = input.value;
  }

  addVariable = (newVar) => {
    this.setState({ addingVariable: true });
    const stateVariables = this.state.variables;
    const { onChange } = this.props;
    // Store the variable reference
    if (!stateVariables.some((v) => v.includes(newVar.split('|')[0]))) {
      stateVariables.push(newVar);
      this.setState({ variables: stateVariables });
    }
    if (this.ckeditor && this.ckeditor.editor) {
      // Create a placeholder to display the variable
      this.ckeditor.editor.execute('placeholder', { value: newVar.split('|')[1], id: newVar.split('|')[0].split(CALCULATION_VARIABLE_PREFIX)[1] });
      const data = this.ckeditor.editor.getData();
      const dataWithoutPTags = data.replace(/(<p[^>]+?>|<p>|<\/p>)/img, '');
      const newData = this.stringFormulaToArray(dataWithoutPTags, stateVariables);
      // Apply changes
      onChange(newData);
      this.ckeditor.editor.editing.view.focus();
    }
    this.setState({ addingVariable: false });
  }

  onReady = (editor) => {
    const { t } = this.props;
    // Hide toolbar
    editor.ui.view.toolbar.element.remove();
    // Listen to keydown event
    editor.editing.view.document.on('keydown', (evt, data) => {
      const { key } = data.domEvent;
      // Disable Enter key
      if (key === 'Enter') {
        data.stopPropagation();
        data.preventDefault();
        evt.stop();
      }
      // Prevent from typing unauthorized characters
      if (!this.charKeys.includes(key) && !this.actionKeys.includes(key) && key !== 'Enter') {
        data.preventDefault();
        evt.stop();
        this.showError(t('project:calculations.invalid-character'));
      }
      // Replace 'x' by '*' and ',' by '.'
      if (this.replaceKeys[key]) {
        data.preventDefault();
        evt.stop();
        const cursorPosition = editor.model.document.selection.getFirstPosition();
        editor.model.change((writer) => {
          writer.insertText(this.replaceKeys[key], cursorPosition);
        });
      }
    }, { priority: 'highest' });
  }

  render() {
    const {
      previewMode,
    } = this.props;
    const {
      invalid, invalidContent, invalidOutline,
    } = this.state;

    return (
      previewMode ? (
        <CKEditor
          editor={InlineEditor}
          data={this.toHtmlFormula(this.props.formula)}
          disabled={this.disabled}
          config={this.config}
        />
      ) : (
        <NewTooltip
          theme="red"
          content={invalidContent || 'error'}
          trigger="manual"
          visible={invalid}
        >
          <div
            className={`calculation-editor fake-input  fake-old-input${invalidOutline ? ' invalid' : ''}`}
          >
            <CKEditor
              editor={InlineEditor}
              config={this.config}
              onReady={(editor) => this.onReady(editor)}
              data={this.state.content}
              onChange={(evt, editor) => {
                // Get the editor content
                const data = editor.getData();
                // Remove <p> tags
                const dataWithoutPTags = data.replace(/(<p[^>]+?>|<p>|<\/p>)/img, '');
                // Process the editor content
                this.onChangeFormula(dataWithoutPTags);
              }}
              ref={(editor) => {
                this.ckeditor = editor;
              }}
              disabled={this.disabled}
            />
          </div>
        </NewTooltip>
      )
    );
  }
}

CalculationEditor.propTypes = {
  formula: PropTypes.arrayOf(PropTypes.string).isRequired,
  onChange: PropTypes.func,
  previewMode: PropTypes.bool,
  t: PropTypes.func.isRequired,
};

CalculationEditor.defaultProps = {
  onChange: () => {},
  previewMode: false,
};

export default CalculationEditor;
