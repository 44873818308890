import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import toastr from 'toastr';
import '../assets/css/initial-loader.css';
import logo from '../assets/img/light_logo.png';
import { nsOptions } from '../i18n';


@withTranslation('', nsOptions)
class InitialLoader extends Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
    loaderMessage: PropTypes.string,
    loaderTitle: PropTypes.string,
  };

  static defaultProps = {
    loaderMessage: null,
    loaderTitle: null,
  };

  render() {
    const { t } = this.props;
    if (this.props.loaderMessage && this.props.loaderTitle) {
      toastr.options.preventDuplicates = true;
      toastr.options.progressBar = true;
      toastr.error(t(this.props.loaderMessage), t(this.props.loaderTitle));
    }
    return (
      <div id="initial-loader">
        <img src={logo} className="initial-loader-logo" alt="" />
        <div className="initial-loader-indicator">
          <svg width="16px" height="12px">
            <polyline
              id="initial-loader-back"
              points="1 6 4 6 6 11 10 1 12 6 15 6"
            />
            <polyline
              id="initial-loader-front"
              points="1 6 4 6 6 11 10 1 12 6 15 6"
            />
          </svg>
        </div>
        <div className="initial-loader-message">
          {t('common:loading')}
        </div>
      </div>
    );
  }
}


export default InitialLoader;
