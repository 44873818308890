import api from '../../api';
import buildStdDbModelActions from './std-db-model-actions';

const stdDbModelActions = buildStdDbModelActions('element-links');

const listByProjectElements = (projectElements, params = {}, options = {}) => async (dispatch) => {
  // 2 steps fetching to simulate 'OR operation' between target and source
  // Pagination must be disabled
  const res = {};
  const newOptions = { ...options, pagination: 'no' };
  const promRes = await Promise.all([
    api.list('element-links', { ...params, element_target__in: projectElements }, newOptions),
    api.list('element-links', { ...params, source__in: projectElements }, newOptions),
  ]);
  res.results = [...promRes[0], ...promRes[1]];
  res.count = res.results.length;
  dispatch(stdDbModelActions.listSuccess(res.results));
  return res;
};

export default {
  ...stdDbModelActions,
  listByProjectElements,
};
