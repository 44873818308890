import React from 'react';
import '../assets/css/error.css';

export default (() => (
  <div className="error">
    <div className="container">
      <div className="row justify-content-center">
        <div className="col-4">
          <h3>Erreur : page introuvable</h3>
        </div>
      </div>
    </div>
  </div>
));
