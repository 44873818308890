import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { projectElementsActions, sortingHandlerActions } from '../redux/actions';
import { getSortingHandlerId, extractSortingItemDigitalId } from '../redux/actions/sorting-handler';
import { nsOptions } from '../i18n';
import { childrenPropTypes } from '../utils/generic-prop-types';
import LabeledSelect from './LabeledSelect';
import NewModal from './NewModal';
import { CardLoader } from './Loader';
import SortUtil from '../utils/SortUtil';
import { formatPageTitle } from '../utils/data-util';
import ElementUtil from '../utils/ElementUtil';
import ErrorUtil from '../utils/ErrorUtil';


const mapStateToProps = (state) => ({
  elements: state.elements,
  pages: state.projectPages,
  projectElements: state.projectElements,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  moveProjectElement: async (pElementId, currentTabId, newPos, newTabId) => (
    dispatch(projectElementsActions.move(pElementId, currentTabId, newPos, newTabId, {
      admin: ownProps.admin,
    }))
  ),
  moveItem: async (
    sourceIndex,
    sourceHandlerId,
    destinationIndex,
    destinationHandlerId,
  ) => dispatch(sortingHandlerActions.moveItem({
    sourceIndex,
    sourceHandlerId,
    destinationIndex,
    destinationHandlerId,
  })),
});


@connect(mapStateToProps, mapDispatchToProps)
@withTranslation('', nsOptions)
class ElementMoveManager extends Component {
  static propTypes = {
    children: childrenPropTypes().isRequired,
    parent: PropTypes.shape().isRequired,
    insideModule: PropTypes.bool,
    elementName: PropTypes.string.isRequired,
    projectElement: PropTypes.shape().isRequired,
    pages: PropTypes.shape().isRequired,
    tabId: PropTypes.number,
    t: PropTypes.func.isRequired,
    elements: PropTypes.shape().isRequired,
    projectElements: PropTypes.shape().isRequired,
    moveProjectElement: PropTypes.func.isRequired,
    moveItem: PropTypes.func.isRequired,
    formSortingHandlerId: PropTypes.string.isRequired,
    admin: PropTypes.bool,
  };

  static defaultProps = {
    tabId: null,
    insideModule: false,
    admin: false,
  };

  constructor(props) {
    super(props);
    this.state = {
      tabOptions: null,
      elementOptions: null,
      loading: false,
    };
    this.modal = null;
    this.tabSelect = null;
    this.elementSelect = null;
  }

  fetchMoveContent = (tabId) => {
    const {
      t, elements, projectElement, projectElements, insideModule, parent, pages,
    } = this.props;
    const tabOptions = [];

    if (insideModule) {
      tabOptions.push(<option
        key={0}
        value={null}
        selected={!tabId}
      >
        {elements[parent.element].name}
      </option>);
    } else {
      tabOptions.push(...Object.values(pages).sort(SortUtil.sortArray).map((page) => (
        <option
          key={page.id}
          value={page.id}
          selected={page.id === tabId}
        >
          {formatPageTitle(page, t)}
        </option>
      )));
    }

    const elementOptions = [
      <option key={null} value={0}>
        {t('common:form.select-top-position')}
      </option>,
      ...Object.values(projectElements).filter((pEl) => (
        // A null tabId means that the movement will be performed into the
        // the module
        (tabId ? pEl.project_page === tabId && pEl.module === null : pEl.module === parent.id)
          && pEl.id !== projectElement.id
      )).sort(SortUtil.sortArray)
        .map((pEl) => (
          <option key={pEl.id} value={pEl.sorting}>
            {ElementUtil.formatElementName(elements[pEl.element], t)}
          </option>
        )),
    ];

    this.setState({
      tabOptions,
      elementOptions,
    });
  };

  moveElement = async () => {
    if (!this.tabSelect || !this.elementSelect) return;
    const {
      projectElement, moveItem, moveProjectElement, formSortingHandlerId, admin, insideModule,
    } = this.props;
    let tabId = parseInt(this.tabSelect.value, 10);
    if (Object.is(tabId, NaN)) tabId = null;
    const externalMove = (tabId && insideModule)
      || (tabId && tabId !== parseInt(extractSortingItemDigitalId(formSortingHandlerId), 10));
    const elementPosition = this.elementSelect.value;
    let newPosition = parseInt(elementPosition, 10);

    let destinationHandlerId = formSortingHandlerId;
    if (externalMove) {
      // The item is moved outside the current list (<> formSortingHandlerId)
      destinationHandlerId = getSortingHandlerId('project-form', tabId);
    }

    const ascendantMove = projectElement.sorting > newPosition;
    const sourceIndex = projectElement.sorting - 1;
    const destinationIndex = ascendantMove || externalMove ? newPosition : newPosition - 1;

    // Take into account that depending on the moving way the api appends
    // or prepends
    if (ascendantMove || externalMove) newPosition += 1;

    try {
      if (externalMove) {
        // There may have some heavy processing
        this.setState({ loading: true });
      }
      await moveProjectElement(projectElement.id, projectElement.project_page, newPosition, tabId,
        admin);
      if (this.modal) this.modal.hide();
      moveItem(
        sourceIndex,
        formSortingHandlerId,
        destinationIndex,
        destinationHandlerId,
      );
    } catch (error) {
      ErrorUtil.handleCatched(this.props, error);
      this.setState({ loading: false });
    }
  };

  render() {
    const { t, tabId, insideModule } = this.props;
    const { loading, tabOptions, elementOptions } = this.state;
    return (
      <NewModal
        trigger={this.props.children}
        title={t('project:modal.move.title')}
        xlHeader={this.props.elementName}
        size="sm"
        type={2}
        onLoad={() => this.fetchMoveContent(insideModule ? null : tabId)}
        footer={(
          <div className="row">
            <button
              type="button"
              className="btn btn-newblue-1 text-white px-3"
              onClick={this.moveElement}
            >
              {t('common:button.move')}
            </button>
          </div>
        )}
        ref={(modal) => {
          this.modal = modal;
        }}
      >
        <LabeledSelect
          className={insideModule ? 'd-none' : ''}
          label={t('project:modal.move.select-page')}
          name="in"
          required
          onChange={(e) => {
            this.fetchMoveContent(parseInt(e.target.value, 10));
          }}
          selectRef={(select) => {
            this.tabSelect = select;
          }}
        >
          {tabOptions}
        </LabeledSelect>
        <LabeledSelect
          label={t(`project:modal.move.${insideModule ? 'select-position-in-module' : 'select-position'}`)}
          name="after"
          required
          selectRef={(select) => {
            this.elementSelect = select;
          }}
        >
          {elementOptions}
        </LabeledSelect>
        {loading && <CardLoader />}
      </NewModal>
    );
  }
}


export default ElementMoveManager;
