import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { withToastManager } from 'react-toast-notifications';
import Page from '../components/Page';
import api from '../api';
import history from '../history';
import { nsOptions } from '../i18n';
import Toast from '../utils/Toast';


@withToastManager
@withTranslation('', nsOptions)
class Validate extends Component {
  static propTypes = {
    match: PropTypes.shape({
      params: PropTypes.shape({
        userId: PropTypes.string,
        token: PropTypes.string,
      }),
    }).isRequired,
  };

  constructor(props) {
    super(props);
    api.logout();
  }

  componentDidMount() {
    const { match } = this.props;
    api.read('validate-email', match.params.token).then(() => {
      history.push('/auth/account-validated');
    }).catch(() => {
      Toast.error(this.props, 'error:error.cannot-validate');
      Toast.error(this.props, 'error:error.please-open-email-again');
      history.push('/');
    });
  }

  render() {
    return (
      <Page
        {...this.props}
        title="Account validation"
        checkAuthorizations={[]}
      />
    );
  }
}


export default Validate;
