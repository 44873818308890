import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  CellMeasurer, CellMeasurerCache, createMasonryCellPositioner, Masonry, AutoSizer,
} from 'react-virtualized';
import withStyles from '@material-ui/core/styles/withStyles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Container from '@material-ui/core/Container';
import Tooltip from '@material-ui/core/Tooltip';

const COLUMN_WIDTH = 65;
const HEIGHT = 200;
const GUTTER_SIZE = 20;
const LEFT_OFFSET = 1; // Position fix (top shadow cropped)
const TOP_OFFSET = 1; // Position fix (left shadow cropped)
const CELL_WIDTH = COLUMN_WIDTH;
const CELL_HEIGHT = 40;


const styles = () => ({
  inclusionCardContent: {
    padding: '8px 15px',
    textAlign: 'center',
    '&:last-child': {
      paddingBottom: 8,
    },
  },
  masonry: {
    '& .ReactVirtualized__Masonry__innerScrollContainer': {
      'min-height': HEIGHT,
    },
  },
});

const LightTooltip = withStyles({
  tooltip: {
    backgroundColor: 'white',
    color: 'inherit',
    fontSize: 'inherit',
    boxShadow: '0px 3px 3px -2px rgb(0 0 0 / 20%), 0px 3px 4px 0px rgb(0 0 0 / 14%), 0px 1px 8px 0px rgb(0 0 0 / 12%)',
    maxWidth: 'unset',
  },
})(Tooltip);

@withStyles(styles)
class DataOverviewList extends Component {
  static propTypes = {
    list: PropTypes.arrayOf(PropTypes.shape()).isRequired,
    classes: PropTypes.shape().isRequired,
    getInclusionTooltipContent: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.cache = new CellMeasurerCache({
      defaultHeight: CELL_HEIGHT,
      defaultWidth: CELL_WIDTH,
      fixedWidth: true,
      fixedHeight: true,
    });
  }

  calculateColumnCount = () => {
    this.columnCount = Math.floor(this.width / (COLUMN_WIDTH + GUTTER_SIZE));
  }

  setMasonryRef = (ref) => {
    this.masonry = ref;
  }

  onResize = ({ width }) => {
    this.width = width;
    this.calculateColumnCount();
    this.resetCellPositioner();
    this.masonry.recomputeCellPositions();
  }

  initCellPositioner = () => {
    if (!this.cellPositioner) {
      this.cellPositioner = createMasonryCellPositioner({
        cellMeasurerCache: this.cache,
        columnCount: this.columnCount,
        columnWidth: COLUMN_WIDTH,
        spacer: GUTTER_SIZE,
      });
    }
  }

  resetCellPositioner = () => {
    this.cellPositioner.reset({
      columnCount: this.columnCount,
      columnWidth: COLUMN_WIDTH,
      spacer: GUTTER_SIZE,
    });
  }

  cellRenderer = ({
    index, key, parent, style,
  }) => {
    const {
      list, getInclusionTooltipContent, classes,
    } = this.props;
    const inclusion = list[index];

    return (
      <CellMeasurer
        cache={this.cache}
        index={index}
        key={key}
        parent={parent}
      >
        <LightTooltip
          key={key}
          interactive
          placement="top"
          title={getInclusionTooltipContent(inclusion)}
          enterTouchDelay={50}
        >
          <Container style={{
            ...style,
            padding: 0,
            margin: 0,
            width: CELL_WIDTH - LEFT_OFFSET,
            height: CELL_HEIGHT - TOP_OFFSET,
          }}
          >
            <Card elevation={3}>
              <CardContent className={classes.inclusionCardContent}>
                {inclusion.per_project_id}
              </CardContent>
            </Card>
          </Container>
        </LightTooltip>
      </CellMeasurer>
    );
  }

  render() {
    const { list, classes } = this.props;

    this.initCellPositioner();

    return (
      <AutoSizer
        disableHeight
        height={HEIGHT}
        onResize={this.onResize}
      >
        {({ width }) => (
          <Masonry
            className={classes.masonry}
            overscanByPixels={50}
            cellCount={list.length}
            cellMeasurerCache={this.cache}
            cellPositioner={(index) => {
              const { left, top } = this.cellPositioner(index);
              return { left: left + LEFT_OFFSET, top: top + TOP_OFFSET };
            }}
            cellRenderer={this.cellRenderer}
            height={HEIGHT}
            ref={this.setMasonryRef}
            width={width}
            scrollingResetTimeInterval={500}
          />
        )}
      </AutoSizer>
    );
  }
}

export default DataOverviewList;
