import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import withStyles from '@material-ui/core/styles/withStyles';
import DialogContentText from '@material-ui/core/DialogContentText';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { KeyboardDatePicker, KeyboardTimePicker } from '@material-ui/pickers';
import { nsOptions } from '../../i18n';
import { isDate, isDateTime, isTime } from '../../utils/stats';
import { getDateInputPlaceholder } from '../ElementDate';
import { getDateFormat } from '../../utils/Formatter';
import Modal from './Modal';

const styles = (theme) => ({
  root: {
    padding: theme.spacing(2),
  },
  formElement: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    '& .MuiFormControlLabel-label, & .MuiInput-input': {
      fontSize: theme.typography.body2.fontSize,
    },
  },
  placeholder: {
    fontSize: theme.typography.body2.fontSize,
  },
});

@withStyles(styles)
@withTranslation('', nsOptions)
class BoundField extends React.Component {
  static propTypes = {
    label: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([PropTypes.shape(), PropTypes.string]),
    onChange: PropTypes.func.isRequired,
    type: PropTypes.string.isRequired,
    autoFocus: PropTypes.bool,
    classes: PropTypes.shape().isRequired,
    t: PropTypes.func.isRequired,
  };

  static defaultProps = {
    value: null,
    autoFocus: false,
  };

  render() {
    const {
      label, value, onChange, type, autoFocus, classes, t, // i18n,
    } = this.props;

    if (isDate(type) || isDateTime(type)) {
      return (
        <KeyboardDatePicker
          disableToolbar
          variant="inline"
          format={getDateFormat(type)}
          margin="normal"
          label={label}
          placeholder={getDateInputPlaceholder(t, type)}
          value={value}
          onChange={onChange}
          KeyboardButtonProps={{
            'aria-label': 'change date',
            disabled: true,
            style: { display: 'none' },
          }}
        />
      );
    } if (isTime(type)) {
      return (
        <KeyboardTimePicker
          ampm={false/* ['en'].includes(i18n.language) */}
          variant="inline"
          format={t('common:elements.time-format')}
          margin="normal"
          label={label}
          placeholder={t('error:placeholder.time')}
          value={value}
          onChange={onChange}
          KeyboardButtonProps={{
            'aria-label': 'change time',
            disabled: true,
            style: { display: 'none' },
          }}
        />
      );
    }
    return (
      <TextField
        autoFocus={autoFocus}
        label={label}
        InputLabelProps={{ className: classes.placeholder }}
        fullWidth
        type="number"
        value={value}
        onChange={(event) => onChange(event.target.value)}
        className={classes.formElement}
      />
    );
  }
}


@withTranslation('', nsOptions)
@withStyles(styles)
class QuantitativeFilterModal extends Component {
   static propTypes = {
     open: PropTypes.bool,
     onValidate: PropTypes.func,
     onClose: PropTypes.func,
     t: PropTypes.func.isRequired,
     classes: PropTypes.shape().isRequired,
     type: PropTypes.string.isRequired,
   };

  static defaultProps = {
    open: false,
    onValidate: () => {},
    onClose: () => {},
  };

  static getInitialState = (type) => {
    const isDateOrTime = isDate(type) || isDateTime(type) || isTime(type);
    return {
      lowBound: isDateOrTime ? null : '',
      highBound: isDateOrTime ? null : '',
      excludeLimits: false,
    };
  };

  constructor(props) {
    super(props);
    this.state = { ...QuantitativeFilterModal.getInitialState(props.type) };
  }

  onClose = () => {
    this.resetState();
    const { onClose } = this.props;
    if (onClose) onClose();
  }

  resetState = () => {
    this.setState({ ...QuantitativeFilterModal.getInitialState(this.props.type) });
  }

  isFormValid = () => {
    const { lowBound, highBound } = this.state;
    const checkBound = (value) => value !== undefined && value !== null && value !== '';
    return checkBound(lowBound) || checkBound(highBound);
  }

  render() {
    const {
      open, onValidate, onClose, t, classes, type,
    } = this.props;
    const { lowBound, highBound, excludeLimits } = this.state;


    return (
      <Modal
        open={open}
        onClose={this.onClose}
        onClickClose={onClose}
        onClickValidate={() => onValidate(lowBound, highBound, !excludeLimits)}
        paperProps={{ className: classes.root }}
        title={t('stats:filter-interval-modal.title')}
        validateButtonDisabled={!this.isFormValid()}
      >
        <DialogContentText>
          { t('stats:filter-interval-modal.help') }
        </DialogContentText>
        <FormControl fullWidth>
          <BoundField
            autoFocus
            label={t('stats:filter-interval-modal.low-bound')}
            value={lowBound}
            type={type}
            onChange={(value) => this.setState({ lowBound: value })}
          />
          <BoundField
            label={t('stats:filter-interval-modal.high-bound')}
            value={highBound}
            type={type}
            onChange={(value) => this.setState({ highBound: value })}
          />
          <FormControlLabel
            control={(
              <Checkbox
                checked={excludeLimits}
                onChange={(e) => this.setState({ excludeLimits: e.target.checked })}
                color="primary"
              />
            )}
            label={t('stats:filter-interval-modal.exclude-limits')}
            className={classes.formElement}
          />
        </FormControl>
      </Modal>
    );
  }
}

export default QuantitativeFilterModal;
