import moment from 'moment';
import {
  DATE_FORMATS, TIME, DATE_FULL, DATE_MONTH_YEAR, DATE_TIME, DATE_YEAR,
} from '../constants';

export const DATE_FORMAT = 'DD/MM/YYYY';
export const API_DATE_TIME_FORMAT = moment.defaultFormat;
export const TIME_FORMAT = 'HH:mm:ss';

export const truncDate = (value, format) => {
  if (typeof value === 'string') {
    const truncFromRegexp = (regexp) => {
      const res = value.match(regexp);
      return res && res.length ? res[0] : value;
    };
    switch (format) {
      case DATE_YEAR:
        return truncFromRegexp(/^[0-9]{4}/);
      case DATE_MONTH_YEAR:
        return truncFromRegexp(/^[0-9]{4}-[0-9]{2}/);
      case DATE_FULL:
        return truncFromRegexp(/^[0-9]{4}-[0-9]{2}-[0-9]{2}/);
      case DATE_TIME:
      default:
        return value;
    }
  }
  // Value is not a string, so returns it without doing anything on it
  return value;
};

export const toMoment = (value, format, momentFormat = API_DATE_TIME_FORMAT, strict = false) => {
  if (format === TIME) {
    return moment(value, TIME_FORMAT, strict);
  }
  if (DATE_FORMATS.includes(format)) {
    const effectiveValue = truncDate(value, format);
    if (format === DATE_TIME) {
      return moment(effectiveValue, momentFormat, strict);
    }
    return moment(effectiveValue, undefined, strict);
  }
  return moment(value, momentFormat, strict);
};
