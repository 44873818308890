import { createReducer } from 'redux-starter-kit';
import { projectsActions } from '../actions';
import getStdDbModelReduxMapping from './std-db-model-reducers';

export const projectsInitialState = {};

const projectsReducer = createReducer(
  projectsInitialState,
  getStdDbModelReduxMapping(projectsActions),
);

export default projectsReducer;
