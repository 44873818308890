import moment from 'moment';

// eslint-disable-next-line import/prefer-default-export
export const setLanguage = async (i18n, lang) => {
  try {
    await i18n.changeLanguage(lang);
    moment.locale(lang);
  } catch (error) {
    console.error(error);
  }
};
