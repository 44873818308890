/* eslint-disable react/jsx-filename-extension,react/prop-types */
import { library } from '@fortawesome/fontawesome-svg-core';
import React from 'react';
import ReactDOM from 'react-dom';
import { I18nextProvider } from 'react-i18next';
import { ToastProvider } from 'react-toast-notifications';
import { ToastContainer } from 'react-toast-notifications/dist/ToastContainer';
import { Provider } from 'react-redux';
import store from './redux/store';
import App from './App';
import i18n from './i18n';
import fontawesome from './fontawesome';

library.add(...fontawesome);

ReactDOM.render(
  <ToastProvider
    autoDismissTimeout={8000}
    placement="top-right"
    transitionDuration={400}
    components={{
      ToastContainer: (props) => (
        <div className="react-toast-container">
          <ToastContainer {...props} />
        </div>
      ),
    }}
  >
    <Provider store={store}>
      <I18nextProvider i18n={i18n}>
        <App />
      </I18nextProvider>
    </Provider>
  </ToastProvider>,
  document.getElementById('root'),
);
