import moment from 'moment';
import Formatter from './Formatter';
import {
  ELEMENT_TYPE_MEASUREMENT, ELEMENT_TYPE_CALCULATION, ELEMENT_TYPE_DATE, DATE_TIME, DATE_FULL,
} from '../constants';
import ElementUtil from './ElementUtil';
import { toMoment } from './date';

const BOUNDS_ERROR = 'error:consistency-check.inconsistent-bounds';
const DECIMALS_ERROR = 'error:consistency-check.decimals-not-allowed';

export const checkSettingsValidity = (
  t, lowBound = '', highBound = '', integer = null, canBeEqual = false,
) => {
  // Check minimum and maximum compatibility
  const min = Number.parseFloat(lowBound);
  const max = Number.parseFloat(highBound);
  if (!Number.isNaN(min) && !Number.isNaN(max)) {
    if (!canBeEqual && (max <= min)) {
      throw new Error(t(BOUNDS_ERROR));
    }
    if (integer) {
      if (!(Number.isInteger(min) && Number.isInteger(max))) {
        throw new Error(t(DECIMALS_ERROR));
      }
    }
  }
  if (!Number.isNaN(min) || !Number.isNaN(max)) {
    const value = Number.isNaN(min) ? max : min;
    if (integer) {
      if (!Number.isInteger(value)) {
        throw new Error(t(DECIMALS_ERROR));
      }
    }
  }
};

export const checkDateValidity = (
  t, minDate = null, maxDate = null, isIncDate = false, isAVariable = false,
  canBeEqual = false,
) => {
  // Check if the date is valid
  const checkDate = (date) => {
    if (date && date instanceof moment) {
      if (!date.isValid()) {
        throw new Error(t('error:error.invalid-date'));
      }
    }
  };

  // Check if limit dates are consistent with each other and if they can be equal
  const checkDates = (min, max) => {
    if (min instanceof moment && max instanceof moment) {
      if (max.isBefore(min) || (!canBeEqual && max.isSame(min))) {
        throw new Error(t('error:consistency-check.inconsistent-dates'));
      }
    }
  };

  // Check if the other limits (inclusion date, a variable from the form) can be equal
  const checkEqualities = () => {
    if (!canBeEqual && (isIncDate || isAVariable)) {
      throw new Error(t('error:consistency-check.inconsistent-dates'));
    }
  };

  checkDate(minDate);
  checkDate(maxDate);
  if (minDate && maxDate) {
    checkDates(minDate, maxDate);
  }
  checkEqualities();
};

export const consistencyCheckActive = (element) => {
  switch (element.type) {
    case ELEMENT_TYPE_MEASUREMENT: return Boolean(
      !Number.isNaN(Number.parseFloat(element.min))
        || !Number.isNaN(Number.parseFloat(element.max))
        || element.integer,
    );
    case ELEMENT_TYPE_CALCULATION: return Boolean(
      !Number.isNaN(Number.parseFloat(element.min))
        || !Number.isNaN(Number.parseFloat(element.max)),
    );
    case ELEMENT_TYPE_DATE: return Boolean(
      element.before || element.after || element.less_than_inc_date
        || element.greater_than_inc_date || element.before_var || element.after_var,
    );
    default: return false;
  }
};

export const formatDate = (format = DATE_FULL, value) => {
  const emptyComponent = undefined;
  const formatter = new Formatter(format, emptyComponent);
  const date = toMoment(value, format);
  return formatter.format(date);
};

export const getConsistencySettings = (
  element, projectElements = null, elements = null, pages = null, t, inclusion,
) => {
  const res = [];
  switch (element.type) {
    case ELEMENT_TYPE_MEASUREMENT: {
      const settings = {
        min: element.min,
        max: element.max,
        strict: element.strict,
        integer: element.integer,
      };
      Object.keys(settings).forEach((settingName) => {
        const value = settings[settingName];
        const setting = { text: '', value: null, comment: '' };
        if (value !== undefined) {
          if (settingName !== 'strict') {
            setting.text = t(`project:consistency-check.settings.${settingName}`);
            setting.value = value;
            res.push(setting);
          } else if (value) {
            setting.text = t(`project:consistency-check.settings.${settingName}`);
            res.push(setting);
          }
        }
      });
      return res;
    }
    case ELEMENT_TYPE_CALCULATION: {
      const { min, max } = element;
      if (min === max) {
        const setting = { text: '', value: null, comment: '' };
        setting.text = t('project:consistency-check.settings.specific-value');
        setting.value = min;
        res.push(setting);
      } else {
        const settings = {
          min: element.min,
          max: element.max,
          strict: element.strict,
        };
        Object.keys(settings).forEach((settingName) => {
          const value = settings[settingName];
          const setting = { text: '', value: null, comment: '' };
          if (value !== undefined) {
            if (settingName !== 'strict') {
              setting.text = t(`project:consistency-check.settings.${settingName}`);
              setting.value = value;
              res.push(setting);
            } else if (value) {
              setting.text = t(`project:consistency-check.settings.${settingName}`);
              res.push(setting);
            }
          }
        });
      }
      return res;
    }
    case ELEMENT_TYPE_DATE: {
      const { format } = element;
      const before = element.before ? formatDate(format, element.before) : undefined;
      const after = element.after ? formatDate(format, element.after) : undefined;
      const beforeVar = element.before_var;
      const afterVar = element.after_var;
      const lessThanIncDate = element.less_than_inc_date;
      const greaterThanIncDate = element.greater_than_inc_date;
      const customDate = before && after && before === after;
      const formDate = beforeVar && afterVar && beforeVar === afterVar;
      const specificDate = (customDate || (greaterThanIncDate && lessThanIncDate)
        || formDate);
      const castMinToDate = element.trunc_min === 'date';
      const castMaxToDate = element.trunc_max === 'date';

      const inclusionDate = `${t('project:consistency-check.inclusion-date')} ${inclusion ? `(${formatDate(false, inclusion.created_at)})` : ''}`;
      const pElements = projectElements.reduce((a, v) => ({ ...a, [v.id]: v }), {});

      const getComplexSetting = (pElementId, dateTypeKey, condition) => {
        const pElement = projectElements.find((pEl) => pEl.id === pElementId);
        if (!pElement) {
          return null;
        }
        const childrenElement = elements[pElement.element];
        const formattedName = ElementUtil.formatProjectElementName(
          pElement, pElements, elements, pages, t,
        );
        const setting = { text: '', value: null, comment: '' };
        setting.text = t(`project:consistency-check.settings.${dateTypeKey}-form-date`);
        setting.value = formattedName;
        if (format === DATE_TIME && childrenElement.format === DATE_TIME) {
          if (condition) {
            setting.comment = t(`project:consistency-check.settings.without-time${inclusion ? '-entry-mode' : ''}`);
          } else {
            setting.comment = t(`project:consistency-check.settings.with-time${inclusion ? '-entry-mode' : ''}`);
          }
        }
        return setting;
      };

      if (specificDate) {
        const setting = { text: '', value: null, comment: '' };
        if (customDate) {
          setting.value = before;
        } else if (formDate) {
          const settingFormDate = getComplexSetting(beforeVar, 'specific', castMinToDate && castMaxToDate);
          if (settingFormDate) {
            Object.assign(setting, settingFormDate);
          }
        } else if (greaterThanIncDate && lessThanIncDate) {
          setting.value = inclusionDate;
        }
        if (!formDate) {
          setting.text = t('project:consistency-check.settings.specific-form-date');
        }
        res.push(setting);
      } else {
        if (after || afterVar || greaterThanIncDate) {
          const setting = { text: '', value: null, comment: '' };
          if (after) {
            setting.value = after;
          } else if (afterVar) {
            const settingFormDate = getComplexSetting(afterVar, 'min', castMinToDate);
            Object.assign(setting, settingFormDate);
          } else if (greaterThanIncDate) {
            setting.value = inclusionDate;
          }
          if (!afterVar) {
            setting.text = t('project:consistency-check.settings.min-form-date');
          }
          res.push(setting);
        }
        if (before || beforeVar || lessThanIncDate) {
          const setting = { text: '', value: null, comment: '' };
          if (before) {
            setting.value = before;
          } else if (beforeVar) {
            const settingFormDate = getComplexSetting(beforeVar, 'max', castMaxToDate);
            Object.assign(setting, settingFormDate);
          } else if (lessThanIncDate) {
            setting.value = inclusionDate;
          }
          if (!beforeVar) {
            setting.text = t('project:consistency-check.settings.max-form-date');
          }
          res.push(setting);
        }
      }
      return res;
    }
    default: return [];
  }
};
