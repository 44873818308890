import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import NewTooltip from './NewTooltip';
import {
  PROJECT_ON_HOLD, PROJECT_IN_PROGRESS, PROJECT_CLOSED,
} from '../constants';


const STATUS_TO_COLOR = {
  [PROJECT_ON_HOLD]: 'text-yellow',
  [PROJECT_IN_PROGRESS]: 'text-green',
  [PROJECT_CLOSED]: 'text-blue-4',
};

function ProjectStatus(props) {
  const {
    className, status, onlyIcon, noTooltip, tooltipTheme, getTooltipContent,
  } = props;

  const { t } = useTranslation();

  return (
    <NewTooltip
      content={getTooltipContent(status)}
      disabled={noTooltip}
      contentClassName="closed-phase-tooltip"
      theme={tooltipTheme}
    >
      <div className={className}>
        <FontAwesomeIcon
          icon="circle"
          className={`small ${STATUS_TO_COLOR[status]}`}
          transform="shrink-2"
        />
        {!onlyIcon && (
          <div className="ml-2">
            {t(`project:statuses.${status}`)}
          </div>
        )}
      </div>
    </NewTooltip>
  );
}

ProjectStatus.propTypes = {
  className: PropTypes.string,
  status: PropTypes.oneOf([PROJECT_ON_HOLD, PROJECT_IN_PROGRESS, PROJECT_CLOSED]).isRequired,
  onlyIcon: PropTypes.bool,
  noTooltip: PropTypes.bool,
  tooltipTheme: PropTypes.oneOf(['dark', 'light']),
  getTooltipContent: PropTypes.func,
};

ProjectStatus.defaultProps = {
  className: 'd-flex align-items-center justify-content-center',
  onlyIcon: false,
  noTooltip: false,
  tooltipTheme: 'dark',
  getTooltipContent: () => '',
};

function ProjectPaused(props) {
  const { className, onlyIcon, tooltipTheme } = props;
  const { t } = useTranslation();

  return (
    <NewTooltip
      content={t('project:project-paused-by-owner')}
      theme={tooltipTheme}
    >
      <span>
        <FontAwesomeIcon
          icon="circle"
          className="small text-newred-2"
          transform="shrink-2"
        />
        {!onlyIcon && (
          <span className={`ml-2 text-red font-weight-semibold ${className}`}>
            {t('project:project-paused')}
          </span>
        )}
      </span>
    </NewTooltip>
  );
}

ProjectPaused.propTypes = {
  className: PropTypes.string,
  onlyIcon: PropTypes.bool,
  tooltipTheme: PropTypes.oneOf(['dark', 'light']),
};

ProjectPaused.defaultProps = {
  className: '',
  onlyIcon: false,
  tooltipTheme: 'dark',
};

function ProjectDeactivated(props) {
  const { onlyIcon } = props;
  const { t } = useTranslation();

  return (
    <NewTooltip
      content={t('project:project-disabled')}
      disabled={!onlyIcon}
    >
      <span>
        <FontAwesomeIcon
          icon={['fas', 'exclamation-triangle']}
          className="warning"
        />
        {!onlyIcon && (
          <span className="ml-2 text-red font-weight-semibold">
            {t('project:project-disabled')}
          </span>
        )}
      </span>
    </NewTooltip>
  );
}

ProjectDeactivated.propTypes = {
  onlyIcon: PropTypes.bool,
};

ProjectDeactivated.defaultProps = {
  onlyIcon: false,
};

export {
  ProjectStatus,
  ProjectPaused,
  ProjectDeactivated,
};
