import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { withToastManager } from 'react-toast-notifications';
import { Cache } from 'axios-extensions';
import moment from 'moment';
import MixedView from './MixedView';
import MobileView from './MobileView';
import { CardLoader } from './Loader';
import { nsOptions } from '../i18n';
import {
  Table, TableHead, TableBody, TableRow, TableCell,
} from './CustomTable';
import FormattedValue from '../utils/FormattedValue';
import LabelStatus from './LabelStatus';

@withToastManager
@withTranslation('', nsOptions)
class NotificationsTable extends React.Component {
  static propTypes = {
    notifications: PropTypes.arrayOf(PropTypes.shape()).isRequired,
    notificationForm: PropTypes.shape(),
    t: PropTypes.func.isRequired,
    onClick: PropTypes.func,
  };

  static defaultProps = {
    notificationForm: null,
    onClick: () => {},
  };

  constructor(props) {
    super(props);
    this.state = {
      loading: false,
    };
    this.cache = new Cache();
  }

  render() {
    const {
      notifications, notificationForm,
      t, onClick,
    } = this.props;
    const { loading } = this.state;
    const notificationsDOM = notifications.map((notification) => (
      <MixedView key={notification.id}>
        <TableRow
          style={{
            height: '67px',
            cursor: 'pointer',
          }}
          onClick={() => onClick(notification)}
        >
          <TableCell
            role="presentation"
          >
            <FormattedValue value={notification.name} />
          </TableCell>
          <TableCell
            role="presentation"
          >
            <FormattedValue value={notification.creator.email} />
          </TableCell>
          <TableCell
            role="presentation"
            className="d-flex justify-content-center"
          >
            <LabelStatus status={notification.status} />
          </TableCell>
          <TableCell
            role="presentation"
          >
            <FormattedValue value={moment(notification.updated_at)} />
          </TableCell>
          <TableCell
            role="presentation"
          >
            <FormattedValue value={notification.recipient_count} />
          </TableCell>
        </TableRow>
      </MixedView>
    ));

    return (
      <div className="contains-loader">
        { loading && <CardLoader /> }
        <MixedView>
          <Table extraClassName={`mt-3 mb-0${!notificationForm ? ' no-pointer' : ''}`}>
            <TableHead>
              <TableRow>
                <TableCell tag="th">
                  {t('project:notification.table.name')}
                </TableCell>
                <TableCell tag="th">
                  {t('project:notification.table.creator')}
                </TableCell>
                <TableCell tag="th">
                  {t('project:notification.table.status')}
                </TableCell>
                <TableCell tag="th">
                  {t('project:notification.table.last-change-date')}
                </TableCell>
                <TableCell tag="th">
                  {t('project:notification.table.recipient')}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {notifications.length > 0 ? notificationsDOM : (
                <TableRow key="no-one" className="no-one">
                  <TableCell
                    colSpan="8"
                  >
                    {t('project:no.notification')}
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </MixedView>
        <MobileView>
          <div className="mt-3 mb-0">
            {notifications.length > 0 ? notificationsDOM : (
              <div>
                <div
                  className="align-middle text-center no-notification"
                >
                  {t('project:no.notification')}
                </div>
              </div>
            )}
          </div>
        </MobileView>
      </div>
    );
  }
}


export default NotificationsTable;
