import React from 'react';
import { childrenPropTypes } from '../utils/generic-prop-types';

const OverMenuBloc = (props) => (
  <div className="overmenu-bloc">
    {props.children}
  </div>
);

OverMenuBloc.propTypes = {
  children: childrenPropTypes().isRequired,
};

export default OverMenuBloc;
