import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { TARGET_TYPE_ELEMENT } from '../constants';
import { nsOptions } from '../i18n';
import ElementBase from './ElementBase';
import ElementLinksInfo from './ElementLinksInfo';

const mapStateToProps = (state, ownProps) => ({
  projectElement: ownProps.projectElementId
    ? state.projectElements[ownProps.projectElementId] : null,
});

@connect(mapStateToProps)
@withTranslation('', nsOptions)
class ElementSpacer extends Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
    parent: PropTypes.shape(),
    elementId: PropTypes.number.isRequired,
    projectElementId: PropTypes.number,
    projectElement: PropTypes.shape(),
    moduleInstanceId: PropTypes.number,
    methods: PropTypes.shape({
      remove: PropTypes.func,
    }).isRequired,
    isEditMode: PropTypes.bool,
    isEntryMode: PropTypes.bool,
    admin: PropTypes.bool,
  };

  static defaultProps = {
    parent: null,
    projectElementId: null,
    projectElement: null,
    moduleInstanceId: null,
    isEditMode: false,
    isEntryMode: false,
    admin: false,
  };

  checkLinks = () => {
    if (this.elBaseRef) this.elBaseRef.checkLinks();
  };

  render() {
    const {
      projectElement, parent, isEntryMode, isEditMode,
    } = this.props;

    return (
      <ElementBase
        {...this.props}
        elementCategory="static"
        noComment
        noLabel
        ref={(ref) => { this.elBaseRef = ref; }}
      >
        <div className="col-auto">
          <span className="legend cursor-default">
            {this.props.isEditMode
              ? `[${this.props.t('common:elements.spacer', 'uppercase')}]`
              : ' '}
          </span>
        </div>
        {projectElement && parent && !isEntryMode && isEditMode && (
          <div className="col-auto">
            <ElementLinksInfo
              {...this.props}
              target={projectElement}
              targetType={TARGET_TYPE_ELEMENT}
            />
          </div>
        )}
      </ElementBase>
    );
  }
}


export default ElementSpacer;
