import memoize from 'memoize-one';
import moment from 'moment';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import React, { Component } from 'react';
import DatePickerInput, { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { enGB, fr } from 'date-fns/locale';
import { nsOptions } from '../i18n';
import { isMobileView } from './MobileView';
import {
  DATE_TIME, DATE_FULL, DATE_MONTH_YEAR, DATE_YEAR,
} from '../constants';

registerLocale('en', enGB);
registerLocale('fr', fr);

export const getDateInputFormat = (t, format) => {
  switch (format) {
    case DATE_TIME:
      return t('common:elements.date-picker.date-time-format');
    case DATE_MONTH_YEAR:
      return t('common:elements.date-picker.date-month-year-format');
    case DATE_YEAR:
      return t('common:elements.date-picker.date-year-format');
    case DATE_FULL:
    default:
      return t('common:elements.date-picker.date-full-format');
  }
};

class CustomInput extends Component {
  onChange = (e) => {
    if (this.checkChars(e.target.value)) {
      this.props.onChange(e);
    }
  };

  checkChars = (str) => {
    const { format } = this.props;
    let allowedChars;

    switch (format) {
      case DATE_TIME:
        allowedChars = '0-9/: ';
        break;

      case DATE_YEAR:
        allowedChars = '0-9';
        break;

      case DATE_MONTH_YEAR:
      case DATE_FULL:
      default:
        allowedChars = '0-9/';
        break;
    }

    const regex = new RegExp(`^[${allowedChars}]*$`);
    return str.match(regex);
  };

  render() {
    const { onChange, format, ...remainingProps } = this.props;
    return (
      <input
        {...remainingProps}
        onChange={this.onChange}
        autoComplete="off"
      />
    );
  }
}

CustomInput.propTypes = {
  onChange: PropTypes.func,
  format: PropTypes.oneOf([
    DATE_TIME,
    DATE_FULL,
    DATE_MONTH_YEAR,
    DATE_YEAR,
  ]),
};

CustomInput.defaultProps = {
  onChange: () => {},
  format: DATE_FULL,
};


@withTranslation('', nsOptions)
class DatePicker extends Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
    tReady: PropTypes.bool,
    i18n: PropTypes.shape().isRequired,
    id: PropTypes.string,
    onChange: PropTypes.func,
    placeholder: PropTypes.string,
    disabled: PropTypes.bool,
    defaultValue: PropTypes.shape(),
    minDate: PropTypes.shape(),
    maxDate: PropTypes.shape(),
    customInputClassName: PropTypes.string,
    format: PropTypes.oneOf([
      DATE_TIME,
      DATE_FULL,
      DATE_MONTH_YEAR,
      DATE_YEAR,
    ]),
  };

  static defaultProps = {
    tReady: false,
    id: null,
    onChange: null,
    placeholder: null,
    disabled: false,
    defaultValue: null,
    minDate: null,
    maxDate: null,
    customInputClassName: null,
    format: DATE_FULL,
  };

  constructor(props) {
    super(props);
    let defaultValue = null;
    if (props.defaultValue) {
      defaultValue = moment(props.defaultValue).toDate();
    }
    this.state = { value: defaultValue };
    const momentToDate = (date) => (date ? date.toDate() : null);
    this.formatMinDate = memoize(momentToDate);
    this.formatMaxDate = memoize(momentToDate);
  }

  onChange = (jsDate) => {
    if (jsDate === null) {
      // Date has been cleared
      this.setState({ value: null });
      this.props.onChange(null);
    } else {
      // A valid or invalid date has been entered
      const {
        minDate, maxDate, onChange, format,
      } = this.props;
      let momentDate;
      if (format === DATE_TIME) {
        momentDate = moment(jsDate);
      } else {
        // Replace default react-datepicker time by midnight utc
        momentDate = moment.utc({
          year: jsDate.getFullYear(),
          month: jsDate.getMonth(),
          date: jsDate.getDate(),
        });
      }
      if (momentDate.isValid()) {
        if (minDate && momentDate.isBefore(minDate)) return;
        if (maxDate && momentDate.isAfter(maxDate)) return;
        this.setState({ value: jsDate });
        if (onChange) onChange(momentDate.format());
      }
    }
  };

  render() {
    const {
      disabled, i18n, id, placeholder, minDate, maxDate, format, t, customInputClassName,
    } = this.props;
    const { value } = this.state;
    const options = {};

    switch (format) {
      case DATE_TIME:
        if (isMobileView()) {
          options.showTimeInput = true;
        } else {
          options.showTimeSelect = true;
        }
        options.timeIntervals = 15;
        options.timeFormat = t('common:elements.time-format');
        options.timeCaption = t('common:elements.time-caption');
        break;

      case DATE_MONTH_YEAR:
        options.showMonthYearPicker = true;
        break;

      case DATE_YEAR:
        options.showYearPicker = true;
        break;

      case DATE_FULL:
      default:
        break;
    }

    options.dateFormat = getDateInputFormat(t, format);

    const customInputProps = {};
    if (customInputClassName) {
      customInputProps.className = customInputClassName;
    }

    return (
      <DatePickerInput
        id={id}
        onChange={this.onChange}
        strictParsing
        selected={value}
        className="react-datepicker-input"
        placeholderText={placeholder}
        disabled={disabled}
        minDate={this.formatMinDate(minDate)}
        maxDate={this.formatMaxDate(maxDate)}
        disabledKeyboardNavigation
        showYearDropdown
        scrollableYearDropdown
        dropdownMode="select" // Better support for very old and future years
        showMonthDropdown
        isClearable={!disabled}
        locale={i18n.language}
        customInput={<CustomInput format={format} {...customInputProps} />}
        ref={(calendar) => {
          this.calendar = calendar;
        }}
        {...options}
      />
    );
  }
}

export default DatePicker;
