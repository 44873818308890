import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { withToastManager } from 'react-toast-notifications';
import { connect } from 'react-redux';
import api from '../api';
import Page from '../components/Page';
import withLicenseMsgModal from '../components/withLicenseMsgModal';
import InvitationCardBase from '../components/InvitationCardBase';
import history from '../history';
import { nsOptions } from '../i18n';
import Toast from '../utils/Toast';
import ErrorUtil from '../utils/ErrorUtil';
import UserUtil from '../utils/UserUtil';
import { USER_TYPE_PRACTITIONER } from '../constants';

const mapStateToProps = (state) => ({
  user: state.auth.authUser,
});

@withToastManager
@connect(mapStateToProps)
@withTranslation('', nsOptions)
@withLicenseMsgModal()
class JoinTeam extends Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
    match: PropTypes.shape().isRequired,
    user: PropTypes.shape().isRequired,
  };

  constructor(props) {
    super(props, props.t('common:nav.join-project'));
    this.state = { invitation: null, errorMessage: '', ready: false };
  }

  componentDidMount() {
    this.fetchInvitation();
  }

  fetchInvitation = async () => {
    const { token } = this.props.match.params;
    try {
      const invitation = await api.read('confirm-team-invitation', token);
      this.setState({ invitation });
    } catch (error) {
      ErrorUtil.handleCatched(this.props, error, false);
      const errorMessage = ErrorUtil.makeApiErrorUserFriendly(this.props, error.message);
      this.setState({ invitation: null, errorMessage });
    } finally {
      this.setState({ ready: true });
    }
  };

  join = async () => {
    try {
      const { user } = this.props;
      const { token } = this.props.match.params;
      await api.create(`confirm-team-invitation/${token}`);
      const { invitation } = this.state;
      let url = `/dashboard/settings/teams/${invitation.team.id}`;
      if (user && invitation
        && user.type === USER_TYPE_PRACTITIONER
        && user.id !== invitation.team.owner.id) {
        url = `/dashboard/settings/teams/joined-teams/${invitation.team.id}`;
      }
      history.push(url);
      Toast.success(this.props, 'error:valid.success');
    } catch (error) {
      ErrorUtil.handleCatched(this.props, error);
    }
  };

  cancel = async () => {
    try {
      const { token } = this.props.match.params;
      await api.create(`dismiss-team-invitation/${token}`);
    } catch (error) {
      ErrorUtil.handleCatched(this.props, error);
    }

    const { user } = this.props;
    const homeLink = UserUtil.getUserHome(user);
    history.push(`/dashboard/${homeLink}`);
  };

  render() {
    const { t } = this.props;
    const { invitation, errorMessage, ready } = this.state;
    const user = invitation ? invitation.creator : null;
    const team = invitation ? invitation.team : null;

    return (
      <Page
        {...this.props}
        className="dashboard-content"
        title={t('common:nav.join-project')}
      >
        <InvitationCardBase
          invitation={invitation}
          errorMessage={errorMessage}
          ready={ready}
          label={user ? user.label : ''}
          join={this.join}
          cancel={this.cancel}
          title="user:team.invitation-card.title"
          subtitle="user:team.invitation-card.subtitle"
          joinLabel="user:team.invitation-card.join-label"
          invalidInvitationMsg="user:team.invitation-card.invalid-invitation-msg"
        >
          {
            team && (
              <div>
                <span className="mr-1 font-weight-semibold">{t('user:team.invitation-card.team-name')}</span>
                <span>{team.name}</span>
              </div>
            )
          }
        </InvitationCardBase>
      </Page>
    );
  }
}

export default JoinTeam;
