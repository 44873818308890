import React from 'react';
import PropTypes from 'prop-types';
import Formatter from './Formatter';


class FormattedValue extends React.Component {
  static propTypes = {
    // eslint-disable-next-line react/forbid-prop-types
    value: PropTypes.any,
    type: PropTypes.string,
    unit: PropTypes.string,
    precision: PropTypes.number,
    isRatio: PropTypes.bool,
    closedLower: PropTypes.bool,
    closedUpper: PropTypes.bool,
    emptyComponent: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  };

  static defaultProps = {
    value: null,
    type: null,
    unit: null,
    precision: 2,
    isRatio: false,
    closedLower: true,
    closedUpper: false,
    emptyComponent: '–',
  };

  constructor(props) {
    super(props);
    this.formatter = new Formatter(
      props.type, props.unit, props.precision, props.isRatio,
      props.closedLower, props.closedUpper, props.emptyComponent,
    );
  }

  render() {
    const { value } = this.props;
    return this.formatter.format(value);
  }
}


export default FormattedValue;
