import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { Component } from 'react';
import { Trans, withTranslation } from 'react-i18next';
import { Progress } from 'reactstrap';
import '../assets/css/inclusion.css';
import memoize from 'memoize-one';
import { withToastManager } from 'react-toast-notifications';
import { connect } from 'react-redux';
import { Mutex } from 'async-mutex';
import { projectEntriesActions, inclusionsActions } from '../redux/actions';
import { getSortingHandlerId } from '../redux/actions/sorting-handler';
import { nsOptions } from '../i18n';
import {
  CAN_INCLUDE, LOCK_MODEL_INCLUSION, LOCK_TOAST_TIMEOUT_S, ORGANIZATION_TYPES,
  ELEMENTS_TO_FILL_TYPES, CAN_EDIT_FORM_AND_DOCUMENTATIONS, TARGET_TYPE_PAGE,
  ELEMENT_DIRECT_IDENTIFIER,
} from '../constants';
import ElementUtil from '../utils/ElementUtil';
import SortUtil from '../utils/SortUtil';
import ErrorUtil from '../utils/ErrorUtil';
import Toast from '../utils/Toast';
import TextUtil from '../utils/TextUtil';
import {
  getMissingDataColors, extractEmptyProjectElements,
  extractEmptyOptProjectElements, extractInconsistentEntries,
  extractQualifiedEmptyEntries,
} from '../utils/inclusion';
import {
  formatPageTitle, isInclusionReadOnly, DataLocker, hasPermission,
} from '../utils/data-util';
import FormExportManager from './FormExportManager';
import { CardLoader } from './Loader';
import NewModal from './NewModal';
import DropdownMenu from './NewDropdownMenu';
import NewTooltip from './NewTooltip';
import { isMobileView } from './MobileView';
import LabeledSelect from './LabeledSelect';
import DataMonitoringTooltip from './DataMonitoringTooltip';
import AuditTrailModal from './AuditTrailModal';
import BrowserView, { isBrowserView } from './BrowserView';
import ButtonConfirm from './ButtonConfirm';
import Help from './Help';
import { DelMsgModal } from './MessageModal';
import FAQLink from './FAQLink';
import { checkChildrenLinks, checkLinks, getRootProjectElements } from '../utils/links';
import fromReduxState from '../utils/redux';

const INDIVIDUAL = 0;
const PROJECT_MAX_LENGTH = 60;
const PROJECT_MAX_LENGTH_MOBILE = 35;

const PageNavigationLink = (props) => {
  const {
    onClick, text, icon, iconClassName, buttonClassName,
  } = props;
  return (
    <button
      className={`btn btn-newturquoise-1 btn-${buttonClassName}`}
      onClick={onClick}
    >
      <FontAwesomeIcon
        icon={icon}
        className={iconClassName}
      />
      {text}
    </button>
  );
};

PageNavigationLink.propTypes = {
  onClick: PropTypes.func.isRequired,
  text: PropTypes.string.isRequired,
  icon: PropTypes.arrayOf(PropTypes.string).isRequired,
  iconClassName: PropTypes.string,
  buttonClassName: PropTypes.string,
};

PageNavigationLink.defaultProps = {
  iconClassName: '',
  buttonClassName: '',
};

const mapStateToProps = (state) => ({
  pages: state.projectPages,
  elementLinks: state.elementLinks,
  projectElements: state.projectElements,
  elements: state.elements,
  projects: state.projects,
  user: state.auth.authUser,
  projectUsers: state.projectUsers,
  teams: state.teams,
  projectEntries: state.projectEntries,
  inclusions: state.inclusions,
});

const mapDispatchToProps = (dispatch) => ({
  fetchProjectEntries: async (pElements, inclusionId) => dispatch(projectEntriesActions.list({
    project_element__in: Object.keys(pElements),
    inclusion: inclusionId,
  }, {
    pagination: 'no',
  })),
  patchInclusion: async (id, data) => dispatch(inclusionsActions.patch(id, data)),
  clearProjectEntries: () => dispatch(projectEntriesActions.removeAllSuccess()),
  deleteInclusion: async (id) => (
    dispatch(inclusionsActions.remove(id))
  ),
});


@withToastManager
@connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })
@withTranslation('', nsOptions)
class InclusionForm extends Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
    pages: PropTypes.shape().isRequired,
    elementLinks: PropTypes.shape().isRequired,
    projectElements: PropTypes.shape().isRequired,
    elements: PropTypes.shape().isRequired,
    projects: PropTypes.shape().isRequired,
    user: PropTypes.shape().isRequired,
    projectUsers: PropTypes.shape().isRequired,
    teams: PropTypes.shape().isRequired,
    fetchProjectEntries: PropTypes.func.isRequired,
    patchInclusion: PropTypes.func.isRequired,
    onClose: PropTypes.func,
    projectEntries: PropTypes.shape().isRequired,
    inclusions: PropTypes.shape().isRequired,
    clearProjectEntries: PropTypes.func.isRequired,
    deleteInclusion: PropTypes.func.isRequired,
    onDelete: PropTypes.func,
    showDelete: PropTypes.bool,
  };

  static defaultProps = {
    onClose: null,
    onDelete: null,
    showDelete: false,
  };

  constructor(props) {
    super(props);
    this.state = {
      inclusionId: null,
      project: null,
      projectUser: null,
      contents: null,
      currentTabId: null,
      loading: false,
      lockedByAnother: false,
      team: INDIVIDUAL,
      ready: false,
      missingDataVisible: false,
      inconsistentDataVisible: false,
      qualifiedMissingDataVisible: false,
      headerVisible: true,
      formListeningToScroll: false,
    };
    this.elementRefs = {};
    this.dropdownRef = undefined;
    this.inclusionLocker = new DataLocker(LOCK_MODEL_INCLUSION);
    this.mutex = new Mutex();
    this.toElLinksArray = fromReduxState((elementLinks) => Object.values(elementLinks));
    this.toPElementsArray = fromReduxState((projectElements) => Object.values(projectElements));
    this.toRootPElsArray = fromReduxState((pEls, elLinks) => (
      getRootProjectElements(pEls, this.toElLinksArray(elLinks))
    ));
    this.toElIdToFillArray = fromReduxState((elements) => (
      Object.values(elements).filter((el) => ELEMENTS_TO_FILL_TYPES.includes(el.type)).map((el) => (
        el.id
      ))
    ));
    this.toPUsersArray = fromReduxState((projectUsers) => Object.values(projectUsers));
    this.toTeamsArray = fromReduxState((teams) => Object.values(teams));
    this.toPEntriesArray = fromReduxState((projectEntries) => Object.values(projectEntries));
    this.memoizedGetProjectUserTeams = memoize(this.getProjectUserTeams);
    this.memoizedGetMissingDataColors = memoize(getMissingDataColors);
    this.memoizedExtractEmptyProjectElements = memoize((
      projectEntries, pages, projectElements, elements, elementLinks, inclusion,
    ) => extractEmptyProjectElements(
      this.toElIdToFillArray(elements), this.toPEntriesArray(projectEntries), pages,
      this.toPElementsArray(projectElements), this.toRootPElsArray(projectElements, elementLinks),
      elements, this.toElLinksArray(elementLinks), inclusion,
    ));
    this.memoizedExtractEmptyOptProjectElements = memoize((
      projectEntries, pages, projectElements, elements, elementLinks, inclusion,
    ) => extractEmptyOptProjectElements(
      this.toElIdToFillArray(elements), this.toPEntriesArray(projectEntries), pages,
      this.toPElementsArray(projectElements), this.toRootPElsArray(projectElements, elementLinks),
      elements, this.toElLinksArray(elementLinks), inclusion,
    ));
    this.memoizedExtractInconsistentEntries = memoize((
      projectEntries, pages, projectElements, elements, elementLinks, inclusion,
    ) => extractInconsistentEntries(
      this.toPEntriesArray(projectEntries), pages, this.toPElementsArray(projectElements),
      this.toRootPElsArray(projectElements, elementLinks), elements,
      this.toElLinksArray(elementLinks), inclusion,
    ));
    this.memoizedExtractQualifiedEmptyEntries = memoize((
      projectEntries, pages, projectElements, elements, elementLinks, inclusion,
    ) => extractQualifiedEmptyEntries(
      this.toPEntriesArray(projectEntries), pages, this.toPElementsArray(projectElements),
      this.toRootPElsArray(projectElements, elementLinks), elements,
      this.toElLinksArray(elementLinks), inclusion,
    ));
    this.memoizedGetCurrentProjectPages = memoize((
      pages, elements, elementLinks, projectElements, projectEntries, inclusionId, project,
    ) => (
      Object.values(pages).filter((page) => (
        page.project === project.id
          && checkLinks(
            elements, this.toElLinksArray(elementLinks), this.toPElementsArray(projectElements),
            this.toRootPElsArray(projectElements, elementLinks),
            this.toPEntriesArray(projectEntries), pages, page, TARGET_TYPE_PAGE, inclusionId, null,
          )
      )).sort(SortUtil.sortArray)
    ));
  }

  componentDidUpdate(prevProps, prevState) {
    if (!isBrowserView()) {
      const form = document.getElementById('inclusion-form-box');
      if (form) {
        this.addFormEventListener(form);
      }
    }
    if (this.state.project) {
      const { currentProjectPages } = this;
      const { inclusionId } = this.state;
      // Do not trigger warning when opening the inclusion form.
      if (this.previousProjectPages && currentProjectPages
        && prevState.inclusionId === inclusionId) {
        const diff = Object.keys(currentProjectPages).length
          - Object.keys(this.previousProjectPages).length;
        if (diff >= 1) {
          const { t } = this.props;
          Toast.warning(this.props, t('inclusion:pages.new-page-triggered',
            { count: diff }), 15000);
        }
      }
      this.previousProjectPages = currentProjectPages;
    }
  }

  get currentProjectPages() {
    const {
      pages, elements, elementLinks, projectElements, projectEntries,
    } = this.props;
    const { inclusionId, project } = this.state;
    return this.memoizedGetCurrentProjectPages(
      pages, elements, elementLinks, projectElements, projectEntries, inclusionId, project,
    );
  }

  addFormEventListener = (form) => {
    const { formListeningToScroll } = this.state;
    if (!formListeningToScroll) {
      form.addEventListener('scroll', this.handleScroll);
      this.setState({ formListeningToScroll: true });
    }
  }

  removeFormEventListener = (form) => {
    const { formListeningToScroll } = this.state;
    if (formListeningToScroll) {
      form.removeEventListener('scroll', this.handleScroll);
      this.setState({ formListeningToScroll: false });
    }
  }

  handleScroll = () => {
    const form = document.getElementById('inclusion-form-box');
    const currentPosition = form.scrollTop;
    const headerVisible = currentPosition === 0;

    this.setState({ headerVisible });
  }

  onClosed = () => {
    const { formListeningToScroll } = this.state;
    this.setState({
      inclusionId: null,
      missingDataVisible: false,
      inconsistentDataVisible: false,
    });
    if (!isBrowserView() && formListeningToScroll) {
      const form = document.getElementById('inclusion-form-box');
      if (form) this.removeFormEventListener(form);
    }
    if (this.inclusionLocker.isLocked()) this.inclusionLocker.unlock();
  }

  onTeamChange = async (e) => {
    const { team, inclusionId } = this.state;
    const { patchInclusion } = this.props;
    const { value } = e.target;
    this.setState({ team: value });
    try {
      await patchInclusion(inclusionId, { team: value });
      Toast.success(this.props, 'error:valid.saved');
    } catch (error) {
      // Undo change
      this.setState({ team });
      ErrorUtil.handleCatched(this.props, error);
    }
  }

  getCurrentTab = () => {
    const { currentTabId } = this.state;
    return this.currentProjectPages.find((page) => page.id === currentTabId);
  };

  getProjectUserTeams = (projectUser, inclusion) => {
    const { teams: tms } = this.props;
    const teams = this.toTeamsArray(tms);
    if (projectUser) {
      if (inclusion.is_test) {
        return teams.filter((team) => (
          (hasPermission(projectUser, CAN_INCLUDE, team.id)
            || hasPermission(projectUser, CAN_EDIT_FORM_AND_DOCUMENTATIONS, team.id))
            && projectUser.teams.includes(team.id)
        ));
      }
      return teams.filter((team) => (
        hasPermission(projectUser, CAN_INCLUDE, team.id)
          && projectUser.teams.includes(team.id)
      ));
    }
    return [];
  };

  hideModal = () => {
    if (this.modal) {
      this.modal.hide();
    }
  }

  showModal = () => {
    if (this.modal) {
      this.modal.show();
    }
  };

  isReadOnly = () => {
    const {
      projectUsers: pUsers, projects, inclusions,
    } = this.props;
    const { inclusionId, lockedByAnother, projectUser } = this.state;
    const inclusion = inclusions[inclusionId];
    const projectUsers = this.toPUsersArray(pUsers);
    return lockedByAnother
      || isInclusionReadOnly(inclusion, projectUser, projectUsers, projects[inclusion.project]);
  };

  init = async (inclusionId, loadProjectEntries = true) => {
    if (this.mutex.isLocked()) return;

    const release = await this.mutex.acquire();

    this.hideModal();

    const {
      t, fetchProjectEntries, projectElements, projects, pages, projectUsers: pUsers,
      clearProjectEntries, inclusions, user, elements, elementLinks,
    } = this.props;
    const inclusion = inclusions[inclusionId];
    const project = projects[inclusion.project];
    const projectUsers = this.toPUsersArray(pUsers);
    const projectUser = projectUsers.find((pUser) => (
      pUser.user.id === user.id && pUser.project === project.id
    ));
    let lockedByAnother = true;

    try {
      if (loadProjectEntries) {
        await fetchProjectEntries(projectElements, inclusionId);
      } else {
        clearProjectEntries();
      }
      if (!isInclusionReadOnly(inclusion, projectUser, projectUsers, projects[inclusion.project])) {
        const res = await this.inclusionLocker.tryLock(inclusionId);
        lockedByAnother = !res.success;
        if (lockedByAnother) {
          Toast.warning(this.props, DataLocker.lockUserMessage(res, t), LOCK_TOAST_TIMEOUT_S);
        }
      } else {
        lockedByAnother = false;
      }
    } catch (error) {
      ErrorUtil.handleCatched(this.props, error, false);
    } finally {
      release();
    }
    const { projectEntries } = this.props;
    this.setState({
      inclusionId,
      project,
      projectUser,
      contents: null,
      currentTabId: this.memoizedGetCurrentProjectPages(
        pages, elements, elementLinks, projectElements, projectEntries, inclusionId, project,
      )[0].id,
      lockedByAnother,
      team: inclusion.team || INDIVIDUAL,
      ready: true,
    }, this.showModal);
  };

  load = async (loading = true) => {
    if (loading) this.setState({ loading: true });
    const {
      projectElements: pEls, elements, inclusions, elementLinks,
    } = this.props;
    const { inclusionId, project } = this.state;
    const projectElements = this.toPElementsArray(pEls);
    const rootProjectElements = this.toRootPElsArray(pEls, elementLinks);
    const inclusion = inclusions[inclusionId];
    const isReadOnly = this.isReadOnly();
    const tab = this.getCurrentTab();
    const getElement = (Type, pElement, element) => {
      const { id } = pElement;
      return Type ? (
        <Type
          {...this.props}
          projectELements={projectElements}
          rootProjectElements={rootProjectElements}
          key={id}
          isEntryMode
          isEditMode={false}
          isReadOnly={isReadOnly}
          isAnonymized={inclusion.is_anonymized
              && element.identification_level === ELEMENT_DIRECT_IDENTIFIER}
          elementId={pElement.element}
          projectElementId={pElement.id}
          inclusionId={inclusion ? inclusion.id : null}
          moduleInstanceId={null}
          parent={project}
          project={project}
          elementSortingHandlerId={getSortingHandlerId('element', id)}
          methods={{
            reload: () => this.load(false),
            checkLinks: () => checkChildrenLinks(
              this.toElLinksArray(elementLinks), id, this.elementRefs, rootProjectElements,
            ),
          }}
          tabId={tab.id}
          ref={(ref) => {
            this.elementRefs[id] = ref;
          }}
        />
      ) : null;
    };
    const contents = projectElements.sort(SortUtil.sortArray)
      .filter((pEl) => pEl.project_page === tab.id && pEl.module === null).map((pEl) => {
        const element = elements[pEl.element];
        const Type = ElementUtil.getElementType(element);
        return getElement(Type, pEl, element);
      });
    await new Promise((resolve) => this.setState({
      contents,
      loading: false,
    }, () => resolve()));
  };

  readOnlyTooltip = (isOrganization, lockedByAnother) => {
    const { t } = this.props;
    if (isOrganization) {
      return (
        <div>
          {t('error:warning.inclusion-read-only-organization')}
        </div>
      );
    }
    if (lockedByAnother) {
      return (
        <>
          <div>
            {t('error:warning.inclusion-already-locked-info-1')}
          </div>
          <div>
            {t('error:warning.inclusion-already-locked-info-2')}
          </div>
        </>
      );
    }
    return (
      <div>
        {t('error:warning.inclusion-read-only')}
      </div>
    );
  };

  getMonitoringDataInfo = () => {
    const res = {
      emptyProjectElements: {},
      emptyOptProjectElements: {},
      qualifiedEmptyProjectElements: {},
      inconsistentProjectElements: {},
      displayOptPrjElements: false,
    };

    if (this.state.ready) {
      const {
        projectEntries, projectElements, elements, elementLinks, inclusions, pages,
      } = this.props;
      const { inclusionId } = this.state;
      const inclusion = inclusions[inclusionId];

      // Required missing data
      res.emptyProjectElements = this.memoizedExtractEmptyProjectElements(
        projectEntries, pages, projectElements, elements, elementLinks, inclusion,
      );

      // Optional missing data
      const optProjectElements = this.toPElementsArray(projectElements).filter((pElement) => (
        !pElement.required && this.toElIdToFillArray(elements).includes(pElement.element)
      ));
      res.displayOptPrjElements = Boolean(optProjectElements.length);

      res.emptyOptProjectElements = this.memoizedExtractEmptyOptProjectElements(
        projectEntries, pages, projectElements, elements, elementLinks, inclusion,
      );

      // Qualified missing data
      res.qualifiedEmptyProjectElements = this.memoizedExtractQualifiedEmptyEntries(
        projectEntries, pages, projectElements, elements, elementLinks, inclusion,
      );

      // Inconsistent data
      res.inconsistentProjectElements = this.memoizedExtractInconsistentEntries(
        projectEntries, pages, projectElements, elements, elementLinks, inclusion,
      );
    }
    return res;
  }

  scrollToElement = async (
    tooltip = null, prjElementId = null, moduleId = null, entry = null, highlight = true,
    isInconsistentData = false, isQualifiedMissingData = false,
  ) => {
    if (moduleId && entry) {
      if (isMobileView()) this.hideMonitoringDataTooltip(tooltip);
      this.blockStatusDataTooltip(tooltip);
      const moduleInstance = this.elementRefs[moduleId].instanceRefs[entry];
      await moduleInstance.show();
      await moduleInstance.load();
      if (prjElementId) {
        const domElement = document.getElementById(`element-base-${prjElementId}`);
        domElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
        const elementRef = this.elementRefs[moduleId].instanceRefs[entry].elementRefs[prjElementId];
        this.highlightElement(elementRef, isInconsistentData, isQualifiedMissingData);
      }
      this.unblockStatusDataTooltip(tooltip);
    } else if (prjElementId) {
      const domElement = document.getElementById(`element-base-${prjElementId}`);
      domElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
      if (highlight) {
        if (isMobileView()) this.hideMonitoringDataTooltip(tooltip);
        const elementRef = this.elementRefs[prjElementId];
        this.highlightElement(elementRef, isInconsistentData, isQualifiedMissingData);
      }
    }
  }

  highlightElement = (elementRef, isInconsistentData = false, isQualifiedMissingData = false) => {
    if (elementRef.ref) {
      elementRef.ref.elBaseRef.highlightElement(isInconsistentData, isQualifiedMissingData);
    } else {
      elementRef.elBaseRef.highlightElement(isInconsistentData, isQualifiedMissingData);
    }
  }

  toggleMissingDataClass = (elementRef) => {
    if (elementRef.ref) {
      elementRef.ref.elBaseRef.toggleMissingDataClass();
    } else {
      elementRef.elBaseRef.toggleMissingDataClass();
    }
  }

  blockStatusDataTooltip = (tooltip) => {
    if (tooltip) tooltip.block();
  }

  unblockStatusDataTooltip = (tooltip) => {
    if (tooltip) tooltip.unblock();
  }

  hideMonitoringDataTooltip = (tooltip) => {
    if (tooltip === this.missingDataTooltip) {
      this.setState({ missingDataVisible: false });
    } else if (tooltip === this.inconsistentDataTooltip) {
      this.setState({ inconsistentDataVisible: false });
    } else if (tooltip === this.qualifiedMissingDataTooltip) {
      this.setState({ qualifiedMissingDataVisible: false });
    }
  }

  handleTooltipDataClick = async (
    tooltip = null, pageId, prjElementId = null, moduleId = null, entry = null,
    emptyProjectElements, isInconsistentData = false, isQualifiedMissingData = false,
  ) => {
    if (pageId !== this.state.currentTabId) {
      await this.setState(
        {
          currentTabId: pageId,
          loading: true,
        },
        async () => {
          await this.load();
          this.container.scrollIntoView();
          this.scrollToElement(
            tooltip, prjElementId, moduleId, entry, true, isInconsistentData,
            isQualifiedMissingData,
          );
        },
      );
    } else {
      this.scrollToElement(
        tooltip, prjElementId, moduleId, entry, true, isInconsistentData,
        isQualifiedMissingData,
      );
    }
  }

  onPageNavClick = async (tabId) => {
    await this.setState(
      {
        currentTabId: tabId,
        loading: true,
      },
      async () => {
        await this.load();
        this.container.scrollIntoView();
      },
    );
  }

  renderPageNavigation = () => {
    const { t } = this.props;
    const { currentTabId } = this.state;
    const { currentProjectPages } = this;
    const currentSorting = currentProjectPages.find(
      (projectPage) => projectPage.id === currentTabId,
    ).sorting;

    const previousPage = currentProjectPages.filter((pg) => (
      pg.sorting < currentSorting
    )).reverse()[0];
    const nextPage = currentProjectPages.filter((pg) => pg.sorting > currentSorting)[0];

    const mobileView = isMobileView();

    return previousPage || nextPage ? (
      <div className="row justify-content-center inclusion-page-nav">
        {previousPage && (
          <div className={`col-auto ${mobileView ? 'mb-1' : ''}`}>
            <PageNavigationLink
              onClick={() => this.onPageNavClick(previousPage.id)}
              text={t('inclusion:page-navigation.previous-page')}
              icon={['far', 'chevron-left']}
              iconClassName="mr-2"
              buttonClassName="previous-page"
            />
          </div>
        )}
        {nextPage && (
          <div className={`col-auto ${mobileView ? 'mt-1' : ''}`}>
            <PageNavigationLink
              onClick={() => this.onPageNavClick(nextPage.id)}
              text={t('inclusion:page-navigation.next-page')}
              icon={['far', 'chevron-right']}
              iconClassName="ml-2"
              buttonClassName="next-page"
            />
          </div>
        )}
      </div>
    ) : null;
  }

  renderTestWarning = () => (
    <div className="bg-newyellow-1 text-center px-3 py-2">
      <Trans i18nKey="error:warning.test-mode" />
    </div>
  );

  deleteInclusion = async () => {
    const { deleteInclusion, t, onDelete } = this.props;
    const { inclusionId } = this.state;
    try {
      await deleteInclusion(inclusionId);
      await onDelete();
      Toast.success(this.props, t('project:deletion-inclusion-success'), 4000);
    } catch (error) {
      ErrorUtil.handleCatched(this.props, error);
    }
  };

  render() {
    const {
      t, user, projectUsers: pUsers, teams: tms, projectElements: pEls, elements, projectEntries:
      pEntries, inclusions, showDelete,
    } = this.props;
    const {
      inclusionId, lockedByAnother, project, currentTabId, team, missingDataVisible,
      inconsistentDataVisible, projectUser, headerVisible, qualifiedMissingDataVisible,
    } = this.state;
    const teams = this.toTeamsArray(tms);
    const projectElements = this.toPElementsArray(pEls);
    const projectEntries = this.toPEntriesArray(pEntries);
    const projectUsers = this.toPUsersArray(pUsers);
    const inclusion = inclusions[inclusionId];

    if (!inclusion) return null;

    const tabs = this.currentProjectPages.map((tab) => ({
      key: tab.id,
      value: formatPageTitle(tab, t),
    }));
    const filteredTeams = this.isReadOnly() ? teams : this.memoizedGetProjectUserTeams(
      projectUser, inclusion,
    );
    const isOrganization = projectUser && projectUser.user && projectUser.user.type
      && (ORGANIZATION_TYPES.includes(projectUser.user.type));

    /* -- Missing data -- */
    const inclusionProgression = inclusion.progression;
    const {
      emptyProjectElements, emptyOptProjectElements, qualifiedEmptyProjectElements,
      inconsistentProjectElements, displayOptPrjElements,
    } = this.getMonitoringDataInfo();

    const { progressBarColor, tooltipColor } = this.memoizedGetMissingDataColors(
      inclusionProgression,
    );

    const options = {
      placement: 'bottom',
    };
    if (isMobileView()) {
      options.trigger = 'click';
    } else {
      options.trigger = 'mouseenter focus';
    }

    const showInconsistentData = inclusion ? Boolean(inclusion.inconsistent_data_count) : false;
    const showQualifiedMissingData = inclusion ? Boolean(inclusion.qualified_missing_data_count)
      : false;

    const showAuditTrail = inclusion && !inclusion.is_test;

    const selectTeamDisabled = this.isReadOnly() || inclusion.creator !== user.id;
    const mobileView = isMobileView();

    const projectNameMaxLength = mobileView ? PROJECT_MAX_LENGTH_MOBILE : PROJECT_MAX_LENGTH;

    const showCreator = user.id !== inclusion.creator;
    const showParticipation = Boolean(team) && filteredTeams.length >= 2;

    const inclusionReadOnly = inclusion ? isInclusionReadOnly(
      inclusion, projectUser, projectUsers, project,
    ) : true;

    const creatorProjectUser = projectUsers && projectUsers.find((pUser) => (
      pUser.user.id === inclusion.creator
    ));

    return (
      <NewModal
        trigger={(<span />)}
        size="xl"
        type={1}
        onLoad={this.load}
        extraClass="inclusion-modal"
        onClosed={this.onClosed}
        ref={(modal) => {
          this.modal = modal;
        }}
      >
        {
          project ? (
            <div className={`entry-form ${inclusion.is_test ? 'test-mode' : ''}`}>
              <div className="inclusion-form-header">
                <div className="row justify-content-between">
                  <div className="col col-md-7 col-lg-8 col-xl-9">
                    <div className={`header-hidable ${headerVisible ? '' : 'header-hidable--hidden'}`}>
                      <div className="d-inline-flex">
                        <h3
                          className={`${mobileView ? 'inclusion-title-mobile' : 'inclusion-title mb-0'} ${!(showCreator || showParticipation || showInconsistentData) ? 'inclusion-title-marge-bottom' : ''}`}
                        >
                          {inclusion.is_test ? (
                            <>
                              {t('project:inclusions.inclusion-test')}
                            </>
                          ) : (
                            <>
                              {t('project:inclusions.inclusion-id', { id: inclusion.per_project_id })}
                            </>
                          )}
                          <NewTooltip
                            content={project.name}
                            disabled={project.name.length <= projectNameMaxLength}
                            contentClassName="text-break"
                          >
                            <span
                              className="text-gray"
                            >
                              &nbsp;-&nbsp;
                              {TextUtil.truncate(
                                project.name,
                                projectNameMaxLength,
                              )}
                            </span>
                          </NewTooltip>
                          <DropdownMenu
                            mainClass="d-inline-block align-middle actions-menu"
                            type={isMobileView() ? 'dropleft' : 'dropright'}
                            triggerElement={(
                              <NewTooltip
                                content={t('inclusion:actions')}
                                disabled={isMobileView()}
                              >
                                <span className="px-3 cursor-pointer">
                                  <FontAwesomeIcon
                                    icon={['fal', 'ellipsis-h']}
                                    className="text-gray-dark"
                                    transform="grow-5"
                                  />
                                </span>
                              </NewTooltip>
                            )}
                            ref={(ref) => { this.dropdownRef = ref; }}
                            onClick={() => {
                              if (this.dropdownRef.current
                                && this.dropdownRef.current.state.opened) {
                                this.dropdownRef.current.hide();
                              }
                            }}
                          >
                            {!inclusionReadOnly && (
                              <BrowserView>
                                <div>
                                  <FormExportManager
                                    project={project}
                                    inclusion={inclusion}
                                    pageId={currentTabId}
                                  >
                                    <button
                                      type="button"
                                      className="no-button-style dropdown-item"
                                    >
                                      <FontAwesomeIcon
                                        icon={['fal', 'file-export']}
                                        className="mr-2"
                                        transform="grow-2"
                                      />
                                      {t('inclusion:entry.export-entries')}
                                    </button>
                                  </FormExportManager>
                                </div>
                              </BrowserView>
                            )}
                            {showAuditTrail && (
                              <AuditTrailModal
                                title={`${t('project:audit-trail.inclusion-id', { id: inclusion.per_project_id })} - ${t('project:audit-trail.title')}`}
                                showModifications
                                showVariable
                                showModule
                                showEntry
                                project={project}
                                inclusion={inclusion}
                              >
                                <button
                                  type="button"
                                  className="no-button-style dropdown-item"
                                >
                                  <FontAwesomeIcon
                                    icon={['fal', 'history']}
                                    className="mr-2"
                                    transform="grow-2"
                                  />
                                  {t('project:audit-trail.view-inclusion-audit-trail')}
                                </button>
                              </AuditTrailModal>
                            )}
                            {showDelete && !inclusionReadOnly && (
                              <div>
                                <DelMsgModal
                                  message={(
                                    <span>
                                      <b>
                                        {t('common:caution')}
                                        &nbsp;!
                                      </b>
                                      <br />
                                      <br />
                                      {t('project:deletion-inclusion-warning')}
                                    </span>
                                  )}
                                  onValidate={this.deleteInclusion}
                                >
                                  <ButtonConfirm
                                    tooltipContent={t('project:button.delete-inclusion')}
                                  >
                                    <button
                                      className="no-button-style dropdown-item"
                                    >
                                      <span>
                                        <FontAwesomeIcon
                                          icon={['fal', 'trash-alt']}
                                          className="mr-2"
                                          transform="grow-2"
                                        />
                                        {t('project:button.delete-inclusion')}
                                      </span>
                                    </button>
                                  </ButtonConfirm>
                                </DelMsgModal>
                              </div>
                            )}
                          </DropdownMenu>
                        </h3>
                      </div>
                      {(showCreator || showParticipation) && (
                        <div className="container mx-0">
                          <div className={`row align-items-center inclusion-subtitle${mobileView ? '-mobile' : ''}`}>
                            {showCreator && (
                              <div className="col-auto px-0">
                                <span>
                                  <i>
                                    {
                                      `${t('project:inclusions.by')} ${creatorProjectUser ? creatorProjectUser.user.label : (inclusion.creator_name || inclusion.creator_email || '')}`
                                    }
                                  </i>
                                  &nbsp;
                                </span>
                              </div>
                            )}
                            {showParticipation && (
                              <div className="col-auto px-0">
                                {selectTeamDisabled ? (
                                  <>
                                    {showCreator && !mobileView && (
                                      <>
                                        –&nbsp;
                                      </>
                                    )}
                                    {t('project:inclusions.team')}
                                    {t('common:colon')}
                                    &nbsp;
                                    {filteredTeams.find((tm) => tm.id === team).name}
                                  </>
                                ) : (
                                  <LabeledSelect
                                    className="col-12 mb-0"
                                    rowClassName={`row align-items-center inclusion-participation${mobileView ? '-mobile' : ''}`}
                                    colLabelClassName={`col-auto px-0 ${mobileView ? 'pt-1' : ''}`}
                                    colSelectClassName="col-auto pl-1"
                                    labelClassName="mb-0"
                                    name="team"
                                    label={`${showCreator ? '– ' : ''}${t('project:inclusions.team')}${t('common:colon')}`}
                                    value={team}
                                    disabled={selectTeamDisabled}
                                    hideOptionalLabel
                                    onChange={this.onTeamChange}
                                    selectClassName={`custom-select pb-1 ${selectTeamDisabled ? 'border-bottom-transparent' : ''}`}
                                  >
                                    {
                                      filteredTeams.map((tm) => (
                                        <option
                                          key={tm.id}
                                          value={tm.id}
                                        >
                                          { tm.name }
                                        </option>
                                      ))
                                    }
                                  </LabeledSelect>
                                )}
                              </div>
                            )}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className={`col-12 col-md-5 col-lg-4 col-xl-3 inclusion-data-monitoring${mobileView ? '-mobile' : ''}`}>
                    <div className="d-flex align-items-center section-title">
                      <div className="small font-italic text-gray">
                        {t('inclusion:monitor-your-data')}
                      </div>
                      <Help
                        iconClassName="ml-1"
                        iconColor="text-gray"
                        transform="shrink-2"
                        tooltipTheme="dark"
                      >
                        {t(`inclusion:monitor-your-data-tooltip${isMobileView() ? '-mobile' : ''}`)}
                      </Help>
                    </div>
                    <div className="container p-0">
                      <NewTooltip
                        containerClassName={tooltipColor}
                        theme="data-status"
                        content={
                          this.state.ready && (
                            <>
                              {
                                mobileView && (
                                  <div className="close-button-box data-monitoring-tooltip">
                                    <button
                                      className="close-button-sm btn btn-link text-primary"
                                      onClick={() => this.hideMonitoringDataTooltip(
                                        this.missingDataTooltip,
                                      )}
                                    >
                                      &times;
                                    </button>
                                  </div>
                                )
                              }
                              <DataMonitoringTooltip
                                projectElements={projectElements}
                                elements={Object.values(elements)}
                                formattedResult={emptyProjectElements}
                                formattedOptionalResult={emptyOptProjectElements}
                                projectEntries={projectEntries}
                                totalPrjElements={inclusion.missing_data_count}
                                displayOptionalResult={displayOptPrjElements}
                                title={t('inclusion:missing-data.tooltip-title',
                                  { count: inclusion.missing_data_count })}
                                footer={(
                                  <div
                                    className="text-center"
                                  >
                                    <FAQLink
                                      text={t('inclusion:missing-data.link-to-help-center')}
                                      articleId={12}
                                    />
                                  </div>
                                )}
                                handleClick={(pageId, prjElementId, moduleId, entry) => (
                                  this.handleTooltipDataClick(
                                    this.missingDataTooltip, pageId, prjElementId, moduleId,
                                    entry, emptyProjectElements,
                                  ))}
                                nothingToDisplayMessage={t('inclusion:missing-data.no-missing-data')}
                                mainTabName={t('inclusion:missing-data.required-variables-tab')}
                                secondTabName={t('inclusion:missing-data.optional-variables-tab')}
                                secondTabNote={t('inclusion:missing-data.optional-variables-note')}
                              />
                            </>
                          )
                        }
                        interactive
                        arrow
                        ref={(ref) => { this.missingDataTooltip = ref; }}
                        onClick={() => {
                          if (isMobileView()) {
                            this.setState({ missingDataVisible: !missingDataVisible });
                          }
                        }}
                        visible={isMobileView() ? missingDataVisible : undefined}
                        {...options}
                      >
                        <Progress
                          value={inclusionProgression || 10}
                          style={
                            {
                              backgroundColor: inclusionProgression ? progressBarColor : 'transparent',
                              color: `${inclusionProgression ? '' : 'var(--dark)'}`,
                            }
                          }
                        >
                          {inclusionProgression}
                          %
                        </Progress>
                      </NewTooltip>
                    </div>
                    {(showInconsistentData || showQualifiedMissingData) && (
                      <div className="container">
                        <div className="row mt-1">
                          {
                            showInconsistentData && (
                              <div
                                className={`px-0 ${showQualifiedMissingData ? 'col-auto' : 'col-12'} text-truncate`}
                              >
                                <NewTooltip
                                  containerClassName="data-status-red"
                                  theme="data-status"
                                  content={
                                    this.state.ready && (
                                      <>
                                        {
                                          isMobileView() && (
                                            <div className="close-button-box data-monitoring-tooltip">
                                              <button
                                                className="close-button-sm btn btn-link text-primary"
                                                onClick={() => this.hideMonitoringDataTooltip(
                                                  this.inconsistentDataTooltip,
                                                )}
                                              >
                                                &times;
                                              </button>
                                            </div>
                                          )
                                        }
                                        <DataMonitoringTooltip
                                          projectElements={projectElements}
                                          elements={Object.values(elements)}
                                          formattedResult={inconsistentProjectElements}
                                          projectEntries={projectEntries}
                                          totalPrjElements={inclusion.inconsistent_data_count}
                                          title={t('inclusion:inconsistent-data.x-invalid-data',
                                            { count: inclusion.inconsistent_data_count })}
                                          handleClick={(pageId, prjElementId, moduleId, entry) => (
                                            this.handleTooltipDataClick(
                                              this.inconsistentDataTooltip, pageId, prjElementId,
                                              moduleId, entry, emptyProjectElements, true,
                                            ))}
                                          nothingToDisplayMessage={t('inclusion:inconsistent-data.no-inconsistent-data')}
                                          footer={(
                                            <div className="text-center">
                                              <FAQLink
                                                text="inclusion:inconsistent-data.link-to-help-center"
                                                articleId={11}
                                              />
                                            </div>
                                          )}
                                        />
                                      </>
                                    )
                                  }
                                  arrow
                                  interactive
                                  ref={(ref) => { this.inconsistentDataTooltip = ref; }}
                                  visible={isMobileView() ? inconsistentDataVisible : undefined}
                                  {...options}
                                >
                                  <a
                                    role="button"
                                    tabIndex="-1"
                                    onClick={() => {
                                      if (isMobileView()) {
                                        this.setState({
                                          inconsistentDataVisible: !inconsistentDataVisible,
                                        });
                                      }
                                    }}
                                    onKeyPress={() => {}}
                                    className={`text-red dotted-underline-red invalid-data${mobileView ? '-mobile' : ''}`}
                                  >
                                    {t('inclusion:inconsistent-data.x-invalid-data',
                                      { count: inclusion.inconsistent_data_count })}
                                  </a>
                                </NewTooltip>
                              </div>
                            )
                          }
                          {(showQualifiedMissingData && showInconsistentData) && (
                            <div className="px-4 col-auto text-gray">
                              /
                            </div>
                          )}
                          {
                            showQualifiedMissingData && (
                              <div
                                className={`px-0 ${showInconsistentData ? 'col-auto' : 'col-12'} text-truncate`}
                              >
                                <NewTooltip
                                  containerClassName="data-status-blue"
                                  theme="data-status"
                                  content={
                                    this.state.ready && (
                                      <>
                                        {
                                          isMobileView() && (
                                            <div className="close-button-box data-monitoring-tooltip">
                                              <button
                                                className="close-button-sm btn btn-link text-primary"
                                                onClick={() => this.hideMonitoringDataTooltip(
                                                  this.qualifiedMissingDataTooltip,
                                                )}
                                              >
                                                &times;
                                              </button>
                                            </div>
                                          )
                                        }
                                        <DataMonitoringTooltip
                                          projectElements={projectElements}
                                          elements={Object.values(elements)}
                                          formattedResult={qualifiedEmptyProjectElements}
                                          projectEntries={projectEntries}
                                          totalPrjElements={inclusion.qualified_missing_data_count}
                                          title={t('inclusion:qualified-missing-data.x-data',
                                            { count: inclusion.qualified_missing_data_count })}
                                          handleClick={(pageId, prjElementId, moduleId, entry) => (
                                            this.handleTooltipDataClick(
                                              this.qualifiedMissingDataTooltip, pageId,
                                              prjElementId, moduleId, entry,
                                              emptyProjectElements, false, true,
                                            ))}
                                          nothingToDisplayMessage={t('inclusion:qualified-missing-data.no-data')}
                                          extraInfo="missing"
                                          footer={(
                                            <div className="text-center">
                                              <FAQLink
                                                text="inclusion:qualified-missing-data.link-to-help-center"
                                                articleId={12}
                                              />
                                            </div>
                                          )}
                                        />
                                      </>
                                    )
                                  }
                                  arrow
                                  interactive
                                  ref={(ref) => { this.qualifiedMissingDataTooltip = ref; }}
                                  visible={isMobileView() ? qualifiedMissingDataVisible : undefined}
                                  {...options}
                                >
                                  <a
                                    role="button"
                                    tabIndex="-1"
                                    onClick={() => {
                                      if (isMobileView()) {
                                        this.setState({
                                          qualifiedMissingDataVisible: !qualifiedMissingDataVisible,
                                        });
                                      }
                                    }}
                                    onKeyPress={() => {}}
                                    className={`text-newblue-1 dotted-underline-newblue-1 qualified-missing-data${mobileView ? '-mobile' : ''}`}
                                  >
                                    {t('inclusion:qualified-missing-data.x-data',
                                      { count: inclusion.qualified_missing_data_count })}
                                  </a>
                                </NewTooltip>
                              </div>
                            )
                          }
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                <div className="inclusion-page-title-container">
                  <span className={`inclusion-page-title${mobileView ? '-mobile' : ''}`}>
                    {formatPageTitle(this.getCurrentTab(), t)}
                  </span>
                  <DropdownMenu
                    type={isMobileView() ? 'dropleft' : 'dropdown'}
                    mainClass="d-inline-block inclusion-page-menu"
                    triggerElement={(
                      <NewTooltip
                        content={t('common:form.show-pages')}
                      >
                        <span className={`${tabs.length < 2 ? 'd-none' : ''}`}>
                          <FontAwesomeIcon
                            icon={['far', 'angle-down']}
                            className="ml-3 cursor-pointer"
                            transform="grow-8"
                          />
                        </span>
                      </NewTooltip>
                    )}
                    ref={(ref) => { this.dropDownMenuRef = ref; }}
                  >
                    {
                      tabs.map((tab, index) => (
                        <NewTooltip
                          content={tab.value}
                          key={tab.key}
                        >
                          <a
                            className={`project-page-link dropdown-item ${tab.key === this.state.currentTabId ? ' current-page' : ''}`}
                            role="link"
                            tabIndex={index === 0 ? 0 : -1}
                            onClick={async () => {
                              this.dropDownMenuRef.hide();
                              await this.setState(
                                {
                                  currentTabId: tab.key,
                                  loading: true,
                                },
                                async () => {
                                  await this.load();
                                  this.container.scrollIntoView();
                                },
                              );
                            }}
                            onKeyPress={() => {}}
                          >
                            {tab.value}
                          </a>
                        </NewTooltip>
                      ))
                    }
                  </DropdownMenu>
                  {
                    this.isReadOnly() && (
                      <NewTooltip
                        content={this.readOnlyTooltip(isOrganization, lockedByAnother)}
                      >
                        <FontAwesomeIcon
                          icon={['fas', 'lock-alt']}
                          className="ml-3 mb-1"
                          transform="grow-1"
                        />
                      </NewTooltip>
                    )
                  }
                </div>
              </div>
              {inclusion.is_test && this.renderTestWarning()}
              <div
                id="inclusion-form-box"
                className={`inclusion-form-box${this.isReadOnly() ? ' read-only-bg' : ''}`}
              >
                <div className="inclusion-form-container">
                  <div className="inclusion-form entry-context">
                    <div className="position-relative inclusion-form-position" ref={(ref) => { this.container = ref; }}>
                      {this.state.contents}
                      {
                        this.state.loading ? (
                          <CardLoader />
                        ) : null
                      }
                    </div>
                    <div>
                      {this.renderPageNavigation()}
                    </div>
                  </div>
                </div>
              </div>
              <div className="close-button-box">
                <button
                  className="close-button btn btn-link text-primary"
                  onClick={this.hideModal}
                >
                  &times;
                </button>
              </div>
            </div>
          ) : (
            <CardLoader />
          )
        }
      </NewModal>
    );
  }
}


export default InclusionForm;
