import api from '../api';

export const NO_BOM = '';
export const UTF8_BOM = new Uint8Array([0xEF, 0xBB, 0xBF]);

export default async (endpoint, method, params, data, bom = NO_BOM, responseType = '') => {
  const otherOptions = {};
  if (responseType) {
    otherOptions.responseType = responseType;
  }
  const response = await api.request(
    endpoint, null, method, params, data, { useCache: false }, otherOptions,
  );
  const link = document.createElement('a');
  link.href = window.URL.createObjectURL(new Blob(
    [
      ...(bom === NO_BOM ? [] : [UTF8_BOM]), // Add explicit UTF8 marker for applications that do
      // not expect UTF8 by default (Excel for instance)
      response.data,
    ],
    { type: response.headers['content-type'] },
  ));
  const filename = (
    response.headers['content-disposition']
      .match(/filename="(.+)"; attachment/)[1]
  );
  link.setAttribute('download', filename);
  link.style.display = 'hidden';
  document.body.appendChild(link);
  link.click();
  link.remove();
};
