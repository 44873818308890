import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { isMobile } from 'react-device-detect';
import { withToastManager } from 'react-toast-notifications';
import { Trans } from 'react-i18next';
import { connect } from 'react-redux';
import { projectEntriesActions } from '../redux/actions';
import ElementBase, { retrieveProjectEntry, getMutex } from './ElementBase';
import Toast from '../utils/Toast';
import TimeoutHandler from '../utils/TimeoutHandler';
import ErrorUtil from '../utils/ErrorUtil';
import { ENTRY_TYPE_TEXT } from '../constants';


const mapStateToProps = (state, ownProps) => ({
  element: state.elements[ownProps.elementId],
  inclusion: state.inclusions[ownProps.inclusionId],
  projectElement: state.projectElements[ownProps.projectElementId],
  projectEntry: retrieveProjectEntry(
    Object.values(state.projectEntries),
    ownProps.inclusionId,
    ownProps.projectElementId,
    ownProps.moduleInstanceId,
  ),
});

const mapDispatchToProps = (dispatch) => ({
  addEntry: async (data) => dispatch(projectEntriesActions.create(
    { type: ENTRY_TYPE_TEXT, ...data },
  )),
  removeEntry: async (id) => dispatch(projectEntriesActions.remove(id)),
  patchEntry: async (id, data) => dispatch(projectEntriesActions.patch(id, data)),
});


@withToastManager
@connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })
class ElementText extends Component {
  static propTypes = {
    elementId: PropTypes.number.isRequired,
    element: PropTypes.shape().isRequired,
    projectElementId: PropTypes.number,
    moduleInstanceId: PropTypes.number,
    inclusion: PropTypes.shape(),
    inclusionId: PropTypes.number,
    parent: PropTypes.shape({
      content: PropTypes.shape(),
    }),
    methods: PropTypes.shape({
      move: PropTypes.func,
      reload: PropTypes.func.isRequired,
      remove: PropTypes.func,
      checkLinks: PropTypes.func,
    }).isRequired,
    isEditMode: PropTypes.bool,
    isEntryMode: PropTypes.bool,
    admin: PropTypes.bool,
    isModule: PropTypes.bool,
    isReadOnly: PropTypes.bool,
    projectEntry: PropTypes.shape(),
    projectElement: PropTypes.shape().isRequired,
    addEntry: PropTypes.func.isRequired,
    removeEntry: PropTypes.func.isRequired,
    patchEntry: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
  };

  static defaultProps = {
    projectElementId: null,
    moduleInstanceId: null,
    inclusion: null,
    inclusionId: null,
    parent: null,
    isEditMode: false,
    isEntryMode: false,
    admin: false,
    isModule: false,
    isReadOnly: false,
    projectEntry: null,
  };

  constructor(props) {
    super(props);
    this.state = {
      textarea: props.projectEntry ? props.projectEntry.value : undefined,
    };
    this.timeoutHandler = new TimeoutHandler();
  }

  checkLinks = () => {
    if (this.elBaseRef) this.elBaseRef.checkLinks();
  };

  showLoader = (show = true) => {
    if (this.elBaseRef) this.elBaseRef.showLoader(show);
  };

  updateEntry = async (e) => {
    const { value } = e.target;
    this.setState({ textarea: value });
    this.timeoutHandler.doAfterTimeout(async () => {
      const mutex = getMutex(this.props);
      mutex.cancel();
      let release;
      try {
        release = await mutex.acquire();
      } catch (error) {
        // Acquire has been canceled.
        return;
      }
      const {
        projectEntry, inclusion, projectElement, moduleInstanceId, addEntry, patchEntry,
        removeEntry, methods,
      } = this.props;
      try {
        this.showLoader();
        if (projectEntry) {
          if (value) {
            await patchEntry(projectEntry.id, { value });
          } else {
            await removeEntry(projectEntry.id);
          }
        } else if (value) {
          await addEntry({
            inclusion: inclusion.id,
            project_element: projectElement.id,
            module: moduleInstanceId,
            value,
          });
        }
        Toast.success(this.props, 'error:valid.saved');
        if (methods.checkLinks) methods.checkLinks();
        if (this.elBaseRef) this.elBaseRef.checkForCorruptedMissingDataCount();
      } catch (error) {
        ErrorUtil.handleCatched(this.props, error);
      } finally {
        release();
        this.showLoader(false);
      }
    }, 0, 1000);
  };

  render() {
    const {
      isEditMode, t, isReadOnly, element, projectEntry, isEntryMode,
    } = this.props;
    const { textarea } = this.state;
    const options = {};

    if (isEditMode || isReadOnly) {
      options.disabled = true;
    }
    if (isEntryMode) {
      options.onChange = this.updateEntry;
    } else {
      options.onChange = (e) => this.setState({ textarea: e.target.value });
    }

    const isTemplate = Boolean(element.template);
    const missingData = isEntryMode && !projectEntry ? 'empty' : '';
    const qualifiedMissingData = isEntryMode && projectEntry && projectEntry.missing ? 'qualified-missing' : '';
    let warningMsg;
    let warningIconColor;

    if (!isEntryMode) {
      if (element.template === 'id_code') {
        warningMsg = <Trans i18nKey="error:warning.identification-number" />;
        warningIconColor = 'text-red';
      } else {
        warningMsg = <Trans i18nKey="error:warning.free-text-element" />;
        warningIconColor = 'text-yellow';
      }
    }

    return (
      <ElementBase
        {...this.props}
        elementCategory="entry"
        ref={(ref) => { this.elBaseRef = ref; }}
        warningMsg={warningMsg}
        warningIconColor={warningIconColor}
      >
        <div className={isTemplate ? 'col-auto' : 'col-12 col-sm-8 col-md-11 col-lg-10 col-xl-8'}>
          <textarea
            title=""
            disabled={isReadOnly}
            className={`form-control ${this.props.isEditMode
              ? 'bg-gray-200 no-resize'
              : 'bg-transparent'} ${isTemplate ? '' : 'element-free-text'} ${missingData}${qualifiedMissingData}`}
            placeholder={t('error:placeholder.element-text')}
            cols="30"
            rows={isMobile ? '2' : '1'}
            value={textarea}
            {...options}
          />
        </div>
      </ElementBase>
    );
  }
}


export default ElementText;
