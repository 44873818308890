import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';
import '../assets/css/head.css';
import BrowserView from './MixedView';
import MobileView from './MobileView';


class DashboardHead extends React.Component {
  static propTypes = {
    hideHeader: PropTypes.bool,
    breadcrumbPrevious: PropTypes.arrayOf(PropTypes.shape({
      link: PropTypes.string,
      icon: PropTypes.string,
      title: PropTypes.string,
    })),
    icon: PropTypes.string,
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.shape()]),
    subtitle: PropTypes.oneOfType([PropTypes.string, PropTypes.shape()]),
    admin: PropTypes.bool,
    helpButton: PropTypes.func,
  };

  static defaultProps = {
    hideHeader: false,
    breadcrumbPrevious: null,
    icon: null,
    title: null,
    subtitle: null,
    admin: false,
    helpButton: null,
  };

  componentDidMount() {
    this.breadcrumb = [];
    let i = 1;
    this.hideHeader = this.props.hideHeader;
    if (this.props.hideHeader !== true) {
      this.hideHeader = false;
    }
    if (this.props.breadcrumbPrevious
      && this.props.breadcrumbPrevious.length > 0) {
      this.props.breadcrumbPrevious.forEach((bc) => {
        this.breadcrumb.push(<li key={i} className="breadcrumb-item">
          <Link to={bc.link}>
            <FontAwesomeIcon icon={bc.icon} className="mr-2" />
            {bc.title}
          </Link>
        </li>);
        i += 1;
      });
    }
    this.breadcrumb.push(
      <li key={i} className="breadcrumb-item active">
        {
          this.props.icon ? (
            <FontAwesomeIcon icon={this.props.icon} className="mr-2" />
          ) : null
        }
        {this.props.title}
      </li>,
    );
    if (this.props.helpButton) this.props.helpButton(this.helpButton);
  }

  componentWillUnmount() {
    delete this.breadcrumb;
    delete this.hideHeader;
    delete this.helpButton;
  }

  render() {
    const { admin, title, subtitle } = this.props;
    return (
      <div
        id={this.props.hideHeader ? '' : 'head'}
      >
        {
          !this.hideHeader ? (
            <div className="row">
              <div className="col-12">
                <BrowserView>
                  <div className="card bg-transparent border-0 pt-5 pt-lg-3 pb-4 mt-3">
                    <h2
                      className={`m-0 d-block font-weight-normal ${
                        admin && admin === true
                          ? 'text-danger'
                          : 'text-primary'}`}
                    >
                      {title}
                    </h2>
                  </div>
                  {subtitle && (
                    <div
                      className="text-gray-dark font-weight-semibold"
                    >
                      {subtitle}
                    </div>
                  )}
                </BrowserView>
                <MobileView>
                  <div className="card bg-transparent border-0 pt-3 pb-4 mt-3">
                    <h2
                      className={`m-0 d-block font-weight-normal ${
                        admin && admin === true
                          ? 'text-danger'
                          : 'text-primary'}`}
                    >
                      {title}
                    </h2>
                  </div>
                </MobileView>
              </div>
            </div>
          ) : null
        }
      </div>
    );
  }
}


export default DashboardHead;
