import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import api from '../api';
import { countriesActions } from '../redux/actions';
import Pagination from './Pagination';
import {
  Table, TableHead, TableBody, TableRow, TableCell,
} from './CustomTable';
import useError from '../utils/HookErrorUtil';


function OrganizationNetwork(props) {
  const { organization, admin } = props;
  const dispatch = useDispatch();
  const { handleCatched } = useError();
  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);
  const [ready, setReady] = useState(false);
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(0);
  const [pageSize, setPageSize] = useState(1);
  const [users, setUsers] = useState(undefined);

  const fetchCountries = async () => dispatch(countriesActions.list({}, { pagination: 'no' }));

  const countries = useSelector((state) => state.countries);


  const fetchPaginatedUsers = async (newPage, initialization = false) => {
    if (!initialization) setLoading(true);
    try {
      // In admin mode, fetchUsers retrieves the current organization from the state users
      const res = await api.list('users', {
        page: newPage,
        id__in: organization.users_who_bookmarked,
        users_who_bookmarked: true,
        admin,
      }, { pagination: 'short' });
      setUsers(Object.values(res.results));
      setPage(newPage);
      setCount(res.count);
      setPageSize(Math.max(res.results.length, pageSize));
    } catch (error) {
      handleCatched(props, error);
    } finally {
      if (!initialization) setLoading(false);
    }
  };

  const fetchData = async () => {
    setLoading(true);
    try {
      await Promise.all([fetchPaginatedUsers(1, true), fetchCountries()]);
    } catch (error) {
      handleCatched(props, error);
    } finally {
      setLoading(false);
      setReady(true);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div>
      <div className="pb-3 community-card-subtitle">
        {t('community:network.subtitle')}
      </div>
      {ready ? (
        <div>
          <Table extraClassName="no-pointer mt-2 mb-0">
            <TableHead className="table-head">
              <TableRow>
                <TableCell>
                  {t('community:user')}
                </TableCell>
                <TableCell>
                  {t('community:type')}
                </TableCell>
                <TableCell>
                  {t('community:specialty')}
                </TableCell>
                <TableCell>
                  {t('community:location')}
                </TableCell>
                <TableCell>
                  {t('community:doqboard-member-since')}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {users && users.length > 0 && !loading ? (
                users.map((user) => (
                  <TableRow
                    key={`user-${user.id}`}
                  >
                    <TableCell className="align-middle text-center font-weight-semibold">
                      {user.label}
                    </TableCell>
                    <TableCell>
                      {t(`user:types.${user.type}`)}
                    </TableCell>
                    <TableCell>
                      {user.specialty ? t(`specialty:${user.specialty}`) : ''}
                    </TableCell>
                    <TableCell>
                      {user.city ? `${user.city}, ` : ''}
                      {user.country ? t(`country:${countries[user.country].name}`) : ''}
                    </TableCell>
                    <TableCell className="align-middle text-center doqboard-member-since">
                      {moment(user.date_joined).format('MMMM YYYY')}
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow className="no-one">
                  <TableCell
                    colSpan="5"
                    className="align-middle text-center text-muted small font-italic"
                  >
                    {loading ? (
                      <span>
                        &nbsp;
                      </span>
                    ) : (
                      <span>
                        {t('community:network.no-followers')}
                      </span>
                    )}
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
          {count > 10 && (
            <div className="mt-2">
              <Pagination
                page={page}
                count={count}
                pageSize={pageSize}
                action={fetchPaginatedUsers}
              />
            </div>
          )}
        </div>
      ) : (<div>hello</div>)}
    </div>
  );
}

OrganizationNetwork.propTypes = {
  organization: PropTypes.shape().isRequired,
  admin: PropTypes.bool,
};

OrganizationNetwork.defaultProps = {
  admin: false,
};

export default OrganizationNetwork;
