import { createReducer } from 'redux-starter-kit';
import { groupsActions } from '../actions';
import getStdDbModelReduxMapping from './std-db-model-reducers';

export const groupsInitialState = {};

const groupsReducer = createReducer(
  groupsInitialState,
  getStdDbModelReduxMapping(groupsActions, 'value'),
);

export default groupsReducer;
