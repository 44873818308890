import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CardLoader } from './Loader';
import { nsOptions } from '../i18n';
import { childrenPropTypes } from '../utils/generic-prop-types';


@withTranslation('', nsOptions)
class InvitationCardBase extends Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
    children: childrenPropTypes(),
    invitation: PropTypes.shape(),
    errorMessage: PropTypes.string,
    ready: PropTypes.bool,
    label: PropTypes.string,
    join: PropTypes.func.isRequired,
    cancel: PropTypes.func.isRequired,
    title: PropTypes.string.isRequired,
    subtitle: PropTypes.string.isRequired,
    joinLabel: PropTypes.string.isRequired,
    invalidInvitationMsg: PropTypes.string.isRequired,
  };

  static defaultProps = {
    children: null,
    invitation: null,
    errorMessage: '',
    ready: false,
    label: '',
  };

  render() {
    const {
      invitation, errorMessage, ready, t, label, children, join, cancel, title, subtitle, joinLabel,
      invalidInvitationMsg,
    } = this.props;

    if (!ready) return (<CardLoader />);

    return (
      <div className="row justify-content-center">
        <div className="col-12 col-md-8 col-lg-6 col-xl-5">
          <div className="card bg-white card-shadow mt-5">
            <div className="invitation-card-header px-5 pt-5">
              <FontAwesomeIcon
                icon={['fal', 'envelope-open-text']}
                className="stamp-icon"
                transform="grow-6"
              />
              <h2 className="text-center">
                {t(title)}
              </h2>
            </div>
            <div className="px-5 pb-5">
              {
                invitation ? (
                  <div>
                    <h4 className="text-center mt-5">
                      {t(subtitle, { label })}
                    </h4>
                    <div className="mt-5">
                      {children}
                    </div>
                    <div className="row justify-content-center mt-5">
                      <div className="col-auto">
                        <button
                          className="btn btn-newblue-3 text-white px-4"
                          type="button"
                          onClick={join}
                        >
                          {t(joinLabel)}
                        </button>
                      </div>
                      <div className="col-auto">
                        <button
                          className="btn btn-link"
                          type="button"
                          onClick={cancel}
                        >
                          {t('common:button.cancel')}
                        </button>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div>
                    <h4 className="text-center mt-5 text-newred-2">
                      {t(invalidInvitationMsg)}
                    </h4>
                    { errorMessage && (
                      <div className="text-center mt-4">
                        {errorMessage}
                      </div>
                    )}
                    <div className="row justify-content-center mt-5">
                      <div className="col-auto">
                        <button
                          className="btn btn-newblue-1 px-4"
                          type="button"
                          onClick={cancel}
                        >
                          {t('common:button.quit')}
                        </button>
                      </div>
                    </div>
                  </div>
                )
              }
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default InvitationCardBase;
