import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { withToastManager } from 'react-toast-notifications';
import { connect } from 'react-redux';
import moment from 'moment';
import withStyles from '@material-ui/core/styles/withStyles';
import Typography from '@material-ui/core/Typography';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Card from '@material-ui/core/Card';
import Grid from '@material-ui/core/Grid';
import Fab from '@material-ui/core/Fab';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Tooltip from '@material-ui/core/Tooltip';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import Chip from '@material-ui/core/Chip';
import FilterListIcon from '@material-ui/icons/FilterList';
import CancelIcon from '@material-ui/icons/Cancel';
import { fade } from '@material-ui/core/styles';
import Chart, { CHART_HEIGHT } from './Chart';
import QuantitativeFilterModal from './QuantitativeFilterModal';
import TextStats from './TextStats';
import LicenseChecker from '../LicenseChecker';
import { nsOptions } from '../../i18n';
import { isQuantitative } from '../../utils/stats';
import ErrorUtil from '../../utils/ErrorUtil';
import Toast from '../../utils/Toast';
import statsFiltersActions from '../../redux/actions/stats-filters';
import { MEASUREMENTS, TIME } from '../../constants';
import ElementUtil from '../../utils/ElementUtil';
import FormattedValue from '../../utils/FormattedValue';
import Formatter from '../../utils/Formatter';
import DataOverviewModal from './DataOverviewModal';
import { CardLoader } from '../Loader';
import fromReduxState from '../../utils/redux';

const styles = (theme) => ({
  empty: {
    marginTop: theme.spacing(8),
    marginBottom: theme.spacing(8),
    // Makes loader work
    position: 'relative',
  },
  cardContent: {
    '&:last-child': {
      paddingBottom: theme.spacing(2),
    },
  },
  chartAndTextContainer: {
    // Makes loader work
    position: 'relative',
  },
  chartContainer: {
    backgroundColor: fade(theme.palette.primary.main, 0.1),
  },
  chartSubContainer: {
    height: '100%',
  },
  textContainer: {
    backgroundColor: fade(theme.palette.primary.main, 0.08),
    overflowY: 'auto',
  },
  textSubContainer: {
    height: CHART_HEIGHT + theme.spacing(4),
    padding: `0 ${theme.spacing(4)}px`,
  },
  textContent: {
    marginTop: 'auto',
    marginBottom: 'auto',
  },
  buttonContainer: {
    position: 'relative',
  },
  clickDescription: {
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%)',
    fontSize: theme.typography.pxToRem(12),
    color: theme.palette.grey[600],
  },
  container: {
    width: 'fit-content',
    marginTop: '0.3rem',
  },
  fab: {
    '&:focus': {
      outline: 'none',
    },
  },
});

class FilterButton extends React.Component {
  static propTypes = {
    type: PropTypes.string.isRequired,
    t: PropTypes.func.isRequired,
    onValueFilter: PropTypes.func,
    onIntervalFilter: PropTypes.func,
    isFiltered: PropTypes.bool.isRequired,
    classes: PropTypes.shape().isRequired,
    project: PropTypes.shape().isRequired,
  };

  static defaultProps = {
    onValueFilter: () => {},
    onIntervalFilter: () => {},
  };

  constructor(props) {
    super(props);
    this.state = {
      filterTypeSelectEl: null,
    };
  }

  onOpenFilterTypeSelect = (event) => {
    this.setState({ filterTypeSelectEl: event.currentTarget });
  };

  onCloseFilterTypeSelect = () => {
    this.setState({ filterTypeSelectEl: null });
  };

  render() {
    const {
      t, type, onValueFilter, onIntervalFilter, isFiltered, classes, project,
    } = this.props;
    const { filterTypeSelectEl } = this.state;

    const hasMenu = isQuantitative(type) && !isFiltered;
    return (
      <>
        <LicenseChecker
          limName="can_use_stats_filters"
          limitations={project.limitations}
          key="filter-button"
        >
          <Fab
            variant="extended"
            onClick={hasMenu ? this.onOpenFilterTypeSelect : onValueFilter}
            aria-controls={hasMenu ? 'filter-type-select' : null}
            aria-haspopup={hasMenu ? true : null}
            className={classes.fab}
          >
            <FilterListIcon className={classes.filterIcon} />
            <Box ml={1}>
              {t(isFiltered ? 'stats:remove-filter' : 'stats:add-as-filter')}
            </Box>
          </Fab>
        </LicenseChecker>
        {
          hasMenu
            ? (
              <Menu
                id="filter-type-select"
                anchorEl={filterTypeSelectEl}
                keepMounted
                open={Boolean(filterTypeSelectEl)}
                onClose={this.onCloseFilterTypeSelect}
              >
                <MenuItem key="filter-value" onClick={onValueFilter}>
                  { t('stats:filter-by-value') }
                </MenuItem>
                <MenuItem key="filter-interval" onClick={onIntervalFilter}>
                  { t('stats:filter-by-interval') }
                </MenuItem>
              </Menu>
            )
            : null
        }
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  statsFilters: state.statsFilters,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  toggleFilter: async (filter) => dispatch(statsFiltersActions.toggleFilter(filter)),
  removeFilters: () => dispatch(statsFiltersActions.deleteTargetFilters(ownProps.id)),
});


@withToastManager
@connect(mapStateToProps, mapDispatchToProps)
@withTranslation('', nsOptions)
@withStyles(styles)
class VariableCard extends React.Component {
  static propTypes = {
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    name: PropTypes.string.isRequired,
    type: PropTypes.string,
    elementType: PropTypes.string,
    unit: PropTypes.string,
    seriesNames: PropTypes.arrayOf(PropTypes.string).isRequired,
    count: PropTypes.number,
    seriesNamesCounts: PropTypes.shape().isRequired,
    iterations: PropTypes.arrayOf(PropTypes.shape()),
    chartData: PropTypes.arrayOf(PropTypes.any).isRequired,
    textStats: PropTypes.shape().isRequired,
    inModule: PropTypes.bool,
    t: PropTypes.func.isRequired,
    classes: PropTypes.shape().isRequired,
    statsFilters: PropTypes.shape().isRequired,
    toggleFilter: PropTypes.func.isRequired,
    removeFilters: PropTypes.func.isRequired,
    loadData: PropTypes.func.isRequired,
    statsLoading: PropTypes.bool,
    project: PropTypes.shape().isRequired,
    canUseAdvancedTools: PropTypes.bool.isRequired,
    filtersParams: PropTypes.shape(),
    isLinkTarget: PropTypes.bool,
  };

  static defaultProps = {
    type: null,
    elementType: null,
    unit: '',
    count: null,
    inModule: false,
    iterations: null,
    statsLoading: false,
    filtersParams: null,
    isLinkTarget: false,
  };

  state = {
    activeIndex: null,
    activeSeriesName: null,
    activeClick: false,
    loading: false,
    showQuantitativeFilterModal: false,
    togglingFilter: false,
  };

  activeTimeout = -1;

  getVariableFilters = fromReduxState((statsFilters, id) => (
    Object.values(statsFilters).filter((filter) => (
      filter.targetId === id
    ))
  ));

  get stats() {
    try {
      const {
        type, unit, count, seriesNames, iterations, chartData, inModule, t,
        classes, project, canUseAdvancedTools, filtersParams, id, isLinkTarget,
        elementType,
      } = this.props;
      const {
        activeIndex, activeSeriesName, activeClick, showQuantitativeFilterModal,
        togglingFilter,
      } = this.state;
      if (activeIndex === null || activeIndex < 0 || activeSeriesName === null || togglingFilter) {
        return null;
      }

      let name;
      let payload;

      if (inModule) {
        name = seriesNames[activeIndex];
        payload = chartData.length === 1
          ? chartData[0] : chartData.find((row) => row.name === activeSeriesName);
      } else {
        name = 'value';
        payload = chartData[activeIndex];
      }

      // Deep copy of filtersParams
      const overviewFilters = JSON.parse(JSON.stringify(filtersParams));
      if (activeClick && !Object.keys(overviewFilters.filters).includes(id)) {
        const fakeFilter = this.getPendingFilter();
        overviewFilters.filters[fakeFilter.targetId] = { value: fakeFilter.value };
        if (inModule) {
          overviewFilters.filters[fakeFilter.targetId].series_name = name;
        }
      }

      if (!payload) return null; // May happen in case of outdated activeIndex vs new chartData

      const value = payload[name];
      const probability = value / count;
      const payloadName = payload.name || t('common:elements.default-labels.answer');

      const title = `${inModule ? `${name} / ` : ''}${payloadName}${unit ? `  ${unit}` : ''}`;

      return (
        <Grid container direction="column" spacing={4}>
          <Grid item>
            <Grid container direction="column" spacing={1}>
              <Grid item>
                <strong>
                  {inModule ? `${name} - ` : ''}
                  {payloadName}
                  {'  '}
                  {unit}
                </strong>
              </Grid>
              <Grid item>
                <FormattedValue value={probability} isRatio />
                {` (${value}/${count} ${t('stats:inclusions.unit')})`}
              </Grid>
              <Grid item>
                { t('stats:ci95') }
                &nbsp;
                <FormattedValue
                  value={payload.confidence_interval_95[name]}
                  isRatio
                  closedLower
                  closedUpper
                  emptyComponent={<em>{t('stats:not-applicable')}</em>}
                />
              </Grid>
              {inModule && type === MEASUREMENTS ? (
                <Grid item>
                  { t('stats:mean-entry') }
                  :&nbsp;
                  <FormattedValue
                    value={
                      iterations.find(
                        (iteration) => iteration.name === seriesNames[activeIndex],
                      ).count
                    }
                  />
                  &nbsp;
                  {unit}
                </Grid>
              ) : null}
              {activeClick && (
                <Grid item>
                  <DataOverviewModal
                    id={id}
                    name={this.props.name}
                    count={value}
                    filtersParams={overviewFilters}
                    project={project}
                    isLinkTarget={isLinkTarget}
                    modalitySelected
                    modalityTitle={title}
                    elementType={elementType}
                    showLoader={this.showLoader}
                    canUseAdvancedTools={canUseAdvancedTools}
                  />
                </Grid>
              )}
            </Grid>
          </Grid>
          <Grid item className={classes.buttonContainer}>
            <div className={classes.clickDescription} hidden={activeClick}>
              {t('stats:click-description')}
            </div>
            <div align="center" hidden={!canUseAdvancedTools || !activeClick}>
              <FilterButton
                type={type}
                t={t}
                onValueFilter={this.valueFilter}
                onIntervalFilter={this.intervalFilter}
                isFiltered={this.isFiltered(activeIndex, activeSeriesName)}
                classes={classes}
                project={project}
              />
              <QuantitativeFilterModal
                open={showQuantitativeFilterModal}
                onClose={() => { this.setState({ showQuantitativeFilterModal: false }); }}
                onValidate={this.validateQuantitativeFilter}
                type={type}
              />
            </div>
          </Grid>
        </Grid>
      );
    } catch (error) {
      ErrorUtil.handleCatched(this.props, error, false);
      return null;
    }
  }

  get text() {
    const { activeSeriesName } = this.state;
    if (activeSeriesName !== null) {
      const { stats } = this;
      if (stats) {
        return stats;
      }
    }

    const {
      id, type, unit, count, textStats, canUseAdvancedTools, seriesNamesCounts, inModule, name,
      filtersParams, isLinkTarget, project, elementType,
    } = this.props;
    return (
      <TextStats
        id={id}
        type={type}
        unit={unit}
        count={count}
        seriesNamesCounts={seriesNamesCounts}
        inModule={inModule}
        variableName={name}
        filtersParams={filtersParams}
        isLinkTarget={isLinkTarget}
        project={project}
        elementType={elementType}
        showLoader={this.showLoader}
        canUseAdvancedTools={canUseAdvancedTools}
        {...textStats}
      />
    );
  }

  isFiltered = (index, seriesName) => {
    const {
      inModule, chartData, statsFilters, id,
    } = this.props;
    const variableFilters = this.getVariableFilters(statsFilters, id);
    if (inModule) {
      return variableFilters.some((filter) => filter.formattedValue === seriesName);
    }
    const row = chartData[index];
    return row && variableFilters.some(
      (filter) => filter.value === (row.rawName || row.name),
    );
  };

  setActive = (index, seriesName, click) => {
    clearTimeout(this.activeTimeout);
    const { activeIndex, activeSeriesName, activeClick } = this.state;
    const isSame = (
      (index === activeIndex) && (seriesName === activeSeriesName)
    );
    if (click && activeClick && isSame) {
      this.unsetActive(true);
    } else if ((click && activeClick && !isSame) || !activeClick) {
      this.setState({
        activeIndex: index,
        activeSeriesName: seriesName,
        activeClick: click,
      });
    }
  };

  unsetActive = (synchronous) => {
    const changeState = () => {
      this.setState({
        activeIndex: null,
        activeSeriesName: null,
        activeClick: false,
      });
      this.activeTimeout = -1;
    };
    if (synchronous && this.state.activeClick) {
      changeState();
    } else if (!synchronous && !this.state.activeClick) {
      clearTimeout(this.activeTimeout);
      this.activeTimeout = setTimeout(changeState, 250);
    }
  };

  getPendingFilter = () => {
    const { activeIndex, activeSeriesName } = this.state;
    const {
      chartData, id, name, inModule, type,
    } = this.props;

    if (activeIndex === undefined || activeSeriesName === null) {
      return null;
    }

    try {
      let activeRow;
      let index;
      if (inModule) {
        index = chartData.length === 1
          ? 0 : chartData.findIndex((row) => row.name === activeSeriesName);
        activeRow = chartData[index];
      } else {
        index = activeIndex;
        activeRow = chartData[activeIndex];
      }
      const value = activeRow.rawName;
      const isLastIndex = index === chartData.length - 1;
      const additionalOptions = {};
      if (isQuantitative(type)) {
        additionalOptions.lowerBound = true;
        additionalOptions.upperBound = isLastIndex;
      }
      return {
        targetId: id,
        targetName: name,
        value,
        formattedValue: activeRow.name,
        ...additionalOptions,
      };
    } catch (error) {
      ErrorUtil.handleCatched(this.props, error, false);
      return null;
    }
  }

  toggleFilter = async (filter) => {
    const { toggleFilter, loadData } = this.props;
    await toggleFilter(filter);
    try {
      await loadData();
      this.unsetActive(true);
    } catch (error) {
      ErrorUtil.handleCatched(this.props, error, false);
    }
  };

  removeFilter = () => {
    const { removeFilters, loadData } = this.props;
    removeFilters();
    loadData();
  }

  valueFilter = async () => {
    const { activeIndex, activeSeriesName } = this.state;

    if (activeIndex !== undefined && activeSeriesName !== null) {
      const filter = this.getPendingFilter();
      if (filter) {
        this.setState({ togglingFilter: true });
        await this.toggleFilter(filter);
        this.setState({ togglingFilter: false });
      } else {
        Toast.error(this.props, 'Not supported yet');
      }
    }
  };

  intervalFilter = () => {
    this.setState({ showQuantitativeFilterModal: true });
  };

  validateQuantitativeFilter = async (lowBound, highBound, includeLimits) => {
    this.setState({ showQuantitativeFilterModal: false });

    const { id, name, type } = this.props;
    const rawValue = [lowBound, highBound];
    const formatter = new Formatter(type, null, null, false, includeLimits, includeLimits);
    const value = rawValue.map((bound) => {
      if (bound instanceof moment) {
        return type === TIME ? formatter.formatDateTime(bound) : bound.format();
      }
      return bound;
    });
    const filter = {
      targetId: id,
      targetName: name,
      value,
      formattedValue: formatter.format(rawValue),
      lowerBound: includeLimits,
      upperBound: includeLimits,
    };

    this.setState({ togglingFilter: true });
    await this.toggleFilter(filter);
    this.setState({ togglingFilter: false });
  };

  showLoader = (show = true) => this.setState({ loading: show });

  render() {
    const {
      id, type, unit, count, seriesNames, chartData, inModule, t, classes, statsFilters,
      statsLoading, name, filtersParams, project, isLinkTarget, elementType, canUseAdvancedTools,
    } = this.props;
    const { loading } = this.state;
    const variableFilters = this.getVariableFilters(statsFilters, id);
    let content;

    if ((chartData.length === 0) || (count === 0)) {
      content = (
        <Container className={classes.empty}>
          {loading && <CardLoader />}
          <Typography align="center">
            {t('stats:empty')}
          </Typography>
          <Container className={classes.container}>
            <DataOverviewModal
              id={id}
              name={name}
              count={count}
              filtersParams={filtersParams}
              project={project}
              isLinkTarget={isLinkTarget}
              elementType={elementType}
              showLoader={this.showLoader}
              canUseAdvancedTools={canUseAdvancedTools}
            />
          </Container>
        </Container>
      );
    } else {
      const { activeIndex, activeSeriesName, activeClick } = this.state;
      content = (
        <Grid className={classes.chartAndTextContainer} container alignItems="stretch" spacing={4}>
          {loading && <CardLoader />}
          <Grid item xs={12} md={8} className={classes.chartContainer}>
            <Grid
              container
              direction="row"
              alignContent="center"
              className={classes.chartSubContainer}
            >
              <Grid item xs={12}>
                <Chart
                  id={id}
                  type={type}
                  unit={unit}
                  seriesNames={seriesNames}
                  count={count}
                  data={chartData}
                  setActive={this.setActive}
                  unsetActive={this.unsetActive}
                  activeIndex={activeIndex}
                  activeSeriesName={activeSeriesName}
                  activeClick={activeClick}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={4} className={classes.textContainer}>
            <Grid
              container
              direction="row"
              spacing={4}
              className={classes.textSubContainer}
            >
              <Grid item xs={12} className={classes.textContent}>
                {this.text}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      );
    }

    const title = (
      <Grid container direction="column">
        <Grid item>
          {ElementUtil.formatElementName(this.props, t)}
        </Grid>
        {
          variableFilters.length
            ? (
              <Grid item>
                {variableFilters.map((filter) => (
                  <Chip
                    key={filter.targetId}
                    icon={<FilterListIcon />}
                    label={(
                      <FormattedValue
                        value={filter.formattedValue
                          || t('common:elements.default-labels.answer')}
                        unit={unit}
                      />
                    )}
                    onDelete={this.removeFilter}
                    deleteIcon={(
                      <Tooltip
                        title={t('stats:remove-filter')}
                        placement="top"
                      >
                        <CancelIcon />
                      </Tooltip>
                    )}
                    size="small"
                    color="primary"
                    disabled={statsLoading}
                  />
                ))}
              </Grid>
            )
            : null
        }
      </Grid>
    );

    return (
      <Card raised={inModule}>
        <CardHeader
          title={title}
          titleTypographyProps={{ align: 'center', variant: 'h6' }}
        />
        <CardContent className={classes.cardContent}>{content}</CardContent>
      </Card>
    );
  }
}

export default VariableCard;
