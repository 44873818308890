/* eslint-disable import/prefer-default-export */
export const getViewport = () => {
  let e = window;
  let a = 'inner';
  if (!('innerWidth' in window)) {
    a = 'client';
    e = document.documentElement || document.body;
  }
  return { width: e[`${a}Width`], height: e[`${a}Height`] };
};

export const isBigTablet = () => {
  const viewport = getViewport();
  return viewport.width > 1360;
};
