import moment from 'moment';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Progress } from 'reactstrap';
import { withToastManager } from 'react-toast-notifications';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { connect } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import { inclusionsActions } from '../redux/actions';
import { nsOptions } from '../i18n';
import {
  IDENTIFICATION_LENGTH, ORGANIZATION_TYPES,
} from '../constants';
import MixedView from './MixedView';
import MobileView from './MobileView';
import { TableCell, TableRow } from './CustomTable';
import { LabeledUserAvatar } from './avatar';
import ErrorUtil from '../utils/ErrorUtil';
import FormattedValue from '../utils/FormattedValue';
import { isInclusionReadOnly } from '../utils/data-util';
import TextUtil from '../utils/TextUtil';
import { getMissingDataColors } from '../utils/inclusion';
import ElementUtil from '../utils/ElementUtil';
import NewTooltip from './NewTooltip';
import TruncatedText from '../utils/TruncatedText';

const IDENTIFIER_LABEL_MAX_LENGTH = 20;

const styles = (theme) => ({
  identificationItem: {
    minWidth: theme.spacing(8),
    textAlign: 'center',
  },
  identifierLabel: {
    fontSize: theme.typography.caption.fontSize,
    color: theme.palette.text.secondary,
  },
});


const mapStateToProps = (state, ownProps) => ({
  project: state.projects[ownProps.inclusion.project],
  projectUsers: state.projectUsers,
  projectUser: Object.values(state.projectUsers).find((pUser) => (
    pUser.user.id === state.auth.authUser.id && pUser.project === ownProps.inclusion.project
  )),
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  deleteInclusion: async () => (
    dispatch(inclusionsActions.remove(ownProps.inclusion.id))
  ),
});


@withToastManager
@connect(mapStateToProps, mapDispatchToProps)
@withTranslation('', nsOptions)
@withStyles(styles)
class InclusionsItem extends Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
    inclusion: PropTypes.shape().isRequired,
    inclusionForm: PropTypes.shape(),
    fetchProjectData: PropTypes.func,
    projectUsers: PropTypes.shape().isRequired,
    projectUser: PropTypes.shape(),
    project: PropTypes.shape(),
    showProject: PropTypes.bool,
    showInclusionDate: PropTypes.bool,
    showLastChangeDate: PropTypes.bool,
    showIdentification: PropTypes.bool,
    showDataMonitoring: PropTypes.bool,
    classes: PropTypes.shape().isRequired,
    showLoader: PropTypes.func,
  };

  static defaultProps = {
    inclusionForm: null,
    fetchProjectData: () => {},
    project: null,
    projectUser: null,
    showProject: false,
    showInclusionDate: false,
    showLastChangeDate: false,
    showIdentification: false,
    showDataMonitoring: false,
    showLoader: () => {},
  };

  onClick = async () => {
    const {
      inclusion, inclusionForm, fetchProjectData, project, showLoader,
    } = this.props;
    try {
      showLoader();
      await fetchProjectData(project);
      await inclusionForm.init(inclusion.id);
    } catch (error) {
      ErrorUtil.handleCatched(this.props, error);
    } finally {
      showLoader(false);
    }
  }

  formatIdentifierLabel = (identifier) => {
    const { t } = this.props;
    const label = ElementUtil.formatElementName(identifier, t);
    return TextUtil.truncate(label, IDENTIFIER_LABEL_MAX_LENGTH);
  }

  readOnlyTooltip = (isOrganization) => {
    const { t } = this.props;
    if (isOrganization) {
      return (
        <div>
          {t('error:warning.inclusion-read-only-organization')}
        </div>
      );
    }
    return (
      <div>
        {t('error:warning.inclusion-read-only')}
      </div>
    );
  };

  render() {
    const {
      t, inclusion, projectUsers, projectUser, showProject, project, classes,
      showInclusionDate, showLastChangeDate, showIdentification, showDataMonitoring,
    } = this.props;
    const inclusionReadOnly = isInclusionReadOnly(inclusion, projectUser, projectUsers, project);
    const creatorProjectUser = Object.values(projectUsers).find((pUser) => (
      pUser.user.id === inclusion.creator
    ));
    const identifiers = inclusion.identifiers.slice(0, IDENTIFICATION_LENGTH);
    const isInclusionCreator = projectUser && projectUser.user
      && projectUser.user.id === inclusion.creator;
    const isOrganization = projectUser && projectUser.user && projectUser.user.type
      && (ORGANIZATION_TYPES.includes(projectUser.user.type));
    const MobileSubItem = (props) => (
      <div className="col-sm-6 text-center">
        <Typography display="inline" noWrap className={`${classes.identifierLabel} ${props.bold ? 'font-weight-semibold' : ''}`}>
          {`${props.label}${t('common:colon')} `}
        </Typography>
        <span>
          {`${props.value} `}
        </span>
        {
          props.lock && (
            <FontAwesomeIcon
              icon={['fas', 'lock-alt']}
              className="text-gray ml-2"
              transform="shrink-3"
            />
          )
        }
      </div>
    );

    const extraAvatarParams = {};
    if (!creatorProjectUser && inclusion) {
      extraAvatarParams.toLabel = () => inclusion.creator_name || inclusion.creator_email || '';
    }

    const { progression } = inclusion;
    const inconsistentData = inclusion.inconsistent_data_count;
    const qualifiedMissingData = inclusion.qualified_missing_data_count;

    return [
      (
        <MixedView key={0}>
          <TableRow
            style={{ height: '67px' }}
            className={inclusion.is_test ? 'test-inclusion-item' : ''}
          >
            {showLastChangeDate && inclusion.is_test && (
              <TableCell
                role="presentation"
                onClick={this.onClick}
              >
                <FormattedValue value={moment(inclusion.updated_at)} />
              </TableCell>
            )}
            {!inclusion.is_test && (
              <>
                <TableCell
                  role="presentation"
                  className="align-middle text-center font-weight-semibold"
                  onClick={this.onClick}
                >
                  {inclusion.per_project_id}
                  {
                    inclusionReadOnly && (
                      <NewTooltip
                        content={this.readOnlyTooltip(isOrganization)}
                      >
                        <FontAwesomeIcon
                          icon={['fas', 'lock-alt']}
                          className="text-gray ml-2"
                          transform="shrink-2"
                        />
                      </NewTooltip>
                    )
                  }
                </TableCell>
                <TableCell
                  role="presentation"
                  onClick={this.onClick}
                >
                  <LabeledUserAvatar
                    user={creatorProjectUser ? creatorProjectUser.user : {}}
                    showAvatar={false}
                    {...extraAvatarParams}
                  />
                </TableCell>
              </>
            )}
            {showProject && project && (
              <TableCell role="presentation">
                <NewTooltip
                  theme="light"
                  content={t('project:inclusions.project-info')}
                >
                  <Link to={`/dashboard/project/${project.id}/details`}>
                    <>
                      <TruncatedText
                        text={project.name}
                        maxLength={40}
                      />
                      <FontAwesomeIcon
                        icon={['far', 'external-link']}
                        className="ml-2 text-gray"
                        transform="shrink-3"
                      />
                    </>
                  </Link>
                </NewTooltip>
              </TableCell>
            )}
            {showDataMonitoring && (
              <TableCell
                role="presentation"
                onClick={this.onClick}
              >
                <NewTooltip
                  content={t('inclusion:missing-data.tooltip-title', { count: inclusion.missing_data_count })}
                >
                  <Progress
                    // If progression is null we can't see the label on the bar
                    value={progression || 10}
                    style={
                      {
                        backgroundColor: progression ? getMissingDataColors(progression).progressBarColor : 'transparent',
                        color: `${progression ? '' : 'var(--dark)'}`,
                      }
                    }
                  >
                    {progression}
                    %
                  </Progress>
                </NewTooltip>
                {Boolean(qualifiedMissingData || inconsistentData) && (
                  <div className="container">
                    <div className={`row pt-2 my-0 ${qualifiedMissingData && inconsistentData ? 'justify-content-center' : 'justify-content-end'} align-items-center`}>
                      {Boolean(inconsistentData) && (
                        <NewTooltip
                          content={t('inclusion:inconsistent-data.x-invalid-data',
                            { count: inconsistentData })}
                        >
                          <div
                            className={`px-0 small text-red col-5 text-truncate ${qualifiedMissingData ? 'text-left' : 'text-right'}`}
                          >
                            {t('inclusion:inconsistent-data.x-invalid-data',
                              { count: inconsistentData })}
                          </div>
                        </NewTooltip>
                      )}
                      {Boolean(qualifiedMissingData && inconsistentData) && (
                        <div
                          className="col-2 px-1 text-center"
                        >
                          -
                        </div>
                      )}
                      {Boolean(qualifiedMissingData) && (
                        <NewTooltip
                          content={t('inclusion:qualified-missing-data.x-data-detailed',
                            { count: qualifiedMissingData })}
                        >
                          <div
                            className="px-0 small text-newblue-1 col-5 text-truncate text-right"
                          >
                            {t('inclusion:qualified-missing-data.x-data',
                              { count: qualifiedMissingData })}
                          </div>
                        </NewTooltip>
                      )}
                    </div>
                  </div>
                )}
              </TableCell>
            )}
            {showIdentification && (
              <TableCell
                role="presentation"
                className="align-middle"
                onClick={this.onClick}
              >
                <Grid container spacing={1}>
                  {identifiers.map((identifier) => (
                    <Grid
                      item
                      xs={12 / IDENTIFICATION_LENGTH}
                      key={identifier.id}
                      className={classes.identificationItem}
                    >
                      <Typography noWrap className={classes.identifierLabel}>
                        {this.formatIdentifierLabel(identifier)}
                      </Typography>
                      <Typography variant="body2" noWrap>{identifier.value}</Typography>
                    </Grid>
                  ))}
                </Grid>
              </TableCell>
            )}
            {showInclusionDate && (
              <TableCell
                role="presentation"
                onClick={this.onClick}
              >
                <FormattedValue value={moment(inclusion.created_at)} />
              </TableCell>
            )}
            {showLastChangeDate && !inclusion.is_test && (
              <TableCell
                role="presentation"
                onClick={this.onClick}
              >
                <FormattedValue value={moment(inclusion.updated_at)} />
              </TableCell>
            )}
          </TableRow>
        </MixedView>),
      (<MobileView key={1}>
        <ol className="list-group">
          <li
            className={`list-group-item mobile-list-item ${inclusion.is_test ? 'test-inclusion-item' : ''}`}
            onClick={this.onClick}
            role="menuitem"
            tabIndex={0}
            onKeyUp={this.handleKeyUp}
          >
            {!inclusion.is_test && (
              <MobileSubItem
                label={t('project:inclusions.id')}
                value={inclusion.per_project_id}
                lock={inclusionReadOnly}
                bold
              />
            )}
            {
              !isInclusionCreator && (
                <div className="col-sm-6 text-center font-italic">
                  <Typography display="inline" noWrap className={classes.identifierLabel}>
                    <LabeledUserAvatar
                      user={creatorProjectUser ? creatorProjectUser.user : {}}
                      showAvatar={false}
                      {...extraAvatarParams}
                    />
                  </Typography>
                </div>
              )
            }
            {showProject && project && (
              <MobileSubItem label={t('project:project')} value={project.name} />
            )}
            {
              showDataMonitoring && (
                <div className="col-sm-6">
                  <div className="text-center">
                    <div className="progress-sm">
                      <Progress
                        // If progression is null then we can't see the label on the bar
                        value={progression || 10}
                        style={
                          {
                            backgroundColor: progression ? getMissingDataColors(progression).progressBarColor : 'transparent',
                            color: `${progression ? '' : 'var(--dark)'}`,
                          }
                        }
                      >
                        {progression}
                        %
                      </Progress>
                    </div>
                    {inconsistentData ? (
                      <div className="small text-red pt-1">
                        {t('inclusion:inconsistent-data.x-invalid-data',
                          { count: inconsistentData })}
                      </div>
                    ) : (null)}
                    {qualifiedMissingData ? (
                      <div className={`small text-newblue-1 ${inconsistentData ? '' : 'pt-1'}`}>
                        {t('inclusion:qualified-missing-data.x-data',
                          { count: qualifiedMissingData })}
                      </div>
                    ) : (null)}
                  </div>
                </div>
              )
            }
            {
              identifiers && identifiers.length > 0 && (
                <MobileSubItem
                  label={this.formatIdentifierLabel(identifiers[0])}
                  value={identifiers[0].value}
                  key={identifiers[0].id}
                />
              )
            }
          </li>
        </ol>
      </MobileView>
      ),
    ];
  }
}


export default InclusionsItem;
