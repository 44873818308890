import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { withToastManager } from 'react-toast-notifications';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import Page from '../components/Page';
import withLicenseMsgModal from '../components/withLicenseMsgModal';
import { nsOptions } from '../i18n';
import Toast from '../utils/Toast';
import ErrorUtil from '../utils/ErrorUtil';
import {
  inclusionsActions, notificationsActions, projectsActions, userNotificationSettingActions,
} from '../redux/actions';
import { CardLoader } from '../components/Loader';
import { isMobileView } from '../components/MobileView';
import LabeledSelect from '../components/LabeledSelect';
import { ACKNOWLEDGED, STOPPED } from '../constants';

const mapStateToProps = (state) => ({
  user: state.auth.authUser,
  notification: Object.values(state.notifications)[0],
  project: Object.values(state.projects)[0],
  inclusion: Object.values(state.inclusions)[0],
});

const mapDispatchToProps = (dispatch) => ({
  fetchNotification: async (id) => (
    dispatch(notificationsActions.read(id))
  ),
  fetchNotificationSettings: async (notificationId, userId) => (
    dispatch(userNotificationSettingActions.list({
      notification: notificationId,
      user: userId,
    }))
  ),
  patchInclusion: async (id, data) => dispatch(inclusionsActions.patch(id, data)),
  handleUnsubscribe: async (notificationId, updatedData) => (
    dispatch(notificationsActions.patch(notificationId, updatedData))
  ),
  fetchProject: async (id) => dispatch(projectsActions.read(id)),
  fetchInclusion: async (id) => dispatch(inclusionsActions.read(id)),
});

@withToastManager
@connect(mapStateToProps, mapDispatchToProps)
@withTranslation('', nsOptions)
@withLicenseMsgModal()
class UnsubscribeNotification extends Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
    match: PropTypes.shape().isRequired,
    user: PropTypes.shape().isRequired,
    fetchNotification: PropTypes.func.isRequired,
    fetchProject: PropTypes.func.isRequired,
    fetchInclusion: PropTypes.func.isRequired,
    notification: PropTypes.shape(),
    project: PropTypes.shape(),
    inclusion: PropTypes.shape(),
    handleUnsubscribe: PropTypes.func.isRequired,
    fetchNotificationSettings: PropTypes.func.isRequired,
    notificationSettings: PropTypes.shape(),
  };

  static defaultProps = {
    notification: null,
    notificationSettings: null,
    project: null,
    inclusion: null,
  }

  constructor(props) {
    super(props, props.t('common:nav.unsubscribe-notifications'));
    this.state = {
      errorMessage: '',
      ready: false,
      unsubscribeReason: '',
      submited: false,
    };
  }

  componentDidMount() {
    const { inclusionId, notificationId } = this.props.match.params;
    this.setState({ inclusionId });
    this.fetchNotification(notificationId, this.props.user.id);
  }

  fetchNotification = async (notificationId, userId) => {
    const {
      fetchNotification,
      fetchNotificationSettings,
      fetchProject,
      fetchInclusion,
    } = this.props;
    try {
      await fetchNotificationSettings(notificationId, userId);
      await fetchNotification(notificationId);
      await fetchProject(this.props.notification.project);
      await fetchInclusion(this.state.inclusionId);
    } catch (error) {
      ErrorUtil.handleCatched(this.props, error, false);
      const errorMessage = ErrorUtil.makeApiErrorUserFriendly(this.props, error.message);
      this.setState({ errorMessage });
    } finally {
      this.setState({ ready: true });
    }
  };

  submit = async () => {
    const {
      user,
      notification, handleUnsubscribe, notificationSettings,
    } = this.props;
    console.log(notificationSettings);
    try {
      handleUnsubscribe(notification.id, {
        unsubscribe_reason: this.state.unsubscribeReason,
        inclusion_id: this.state.inclusionId,
        unsubscribed_user: user.id,
        project: notification.project,
      });
      this.setState({ submited: true });
      Toast.success(this.props, 'error:valid.saved');
    } catch (error) {
      ErrorUtil.handleCatched(this.props, error);
    }
  }

  render() {
    const {
      t, notification, project, inclusion,
    } = this.props;
    const { errorMessage, unsubscribeReason } = this.state;
    const mobileView = isMobileView();
    if (inclusion) {
      console.log(inclusion);
    }
    return (
      <Page
        {...this.props}
        className="dashboard-content"
        title={t('common:nav.unsubscribe-notifications')}
      >
        {!this.state.ready && <CardLoader />}
        {this.state.ready && (
          <div className="row justify-content-center">
            <div className="col-12 col-md-8 col-lg-6 col-xl-5">
              <div className="card bg-white card-shadow mt-5">
                <div className="invitation-card-header px-5 pt-5">
                  <FontAwesomeIcon
                    icon={['far', 'key']}
                    className="stamp-icon"
                    transform="grow-6"
                  />
                  <h2 className="text-center">
                    {t('project:notification.card.notification-management')}
                  </h2>
                </div>
                <div className="px-5 pb-5">
                  {
                    notification ? (
                      <form onSubmit={this.submit}>
                        <div className="my-3">
                          <span className="mr-1 font-weight-semibold">{`${t('project:notification.notification')} :`}</span>
                          <span>{notification.content_header}</span>
                        </div>
                        {project && (
                          <div className="my-3">
                            <span className="mr-1 font-weight-semibold">{`${t('project:project')} :`}</span>
                            <span>{project.name}</span>
                          </div>
                        )}
                        {inclusion && (
                          <div className="my-3">
                            <span className="mr-1 font-weight-semibold">{`${t('project:notification.inclusion')} :`}</span>
                            <span>{inclusion.per_project_id}</span>
                          </div>
                        )}
                        <div className="my-3">
                          <span className="mr-1 font-weight-semibold">{`${t('project:notification.form.notification-content-message')} :`}</span>
                          <span>{notification.content_body}</span>
                        </div>
                        <div className="my-3">
                          <span className="mr-1 mb-3 font-weight-semibold">{`${t('project:notification.card.why-stop')} :`}</span>
                          <LabeledSelect
                            className="col-12 mb-0 px-2 mt-3"
                            rowClassName={`row align-items-center inclusion-participation${mobileView ? '-mobile' : ''}`}
                            colLabelClassName={`col-auto px-0 ${mobileView ? 'pt-1' : ''}`}
                            colSelectClassName="col-auto pl-1"
                            labelClassName="mb-0"
                            name="team"
                            value={unsubscribeReason}
                            hideOptionalLabel
                            disabled={this.state.submited}
                            onChange={(e) => {
                              this.setState({ unsubscribeReason: e.target.value });
                            }}
                            selectClassName="custom-select pb-1 "
                          >
                            <option key="" value="" disabled>
                              {t('project:notification.card.choose-reason')}
                            </option>
                            <option
                              key={ACKNOWLEDGED}
                              value={ACKNOWLEDGED}
                            >
                              {t('project:notification.card.done')}
                            </option>
                            <option
                              key={STOPPED}
                              value={STOPPED}
                            >
                              {t('project:notification.card.dont-need')}
                            </option>
                          </LabeledSelect>
                        </div>
                        <div className="row justify-content-center mt-5">
                          <div className="col-auto">
                            {!this.state.submited && (
                              <button
                                className="btn btn-newblue-3 text-white px-4 mr-3"
                                type="button"
                                disabled={unsubscribeReason === ''}
                                onClick={this.submit}
                              >
                                {t('project:notification.card.confirm')}
                              </button>
                            )}
                            {this.state.submited && (
                              <div className="mr-1 mb-3 font-weight-semibold text-newgreen-2">{t('project:notification.card.no-longer-receive')}</div>
                            )}
                            <Link to="/dashboard/home/projects">
                              {!this.state.submited ? t('common:button.quit') : t('common:button.back')}
                            </Link>
                          </div>
                        </div>
                      </form>
                    ) : (
                      <div>
                        <h4 className="text-center mt-5 text-newred-2">
                          {t('project:notification.card.invalid')}
                        </h4>
                        { errorMessage && (
                          <div className="text-center mt-4">
                            {errorMessage}
                          </div>
                        )}
                        <div className="row justify-content-center mt-5">
                          <div className="col-auto">
                            <Link to="/dashboard/home/projects">
                              {t('common:button.quit')}
                            </Link>
                          </div>
                        </div>
                      </div>
                    )
                  }
                </div>
              </div>
            </div>
          </div>
        )}
      </Page>
    );
  }
}

export default UnsubscribeNotification;
