import { createAction } from 'redux-starter-kit';

const setItems = createAction('sorting_handler/set_items');
const addItems = createAction('sorting_handler/add_items');
const removeItem = createAction('sorting_handler/remove_item');
const removeHandler = createAction('sorting_handler/remove_handler');
const moveItem = createAction('sorting_handler/move_item');
const insertItem = createAction('sorting_handler/insert_item');

const SORTING_HANDLER_SEP = '/';

export const getSortingHandlerId = (type, digitalId) => `${type}${SORTING_HANDLER_SEP}${digitalId}`;

export const extractSortingHandlerType = (sortingHandlerId) => (
  sortingHandlerId.split(SORTING_HANDLER_SEP)[0]
);

export const extractSortingHandlerDigitalId = (sortingHandlerId) => (
  parseInt(sortingHandlerId.split(SORTING_HANDLER_SEP).pop(), 10)
);

export const getSortingItemId = (handlerId, digitalId) => (
  `${handlerId}${SORTING_HANDLER_SEP}${digitalId}`
);

export const extractSortingItemDigitalId = (sortingItemId) => (
  parseInt(sortingItemId.split(SORTING_HANDLER_SEP).pop(), 10)
);

export default {
  setItems,
  addItems,
  removeItem,
  removeHandler,
  moveItem,
  insertItem,
};
