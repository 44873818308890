import { FILE_UPLOAD_MAX_SIZE } from '../constants';

export default class FileUtil {
  /**
   * Check the file extension (server-side verification also required)
   * @param file File
   * @returns Boolean
   */
  static isCsvFile(file) {
    if (!file || !file.type) return false;
    return (/csv$/i).test(file.type);
  }

  /**
   * Check the file extension (server-side verification also required)
   * @param file File
   * @returns Boolean
   */
  static isJsonFile(file) {
    if (!file || !file.type) return false;
    return (/json$/i).test(file.type);
  }

  static convertBytesToMegaBytes = (bytes) => bytes / 1024 ** 2;

  static checkFileSize = (file, maxSize = FILE_UPLOAD_MAX_SIZE, sizeUnit = 'MB') => {
    if (sizeUnit === 'MB') {
      const fileSizeMB = this.convertBytesToMegaBytes(file.size);
      return fileSizeMB < maxSize;
    }
    return false;
  };
}
