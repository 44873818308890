import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import Page, { checkNotVerified } from '../components/Page';
import { nsOptions } from '../i18n';
import { USER_LICENSE_INTERN_KEY } from '../utils/license';
import { deObfuscateEmail } from '../utils/email';


const mapStateToProps = (state) => ({ user: state.auth.authUser });

@connect(mapStateToProps)
@withTranslation('', nsOptions)
class UnderVerification extends Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
    user: PropTypes.shape().isRequired,
  };

  constructor(props) {
    super(props);
    this.props = props;
  }

  render() {
    const { t, user } = this.props;

    return (
      <Page
        {...this.props}
        id="under-verif-zone"
        className="row align-items-center"
        title={t('common:nav.under-verification')}
        checkAuthorizations={[checkNotVerified]}
      >
        <div id="under-verif-text" className="col lead text-center mx-3 mx-lg-4 mx-xl-5">
          <div className="pb-2">
            <FontAwesomeIcon
              icon={['fal', 'user-clock']}
              transform="grow-4"
            />
          </div>
          {
            user.type === USER_LICENSE_INTERN_KEY
              ? (
                <>
                  {t('common:intern-being-check.part-1')}
                  <br />
                  {t('common:intern-being-check.part-2', { email: deObfuscateEmail('license', 'doqboard.com') })}
                </>
              ) : (
                <>
                  {t('common:user-being-check.part-1')}
                  <br />
                  {t('common:user-being-check.part-2')}
                </>
              )
          }
        </div>
      </Page>
    );
  }
}

export default UnderVerification;
