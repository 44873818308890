import { createReducer } from 'redux-starter-kit';
import { countriesActions } from '../actions';
import getStdDbModelReduxMapping from './std-db-model-reducers';

export const countriesInitialState = {};

const countriesReducer = createReducer(
  countriesInitialState,
  getStdDbModelReduxMapping(countriesActions),
);

export default countriesReducer;
