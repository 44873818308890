import { createAction } from 'redux-starter-kit';
import api from '../../api';
import misc from './misc';

const authSuccess = createAction('auth/authentication_success');
const authFailure = createAction('auth/authentication_failure');
const setLastAuthUserId = createAction('auth/set_last_auth_user_id');
const resyncAuthUser = createAction('auth/resync_auth_user');

const auth = (username, password) => async (dispatch) => {
  try {
    const user = await api.auth(username, password);
    dispatch(authSuccess(user));
    return user;
  } catch (error) {
    dispatch(authFailure());
    throw error;
  }
};

const tryAutoReAuth = () => async (dispatch) => {
  try {
    const user = await api.tryAutoReAuth();
    dispatch(authSuccess(user));
  } catch (error) {
    dispatch(authFailure());
    throw error;
  }
};

const logout = (resetStore = true) => async (dispatch, getState) => {
  try {
    await api.logout();
  } finally {
    let lastAuthUserId;
    if (getState().auth.authUser) {
      lastAuthUserId = getState().auth.authUser.id;
    }
    if (resetStore) dispatch(misc.storeReset());
    if (lastAuthUserId) dispatch(setLastAuthUserId(lastAuthUserId));
  }
};


export default {
  authSuccess,
  authFailure,
  setLastAuthUserId,
  resyncAuthUser,
  auth,
  tryAutoReAuth,
  logout,
};
