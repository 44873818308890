import {
  CALCULATION_VARIABLE_PREFIX, ELEMENT_TYPE_CALCULATION, ELEMENT_TYPE_DATE,
  ELEMENT_TYPE_MEASUREMENT, ELEMENT_TYPE_TIME,
} from '../constants';

export const RAW_VAR_ERROR = `${CALCULATION_VARIABLE_PREFIX}0`;
export const VAR_ERROR = `${RAW_VAR_ERROR}|err`;

export const isVariable = (item, extendedFormat = false) => {
  const regexp = new RegExp(
    `^${CALCULATION_VARIABLE_PREFIX}(.*)${extendedFormat ? '\\|(.*)' : ''}$`,
    'gi',
  );
  return regexp.test(item);
};

export const isFormulaOnlyVarDiff = (formula, extendedFormat = false) => {
  const filteredFormula = formula.filter((item) => !['(', ')'].includes(item));
  return filteredFormula.length === 3
    && filteredFormula[1] === '-'
    && isVariable(filteredFormula[0], extendedFormat)
    && isVariable(filteredFormula[2], extendedFormat);
};

export const formulaVariableToProjectElementId = (formulaVar) => (
  parseInt(formulaVar.replace(CALCULATION_VARIABLE_PREFIX, ''), 10)
);

export const extractVariableIdsFromFormula = (formula, extendedFormat = false) => (
  formula ? formula.filter((v) => isVariable(v, extendedFormat)).map((v) => (
    formulaVariableToProjectElementId(v)
  )) : []
);

export const isElementSupportedInCalculations = (element) => {
  switch (element.type) {
    case ELEMENT_TYPE_CALCULATION:
    case ELEMENT_TYPE_MEASUREMENT:
    case ELEMENT_TYPE_DATE:
    case ELEMENT_TYPE_TIME:
      return true;

    default:
      return false;
  }
};

export const getInvolvedCalculations = (projectElement, projectElements, elements) => {
  const element1 = elements.find((el) => el.id === projectElement.element);
  if (!isElementSupportedInCalculations(element1)) return [];

  // List calculations based on projectElement
  const involvedCalculations = [];
  projectElements.forEach((pEl) => {
    const element2 = elements.find((el) => el.id === pEl.element);
    if (element2.type !== ELEMENT_TYPE_CALCULATION) return;

    const formulaProjectElements = extractVariableIdsFromFormula(element2.formula);
    if (formulaProjectElements.includes(projectElement.id)) involvedCalculations.push(pEl);
  });

  // List calculations based on involved calculations recursively (descendants)
  const descendantCalculations = [];
  involvedCalculations.forEach((pEl) => {
    descendantCalculations.push(...getInvolvedCalculations(pEl, projectElements, elements));
  });

  return [...involvedCalculations, ...descendantCalculations];
};
