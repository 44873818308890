import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { nsOptions } from '../i18n';


@withTranslation('', nsOptions)
class DeviceUnsupported extends Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
    children: PropTypes.string.isRequired,
    className: PropTypes.string,
  };

  static defaultProps = {
    className: '',
  };

  render() {
    const { t, children, className } = this.props;
    return (
      <div className={`text-center text-muted disabled-feature-text ${className}`}>
        {t(children)}
      </div>
    );
  }
}


export default DeviceUnsupported;
