import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { withToastManager } from 'react-toast-notifications';
import { nsOptions } from '../i18n';

@withToastManager
@withTranslation('', nsOptions)
class StdLicenseMessage extends Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
    };
  }

  render() {
    const {
      t,
    } = this.props;

    return (
      <>
        <h4 className="text-center">
          { t('error:license.need-upgrade') }
        </h4>
        <br />
        <div className="text-center mt-2">
          { t('user:license.upgrade-info') }
        </div>
      </>
    );
  }
}


export default StdLicenseMessage;
