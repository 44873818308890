import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withToastManager } from 'react-toast-notifications';
import { Mutex } from 'async-mutex';
import { connect } from 'react-redux';
import { elementModalitiesActions, sortingHandlerActions, elementsActions } from '../redux/actions';
import { getSortingItemId } from '../redux/actions/sorting-handler';
import ErrorUtil from '../utils/ErrorUtil';
import Toast from '../utils/Toast';
import TimeoutHandler from '../utils/TimeoutHandler';

const withChoicesManagement = (ElementChoice) => {
  const mapStateToProps = (state, ownProps) => ({
    element: state.elements[ownProps.elementId],
    elementModalities: state.elementModalities,
  });

  const mapDispatchToProps = (dispatch, ownProps) => ({
    addModality: async (data) => dispatch(elementModalitiesActions.create(data, {
      admin: ownProps.admin,
    })),
    patchModality: async (id, data, params) => dispatch(elementModalitiesActions.patch(id, data, {
      ...params,
      admin: ownProps.admin,
    })),
    removeModality: async (id) => dispatch(elementModalitiesActions.remove(id, {
      admin: ownProps.admin,
    })),
    setSortedItems: (items) => dispatch(sortingHandlerActions.setItems({
      handlerId: ownProps.elementSortingHandlerId,
      items,
    })),
    addSortedItems: (items) => dispatch(sortingHandlerActions.addItems({
      handlerId: ownProps.elementSortingHandlerId,
      items,
    })),
    removeSortedItem: (item) => dispatch(sortingHandlerActions.removeItem({
      handlerId: ownProps.elementSortingHandlerId,
      item,
    })),
    readElement: async () => dispatch(elementsActions.read(ownProps.elementId, {
      admin: ownProps.admin,
    })),
  });

  @withToastManager
  @connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })
  class ElementWithChoicesManagement extends Component {
    static propTypes = {
      elementId: PropTypes.number.isRequired,
      element: PropTypes.shape().isRequired,
      elementModalities: PropTypes.shape().isRequired,
      elementSortingHandlerId: PropTypes.string.isRequired,
      addModality: PropTypes.func.isRequired,
      patchModality: PropTypes.func.isRequired,
      removeModality: PropTypes.func.isRequired,
      setSortedItems: PropTypes.func.isRequired,
      addSortedItems: PropTypes.func.isRequired,
      removeSortedItem: PropTypes.func.isRequired,
      readElement: PropTypes.func.isRequired,
    };

    constructor(props) {
      super(props);
      this.timeoutHandler = new TimeoutHandler();
      this.mutex = new Mutex();
    }

    componentDidMount() {
      const { setSortedItems, element, elementSortingHandlerId } = this.props;
      setSortedItems(element.values.map((val) => getSortingItemId(elementSortingHandlerId, val)));
    }

    checkLinks = () => {
      if (this.ref) this.ref.checkLinks();
    };

    addValue = async (name = undefined) => {
      const release = await this.mutex.acquire();
      const {
        addModality, addSortedItems, elementSortingHandlerId,
      } = this.props;

      try {
        const res = await addModality({
          element: this.props.elementId,
          name,
        });
        await addSortedItems([getSortingItemId(elementSortingHandlerId, res.id)]);
        if (this.ref && res && res.name === '') {
          this.ref.focusOnModalityNameInput(res.id);
        }
        Toast.success(this.props, 'error:valid.saved');
        return res;
      } catch (error) {
        ErrorUtil.handleCatched(this.props, error);
        return null;
      } finally {
        release();
      }
    };

    renameValue = (e, id) => {
      const name = e.target.value;
      const { patchModality } = this.props;
      return new Promise((resolve) => this.timeoutHandler.doAfterTimeout(async () => {
        try {
          await patchModality(id, { name });
          Toast.success(this.props, 'error:valid.saved');
          resolve(name);
        } catch (error) {
          ErrorUtil.handleCatched(this.props, error);
          resolve(null);
        }
      }, id, 1000));
    };

    patchValue = async (id, patch) => {
      const { patchModality } = this.props;
      try {
        await patchModality(id, patch);
        Toast.success(this.props, 'error:valid.saved');
      } catch (error) {
        ErrorUtil.handleCatched(this.props, error);
      }
    };

    removeValue = async (valueId) => {
      const { values } = this.props.element;
      const { removeModality, removeSortedItem, elementSortingHandlerId } = this.props;
      if (values.length > 2) {
        try {
          removeSortedItem(getSortingItemId(elementSortingHandlerId, valueId));
          await removeModality(valueId);
          Toast.success(this.props, 'error:valid.saved');
        } catch (error) {
          ErrorUtil.handleCatched(this.props, error);
        }
      }
    };

    render() {
      return (
        <ElementChoice
          {...this.props}
          choiceMethods={{
            addValue: this.addValue,
            renameValue: this.renameValue,
            patchValue: this.patchValue,
            removeValue: this.removeValue,
          }}
          ref={(ref) => { this.ref = ref; }}
        />
      );
    }
  }

  return ElementWithChoicesManagement;
};

export default withChoicesManagement;
