import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import AsyncSelect from 'react-select/async';
import AsyncCreatableSelect from 'react-select/async-creatable';
import { components as SelectComponents } from 'react-select';
import { nsOptions } from '../i18n';

export const defaultStyles = {
  option: (styles) => ({ ...styles, cursor: 'pointer' }),
  singleValue: (styles) => ({
    ...styles,
    padding: '3px 6.5px 3px 0px',
    borderRadius: '2px',
  }),
  control: (provided, state) => ({
    ...provided,
    borderColor: state.isFocused ? 'var(--newturquoise-1) !important' : 'var(--gray)',
  }),
  valueContainer: (provided) => ({
    ...provided,
    overflow: 'visible',
  }),
  placeholder: (provided, state) => ({
    ...provided,
    position: 'absolute',
    top: (state.hasValue || state.selectProps.inputValue) ? -3 : '50%',
    transition: 'top 0.1s, font-size 0.1s',
    fontSize: (state.hasValue || state.selectProps.inputValue) && 13,
    width: (state.hasValue || state.selectProps.inputValue) && 'max-content',
    color: 'var(--gray-dark)',
  }),
  noOptionsMessage: (provided) => ({
    ...provided,
    color: 'var(--primary)',
    backgroundColor: 'var(--newyellow-1-light)',
    textAlign: 'start',
    fontWeight: 600,
  }),
};

@withTranslation('', nsOptions)
class CustomAsyncSelect extends Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
    className: PropTypes.string,
    classNamePrefix: PropTypes.string,
    placeholder: PropTypes.string.isRequired,
    menuPlacement: PropTypes.string,
    focusedOptionMessage: PropTypes.string,
    focusedOptionClassName: PropTypes.string,
    noOptionsMessage: PropTypes.func.isRequired,
    loadOptions: PropTypes.func.isRequired,
    onChange: PropTypes.func,
    formatCreateLabel: PropTypes.func,
    isValidNewOption: PropTypes.func,
    components: PropTypes.shape(),
    styles: PropTypes.shape(),
    value: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.shape()), PropTypes.shape()]),
    creatable: PropTypes.bool,
    cacheOptions: PropTypes.bool,
    defaultOptions: PropTypes.oneOfType(
      [PropTypes.bool, PropTypes.arrayOf(PropTypes.shape())],
    ),
    openMenuOnFocus: PropTypes.bool,
    closeMenuOnSelect: PropTypes.bool,
    blurInputOnSelect: PropTypes.bool,
    backspaceRemovesValue: PropTypes.bool,
    hideSelectedOptions: PropTypes.bool,
    isMulti: PropTypes.bool,
    isClearable: PropTypes.bool,
    isDisabled: PropTypes.bool,
    isLoading: PropTypes.bool,
  };

  static defaultProps = {
    className: 'react-select',
    classNamePrefix: 'react-select',
    menuPlacement: 'auto',
    focusedOptionMessage: 'common:select.focused-option-message',
    focusedOptionClassName: 'bg-newyellow-1-light font-weight-semibold',
    onChange: () => {},
    formatCreateLabel: undefined,
    isValidNewOption: undefined,
    components: {},
    styles: {},
    value: null,
    creatable: false,
    cacheOptions: false,
    defaultOptions: true,
    openMenuOnFocus: true,
    closeMenuOnSelect: false,
    blurInputOnSelect: false,
    backspaceRemovesValue: false,
    hideSelectedOptions: true,
    isMulti: false,
    isClearable: false,
    isDisabled: false,
    isLoading: false,
  };

  getType = () => {
    const { creatable } = this.props;
    if (creatable) return AsyncCreatableSelect;
    return AsyncSelect;
  }

  onChange = async (newValue) => {
    const {
      isMulti, blurInputOnSelect, closeMenuOnSelect, onChange,
    } = this.props;
    await onChange(newValue);
    // The promise avoids losing focus when the toast is fired
    if (isMulti && !blurInputOnSelect && !closeMenuOnSelect && this.ref) {
      this.ref.focus();
    }
  }

  render() {
    const {
      t, isMulti, components, focusedOptionMessage, styles, focusedOptionClassName,
    } = this.props;
    const Type = this.getType();
    const {
      Input, ValueContainer, Placeholder, Option, SingleValue,
    } = SelectComponents;

    const CustomValueContainer = ({ children, ...props }) => (
      components.ValueContainer ? (
        <components.ValueContainer {...props} />
      ) : (
        <ValueContainer {...props}>
          <Placeholder {...props} isFocused={props.isFocused}>
            {props.selectProps.placeholder}
          </Placeholder>
          {children.map((child) => (
            child && child.type !== Placeholder ? child : null
          ))}
        </ValueContainer>
      )
    );

    const CustomInput = ({ ...props }) => (
      components.Input ? (
        <components.Input {...props} inputClassName="react-select__input" />
      ) : (
        <Input
          {...props}
          inputClassName="react-select__input"
        />
      )
    );

    const CustomOption = ({ ...props }) => {
      if (components.Option) {
        return <components.Option {...props} />;
      }
      const focusedOption = props.isFocused;
      const newOption = props.data.__isNew__;
      return (
        <Option
          {...props}
          className={focusedOption ? focusedOptionClassName : ''}
        >
          {props.data.label}
          {focusedOption && !newOption && (
            <span className="text-gray-dark ml-2 font-weight-normal">
              &nbsp;
              -
              &nbsp;
              {t(focusedOptionMessage)}
              &nbsp;
              -
            </span>
          )}
        </Option>
      );
    };

    const CustomSingleValue = ({ children, ...props }) => (
      <SingleValue {...props}>
        <span style={{
          backgroundColor: 'var(--newturquoise-1)',
          padding: '5px 8px',
          color: 'var(--primary)',
        }}
        >
          {children}
        </span>
      </SingleValue>
    );

    return (
      <Type
        {...this.props}
        onChange={this.onChange}
        styles={Object.values(styles).length ? styles : defaultStyles}
        components={{
          ValueContainer: isMulti ? CustomValueContainer : ValueContainer,
          Option: CustomOption,
          Input: CustomInput,
          SingleValue: CustomSingleValue,
        }}
        ref={(ref) => { this.ref = ref; }}
      />
    );
  }
}


export default CustomAsyncSelect;
