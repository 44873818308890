import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import cellEditFactory from 'react-bootstrap-table2-editor';
import { withTranslation } from 'react-i18next';
import { withToastManager } from 'react-toast-notifications';
import {
  Button, Form, FormGroup, Input, Label,
} from 'reactstrap';
import api from '../api';
import '../assets/css/table.css';
import Head from '../components/AdminHead';
import IconConfirm from '../components/IconConfirm';
import { CardLoader, InputLoader } from '../components/Loader';
import Pagination from '../components/Pagination';
import { languages, nsOptions } from '../i18n';
import ErrorUtil from '../utils/ErrorUtil';
import { getNestedValueFromStr, setNestedValueFromStr } from '../utils/object-util';
import Toast from '../utils/Toast';
import TimeoutHandler from '../utils/TimeoutHandler';

const PAGE_SIZE = 10;


@withToastManager
@withTranslation('', nsOptions)
class AdminSpecialties extends Component {
  static propTypes = {
    i18n: PropTypes.shape().isRequired,
    t: PropTypes.func.isRequired,
  };

  static getTableColumns(t, deleteSpecialty) {
    const translationsColumns = languages.map((lang) => ({
      dataField: lang,
      text: t(`locale:${lang}`),
      sort: true,
    }));

    return [
      {
        dataField: 'key',
        text: 'Identifier',
        sort: true,
      }, ...translationsColumns, {
        dataField: 'creator',
        text: 'Creator',
        sort: true,
        editable: false,
      }, {
        dataField: 'delete',
        text: 'Delete specialty',
        isDummyField: true,
        sort: false,
        headerStyle: {
          width: '75px',
        },
        editable: false,
        align: 'center',
        formatter: (cellContent, row) => (
          <IconConfirm
            tooltipContent="Delete specialty"
            tooltipDistance={18}
            onClick={() => deleteSpecialty(row.key)}
          >
            <button
              type="button"
              className="overmenu-item p-0"
            >
              <FontAwesomeIcon icon="trash-alt" />
            </button>
          </IconConfirm>
        )
        ,
      },
    ];
  }

  constructor(props) {
    super(props, 'Doqboard specialties');
    this.state = {
      columns: AdminSpecialties.getTableColumns(this.props.t, this.deleteSpecialty),
      currentPageSpecialties: [],
      specialtiesCount: 0,
      dataLoading: false,
      searchLoading: false,
      searchValue: '',
    };
    this.specialties = [];
    this.translations = {};
    this.currentPage = 1;
    this.timeoutHandler = new TimeoutHandler();
  }

  componentDidMount() {
    this.setState({ dataLoading: true });
    this.fetchTranslations().then(() => {
      this.updateTableColumns();
      this.fetchSpecialties(this.currentPage)
        .then(() => this.setState({ dataLoading: false }))
        .catch((err) => {
          console.error(err);
          this.setState({ dataLoading: false });
        });
    }).catch((err) => {
      console.error(err);
      this.setState({ dataLoading: false });
    });
  }

  getSpecialtiesMatching(search) {
    let specialties = {};
    if (search && search !== '') {
      Object.entries(this.translations).forEach((lang) => {
        Object.entries(lang[1]).forEach((translation) => {
          if (translation[1].toLowerCase().includes(search.toLowerCase())) {
            const test = { [translation[0]]: translation[1] };
            specialties = { ...specialties, ...test };
          }
        });
      });
    }
    return Object.keys(specialties);
  }

  getSpecialties = (page) => {
    const { specialtiesCount } = this.state;

    this.currentPage = Math.max(Math.min(page, Math.ceil(specialtiesCount / PAGE_SIZE)), 1);

    // Fetch specialties for 'page'
    const offset = (this.currentPage - 1) * PAGE_SIZE;
    this.setState({
      currentPageSpecialties: this.specialties.slice(offset, (offset + PAGE_SIZE)),
    });
  };

  fetchTranslations = async (langList = languages) => {
    try {
      if (!this.translations) this.translations = {};
      const { i18n } = this.props;
      const promises = [];
      langList.forEach((lang) => {
        promises.push(new Promise((resolve) => {
          i18n.reloadResources(lang, 'specialty', resolve);
        }));
      });
      await Promise.all(promises);
      langList.forEach((lang) => {
        this.translations[lang] = i18n.getResourceBundle(lang, 'specialty');
      });
    } catch (error) {
      ErrorUtil.handleCatched(this.props, error, false);
    }
  };

  updateTableColumns = () => {
    this.setState({
      columns: AdminSpecialties.getTableColumns(this.props.t, this.deleteSpecialty),
    });
  };

  fetchSpecialties = async (page) => {
    const query = {
      admin: true,
      ordering: '-created_at',
    };

    // Filter specialties if needed
    const { searchValue } = this.state;
    if (searchValue && searchValue !== '') {
      query.key__in = this.getSpecialtiesMatching(searchValue);
    }

    try {
      // Get all specialties (no pagination for this service)
      const res = await api.list('specialties', query);

      // Format incoming data to feed the table
      const tempSpecialtiesList = [];
      res.forEach((specialty) => {
        const tempSpecialty = {};
        this.state.columns.forEach((col) => {
          // Ignore translated values (ie specialties labels)
          if (!languages.find((lang) => lang === col.dataField
            && col.isDummyField !== true)) {
            const value = getNestedValueFromStr(specialty, col.dataField);
            setNestedValueFromStr(tempSpecialty, col.dataField, value || '');
          }
        });
        tempSpecialtiesList.push(tempSpecialty);
      });

      // Translate specialties labels
      languages.forEach((lang) => { // eslint-disable-line no-restricted-syntax
        tempSpecialtiesList.forEach((specialty, index) => {
          tempSpecialtiesList[index][lang] = this.translations[lang][specialty.key];
        });
      });

      // Set the new 'specialties' state
      this.specialties = tempSpecialtiesList;
      this.setState({ specialtiesCount: res.length },
        () => { this.getSpecialties(page); });
    } catch (error) {
      ErrorUtil.handleCatched(this.props, error, false);
    }
  };

  addSpecialties = async () => {
    const specialties = languages.map((lang) => (
      this.state[`${lang}Specialties`].split(';')
    ));

    specialties.forEach((lang, index1) => lang.forEach((specialty, index2) => {
      specialties[index1][index2] = specialty.trim();
    }));

    const count = specialties[0].length;
    if (specialties.every((specialty) => specialty.length === count)) {
      try {
        const promises = [];
        for (let i = 0; i < count; i += 1) {
          const languageLabels = {};
          languages.forEach((lang, index) => { languageLabels[lang] = specialties[index][i]; });
          const namespace = 'specialty';

          promises.push(api.create('specialties', {
            active: true,
            namespace,
            ...languageLabels,
          }, { admin: true }));
        }
        this.setState({ addEnabled: false });
        await Promise.all(promises);
        await this.fetchTranslations();
        await this.fetchSpecialties(this.currentPage);
        Toast.success(this.props, 'error:valid.saved');
      } catch (error) {
        ErrorUtil.handleCatched(this.props, error);
      } finally {
        this.setState({ addEnabled: true });
      }
    } else {
      const error = new Error('Each language must have the same number of specialties');
      ErrorUtil.handleCatched(this.props, error);
    }
  };

  saveSpecialty = async (specialtyId, field, value) => {
    try {
      if (field === 'validated' || languages.find((lang) => lang === field)) {
        await api.partial_update('specialties', specialtyId, { [field]: value }, { admin: true });
        this.fetchTranslations([field]);
      } else {
        throw new Error(`Save not supported for that field ('${field}')`);
      }

      Toast.success(this.props, 'error:valid.saved');
      return true;
    } catch (error) {
      ErrorUtil.handleCatched(this.props, error);
      return false;
    }
  };

  deleteSpecialty = async (specialtyId) => {
    try {
      await api.delete('specialties', specialtyId, { admin: true });
      Toast.success(this.props, 'error:valid.deleted');
      this.fetchSpecialties(this.currentPage);
    } catch (error) {
      ErrorUtil.handleCatched(this.props, error);
    }
  };

  handleSpecialtiesSelectionChange = (language, specialties) => {
    const newState = { ...this.state };
    newState[`${language}Specialties`] = specialties;
    const addEnabled = languages.every((lang) => newState[`${lang}Specialties`]);
    this.setState({
      ...newState,
      addEnabled,
    });
  };

  handleSearch = (event) => {
    const { value } = event.target;
    this.timeoutHandler.doAfterTimeoutWithClbk((startTimeout) => {
      this.setState({ searchValue: value }, () => startTimeout(() => {
        this.fetchSpecialties(1);
      }));
    }, 'default', 200);
  };

  render() {
    const {
      addEnabled, searchValue, searchLoading, dataLoading,
      currentPageSpecialties, columns, specialtiesCount,
    } = this.state;
    const cellEdit = cellEditFactory({
      mode: 'dbclick',
      blurToSave: true,
      // eslint-disable-next-line no-unused-vars
      beforeSaveCell: (oldValue, newValue, row, column, done) => {
        if (oldValue !== newValue) {
          this.saveSpecialty(row.key, column.dataField, newValue)
            .then((ret) => { done(ret); });
        }
        return { async: true };
      },
    });

    return (
      <div>
        <Head
          title="Doqboard specialties"
          subTitle="Find here Doqboard specialties."
          admin
          {...this.props}
        />
        <div className="dashboard-content">
          <Form>
            <FormGroup>
              <Label>Add specialties</Label>
              {
                languages.map((lang) => (
                  <div key={lang} className="row mb-2">
                    <div className="col-1 pt-2">
                      <b>
                        { `${lang} :` }
                      </b>
                    </div>
                    <div className="col-11">
                      <Input
                        placeholder="Add here your specialties separated by semi colon"
                        value={this.state[`${lang}Specialties`] || ''}
                        onChange={(e) => {
                          this.handleSpecialtiesSelectionChange(lang, e.target.value);
                        }}
                      />
                    </div>
                  </div>
                ))
              }
              <div className="mt-4 mb-5">
                <Button
                  color="primary"
                  disabled={!addEnabled}
                  onClick={this.addSpecialties}
                >
                  Add
                </Button>
              </div>
            </FormGroup>
            <FormGroup>
              <Label>Search specialties</Label>
              <div className="row mb-5">
                <div className="col-12 col-md-6 contains-loader">
                  <Input
                    type="text"
                    id="projectse-input"
                    className="d-block"
                    placeholder="Enter your pattern..."
                    value={searchValue}
                    onInput={this.handleSearch}
                    onChange={() => {}}
                  />
                  {
                    searchLoading ? (
                      <div className="d-none d-md-block">
                        <InputLoader />
                      </div>
                    ) : null
                  }
                </div>
              </div>
            </FormGroup>
          </Form>
          <div className="contains-loader">
            {
              dataLoading ? (
                <CardLoader />
              ) : null
            }
            <BootstrapTable
              keyField="key"
              data={currentPageSpecialties}
              columns={columns}
              cellEdit={cellEdit}
              headerClasses="admin_table"
              wrapperClasses="admin_table"
              bootstrap4
              hover
            />
          </div>
        </div>
        <nav className="my-5">
          <Pagination
            page={this.currentPage}
            count={specialtiesCount}
            pageSize={PAGE_SIZE}
            action={this.getSpecialties}
          />
        </nav>
      </div>
    );
  }
}


export default AdminSpecialties;
