import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import AuthContainer from '../components/AuthContainer';
import Page from '../components/Page';
import api from '../api';
import { nsOptions } from '../i18n';


@withTranslation('', nsOptions)
class AccountValidated extends Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    api.logout();
  }

  render() {
    const { t } = this.props;
    return (
      <Page
        {...this.props}
        title={t('auth:email-validated')}
        checkAuthorizations={[]}
      >
        <AuthContainer
          onSubmit={this.handleFormSubmit}
          top={{
            info: t('auth:pages.email-validated.info'),
          }}
          bottom={{
            navContent: (
              <div className="row justify-content-center">
                <div className="col-auto">
                  <Link to="/" className="btn btn-newblue-1">
                    {t('auth:button.login')}
                  </Link>
                </div>
              </div>
            ),
          }}
        />
      </Page>
    );
  }
}


export default AccountValidated;
