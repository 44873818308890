import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { withToastManager } from 'react-toast-notifications';
import { Mutex } from 'async-mutex';
import { connect } from 'react-redux';
import memoize from 'memoize-one';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import api from '../api';
import {
  projectPagesActions, elementsActions, projectElementsActions,
  elementLinksActions, elementModalitiesActions,
  sortingHandlerActions,
} from '../redux/actions';
import {
  getSortingHandlerId, getSortingItemId, extractSortingItemDigitalId,
} from '../redux/actions/sorting-handler';
import { nsOptions } from '../i18n';
import ElementUtil from '../utils/ElementUtil';
import Toast from '../utils/Toast';
import ElementAddBox from './ElementAddBox';
import { CardLoader } from './Loader';
import Help from './Help';
import FAQLink from './FAQLink';
import ErrorUtil from '../utils/ErrorUtil';
import { hasPermission } from '../utils/data-util';
import withLicenseMsgModal from './withLicenseMsgModal';
import DraggableComponent from './DraggableComponent';
import DroppableComponent from './DroppableComponent';
import FormTitle from './FormTitle';
import {
  CAN_EDIT_FORM_AND_DOCUMENTATIONS, ELEMENT_TYPE_DIVIDER, ELEMENT_TYPE_MODULE,
  ELEMENT_TYPE_MULTIPLE_CHOICES, ELEMENT_TYPE_SPACER, ELEMENT_TYPE_TITLE,
  ELEMENT_TYPE_UNIQUE_CHOICE,
} from '../constants';
import { getRootProjectElements } from '../utils/links';
import fromReduxState from '../utils/redux';
import NewTooltip from './NewTooltip';
import downloadEndpoint, { UTF8_BOM } from '../utils/downloadEndpoint';
import { NewBadge } from './Badges';


const mapStateToProps = (state, ownProps) => ({
  user: state.auth.authUser,
  page: state.projectPages[ownProps.id],
  projectElements: state.projectElements,
  elements: state.elements,
  elementLinks: state.elementLinks,
  projectUser: Object.values(state.projectUsers).find((pUser) => (
    pUser.user && pUser.user.id === state.auth.authUser.id
      && pUser.project === ownProps.project.id
  )),
  sortedItems: state.sortingHandler[ownProps.formSortingHandlerId],
  modalOpened: state.dragAndDrop.modalsOpened > 0,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  fetchPage: async () => dispatch(projectPagesActions.read(ownProps.id, {
    admin: ownProps.admin,
  })),
  addElement: async (data) => dispatch(elementsActions.create(data, {
    admin: ownProps.admin,
  })),
  resyncElements: async () => dispatch(elementsActions.resync({
    admin: ownProps.admin,
  })),
  addProjectElement: async (data) => dispatch(projectElementsActions.create(data, {
    admin: ownProps.admin,
  })),
  removeProjectElement: async (id) => dispatch(projectElementsActions.remove(id, {
    admin: ownProps.admin,
  })),
  resyncProjectElements: async () => dispatch(projectElementsActions.resync({
    admin: ownProps.admin,
  })),
  resyncElementLinks: async () => dispatch(elementLinksActions.resync({
    admin: ownProps.admin,
  })),
  addSortedItems: (items) => dispatch(sortingHandlerActions.addItems({
    handlerId: ownProps.formSortingHandlerId,
    items,
  })),
  removeSortedItem: (item) => dispatch(sortingHandlerActions.removeItem({
    handlerId: ownProps.formSortingHandlerId,
    item,
  })),
  fetchElement: async (id) => dispatch(elementsActions.read(id, {
    admin: ownProps.admin,
  })),
  fetchProjectElement: async (id) => dispatch(projectElementsActions.read(id, {
    admin: ownProps.admin,
  })),
  insertSortedItem: (previousItem, item) => dispatch(sortingHandlerActions.insertItem({
    handlerId: ownProps.formSortingHandlerId,
    previousItem,
    item,
  })),
  fetchElementModalities: async (elements) => dispatch(
    elementModalitiesActions.list({
      element__in: Object.values(elements).map((el) => el.id),
      admin: ownProps.admin,
    }),
  ),
  fetchElementLinks: async (projectElements) => dispatch(elementLinksActions.listByProjectElements(
    Object.values(projectElements).map((pEl) => pEl.id), { admin: ownProps.admin }, { pagination: 'no' },
  )),
  fetchProjectElements: async (pages) => dispatch(projectElementsActions.list({
    project_page__in: Object.values(pages).map((p) => p.key),
    admin: ownProps.admin,
  }, { pagination: 'no' })),
  fetchElements: async (projectElements) => dispatch(elementsActions.list({
    id__in: Object.values(projectElements).map((pEl) => pEl.element),
    admin: ownProps.admin,
  }, { pagination: 'no' })),
});


@withToastManager
@connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })
@withTranslation('', nsOptions)
@withLicenseMsgModal()
class ProjectForm extends Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
    id: PropTypes.number.isRequired,
    formSortingHandlerId: PropTypes.string.isRequired,
    project: PropTypes.shape().isRequired,
    pages: PropTypes.arrayOf(PropTypes.shape()).isRequired,
    addTab: PropTypes.func.isRequired,
    cloneTab: PropTypes.func.isRequired,
    renameTab: PropTypes.func.isRequired,
    removeTab: PropTypes.func.isRequired,
    match: PropTypes.shape({
      url: PropTypes.string.isRequired,
      params: PropTypes.shape().isRequired,
    }).isRequired,
    location: PropTypes.shape().isRequired,
    admin: PropTypes.bool,
    editionMode: PropTypes.bool,
    onEditionToggle: PropTypes.func.isRequired,
    user: PropTypes.shape().isRequired,
    page: PropTypes.shape().isRequired,
    projectElements: PropTypes.shape().isRequired,
    elements: PropTypes.shape().isRequired,
    elementLinks: PropTypes.shape().isRequired,
    projectUser: PropTypes.shape(),
    sortedItems: PropTypes.arrayOf(PropTypes.string).isRequired,
    modalOpened: PropTypes.bool.isRequired,
    fetchPage: PropTypes.func.isRequired,
    addElement: PropTypes.func.isRequired,
    resyncElements: PropTypes.func.isRequired,
    addProjectElement: PropTypes.func.isRequired,
    removeProjectElement: PropTypes.func.isRequired,
    resyncProjectElements: PropTypes.func.isRequired,
    resyncElementLinks: PropTypes.func.isRequired,
    addSortedItems: PropTypes.func.isRequired,
    removeSortedItem: PropTypes.func.isRequired,
    fetchElement: PropTypes.func.isRequired,
    fetchProjectElement: PropTypes.func.isRequired,
    insertSortedItem: PropTypes.func.isRequired,
    fetchElementModalities: PropTypes.func.isRequired,
    fetchElementLinks: PropTypes.func.isRequired,
    fetchProjectElements: PropTypes.func.isRequired,
    fetchElements: PropTypes.func.isRequired,
  };

  static defaultProps = {
    admin: false,
    editionMode: false,
    projectUser: undefined,
  };

  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      ready: false,
    };
    this.page = null;
    this.mounted = false;
    this.elementRefs = {};
    this.loadContent = memoize(this.load);
    this.mutex = new Mutex();
    this.toPElementsArray = fromReduxState((projectElements, id) => (
      Object.values(projectElements).filter((pEl) => pEl.project_page === id)
    ));
    this.toRootPElsArray = fromReduxState((projectElements, elementLinks) => getRootProjectElements(
      projectElements,
      Object.values(elementLinks),
    ));
  }

  componentDidMount() {
    this.mounted = true;
    this.initData();
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  initData = async () => {
    const {
      page, fetchPage, projectElements: pElements, elements, modalOpened, editionMode, id,
    } = this.props;
    const projectElements = this.toPElementsArray(pElements, id);

    if (!page) await fetchPage();

    this.setState({
      loading: false,
      ready: true,
    }, () => (
      this.load(editionMode, projectElements, elements, this.props.sortedItems, modalOpened)
    ));
  };

  addElement = async (data, tabId) => {
    this.setState({ loading: true });
    const release = await this.mutex.acquire();
    try {
      const {
        addElement, addProjectElement, addSortedItems, formSortingHandlerId, t,
      } = this.props;
      const newData = { ...data };
      if (ElementUtil.isElementStatic(data.type) && data.type !== ELEMENT_TYPE_TITLE) {
        newData.name = ElementUtil.getStaticElementTypeName(data.type, t);
      }
      const element = await addElement(newData);
      const res = await addProjectElement({ element: element.id, project_page: tabId });
      await addSortedItems([getSortingItemId(formSortingHandlerId, res.id)]);

      // Try to focus on the variable name
      if (this.elementRefs[res.id]) {
        const elBaseRef = this.elementRefs[res.id].elBaseRef
          || this.elementRefs[res.id].ref.elBaseRef;
        if (elBaseRef && elBaseRef.props && elBaseRef.props.element
          && elBaseRef.props.element.name === '') {
          elBaseRef.focusOnNameInput();
        }
      }
      Toast.success(this.props, 'error:valid.saved');
    } catch (error) {
      ErrorUtil.handleCatched(this.props, error);
    } finally {
      release();
      this.setState({ loading: false });
    }
  };

  deleteElement = async (projectElement, formSortingHandlerId) => {
    this.setState({ loading: true });
    const {
      removeProjectElement, resyncElements, resyncProjectElements, removeSortedItem,
      resyncElementLinks,
    } = this.props;
    try {
      removeSortedItem(getSortingItemId(formSortingHandlerId, projectElement.id));
      await removeProjectElement(projectElement.id);
      await Promise.all([
        resyncElements(), // Associated element should have been deleted
        resyncProjectElements(), // Sorting value of other project elements may have changed
        resyncElementLinks(), // Links could have been removed in cascade
      ]);
      Toast.success(this.props, 'error:valid.saved');
    } catch (error) {
      ErrorUtil.handleCatched(this.props, error);
    } finally {
      this.setState({ loading: false });
    }
  };

  cloneElement = async (projectElement) => {
    this.setState({ loading: true });
    const release = await this.mutex.acquire();
    const {
      fetchElement, formSortingHandlerId, insertSortedItem, fetchElementLinks,
      fetchElementModalities, fetchProjectElements, fetchElements, admin,
    } = this.props;
    try {
      // Request cloning to the api
      const res = await api.create('clone-variable', {
        project_element: projectElement.id,
        related_objects: true,
      }, { admin });

      const clonedProjectElement = JSON.parse(res.project_element);
      const elementType = clonedProjectElement.element_type;
      const projectElements = await fetchProjectElements(this.props.pages);

      if (elementType === ELEMENT_TYPE_MODULE) {
        if (Object.keys(projectElements).length > 0) {
          const promRes = await Promise.all([
            fetchElements(projectElements),
            fetchElementLinks(projectElements),
          ]);
          const elements = promRes[0];
          await fetchElementModalities(elements);
        }
      } else {
        await fetchElement(clonedProjectElement.element);
        if ([ELEMENT_TYPE_MULTIPLE_CHOICES, ELEMENT_TYPE_UNIQUE_CHOICE].includes(elementType)) {
          await fetchElementModalities(this.props.elements);
        }
        await fetchElementLinks(projectElements);
      }

      const previousItem = getSortingItemId(formSortingHandlerId, projectElement.id);
      const item = getSortingItemId(formSortingHandlerId, clonedProjectElement.id);
      insertSortedItem(previousItem, item);

      Toast.success(this.props, 'error:valid.cloned');
    } catch (error) {
      ErrorUtil.handleCatched(this.props, error);
    } finally {
      release();
      this.setState({ loading: false });
    }
  };

  exportData = async () => {
    const { project, admin } = this.props;
    try {
      await downloadEndpoint(
        `projects/${project.id}/data-dictionary`,
        'get',
        { admin },
        undefined,
        UTF8_BOM,
      );
    } catch (error) {
      ErrorUtil.handleCatched(this.props, error, false);
    }
  }

  load = (editionMode, projectElements, elements, sortedItems, modalOpened) => {
    const {
      project, formSortingHandlerId, projectElements: pElements, elementLinks,
    } = this.props;
    const rootProjectElements = this.toRootPElsArray(pElements, elementLinks);
    const tabId = this.props.id;
    this.elementRefs = {};
    const getElement = (index, Type, pElement, ref = null) => {
      const { id } = pElement;
      const sortingItemId = getSortingItemId(formSortingHandlerId, pElement.id);
      return Type ? (
        <DraggableComponent
          key={sortingItemId}
          draggableId={sortingItemId}
          droppableId={formSortingHandlerId}
          index={index}
          isDragDisabled={modalOpened || !editionMode}
        >
          <Type
            {...this.props}
            key={id}
            isEditMode={editionMode}
            isReadOnly={!editionMode}
            elementId={pElement.element}
            rootProjectElements={rootProjectElements}
            projectElementId={id}
            project={project}
            moduleInstanceId={null}
            parent={project}
            formSortingHandlerId={formSortingHandlerId}
            elementSortingHandlerId={getSortingHandlerId('element', id)}
            methods={{
              reload: () => (
                this.load(editionMode, projectElements, elements, sortedItems, modalOpened)
              ),
              remove: () => this.deleteElement(pElement, formSortingHandlerId),
              clone: () => this.cloneElement(pElement),
            }}
            tabId={tabId}
            ref={ref}
          />
        </DraggableComponent>
      ) : null;
    };

    const content = sortedItems.map((pElId, index) => {
      const dId = extractSortingItemDigitalId(pElId);
      const pElement = projectElements.find((pEl) => pEl.id === dId);
      if (pElement) {
        const { id, element: elementId } = pElement;
        const element = elements[elementId];
        const Type = ElementUtil.getElementType(element);
        const elementType = element.type;
        const refClbk = ![ELEMENT_TYPE_SPACER, ELEMENT_TYPE_DIVIDER].includes(elementType)
          ? (ref) => { this.elementRefs[id] = ref; } : null;
        return getElement(index, Type, pElement, refClbk);
      }
      // May happen when moving an element to another page and opening that page before
      // project elments have been resynchronized
      return null;
    });

    return content;
  };

  render() {
    const {
      t, pages, projectElements: pElements, elements, sortedItems, formSortingHandlerId,
      modalOpened, addTab, renameTab, removeTab, cloneTab, id, admin, match, projectUser,
      onEditionToggle, editionMode, project,
    } = this.props;
    const projectElements = this.toPElementsArray(pElements, id);
    const { ready, loading } = this.state;
    const content = ready ? this.loadContent(
      editionMode,
      projectElements,
      elements,
      sortedItems,
      modalOpened,
    ) : null;
    const canEdit = admin || hasPermission(projectUser, CAN_EDIT_FORM_AND_DOCUMENTATIONS);

    return ready ? (
      <div className="position-relative">
        <h4 className="font-weight-normal mt-0 mb-3">
          <div className="row align-items-center">
            <div className="col-auto">
              {t('project:nav.form')}
            </div>
            <div
              className="col-auto px-0"
              style={{ fontSize: '1rem', paddingTop: '0.15rem' }}
            >
              <Help interactive>
                <FAQLink text="common:discover-features" />
              </Help>
            </div>
            <div className="col d-flex justify-content-end">
              <NewTooltip
                content={t('project:export-data')}
              >
                <button
                  className="bg-transparent border-0 text-primary"
                  onClick={this.exportData}
                >
                  <FontAwesomeIcon
                    icon={['fas', 'book']}
                    color="primary"
                    transform="grow-1"
                  />
                </button>
              </NewTooltip>
              <NewBadge className="data-dico-badge" />
            </div>
          </div>
        </h4>
        <div
          key={`content-${id}`}
          className="element-tab mt-4 position-relative"
          id={id}
        >
          { loading && <CardLoader /> }
          <div className="row justify-content-center align-items-center">
            <div className="col">
              <FormTitle
                admin={admin}
                addTab={addTab}
                editLinks={() => {}}
                renameTab={renameTab}
                removeTab={removeTab}
                cloneTab={cloneTab}
                editionMode={editionMode}
                showEditionBtn={canEdit}
                onEditionToggle={onEditionToggle}
                currentPageId={id}
                pages={pages}
                match={match}
                project={project}
              />
            </div>
          </div>
          <div className="row justify-content-center form-elements">
            <div className="col-12">
              <div className={`element-edit-form mt-0 ${editionMode ? '' : 'read-only-form'}`}>
                <DroppableComponent
                  droppableId={formSortingHandlerId}
                  type="FORM"
                  isDropDisabled={modalOpened}
                >
                  {content}
                </DroppableComponent>
              </div>
            </div>
            <div className="col-12 col-lg-11 col-xl-10 mt-4">
              {
                editionMode && (
                  <ElementAddBox
                    addElement={(data) => this.addElement(data, id,
                      () => (
                        this.loadContent(
                          editionMode,
                          projectElements,
                          elements,
                          sortedItems,
                          modalOpened,
                        )
                      ))}
                  />
                )
              }
            </div>
          </div>
        </div>
      </div>
    ) : (<CardLoader />);
  }
}


export default ProjectForm;
