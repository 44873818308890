import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { miscActions } from '../redux/actions';
import {
  isAuthenticated, isVerified,
} from '../utils/data-util';


const mapStateToProps = (state) => ({
  user: state.auth.authUser,
  pendingUrl: state.misc.pendingUrl,
});

const mapDispatchToProps = (dispatch) => ({
  clearPendingUrl: () => dispatch(miscActions.clearPendingUrl()),
});


@connect(mapStateToProps, mapDispatchToProps)
class Home extends Component {
  static propTypes = {
    user: PropTypes.shape(),
    clearPendingUrl: PropTypes.func.isRequired,
    pendingUrl: PropTypes.string,
    location: PropTypes.shape({
      search: PropTypes.string,
    }).isRequired,
  };

  static defaultProps = {
    user: undefined,
    pendingUrl: undefined,
  };

  getRedirection() {
    const { user, location } = this.props;
    if (user && isAuthenticated(user)) {
      if (!isVerified(user)) {
        return '/dashboard/under-verification';
      }
      const { pendingUrl, clearPendingUrl } = this.props;
      if (pendingUrl) {
        clearPendingUrl();
        return pendingUrl;
      }
      return '/dashboard/home';
    }
    return `/auth/login${location.search}`;
  }

  render() {
    return (<Redirect to={this.getRedirection()} />);
  }
}


export default Home;
