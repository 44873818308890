import store from '../redux/store';
import { authActions } from '../redux/actions';
import ErrorUtil from './ErrorUtil';
import Toast from './Toast';

// eslint-disable-next-line import/prefer-default-export
export const logout = async (props) => {
  try {
    await store.dispatch(authActions.logout());
    Toast.success(props, 'error:valid.success');
    props.history.push('/');
  } catch (error) {
    ErrorUtil.handleCatched(props, error, false);
  }
};
