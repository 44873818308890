const downloadHandlers = {};

export default (blob, fileName, handlerId) => {
  const handler = downloadHandlers[handlerId];

  if (handler) window.URL.revokeObjectURL(handler);

  downloadHandlers[handlerId] = window.URL.createObjectURL(blob);

  // Create a temporary link element pointing onto URL object
  const tempElement = document.createElement('a');
  tempElement.setAttribute('id', handlerId);
  tempElement.href = downloadHandlers[handlerId];
  tempElement.download = fileName;
  document.body.appendChild(tempElement); // Required for this to work in FireFox

  // Start the download programmatically
  tempElement.click();

  // Remove the temporary element (if the removing is premature do it at the same way
  // as revokeObjectURL)
  document.body.removeChild(tempElement);
};
