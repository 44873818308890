import { createReducer } from 'redux-starter-kit';
import { userLicensesActions } from '../actions';
import getStdDbModelReduxMapping from './std-db-model-reducers';

export const userLicensesInitialState = {};

const userLicensesReducer = createReducer(
  userLicensesInitialState,
  getStdDbModelReduxMapping(userLicensesActions),
);

export default userLicensesReducer;
