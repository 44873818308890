import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Label } from 'reactstrap';
import { childrenPropTypes } from '../utils/generic-prop-types';

// eslint-disable-next-line react/prefer-stateless-function
class RowContent extends Component {
  static propTypes = {
    label: PropTypes.oneOfType([PropTypes.string, childrenPropTypes()]),
    className: PropTypes.string,
    contentClassName: PropTypes.string,
    children: childrenPropTypes().isRequired,
    show: PropTypes.bool,
  };

  static defaultProps = {
    className: '',
    contentClassName: 'col-12 col-md-8 col-lg-7 col-xl-6 mt-2',
    show: true,
    label: undefined,
  };

  render() {
    const {
      className, contentClassName, label, children, show,
    } = this.props;

    return show && (
      <div className={`row field-row${className ? ` ${className}` : ''}`}>
        {
          label && (
            <div className="col-12 col-sm-12 col-md-4 col-lg-3 col-xl-2">
              <Label className="field-label w-100 mt-2">
                {label}
              </Label>
            </div>
          )
        }
        <div className={contentClassName}>
          {children}
        </div>
      </div>
    );
  }
}


export default RowContent;
