import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { withToastManager } from 'react-toast-notifications';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { Label } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { teamsActions, teamMembersActions } from '../redux/actions';
import { nsOptions } from '../i18n';
import api from '../api';
import RowContent from './RowContent';
import LabeledInput from './LabeledInput';
import LabeledSelect from './LabeledSelect';
import { CardLoader } from './Loader';
import withLicenseMsgModal from './withLicenseMsgModal';
import ButtonConfirm from './ButtonConfirm';
import { DelMsgModal } from './MessageModal';
import { InvitationAvatar } from './avatar';
import EditButton from './EditButton';
import InvitationTooltipBase from './InvitationTooltipBase';
import Help from './Help';
import UserSelect from './UserSelect';
import SpecialtySelect from './SpecialtySelect';
import ErrorUtil from '../utils/ErrorUtil';
import { emailListToArray, deObfuscateEmail, formatPasteEmailList } from '../utils/email';
import Toast from '../utils/Toast';
import TimeoutHandler from '../utils/TimeoutHandler';
import { childrenPropTypes } from '../utils/generic-prop-types';
import TextUtil from '../utils/TextUtil';

import { DataLocker } from '../utils/data-util';
import { TEAM_LICENSE_PRO_KEY, TEAM_LICENSE_EXPERT_KEY } from '../utils/license';
import {
  LOCK_MODEL_TEAM_MEMBER, LOCK_MODEL_TEAM, LOCK_SECTION, LOCK_SECTION_NAME, LOCK_TOAST_TIMEOUT_S,
  TEAM_MEMBER_USUAL,
  NO_TEAM_LEADER,
} from '../constants';
import NewTooltip from './NewTooltip';
import fromReduxState from '../utils/redux';


const ROLE_LEADER = 'leader';
const ROLE_MEMBER = 'member';

@withTranslation('', nsOptions)
class MemberRoleSelect extends Component {
  static propTypes = {
    className: PropTypes.string,
    value: PropTypes.string,
    defaultValue: PropTypes.string,
    t: PropTypes.func.isRequired,
    selectTextColor: PropTypes.string,
    readOnly: PropTypes.bool,
    label: PropTypes.string,
    onChange: PropTypes.func.isRequired,
  };

  static defaultProps = {
    className: '',
    value: undefined,
    defaultValue: undefined,
    selectTextColor: undefined,
    readOnly: true,
    label: undefined,
  };

  constructor(props) {
    super(props);
    this.state = {
      internalValue: props.defaultValue,
    };
  }

  onChange = async (e) => {
    const { internalValue } = this.state;
    const prevInternalValue = internalValue;

    if (internalValue) {
      this.setState({ internalValue: e.target.value });
    }
    const success = await this.props.onChange(e);

    if (!success && prevInternalValue) {
      // Undo change
      this.setState({ internalValue: prevInternalValue });
    }
  };

  render() {
    const {
      t, className, value, selectTextColor, readOnly, label,
    } = this.props;
    const { internalValue } = this.state;
    const effectiveValue = internalValue || value;

    return (
      <LabeledSelect
        name="role"
        className={className}
        label={label}
        selectClassName={`custom-select${selectTextColor ? ` ${selectTextColor}` : ''}`}
        colSelectClassName="col-12 col-sm-12 col-md-10 col-lg-8 col-xl-5"
        value={effectiveValue}
        disabled={readOnly}
        hideOptionalLabel
        onChange={this.onChange}
      >
        <option
          key={ROLE_MEMBER}
          value={ROLE_MEMBER}
        >
          { t('user:team.role.member') }
        </option>
        <option
          key={ROLE_LEADER}
          value={ROLE_LEADER}
        >
          { t('user:team.role.leader') }
        </option>
      </LabeledSelect>
    );
  }
}


const memberTooltipMapStateToProps = () => ({
});

const memberTooltimapDispatchToProps = (dispatch, ownProps) => ({
  addTeamMember: async (data) => dispatch(teamMembersActions.create(data,
    { admin: ownProps.admin })),
  patchTeamMember: async (id, data) => dispatch(teamMembersActions.patch(id, data,
    { admin: ownProps.admin })),
  deleteTeamMember: async (id) => dispatch(teamMembersActions.remove(id,
    { admin: ownProps.admin })),
  resyncTeam: async (id) => dispatch(teamsActions.read(id)),
});

// eslint-disable-next-line react/prefer-stateless-function
@withToastManager
@connect(memberTooltipMapStateToProps, memberTooltimapDispatchToProps, null, { forwardRef: true })
@withTranslation('', nsOptions)
class MemberTooltip extends Component {
  static propTypes = {
    children: childrenPropTypes().isRequired,
    member: PropTypes.shape().isRequired,
    t: PropTypes.func.isRequired,
    readOnly: PropTypes.bool,
    patchTeamMember: PropTypes.func.isRequired,
    addTeamMember: PropTypes.func.isRequired,
    deleteTeamMember: PropTypes.func.isRequired,
    resyncTeam: PropTypes.func.isRequired,
    admin: PropTypes.bool,
    teamFrozen: PropTypes.bool,
  };

  static defaultProps = {
    readOnly: true,
    admin: false,
    teamFrozen: false,
  };

  onRoleChange = async (e) => {
    try {
      const { patchTeamMember, member, resyncTeam } = this.props;
      const { value } = e.target;
      const data = {};
      switch (value) {
        case ROLE_LEADER:
          data.leader = true;
          break;
        case ROLE_MEMBER:
          data.leader = false;
          break;
        default:
          console.error('Not expected value');
          return;
      }
      await patchTeamMember(member.id, data);
      if (data.leader) {
        // Resync the team in case the team had no leader and was frozen
        await resyncTeam(member.team);
      }
      Toast.success(this.props, 'error:valid.success');
    } catch (error) {
      ErrorUtil.handleCatched(this.props, error);
    }
  };

  resendInvitation = async (invitation) => {
    try {
      const { addTeamMember } = this.props;
      const { team, email, leader } = invitation;
      await addTeamMember({ team, email, leader });
      Toast.success(this.props, 'error:valid.success');
    } catch (error) {
      ErrorUtil.handleCatched(this.props, error);
    }
  };

  remove = async (invitation) => {
    try {
      const { deleteTeamMember } = this.props;
      await deleteTeamMember(invitation.id);
      Toast.success(this.props, 'error:valid.success');
    } catch (error) {
      ErrorUtil.handleCatched(this.props, error);
    }
  };

  copyInvitationLink = async (invitation) => {
    const { admin } = this.props;
    TextUtil.asyncCopyTextToClipboard(
      this.props,
      api.requestData(
        `team-members/${invitation.id}/invitation-url`,
        null, 'get', { admin },
      ),
      (response) => response.url,
    );
  }

  render() {
    const {
      t, member, children, readOnly, admin, teamFrozen,
    } = this.props;
    const { confirmed, email } = member;

    return (
      <InvitationTooltipBase
        admin={admin}
        dataLockerType={LOCK_MODEL_TEAM_MEMBER}
        invitation={member}
        showManagement={!readOnly}
        resendInvitation={this.resendInvitation}
        cancelInvitation={this.remove}
        removeGuest={this.remove}
        copyInvitationLink={this.copyInvitationLink}
        removeGuestLabel="user:team.remove-from-team"
        removeGuestWarning={(
          <span>
            {t('user:team.remove-from-team-warning')}
            <br />
            <br />
            {t('common:confirm-deletion')}
          </span>
        )}
        extraContent={(
          <>
            <div className="font-italic mb-2">
              {confirmed ? email : t('user:team.invitations.pending')}
            </div>
            {
              !readOnly && (
                <MemberRoleSelect
                  defaultValue={member.leader ? ROLE_LEADER : ROLE_MEMBER}
                  selectTextColor="text-white"
                  readOnly={readOnly || teamFrozen}
                  onChange={this.onRoleChange}
                />
              )
            }
          </>
        )}
      >
        { children }
      </InvitationTooltipBase>
    );
  }
}

// eslint-disable-next-line react/prefer-stateless-function
@withTranslation('', nsOptions)
class MemberAvatar extends Component {
  static propTypes = {
    member: PropTypes.shape().isRequired,
    readOnly: PropTypes.bool,
    admin: PropTypes.bool,
    teamFrozen: PropTypes.bool,
  };

  static defaultProps = {
    readOnly: true,
    admin: false,
    teamFrozen: false,
  };

  render() {
    const {
      member, readOnly, admin, teamFrozen,
    } = this.props;

    return (
      <div className="mr-2 mt-2 position-relative d-inline" key={member.id}>
        <MemberTooltip
          member={member}
          readOnly={readOnly}
          admin={admin}
          teamFrozen={teamFrozen}
        >
          <InvitationAvatar
            user={member.user}
            email={member.email}
            confirmed={member.confirmed}
          />
        </MemberTooltip>
      </div>
    );
  }
}

@withTranslation('', nsOptions)
class MemberList extends Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
    role: PropTypes.oneOf([ROLE_LEADER, ROLE_MEMBER]).isRequired,
    className: PropTypes.string,
    children: childrenPropTypes().isRequired,
    teamMembers: PropTypes.arrayOf(PropTypes.shape()).isRequired,
    readOnly: PropTypes.bool,
    userId: PropTypes.number.isRequired,
    admin: PropTypes.bool,
    canModifyItsOwnRole: PropTypes.bool,
    teamFrozen: PropTypes.bool,
  };

  static defaultProps = {
    className: '',
    readOnly: true,
    admin: false,
    canModifyItsOwnRole: false,
    teamFrozen: false,
  };

  render() {
    const {
      t, role, className, children, teamMembers, readOnly, userId, admin, canModifyItsOwnRole,
      teamFrozen,
    } = this.props;

    const teamFrozenAndNoMember = teamFrozen && role === ROLE_MEMBER;

    return (
      <div className={className}>
        <div className="row">
          <div className="col">
            <Label className="mb-2">
              {children}
            </Label>
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-sm">
            {
              teamMembers.length > 0 ? teamMembers.map((member) => (
                <MemberAvatar
                  key={member.id}
                  member={member}
                  readOnly={readOnly || (member.user.id === userId && !canModifyItsOwnRole)}
                  admin={admin}
                  teamFrozen={teamFrozen}
                />
              )) : (
                <div className={`font-italic ${teamFrozenAndNoMember ? 'text-red font-weight-semibold' : 'text-gray'} pt-1`}>
                  {teamFrozenAndNoMember ? t('user:team.cannot-invite-members')
                    : t('user:team.invitations.none')}
                </div>
              )
            }
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  userId: state.auth.authUser.id,
  user: state.auth.authUser,
  teamLicenses: state.teamLicenses,
  teamMembers: state.teamMembers,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  fetchTeams: async (userId) => dispatch(teamsActions.list(
    ownProps.admin ? { admin: true } : { members__user: userId },
    { pagination: 'no' },
  )),
  patchTeam: async (id, data) => dispatch(teamsActions.patch(id, data, { admin: ownProps.admin })),
  deleteTeam: async (id) => dispatch(teamsActions.remove(id, { admin: ownProps.admin })),
  fetchTeamMembers: async (teamId) => dispatch(teamMembersActions.list({
    team: teamId,
  }, { pagination: 'no' })),
  addTeamMember: async (data) => dispatch(teamMembersActions.create(data,
    { admin: ownProps.admin })),
  deleteTeamMember: async (id) => dispatch(teamMembersActions.remove(id,
    { admin: ownProps.admin })),
});

@withToastManager
@connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })
@withTranslation('', nsOptions)
@withRouter
@withLicenseMsgModal()
class TeamForm extends Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
    admin: PropTypes.bool,
    team: PropTypes.shape(),
    teamLicenses: PropTypes.shape(),
    teamMembers: PropTypes.shape(),
    userId: PropTypes.number.isRequired,
    user: PropTypes.shape().isRequired,
    fetchTeams: PropTypes.func.isRequired,
    patchTeam: PropTypes.func.isRequired,
    deleteTeam: PropTypes.func.isRequired,
    fetchTeamMembers: PropTypes.func.isRequired,
    addTeamMember: PropTypes.func.isRequired,
    deleteTeamMember: PropTypes.func.isRequired,
    userOwnTeams: PropTypes.bool,
    focusTeamNameOnOpen: PropTypes.bool,
    onOpen: PropTypes.func,
    onQuitTeam: PropTypes.func,
    onDeleteTeam: PropTypes.func,
  };

  static defaultProps = {
    admin: false,
    team: undefined,
    teamLicenses: {},
    teamMembers: [],
    userOwnTeams: false,
    focusTeamNameOnOpen: false,
    onOpen: () => {},
    onQuitTeam: () => {},
    onDeleteTeam: () => {},
  };

  static getExtraInvestigators = (team) => {
    const value = team.licence_options.max_investigators_per_project;
    return !value ? '0' : value.toString();
  };

  static isTeamOwner = (team, userId) => team.owner.id === userId;

  static isTeamLeader = (userId, teamId, teamMembers) => {
    if (!teamMembers || !teamMembers.length) return false;
    return teamMembers.findIndex((member) => member.user.id === userId && member.leader
      && member.team === teamId) >= 0;
  };

  static isOwnerOrLeader = (userId, team, teamMembers) => {
    if (!userId || !team) return false;
    if (TeamForm.isTeamOwner(team, userId)) return true;
    return TeamForm.isTeamLeader(userId, team.id, teamMembers);
  };

  static getInitialState = (props) => ({
    id: props.team ? props.team.id : undefined,
    name: props.team ? props.team.name : '',
    type: props.team ? props.team.licence : undefined,
    owner: props.team ? props.team.owner.id : undefined,
    ownerLabel: props.team ? props.team.owner.label : undefined,
    extraInvestigators: props.team ? TeamForm.getExtraInvestigators(props.team) : undefined,
    isFrozen: props.team ? props.team.is_frozen : undefined,
    canManageMembers: props.team
      ? TeamForm.isOwnerOrLeader(
        props.userId,
        props.team,
        Object.values(props.teamMembers),
      ) || props.admin
      : false,
    canEditAndDeleteTeam: props.team ? TeamForm.isTeamOwner(props.team, props.userId) || props.admin
      : false,
    specialties: props.team ? props.team.specialties : [],
  });

  static getInvitationRole = (props) => {
    const { team } = props;
    if (!team) return ROLE_MEMBER;
    // Cannot invite members if there is no leader
    if (team.is_frozen && team.frozen_reason === NO_TEAM_LEADER) return ROLE_LEADER;
    return ROLE_MEMBER;
  };

  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      nameEditing: false,
      invitationCreationInProgress: false,
      processingInvitation: false,
      invitationEmail: '',
      invitationEmailValid: false,
      invitationRole: TeamForm.getInvitationRole(props),
      ...TeamForm.getInitialState(props),
    };
    this.timeoutHandler = new TimeoutHandler();
    this.nameLocker = new DataLocker(LOCK_MODEL_TEAM, LOCK_SECTION, props.admin);
    this.specialtySelect = null;
    this.toTeamMembersArray = fromReduxState((teamMembers) => Object.values(teamMembers));
  }

  async componentDidMount() {
    const { focusTeamNameOnOpen, onOpen } = this.props;
    const { nameEditing } = this.state;
    if (focusTeamNameOnOpen && !nameEditing) {
      await this.onNameEditionToggle();
      if (this.nameInput) {
        const input = document.getElementById(this.nameInput.id);
        if (input) input.focus();
      }
    }
    onOpen();
  }

  componentDidUpdate(prevProps) {
    const { team: prevTeam } = prevProps;
    const { team } = this.props;
    if (prevTeam && team && team.is_frozen !== prevTeam.is_frozen) {
      this.updateFrozenState();
    }
    if (!prevTeam && team && team.is_frozen) {
      this.updateInvitationRole();
    }
  }

  componentWillUnmount() {
    this.closeAllEditions();
  }

  resetTeam = () => this.setState({ ...TeamForm.getInitialState(this.props) });

  updateFrozenState = () => {
    const { team } = this.props;
    this.setState({ isFrozen: team ? team.is_frozen : undefined });
  };

  updateInvitationRole = () => {
    const newInvitationRole = TeamForm.getInvitationRole(this.props);
    this.setState({ invitationRole: newInvitationRole });
  };

  onNameEditionToggle = async () => {
    const { t } = this.props;
    const { id } = this.state;

    try {
      const res = await this.nameLocker.toggleLock(id, LOCK_SECTION_NAME);
      const { locked, success } = res;
      if (success) {
        this.setState({ nameEditing: locked });
      } else {
        Toast.warning(this.props, DataLocker.lockUserMessage(res, t), LOCK_TOAST_TIMEOUT_S);
      }
    } catch (error) {
      ErrorUtil.handleCatched(this.props, error);
    }
  }

  onEmailInvitationChange = (e) => {
    const value = e.target ? e.target.value : null;
    this.setState({
      invitationEmail: value,
      invitationEmailValid: this.emailValidator.validate(value),
    });
  }

  onEmailInvitationPaste = (e) => {
    formatPasteEmailList(e);
    this.onEmailInvitationChange(e);
  }

  isInvitationFormValid = () => this.state.invitationEmailValid;

  closeAllEditions = (updateState = false) => {
    if (this.nameLocker.isLocked()) this.nameLocker.unlock();
    if (updateState) {
      this.setState({
        nameEditing: false,
      });
    }
  }

  updateTeam = async (field, value, undoValueIfError = true, throwError = false) => {
    const { team } = this.props;
    const { id } = this.state;
    try {
      const { patchTeam } = this.props;
      await patchTeam(id, { [field]: value });
      this.setState({ [field]: value });
      Toast.success(this.props, 'error:valid.success');
    } catch (error) {
      if (undoValueIfError && team) {
        this.setState({ [field]: team[field] });
      }
      if (throwError) {
        throw error;
      } else {
        ErrorUtil.handleCatched(this.props, error);
      }
    }
  }

  deleteTeam = async () => {
    this.setState({ loading: true });
    try {
      const { deleteTeam, onDeleteTeam } = this.props;
      const { id } = this.state;
      await deleteTeam(id);
      Toast.success(this.props, 'error:valid.success');
      await onDeleteTeam();
    } catch (error) {
      ErrorUtil.handleCatched(this.props, error);
    } finally {
      this.setState({ loading: false });
    }
  }

  quitTeam = async () => {
    this.setState({ loading: true });
    try {
      const {
        teamMembers: tmMembers, deleteTeamMember, userId, fetchTeams, onQuitTeam,
      } = this.props;
      const { id } = this.state;
      const teamMembers = this.toTeamMembersArray(tmMembers);
      const teamMember = teamMembers.find((tmMember) => (
        tmMember.team === id && tmMember.user.id === userId
      ));
      await deleteTeamMember(teamMember.id);
      await fetchTeams(userId);
      Toast.success(this.props, 'error:valid.success');
      await onQuitTeam();
    } catch (error) {
      ErrorUtil.handleCatched(this.props, error);
    } finally {
      this.setState({ loading: false });
    }
  }

  invite = async () => {
    this.setState({ processingInvitation: true });
    try {
      const { addTeamMember, fetchTeamMembers, admin } = this.props;
      const { id, invitationEmail, invitationRole } = this.state;
      const emails = emailListToArray(invitationEmail);
      const leader = invitationRole === ROLE_LEADER;
      if (emails.length === 1) {
        await addTeamMember({
          team: id,
          email: emails[0],
          leader,
        });
      } else {
        await api.create('bulk-invite-in-team', {
          team: id,
          emails,
          leader,
        }, { admin });
        await fetchTeamMembers(id);
      }
      Toast.success(this.props, 'error:valid.saved');
    } catch (error) {
      ErrorUtil.handleCatched(this.props, error);
    } finally {
      this.setState({
        invitationEmail: '',
        invitationEmailValid: false,
        invitationRole: TeamForm.getInvitationRole(this.props),
        processingInvitation: false,
        invitationCreationInProgress: false,
      });
    }
  };

  renderName = () => {
    const { t } = this.props;
    const {
      name, nameEditing, canEditAndDeleteTeam, isFrozen,
    } = this.state;
    const canEditName = canEditAndDeleteTeam && nameEditing;
    return (
      <>
        <LabeledInput
          className={!canEditName ? 'read-only' : ''}
          name="name"
          type="text"
          placeholder={t('user:team.placeholder.name')}
          colInputClassName="col-12"
          value={name}
          readOnly={!canEditName}
          required
          onChange={(e) => {
            const { value } = e.target;
            const undoValueIfError = value !== '';
            this.setState({ name: value });
            this.timeoutHandler.doAfterTimeout(() => this.updateTeam('name', value, undoValueIfError));
          }}
          inputClassName="team-name-input labeled-input"
          ref={(ref) => { this.nameInput = ref; }}
        />
        {isFrozen && (
          <div className="col-12 px-0">
            <NewTooltip
              content={t('user:team.frozen.help')}
            >
              <span>
                <FontAwesomeIcon
                  icon={['fas', 'exclamation-triangle']}
                  transform="grow-2"
                  className="text-red"
                />
                <span className="ml-3 dotted-underline font-weight-semibold text-red">
                  {t('user:team.frozen.info')}
                </span>
              </span>
            </NewTooltip>
          </div>
        )}
      </>
    );
  };

  renderOwner = () => {
    const { fetchTeamMembers, admin } = this.props;
    const { owner, id, ownerLabel } = this.state;

    return admin ? (
      <div className="row">
        <div className="col-12 mb-3">
          <UserSelect
            admin
            value={owner}
            onChange={async (e) => {
              await this.updateTeam('owner', e.value, false);
              await fetchTeamMembers(id);
              this.resetTeam();
            }}
          />
        </div>
      </div>
    ) : (
      ownerLabel && (
        <div
          className="mt-2"
          style={{ paddingTop: '0.15rem' }}
        >
          {ownerLabel}
        </div>
      )
    );
  }

  getLicenseSpecs = (license) => {
    if (license.key === TEAM_LICENSE_PRO_KEY) {
      return [
        { key: 'spec-1', access: true, value: 'participate-in-projects' },
        { key: 'spec-2', access: false, value: 'create-projects' },
      ];
    }
    return [
      { key: 'spec-1', access: true, value: 'participate-in-projects' },
      { key: 'spec-2', access: true, value: 'create-projects' },
    ];
  }

  renderType = () => {
    const { t, teamLicenses, admin } = this.props;
    const { type, extraInvestigators } = this.state;
    const upgradeLicenseEmail = deObfuscateEmail('support', 'doqboard.com');
    const teamLicense = teamLicenses[type];
    const teamLicenseSpecs = teamLicense ? this.getLicenseSpecs(teamLicense) : [];

    return (
      teamLicense && (
        <>
          <div className="font-weight-semibold">
            {t(`user:licenses.team-${teamLicense.key}`)}
          </div>
          {admin && extraInvestigators !== undefined && (
            <div>
              <span className="mt-1 font-italic">Max number of external investigators:</span>
              <span>{` ${extraInvestigators}`}</span>
            </div>
          )}
          {!admin && (
            <>
              <ul className="list-group py-2">
                {
                  teamLicenseSpecs.map((spec) => {
                    const { key, access, value } = spec;
                    return (
                      <li
                        key={key}
                        className={`${access ? '' : 'text-gray-dark'} list-group-item border-0 pl-0 py-1 bg-transparent`}
                      >
                        <span className="pr-1">
                          <FontAwesomeIcon
                            className={`fa-fw ${access ? 'text-green' : 'text-red'}`}
                            icon={access ? ['fas', 'check'] : ['fas', 'times']}
                          />
                        </span>
                        <span>
                          {t(`user:team.specs.${value}`)}
                        </span>
                      </li>
                    );
                  })
                }
              </ul>
              {teamLicense.key !== TEAM_LICENSE_EXPERT_KEY && (
                <span>
                  {t('user:license-team-info')}
                  &nbsp;
                  <span className="font-weight-semibold">
                    {upgradeLicenseEmail}
                  </span>
                  .
                </span>
              )}
            </>
          )}
        </>
      )
    );
  };

  renderSpecialties = () => {
    const { t, admin } = this.props;
    const { specialties } = this.state;

    if (admin) {
      return (
        <SpecialtySelect
          admin={admin}
          values={specialties}
          onChange={(newSpecialties) => this.updateTeam(
            'specialties', newSpecialties.map((specialty) => specialty.value), true, true,
          )}
          disabled={!admin}
          isMulti
          ref={(ref) => { this.specialtySelect = ref; }}
          placeholder={t('user:team.select-team-specialty', { context: 'plural' })}
        />
      );
    }

    return specialties.length > 0 ? (
      specialties.map((specialty) => (
        <div
          className="badge badge-newyellow-2 text-truncate mx-1"
          style={{ maxWidth: 'inherit' }}
          key={specialty}
        >
          {t(`specialty:${specialty}`)}
        </div>
      ))
    ) : null;
  };

  renderInvitations = () => {
    const {
      id, invitationCreationInProgress, processingInvitation, invitationEmail,
      invitationRole, canManageMembers, canEditAndDeleteTeam,
    } = this.state;
    const {
      t, teamMembers: tmMembers, userId, admin, team,
    } = this.props;
    const teamMembers = this.toTeamMembersArray(tmMembers);

    const leaders = teamMembers.filter((mb) => mb.leader && mb.team === id);
    const members = teamMembers.filter((mb) => !mb.leader && mb.team === id
      && mb.type === TEAM_MEMBER_USUAL);

    const membersListCommonProps = {
      className: 'mb-3',
      readOnly: !canManageMembers,
      userId,
      admin,
      canModifyItsOwnRole: canEditAndDeleteTeam,
    };

    const showNewInvitationBtn = canManageMembers;
    const invitationFormValid = this.isInvitationFormValid();

    const teamFrozen = team ? team.is_frozen : false;

    return (
      <div>
        <MemberList
          role={ROLE_LEADER}
          teamMembers={leaders}
          teamFrozen={teamFrozen}
          {...membersListCommonProps}
        >
          {t('user:team.invitations.leaders')}
        </MemberList>
        <MemberList
          role={ROLE_MEMBER}
          teamMembers={members}
          teamFrozen={teamFrozen}
          {...membersListCommonProps}
        >
          {t('user:team.invitations.members')}
        </MemberList>
        {
          showNewInvitationBtn && (
            <div className="pt-3">
              <NewTooltip
                content={t('user:team.new-invitation.title-tooltip')}
                containerClassName="team-new-invitation-tooltip"
                placement="right"
                arrow
              >
                <button
                  className="btn btn-invite-in-a-team"
                  disabled={invitationCreationInProgress}
                  onClick={() => this.setState({ invitationCreationInProgress: true })}
                >
                  {t('user:team.new-invitation.title')}
                </button>
              </NewTooltip>
            </div>
          )
        }
        {
          invitationCreationInProgress && (
            <div className="col-12">
              <LabeledInput
                type="textarea"
                label={(
                  <span>
                    {t('user:team.new-invitation.email')}
                    <Help iconClassName="ml-2">
                      {t('user:team.new-invitation-info')}
                    </Help>
                  </span>
                )}
                name="email"
                placeholder={t('error:placeholder.email')}
                validation="email_list"
                required
                className="mt-4 mb-0"
                colInputClassName="col-12"
                validatorTooltipDistance={35}
                value={invitationEmail}
                onChange={this.onEmailInvitationChange}
                onPaste={this.onEmailInvitationPaste}
                ref={(validator) => { this.emailValidator = validator; }}
              />
              <span className="help-text">
                {t('user:team.new-invitation.enter-emails-info')}
              </span>
              <MemberRoleSelect
                className="mb-4 mt-4"
                label={t('user:team.new-invitation.role')}
                value={invitationRole}
                readOnly={!canManageMembers || teamFrozen}
                onChange={(e) => this.setState({ invitationRole: e.target.value })}
              />
              <button
                className={`btn btn-invite-in-a-team text-white mt-1 ${invitationFormValid ? '' : 'cursor-default'}`}
                disabled={!invitationFormValid}
                onClick={this.invite}
              >
                <span>
                  {t('common:button.invite')}
                </span>
                <div className="d-inline-block ml-1">
                  { processingInvitation && <CardLoader /> }
                </div>
              </button>
              <button
                disabled={processingInvitation}
                className="ml-4 btn btn-link"
                type="button"
                onClick={() => this.setState({ invitationCreationInProgress: false })}
              >
                {t('common:button.cancel')}
              </button>
            </div>
          )
        }
      </div>
    );
  };

  renderDeleteTeam = () => {
    const { t } = this.props;
    return (
      <DelMsgModal
        message={(
          <span>
            <b>
              {t('common:caution')}
              &nbsp;!
            </b>
            <br />
            <br />
            {t('user:team.deletion-warning')}
            <br />
            <br />
            {t('common:confirm-deletion')}
          </span>
        )}
        onValidate={this.deleteTeam}
      >
        <ButtonConfirm>
          <button
            type="button"
            className="btn btn-primary"
          >
            {t('user:team.delete')}
          </button>
        </ButtonConfirm>
      </DelMsgModal>
    );
  };

  renderQuitTeam = () => {
    const { t, admin } = this.props;
    const { id } = this.state;

    return (
      <DelMsgModal
        message={(
          <span>
            <b>
              {t('common:caution')}
              &nbsp;!
            </b>
            <br />
            <br />
            {t('user:team.quit-warning')}
            <br />
            <br />
            {t('common:confirm-deletion')}
          </span>
        )}
        onValidate={this.quitTeam}
      >
        <ButtonConfirm>
          <button
            type="button"
            className="btn btn-primary"
            disabled={admin && !id}
          >
            {t('user:team.quit')}
          </button>
        </ButtonConfirm>
      </DelMsgModal>
    );
  };

  render() {
    const {
      t, admin, team,
    } = this.props;
    const {
      loading, id, nameEditing, type, owner, canEditAndDeleteTeam,
    } = this.state;

    const showDeleteTeam = canEditAndDeleteTeam;

    return (
      <div>
        { loading && <CardLoader /> }
        {(!admin || id) && (
          <div className="new-settings mx-4">
            <RowContent
              contentClassName="col-12 col-md-8 col-lg-7 col-xl-7 mt-1"
              label={(
                <>
                  <span>
                    {t('user:team.title.name')}
                  </span>
                  {canEditAndDeleteTeam && (
                    <>
                      <span className="ml-2" />
                      <EditButton
                        active={nameEditing}
                        transform="grow-2"
                        onClick={this.onNameEditionToggle}
                      />
                    </>
                  )}
                </>
              )}
              show={Boolean(team)}
            >
              {this.renderName()}
            </RowContent>
            {owner && (
              <RowContent
                label={t('user:team.title.owner')}
                contentClassName="col-auto"
                show={Boolean(team)}
              >
                {this.renderOwner()}
              </RowContent>
            )}
            {type && (
              <RowContent
                contentClassName="col-12 col-md-8 mt-2"
                label={t('user:team.title.type')}
                show={Boolean(team)}
              >
                {this.renderType()}
              </RowContent>
            )}
            <RowContent
              contentClassName="col-12 col-sm-8 col-md-5 col-lg-5 col-xl-4 mt-2"
              label={t('user:team.title.specialties')}
              show={Boolean(team)}
            >
              {this.renderSpecialties()}
            </RowContent>
            <RowContent
              contentClassName="col-12 col-md-8 mt-2"
              label={t('user:team.title.invitations')}
              show={Boolean(team)}
            >
              {this.renderInvitations()}
            </RowContent>
            <RowContent label={t('user:team.title.delete')} show={Boolean(showDeleteTeam && team)}>
              {this.renderDeleteTeam()}
            </RowContent>
            <RowContent label={t('user:team.title.leaving')} show={Boolean(!showDeleteTeam && team)}>
              {this.renderQuitTeam()}
            </RowContent>
          </div>
        )}
      </div>
    );
  }
}


export default TeamForm;
