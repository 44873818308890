import { createReducer } from 'redux-starter-kit';
import { elementsActions } from '../actions';
import getStdDbModelReduxMapping from './std-db-model-reducers';

export const elementsInitialState = {};

const elementsReducer = createReducer(
  elementsInitialState,
  getStdDbModelReduxMapping(elementsActions),
);

export default elementsReducer;
