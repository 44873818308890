import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { withToastManager } from 'react-toast-notifications';
import memoize from 'memoize-one';
import isEqual from 'react-fast-compare';
import { Link } from 'react-router-dom';
import withStyles from '@material-ui/core/styles/withStyles';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import api from '../../api';
import { nsOptions } from '../../i18n';
import SortUtil from '../../utils/SortUtil';
import ElementUtil from '../../utils/ElementUtil';
import ErrorUtil from '../../utils/ErrorUtil';
import TextUtil from '../../utils/TextUtil';
import LabeledChoice from '../LabeledChoice';
import DataOverviewList from './DataOverviewList';
import {
  PROJECT_ON_HOLD, ELEMENT_TYPE_MODULE, ELEMENT_TYPE_CALCULATION,
} from '../../constants';
import Modal from './Modal';

const INCONSISTENT_DATA_TAB = 'inconsistent_data';
const MISSING_DATA_TAB = 'missing_data';
const VALID_DATA_TAB = 'valid_data';

const NOT_QUALIFIED = 'not_qualified';
const NOT_AVAILABLE = 'not_available';
const NOT_APPLICABLE = 'not_applicable';

const IDENTIFICATION_LENGTH = 2;
const TOOLTIP_CONTENT_MAX_LENGTH = 25;
const IDENTIFYING_DATA_MAX_LENGTH = 20;
const ELEMENT_NAME_MAX_LENGTH = 30;

const ELEMENT_NAME_SEPARATOR = '-------------------------';

const styles = (theme) => ({
  root: {
    padding: theme.spacing(2),
    height: '610px',
    width: '920px',
    maxWidth: 'unset',
  },
  linkedRoot: {
    padding: theme.spacing(2),
    height: '650px',
    width: '850px',
    maxWidth: 'unset',
  },
  smallRoot: {
    padding: theme.spacing(2),
    height: '500px',
    width: '600px',
    maxWidth: 'unset',
  },
  smallLinkedRoot: {
    padding: theme.spacing(2),
    height: '580px',
    width: '600px',
    maxWidth: 'unset',
  },
  openButton: {
    backgroundColor: theme.palette.turquoise.light,
    '&:hover': {
      backgroundColor: theme.palette.turquoise.main,
    },
    '&:focus': {
      outline: 'none',
    },
    fontSize: '0.74rem',
    textTransform: 'none',
    padding: '0.5px 5px',
  },
  tabs: {
    '& .MuiTab-root': {
      textTransform: 'unset',
      minWidth: 'unset',
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      maxWidth: 'unset',
    },
    '& .Mui-selected': {
      fontWeight: '600',
      '&:focus': {
        outline: 'none',
      },
    },
  },
  dialogContent: {
    paddingRight: 0,
    paddingLeft: 0,
    paddingBottom: 0,
  },
  container: {
    ...theme.typography.body2,
    borderBottom: 0,
    paddingLeft: 0,
    paddingRight: 0,
    width: 'fit-content',
    margin: 0,
  },
  containerEmpty: {
    ...theme.typography.caption,
    borderBottom: 0,
    paddingLeft: 0,
    paddingRight: 0,
    width: 'fit-content',
    margin: 0,
  },
  containerEmptyModule: {
    ...theme.typography.caption,
    borderBottom: 0,
    paddingLeft: 0,
    paddingRight: 0,
    width: 'fit-content',
    margin: 'auto',
  },
  dataContainer: {
    paddingTop: theme.spacing(2),
    '&:first-child': {
      paddingTop: 0,
    },
  },
  subtitle: {
    marginTop: theme.spacing(2),
  },
  inclusionItem: {
    ...theme.typography.body2,
    textAlign: 'center',
  },
  tabContent: {
    padding: '15px 0 0 0',
  },
  missingDataTypeRadios: {
    paddingTop: '12px',
  },
  tooltipContainer: {
    padding: '20px 24px',
  },
  tooltipItem: {
    padding: '6px 0',
    '&:last-child': {
      paddingTop: '15px',
    },
  },
  tooltipListContainer: {
    padding: '6px 0',
  },
  tooltipList: {
    padding: 0,
    paddingTop: '4px',
  },
  tooltipListItem: {
    padding: '2px 16px',
  },
  tooltipListText: {
    '& .MuiListItemText-primary': {
      ...theme.typography.body2,
    },
    '& .MuiListItemText-multiline': {
      padding: 0,
    },
  },
  help: {
    fontStyle: 'italic',
    color: 'var(--gray-dark)',
  },
  note: {
    fontStyle: 'italic',
    color: 'var(--gray-dark)',
    marginBottom: theme.spacing(3),
    padding: '4px 0',
  },
  redirection: {
    color: 'var(--gray-dark)',
    '&:hover': {
      color: 'var(--gray-dark)',
      textDecorationColor: 'var(--gray-dark)',
    },
  },
  copyButton: {
    backgroundColor: theme.palette.turquoise.light,
    textTransform: 'unset',
    '&:hover': {
      backgroundColor: theme.palette.turquoise.main,
    },
    '&:focus': {
      outline: 'none',
    },
  },
  listContainer: {
    padding: 0,
    marginBottom: theme.spacing(4),
    marginTop: theme.spacing(4),
    display: 'inline-block',
  },
});

const LightTooltip = withStyles({
  tooltip: {
    backgroundColor: 'white',
    color: 'inherit',
    fontSize: 'inherit',
    boxShadow: '0px 3px 3px -2px rgb(0 0 0 / 20%), 0px 3px 4px 0px rgb(0 0 0 / 14%), 0px 1px 8px 0px rgb(0 0 0 / 12%)',
    maxWidth: 'unset',
  },
})(Tooltip);

const mapStateToProps = (state) => ({
  projectUsers: state.projectUsers,
  teams: state.teams,
});

@withToastManager
@connect(mapStateToProps)
@withTranslation('', nsOptions)
@withStyles(styles)
class DataOverviewModal extends Component {
  static propTypes = {
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    t: PropTypes.func.isRequired,
    classes: PropTypes.shape().isRequired,
    name: PropTypes.string.isRequired,
    projectUsers: PropTypes.shape().isRequired,
    teams: PropTypes.shape().isRequired,
    isModule: PropTypes.bool,
    count: PropTypes.number.isRequired,
    filtersParams: PropTypes.shape(),
    project: PropTypes.shape().isRequired,
    isLinkTarget: PropTypes.bool,
    modalitySelected: PropTypes.bool,
    modalityTitle: PropTypes.string,
    elementType: PropTypes.string,
    showLoader: PropTypes.func,
    canUseAdvancedTools: PropTypes.bool.isRequired,
  };

  static defaultProps = {
    isModule: false,
    filtersParams: undefined,
    isLinkTarget: false,
    modalitySelected: false,
    modalityTitle: '',
    elementType: null,
    showLoader: () => {},
  };

  constructor(props) {
    super(props);
    this.state = {
      currentTab: VALID_DATA_TAB,
      currentSubTab: NOT_QUALIFIED,
      openOverviewModal: false,
      dataOverview: null,
      requestOverview: true,
      showCopiedMessage: false,
    };
    this.memoizedFormatDataOverview = memoize(this.formatDataOverview, isEqual);
    this.activeTimeout = -1;
  }

  componentDidUpdate(prevProps) {
    if (prevProps.filtersParams !== this.props.filtersParams) {
      this.updateRequestOverview();
    }
  }

  updateRequestOverview = () => {
    this.setState({ requestOverview: true });
  }

  onTabChange = (event, newValue) => {
    this.setState({ currentTab: newValue, showCopiedMessage: false });
  }

  getProjectUserLabel = (inclusion) => {
    const { projectUsers } = this.props;
    let projectUser;
    let truncatedLabel = '';
    if (inclusion) {
      projectUser = Object.values(projectUsers).find((pUser) => (
        pUser.user.id === inclusion.creator_id));
      truncatedLabel = TextUtil.truncate(
        projectUser ? projectUser.user.label : inclusion.creator_name,
        TOOLTIP_CONTENT_MAX_LENGTH,
      );
    }
    return {
      projectUser,
      truncatedLabel,
    };
  }

  getTeamName = (teamId) => {
    const { teams } = this.props;
    if (teamId) {
      const team = teams[teamId];
      if (team) {
        const teamName = TextUtil.truncate(team.name, TOOLTIP_CONTENT_MAX_LENGTH);
        return {
          team,
          teamName,
        };
      }
    }
    return {
      team: null,
      teamName: null,
    };
  }

  getInclusionTooltipContent = (inclusion) => {
    const {
      t, classes, project,
    } = this.props;

    const teamId = inclusion.team_id;

    const { projectUser, truncatedLabel } = this.getProjectUserLabel(inclusion);
    const completeLabel = projectUser ? projectUser.user.label : inclusion.creator_name;

    const { team, teamName } = this.getTeamName(teamId);

    let identifiers = [];
    if (inclusion.identifiers && inclusion.identifiers.length) {
      identifiers = inclusion.identifiers.slice(0, IDENTIFICATION_LENGTH);
    }

    return (
      <Container className={classes.tooltipContainer}>
        <Typography variant="body2" className={classes.tooltipItem}>
          {t('stats:data-overview.inclusion-creator')}
          &nbsp;
          <LightTooltip
            placement="top"
            title={(completeLabel === truncatedLabel) ? '' : completeLabel}
            enterTouchDelay={50}
          >
            <span>{truncatedLabel}</span>
          </LightTooltip>
        </Typography>
        {
          team && teamName && (
            <Typography variant="body2" className={classes.tooltipItem}>
              {t('stats:data-overview.inclusion-team')}
              &nbsp;
              <LightTooltip
                placement="top"
                title={(team.name === teamName) ? '' : team.name}
                enterTouchDelay={50}
              >
                <span>{teamName}</span>
              </LightTooltip>
            </Typography>
          )
        }
        {
          Boolean(identifiers.length) && (
            <Container className={classes.tooltipListContainer}>
              <Typography variant="body2">
                {t('stats:data-overview.inclusion-identifying-data')}
              </Typography>
              <List className={classes.tooltipList}>
                {identifiers.map((identifier) => {
                  const formattedName = ElementUtil.formatElementName(identifier, t);
                  const truncatedName = TextUtil.truncate(
                    formattedName,
                    IDENTIFYING_DATA_MAX_LENGTH,
                  );

                  const truncatedValue = TextUtil.truncate(
                    identifier.value,
                    IDENTIFYING_DATA_MAX_LENGTH,
                  );
                  return (
                    <ListItem
                      key={`identifier-id-${identifier.id}`}
                      className={classes.tooltipListItem}
                    >
                      <LightTooltip
                        placement="top"
                        title={(formattedName === truncatedName) ? '' : formattedName}
                        enterTouchDelay={50}
                      >
                        <span>{truncatedName}</span>
                      </LightTooltip>
                      {t('common:colon')}
                      &nbsp;
                      <LightTooltip
                        placement="top"
                        title={(identifier.value === truncatedValue) ? '' : identifier.value}
                        enterTouchDelay={50}
                      >
                        <span>{truncatedValue}</span>
                      </LightTooltip>
                    </ListItem>
                  );
                })}
              </List>
            </Container>
          )
        }
        {
          inclusion.instances && (
            <Container className={classes.tooltipListContainer}>
              <Typography variant="body2">
                {t('stats:data-overview.inclusion-entries', { count: inclusion.instances.length })}
              </Typography>
              <List
                className={classes.tooltipList}
                style={{ maxHeight: '70px', overflow: 'auto' }}
              >
                {
                  inclusion.instances.sort(SortUtil.sortById).map((instance) => (
                    <ListItem
                      key={`list-instance-item-${instance.id}`}
                      className={classes.tooltipListItem}
                    >
                      {instance.name}
                    </ListItem>
                  ))
                }
              </List>
            </Container>
          )
        }
        {
          (project.status !== PROJECT_ON_HOLD) && (
            <Container className={classes.tooltipItem}>
              <Link
                to={{
                  pathname: `/dashboard/project/${project.id}/inclusions`,
                  search: `?searchValue=#${inclusion.per_project_id}`,
                }}
                className={classes.redirection}
              >
                <>
                  <FontAwesomeIcon
                    icon={['far', 'external-link']}
                    className="mr-1"
                    transform="shrink-3"
                  />
                  <small>
                    {t('stats:data-overview.go-to-inclusion')}
                  </small>
                </>
              </Link>
            </Container>
          )
        }
      </Container>
    );
  }

  formatOverviewByKey = (dataOverview, key) => {
    const res = {};
    dataOverview.forEach((overview) => {
      if (Object.keys(res).includes(overview[key].toString())) {
        res[overview[key]].push(overview);
      } else {
        res[overview[key]] = [overview];
      }
    });
    return res;
  }

  renderDataOverview = (type, data, count) => {
    const {
      t, classes, isModule, isLinkTarget, modalitySelected, elementType,
    } = this.props;
    const { showCopiedMessage, currentTab, currentSubTab } = this.state;

    const formattedType = type.replace('_', '-');
    const formattedSubType = (currentTab === MISSING_DATA_TAB
      && ![ELEMENT_TYPE_MODULE, ELEMENT_TYPE_CALCULATION].includes(elementType))
      ? currentSubTab.replace('_', '-') : '';
    const list = Object.values(data.inclusions);

    return (
      count ? (
        <Container
          className={classes.dataContainer}
        >
          <Grid
            container
            direction="column"
            className={classes.subtitle}
          >
            <Grid item>
              <Typography
                variant="subtitle2"
              >
                <b>
                  {t('stats:data-overview.tabs-title.inclusion', { count })}
                </b>
                &nbsp;
                {
                  modalitySelected ? (
                    t('stats:data-overview.tabs-title.modality-inclusion', { count })
                  ) : (
                    t(`stats:data-overview.tabs-title.${(count > 1) ? 'many' : 'one'}-${formattedSubType ? `${formattedSubType}-` : ''}${formattedType}${isModule && (type === MISSING_DATA_TAB) ? '-module' : ''}`)
                  )
                }
              </Typography>
            </Grid>
            <Grid item>
              <Typography
                variant="body2"
                className={classes.help}
              >
                <small>{t('stats:data-overview.inclusion-count-help')}</small>
              </Typography>
            </Grid>
          </Grid>
          <Container className={classes.listContainer}>
            <DataOverviewList
              key={`${formattedType}-overview-list${currentTab === MISSING_DATA_TAB ? `${currentSubTab}` : ''}`}
              list={list}
              getInclusionTooltipContent={this.getInclusionTooltipContent}
            />
          </Container>
          {
            (type === VALID_DATA_TAB) && isLinkTarget && (
              <Container
                className={classes.note}
                style={{ padding: 0 }}
              >
                <Typography
                  variant="body2"
                  size="medium"
                >
                  {t('stats:linked-variable')}
                </Typography>
              </Container>
            )
          }
          <Container style={{ padding: '4px 0px' }}>
            <Grid
              container
              direction="row"
              alignItems="center"
              spacing={2}
            >
              <Grid item>
                <Button
                  variant="contained"
                  onClick={() => this.copyToClipboard(data)}
                  color="primary"
                  className={classes.copyButton}
                  startIcon={<FontAwesomeIcon icon={['fal', 'copy']} />}
                  size="small"
                >
                  {t('stats:data-overview.copy')}
                </Button>
              </Grid>
              {
                showCopiedMessage && (
                  <Grid item>
                    <Typography
                      variant="body2"
                      style={{ color: 'var(--newgreen-2)' }}
                    >
                      {t('stats:data-overview.copied')}
                    </Typography>
                  </Grid>
                )
              }
            </Grid>
          </Container>
        </Container>
      ) : (
        <Container
          className={classes.dataContainer}
        >
          <Grid
            container
            direction="column"
            className={classes.subtitle}
          >
            <Grid item>
              <Typography variant="subtitle2">
                {t(`stats:data-overview.empty.${formattedSubType ? `${formattedSubType}-` : ''}${formattedType}`)}
              </Typography>
            </Grid>
          </Grid>
        </Container>
      )
    );
  }

  formatData = (data) => {
    const { t, name } = this.props;
    const res = [];
    res.push(ElementUtil.formatElementName({ name }, t));
    res.push(ELEMENT_NAME_SEPARATOR);
    res.push('');
    Object.values(data.inclusions).forEach((inclusion) => {
      const { truncatedLabel } = this.getProjectUserLabel(inclusion);
      const { teamName } = this.getTeamName(inclusion.team_id);
      res.push(`${t('stats:data-overview.inclusion')} #${inclusion.per_project_id}${t('common:colon')}`);
      res.push(`\t- ${t('stats:data-overview.inclusion-creator')} ${truncatedLabel}`);
      if (teamName) {
        res.push(`\t- ${t('stats:data-overview.inclusion-team')} ${teamName}`);
      }
      if (inclusion.identifiers && inclusion.identifiers.length) {
        const identifiers = inclusion.identifiers.slice(0, IDENTIFICATION_LENGTH);
        res.push(`\t- ${t('stats:data-overview.inclusion-identifying-data')}`);
        identifiers.forEach((identifier) => {
          const variableName = ElementUtil.formatElementName(identifier, t);
          res.push(`\t\t- ${variableName}: ${identifier.value}`);
        });
      }

      if (inclusion.instances) {
        res.push(`\t- ${t('stats:data-overview.inclusion-entries')}`);
        inclusion.instances.forEach((instance) => {
          res.push(`\t\t- ${instance.name}`);
        });
      }
      res.push('');
    });
    return res;
  }

  copyToClipboard = (data) => {
    const changeState = (value) => {
      this.setState({
        showCopiedMessage: value,
      });
      this.activeTimeout = -1;
    };
    try {
      const formattedData = this.formatData(data).join('\n');
      navigator.clipboard.writeText(formattedData);
      this.setState({ showCopiedMessage: true });
      clearTimeout(this.activeTimeout);
      this.activeTimeout = setTimeout(() => changeState(false), 5000);
    } catch (error) {
      ErrorUtil.handleCatched(this.props, error, false);
    }
  }

  renderModalContent = () => {
    const {
      classes, t, modalitySelected, elementType,
    } = this.props;
    const { currentTab, dataOverview, currentSubTab } = this.state;

    let formattedDataOverview;
    if (modalitySelected) {
      formattedDataOverview = {
        ...this.memoizedFormatDataOverview(dataOverview, [VALID_DATA_TAB]),
      };
    } else {
      formattedDataOverview = {
        ...this.memoizedFormatDataOverview(
          dataOverview,
          [VALID_DATA_TAB, MISSING_DATA_TAB, INCONSISTENT_DATA_TAB],
        ),
      };
    }

    return (
      modalitySelected ? (
        <Container>
          {
            this.renderDataOverview(
              VALID_DATA_TAB,
              formattedDataOverview[VALID_DATA_TAB],
              formattedDataOverview[VALID_DATA_TAB].count,
            )
          }
        </Container>
      ) : (
        <Container>
          <Tabs
            value={currentTab}
            onChange={this.onTabChange}
            className={classes.tabs}
            variant="scrollable"
            scrollButtons="auto"
          >
            <Tab
              label={t('stats:data-overview.tabs.usable-inclusions', { count: formattedDataOverview[VALID_DATA_TAB].count })}
              value={VALID_DATA_TAB}
              disabled={!formattedDataOverview[VALID_DATA_TAB].count}
            />
            <Tab
              label={t('stats:data-overview.tabs.missing-data', { count: formattedDataOverview[MISSING_DATA_TAB].count })}
              value={MISSING_DATA_TAB}
              disabled={!formattedDataOverview[MISSING_DATA_TAB].count}
            />
            <Tab
              label={t('stats:data-overview.tabs.inconsistent-data', { count: formattedDataOverview[INCONSISTENT_DATA_TAB].count })}
              value={INCONSISTENT_DATA_TAB}
              disabled={!formattedDataOverview[INCONSISTENT_DATA_TAB].count}
            />
          </Tabs>
          {(currentTab !== MISSING_DATA_TAB) ? (
            <Container className={classes.tabContent}>
              {
                this.renderDataOverview(
                  currentTab,
                  formattedDataOverview[currentTab],
                  formattedDataOverview[currentTab].count,
                )
              }
            </Container>
          ) : (
            <Container className={classes.tabContent}>
              {![ELEMENT_TYPE_MODULE, ELEMENT_TYPE_CALCULATION].includes(elementType) && (
                <Container
                  className={classes.missingDataTypeRadios}
                >
                  <LabeledChoice
                    name="selected-missing-data-type"
                    label={t('stats:data-overview.sub-tabs.label')}
                    value={currentSubTab}
                    choices={[
                      {
                        text: `${t('stats:data-overview.sub-tabs.not-qualified')} (${formattedDataOverview[currentTab][NOT_QUALIFIED].count})`,
                        value: NOT_QUALIFIED,
                      },
                      {
                        text: `${t('stats:data-overview.sub-tabs.not-applicable')} (${formattedDataOverview[currentTab][NOT_APPLICABLE].count})`,
                        value: NOT_APPLICABLE,
                      },
                      {
                        text: `${t('stats:data-overview.sub-tabs.not-available')} (${formattedDataOverview[currentTab][NOT_AVAILABLE].count})`,
                        value: NOT_AVAILABLE,
                      },
                    ]}
                    onChange={(e) => {
                      const newSubTab = e.target.value;
                      if (currentSubTab !== newSubTab) {
                        this.setState({ currentSubTab: newSubTab });
                      }
                    }}
                    hideOptionalLabel
                    className="mb-0 stats-missing-data-type-selection"
                    colLabelClassName="col-auto pr-2"
                    disabledChoices={[NOT_QUALIFIED, NOT_APPLICABLE, NOT_AVAILABLE].filter(
                      (key) => formattedDataOverview[currentTab][key].count === 0,
                    )}
                  />
                </Container>
              )}
              {
                this.renderDataOverview(
                  currentTab,
                  formattedDataOverview[currentTab][currentSubTab],
                  formattedDataOverview[currentTab][currentSubTab].count,
                )
              }
            </Container>
          )}
        </Container>
      )
    );
  }

  openDataOverviewDialog = async () => {
    const {
      id, filtersParams, modalitySelected, showLoader,
    } = this.props;
    const { requestOverview } = this.state;

    if (requestOverview) {
      try {
        showLoader();
        const res = await api.requestData(
          `project-elements/${id}/data-overview`, null, 'post', undefined, {
            ...filtersParams,
            stats_modality_selected: modalitySelected,
          },
        );
        this.setState({
          dataOverview: res,
          requestOverview: false,
          openOverviewModal: true,
        });
      } catch (error) {
        ErrorUtil.handleCatched(this.props, error);
      } finally {
        showLoader(false);
      }
    } else {
      this.setState({ openOverviewModal: true });
    }
  }

  formatDataOverview = (dataOverview, tabs) => {
    const res = {
    };
    tabs.forEach((tab) => {
      if (tab === MISSING_DATA_TAB) {
        const notQualifiedMissingData = this.formatTabData(dataOverview[tab][NOT_QUALIFIED]);
        const notAvailableMissingData = this.formatTabData(dataOverview[tab][NOT_AVAILABLE]);
        const notApplicableMissingData = this.formatTabData(dataOverview[tab][NOT_APPLICABLE]);
        res[tab] = {
          count: notQualifiedMissingData.count + notAvailableMissingData.count
            + notApplicableMissingData.count,
          [NOT_QUALIFIED]: notQualifiedMissingData,
          [NOT_AVAILABLE]: notAvailableMissingData,
          [NOT_APPLICABLE]: notApplicableMissingData,
        };
      } else {
        res[tab] = this.formatTabData(dataOverview[tab]);
      }
    });
    return res;
  }

  formatTabData = (data) => {
    if (data) {
      const res = {
        inclusions: {},
        inclusionsId: [],
        instances: [],
        count: 0,
      };
      data.inclusions.forEach((inclusion) => {
        if (!res.inclusionsId.includes(inclusion.id)) {
          res.count += 1;
          res.inclusions[inclusion.id] = inclusion;
          res.inclusionsId.push(inclusion.id);
        }
        if (inclusion.instance_name) {
          if (!res.inclusions[inclusion.id].instances) {
            res.inclusions[inclusion.id].instances = [];
          }
          res.inclusions[inclusion.id].instances.push({
            id: inclusion.instance_id,
            name: inclusion.instance_name,
          });
        }
      });
      return res;
    }
    return {};
  }

  render() {
    const {
      t, name, classes, isModule, count, modalitySelected, isLinkTarget, modalityTitle,
      canUseAdvancedTools,
    } = this.props;
    const { openOverviewModal, dataOverview } = this.state;

    let containerClassName;
    if (!count) {
      containerClassName = (isModule) ? classes.containerEmptyModule : classes.containerEmpty;
    } else {
      containerClassName = classes.container;
    }

    let dialogClass = classes.root;
    if (modalitySelected) {
      if (isLinkTarget) {
        dialogClass = classes.smallLinkedRoot;
      } else {
        dialogClass = classes.smallRoot;
      }
    } else if (isLinkTarget) {
      dialogClass = classes.linkedRoot;
    }

    const elementName = ElementUtil.formatElementName({ name }, t);
    const formattedElementName = TextUtil.truncate(
      elementName,
      modalitySelected ? ELEMENT_NAME_MAX_LENGTH : ELEMENT_NAME_MAX_LENGTH * 2,
    );

    return (
      <>
        <Grid
          className={containerClassName}
          direction="row"
          container
          alignItems="center"
        >
          {Boolean(count) && (
            <Grid item style={{ paddingRight: '8px' }}>
              {t('stats:inclusions-count', { count })}
            </Grid>
          )}
          {canUseAdvancedTools && (
            <Grid item>
              <Button
                className={classes.openButton}
                variant="contained"
                onClick={() => this.openDataOverviewDialog()}
                onKeyPress={() => {}}
                onTouchEnd={() => {}}
                size="small"
                color="primary"
              >
                {t('stats:data-overview.see-detail-button')}
              </Button>
            </Grid>
          )}
        </Grid>
        <Modal
          contentClassName={classes.dialogContent}
          hideValidateButton
          open={openOverviewModal}
          onClose={() => this.setState({ openOverviewModal: false })}
          onClickClose={() => this.setState({ openOverviewModal: false })}
          paperProps={{ className: dialogClass }}
          title={(
            <span>
              <LightTooltip
                placement="top"
                title={(elementName === formattedElementName) ? '' : elementName}
                enterTouchDelay={50}
              >
                <span>
                  {formattedElementName}
                </span>
              </LightTooltip>
              {
                modalitySelected && modalityTitle && (
                  <span>
                    &nbsp;/&nbsp;
                    {modalityTitle}
                  </span>
                )
              }
            </span>
          )}

        >
          {
            dataOverview ? (
              this.renderModalContent()
            ) : (
              <Typography variant="body2">
                {t('stats:empty')}
              </Typography>
            )
          }
        </Modal>
      </>
    );
  }
}

export default DataOverviewModal;
