import common from './common.json';
import error from './error.json';
import project from './project.json';
import inclusion from './inclusion.json';
import user from './user.json';
import auth from './auth.json';
import stats from './stats.json';
import community from './community.json';

export default {
  common,
  error,
  project,
  inclusion,
  user,
  auth,
  stats,
  community,
};
