import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import moment from 'moment';
import { childrenPropTypes } from '../utils/generic-prop-types';
import { authActions, miscActions } from '../redux/actions';
import { nsOptions } from '../i18n';
import InitialLoader from './InitialLoader';
import {
  isAdmin, isAuthenticated, isVerified,
} from '../utils/data-util';
import { setLanguage } from '../utils/language';

export const checkVerified = (props) => {
  const { user } = props;
  if (!isAuthenticated(user) || !isVerified(user)) props.history.push('/');
};

export const checkNotVerified = (props) => {
  const { user } = props;
  if (!isAuthenticated(user) || isVerified(user)) props.history.push('/');
};

export const checkAuth = (props) => {
  const { user } = props;
  if (!isAuthenticated(user)) {
    props.setPendingUrl(props.location.pathname);
    props.history.push('/');
  }
};

export const checkNotAuth = (props) => {
  const { user } = props;
  if (isAuthenticated(user)) props.history.push('/');
};

export const checkAdmin = (props) => {
  const { user } = props;
  if (!isAdmin(user)) props.history.push('/');
};


const mapStateToProps = (state) => ({ user: state.auth.authUser });

const mapDispatchToProps = (dispatch) => ({
  tryAutoReAuth: async () => dispatch(authActions.tryAutoReAuth()),
  setPendingUrl: (url) => dispatch(miscActions.setPendingUrl(url)),
});


@connect(mapStateToProps, mapDispatchToProps)
@withTranslation('', nsOptions)
class Page extends Component {
  static propTypes = {
    id: PropTypes.string,
    className: PropTypes.string,
    key: PropTypes.string,
    title: PropTypes.string,
    i18n: PropTypes.shape().isRequired,
    tReady: PropTypes.bool,
    checkAuthorizations: PropTypes.arrayOf(PropTypes.oneOf([
      checkVerified,
      checkNotVerified,
      checkAuth,
      checkNotAuth,
      checkAdmin,
    ])),
    children: childrenPropTypes(),
    user: PropTypes.shape(),
    tryAutoReAuth: PropTypes.func.isRequired,
    setPendingUrl: PropTypes.func.isRequired,
  };

  static defaultProps = {
    id: undefined,
    className: undefined,
    key: undefined,
    title: '',
    user: undefined,
    checkAuthorizations: [checkVerified],
    children: undefined,
    tReady: undefined,
  };

  constructor(props) {
    super(props);
    this.state = {
      initialLoading: true,
      initialLoadingTitle: null,
      initialLoadingMessage: null,
    };
  }

  componentDidMount() {
    if (!isAuthenticated(this.props.user) && this.shouldAuthenticate()) {
      this.props.tryAutoReAuth().then(() => {
        this.initPage(true);
      }).catch(() => {
        this.initPage();
      });
    } else {
      this.initPage();
    }
  }

  componentDidUpdate(prevProps) {
    const { title } = this.props;
    if (prevProps.title !== title) {
      this.updateDocumentTitle();
    }
  }

  updateDocumentTitle = () => {
    const { title } = this.props;
    document.title = `Doqboard${title ? ` • ${title}` : ''}`;
  };

  manageLanguage(forceUpdate = false) {
    const { user, i18n } = this.props;
    if (user) {
      const lang = user.language;
      moment.locale(lang);
      if (i18n.language !== lang || forceUpdate) {
        setLanguage(i18n, lang);
      }
    }
  }

  initPage(forceLanguageUpdate = false) {
    const { checkAuthorizations } = this.props;
    this.updateDocumentTitle();
    checkAuthorizations.forEach((check) => check(this.props));
    this.manageLanguage(forceLanguageUpdate);
    this.setState({ initialLoading: false });
  }

  shouldAuthenticate() {
    const { checkAuthorizations } = this.props;
    if (!checkAuthorizations || checkAuthorizations.length === 0
      || checkAuthorizations.includes(checkNotAuth)) return false;
    return true;
  }

  render() {
    const {
      id, className, key, children, tReady,
    } = this.props;
    const { initialLoading, initialLoadingTitle, initialLoadingMessage } = this.state;

    return (
      <div
        id={id}
        className={className}
        key={key}
      >
        {
          (initialLoading || !tReady) ? (
            <InitialLoader
              loaderTitle={initialLoadingTitle}
              loaderMessage={initialLoadingMessage}
              {...this.props}
            />
          ) : children
        }
      </div>
    );
  }
}


export default Page;
