import React, { useEffect, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Button, Label } from 'reactstrap';
import { useParams } from 'react-router';
import { Link } from 'react-router-dom';
import Select from 'react-select';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  projectsActions, teamLicensesActions, teamsActions, teamMembersActions,
} from '../redux/actions';
import api from '../api';
import history from '../history';
import Pagination from '../components/Pagination';
import {
  Table, TableHead, TableRow, TableBody, TableCell,
} from '../components/CustomTable';
import { CardLoader } from '../components/Loader';
import Page from '../components/Page';
import DashboardHead from '../components/DashboardHead';
import NewTooltip from '../components/NewTooltip';
import TeamForm from '../components/TeamForm';
import BrowserView from '../components/BrowserView';
import MobileUnsupported from '../components/MobileUnsupported';
import TabletUnsupported from '../components/TabletUnsupported';
import TeamCreateModal from '../components/TeamCreateModal';
import useError from '../utils/HookErrorUtil';
import TextUtil from '../utils/TextUtil';
import Warning from '../components/Warning';
import TruncatedBadgeList from '../components/TruncatedBadgeList';
import Head from '../components/AdminHead';


const TEXT_MAX_LENGTH = 40;

export function TeamPage(props) {
  const { admin, userOwnTeams } = props;
  const { id } = useParams();

  const { handleCatched } = useError();
  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);

  const userId = useSelector((state) => state.auth.authUser.id);
  const tms = useSelector((state) => state.teams);

  const teams = useMemo(() => Object.values(tms), [tms]);
  const team = useMemo(() => teams.find((tm) => tm.id === Number(id)), [teams, id]);

  const dispatch = useDispatch();

  const fetchTeams = async (data) => dispatch(teamsActions.list(data, { admin, pagination: 'no' }));
  const fetchTeamLicenses = async () => dispatch(teamLicensesActions.list(
    {}, { pagination: 'no', useCache: true },
  ));
  const fetchTeamMembers = async (teamId) => dispatch(teamMembersActions.list(
    { team: teamId }, { pagination: 'no' },
  ));

  const fetchData = async () => {
    try {
      setLoading(true);
      if (!teams.length) {
        const params = {};
        if (!admin) params.owner = userId;

        await Promise.all([
          fetchTeams(params),
          fetchTeamLicenses(),
        ]);
      }
      await fetchTeamMembers(Number(id));
    } catch (error) {
      handleCatched(props, error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const returnToTeamsPage = () => {
    const baseUrl = admin ? 'admin' : 'dashboard';
    history.push(`/${baseUrl}/teams`);
  };

  return (
    <Page
      {...props}
      title={t('common:nav.teams')}
    >
      <DashboardHead
        {...props}
        title={t('common:nav.teams')}
        breadcrumdPrevious={[]}
      />
      <div className="dashboard-content mb-2">
        <div className="card bg-white table-responsive contains-loader card-shadow p-4 mt-3 mobile-card overflow-x-visible">
          {loading && <CardLoader />}
          {team && (
            <>
              <div className="mb-3">
                <h4 className="font-weight-normal list-title mb-0">
                  {team.name}
                </h4>
              </div>
              <div className="pt-3">
                <TeamForm
                  admin={admin}
                  userOwnTeams={userOwnTeams}
                  team={team}
                  onDeleteTeam={returnToTeamsPage}
                  {...props}
                />
              </div>
            </>
          )}
        </div>
      </div>
    </Page>
  );
}

TeamPage.propTypes = {
  admin: PropTypes.bool,
  match: PropTypes.shape({
    params: PropTypes.shape().isRequired,
  }).isRequired,
  userOwnTeams: PropTypes.bool,
};

TeamPage.defaultProps = {
  admin: false,
  userOwnTeams: false,
};

function TeamFilter(props) {
  const {
    items, onChange, placeholder, labelKey, className,
  } = props;

  const itemOptions = items.map((item) => ({ label: item[labelKey], value: item.id }));

  return (
    <Select
      className={`react-select ${className}`}
      classNamePrefix="react-select"
      onChange={(e) => {
        let newValue;
        if (e) {
          newValue = Number(e.value);
        }
        onChange(newValue);
      }}
      options={itemOptions}
      placeholder={placeholder}
      isClearable
    />
  );
}

TeamFilter.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  placeholder: PropTypes.string.isRequired,
  className: PropTypes.string,
  labelKey: PropTypes.string,
  onChange: PropTypes.func,
};

TeamFilter.defaultProps = {
  className: '',
  labelKey: 'label',
  onChange: () => {},
};


function Teams(props) {
  const { admin } = props;

  const { handleCatched } = useError();
  const { t } = useTranslation();

  let searchDelay;

  const authUser = useSelector((state) => state.auth.authUser);
  const tms = useSelector((state) => state.teams);
  // Sort teams: TExpert > TAdvanced > TPro
  const teams = useMemo(
    () => Object.values(tms).sort((a, b) => (a.licence > b.licence ? -1 : 1)),
    [tms],
  );
  const teamLicenses = useSelector((state) => state.teamLicenses);
  const pjts = useSelector((state) => state.projects);
  const projects = useMemo(() => Object.values(pjts), [pjts]);

  const [loading, setLoading] = useState(false);
  const [ready, setReady] = useState(false);
  const [page, setPage] = useState(1);
  const [teamCount, setTeamCount] = useState(0);
  const [pageSize, setPageSize] = useState(1);

  // Search filters
  const [owners, setOwners] = useState([]);
  const [leaders, setLeaders] = useState([]);
  const [filters, setFilters] = useState({ owner: undefined, leader: undefined });

  const dispatch = useDispatch();

  const fetchTeams = async (data) => dispatch(teamsActions.list(
    data, { pagination: 'short' },
  ));
  const fetchTeamLicenses = async () => dispatch(teamLicensesActions.list());
  const fetchProjects = async (data) => dispatch(projectsActions.list(data, { pagination: 'no' }));

  const fetchPaginatedTeams = async (currentPage) => {
    try {
      setLoading(true);

      const extraParams = {};
      if (admin) {
        if (filters.owner) extraParams.owner = filters.owner;
        if (filters.leader) extraParams.leader = filters.leader;
      } else {
        extraParams.owner = authUser.id;
      }

      const res = await fetchTeams({
        page: currentPage,
        ordering: '-licence__pk',
        admin,
        annotate_members_count: true,
        annotate_projects: true,
        annotate_first_leader: true,
        ...extraParams,
      });

      const teamIds = res.results.map((tm) => tm.id);

      await fetchProjects({ admin, team__in: teamIds });

      setPage(currentPage);
      setTeamCount(res.count);
      setPageSize(Math.max(res.results.length, pageSize));
    } catch (error) {
      handleCatched(props, error);
    } finally {
      setLoading(false);
    }
  };

  const fetchUsers = async (params = {}, saveFunction = () => {}) => {
    try {
      const res = await api.list('users', { admin, ...params }, { pagination: 'no' });
      saveFunction(res);
    } catch (error) {
      handleCatched(props, error, false);
    }
  };

  const fetchOwners = async () => fetchUsers({ team_owner: true }, setOwners);
  const fetchLeaders = async () => fetchUsers({ team_leader: true }, setLeaders);

  const fetchData = async () => {
    try {
      const promises = [fetchPaginatedTeams(1), fetchTeamLicenses()];
      if (admin) {
        promises.push(fetchOwners(), fetchLeaders());
      }
      await Promise.all(promises);
      setReady(true);
    } catch (error) {
      handleCatched(props, error);
    }
  };

  const handleSearch = async (key = '', value = '') => {
    const newFilters = { [key]: value };
    await setFilters((currentFilters) => ({ ...currentFilters, ...newFilters }));
  };

  const handleSearchOwner = (value) => handleSearch('owner', value);
  const handleSearchLeader = (value) => handleSearch('leader', value);

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    clearTimeout(searchDelay);
    searchDelay = setTimeout(() => fetchPaginatedTeams(1), 250);
  }, [filters.owner, filters.leader]);

  const cellLinkProps = {
    className: `text-center align-middle ${admin ? '' : 'cursor-default'}`,
    onClick: admin ? () => {} : (e) => { e.preventDefault(); e.stopPropagation(); },
  };

  return (
    <Page
      {...props}
      title={t('common:nav.teams')}
    >
      {admin ? (
        <Head
          title="Doqboard teams"
          subTitle="Find here Doqboard teams"
          admin
          {...props}
        />
      ) : (
        <DashboardHead
          {...props}
          title={t('common:nav.teams')}
          breadcrumdPrevious={[]}
        />
      )}
      <div className="dashboard-content mb-2">
        <div className="card bg-white table-responsive contains-loader card-shadow p-4 mt-3 mobile-card overflow-x-visible">
          <BrowserView>
            {!admin && (
              <h4 className="font-weight-normal list-title">
                {t('user:team.list-title')}
              </h4>
            )}
            <div className="row justify-content-between align-items-center mt-3">
              <div className="col-12 col-md-auto">
                <div className="row align-items-center">
                  <div className="col-auto text-bigger">
                    {teamCount}
                    &nbsp;
                    {t('common:dashboard.team', { count: teamCount })}
                  </div>
                  {admin && (
                    <>
                      <div className="col-auto d-flex align-items-center">
                        <Label
                          className="mr-2 mb-0"
                        >
                          Owner :
                        </Label>
                        <TeamFilter
                          key="owner-select"
                          items={owners}
                          placeholder="Select an owner"
                          onChange={handleSearchOwner}
                        />
                      </div>
                      <div className="col-auto d-flex align-items-center">
                        <Label
                          className="mr-2 mb-0"
                        >
                          Leader :
                        </Label>
                        <TeamFilter
                          key="leader-select"
                          items={leaders}
                          placeholder="Select a leader"
                          onChange={handleSearchLeader}
                        />
                      </div>
                    </>
                  )}
                </div>
              </div>
              <div className="col-12 col-md-auto">
                <TeamCreateModal
                  admin={admin}
                >
                  <Button
                    color="newturquoise-1"
                  >
                    <FontAwesomeIcon
                      icon={['fal', 'plus']}
                      transform="grow-3"
                      className="mr-2"
                    />
                    <span className="ml-1">
                      {t('user:team.add')}
                    </span>
                  </Button>
                </TeamCreateModal>
              </div>
            </div>
            <Table extraClassName="mt-3 mb-0">
              <TableHead>
                <TableRow>
                  <TableCell>
                    {t('user:team.name')}
                  </TableCell>
                  <TableCell>
                    {t('user:team.discipline')}
                  </TableCell>
                  {admin && (
                    <TableCell>
                      {t('user:team.title.owner')}
                    </TableCell>
                  )}
                  <TableCell>
                    {t('user:team.role.leader')}
                  </TableCell>
                  <TableCell>
                    {t('user:team.members')}
                  </TableCell>
                  <TableCell>
                    {t('user:team.projects')}
                  </TableCell>
                  <TableCell>
                    {t('user:team.license')}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {ready && !loading && teams.length > 0 ? (
                  teams.map((team) => {
                    const license = teamLicenses[team.licence];
                    const { name } = team;
                    const truncatedName = TextUtil.truncate(name, TEXT_MAX_LENGTH);
                    const teamProjects = projects.filter((project) => project.team === team.id)
                      .map((project) => project.name);
                    return (
                      <TableRow
                        key={team.id}
                        onClick={() => {
                          const baseUrl = admin ? 'admin' : 'dashboard';
                          const newUrl = `/${baseUrl}/team/${team.id}`;
                          history.push(newUrl);
                        }}
                      >
                        <TableCell>
                          <NewTooltip
                            content={name}
                            disabled={name === truncatedName}
                          >
                            <span className="font-weight-semibold">
                              {truncatedName}
                            </span>
                          </NewTooltip>
                        </TableCell>
                        <TableCell>
                          <TruncatedBadgeList
                            items={team.specialties.map((specialty) => t(`specialty:${specialty}`))}
                            badgeColor="badge-newyellow-2"
                            maxDisplayedItems={2}
                          />
                        </TableCell>
                        {admin && (
                          <TableCell>
                            {team.owner.label}
                          </TableCell>
                        )}
                        <TableCell>
                          {team.first_leader ? (
                            <span>
                              {team.first_leader}
                            </span>
                          ) : (
                            <Warning
                              placement="top"
                              tooltipTheme="dark"
                            >
                              {t('user:team.no-team-leader-tooltip')}
                            </Warning>
                          )}
                        </TableCell>
                        <TableCell>
                          {team.members_count || 0}
                        </TableCell>
                        <TableCell
                          {...cellLinkProps}
                        >
                          {teamProjects.length > 0 && !admin ? (
                            <Link
                              to="/dashboard/projects"
                            >
                              <NewTooltip
                                content={teamProjects.join(', ')}
                              >
                                <span>
                                  {teamProjects.length}
                                </span>
                              </NewTooltip>
                              <NewTooltip
                                content={t('user:team.view-projects')}
                              >
                                <FontAwesomeIcon
                                  icon={['far', 'external-link']}
                                  className="ml-2 text-gray"
                                  transform="shrink-3"
                                />
                              </NewTooltip>
                            </Link>
                          ) : (
                            <span>
                              {teamProjects.length}
                            </span>
                          )}
                        </TableCell>
                        <TableCell
                          {...cellLinkProps}
                        >
                          {admin ? (
                            <span>
                              {t(`user:licenses.team-${license.key}`)}
                            </span>
                          ) : (
                            <NewTooltip
                              content={t('user:team.view-licenses')}
                            >
                              <Link
                                to="/dashboard/licensed-products"
                              >
                                {t(`user:licenses.team-${license.key}`)}
                                <FontAwesomeIcon
                                  icon={['far', 'external-link']}
                                  className="ml-2 text-gray"
                                  transform="shrink-3"
                                />
                              </Link>
                            </NewTooltip>
                          )}
                        </TableCell>
                      </TableRow>
                    );
                  })
                ) : (
                  <TableRow>
                    <TableCell colSpan="5">
                      {loading || !ready ? (
                        <CardLoader />
                      ) : (
                        <span>
                          {t('user:team.no-teams')}
                        </span>
                      )}
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
            {teamCount > 10 && (
              <div className="mt-2">
                <Pagination
                  page={page}
                  count={teamCount}
                  pageSize={pageSize}
                  action={fetchPaginatedTeams}
                />
              </div>
            )}
          </BrowserView>
          <TabletUnsupported />
          <MobileUnsupported>
            common:mobile-and-tablet-unsupported
          </MobileUnsupported>
        </div>
      </div>
    </Page>
  );
}

Teams.propTypes = {
  admin: PropTypes.bool,
};

Teams.defaultProps = {
  admin: false,
};

export default Teams;
