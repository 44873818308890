export default null;

export const arraysEqual = (a1, a2, strictOrder = false, cmp = (e1, e2) => e1 === e2) => {
  if (!Array.isArray(a1) || !Array.isArray(a2)) return false;
  if (a1.length !== a2.length) return false;
  return a1.every((e1, index1) => {
    const index2 = a2.findIndex((e2) => cmp(e1, e2));
    if (index2 < 0 || (strictOrder && index1 !== index2)) return false;
    return true;
  });
};
