export const buildStdDbModelReducers = (idLabel = 'id') => ({
  createSuccessClbk: (state, action) => ({
    ...state,
    [action.payload[idLabel]]: action.payload,
  }),

  createMultipleSuccessClbk: (state, action) => {
    const newState = { ...state };
    action.payload.forEach((item) => { newState[item[idLabel]] = item; });
    return newState;
  },

  listSuccessClbk: (state, action) => {
    const newState = {};
    action.payload.forEach((item) => { newState[item[idLabel]] = item; });
    return newState;
  },

  readSuccessClbk: (state, action) => ({
    ...state,
    [action.payload[idLabel]]: action.payload,
  }),

  patchSuccessClbk: (state, action) => ({
    ...state,
    [action.payload[idLabel]]: action.payload,
  }),

  patchMultipleSuccessClbk: (state, action) => {
    const newState = { ...state };
    action.payload.forEach((item) => { newState[item[idLabel]] = item; });
    return newState;
  },

  removeSuccessClbk: (state, action) => {
    const key = `${action.payload}`;
    const { [key]: value, ...newState } = state;
    return newState;
  },

  removeMultipleSuccessClbk: (state, action) => {
    const newState = {};
    Object.keys(state).forEach((key) => {
      if (!action.payload.includes(parseInt(key, 10))) newState[key] = state[key];
    });
    return newState;
  },

  removeAllSuccessClbk: () => ({}),

  resyncSuccessClbk: (state, action) => {
    const newState = {};
    action.payload.forEach((item) => { newState[item[idLabel]] = item; });
    return newState;
  },

  ignoreDataSuccessClbk: (state) => state,
});

const getStdDbModelReduxMapping = (actions, idLabel = 'id') => {
  const {
    createSuccess,
    createMultipleSuccess,
    listSuccess,
    readSuccess,
    patchSuccess,
    patchMultipleSuccess,
    removeSuccess,
    removeMultipleSuccess,
    removeAllSuccess,
    resyncSuccess,
    ignoreDataSuccess,
  } = actions;

  const {
    createSuccessClbk,
    createMultipleSuccessClbk,
    listSuccessClbk,
    readSuccessClbk,
    patchSuccessClbk,
    patchMultipleSuccessClbk,
    removeSuccessClbk,
    removeMultipleSuccessClbk,
    removeAllSuccessClbk,
    resyncSuccessClbk,
    ignoreDataSuccessClbk,
  } = buildStdDbModelReducers(idLabel);

  return {
    [createSuccess]: createSuccessClbk,
    [createMultipleSuccess]: createMultipleSuccessClbk,
    [listSuccess]: listSuccessClbk,
    [readSuccess]: readSuccessClbk,
    [patchSuccess]: patchSuccessClbk,
    [patchMultipleSuccess]: patchMultipleSuccessClbk,
    [removeSuccess]: removeSuccessClbk,
    [removeMultipleSuccess]: removeMultipleSuccessClbk,
    [removeAllSuccess]: removeAllSuccessClbk,
    [resyncSuccess]: resyncSuccessClbk,
    [ignoreDataSuccess]: ignoreDataSuccessClbk,
  };
};

export default getStdDbModelReduxMapping;
