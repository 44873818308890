import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { FormGroup } from 'reactstrap';
import DbUtil from '../utils/DbUtil';
import { childrenPropTypes } from '../utils/generic-prop-types';
import FieldLabel from './FieldLabel';
import Validator from './Validator';


class LabeledInput extends Component {
  static propTypes = {
    label: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.shape(),
    ]),
    type: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    placeholder: PropTypes.string.isRequired,
    onChange: PropTypes.func,
    onValidChange: PropTypes.func,
    onInvalidChange: PropTypes.func,
    onValidityChange: PropTypes.func,
    onKeyPress: PropTypes.func,
    onPaste: PropTypes.func,
    onFocus: PropTypes.func,
    onBlur: PropTypes.func,
    smallInformation: PropTypes.oneOfType([PropTypes.element, PropTypes.node]),
    validation: PropTypes.string,
    required: PropTypes.bool,
    disabled: PropTypes.bool,
    defaultValue: PropTypes.string,
    value: PropTypes.string,
    className: PropTypes.string,
    rowClassName: PropTypes.string,
    colLabelClassName: PropTypes.string,
    labelClassName: PropTypes.string,
    colInputClassName: PropTypes.string,
    inputClassName: PropTypes.string,
    prependClassName: PropTypes.string,
    validatorClassName: PropTypes.string,
    appendClassName: PropTypes.string,
    validatorTooltipDistance: PropTypes.number,
    style: PropTypes.shape(),
    confirmValue: PropTypes.func,
    prepend: childrenPropTypes(),
    append: childrenPropTypes(),
    autoFocus: PropTypes.bool,
    hideOptionalLabel: PropTypes.bool,
    readOnly: PropTypes.bool,
    autoComplete: PropTypes.string,
  };

  static defaultProps = {
    onChange: () => {},
    onValidChange: () => {},
    onInvalidChange: () => {},
    onValidityChange: () => {},
    onKeyPress: () => {},
    onPaste: () => {},
    onFocus: () => {},
    onBlur: () => {},
    smallInformation: null,
    validation: undefined,
    required: false,
    confirmValue: undefined,
    disabled: false,
    defaultValue: undefined,
    value: undefined,
    className: '',
    rowClassName: 'row',
    colLabelClassName: 'col-12',
    labelClassName: 'mb-1',
    colInputClassName: 'col-12',
    inputClassName: 'labeled-input',
    prependClassName: '',
    validatorClassName: '',
    appendClassName: '',
    validatorTooltipDistance: undefined,
    style: undefined,
    prepend: undefined,
    append: undefined,
    autoFocus: null,
    hideOptionalLabel: false,
    readOnly: false,
    autoComplete: undefined,
    label: undefined,
  };

  constructor(props) {
    super(props);
    this.state = { validInput: this.props.validation === null };
    this.validator = null;
    this.id = `LabeledInput_${DbUtil.generateId()}`;
  }

  handleInput = (e) => {
    this.props.onChange(e);
    const isValid = this.validate(e.target.value);
    if (isValid) {
      this.props.onValidChange(e);
    } else {
      this.props.onInvalidChange(e);
    }
    if (isValid !== this.state.validInput) {
      this.setState({ validInput: isValid }, () => this.props.onValidityChange(isValid));
    }
  };

  validate = (value) => (
    this.validator ? this.validator.validate(value) : true
  );

  reset() {
    if (this.validator) this.validator.reset();
    this.setState({ validInput: false });
  }

  render() {
    const {
      rowClassName, colLabelClassName, labelClassName, colInputClassName, inputClassName,
      autoFocus, onKeyPress, prependClassName, validatorClassName, hideOptionalLabel, required,
      readOnly, autoComplete, label, prepend, validation, confirmValue, type, name, placeholder,
      disabled, defaultValue, value, style, append, smallInformation, className, appendClassName,
      onPaste, validatorTooltipDistance, onFocus, onBlur,
    } = this.props;

    const commonProps = {
      autoComplete,
      id: this.id,
      name,
      placeholder,
      disabled,
      className: inputClassName,
      defaultValue,
      value,
      onInput: this.handleInput,
      onChange: () => {}, // Prevent warning
      onPaste,
      onKeyPress,
      onFocus,
      onBlur,
      style,
      autoFocus,
      readOnly,
    };

    return (
      <FormGroup className={className}>
        <div className={`${rowClassName}`}>
          {
            label && (
              <div className={colLabelClassName}>
                <FieldLabel
                  className={labelClassName}
                  required={required || hideOptionalLabel}
                  for={this.id}
                >
                  {label}
                </FieldLabel>
              </div>
            )
          }
          <div className={colInputClassName}>
            {
              prepend ? (
                <div className={prependClassName}>
                  {prepend}
                </div>
              ) : null
            }
            <Validator
              className={validatorClassName}
              validation={validation}
              required={required}
              confirmValue={confirmValue}
              tooltipDistance={validatorTooltipDistance}
              ref={(validator) => { this.validator = validator; }}
            >
              {
                type === 'textarea' ? (
                  <textarea {...commonProps} />
                ) : (
                  <input type={type} {...commonProps} />
                )
              }
            </Validator>
            {
              append ? (
                <div className={appendClassName}>
                  {append}
                </div>
              ) : null
            }
            {
              smallInformation ? (
                <div className="authentication-small-info">
                  {smallInformation}
                </div>
              ) : null
            }
          </div>
        </div>
      </FormGroup>
    );
  }
}


export default LabeledInput;
