import React from 'react';
import withMsgModalBase from './withMsgModalBase';
import { LicMsgModal } from './MessageModal';


const withLicenseMsgModal = (forwardRef = false) => (ComponentBase) => {
  const ComponentWithLicenseMsgModal = withMsgModalBase(
    ComponentBase,
    LicMsgModal,
    'getLicMsgModalManager',
  );
  if (forwardRef) {
    return React.forwardRef((props, ref) => (
      <ComponentWithLicenseMsgModal {...props} forwardedRef={ref} />
    ));
  }
  return ComponentWithLicenseMsgModal;
};

export default withLicenseMsgModal;
