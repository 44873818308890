import PropTypes from 'prop-types';
import React, { Component } from 'react';
import '../assets/css/bigmenu.css';

// eslint-disable-next-line react/prefer-stateless-function
class DashboardMenu extends Component {
  render() {
    return (
      <div className="dashboard-menu row">
        {this.props.children}
      </div>
    );
  }
}

DashboardMenu.propTypes = {
  children: PropTypes.node.isRequired,
};

export default DashboardMenu;
