import { createReducer } from 'redux-starter-kit';
import { specialtiesActions } from '../actions';
import getStdDbModelReduxMapping from './std-db-model-reducers';

export const specialtiesInitialState = {};

const specialtiesReducer = createReducer(
  specialtiesInitialState,
  getStdDbModelReduxMapping(specialtiesActions, 'key'),
);

export default specialtiesReducer;
