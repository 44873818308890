import PropTypes from 'prop-types';
import React from 'react';
import { withTranslation } from 'react-i18next';
import { nsOptions } from '../i18n';
import {
  ACKNOWLEDGED, ACTIVE, FINISHED, ONGOING, STOPPED,
} from '../constants';

@withTranslation('', nsOptions)
class LabelStatus extends React.Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
    status: PropTypes.string.isRequired,
    callback: PropTypes.func,
    center: PropTypes.bool,
  };

  static defaultProps = {
    callback: null,
    center: false,
  };

  render() {
    const {
      status, t, callback, center,
    } = this.props;
    let color;
    let label;

    switch (status) {
      case ACTIVE:
        color = 'var(--newgreen-2)';
        label = t('project:notification.status.active');
        break;
      case ONGOING:
        color = 'var(--blue-3)';
        label = t('project:notification.status.ongoing');
        break;
      case ACKNOWLEDGED:
        color = 'var(--blue-5)';
        label = t('project:notification.status.acknowledged');
        break;
      case STOPPED:
        color = 'var(--newred-2)';
        label = t('project:notification.status.stopped');
        break;
      case FINISHED:
        color = 'var(--newgreen-1)';
        label = t('project:notification.status.finished');
        break;
      default: // inactive
        color = 'var(--newyellow-2)';
        label = t('project:notification.status.inactive');
    }

    return (
      <button
        style={{
          width: '90px',
          background: color,
          height: '30px',
          border: 'none',
          cursor: callback ? 'pointer' : 'default',
          color: 'inherit',
        }}
        className={`mt-3 mb-3 d-flex justify-content-center align-items-center ${center ? 'mx-auto' : ''}`}
        onClick={(e) => {
          e.preventDefault();
          if (callback) callback(e);
        }}
      >
        {label}
      </button>
    );
  }
}

export default LabelStatus;
