import React from 'react';
import PropTypes from 'prop-types';
import NewTooltip from './NewTooltip';

function TruncatedBadgeList(props) {
  const { items, badgeColor, maxDisplayedItems } = props;

  const count = items.length;

  if (count === 0) return '';

  return (
    <NewTooltip
      disabled={count <= maxDisplayedItems}
      content={(
        <span>{items.join(', ')}</span>
      )}
    >
      <div
        className="d-flex align-items-center justify-content-center"
        style={{ flexWrap: 'wrap' }}
      >
        {items.slice(0, maxDisplayedItems).map((item) => (
          <div
            className={`badge ${badgeColor} m-1 text-truncate`}
            style={{ maxWidth: 'inherit' }}
            key={item}
          >
            {item}
          </div>
        ))}
        {count > maxDisplayedItems && (
          <div>...</div>
        )}
      </div>
    </NewTooltip>
  );
}

TruncatedBadgeList.propTypes = {
  items: PropTypes.arrayOf(PropTypes.string).isRequired,
  badgeColor: PropTypes.string,
  maxDisplayedItems: PropTypes.number,
};

TruncatedBadgeList.defaultProps = {
  badgeColor: 'badge-turquoise',
  maxDisplayedItems: 3,
};

export default TruncatedBadgeList;
