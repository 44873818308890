import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { withToastManager } from 'react-toast-notifications';
import { Mutex } from 'async-mutex';
import { connect } from 'react-redux';
import { usersActions, miscActions } from '../redux/actions';
import AccountForm from '../components/AccountForm';
import AuthContainer from '../components/AuthContainer';
import NavButtons from '../components/NavButtons';
import Page, { checkNotAuth } from '../components/Page';
import history from '../history';
import { nsOptions } from '../i18n';
import Toast from '../utils/Toast';
import ErrorUtil from '../utils/ErrorUtil';


const mapStateToProps = (state) => ({
  pendingUrl: state.misc.pendingUrl,
});

const mapDispatchToProps = (dispatch) => ({
  createUser: (data) => dispatch(usersActions.create(data)),
  clearPendingUrl: () => dispatch(miscActions.clearPendingUrl()),
});


@withToastManager
@connect(mapStateToProps, mapDispatchToProps)
@withTranslation('', nsOptions)
class Register extends Component {
  static propTypes = {
    i18n: PropTypes.shape().isRequired,
    t: PropTypes.func.isRequired,
    pendingUrl: PropTypes.string.isRequired,
    createUser: PropTypes.func.isRequired,
    clearPendingUrl: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = { typeEmpty: true };
    this.mutex = new Mutex();
  }

  handleFormChange = (fieldsValues) => {
    this.fieldsValues = fieldsValues;
    this.setState({ typeEmpty: !fieldsValues.typeInputValue });
  };

  handleFormSubmit = async (event) => {
    event.preventDefault();

    if (!this.accountFormRef.validate()) return;

    const { createUser, clearPendingUrl, pendingUrl } = this.props;
    const {
      typeInputValue,
      civilityInputValue,
      subtypeInputValue,
      lastNameInputValue,
      firstNameInputValue,
      emailInputValue,
      passwordInputValue,
      specialtyInputValue,
      institutionInputValue,
      cityInputValue,
      countryInputValue,
      proNumberInputValue,
      languageInputValue,
      acronymInputValue,
      addressInputValue,
      zipCodeInputValue,
      phoneInputValue,
      websiteInputValue,
      emailAltInputValue,
      phoneAltInputValue,
    } = { ...this.fieldsValues };

    if (this.mutex.isLocked()) return;
    const release = await this.mutex.acquire();

    this.setState({});

    const data = {
      type: typeInputValue,
      civility: civilityInputValue,
      subtype: subtypeInputValue,
      last_name: lastNameInputValue,
      first_name: firstNameInputValue,
      email: emailInputValue,
      password: passwordInputValue,
      specialty: specialtyInputValue,
      institution: institutionInputValue,
      city: cityInputValue,
      country: countryInputValue,
      professional_number: proNumberInputValue,
      language: languageInputValue,
      username: acronymInputValue,
      address: addressInputValue,
      zip_code: zipCodeInputValue,
      phone_number: phoneInputValue,
      website: websiteInputValue,
      email_alt: emailAltInputValue,
      phone_number_alt: phoneAltInputValue,
    };

    Object.keys(data).forEach((key) => ([undefined, ''].includes(data[key]) ? delete data[key] : {}));

    const addInvitationTokenIfRelevant = (url, tokenField) => {
      if (pendingUrl.match(new RegExp(`^${url}.*$`))) {
        // Bind invitation to the new account
        const invitationToken = pendingUrl.split('/').pop();
        data[tokenField] = invitationToken;
      }
    };

    addInvitationTokenIfRelevant('/dashboard/join-project/', 'project_invitation_token');
    addInvitationTokenIfRelevant('/dashboard/join-team/', 'team_invitation_token');

    try {
      await createUser(data);
      clearPendingUrl(); // In any case pendingUrl is not needed starting now
      history.push('/auth/please-confirm');
    } catch (error) {
      this.setState({});
      if (error.message.match(new RegExp(`^(.*)duplicate(.*)${emailInputValue}(.*)$`))) {
        Toast.error(this.props, 'error:error.email-already-used');
      } else {
        ErrorUtil.handleCatched(this.props, error);
      }
    } finally {
      release();
    }
  };

  render() {
    const { t } = this.props;
    const { typeEmpty } = this.state;

    return (
      <Page
        {...this.props}
        title={t('auth:register')}
        checkAuthorizations={[checkNotAuth]}
      >
        <AuthContainer
          className="small-width"
          onSubmit={this.handleFormSubmit}
          top={{
            info: t('auth:pages.register.info'),
          }}
          bottom={{
            navContent: (
              <div>
                <NavButtons
                  className={typeEmpty ? ' invisible' : ''}
                  validateBtnLabel={t('auth:button.register')}
                  validateBtnClbk={this.handleFormSubmit}
                />
                <div className="col-12">
                  <div className="authentication-small-info">
                    <Link to="/auth/login">
                      {t('auth:button.login-to-doqboard')}
                    </Link>
                  </div>
                </div>
              </div>
            ),
          }}
        >
          <div className="">
            <AccountForm
              {...this.props}
              onFormChange={this.handleFormChange}
              ref={(ref) => { this.accountFormRef = ref; }}
            />
          </div>
        </AuthContainer>
      </Page>
    );
  }
}

export default Register;
