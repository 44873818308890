import { WEBSITE_URL } from '../constants';

export const getWebsiteUrl = (language) => {
  switch (language) {
    case 'fr':
      return WEBSITE_URL;

    case 'en':
    default:
      return WEBSITE_URL;
  }
};

export const getCgsuUrl = (language) => {
  switch (language) {
    case 'fr':
      return `${WEBSITE_URL}/cgvu?lang=fr`;

    case 'en':
    default:
      return `${WEBSITE_URL}/cgvu?lang=en`;
  }
};

export const getLegalUrl = (language) => {
  switch (language) {
    case 'fr':
      return `${WEBSITE_URL}/mentionslegales?lang=fr`;

    case 'en':
    default:
      return `${WEBSITE_URL}/mentionslegales?lang=en`;
  }
};

export const getFAQUrl = (language) => {
  switch (language) {
    case 'fr':
      return `${WEBSITE_URL}/centreaide?lang=fr`;

    case 'en':
    default:
      return `${WEBSITE_URL}/centreaide?lang=en`;
  }
};

export const getOffersUrl = (language) => {
  switch (language) {
    case 'fr':
      return `${WEBSITE_URL}/offres?lang=fr`;
    case 'en':
    default:
      return `${WEBSITE_URL}/offres?lang=en`;
  }
};

export const getFAQArticleUrl = (language, article) => {
  const lang = (language === 'fr') ? 'lang=fr' : 'lang=en';
  const baseUrl = `${WEBSITE_URL}/centreaide`;
  let questionId = '?questionId=';
  switch (article) {
    case 1:
      // Comment fonctionnent les abonnements dans Doqboard ?
      questionId = `${questionId}9ed841f6-aa2d-4e8c-8cd2-21d2e76030a6`;
      break;
    case 2:
      // Le mode inclusions tests
      questionId = `${questionId}c8b06295-d319-4d21-9d15-c1cdf23bff05`;
      break;
    case 3:
      // Tableau des inclusions : filtres, trier, rechercher et supprimer des inclusions
      questionId = `${questionId}24946123-fb0e-49c3-acd0-dbf76928ecbf`;
      break;
    case 4:
      // Inviter un centre investigateur externe à mon projet
      questionId = `${questionId}78a1b8f9-aacf-4c17-adcb-1b7c4e4deb74`;
      break;
    case 5:
      // Gérer la visibilité des résultats du projet
      questionId = `${questionId}ce6038f0-0a0f-4773-ac72-cb1df847bad6`;
      break;
    case 6:
      // A quoi servent les Équipes ?
      questionId = `${questionId}826a242b-610a-4281-b51c-0c5fe3412b1f`;
      break;
    case 7:
      // Quelles sont les différences entre un projet personnel et un projet d'équipe ?
      questionId = `${questionId}8d3ed310-d637-4923-a7db-93025bfb63d2`;
      break;
    case 8:
      // Les différents types de variables à ajouter à un formulaire
      questionId = `${questionId}480024a3-0f84-4a2a-8980-455291458951`;
      break;
    case 9:
      // Résultats : les trier, les filtrer et les exporter
      questionId = `${questionId}631e5b0f-b9c7-4fd4-acbe-2ff54b5c143c`;
      break;
    case 10:
      // Suivez les modifications apportées grâce à l’audit trail
      questionId = `${questionId}1b3eaf1d-d254-4980-98c8-40d7788e40f6`;
      break;
    case 11:
      // Contôler la validité d’une donnée
      questionId = `${questionId}5684ae33-3a48-4177-8265-7a12e578dd94`;
      break;
    case 12:
      // Qualifier les données manquantes
      questionId = `${questionId}aa9d149e-8f2a-4a6e-8280-abd33468771b`;
      break;
    case 13:
      // Gérer des liens logiques entre les éléments d'un formulaire
      questionId = `${questionId}ded0d18b-7316-4879-9ca5-f53186f03d74`;
      break;
    case 14:
      // Transférer des tableurs au format .CSV UTF-8
      questionId = `${questionId}aab3d44a-aa46-40f6-812c-00f8ffe3e02b`;
      break;
    default:
      questionId = '';
      break;
  }
  return `${baseUrl}${questionId ? `${questionId}&${lang}` : `?${lang}`}`;
};

export default null;
