import { Mutex } from 'async-mutex';
import {
  CAN_INCLUDE, LOCK_DEFAULT_TIMEOUT_S, CAN_EDIT_FORM_AND_DOCUMENTATIONS, PROJECT_CLOSED,
  PROJECT_IN_PROGRESS, CAN_INCLUDE_TEST_DATA, PROJECT_CLOSED_WITH_MODIFICATIONS,
} from '../constants';
import TimeoutHandler from './TimeoutHandler';
import api from '../api';

// Users
export const isAuthenticated = (user) => user !== undefined && user !== null && user !== '';

export const isVerified = (user) => user && user.verified;

export const isAdmin = (user) => user && user.is_staff;

// Projects
export const formatPageTitle = (page, t) => (
  page && page.title ? page.title : t('project:form.page-default-name')
);

export const getPageTitle = (project, pageId, t) => {
  let page;
  if (project && project.pages) page = project.pages.find((pg) => pg.id === pageId);
  return formatPageTitle(page, t);
};

/**
 * Return whether the project user has the permission or not.
 * @param {Object} projectUser
 * @param {String} permName
 * @param {Number} teamId (null search outside of teams, undefined means search anywhere)
 */
export const hasPermission = (projectUser, permName, teamId = null) => (
  Boolean(projectUser && projectUser.permissions.find((perm) => {
    let res = (perm.name === permName);
    if (teamId) {
      res = (res && perm.team === teamId);
    }
    return res;
  }))
);

export const isProjectDisabled = (project) => project.is_frozen || project.is_disabled;

export const isInclusionReadOnly = (inclusion, projectUser, projectUsers, project) => {
  try {
    if (isProjectDisabled(project)) return true;
    if (project.is_paused) return true;
    if (inclusion.team_is_frozen) return true;
    const userCanEdit = projectUser.teams.includes(inclusion.team);
    // If the user is the creator, in test mode, the rights CAN_INCLUDE and CAN_EDIT_FORM_AND_DOCS
    // are equivalent
    // If the user is not the inclusion creator, in test mode, we only take CAN_INCLUDE into account
    let userCanInclude;
    if (inclusion.is_test) {
      userCanInclude = hasPermission(projectUser, CAN_INCLUDE, inclusion.team)
        || hasPermission(projectUser, CAN_EDIT_FORM_AND_DOCUMENTATIONS, inclusion.team)
        || hasPermission(projectUser, CAN_INCLUDE_TEST_DATA, inclusion.team);
    } else {
      userCanInclude = hasPermission(projectUser, CAN_INCLUDE, inclusion.team);
    }
    const inclusionEditable = (project.status === PROJECT_CLOSED
      && project.closed_status_option === PROJECT_CLOSED_WITH_MODIFICATIONS)
      || (inclusion.is_test && project.status !== PROJECT_CLOSED)
      || (project.status === PROJECT_IN_PROGRESS);
    return !userCanInclude || !inclusionEditable
      || (inclusion.creator !== projectUser.user.id && !userCanEdit);
  } catch (error) {
    return true;
  }
};

export class DataLocker {
  static TIMEOUT_MARGIN_PERCENT = 0.33;

  static TIMEOUT_MS = (LOCK_DEFAULT_TIMEOUT_S * (1 - DataLocker.TIMEOUT_MARGIN_PERCENT)) * 1000;

  static lockUserMessage(lockRes, t) {
    return lockRes.lockedBy
      ? t('error:warning.object-already-locked', { label: lockRes.lockedBy })
      : t('error:warning.object-already-locked-anonymous');
  }

  constructor(dataType, subDataType = undefined, admin = false) {
    this.dataType = dataType;
    this.lockEndPoint = `${dataType.replace('_', '-')}-locks`;
    this.lockId = undefined;
    this.subDataType = subDataType;
    this.admin = admin;
    this.timeoutHandler = new TimeoutHandler();
    this.mutex = new Mutex();
  }

  /**
   * Private function used to update periodically the lock.
   * DO NOT USE EXTERNALLY.
   */
  updateLock = async () => {
    if (this.lockId) {
      const release = await this.mutex.acquire();
      try {
        await api.partial_update(this.lockEndPoint, this.lockId, { admin: this.admin });
        this.timeoutHandler.doAfterTimeout(this.updateLock, 0, DataLocker.TIMEOUT_MS);
      } catch (error) {
        console.error(error);
      } finally {
        release();
      }
    }
  };

  /**
   * Use it to lock a data (only reusable after unlock() call or in case of failure)
   * @param {any} dataId
   * @returns Object ({ success: true } | { success: false } | { success: false, lockedBy: name })
   */
  async tryLock(dataId, subDataValue = undefined, checkMutex = true) {
    const release = checkMutex ? await this.mutex.acquire() : () => {};
    if (this.lockId !== undefined) throw new Error('Lock already requested.');
    const data = { [this.dataType]: dataId };
    if (this.dataType && subDataValue) {
      data[this.subDataType] = subDataValue;
    }
    try {
      // Try to add the lock
      this.lockId = null;
      const res = await api.create(this.lockEndPoint, data, { admin: this.admin });
      this.lockId = res.id;
      this.timeoutHandler.doAfterTimeout(this.updateLock, 0, DataLocker.TIMEOUT_MS);
      return { success: true };
    } catch (error) {
      try {
        // Try to get the locker if existing
        this.lockId = undefined;
        const params = { ...data, admin: this.admin };
        const res = await api.list(this.lockEndPoint, params);
        if (res.results.length !== 1) throw new Error('Not able to retrieve locker.');
        return { success: false, lockedBy: res.results[0].creator.label };
      } catch (err) {
        return { success: false };
      }
    } finally {
      release();
    }
  }

  /**
   * Use it to unlock the data (only after a successfull tryLock() call)
   * @param {any} dataId
   */
  async unlock(checkMutex = true) {
    const release = checkMutex ? await this.mutex.acquire() : () => {};
    if (!this.lockId) throw new Error('No lock to remove.');
    try {
      await api.delete(this.lockEndPoint, this.lockId, { admin: this.admin });
    } catch (error) {
      console.error(error);
    } finally {
      this.lockId = undefined;
      release();
    }
  }

  async toggleLock(
    dataId,
    subDataValue = undefined,
  ) {
    const release = await this.mutex.acquire();
    let result;
    try {
      if (this.isLocked()) {
        await this.unlock(false);
        result = { locked: false, success: true };
      } else {
        const res = await this.tryLock(dataId, subDataValue, false);
        if (!res.success) {
          result = { locked: false, ...res };
        } else {
          result = { locked: true, ...res };
        }
      }
    } finally {
      release();
    }
    return result;
  }

  /**
   * Returns whether data has been locked or not
   */
  isLocked() {
    return Boolean(this.lockId);
  }
}
