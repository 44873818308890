import PropTypes from 'prop-types';
import React from 'react';
import MobileView from './MobileView';
import DeviceUnsupported from './DeviceUnsupported';

const MobileUnsupported = (props) => (
  <MobileView>
    <DeviceUnsupported>
      { props.children || 'common:mobile-unsupported' }
    </DeviceUnsupported>
  </MobileView>
);

MobileUnsupported.propTypes = {
  children: PropTypes.string,
};

MobileUnsupported.defaultProps = {
  children: '',
};

export default MobileUnsupported;
