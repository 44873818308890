import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Label } from 'reactstrap';
import { useParams } from 'react-router';
import { teamLicensesActions, teamMembersActions } from '../redux/actions';
import TeamForm from './TeamForm';
import LabeledSelect from './LabeledSelect';
import { CardLoader } from './Loader';
import useError from '../utils/HookErrorUtil';

export const filterTeams = (teams, userId, admin = false, ownTeams = false) => {
  if (admin) return Object.values(teams);
  return Object.values(teams).filter((tm) => (ownTeams && tm.owner.id === userId)
    || (!ownTeams && tm.owner.id !== userId));
};

function TeamForms(props) {
  const {
    admin, userOwnTeams, newTeamIds, onTeamFormOpen,
  } = props;

  const { handleCatched } = useError();
  const { t } = useTranslation();
  const { id: paramId } = useParams();

  const [loading, setLoading] = useState(true);
  const [currentTeamId, setCurrentTeamId] = useState(undefined);

  const userId = useSelector((state) => state.auth.authUser.id);
  const tmMembers = useSelector((state) => state.teamMembers);
  const tmLicenses = useSelector((state) => state.teamLicenses);
  const tms = useSelector((state) => state.teams);

  const teamMembers = useMemo(() => Object.values(tmMembers), [tmMembers]);
  const teamLicenses = useMemo(() => Object.values(tmLicenses), [tmLicenses]);
  const teams = useMemo(
    () => filterTeams(tms, userId, admin, userOwnTeams),
    [tms, userId, admin, userOwnTeams],
  );

  const dispatch = useDispatch();

  const fetchTeamLicenses = async () => dispatch(teamLicensesActions.list(
    {}, { pagination: 'no', useCache: true },
  ));
  const fetchTeamMembers = async (teamId) => dispatch(teamMembersActions.list(
    { team: teamId }, { pagination: 'no' },
  ));

  const isTeamLeader = (teamId) => {
    if (!teamMembers || !teamMembers.length) return false;
    return teamMembers.findIndex((member) => member.user.id === userId && member.leader
      && member.team === teamId) >= 0;
  };

  const getTeamMembers = async (team) => {
    setLoading(true);
    try {
      await fetchTeamMembers(team.id);
    } catch (error) {
      handleCatched(props, error, false);
    } finally {
      setLoading(false);
    }
  };

  const selectTeam = async (team) => {
    if (team) await getTeamMembers(team);
    setCurrentTeamId(team ? team.id : undefined);
  };

  const selectTeamFromId = async (id) => {
    const team = teams.find((tm) => tm.id === Number(id));
    await selectTeam(team);
    return team;
  };

  const selectFirstTeam = async () => {
    const team = teams.length ? teams[0] : undefined;
    await selectTeam(team);
    return team;
  };

  const fetchData = async () => {
    try {
      await fetchTeamLicenses();

      // Team selection
      if (paramId) await selectTeamFromId(paramId);
      else await selectFirstTeam();
    } catch (error) {
      handleCatched(props, error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    fetchData();
  }, [teams.length]);

  const renderSelectTeam = () => {
    const isLeader = isTeamLeader(currentTeamId);

    return (
      <div className="row d-flex justify-content-between align-items-start mt-4 mb-4 mx-4 mx-md-0">
        {
          Boolean(teams.length) && (
            <div className="col-12 col-sm-auto px-0">
              <div className="row align-items-center mx-0">
                <LabeledSelect
                  name="team"
                  label={(<span className="text-bigger">{t('user:team.select')}</span>)}
                  className="col-12 col-lg-auto mb-0"
                  rowClassName="row align-items-center"
                  selectClassName="custom-select team-select"
                  colLabelClassName="col-12 col-md-auto pl-0 mt-1"
                  colSelectClassName="col-12 col-md-auto pl-0 mb-3 mb-sm-0"
                  validatorClassName="w-100"
                  hideOptionalLabel
                  value={currentTeamId}
                  onChange={(e) => selectTeamFromId(e.target.value)}
                >
                  {
                    teams.map((team) => (
                      <option
                        key={team.id}
                        value={team.id}
                      >
                        {team.name}
                      </option>
                    ))
                  }
                </LabeledSelect>
                {!admin && (
                  <Label
                    className="col-12 col-lg-auto mt-2 mb-3 mb-sm-1 text-bigger"
                  >
                    <span>
                      {t(isLeader ? 'user:team.role.leader' : 'user:team.role.member').toUpperCase()}
                    </span>
                    {
                      !isLeader && (
                        <div className="d-inline">
                          <span>
                            {' ('}
                          </span>
                          <i>
                            {t('user:team.read-only')}
                          </i>
                          )
                        </div>
                      )
                    }
                  </Label>
                )}
              </div>
            </div>
          )
        }
      </div>
    );
  };

  return (
    <div>
      {loading && <CardLoader />}
      {teams.length > 1 && renderSelectTeam()}
      {currentTeamId && (
        <TeamForm
          key={`team-form-${currentTeamId}`}
          admin={admin}
          team={teams.find((tm) => tm.id === currentTeamId)}
          teamLicenses={teamLicenses}
          userOwnTeam={userOwnTeams}
          focusTeamNameOnOpen={newTeamIds.includes(currentTeamId)}
          onOpen={onTeamFormOpen}
          onDeleteTeam={selectFirstTeam}
          onQuitTeam={selectFirstTeam}
        />
      )}
    </div>
  );
}

TeamForms.propTypes = {
  admin: PropTypes.bool,
  userOwnTeams: PropTypes.bool,
  newTeamIds: PropTypes.arrayOf(PropTypes.number),
  onTeamFormOpen: PropTypes.func,
};

TeamForms.defaultProps = {
  admin: false,
  userOwnTeams: false,
  newTeamIds: [],
  onTeamFormOpen: () => {},
};

export default TeamForms;
