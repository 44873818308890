import {
  UNIQUE_CHOICE,
  MULTIPLE_CHOICES,
  MEASUREMENTS,
  TIME,
  DATE_TIME,
  MODULE,
  TITLE,
  ELEMENT_TYPE_MULTIPLE_CHOICES,
  ELEMENT_TYPE_UNIQUE_CHOICE,
  ELEMENT_TYPE_MEASUREMENT,
  ELEMENT_TYPE_DATE,
  ELEMENT_TYPE_TIME,
  ELEMENT_TYPE_MODULE,
  ELEMENT_TYPE_TITLE,
  ELEMENT_TYPE_CALCULATION,
  DATE_MONTH_YEAR,
  DATE_FULL,
  DATE_YEAR,
} from '../constants';
import Formatter from './Formatter';

export const isQuantitative = (type) => (
  [DATE_FULL, DATE_MONTH_YEAR, DATE_YEAR, DATE_TIME, TIME, MEASUREMENTS].includes(type)
);
export const isDate = (type) => [DATE_FULL, DATE_MONTH_YEAR, DATE_YEAR].includes(type);
export const isFullDate = (type) => type === DATE_FULL;
export const isMonthYearDate = (type) => type === DATE_MONTH_YEAR;
export const isYearDate = (type) => type === DATE_YEAR;
export const isDateTime = (type) => type === DATE_TIME;
export const isTime = (type) => type === TIME;

export const isDateOrTime = (type) => [
  DATE_FULL, DATE_MONTH_YEAR, DATE_YEAR, DATE_TIME, TIME,
].includes(type);

export const formatChartData = (elementStats) => {
  if (!elementStats.chart_data) {
    return [];
  }
  const lastIndex = elementStats.chart_data.length - 1;
  return elementStats.chart_data.map((row, i) => {
    const isLast = i === lastIndex;
    const formatter = new Formatter(
      null, null, null, false, true, isLast,
    );
    return {
      ...row,
      name: formatter.format(row.name),
      rawName: row.raw_name || row.name,
    };
  });
};


export const getStatsElementTypeFromElement = (element) => {
  switch (element.type) {
    case ELEMENT_TYPE_UNIQUE_CHOICE: return UNIQUE_CHOICE;
    case ELEMENT_TYPE_MULTIPLE_CHOICES: return MULTIPLE_CHOICES;
    case ELEMENT_TYPE_CALCULATION:
    case ELEMENT_TYPE_MEASUREMENT: return MEASUREMENTS;
    case ELEMENT_TYPE_DATE: return element.format;
    case ELEMENT_TYPE_TIME: return TIME;
    case ELEMENT_TYPE_MODULE: return MODULE;
    case ELEMENT_TYPE_TITLE: return TITLE;
    default: return null;
  }
};


export const isHidden = (element) => (
  getStatsElementTypeFromElement(element) === null
);
