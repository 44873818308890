import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { withToastManager } from 'react-toast-notifications';
import { nsOptions } from '../i18n';
import { childrenPropTypes } from '../utils/generic-prop-types';
import Toast from '../utils/Toast';
import ErrorUtil from '../utils/ErrorUtil';
import ButtonConfirm from './ButtonConfirm';
import Editor from './Editor';
import NewModal from './NewModal';
import NewTooltip from './NewTooltip';
import LicenseChecker from './LicenseChecker';


@withToastManager
@withTranslation('', nsOptions)
class ElementCommentManager extends Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
    children: childrenPropTypes().isRequired,
    element: PropTypes.shape().isRequired,
    elementName: PropTypes.string.isRequired,
    project: PropTypes.shape().isRequired,
    methods: PropTypes.shape().isRequired,
    autoOpenChoice: PropTypes.bool,
    hideValidationToast: PropTypes.bool,
  };

  static defaultProps = {
    autoOpenChoice: false,
    hideValidationToast: false,
  };

  constructor(props) {
    super(props);
    this.state = {
      commentAutoOpen: props.element.comment_auto_open,
      editorContent: props.element.comment,
    };
    this.modal = null;
    this.textarea = null;
    this.editor = null;
  }

  onAutoOpenChoiceChange = (event) => {
    this.setState({ commentAutoOpen: event.target.checked });
  };

  deleteComment = async () => {
    const { methods, hideValidationToast } = this.props;
    if (methods.patchElement) {
      try {
        await methods.patchElement({
          comment: '',
          comment_auto_open: false,
        });
        this.modal.hide();
        if (!hideValidationToast) {
          Toast.success(this.props, 'error:valid.saved');
        }
      } catch (error) {
        ErrorUtil.handleCatched(this.props, error);
      }
    }
  };

  updateComment = async () => {
    const { methods, autoOpenChoice, hideValidationToast } = this.props;
    const { commentAutoOpen, editorContent } = this.state;
    if (methods.patchElement && this.editor) {
      let value = editorContent;
      if (value === '<p>&nbsp;</p>') {
        value = null;
      }
      const autoOpen = value && autoOpenChoice ? commentAutoOpen : false;
      try {
        await methods.patchElement({
          comment: value,
          comment_auto_open: autoOpen,
        });
        this.modal.hide();
        if (!hideValidationToast) {
          Toast.success(this.props, 'error:valid.saved');
        }
      } catch (error) {
        ErrorUtil.handleCatched(this.props, error);
      }
    }
  };

  render() {
    const {
      t, autoOpenChoice, children, project,
    } = this.props;
    const { editorContent } = this.state;

    return (
      <LicenseChecker
        limName="can_add_modality_comment"
        limitations={project.limitations}
        bypassChecking={!autoOpenChoice}
        licMsgModalChildren={children}
      >
        <NewModal
          trigger={children}
          title={autoOpenChoice
            ? t('project:modal.comment.modality-title')
            : t('project:modal.comment.element-title')}
          xlHeader={this.props.elementName}
          size="md"
          type={2}
          footer={(
            <div className="row">
              <button
                type="button"
                className="btn btn-newblue-1 text-white px-3"
                onClick={this.updateComment}
                disabled={!editorContent}
              >
                {t('common:button.save')}
              </button>
              {
                this.props.element.comment ? (
                  <ButtonConfirm
                    onClick={this.deleteComment}
                  >
                    <button type="button" className="btn btn-link ml-2">
                      {t('common:button.delete')}
                    </button>
                  </ButtonConfirm>
                ) : (
                  <NewTooltip
                    content={t('common:tooltip.cancel')}
                  >
                    <button
                      type="button"
                      className="btn btn-link ml-2"
                      onClick={() => this.modal.hide()}
                    >
                      {t('common:button.cancel')}
                    </button>
                  </NewTooltip>
                )
              }
            </div>
          )}
          ref={(modal) => {
            this.modal = modal;
          }}
        >
          <div className="text-gray-dark pb-1">
            {t('project:modal.comment.subtitle')}
          </div>
          <div className="fake-input-with-border comment-input">
            <Editor
              data={this.props.element.comment || ''}
              onInit={(editor) => {
                this.editor = editor;
              }}
              onChange={(content) => this.setState({ editorContent: content })}
            />
          </div>
          {
            autoOpenChoice ? (
              <LicenseChecker
                limName="can_set_modality_auto_open_comment"
                limitations={project.limitations}
                triggerType="checkbox"
                undoTriggerChange={() => this.setState({ commentAutoOpen: false })}
              >
                <div className="mt-2">
                  <NewTooltip
                    content={t('project:modal.comment.force-opening-tooltip')}
                    placement="top"
                  >
                    <div className="custom-control custom-checkbox d-inline-block">
                      <input
                        type="checkbox"
                        className="custom-control-input"
                        id="autoOpen"
                        checked={this.state.commentAutoOpen}
                        onChange={this.onAutoOpenChoiceChange}
                      />
                      <label className="custom-control-label" htmlFor="autoOpen">
                        {t('project:modal.comment.force-opening')}
                      </label>
                    </div>
                  </NewTooltip>
                </div>
              </LicenseChecker>
            ) : null
          }
        </NewModal>
      </LicenseChecker>
    );
  }
}

export default ElementCommentManager;
