import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { withTranslation } from 'react-i18next';
import { nsOptions } from '../i18n';
import NewTooltip from './NewTooltip';


@withTranslation('', nsOptions)
class EditButton extends Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
    className: PropTypes.string,
    size: PropTypes.string,
    transform: PropTypes.string,
    onClick: PropTypes.func,
    active: PropTypes.bool,
    hideTooltip: PropTypes.bool,
  };

  static defaultProps = {
    className: '',
    size: undefined,
    transform: undefined,
    onClick: () => {},
    active: false,
    hideTooltip: false,
  };

  render() {
    const {
      t, className, onClick, active, size, transform, hideTooltip,
    } = this.props;
    const button = (
      <button
        className={`${className ? `${className} ` : ''}edit-button-item${active ? ' active' : ''}`}
        onClick={onClick}
      >
        <span className="edit-button-icon">
          <FontAwesomeIcon
            size={size}
            icon={['far', 'edit']}
            transform={transform}
          />
        </span>
      </button>
    );

    return hideTooltip ? button : (
      <NewTooltip content={t(`common:edition.${active ? 'end' : 'start'}`)}>
        {button}
      </NewTooltip>
    );
  }
}

export default EditButton;
