import { useTranslation } from 'react-i18next';
import useToast from './HookToast';
import {
  baseMatchOneErrorOverride, baseHandlePageNotFound, baseMakeApiErrorUserFriendly,
  baseHandleCatched,
} from './ErrorUtil';

export default function useError() {
  const { error } = useToast();
  const { t } = useTranslation();

  /**
   * Make api raw error messages more user friendly
   * @param {Object} props
   * @param {JSON} jsonError
   */
  function makeApiErrorUserFriendly(jsonError) {
    return baseMakeApiErrorUserFriendly(t, jsonError);
  }

  /**
   * Helper function to handle messages for catched errors
   * @param {Object} props
   * @param {Object} error
   * @param {Boolean} notifyUser
   */
  function handleCatched(props, err, notifyUser) {
    baseHandleCatched(props, err, notifyUser, error);
  }

  /**
   * Check if the message will be ovveriden
   * @param {any} errorCode
   */
  function matchOneErrorOverride(errorCode) {
    return baseMatchOneErrorOverride(errorCode);
  }

  /**
   * Helper function to handle page not found error
   * @param {Object} error
   * @param {String} currentPage
   * @param {function} action
   */
  async function handlePageNotFound(err, currentPage, action) {
    return baseHandlePageNotFound(err, currentPage, action);
  }

  return {
    handleCatched,
    matchOneErrorOverride,
    handlePageNotFound,
    makeApiErrorUserFriendly,
  };
}
