import { createAction } from 'redux-starter-kit';

const start = createAction('drag_and_drop/start');
const end = createAction('drag_end_drop/end');
const modalOpened = createAction('drag_and_drop/modal_opened');
const modalClosed = createAction('drag_end_drop/modal_closed');

export default {
  start,
  end,
  modalOpened,
  modalClosed,
};
