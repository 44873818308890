export const TITLE = 'title';
export const UNIQUE_CHOICE = 'unique-choice';
export const MULTIPLE_CHOICES = 'multiple-choices';
export const DATE_YEAR = 'year';
export const DATE_MONTH_YEAR = 'month-year';
export const DATE_FULL = 'full';
export const DATE_TIME = 'date-time';
export const TIME = 'time';
export const MEASUREMENTS = 'measurements';
export const MODULE = 'module';

export const DATE_FORMATS = [DATE_YEAR, DATE_MONTH_YEAR, DATE_FULL, DATE_TIME];

export const CAN_CLONE_PROJECT = 'can_clone_project';
export const CAN_DELETE_PROJECT = 'can_delete_project';
export const CAN_PAUSE_PROJECT = 'can_pause_project';
export const CAN_NAME_MANAGER = 'can_name_manager';
export const CAN_RENAME_PROJECT = 'can_rename_project';
export const CAN_CHANGE_PROJECT_CONNECTED_ACCOUNT = 'can_change_project_connected_account';
export const CAN_CHANGE_PROJECT_STATUS = 'can_change_project_status';
export const CAN_CONFIRM_REGULATORY_APPROVAL = 'can_confirm_regulatory_approval';
export const CAN_MODIFY_PROJECT_TOPICS = 'can_modify_project_topics';
export const CAN_CHANGE_PROJECT_STATS_ACCESS = 'can_change_project_stats_access';
export const CAN_CHANGE_PROJECT_VISIBILITY_STATUS = 'can_change_project_visibility_status';
export const CAN_EDIT_FORM_AND_DOCUMENTATIONS = 'can_edit_form_and_documentations';
export const CAN_VIEW_FORM_AND_DOCUMENTATIONS = 'can_view_form_and_documentations';
export const CAN_INVITE_PROJECT_TEAM = 'can_invite_project_team';
export const CAN_INVITE_EXTERNAL_TEAM = 'can_invite_external_team';
export const CAN_MANAGE_ADVANCED_PERMISSIONS = 'can_manage_advanced_permissions';
export const CAN_DELEGATE_ADVANCED_PERMISSIONS_MANAGEMENT = 'can_delegate_advanced_permissions_management';
export const CAN_INCLUDE = 'can_include';
export const CAN_INCLUDE_TEST_DATA = 'can_include_test_data';
export const CAN_INVITE_EXTERNAL = 'can_invite_external';
export const CAN_MANAGE_EXTERNAL_INVITATIONS = 'can_manage_external_invitations';
export const CAN_VIEW_EXTERNAL_INCLUSIONS = 'can_view_external_inclusions';
export const CAN_VIEW_PROJECT_RESULTS = 'can_view_project_results';
export const CAN_VIEW_TEAM_RESULTS = 'can_view_team_results';
export const CAN_INVITE_TEAM_MEMBER = 'can_invite_team_member';
export const CAN_MANAGE_TEAM_INVITATIONS = 'can_manage_team_invitations';

export const BASIC_EDITABLE_PERMS = [CAN_INCLUDE, CAN_INVITE_TEAM_MEMBER, CAN_VIEW_TEAM_RESULTS];
export const ADVANCED_EDITABLE_PERMS = [CAN_EDIT_FORM_AND_DOCUMENTATIONS,
  CAN_VIEW_EXTERNAL_INCLUSIONS, CAN_INVITE_EXTERNAL];
export const ADMIN_EDITABLE_PERMS = [CAN_MANAGE_ADVANCED_PERMISSIONS];

export const PROJECT_OWNER = 'owner';
export const PROJECT_MANAGER = 'manager';
export const PROJECT_CO_MANAGER = 'comanager';
export const PROJECT_COLLABORATOR = 'collaborator';
export const PROJECT_INVESTIGATOR = 'investigator';

export const PROJECT_PUBLIC = 'public';
export const PROJECT_NOT_PUBLIC = 'not_public';
export const PROJECT_PUBLIC_STATUS_REQUESTED = 'public_status_requested';

export const PROJECT_ON_HOLD = 'on_hold';
export const PROJECT_IN_PROGRESS = 'in_progress';
export const PROJECT_CLOSED = 'closed';

export const PROJECT_CLOSED_WITH_MODIFICATIONS = 'closed_with_modifications';
export const PROJECT_CLOSED_WITH_NO_MODIFICATIONS = 'closed_with_no_modifications';

export const PROJECT_STATS_ACCESS_ALL = 'all';
export const PROJECT_STATS_ACCESS_RESTRICTED = 'restricted';
export const PROJECT_STATS_ACCESS_NONE = 'none';

export const LOCK_MODEL_INCLUSION = 'inclusion';
export const LOCK_MODEL_PROJECT_USER = 'project_user';
export const LOCK_MODEL_TEAM_MEMBER = 'team_member';
export const LOCK_MODEL_PROJECT = 'project';
export const LOCK_MODEL_TEAM = 'team';
export const LOCK_SECTION = 'section';
export const LOCK_SECTION_DESCRIPTION = 'description';
export const LOCK_SECTION_RESOURCES = 'resources';
export const LOCK_SECTION_FORM = 'form';
export const LOCK_SECTION_NAME = 'name';
export const LOCK_SECTION_PAUSE_REASON = 'pause_reason';
export const LOCK_DEFAULT_TIMEOUT_S = 90;
export const LOCK_TOAST_TIMEOUT_S = 10000;

export const USER_TYPE_ASSISTANT = 'assistant';
export const USER_TYPE_INTERN = 'intern';
export const USER_TYPE_PRACTITIONER = 'practitioner';
export const USER_TYPE_ASSOCIATION = 'association';
export const USER_TYPE_INDUSTRIALIST = 'industrialist';
export const USER_TYPE_HEALTHCARE_FACILITY = 'healthcare-facility';

export const USER_LICENSE_TRIAL = 'user_license_trial';
export const TEAM_LICENSE_TRIAL = 'team_license_trial';

export const INDIVIDUAL_TYPES = [USER_TYPE_PRACTITIONER, USER_TYPE_INTERN, USER_TYPE_ASSISTANT];
export const INSTITUTION_TYPES = [USER_TYPE_ASSOCIATION, USER_TYPE_INDUSTRIALIST,
  USER_TYPE_HEALTHCARE_FACILITY];

export const USER_CIVILITY_MR = 'Mr';
export const USER_CIVILITY_MRS = 'Mrs';
export const USER_CIVILITY_OTHER = 'Other';
export const USER_CIVILITY_UNKNOWN = '';

export const USER_SUBTYPE_DOCTOR = 'doctor';
export const USER_SUBTYPE_PROFESSOR = 'professor';
export const USER_SUBTYPE_SECRETARY = 'secretary';
export const USER_SUBTYPE_NURSE = 'nurse';
export const USER_SUBTYPE_CRA = 'cra';
export const USER_SUBTYPE_OTHER = 'other';

export const ORGANIZATION_ASSOCIATION = 'association';
export const ORGANIZATION_INDUSTRIALIST = 'industrialist';
export const ORGANIZATION_HEALTHCARE_FACILITY = 'healthcare-facility';

export const ORGANIZATION_TYPES = [ORGANIZATION_ASSOCIATION, ORGANIZATION_HEALTHCARE_FACILITY,
  ORGANIZATION_INDUSTRIALIST];

export const POOL_STATS_FILTERS = 'stats-filters-pool';
export const POOL_PROJECT_MUTEXES = 'project-mutexes-pool';

export const IDENTIFICATION_LENGTH = 2;

export const CALCULATION_VARIABLE_PREFIX = 'var:';

export const WEBSITE_URL = 'https://doqboard.com';

export const CKEDITOR_VARIABLE_BEGINNING = '<span class=';
export const CKEDITOR_VARIABLE_END = '</span>';

export const ELEMENT_TYPE_CALCULATION = 'calculation';
export const ELEMENT_TYPE_DATE = 'date';
export const ELEMENT_TYPE_DIVIDER = 'divider';
export const ELEMENT_TYPE_INFORMATION = 'information';
export const ELEMENT_TYPE_MEASUREMENT = 'measurement';
export const ELEMENT_TYPE_MODULE = 'module';
export const ELEMENT_TYPE_MULTIPLE_CHOICES = 'multiplechoices';
export const ELEMENT_TYPE_SPACER = 'spacer';
export const ELEMENT_TYPE_TEXT = 'text';
export const ELEMENT_TYPE_TIME = 'time';
export const ELEMENT_TYPE_TITLE = 'title';
export const ELEMENT_TYPE_UNIQUE_CHOICE = 'uniquechoice';

export const ELEMENT_NOT_IDENTIFIER = 'not_id';
export const ELEMENT_INDIRECT_IDENTIFIER = 'indirect_id';
export const ELEMENT_DIRECT_IDENTIFIER = 'direct_id';

export const ENTRY_TYPE_DATE = 'date';
export const ENTRY_TYPE_MEASUREMENT = 'measurement';
export const ENTRY_TYPE_MODULE = 'module';
export const ENTRY_TYPE_MULTIPLE_CHOICES = 'multiplechoices';
export const ENTRY_TYPE_TEXT = 'text';
export const ENTRY_TYPE_TIME = 'time';
export const ENTRY_TYPE_UNIQUE_CHOICE = 'uniquechoice';

export const ELEMENTS_TO_FILL_TYPES = [ELEMENT_TYPE_MEASUREMENT, ELEMENT_TYPE_TEXT,
  ELEMENT_TYPE_UNIQUE_CHOICE, ELEMENT_TYPE_MULTIPLE_CHOICES, ELEMENT_TYPE_DATE,
  ELEMENT_TYPE_TIME, ELEMENT_TYPE_MODULE];

export const ELEMENTS_CONSISTENCY_TO_CHECK = [
  ELEMENT_TYPE_MEASUREMENT, ELEMENT_TYPE_CALCULATION, ELEMENT_TYPE_DATE,
];

export const ELEMENTS_CONSISTENCY_FIELDS = [
  'min', 'max', 'strict', 'integer', 'before', 'after', 'before_var', 'after_var',
  'less_than_inc_date', 'greater_than_inc_date',
];

// Links
export const LINK_OPERATOR_OR = 'OR';
export const LINK_OPERATOR_AND = 'AND';

export const TARGET_TYPE_ELEMENT = 'element';
export const TARGET_TYPE_PAGE = 'page';

// Inclusions sorting
export const SORTING_BY_DATE = '-updated_at';
export const ASCENDING_PROGRESSION = 'progression';
export const DESCENDING_PROGRESSION = '-progression';
export const SORTING_BY_INCONSISTENT_DATA = '-inconsistent_data_count';
export const ASCENDING_PER_PROJECT_ID = 'per_project_id';
export const DESCENDING_PER_PROJECT_ID = '-per_project_id';
export const SORTING_BY_QUALIFIED_MISSING_DATA = '-qualified_missing_data_count';

// Project user types
export const PROJECT_USER_USUAL = 'usual';
export const PROJECT_USER_REQUESTED_TO_JOIN = 'requested_to_join';
export const PROJECT_USER_OWNER_ONLY = 'owner_only';

// Team member types
export const TEAM_MEMBER_USUAL = 'usual';
export const TEAM_MEMBER_OWNER_ONLY = 'owner_only';

// File uploads
// Upload is limited to 10MB by file
export const FILE_UPLOAD_MAX_SIZE = 4;

// License unlimited number
export const UNLIMITED_NUMBER = 10000;

// Default suffix for truncated text
export const TRUNCATED_TEXT_SUFFIX = '...';

// Team frozen reason
export const NO_TEAM_LEADER = 'no_team_leader';

// Notifications
export const RECIPIENT_TYPE_INCLUSION_CREATOR = 'inclusion_creator';
export const CONDITION_TYPE_CALENDAR_DATE = 'calendar_date'; // the notification is based on a specific date
export const CONDITION_TYPE_DATE_VAR = 'date_variable'; // the notification is based on a variable (from a form)
export const CONDITION_TYPE_INCLUSION_DATE = 'inclusion_date'; // the notification is based on the inclusion date
export const INCLUSION_CREATOR = 'inclusion_creator';
export const ACTIVE = 'active';
export const INACTIVE = 'inactive';
export const ONGOING = 'ongoing';
export const ACKNOWLEDGED = 'acknowledged'; // -> it's done (in the form)
export const STOPPED = 'stopped'; // -> I do not need this notification (in the form)
export const FINISHED = 'finished';
export const SENT = 'sent';
export const PENDING = 'pending';
export const NOTIFICATION_STATUS = [ACTIVE, INACTIVE];
export const INCLUSION_NOTIFICATION_STATUS = ['ongoing', 'acknowledged', 'stopped', 'finished'];
export const DELAY_CONDITION_COMPLETION_DATE = 'completion_date';
export const DELAY_CONDITION_USER_INPUT_DATE = 'user_input_date';
export const DELAY_CONDITION = [DELAY_CONDITION_COMPLETION_DATE, DELAY_CONDITION_USER_INPUT_DATE];
export const UNIT_DAY = 'days';
export const UNIT_MONTH = 'months';
export const UNIT_YEAR = 'years';
export const MAX_FOLLOW_UPS = 1000;
