import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { withToastManager } from 'react-toast-notifications';
import { connect } from 'react-redux';
import InclusionsTable from './InclusionsTable';
import InclusionForm from './InclusionForm';
import { CardLoader } from './Loader';
import { nsOptions } from '../i18n';
import ErrorUtil from '../utils/ErrorUtil';
import ComponentLifeTracker from '../utils/ComponentLifeTracker';
import { projectUsersActions, inclusionsActions, projectsActions } from '../redux/actions';
import SortUtil from '../utils/SortUtil';
import fromReduxState from '../utils/redux';

const mapStateToProps = (state) => ({
  user: state.auth.authUser,
  inclusions: state.inclusions,
});

const mapDispatchToProps = (dispatch) => ({
  fetchInclusions: async (params, compLifeTracker) => dispatch(inclusionsActions.list(
    params, { pagination: 'short' }, () => compLifeTracker.isUnmounted(),
  )),
  resyncInclusion: async (inclusion, compLifeTracker) => (
    dispatch(inclusionsActions.read(inclusion.id, {}, () => compLifeTracker.isUnmounted()))
  ),
  clearInclusions: () => dispatch(inclusionsActions.removeAllSuccess()),
  fetchProjectUsers: async (projectIds, compLifeTracker) => dispatch(projectUsersActions.list({
    project__in: projectIds,
  }, { pagination: 'no' }, () => compLifeTracker.isUnmounted())),
  fetchProjects: async (projectIds, compLifeTracker) => dispatch(projectsActions.list({
    id__in: projectIds,
  }, { pagination: 'no' }, () => compLifeTracker.isUnmounted())),
});

@withToastManager
@connect(mapStateToProps, mapDispatchToProps)
@withTranslation('', nsOptions)
class DashboardInclusions extends Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
    user: PropTypes.shape().isRequired,
    inclusions: PropTypes.shape().isRequired,
    fetchInclusions: PropTypes.func.isRequired,
    resyncInclusion: PropTypes.func.isRequired,
    clearInclusions: PropTypes.func.isRequired,
    fetchProjectUsers: PropTypes.func.isRequired,
    fetchProjects: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      loading: false,
    };
    this.compLifeTracker = new ComponentLifeTracker();
    this.toInclusionsArray = fromReduxState((inclusions) => (
      Object.values(inclusions).sort(SortUtil.sortInclusionsByDate)
    ));
  }

  componentDidMount() {
    this.compLifeTracker.setMounted();
    this.fetchData();
  }

  componentWillUnmount() {
    this.compLifeTracker.setUnmounted();
  }

  resyncInclusion = async (inclusion) => {
    if (inclusion) {
      const { resyncInclusion } = this.props;
      try {
        await resyncInclusion(inclusion, this.compLifeTracker);
      } catch (error) {
        ErrorUtil.handleCatched(this.props, error, false);
      }
    }
  }

  async fetchData() {
    this.setState({ loading: true });

    const {
      clearInclusions, fetchInclusions, fetchProjectUsers, fetchProjects, user,
    } = this.props;

    clearInclusions();

    try {
      const res = await fetchInclusions(
        { creator: user.id, ordering: '-updated_at', is_test: false },
        this.compLifeTracker,
      );

      if (this.compLifeTracker.isMounted()) {
        const inclusions = res.results;

        const projectIds = [...new Set(inclusions.map((inclusion) => inclusion.project))];

        await Promise.all([
          fetchProjects(projectIds, this.compLifeTracker),
          fetchProjectUsers(projectIds, this.compLifeTracker),
        ]);
      }
    } catch (error) {
      ErrorUtil.handleCatched(this.props, error, false);
    } finally {
      if (this.compLifeTracker.isMounted()) {
        this.setState({ loading: false });
      }
    }
  }

  render() {
    const { t, user, inclusions: incls } = this.props;
    const { loading } = this.state;
    const {
      can_include_as_project_manager: canIncludeAsProjectManager,
      can_join_project_as_external: canJoinProjectAsExternal,
      can_join_project_in_team: canJoinProjectInTeam,
    } = user.limitations;
    const showIdentification = canIncludeAsProjectManager || canJoinProjectAsExternal
      || canJoinProjectInTeam;
    const inclusions = this.toInclusionsArray(incls);

    return (
      <>
        <InclusionForm
          ref={(ref) => { this.inclusionForm = ref; }}
          onClose={this.resyncInclusion}
        />
        <div className="contains-loader">
          { loading && <CardLoader /> }
          <h4 className="font-weight-normal mb-3">
            {t('common:dashboard.your-recent-inclusions')}
          </h4>
          <InclusionsTable
            inclusions={loading ? [] : inclusions}
            inclusionForm={this.inclusionForm}
            showProject
            showLastChangeDate
            showIdentification={showIdentification}
            loading={loading}
            showDataMonitoring
          />
        </div>
      </>
    );
  }
}

export default DashboardInclusions;
