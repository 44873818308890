import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import withStyles from '@material-ui/core/styles/withStyles';
import Tooltip from '@material-ui/core/Tooltip';
import { nsOptions } from '../../i18n';

const styles = (theme) => ({
  icon: {
    marginBottom: theme.spacing(0.1),
    color: theme.palette.text.primary,
  },
});


@withTranslation('', nsOptions)
@withStyles(styles)
class FilteredIcon extends Component {
  static propTypes = {
    className: PropTypes.string,
    classes: PropTypes.shape().isRequired,
    t: PropTypes.func.isRequired,
  };

  static defaultProps = {
    className: '',
  };

  render() {
    const { t, className, classes } = this.props;

    return (
      <Tooltip title={t('stats:filtered-tooltip')} placement="top">
        <span className={className}>
          <FontAwesomeIcon
            icon={['fas', 'filter']}
            className={classes.icon}
            size="xs"
          />
        </span>
      </Tooltip>
    );
  }
}


export default FilteredIcon;
