// import moment from 'moment';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import React, { Component } from 'react';
import moment from 'moment';
import DatePickerInput from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { nsOptions } from '../i18n';
import { TIME_FORMAT } from '../utils/date';

class CustomInput extends Component {
  static propTypes = {
    onChange: PropTypes.func,
  };

  static defaultProps = {
    onChange: () => {},
  };

  static checkChars(str) {
    return str.match(new RegExp('^[0-9:]*$'));
  }

  onChange = (e) => {
    if (CustomInput.checkChars(e.target.value)) {
      this.props.onChange(e);
    }
  };

  render() {
    const { onChange, ...remainingProps } = this.props;
    return (
      <input
        {...remainingProps}
        onChange={this.onChange}
      />
    );
  }
}


@withTranslation('', nsOptions)
class TimePicker extends Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
    i18n: PropTypes.shape().isRequired,
    id: PropTypes.string,
    onChange: PropTypes.func,
    placeholder: PropTypes.string,
    disabled: PropTypes.bool,
    defaultValue: PropTypes.shape(),
  };

  static defaultProps = {
    id: null,
    onChange: null,
    placeholder: null,
    disabled: false,
    defaultValue: undefined,
  };

  constructor(props) {
    super(props);
    this.state = { value: props.defaultValue };
  }

  onChange = (time) => {
    this.setState({ value: time });
    this.props.onChange(time ? moment(time).format(TIME_FORMAT) : null);
  };

  render() {
    const {
      id, disabled, i18n, placeholder, t,
    } = this.props;
    const { value } = this.state;

    return (
      <DatePickerInput
        id={id}
        onChange={this.onChange}
        strictParsing
        selected={value}
        className="react-datepicker-input"
        placeholderText={placeholder}
        disabled={disabled}
        timeFormat={t('common:elements.time-format')}
        dateFormat={t('common:elements.time-format')}
        disabledKeyboardNavigation
        showTimeSelect
        showTimeSelectOnly
        timeIntervals={15}
        timeCaption={t('common:elements.time-caption')}
        isClearable={!disabled}
        locale={i18n.language}
        customInput={<CustomInput />}
        ref={(calendar) => {
          this.calendar = calendar;
        }}
      />
    );
  }
}


export default TimePicker;
