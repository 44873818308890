import PropTypes from 'prop-types';
import React, {
  useEffect, useMemo, useRef, useState,
} from 'react';
import { withRouter } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { Mutex } from 'async-mutex';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  usersActions, countriesActions, projectsActions, projectUsersActions,
} from '../redux/actions';
import history from '../history';
import defaultLogo from '../assets/img/organization_default_logo.png';
import Editor from './Editor';
import TopicSelect from './TopicSelect';
import LabeledInput from './LabeledInput';
import { CardLoader, MiniLoader } from './Loader';
import Pagination from './Pagination';
import ImageCropper from './ImageCropper';
import useError from '../utils/HookErrorUtil';
import useToast from '../utils/HookToast';
import TextUtil from '../utils/TextUtil';
import TimeoutHandler from '../utils/TimeoutHandler';
import { childrenPropTypes } from '../utils/generic-prop-types';
import { Section, SectionLabel } from './AccountForm';
import Help from './Help';
import NewTooltip from './NewTooltip';
import MixedView, { isMixedView } from './MixedView';
import MobileView, { isMobileView } from './MobileView';
import {
  Table, TableHead, TableBody, TableRow, TableCell,
} from './CustomTable';
import { PROJECT_USER_REQUESTED_TO_JOIN, PROJECT_PUBLIC } from '../constants';
import TruncatedBadgeList from './TruncatedBadgeList';

function Card(props) {
  return (
    <div className={props.className}>
      <div
        className={`card border-0 ${props.cardClassName}`}
        style={props.shadow ? { boxShadow: 'var(--gray) 0 2px 6px', border: 'none' } : {}}
      >
        {props.header && (
          <div className={`card-header ${props.headerClassName}`}>
            <span>
              {props.header}
            </span>
            {props.help && (
              <Help
                iconClassName="ml-2"
                className="text-left text-break"
              >
                {props.help}
              </Help>
            )}
          </div>
        )}
        <div className={`card-body ${props.bodyClassName}`}>
          {props.body}
        </div>
      </div>
    </div>
  );
}

Card.propTypes = {
  className: PropTypes.string,
  cardClassName: PropTypes.string,
  headerClassName: PropTypes.string,
  bodyClassName: PropTypes.string,
  header: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  help: PropTypes.string,
  shadow: PropTypes.bool,
  body: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
};

Card.defaultProps = {
  className: 'mb-4',
  cardClassName: 'bg-white',
  headerClassName: 'bg-white',
  bodyClassName: '',
  header: '',
  help: '',
  shadow: true,
};

function ProfileHeader(props) {
  const {
    onChange, text, tooltipText, isPreview, switchLabel,
  } = props;

  return (
    <div className="d-flex align-items-center justify-content-between mb-3">
      <div className="community-card-subtitle">
        {text}
      </div>
      <div>
        <NewTooltip
          content={tooltipText}
          disabled={!tooltipText}
        >
          <div className="custom-control custom-switch preview-switch">
            <input
              className="custom-control-input"
              type="checkbox"
              id="preview-switch"
              checked={isPreview}
              onChange={onChange}
            />
            <label
              className="custom-control-label"
              htmlFor="preview-switch"
            >
              {switchLabel}
            </label>
          </div>
        </NewTooltip>
      </div>
    </div>
  );
}

ProfileHeader.propTypes = {
  onChange: PropTypes.func.isRequired,
  switchLabel: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  tooltipText: PropTypes.string,
  isPreview: PropTypes.bool,
};

ProfileHeader.defaultProps = {
  tooltipText: '',
  isPreview: false,
};

const MobileProjectItem = (props) => {
  const { children, className } = props;
  return (
    <div className={`col-sm-6 text-center ${className}`}>
      <div className="d-inline">
        {children}
      </div>
    </div>
  );
};

MobileProjectItem.propTypes = {
  children: childrenPropTypes().isRequired,
  className: PropTypes.string,
};

MobileProjectItem.defaultProps = {
  className: '',
};

function OrganizationProfile(props) {
  const {
    isEditMode, match, admin, organization,
  } = props;
  const timeoutHandler = useRef(null);
  const mutex = useRef(null);
  const topicsInput = useRef(null);
  const { success } = useToast();
  const { handleCatched } = useError();
  const { t } = useTranslation();

  let publicProfile;

  if (admin && organization) {
    publicProfile = organization;
  } else {
    publicProfile = match.params.id
      ? useSelector((state) => state.users[Number(match.params.id)])
      : useSelector((state) => state.auth.authUser);
  }

  const authUser = useSelector((state) => state.auth.authUser);
  const countries = useSelector((state) => state.countries);
  const prjs = useSelector((state) => state.projects);
  const prjUsers = useSelector((state) => state.projectUsers);

  const projects = useMemo(() => Object.values(prjs), [prjs]);
  const projectUsers = useMemo(() => Object.values(prjUsers), [prjUsers]);

  const [isPreview, setIsPreview] = useState(false);
  const [canEdit, setCanEdit] = useState(isEditMode);
  const [loading, setLoading] = useState(false);
  const [ready, setReady] = useState(false);
  const [logoURL, setLogoURL] = useState(publicProfile ? publicProfile.logo : undefined);
  const [websiteInputValue, setWebsiteInputValue] = useState(publicProfile ? publicProfile.website : '');
  const [contactEmailInputValue, setContactEmailInputValue] = useState(publicProfile ? publicProfile.contact_email : '');
  const [acronymInputValue, setAcronymInputValue] = useState(publicProfile ? publicProfile.username : '');
  const [projectsLoading, setProjectsLoading] = useState(false);
  const [projectsPage, setProjectsPage] = useState(1);
  const [projectsCount, setProjectsCount] = useState(0);
  const [projectsPageSize, setProjectsPageSize] = useState(1);
  const [editingAreaVisible, setEditingAreaVisible] = useState(false);
  const [projectLoading, setProjectLoading] = useState(null);

  const dispatch = useDispatch();

  const addProjectUser = async (data, params) => dispatch(projectUsersActions.create(data, params));
  const patchUser = async (id, data) => dispatch(usersActions.patch(id, data, { admin }));
  const fetchUser = async (id, params = {}) => dispatch(usersActions.read(
    id,
    { admin, ...params },
  ));
  const fetchCountry = async (id) => dispatch(countriesActions.read(id, {}));
  const fetchProjects = async (data) => dispatch(projectsActions.list(data, { pagination: 'short' }));
  const fetchProjectUsers = async (data) => dispatch(projectUsersActions.list(data, { pagination: 'no' }));
  const clearProjects = async () => dispatch(projectsActions.removeAllSuccess());

  const fetchPaginatedProjects = async (page, profile = undefined, preview = false) => {
    try {
      setProjectsLoading(true);
      const otherParams = {};
      if (!canEdit || preview) otherParams.visibility_status = PROJECT_PUBLIC;

      const res = await fetchProjects({
        page,
        ordering: '-created_at',
        owner: profile ? profile.id : publicProfile.id,
        ...otherParams,
      });

      const projectIds = res.results.map((project) => project.id);

      // No need to fetch projectUsers if the preview mode is enabled
      if (!preview && !isPreview) {
        await fetchProjectUsers({
          project__in: projectIds,
          ...otherParams,
        });
      }

      setProjectsPage(page);
      setProjectsCount(res.count);
      setProjectsPageSize(Math.max(res.results.length, projectsPageSize));
    } catch (error) {
      handleCatched(props, error);
    } finally {
      setProjectsLoading(false);
    }
  };

  const fetchData = async (loadUser = false, initialization = false) => {
    try {
      setLoading(true);
      let profile = publicProfile;
      if (loadUser) {
        profile = await fetchUser(Number(match.params.id), { public_profile: true });
        setLogoURL(profile.logo);
        setWebsiteInputValue(profile.website);
      }
      if (initialization) {
        await clearProjects();
      }
      await fetchCountry(profile.country);
      if (!canEdit) await fetchPaginatedProjects(1, profile);
    } catch (error) {
      handleCatched(props, error);
      const baseUrl = `${admin ? '/admin/organizations' : '/dashboard/community'}`;
      history.push(`${baseUrl}/${isEditMode ? 'projects' : 'profiles'}`);
    } finally {
      setLoading(false);
      setReady(true);
    }
  };

  const openPreview = async () => {
    try {
      setLoading(true);
      await fetchPaginatedProjects(1, publicProfile, true);
    } catch (error) {
      handleCatched(props, error);
    } finally {
      setLoading(false);
      setIsPreview(true);
      setCanEdit(false);
    }
  };

  useEffect(() => {
    timeoutHandler.current = new TimeoutHandler();
    mutex.current = new Mutex();
    fetchData(match.params.id && !publicProfile, true);
  }, []);

  const saveInput = async (patchFunction, id, key, value) => (
    new Promise((resolve) => {
      timeoutHandler.current.doAfterTimeout(async () => {
        try {
          const data = {};
          data[key] = value;
          await patchFunction(id, data);
          success('error:valid.success');
          resolve();
        } catch (error) {
          handleCatched(props, error);
          resolve();
        }
      });
    })
  );

  const saveUser = (key, value) => saveInput(patchUser, publicProfile.id, key, value);

  const handleLogoChange = async (image) => {
    try {
      const data = new FormData();
      data.append('logo', image);
      await patchUser(publicProfile.id, data);
      setLogoURL(image);
      setEditingAreaVisible(false);
      success('error:valid.success');
    } catch (error) {
      handleCatched(props, error);
    }
  };

  const requestToJoinProject = async (id) => {
    if (!mutex.current.isLocked()) {
      const release = await mutex.current.acquire();
      setProjectLoading(id);
      try {
        await addProjectUser({
          project: id,
          user: authUser.id,
          email: authUser.email,
        }, { visibility_status: PROJECT_PUBLIC });
        success('error:valid.success');
      } catch (error) {
        handleCatched(props, error);
      } finally {
        release();
        setProjectLoading(null);
      }
    }
  };

  const labelInputParams = {
    colLabelClassName: 'col-auto',
    colInputClassName: 'col-auto',
    hideOptionalLabel: true,
  };

  const inputClassNames = {
    rowClassName: 'row align-items-center',
    colLabelClassName: 'col-12 col-sm-12 col-md-4 col-lg-3 col-xl-2',
    labelClassName: 'organization-profile-label mb-0',
    colInputClassName: 'col-auto',
  };

  const renderName = () => {
    const label = (
      <SectionLabel>
        <span className="font-weight-semibold">
          {t('user:last-name')}
        </span>
      </SectionLabel>
    );
    return (
      <div className="row align-items-center">
        <div className="col-12 col-sm-12 col-md-4 col-lg-3 col-xl-2">
          {label}
        </div>
        <div className="col-12 col-sm-12 col-md-6">
          {publicProfile.institution}
        </div>
      </div>
    );
  };

  const renderAcronym = () => {
    const label = (
      <SectionLabel>
        <span className="font-weight-semibold">
          {t('user:acronym')}
        </span>
      </SectionLabel>
    );
    return (
      <LabeledInput
        {...labelInputParams}
        {...inputClassNames}
        label={label}
        type="text"
        name="organization-acronym"
        validation="city"
        onValidChange={(e) => {
          setAcronymInputValue(e.target.value);
          saveUser('username', e.target.value);
        }}
        onChange={(e) => setAcronymInputValue(e.target.value)}
        placeholder={t('error:placeholder.acronym')}
        value={acronymInputValue}
      />
    );
  };

  const renderSpecialty = () => {
    const label = (
      <SectionLabel>
        <span className="font-weight-semibold">
          {t('user:specialty')}
        </span>
      </SectionLabel>
    );
    return (
      <div className="row align-items-center">
        <div className="col-12 col-sm-12 col-md-4 col-lg-3 col-xl-2">
          {label}
        </div>
        <div className="col-12 col-sm-12 col-md-6">
          {publicProfile.specialty ? t(`specialty:${publicProfile.specialty}`) : ''}
        </div>
      </div>
    );
  };

  const renderCity = () => {
    const label = (
      <SectionLabel>
        <span className="font-weight-semibold">
          {t('user:city')}
        </span>
      </SectionLabel>
    );
    return (
      <div className="row align-items-center">
        <div className="col-12 col-sm-12 col-md-4 col-lg-3 col-xl-2">
          {label}
        </div>
        <div className="col-12 col-sm-12 col-md-6">
          {publicProfile.city || ''}
        </div>
      </div>
    );
  };

  const renderCountry = () => {
    const label = (
      <SectionLabel>
        <span className="font-weight-semibold">
          {t('user:country')}
        </span>
      </SectionLabel>
    );
    return (
      <div className="row align-items-center">
        <div className="col-12 col-sm-12 col-md-4 col-lg-3 col-xl-2">
          {label}
        </div>
        <div className="col-12 col-sm-12 col-md-6">
          {publicProfile.country ? t(`country:${countries[publicProfile.country].name}`) : ''}
        </div>
      </div>
    );
  };

  const renderWebsite = () => {
    const label = (
      <SectionLabel>
        <span className="font-weight-semibold">
          {t('user:website')}
        </span>
      </SectionLabel>
    );
    return (
      <LabeledInput
        {...labelInputParams}
        {...inputClassNames}
        label={label}
        type="text"
        name="organization-website"
        validation="url"
        onValidChange={(e) => {
          setWebsiteInputValue(e.target.value);
          saveUser('website', e.target.value);
        }}
        onChange={(e) => setWebsiteInputValue(e.target.value)}
        placeholder={t('error:placeholder.website')}
        value={websiteInputValue}
      />
    );
  };

  const renderContactEmail = () => {
    const label = (
      <SectionLabel>
        <span className="font-weight-semibold">
          {t('user:contact-email')}
        </span>
      </SectionLabel>
    );

    return (
      <LabeledInput
        {...labelInputParams}
        {...inputClassNames}
        label={label}
        type="text"
        name="organization-contact-email"
        validation="email"
        onValidChange={(e) => {
          setContactEmailInputValue(e.target.value);
          saveUser('contact_email', e.target.value);
        }}
        onChange={(e) => setContactEmailInputValue(e.target.value)}
        placeholder={t('error:placeholder.email')}
        value={contactEmailInputValue}
      />
    );
  };

  const renderTopics = () => {
    if (canEdit) {
      return (
        <div className="col-12 col-sm-12 col-md-6 px-0">
          <TopicSelect
            onChange={(topics) => saveUser('topics', topics.map((topic) => topic.value))}
            values={publicProfile.topics}
            canCreate
            disabled={!canEdit}
            ref={topicsInput}
            required
            admin={admin}
          />
        </div>
      );
    }
    return publicProfile.topics.length > 0 ? (
      publicProfile.topics.map((topic) => (
        <div
          className="badge badge-turquoise text-truncate mx-1"
          style={{ maxWidth: 'inherit' }}
          key={topic}
        >
          {t(`topic:${topic}`)}
        </div>
      ))
    ) : ' - ';
  };

  const renderLogo = () => (
    <div className="row align-items-center">
      <div className="col-auto text-center px-4">
        {logoURL ? (
          <div>
            <img
              src={logoURL}
              alt="The organization logo"
            />
          </div>
        ) : (
          <div>
            <img src={defaultLogo} width="100" height="100" alt="Default organization logo" />
            <div className="font-italic pt-1 small">
              {t('community:organizations.default-logo')}
            </div>
          </div>
        )}
        <button
          className="mt-2 btn btn-sm btn-outline-primary"
          onClick={() => setEditingAreaVisible(true)}
        >
          {t(`community:organizations.${logoURL ? 'change-logo' : 'upload-logo'}`)}
        </button>
        {logoURL && (
          <button
            className="mt-2 btn btn-link"
            onClick={() => handleLogoChange(null)}
          >
            {t('community:organizations.delete-logo')}
          </button>
        )}
      </div>
      {canEdit && editingAreaVisible && (
        <div className="col-auto px-4">
          <ImageCropper
            showScale
            saveImage={handleLogoChange}
          />
        </div>
      )}
    </div>
  );

  const renderDescription = () => (
    <div className="fake-input-with-border">
      <Editor
        data={publicProfile.description}
        onChange={(data) => saveUser('description', data)}
        type="classic"
        placeholder={t('community:organizations.enter-description')}
      />
    </div>
  );

  const getButton = (project) => {
    if (projectLoading === project.id) {
      return <MiniLoader />;
    }
    let text = 'project-joined';
    let icon = ['fal', 'check'];
    let textColor = 'text-green';
    let canRequestToJoin = false;

    if (!isPreview) {
      const projectUser = projectUsers.find((pUser) => pUser.user.id === authUser.id
        && pUser.project === project.id);
      if (projectUser && !projectUser.confirmed
        && projectUser.type === PROJECT_USER_REQUESTED_TO_JOIN) {
        text = 'request-sent';
        icon = ['fal', 'envelope'];
        textColor = 'text-gray-dark';
      } else if (projectUser && projectUser.confirmed) {
        // do nothing
      } else {
        text = 'request-to-join';
        icon = ['fal', 'sign-in'];
        textColor = 'text-white';
        canRequestToJoin = true;
      }
    }

    const content = (
      <>
        <FontAwesomeIcon
          icon={icon}
          transform="grow-2"
          className="mr-2"
        />
        <span
          className="font-weight-semibold"
          style={{ fontSize: '0.95rem' }}
        >
          {t(`community:organizations.${text}`)}
        </span>
      </>
    );

    return (
      canRequestToJoin ? (
        <button
          className={`btn btn-link ${isMobileView() ? 'p-0' : ''}`}
          onClick={() => requestToJoinProject(project.id)}
        >
          {content}
        </button>
      ) : (
        <span className={textColor}>
          {content}
        </span>
      )
    );
  };

  const getCityCountry = () => {
    const res = [];
    if (publicProfile.city) res.push(publicProfile.city);
    if (publicProfile.country) res.push(t(`country:${countries[publicProfile.country].name}`));
    return res.join(', ');
  };

  const showInformationColumn = Boolean(projects.filter((p) => p.description).length);

  let projectsDOM;
  if (canEdit) projectsDOM = null;
  else {
    projectsDOM = isMixedView() ? projects.map((project) => (
      <tr
        key={`project-${project.id}`}
      >
        <td className="align-middle text-center font-weight-semibold">
          {project.name}
        </td>
        {showInformationColumn && (
          <td className="align-middle text-center">
            {project.description && (
              <Help
                interactive
                transform="grow-2"
              >
                <div
                  className="join-project-description"
                  dangerouslySetInnerHTML={{
                    __html: project.description
                      && project.description !== '<p>&nbsp;</p>'
                      ? TextUtil.parseLinks(project.description)
                      : '',
                  }}
                />
              </Help>
            )}
          </td>
        )}
        <td
          className="align-middle text-center"
          style={{ maxWidth: '15rem' }}
        >
          <TruncatedBadgeList items={project.topics.map((topic) => t(`topic:${topic}`))} />
        </td>
        <td className="align-middle text-center">
          {getButton(project)}
        </td>
      </tr>
    )) : projects.map((project) => (
      <ol
        key={project.id}
        className="list-group"
      >
        <li
          className="list-group-item mobile-list-item organization-project-item"
        >
          <MobileProjectItem>
            <span className="font-weight-semibold">
              {project.name}
            </span>
          </MobileProjectItem>
          {showInformationColumn && (
            <MobileProjectItem
              className="mt-2 mb-1"
            >
              {project.description && (
                <Help
                  interactive
                  transform="grow-2"
                  tooltipContainerClassName="public-project-description"
                  tooltipPlacement="top"
                >
                  <div
                    className="join-project-description"
                    dangerouslySetInnerHTML={{
                      __html: project.description
                        && project.description !== '<p>&nbsp;</p>'
                        ? TextUtil.parseLinks(project.description)
                        : '',
                    }}
                  />
                </Help>
              )}
            </MobileProjectItem>
          )}
          <MobileProjectItem>
            {getButton(project)}
          </MobileProjectItem>
        </li>
      </ol>
    ));
  }

  return (
    <div>
      {loading && <CardLoader />}
      {ready && canEdit && (
        <div className="organization-public-profile edition">
          <ProfileHeader
            onChange={openPreview}
            switchLabel={t('community:organizations.preview.go-to-preview')}
            text={t('community:organizations.subtitle')}
            tooltipText={t('community:organizations.preview.button-tooltip')}
          />
          <Card
            body={(
              <div>
                <p className="body-title">
                  {t('community:organizations.account-information')}
                  &nbsp;
                  <span className="additional-information">
                    <span className="font-italic">
                      {t('community:organizations.not-editable')}
                    </span>
                    <Help
                      iconClassName="ml-2"
                      className="text-left text-break"
                    >
                      {t('community:edition-disabled')}
                    </Help>
                  </span>
                </p>
                <div>
                  {renderName()}
                  {renderSpecialty()}
                  {renderCity()}
                  {renderCountry()}
                </div>
              </div>
            )}
          />
          <Card
            body={(
              <div>
                <p className="body-title">
                  {t('community:organizations.logo')}
                </p>
                <div>
                  {renderLogo()}
                </div>
              </div>
            )}
          />
          <Card
            body={(
              <div>
                <p className="body-title">
                  {t('community:topics')}
                </p>
                <div>
                  {renderTopics()}
                </div>
              </div>
            )}
          />
          <Card
            body={(
              <div>
                <p className="body-title">
                  {t('community:organizations.about')}
                </p>
                <div>
                  {renderDescription()}
                </div>
              </div>
            )}
          />
          <Card
            body={(
              <div>
                <p className="body-title">
                  {t('community:organizations.additional-information')}
                </p>
                <div>
                  <Section className="pb-3" editionMode={canEdit}>
                    {renderAcronym()}
                  </Section>
                  <Section className="pb-3" editionMode={canEdit}>
                    {renderWebsite()}
                  </Section>
                  <Section className="pb-3" editionMode={canEdit}>
                    {renderContactEmail()}
                  </Section>
                </div>
              </div>
            )}
          />
        </div>
      )}
      {ready && !canEdit && (
        <div className={`organization-public-profile ${isPreview ? 'preview' : ''}`}>
          {isPreview && isEditMode && (
            <ProfileHeader
              onChange={() => {
                setIsPreview(false);
                setCanEdit(isEditMode);
              }}
              switchLabel={t('community:organizations.preview.go-to-edition')}
              text={t('community:organizations.preview.subtitle')}
              isPreview
            />
          )}
          <div className="row">
            <div className="col-12 col-lg-3 col-xl-3">
              <Card
                cardClassName="bg-white presentation-card"
                body={(
                  <div className="text-center">
                    <div className="organization-logo">
                      <img
                        src={logoURL || defaultLogo}
                        alt="The organization logo"
                        width="100%"
                        height="auto"
                      />
                    </div>
                    <div className="organization-label">
                      {publicProfile.username && (
                        <h1 className="mb-0">{publicProfile.username}</h1>
                      )}
                      <p className="mb-0">{publicProfile.label}</p>
                    </div>
                    {(publicProfile.type || publicProfile.specialty) && (
                      <div className="organization-type-and-specialty">
                        {publicProfile.type && (
                          <div className="text-muted">
                            {t(`user:types.${publicProfile.type}`)}
                          </div>
                        )}
                        {publicProfile.specialty && (
                          <div className="text-muted">
                            {t(`specialty:${publicProfile.specialty}`)}
                          </div>
                        )}
                      </div>
                    )}
                    <hr />
                    {(publicProfile.city || publicProfile.country
                      || publicProfile.contact_email || publicProfile.topics.length > 0
                      || publicProfile.website) && (
                      <div className="organization-info">
                        {(publicProfile.city || publicProfile.country) && (
                          <div className="text-muted pb-2">
                            <FontAwesomeIcon
                              icon={['fal', 'map-marker-alt']}
                              className="address-icon"
                            />
                            {getCityCountry()}
                          </div>
                        )}
                        {publicProfile.contact_email && (
                          <div className="text-muted text-break pb-2">
                            <FontAwesomeIcon
                              icon={['fal', 'envelope']}
                              className="contact-email-icon"
                            />
                            {publicProfile.contact_email}
                          </div>
                        )}
                        {publicProfile.topics.length > 0 && (
                          <>
                            <hr />
                            <div
                              className="text-muted organization-topics"
                              style={{ paddingBottom: publicProfile.website ? '2rem' : '0' }}
                            >
                              <div className="font-weight-semibold pb-3">
                                {t('community:topics')}
                              </div>
                              {publicProfile.topics.map((topic) => (
                                <div
                                  className="badge badge-turquoise text-truncate mx-1"
                                  style={{
                                    maxWidth: 'inherit',
                                    fontSize: '80%',
                                    padding: '0.3rem 0.35rem',
                                  }}
                                  key={topic}
                                >
                                  {t(`topic:${topic}`)}
                                </div>
                              ))}
                            </div>
                          </>
                        )}
                        {publicProfile.website && (
                          <a
                            href={publicProfile.website}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="btn btn-outline-primary"
                          >
                            {t('community:organizations.visit-website')}
                            <FontAwesomeIcon
                              icon={['far', 'external-link']}
                              className="ml-1"
                              transform="shrink-3"
                            />
                          </a>
                        )}
                      </div>
                    )}
                  </div>
                )}
              />
            </div>
            <div className="col-12 col-lg-9 col-xl-9">
              <Card
                body={(
                  <div>
                    <p className="body-title">
                      {t('community:organizations.about')}
                    </p>
                    {publicProfile.description ? (
                      <div
                        className="organization-description"
                        dangerouslySetInnerHTML={{
                          __html: publicProfile.description && publicProfile.description !== ''
                            && publicProfile.description !== '<p>&nbsp;</p>'
                            ? TextUtil.parseLinks(publicProfile.description)
                            : '',
                        }}
                      />
                    ) : (
                      <span className="text-muted small font-italic">
                        {t('community:organizations.no-description')}
                      </span>
                    )}
                  </div>
                )}
              />
              <Card
                body={(
                  <div className="public-projects">
                    <p className="body-title">
                      {t('community:public-projects')}
                    </p>
                    <div className="pb-2 pt-1">
                      {t('community:user-community.ask-to-join-a-project')}
                    </div>
                    <MixedView>
                      <Table extraClassName="no-pointer mt-2 mb-0">
                        <TableHead>
                          <TableRow>
                            <TableCell>
                              {t('community:project')}
                            </TableCell>
                            {showInformationColumn && (
                              <TableCell>
                                {t('community:projects.information')}
                              </TableCell>
                            )}
                            <TableCell>
                              {t('community:subjects')}
                            </TableCell>
                            <TableCell>
                              {t('community:projects.join')}
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {projects.length > 0 && !projectsLoading ? projectsDOM : (
                            <TableRow className="no-one">
                              <TableCell
                                colSpan="3"
                                className="align-middle text-center text-muted small font-italic"
                              >
                                {t('community:projects.no-projects')}
                              </TableCell>
                            </TableRow>
                          )}
                        </TableBody>
                      </Table>
                    </MixedView>
                    <MobileView>
                      {projects.length > 0 && !projectsLoading ? projectsDOM : (
                        <div
                          className="align-middle text-center no-inclusion"
                        >
                          {t('community:projects.no-projects')}
                        </div>
                      )}
                    </MobileView>
                    {projectsCount > 10 && (
                      <div className="mt-2">
                        <Pagination
                          page={projectsPage}
                          count={projectsCount}
                          pageSize={projectsPageSize}
                          action={fetchPaginatedProjects}
                        />
                      </div>
                    )}
                  </div>
                )}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

OrganizationProfile.propTypes = {
  match: PropTypes.shape().isRequired,
  isEditMode: PropTypes.bool,
  admin: PropTypes.bool,
  organization: PropTypes.shape(),
};

OrganizationProfile.defaultProps = {
  isEditMode: false,
  admin: false,
  organization: undefined,
};

export default withRouter(OrganizationProfile);
