import { createReducer } from 'redux-starter-kit';
import { topicsActions } from '../actions';
import getStdDbModelReduxMapping from './std-db-model-reducers';

export const topicsInitialState = {};

const topicsReducer = createReducer(
  topicsInitialState,
  getStdDbModelReduxMapping(topicsActions),
);

export default topicsReducer;
