import React, {
  useEffect, useState, useRef, useMemo,
} from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Mutex } from 'async-mutex';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { projectsActions } from '../redux/actions';
import { DelMsgModal } from './MessageModal';
import Pagination from './Pagination';
import { CardLoader, MiniLoader } from './Loader';
import NewTooltip from './NewTooltip';
import Help from './Help';
import {
  Table, TableHead, TableBody, TableRow, TableCell,
} from './CustomTable';
import TruncatedBadgeList from './TruncatedBadgeList';
import useError from '../utils/HookErrorUtil';
import useToast from '../utils/HookToast';
import TimeoutHandler from '../utils/TimeoutHandler';
import TextUtil from '../utils/TextUtil';
import {
  PROJECT_PUBLIC, PROJECT_NOT_PUBLIC, PROJECT_PUBLIC_STATUS_REQUESTED,
} from '../constants';

export const VISIBILITY_OPTIONS = {
  [PROJECT_PUBLIC]: {
    nextStatus: PROJECT_NOT_PUBLIC,
    adminNextStatus: PROJECT_NOT_PUBLIC,
    adminAction: 'make-private',
    action: 'make-private',
    class: 'text-green font-weight-semibold font-italic',
    icon: ['fal', 'globe'],
  },
  [PROJECT_NOT_PUBLIC]: {
    nextStatus: PROJECT_PUBLIC_STATUS_REQUESTED,
    adminNextStatus: PROJECT_PUBLIC,
    adminAction: 'make-public',
    action: 'make-public',
    class: 'text-primary font-weight-semibold font-italic',
    icon: ['fal', 'key'],
  },
  [PROJECT_PUBLIC_STATUS_REQUESTED]: {
    nextStatus: PROJECT_NOT_PUBLIC,
    adminNextStatus: PROJECT_PUBLIC,
    adminAction: 'make-public',
    action: 'cancel-request',
    class: 'text-gray font-weight-semibold font-italic',
    icon: null,
  },
};

function OrganizationProjects(props) {
  const { organization, admin } = props;
  const { t } = useTranslation();
  const { handleCatched } = useError();
  const { success } = useToast();
  const dispatch = useDispatch();

  const timeoutHandler = useRef(null);
  const mutex = useRef(null);

  const [loading, setLoading] = useState(false);
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(1);
  const [projectLoading, setProjectLoading] = useState(null);

  const fetchProjects = async (data) => dispatch(projectsActions.list(
    { ...data, admin }, { pagination: 'short' },
  ));
  const patchProject = async (id, data) => dispatch(projectsActions.patch(
    id, data, { admin },
  ));

  const prjs = useSelector((state) => state.projects);

  const projects = useMemo(() => Object.values(prjs).sort(
    (a, b) => a.name.localeCompare(b.name),
  ), [prjs]);

  const saveProject = async (projectId, key, value) => {
    if (!mutex.current.isLocked()) {
      const release = await mutex.current.acquire();
      setProjectLoading(projectId);
      await new Promise((resolve) => {
        timeoutHandler.current.doAfterTimeout(async () => {
          try {
            const data = {};
            data[key] = value;
            await patchProject(projectId, data);
            success('error:valid.success');
            resolve();
          } catch (error) {
            handleCatched(props, error);
            resolve();
          } finally {
            release();
            setProjectLoading(null);
          }
        });
      });
    }
  };

  const fetchPaginatedProjects = async (newPage) => {
    setLoading(true);
    try {
      const res = await fetchProjects({
        page: newPage,
        ordering: 'name',
        owner: organization.id,
      });
      setPage(newPage);
      setCount(res.count);
      setPageSize(Math.max(res.results.length, pageSize));
    } catch (error) {
      handleCatched(props, error);
    } finally {
      setLoading(false);
    }
  };

  const fetchData = async () => fetchPaginatedProjects(1);

  useEffect(() => {
    timeoutHandler.current = new TimeoutHandler();
    mutex.current = new Mutex();
    fetchData();
  }, []);

  return (
    <div>
      {loading && (
        <CardLoader />
      )}
      <div className="pb-3 community-card-subtitle">
        {t('community:projects.subtitle')}
      </div>
      <Table extraClassName="no-pointer mt-2 mb-0">
        <TableHead>
          <TableRow>
            <TableCell>
              {t('community:project')}
            </TableCell>
            <TableCell>
              {t('community:subjects')}
            </TableCell>
            <TableCell>
              {t('community:projects.description')}
            </TableCell>
            <TableCell>
              {t('community:projects.status')}
            </TableCell>
            <TableCell>
              {t('community:projects.action')}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {projects.length > 0 && !loading ? (
            projects.map((project) => {
              const rowData = VISIBILITY_OPTIONS[project.visibility_status];
              return (
                <TableRow
                  key={`project-${project.id}`}
                >
                  <TableCell>
                    <Link to={`${admin ? '/admin' : '/dashboard'}/project/${project.id}/details`}>
                      <span className="font-weight-semibold">
                        {project.name}
                      </span>
                      <NewTooltip
                        content={t('community:projects.go-to-project')}
                      >
                        <FontAwesomeIcon
                          icon={['far', 'external-link']}
                          className="ml-2 text-gray"
                          transform="shrink-3"
                        />
                      </NewTooltip>
                    </Link>
                  </TableCell>
                  <TableCell>
                    <TruncatedBadgeList items={project.topics.map((topic) => t(`topic:${topic}`))} />
                  </TableCell>
                  <TableCell>
                    {project.description && (
                      <Help
                        interactive
                        transform="grow-2"
                        tooltipContainerClassName="public-project-description"
                        tooltipPlacement="top"
                      >
                        <div
                          className="join-project-description"
                          dangerouslySetInnerHTML={{
                            __html: project.description
                              && project.description !== '<p>&nbsp;</p>'
                              ? TextUtil.parseLinks(project.description)
                              : '',
                          }}
                        />
                      </Help>
                    )}
                  </TableCell>
                  <TableCell className={`align-middle text-center ${rowData.class}`}>
                    {Boolean(rowData.icon) && (
                      <FontAwesomeIcon
                        icon={rowData.icon}
                        className="mr-2"
                      />
                    )}
                    {t(`project:visibility-status.${project.visibility_status}`)}
                  </TableCell>
                  <TableCell>
                    <DelMsgModal
                      message={(
                        <span>
                          <strong>
                            {t('common:caution')}
                            &nbsp;!
                          </strong>
                          <br />
                          <br />
                          {t('project:depublication-warning.part-1')}
                          <strong>
                            {t('project:depublication-warning.part-2')}
                          </strong>
                          {t('project:depublication-warning.part-3')}
                        </span>
                      )}
                      onValidate={() => saveProject(
                        project.id,
                        'visibility_status',
                        PROJECT_NOT_PUBLIC,
                      )}
                      triggerCondition={() => (!projectLoading
                        || projectLoading === project.id)
                        && project.visibility_status === PROJECT_PUBLIC}
                      triggerFuncName="onClick"
                    >
                      {projectLoading === project.id ? (
                        <MiniLoader />
                      ) : (
                        <button
                          className="btn btn-link"
                          name={`project-visibility-status-${project.id}`}
                          onClick={() => saveProject(
                            project.id,
                            'visibility_status',
                            admin ? rowData.adminNextStatus : rowData.nextStatus,
                          )}
                        >
                          {t(`community:projects.${admin ? rowData.adminAction : rowData.action}`)}
                        </button>
                      )}
                    </DelMsgModal>
                  </TableCell>
                </TableRow>
              );
            })
          ) : (
            <TableRow className="no-one">
              <TableCell
                colSpan="5"
                className="align-middle text-center text-muted small font-italic"
              >
                {loading ? (
                  <span>
                    &nbsp;
                  </span>
                ) : (
                  <span>
                    {t('community:projects.no-projects')}
                  </span>
                )}
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
      {count > 10 && (
        <div className="mt-2">
          <Pagination
            page={page}
            count={count}
            pageSize={pageSize}
            action={fetchPaginatedProjects}
          />
        </div>
      )}
    </div>
  );
}

OrganizationProjects.propTypes = {
  organization: PropTypes.shape().isRequired,
  admin: PropTypes.bool,
};

OrganizationProjects.defaultProps = {
  admin: false,
};

export default OrganizationProjects;
