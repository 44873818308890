import { createReducer } from 'redux-starter-kit';
import { userFilesActions } from '../actions';
import getStdDbModelReduxMapping from './std-db-model-reducers';

export const userFilesInitialState = {};

const userFilesReducer = createReducer(
  userFilesInitialState,
  getStdDbModelReduxMapping(userFilesActions),
);

export default userFilesReducer;
