import { createAction } from 'redux-starter-kit';
import isEqual from 'react-fast-compare';

const DEFAULT_ID = 1;

const setPoolUniqueId = createAction('local_id_manager/set_pool_unique_id');
const removePoolUniqueId = createAction('local_id_manager/remove_pool_unique_id');
const removePoolAllUniqueIds = createAction('local_id_manager/remove_pool_all_unique_ids');

const retrieveOrGenerateId = (pool, existingIds, associatedIds, dispatch) => {
  let id = DEFAULT_ID;

  if (existingIds) {
    if (associatedIds && associatedIds.length) {
      const index = Object.values(existingIds).findIndex((aIds) => isEqual(aIds, associatedIds));
      if (index >= 0) return Object.keys(existingIds)[index];
    }

    let i = 0;
    const existingIdsArray = Object.keys(existingIds);

    while (i < existingIdsArray.length) {
      if (Number(existingIdsArray[i]) !== id) break;
      i += 1;
      id += 1;
    }
  }

  dispatch(setPoolUniqueId({ pool, id, associatedIds }));

  return id;
};

const getPoolUniqueId = (pool, associatedIds) => (dispatch, getState) => {
  const { localIdManager } = getState();
  const existingIds = localIdManager[pool];
  return retrieveOrGenerateId(pool, existingIds, associatedIds, dispatch);
};

const exported = {
  setPoolUniqueId,
  removePoolUniqueId,
  removePoolAllUniqueIds,
  getPoolUniqueId,
};

if (process.env.NODE_ENV === 'test') exported.retieveOrGenerateId = retrieveOrGenerateId;

export default {
  ...exported,
};
