import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { FormGroup } from 'reactstrap';
import DbUtil from '../utils/DbUtil';
import { childrenPropTypes } from '../utils/generic-prop-types';
import FieldLabel from './FieldLabel';
import Validator, { GUI_EFFECT_FULL } from './Validator';


class LabeledSelect extends Component {
  static propTypes = {
    children: childrenPropTypes(),
    label: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.shape(),
    ]),
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func,
    onValidityChange: PropTypes.func,
    smallInformation: PropTypes.oneOfType([PropTypes.element, PropTypes.node]),
    required: PropTypes.bool,
    disabled: PropTypes.bool,
    selectRef: PropTypes.func,
    className: PropTypes.string,
    rowClassName: PropTypes.string,
    colLabelClassName: PropTypes.string,
    labelClassName: PropTypes.string,
    colSelectClassName: PropTypes.string,
    selectClassName: PropTypes.string,
    validatorClassName: PropTypes.string,
    defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    hideOptionalLabel: PropTypes.bool,
    validatorGuiEffect: PropTypes.string,
  };

  static defaultProps = {
    children: null,
    onChange: () => {},
    onValidityChange: () => {},
    smallInformation: null,
    required: false,
    disabled: false,
    selectRef: null,
    className: '',
    rowClassName: 'row',
    colLabelClassName: 'col-12',
    labelClassName: 'mb-1',
    colSelectClassName: 'col-12',
    selectClassName: 'custom-select',
    validatorClassName: '',
    defaultValue: undefined,
    value: undefined,
    hideOptionalLabel: false,
    label: undefined,
    validatorGuiEffect: GUI_EFFECT_FULL,
  };

  constructor(props) {
    super(props);
    this.state = { validSelection: !this.props.required };
    this.validator = null;
    this.id = `LabeledSelect_${DbUtil.generateId()}`;
  }

  componentDidMount() {
    if (this.props.selectRef) {
      this.props.selectRef(document.getElementById(this.id));
    }
  }

  handleChange = (e) => {
    const { validatorGuiEffect, onChange, onValidityChange } = this.props;
    const isValid = this.validate(e.target.value, validatorGuiEffect);
    if (isValid) onChange(e);
    if (isValid !== this.state.validSelection) {
      this.setState({ validSelection: isValid }, () => onValidityChange(isValid));
    }
  };

  validate = (value, guiEffect = GUI_EFFECT_FULL) => this.validator.validate(value, guiEffect);

  render() {
    const {
      rowClassName, colLabelClassName, labelClassName, colSelectClassName, selectClassName,
      hideOptionalLabel, required, label, className, name, disabled, defaultValue, value,
      smallInformation, validatorClassName,
    } = this.props;
    return (
      <FormGroup className={className}>
        <div className={`${rowClassName}`}>
          {
            label && (
              <div className={colLabelClassName}>
                <FieldLabel
                  className={labelClassName}
                  required={required || hideOptionalLabel}
                  for={this.id}
                >
                  {label}
                </FieldLabel>
              </div>
            )
          }
          <div className={colSelectClassName}>
            <Validator
              required={required}
              className={validatorClassName}
              ref={(validator) => { this.validator = validator; }}
            >
              <select
                className={selectClassName}
                id={this.id}
                name={name}
                disabled={disabled}
                defaultValue={defaultValue}
                value={value}
                onChange={this.handleChange}
              >
                {this.props.children}
              </select>
            </Validator>
            {
              smallInformation ? (
                <div className="authentication-small-info">
                  {smallInformation}
                </div>
              ) : null
            }
          </div>
        </div>
      </FormGroup>
    );
  }
}


export default LabeledSelect;
