import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { components } from 'react-select';
import AsyncSelect from 'react-select/async';
import { withToastManager } from 'react-toast-notifications';
import { connect } from 'react-redux';
import memoize from 'memoize-one';
import api from '../api';
import { childrenPropTypes } from '../utils/generic-prop-types';
import ErrorUtil from '../utils/ErrorUtil';
import SortUtil from '../utils/SortUtil';
import AdminBadge from './AdminBadge';
import { UserAvatar } from './avatar';


// eslint-disable-next-line react/prefer-stateless-function
class SingleValue extends React.Component {
  static propTypes = {
    hasValue: PropTypes.bool,
    data: PropTypes.shape().isRequired,
    children: childrenPropTypes().isRequired,
  };

  static defaultProps = {
    hasValue: false,
  };

  render() {
    const { hasValue, data, children } = this.props;
    return (
      <components.SingleValue {...this.props}>
        {hasValue && (
          <UserAvatar
            user={data}
            className="mr-2"
          />
        )}
        {children}
        {(hasValue && data.isAdmin) && (
          <AdminBadge className="ml-2" />
        )}
      </components.SingleValue>
    );
  }
}


// eslint-disable-next-line react/prefer-stateless-function
class Option extends React.Component {
  static propTypes = {
    data: PropTypes.shape().isRequired,
    children: childrenPropTypes().isRequired,
  };

  render() {
    const { data, children } = this.props;
    return (
      <components.Option {...this.props}>
        <UserAvatar
          user={data}
          className="mr-2"
        />
        {children}
        {
          data.isAdmin ? (
            <AdminBadge className="ml-2" />
          ) : null
        }
      </components.Option>
    );
  }
}


const mapStateToProps = (state) => ({ user: state.auth.authUser });


@withToastManager
@connect(mapStateToProps)
class UserSelect extends Component {
  static propTypes = {
    onChange: PropTypes.func.isRequired,
    value: PropTypes.number,
    admin: PropTypes.bool,
    placeholder: PropTypes.string,
    fetchUsers: PropTypes.func,
    disabled: PropTypes.bool,
    required: PropTypes.bool,
  };

  static defaultProps = {
    value: null,
    admin: false,
    placeholder: '',
    fetchUsers: undefined,
    disabled: false,
    required: false,
  };

  constructor(props) {
    super(props);
    this.state = { users: null };
    this.memoizedGetUser = memoize(this.getUser);
  }

  getUser = (id, users) => {
    if (users && id) {
      const res = users.find((user) => user.value === id);
      if (res) return res;
    }
    return null;
  }

  fetchUsers = async (search = null) => {
    const { fetchUsers } = this.props;
    try {
      const { admin } = this.props;
      let res;
      if (fetchUsers) res = await fetchUsers(search);
      else res = await api.list('users', { search, admin }, { pagination: 'no' });
      return res;
    } catch (error) {
      ErrorUtil.handleCatched(this.props, error, false);
      return [];
    }
  };

  load = async (inputValue) => {
    const users = await this.fetchUsers(inputValue);
    const results = [];
    users.sort(SortUtil.sortUsers).forEach((user) => {
      results.push({
        value: user.id,
        label: user.label,
        username: user.username,
        type: user.type,
        isAdmin: user.is_staff,
      });
    });
    if (!this.state.users) {
      this.setState({ users: results });
    }
    return results;
  };

  handleChange = (result) => {
    this.props.onChange(result);
  };

  render() {
    const {
      value, placeholder, disabled, required,
    } = this.props;
    const { users } = this.state;
    const userValue = this.memoizedGetUser(value, users);

    return (
      <AsyncSelect
        isClearable={false}
        noOptionsMessage={(val) => `No user found${val.inputValue
          ? ` with name "${val.inputValue}"`
          : ''}.`}
        className={`react-select user-select ${!userValue && required ? 'no-user' : ''}`}
        classNamePrefix="react-select"
        placeholder={placeholder}
        loadOptions={this.load}
        menuPlacement="auto"
        onChange={this.handleChange}
        components={{ SingleValue, Option }}
        openMenuOnFocus
        closeMenuOnSelect
        backspaceRemovesValue={false}
        value={userValue}
        defaultOptions
        isDisabled={disabled}
      />
    );
  }
}


export default UserSelect;
