import React, { Component } from 'react';
import '../assets/css/bigmenu.css';

// eslint-disable-next-line react/prefer-stateless-function
class BigMenuDivider extends Component {
  render() {
    return (
      <div className="big-menu-divider" />
    );
  }
}

export default BigMenuDivider;
