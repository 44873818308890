import { createReducer } from 'redux-starter-kit';
import fromEntries from 'fromentries';
import { localIdManagerActions } from '../actions';

const { setPoolUniqueId, removePoolUniqueId, removePoolAllUniqueIds } = localIdManagerActions;

export const localIdManagerInitialState = {};

const localIdManagerReducer = createReducer(localIdManagerInitialState, {
  [setPoolUniqueId]: (state, action) => {
    const { pool, id, associatedIds } = action.payload;
    const newPoolContent = { ...state[pool], [id]: associatedIds };
    return {
      ...state,
      [pool]: { ...newPoolContent },
    };
  },
  [removePoolUniqueId]: (state, action) => {
    const { pool, id } = action.payload;
    return {
      ...state,
      [pool]: fromEntries(Object.entries(state[pool]).filter(([key]) => key !== String(id))),
    };
  },
  [removePoolAllUniqueIds]: (state, action) => {
    const { payload } = action;
    return {
      ...state,
      [String(payload)]: { },
    };
  },
});

export default localIdManagerReducer;
