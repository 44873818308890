class TimeoutHandler {
  constructor() {
    this.timeoutHandles = {};
  }

  // Final action (postAction) is past as argument of preAction function
  // Use this function for example with setState :
  // this.doAfterTimeoutWithClbk((startTimeout) => this.setState({ status: value },
  //    () => startTimeout(() => { /* here action to be done after timeout */ }))));
  doAfterTimeoutWithClbk(preAction, id = 0, timeout = 500) {
    if (this.timeoutHandles[id]) {
      clearTimeout(this.timeoutHandles[id]);
    }
    const startTimeout = (postAction) => {
      this.timeoutHandles[id] = setTimeout(postAction, timeout);
    };
    preAction(startTimeout);
  }

  // Simplified version of doAfterTimeoutWithClbk, just pass your action
  // as callback function to be called after timeout
  doAfterTimeout(action, id = 0, timeout = 500) {
    this.doAfterTimeoutWithClbk((startTimeout) => startTimeout(() => action()), id, timeout);
  }
}

export default TimeoutHandler;
