import {
  DATE_FULL,
  DATE_MONTH_YEAR,
  DATE_TIME,
  DATE_YEAR,
  ELEMENT_TYPE_CALCULATION, ELEMENT_TYPE_DATE, ELEMENT_TYPE_DIVIDER,
  ELEMENT_TYPE_INFORMATION, ELEMENT_TYPE_MEASUREMENT, ELEMENT_TYPE_MODULE,
  ELEMENT_TYPE_MULTIPLE_CHOICES, ELEMENT_TYPE_SPACER, ELEMENT_TYPE_TEXT, ELEMENT_TYPE_TIME,
  ELEMENT_TYPE_TITLE, ELEMENT_TYPE_UNIQUE_CHOICE,
} from '../constants';
import ElementDate from '../components/ElementDate';
import ElementTime from '../components/ElementTime';
import ElementInformation from '../components/ElementInformation';
import ElementMeasurement from '../components/ElementMeasurement';
import ElementModule from '../components/ElementModule';
import ElementChoice from '../components/ElementChoice';
import ElementText from '../components/ElementText';
import ElementTitle from '../components/ElementTitle';
import ElementDivider from '../components/ElementDivider';
import ElementSpacer from '../components/ElementSpacer';
import ElementCalculation from '../components/ElementCalculation';
import ElementImplementableChoice from '../components/ElementImplementableChoice';
import { formatPageTitle } from './data-util';

export default class ElementUtil {
  /**
   * @param element Element
   * @returns Component|null
   */
  static getElementType(element) {
    if (!element) return null;
    const types = {
      [ELEMENT_TYPE_TITLE]: ElementTitle,
      [ELEMENT_TYPE_DATE]: ElementDate,
      [ELEMENT_TYPE_TIME]: ElementTime,
      [ELEMENT_TYPE_INFORMATION]: ElementInformation,
      [ELEMENT_TYPE_MULTIPLE_CHOICES]: element.implementable ? ElementImplementableChoice
        : ElementChoice,
      [ELEMENT_TYPE_UNIQUE_CHOICE]: element.implementable ? ElementImplementableChoice
        : ElementChoice,
      [ELEMENT_TYPE_MEASUREMENT]: ElementMeasurement,
      [ELEMENT_TYPE_TEXT]: ElementText,
      [ELEMENT_TYPE_MODULE]: ElementModule,
      [ELEMENT_TYPE_SPACER]: ElementSpacer,
      [ELEMENT_TYPE_DIVIDER]: ElementDivider,
      [ELEMENT_TYPE_CALCULATION]: ElementCalculation,
    };
    return types[element.type];
  }

  /**
   *
   * @param {String} type
   * @returns {String|null}
   */
  static isElementStatic(type) {
    switch (type) {
      case ELEMENT_TYPE_DIVIDER:
      case ELEMENT_TYPE_SPACER:
      case ELEMENT_TYPE_INFORMATION:
      case ELEMENT_TYPE_TITLE:
        return true;
      default:
        return false;
    }
  }

  /**
   * @param {String} type
   * @param {function} t
   * @returns {String|null}
   */
  static getStaticElementTypeName(type, t) {
    switch (type) {
      case ELEMENT_TYPE_TITLE:
        return t('common:elements.title');
      case ELEMENT_TYPE_INFORMATION:
        return t('common:elements.information');
      case ELEMENT_TYPE_SPACER:
        return t('common:elements.spacer');
      case ELEMENT_TYPE_DIVIDER:
        return t('common:divider');
      default:
        return null;
    }
  }

  /**
   * @param element Element
   * @param t i18next translate function
   * @returns String|null
   */
  static getElementTypeName(element, t) {
    if (!element) return null;
    switch (element.type) {
      case ELEMENT_TYPE_DATE:
        switch (element.format) {
          case DATE_TIME:
            return t('common:elements.date-time');
          case DATE_MONTH_YEAR:
            return t('common:elements.date-month-year');
          case DATE_YEAR:
            return t('common:elements.date-year');
          case DATE_FULL:
          default:
            return t('common:elements.date-full');
        }
      case ELEMENT_TYPE_TIME:
        return t('common:elements.time');
      case ELEMENT_TYPE_UNIQUE_CHOICE:
        return t(element.implementable
          ? 'common:elements.implementable-unique-choice'
          : 'common:elements.unique-choice');
      case ELEMENT_TYPE_MULTIPLE_CHOICES:
        return t(element.implementable
          ? 'common:elements.implementable-multiple-choice'
          : 'common:elements.multiple-choice');
      case ELEMENT_TYPE_MEASUREMENT:
        return t('common:elements.measurement');
      case ELEMENT_TYPE_MODULE:
        return t('common:elements.module');
      case ELEMENT_TYPE_TEXT:
        return t('common:elements.text');
      case ELEMENT_TYPE_CALCULATION:
        return t('common:elements.calculation');
      default:
        return ElementUtil.getStaticElementTypeName(element.type, t);
    }
  }

  /**
   * @param element Element
   * @param t i18next translate function
   * @returns String
   */
  static getElementDefaultLabel(element, t) {
    if (!element) return '';
    if (element.type === ELEMENT_TYPE_TITLE) {
      return t('common:elements.default-labels.title');
    }
    return t('common:elements.default-labels.variable');
  }

  /**
   * @param element Element
   * @param t i18next translate function
   * @returns String|null
   */
  static getElementPlaceholder(element, t) {
    if (!element) return '';
    switch (element.type) {
      case ELEMENT_TYPE_DATE:
      case ELEMENT_TYPE_TIME:
      case ELEMENT_TYPE_UNIQUE_CHOICE:
      case ELEMENT_TYPE_MULTIPLE_CHOICES:
      case ELEMENT_TYPE_MEASUREMENT:
      case ELEMENT_TYPE_MODULE:
      case ELEMENT_TYPE_TEXT:
        return t('common:elements.placeholders.question');
      case ELEMENT_TYPE_TITLE:
        return t('common:elements.placeholders.title');
      case ELEMENT_TYPE_INFORMATION:
      case ELEMENT_TYPE_SPACER:
      case ELEMENT_TYPE_DIVIDER:
      case ELEMENT_TYPE_CALCULATION:
        return t('common:elements.placeholders.label');
      default:
        return '';
    }
  }

  static formatElementName(element, t) {
    return element && element.name ? element.name : this.getElementDefaultLabel(element, t);
  }

  static formatModalityName(modality, t) {
    return modality && modality.name ? modality.name : t('common:elements.default-labels.answer');
  }

  static formatProjectElementName(projectElement, projectElements, elements, pages, t) {
    const elementName = ElementUtil.formatElementName(elements[projectElement.element], t);
    const pageName = formatPageTitle(pages[projectElement.project_page], t);
    const moduleName = projectElement.module
      ? ElementUtil.formatElementName(elements[projectElements[projectElement.module].element], t)
      : '';
    return `${elementName} (${pageName}${moduleName ? ` - ${moduleName}` : ''})`;
  }
}
