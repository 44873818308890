import PropTypes from 'prop-types';
import React from 'react';
import NewTooltip from './NewTooltip';
import Warning from './Warning';

const INVALID_CSS_CLASS = 'error';

class ElementContentEditable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentValue: props.defaultValue || props.value,
      invalidValue: false,
    };
    this.input = null;
  }

  setInputCssClass = (input) => {
    const { className } = this.props;
    const { invalidValue } = this.state;
    const newInput = input;
    newInput.className = className;
    if (invalidValue && !input.className.includes(INVALID_CSS_CLASS)) {
      newInput.className = `${newInput.className} ${INVALID_CSS_CLASS}`;
    }
  }

  setSpanCssAndValue = (span, setValue = true) => {
    const { staticLabelClassName } = this.props;
    const { invalidValue, currentValue } = this.state;
    const newSpan = span;
    const defaultClassName = `${staticLabelClassName} ${currentValue ? '' : 'element-edit-span-empty'}`;
    newSpan.className = defaultClassName;
    if (invalidValue && !span.className.includes(INVALID_CSS_CLASS)) {
      newSpan.className = `${newSpan.className} ${INVALID_CSS_CLASS}`;
    }
    if (!invalidValue && span.className.includes(INVALID_CSS_CLASS) && setValue) {
      const { placeholder } = this.props;
      newSpan.innerHTML = currentValue || placeholder;
    }
  }

  onChange = async (event) => {
    this.setState({ currentValue: event.target.value });
    const res = await this.props.onChange(event);
    const { containerId, handleInvalidValues, resizeInput } = this.props;
    const invalidValue = res === null;
    this.setState({ invalidValue });
    if (handleInvalidValues && resizeInput) {
      const input = document.getElementById(`${containerId}-name-input`);
      if (input) this.setInputCssClass(input);
      const span = document.getElementById(`${containerId}-name-span`);
      if (span) this.setSpanCssAndValue(span);
    }
  };

  switchToInput = (e) => {
    const { currentValue } = this.state;
    const {
      containerId, disabled, style, placeholder,
    } = this.props;

    // Change the span into an input
    const inputKey = `${containerId}-name-input`;
    const element = document.getElementById(containerId);
    if (e) {
      e.stopPropagation();
    }
    element.innerHTML = `<input type='text' id='${inputKey}' spellCheck='false' />`;

    // Set up and focus the new input
    const inputElement = document.getElementById(inputKey);
    inputElement.disabled = disabled;
    inputElement.value = currentValue || '';
    this.setInputCssClass(inputElement);
    inputElement.style = style;
    inputElement.placeholder = placeholder;
    inputElement.addEventListener('blur', this.switchToSpan);
    inputElement.addEventListener('click', () => {});
    inputElement.addEventListener('keydown', this.preventForbiddenKeys);
    inputElement.addEventListener('input', this.onChange);
    document.getElementById(inputKey).focus();
  };

  switchToSpan = (e) => {
    const { currentValue } = this.state;
    const { containerId, placeholder } = this.props;

    // Change the input into an span
    const spanKey = `${containerId}-name-span`;
    const spanValue = currentValue || placeholder;
    const element = document.getElementById(containerId);
    if (e) {
      e.stopPropagation();
    }
    element.innerHTML = `<span id='${spanKey}' role='button' tabindex='0'>${spanValue}</span>`;

    // Set up the new span
    const spanElement = document.getElementById(spanKey);
    this.setSpanCssAndValue(spanElement);
    spanElement.addEventListener('click', this.switchToInput);
  };

  render() {
    const {
      value, autoTooltip, className, defaultValue, disabled, style, placeholder, containerId,
      resizeInput, staticLabelClassName, invalidValueMessage,
    } = this.props;
    const { currentValue, invalidValue } = this.state;

    const content = resizeInput ? (
      <>
        <span
          id={containerId}
          style={{ width: 'fit-content' }}
          className="resizing-span-container"
        >
          <span
            id={`${containerId}-name-span`}
            onClick={disabled ? undefined : this.switchToInput}
            onKeyDown={this.preventForbiddenKeys}
            role="button"
            tabIndex="0"
            className={`${staticLabelClassName} ${currentValue ? '' : 'element-edit-span-empty'} ${invalidValue ? 'duplicate-modality-name' : ''}`}
          >
            {currentValue || placeholder}
          </span>
        </span>
        {invalidValue && invalidValueMessage && (
          <Warning
            className="element-content-editable-error-tooltip"
            iconClassName="ml-2"
          >
            {invalidValueMessage}
          </Warning>
        )}
      </>
    ) : (
      <input
        type="text"
        onInput={this.onChange}
        onChange={() => {}} // Prevent warning
        onKeyDown={this.preventForbiddenKeys}
        className={className}
        defaultValue={defaultValue}
        disabled={disabled}
        value={value}
        style={style}
        placeholder={placeholder}
        spellCheck="false"
      />
    );

    return autoTooltip ? (
      <NewTooltip
        placement="top-start"
        content={currentValue}
      >
        { content }
      </NewTooltip>
    ) : content;
  }
}

ElementContentEditable.propTypes = {
  autoTooltip: PropTypes.bool,
  defaultValue: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  className: PropTypes.string,
  staticLabelClassName: PropTypes.string,
  style: PropTypes.shape(),
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  containerId: PropTypes.string,
  resizeInput: PropTypes.bool,
  handleInvalidValues: PropTypes.bool,
  invalidValueMessage: PropTypes.oneOfType([PropTypes.string, PropTypes.shape()]),
};

ElementContentEditable.defaultProps = {
  autoTooltip: false,
  defaultValue: undefined,
  value: undefined,
  onChange: null,
  className: undefined,
  staticLabelClassName: undefined,
  style: undefined,
  placeholder: '',
  disabled: false,
  containerId: '',
  resizeInput: false,
  handleInvalidValues: false,
  invalidValueMessage: '',
};

export default ElementContentEditable;
