import { createAction } from 'redux-starter-kit';
import { localIdManagerActions } from './index';
import { POOL_STATS_FILTERS } from '../../constants';

export const FILTER_TYPE_MEASUREMENT = 'filter-measurement';
export const FILTER_TYPE_QUALITATIVE = 'filter-qualitative';

const addFilter = createAction('stats_filter/add_filter');
const removeFilter = createAction('stats_filter/remove_filter');
const removeAllFilters = createAction('stats_filter/remove_all_filters');

const createFilter = (filterData) => (dispatch) => {
  const id = dispatch(localIdManagerActions.getPoolUniqueId(POOL_STATS_FILTERS));
  dispatch(addFilter({ filterId: id, filterData }));
  return id;
};

const deleteFilter = (filterId) => (dispatch) => {
  dispatch(removeFilter(filterId));
  dispatch(localIdManagerActions.removePoolUniqueId({
    pool: POOL_STATS_FILTERS,
    id: filterId,
  }));
};

const deleteTargetFilters = (targetId) => (dispatch, getState) => {
  const filters = Object.entries(getState().statsFilters).filter(([, filterData]) => (
    filterData.targetId === targetId
  ));
  filters.forEach(([filterId]) => dispatch(deleteFilter(filterId)));
};

const deleteAllFilters = () => (dispatch) => {
  dispatch(removeAllFilters());
  dispatch(localIdManagerActions.removePoolAllUniqueIds(POOL_STATS_FILTERS));
};

const toggleFilter = (filterData) => (dispatch, getState) => {
  let id;
  let filter;
  let shouldRemove = false;
  let shouldCreate = true;
  // eslint-disable-next-line no-restricted-syntax,no-unused-vars
  for (const data of Object.entries(getState().statsFilters)) {
    [id, filter] = data;
    if (filter.targetId === filterData.targetId) {
      shouldRemove = true;
      if (filter.formattedValue === filterData.formattedValue) {
        shouldCreate = false;
      }
      break;
    }
  }
  if (shouldRemove) {
    dispatch(deleteFilter(id));
  }
  if (shouldCreate) {
    dispatch(createFilter(filterData));
  }
};

export default {
  addFilter,
  removeFilter,
  removeAllFilters,
  deleteFilter,
  deleteTargetFilters,
  deleteAllFilters,
  toggleFilter,
};
