import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { withTranslation } from 'react-i18next';
import { nsOptions } from '../i18n';
import LabeledInput from './LabeledInput';
import Help from './Help';


@withTranslation('', nsOptions)
class PasswordInput extends Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
    label: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    placeholder: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    onValidityChange: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
    onValidChange: PropTypes.func,
    confirmValue: PropTypes.func,
    smallInformation: PropTypes.oneOfType([PropTypes.element, PropTypes.node]),
    editionMode: PropTypes.bool,
    needValidation: PropTypes.bool,
  };

  static defaultProps = {
    confirmValue: undefined,
    smallInformation: undefined,
    onValidChange: () => {},
    editionMode: false,
    needValidation: true,
  };

  constructor(props) {
    super(props);
    this.state = { type: 'password' };
  }

  onChange = (e) => {
    const { onChange } = this.props;
    if (!e.target.value) this.setState({ type: 'password' });
    onChange(e);
  };

  toggleType = () => {
    const { type } = this.state;
    this.setState({ type: type === 'password' ? 'text' : 'password' });
  };

  reset() {
    if (this.input) this.input.reset();
    this.setState({ type: 'password' });
  }

  validate(value) {
    return this.input && this.input.validate(value);
  }

  render() {
    const {
      label, name, placeholder, value, onValidityChange, onValidChange, confirmValue,
      smallInformation, editionMode, t, needValidation,
    } = this.props;
    const { type } = this.state;

    return (
      <LabeledInput
        colInputClassName="col-12 d-flex"
        validatorClassName="flex-grow-1"
        type={type}
        label={(
          <div>
            <span>
              {label}
            </span>
            {
              !editionMode && (
                <Help iconClassName="ml-2">
                  {t('user:password-hint')}
                </Help>
              )
            }
          </div>
        )}
        name={name}
        placeholder={placeholder}
        autoComplete="new-password"
        required
        validation={needValidation ? 'password' : undefined}
        confirmValue={confirmValue}
        value={value}
        onValidityChange={onValidityChange}
        onChange={this.onChange}
        onValidChange={onValidChange}
        ref={(ref) => { this.input = ref; }}
        smallInformation={smallInformation}
        append={(
          <button
            type="button"
            className={`no-button-style text-gray${value ? '' : ' d-none'}`}
            onClick={() => this.toggleType()}
          >
            <FontAwesomeIcon
              icon={['fas', type === 'password' ? 'eye' : 'eye-slash']}
              className="ml-2"
            />
          </button>
        )}
      />
    );
  }
}


export default PasswordInput;
