import { createAction } from 'redux-starter-kit';
import api from '../../api';
import toCamelCase from '../../utils/toCamelCase';

export const getQueryOnId = (getState, endpoint) => ({
  id__in: Object.values(getState()[toCamelCase(endpoint)]).map((item) => item.id),
});

export const resyncWithoutDispatch = async (
  endpoint, getState, getQuery = getQueryOnId,
  params = {}, options = {},
) => {
  // Pagination must be disabled
  const newOptions = { ...options, pagination: 'no' };
  const query = getQuery(getState, endpoint);
  const res = await api.list(endpoint, {
    ...params,
    ...query,
  }, newOptions);
  return res;
};

const build = (endpoint) => {
  const createSuccess = createAction(`${endpoint}/create_success`);
  const createMultipleSuccess = createAction(`${endpoint}/create_multiple_success`);
  const listSuccess = createAction(`${endpoint}/list_success`);
  const readSuccess = createAction(`${endpoint}/read_success`);
  const patchSuccess = createAction(`${endpoint}/patch_success`);
  const patchMultipleSuccess = createAction(`${endpoint}/patch_multiple_success`);
  const removeSuccess = createAction(`${endpoint}/remove_success`);
  const removeMultipleSuccess = createAction(`${endpoint}/remove_multiple_success`);
  const removeAllSuccess = createAction(`${endpoint}/remove_all_success`);
  const resyncSuccess = createAction(`${endpoint}/resync_success`);
  const ignoreDataSuccess = createAction(`${endpoint}/ignore_data_success`);

  const create = (data, params = {}, ignoreData = () => false) => async (dispatch) => {
    const res = await api.create(endpoint, data, params);
    dispatch(ignoreData() ? ignoreDataSuccess() : createSuccess(res));
    return res;
  };

  const list = (params = {}, options = {}, ignoreData = () => false) => async (dispatch) => {
    if (options.removeAllBefore) {
      dispatch(removeAllSuccess());
    }
    const res = await api.list(endpoint, params, options);
    dispatch(
      ignoreData()
        ? ignoreDataSuccess()
        : listSuccess(options.pagination === 'no' ? res : res.results),
    );
    return res;
  };

  const read = (id, params = {}, ignoreData = () => false) => async (dispatch) => {
    const res = await api.read(endpoint, id, params);
    dispatch(ignoreData() ? ignoreDataSuccess() : readSuccess(res));
    return res;
  };

  const patch = (id, data = {}, params = {}, ignoreData = () => false) => async (dispatch) => {
    const res = await api.partial_update(endpoint, id, data, params);
    dispatch(ignoreData() ? ignoreDataSuccess() : patchSuccess(res));
    return res;
  };

  const remove = (id, params = {}) => async (dispatch) => {
    await api.delete(endpoint, id, params);
    dispatch(removeSuccess(id));
  };

  const resync = (params = {}, options = {}, ignoreData = () => false) => (
    async (dispatch, getState) => {
      const res = await resyncWithoutDispatch(
        endpoint, getState, getQueryOnId, params, options,
      );
      if (options.removeAllBefore) {
        dispatch(removeAllSuccess());
      }
      dispatch(ignoreData() ? ignoreDataSuccess() : resyncSuccess(res));
      return res;
    }
  );

  return {
    createSuccess,
    createMultipleSuccess,
    listSuccess,
    readSuccess,
    patchSuccess,
    patchMultipleSuccess,
    removeSuccess,
    removeMultipleSuccess,
    removeAllSuccess,
    resyncSuccess,
    ignoreDataSuccess,
    create,
    list,
    read,
    patch,
    remove,
    resync,
  };
};

export default build;
