import { Component } from 'react';
import { isBrowser, isTablet } from 'react-device-detect';
import { childrenPropTypes } from '../utils/generic-prop-types';
import { isBigTablet } from '../utils/viewport';

export const isBrowserView = () => isBrowser || (isTablet && isBigTablet());

class BrowserView extends Component {
  render() {
    return isBrowserView() ? this.props.children : null;
  }
}

BrowserView.propTypes = {
  children: childrenPropTypes().isRequired,
};

export default BrowserView;
