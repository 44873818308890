import { createReducer } from 'redux-starter-kit';
import { teamMembersActions } from '../actions';
import getStdDbModelReduxMapping from './std-db-model-reducers';

export const teamMembersInitialState = {};

const teamMembersReducer = createReducer(
  teamMembersInitialState,
  getStdDbModelReduxMapping(teamMembersActions),
);

export default teamMembersReducer;
