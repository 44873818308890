import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { withToastManager } from 'react-toast-notifications';
import '../assets/css/overmenu.css';
import { nsOptions } from '../i18n';
import ElementUtil from '../utils/ElementUtil';
import Toast from '../utils/Toast';
import ErrorUtil from '../utils/ErrorUtil';
import { elementsActions } from '../redux/actions';
import { DelMsgModal } from './MessageModal';
import ElementCommentManager from './ElementCommentManager';
import ElementLinkManager from './ElementLinkManager';
import ElementMoveManager from './ElementMoveManager';
import IconConfirm from './IconConfirm';
import OverMenu from './OverMenu';
import OverMenuBloc from './OverMenuBloc';
import OverMenuItem from './OverMenuItem';
import LicenseChecker from './LicenseChecker';
import { LinkedIcon } from './ElementLinksInfo';
import ConsistencyCheckManager from './ConsistencyCheckManager';
import ElementIdentifierManager from './ElementIdentifierManager';
import {
  ELEMENT_TYPE_MULTIPLE_CHOICES, ELEMENTS_CONSISTENCY_TO_CHECK, TARGET_TYPE_ELEMENT,
  ELEMENT_NOT_IDENTIFIER,
} from '../constants';
import { consistencyCheckActive } from '../utils/consistency-check';
import IdentifierIcon from './IdentifierIcon';
import { UpdatedBadge } from './Badges';
import fromReduxState from '../utils/redux';

const mapStateToProps = (state) => ({
  elementLinks: state.elementLinks,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  patchElement: async (data) => dispatch(
    elementsActions.patch(
      ownProps.element.id,
      data,
      { admin: ownProps.admin },
    ),
  ),
});

@connect(mapStateToProps, mapDispatchToProps)
@withToastManager
@withTranslation('', nsOptions)
class ElementOverMenu extends Component {
  static propTypes = {
    i18n: PropTypes.shape().isRequired,
    t: PropTypes.func.isRequired,
    elementName: PropTypes.string.isRequired,
    element: PropTypes.shape().isRequired,
    projectElement: PropTypes.shape(),
    project: PropTypes.shape().isRequired,
    methods: PropTypes.shape({
      reload: PropTypes.func.isRequired,
      patchProjectElement: PropTypes.func,
      remove: PropTypes.func.isRequired,
      clone: PropTypes.func.isRequired,
    }),
    parent: PropTypes.shape(),
    tabId: PropTypes.number,
    noComment: PropTypes.bool,
    noOptional: PropTypes.bool,
    addValue: PropTypes.func,
    supportLinks: PropTypes.bool,
    siblingType: PropTypes.shape(),
    admin: PropTypes.bool,
    formSortingHandlerId: PropTypes.string.isRequired,
    elementLinks: PropTypes.shape().isRequired,
    patchElement: PropTypes.func.isRequired,
  };

  static defaultProps = {
    projectElement: null,
    methods: { move: () => {} },
    parent: null,
    noComment: false,
    noOptional: false,
    addValue: null,
    supportLinks: false,
    siblingType: null,
    tabId: null,
    admin: false,
  };

  toElLinksArray = fromReduxState((elLinks, projectElementId) => Object.values(elLinks).filter(
    (link) => link.element_target === projectElementId,
  ));

  get showIdentifierToggle() {
    const { element } = this.props;
    return !ElementUtil.isElementStatic(element.type) && !element.template;
  }

  toggleOptional = async () => {
    if (this.props.methods.patchProjectElement) {
      try {
        await this.props.methods.patchProjectElement({
          required: !this.props.projectElement.required,
        });
        Toast.success(this.props, 'error:valid.saved');
      } catch (error) {
        ErrorUtil.handleCatched(this.props, error);
      }
    }
  };

  changeElementType = async (data) => {
    const { patchElement } = this.props;
    try {
      await patchElement({ ...data });
      Toast.success(this.props, 'error:valid.saved');
    } catch (error) {
      ErrorUtil.handleCatched(this.props, error);
    }
  };

  deleteElement = async () => {
    const { methods } = this.props;
    try {
      if (methods.remove) {
        await methods.remove();
      } else {
        throw new Error('Deletion not supported.');
      }
    } catch (error) {
      ErrorUtil.handleCatched(this.props, error, false);
    }
  };

  cloneProjectElement = async () => {
    const { methods } = this.props;
    try {
      if (methods.clone) {
        await methods.clone();
      } else {
        throw new Error('Duplication not supported.');
      }
    } catch (error) {
      ErrorUtil.handleCatched(this.props, error, false);
    }
  };

  renderIdentifierStatus = () => {
    const { t, element, admin } = this.props;
    return (
      <ElementIdentifierManager
        admin={admin}
        element={element}
      >
        <OverMenuItem
          active={element.identification_level !== ELEMENT_NOT_IDENTIFIER}
          tooltip={t('project:form.manage-identification')}
          onClick={() => {}}
        >
          <IdentifierIcon
            identificationLevel={(element.identification_level)}
            transform="grow-1"
          />
          <UpdatedBadge className="identifier-badge">
            <span />
          </UpdatedBadge>
        </OverMenuItem>
      </ElementIdentifierManager>
    );
  };

  renderSwitchType = () => {
    const { t, siblingType, element } = this.props;
    const renderContent = (onClick = undefined) => (
      <IconConfirm
        tooltipContent={t('project:form.switch-to-type.main', {
          type: t(siblingType.name),
        })}
        tooltipDistance={21.5}
        tooltipArrow
        keepTooltipBlockedAfterAction={false}
        onClick={onClick}
      >
        <OverMenuItem onClick={() => {}}>
          <FontAwesomeIcon icon={['fas', 'repeat']} />
        </OverMenuItem>
      </IconConfirm>
    );

    return element.type === ELEMENT_TYPE_MULTIPLE_CHOICES ? (
      <DelMsgModal
        message={(
          <span>
            <b>
              {t('common:caution')}
              &nbsp;!
            </b>
            <br />
            <br />
            {t('project:form.switch-to-type.warning.part-1')}
            <br />
            <br />
            {t('project:form.switch-to-type.warning.part-2')}
            <br />
            {t('project:form.switch-to-type.warning.part-3')}
            <br />
            {t('project:form.switch-to-type.warning.part-4')}
            <br />
            <br />
            {t('project:form.switch-to-type.warning.part-5')}
          </span>
        )}
        onValidate={() => this.changeElementType(siblingType.data)}
      >
        {renderContent()}
      </DelMsgModal>
    ) : renderContent(() => this.changeElementType(siblingType.data));
  };

  renderElementDeletion = () => {
    const { element, t } = this.props;
    const renderContent = (onClick = undefined) => (
      <IconConfirm
        tooltipContent={t('project:form.delete-element')}
        tooltipDistance={21.5}
        tooltipArrow
        onClick={onClick}
      >
        <OverMenuItem onClick={() => {}}>
          <FontAwesomeIcon icon="trash-alt" />
        </OverMenuItem>
      </IconConfirm>
    );

    return ElementUtil.isElementStatic(element.type) ? renderContent(this.deleteElement) : (
      <DelMsgModal
        message={(
          <span>
            <b>
              {t('common:caution')}
            </b>
            <br />
            <br />
            <strong>
              {t('error:warning.data-loss-risk-1')}
            </strong>
            <br />
            <br />
            {t('error:warning.data-loss-risk-2')}
            <br />
            <br />
            {`${t('common:confirm-deletion')} `}
            <strong>
              {t('error:warning.irreversible-action')}
            </strong>
          </span>
        )}
        onValidate={this.deleteElement}
      >
        {renderContent()}
      </DelMsgModal>
    );
  };

  render() {
    const {
      t, element, projectElement, elementLinks: elLinks, addValue, supportLinks, noOptional,
      noComment, tabId, project, parent, siblingType, admin,
    } = this.props;
    const elementLinks = this.toElLinksArray(elLinks, projectElement.id);
    const elementName = ElementUtil.getElementTypeName(element, this.props.t);
    const showConsistencyCheckItem = ELEMENTS_CONSISTENCY_TO_CHECK.includes(element.type);

    const consistencyConfigured = consistencyCheckActive(element);

    return (
      <div className="element-overmenu">
        <OverMenu>
          {
            elementName ? (
              <OverMenuBloc>
                <OverMenuItem
                  tooltip=""
                  onClick={() => {}}
                  className="element-overmenu-name"
                >
                  {elementName}
                </OverMenuItem>
              </OverMenuBloc>
            ) : null
          }
          {
            addValue || supportLinks || siblingType || (!noOptional && element !== null
              && projectElement !== null) ? (
                <OverMenuBloc>
                  {
                    showConsistencyCheckItem && (
                      <ConsistencyCheckManager
                        {...this.props}
                      >
                        <OverMenuItem
                          active={consistencyConfigured}
                          tooltip={t(`project:form.${consistencyConfigured ? 'edit' : 'set-up'}-consistency-check`)}
                          onClick={() => {}}
                        >
                          <FontAwesomeIcon
                            icon={['far', 'spell-check']}
                            style={{ fontSize: '103%' }}
                          />
                        </OverMenuItem>
                      </ConsistencyCheckManager>
                    )
                  }
                  {
                    element && addValue ? (
                      <OverMenuItem
                        tooltip={t('project:form.add-a-choice')}
                        onClick={() => addValue()}
                      >
                        <span className="fa-layers fa-fw">
                          <FontAwesomeIcon
                            icon="list-ul"
                            transform="shrink-1 left-2 up-4"
                          />
                          <FontAwesomeIcon
                            icon="level-down-alt"
                            transform="rotate-90 shrink-7 right-3 down-6"
                          />
                        </span>
                      </OverMenuItem>
                    ) : null
                }
                  {
                  supportLinks && projectElement ? (
                    <ElementLinkManager
                      title={this.props.elementName}
                      project={project}
                      target={projectElement}
                      targetType={TARGET_TYPE_ELEMENT}
                      admin={admin}
                    >
                      <OverMenuItem
                        active={elementLinks.length > 0}
                        tooltip={elementLinks.length > 0 ? t('project:form.edit-logic-node')
                          : t('project:form.add-logic-node')}
                        onClick={() => {}}
                      >
                        <LinkedIcon />
                      </OverMenuItem>
                    </ElementLinkManager>
                  ) : null
                }
                  {
                    !noOptional && projectElement ? (
                      <LicenseChecker
                        limName="can_set_project_element_as_optional"
                        limitations={project.limitations}
                      >
                        <OverMenuItem
                          active={!projectElement.required}
                          tooltip={projectElement.required
                            ? t('project:form.make-optional')
                            : t('project:form.make-required')}
                          onClick={this.toggleOptional}
                        >
                          <FontAwesomeIcon
                            icon={projectElement.required
                              ? 'asterisk'
                              : 'asterisk'}
                          />
                        </OverMenuItem>
                      </LicenseChecker>
                    ) : null
                  }
                  {this.showIdentifierToggle && this.renderIdentifierStatus()}
                  {siblingType && this.renderSwitchType()}
                </OverMenuBloc>
              ) : null
          }
          <OverMenuBloc>
            {
              !noComment && element && (
              <ElementCommentManager
                {...this.props}
              >
                <OverMenuItem
                  active={element.comment !== ''}
                  tooltip={t(`project:form.${element.comment === '' ? 'add' : 'edit'}-question-comments`)}
                  onClick={() => {}}
                >
                  <FontAwesomeIcon
                    icon={['fas', 'comment-alt-exclamation']}
                    transform="grow-1"
                  />
                </OverMenuItem>
              </ElementCommentManager>
              )
            }
            <OverMenuItem
              tooltip={t('project:form.duplicate-element')}
              onClick={this.cloneProjectElement}
            >
              <FontAwesomeIcon
                icon={['fas', 'clone']}
              />
            </OverMenuItem>
            {
              tabId && projectElement ? (
                <ElementMoveManager
                  {...this.props}
                  insideModule={parent !== project}
                >
                  <OverMenuItem
                    active={element.topics.length > 0}
                    tooltip={t('project:form.manage-position')}
                    onClick={() => {}}
                  >
                    <FontAwesomeIcon icon="arrows-alt" />
                  </OverMenuItem>
                </ElementMoveManager>
              ) : null
            }
          </OverMenuBloc>
          <OverMenuBloc>
            {this.renderElementDeletion()}
          </OverMenuBloc>
        </OverMenu>
      </div>
    );
  }
}


export default ElementOverMenu;
