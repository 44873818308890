import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import cellEditFactory, { Type } from 'react-bootstrap-table2-editor';
import { withTranslation } from 'react-i18next';
import { withToastManager } from 'react-toast-notifications';
import {
  Button, Form, FormGroup, Input, Label,
} from 'reactstrap';
import api from '../api';
import '../assets/css/table.css';
import Head from '../components/AdminHead';
import IconConfirm from '../components/IconConfirm';
import { CardLoader, InputLoader } from '../components/Loader';
import Pagination from '../components/Pagination';
import TopicSelect from '../components/TopicSelect';
import { languages, nsOptions } from '../i18n';
import ErrorUtil from '../utils/ErrorUtil';
import { getNestedValueFromStr, setNestedValueFromStr } from '../utils/object-util';
import Toast from '../utils/Toast';
import TimeoutHandler from '../utils/TimeoutHandler';

const VALIDATED_STR = 'Validated';
const NOT_VALIDATED_STR = 'Not Validated';
const PAGE_SIZE = 10;


@withToastManager
@withTranslation('', nsOptions)
class Topics extends Component {
  static propTypes = {
    i18n: PropTypes.shape().isRequired,
    t: PropTypes.func.isRequired,
  };

  static getTableColumns(t, deleteTopic) {
    const translationsColumns = languages.map((lang) => ({
      dataField: lang,
      text: t(`locale:${lang}`),
      sort: true,
    }));

    return [
      {
        dataField: 'key',
        text: 'Identifier',
        sort: true,
      }, ...translationsColumns, {
        dataField: 'creator',
        text: 'Creator',
        sort: true,
        editable: false,
      }, {
        dataField: 'validated',
        text: 'Validation status',
        sort: true,
        editor: {
          type: Type.SELECT,
          options: [
            {
              value: true,
              label: VALIDATED_STR,
            }, {
              value: false,
              label: NOT_VALIDATED_STR,
            },
          ],
        },
        formatter: (cellContent, row) => { // eslint-disable-line no-unused-vars
          let classNameValue;
          let value;
          if (cellContent === true || cellContent === 'true') {
            classNameValue = 'verified';
            value = VALIDATED_STR;
          } else {
            classNameValue = 'not-verified';
            value = NOT_VALIDATED_STR;
          }
          return (<span className={classNameValue}>{value}</span>);
        },
      }, {
        dataField: 'delete',
        text: 'Delete topic',
        isDummyField: true,
        sort: false,
        headerStyle: {
          width: '75px',
        },
        editable: false,
        align: 'center',
        formatter: (cellContent, row) => (
          <IconConfirm
            tooltipContent="Delete topic"
            tooltipDistance={18}
            onClick={() => deleteTopic(row.key)}
          >
            <button
              type="button"
              className="overmenu-item p-0"
            >
              <FontAwesomeIcon icon="trash-alt" />
            </button>
          </IconConfirm>
        )
        ,
      },
    ];
  }

  constructor(props) {
    super(props, 'Doqboard topics');
    this.state = {
      columns: Topics.getTableColumns(this.props.t, this.deleteTopic),
      currentPageTopics: [],
      topicsCount: 0,
      dataLoading: false,
      searchLoading: false,
      mergeEnabled: false,
      selectedTopics: [],
      searchValue: '',
    };
    this.topics = [];
    this.translations = {};
    this.currentPage = 1;
    this.timeoutHandler = new TimeoutHandler();
  }

  componentDidMount() {
    this.setState({ dataLoading: true });
    this.fetchTranslations().then(() => {
      this.updateTableColumns();
      this.fetchTopics(this.currentPage)
        .then(() => this.setState({ dataLoading: false }))
        .catch((err) => {
          console.error(err);
          this.setState({ dataLoading: false });
        });
    }).catch((err) => {
      console.error(err);
      this.setState({ dataLoading: false });
    });
  }

  getTopicsMatching(search) {
    let topics = {};
    if (search && search !== '') {
      Object.entries(this.translations).forEach((lang) => {
        Object.entries(lang[1]).forEach((translation) => {
          if (translation[1].toLowerCase().includes(search.toLowerCase())) {
            const test = { [translation[0]]: translation[1] };
            topics = { ...topics, ...test };
          }
        });
      });
    }
    return Object.keys(topics);
  }

  getTopics = (page) => {
    const { topicsCount } = this.state;

    this.currentPage = Math.max(Math.min(page, Math.ceil(topicsCount / PAGE_SIZE)), 1);

    // Fetch topics for 'page'
    const offset = (this.currentPage - 1) * PAGE_SIZE;
    this.setState({ currentPageTopics: this.topics.slice(offset, (offset + PAGE_SIZE)) });
  };

  fetchTranslations = async (langList = languages) => {
    try {
      if (!this.translations) this.translations = {};
      const { i18n } = this.props;
      const promises = [];
      langList.forEach((lang) => {
        promises.push(new Promise((resolve) => {
          i18n.reloadResources(lang, 'topic', resolve);
        }));
      });
      await Promise.all(promises);
      langList.forEach((lang) => {
        this.translations[lang] = i18n.getResourceBundle(lang, 'topic');
      });
    } catch (error) {
      ErrorUtil.handleCatched(this.props, error, false);
    }
  };

  updateTableColumns = () => {
    this.setState({
      columns: Topics.getTableColumns(this.props.t, this.deleteTopic),
    });
  };

  fetchTopics = async (page) => {
    const query = {
      admin: true,
      ordering: '-created_at',
    };

    // Filter topics if needed
    const { searchValue } = this.state;
    if (searchValue && searchValue !== '') {
      query.key__in = this.getTopicsMatching(searchValue);
    }

    try {
      // Get all topics (no pagination for this service)
      const res = await api.list('topics', query);

      // Format incoming data to feed the table
      const tempTopicsList = [];
      res.forEach((topic) => {
        const tempTopic = {};
        this.state.columns.forEach((col) => {
          // Ignore translated values (ie topics labels)
          if (!languages.find((lang) => lang === col.dataField
            && col.isDummyField !== true)) {
            const value = getNestedValueFromStr(topic, col.dataField);
            setNestedValueFromStr(tempTopic, col.dataField, value || '');
          }
        });
        tempTopicsList.push(tempTopic);
      });

      // Translate topics labels
      languages.forEach((lang) => { // eslint-disable-line no-restricted-syntax
        tempTopicsList.forEach((topic, index) => {
          tempTopicsList[index][lang] = this.translations[lang][topic.key];
        });
      });

      // Set the new 'topics' state
      this.topics = tempTopicsList;
      this.setState({ topicsCount: res.length },
        () => { this.getTopics(page); });
    } catch (error) {
      ErrorUtil.handleCatched(this.props, error, false);
    }
  };

  saveTopic = async (topicId, field, value) => {
    try {
      if (field === 'validated' || languages.find((lang) => lang === field)) {
        await api.partial_update('topics', topicId, { [field]: value }, { admin: true });
        this.fetchTranslations([field]);
      } else {
        throw new Error(`Save not supported for that field ('${field}')`);
      }

      Toast.success(this.props, 'error:valid.saved');
      return true;
    } catch (error) {
      ErrorUtil.handleCatched(this.props, error);
      return false;
    }
  };

  deleteTopic = async (topicId) => {
    try {
      await api.delete('topics', topicId, { admin: true });
      Toast.success(this.props, 'error:valid.deleted');
      this.fetchTopics(this.currentPage);
    } catch (error) {
      ErrorUtil.handleCatched(this.props, error);
    }
  };

  handleTopicsSelectionChange = (topics) => {
    this.setState({
      mergeEnabled: Array.isArray(topics) && topics.length >= 2,
      selectedTopics: topics,
    });
  };

  mergeSelectedTopics = async () => {
    try {
      // Request merge to the API
      const res = await api.create('merge-topics', {
        topics: this.state.selectedTopics,
      });
      // Refresh page if needed
      if (this.state.selectedTopics.find(
        (selectedTopic) => this.topics.find(
          (topic) => topic.key === selectedTopic,
        ),
      )) {
        this.fetchTopics(this.currentPage);
      }
      // Select only the merged topic
      this.setState({ mergeEnabled: false, selectedTopics: [res.kept] });
      Toast.success(this.props, 'error:valid.merged');
    } catch (error) {
      ErrorUtil.handleCatched(this.props, error);
    }
  };

  handleSearch = (event) => {
    const { value } = event.target;
    this.timeoutHandler.doAfterTimeoutWithClbk((startTimeout) => {
      this.setState({ searchValue: value }, () => startTimeout(() => {
        this.fetchTopics(1);
      }));
    }, 'default', 200);
  };

  render() {
    const {
      selectedTopics, mergeEnabled, searchLoading, searchValue, dataLoading,
      currentPageTopics, columns, topicsCount,
    } = this.state;
    const cellEdit = cellEditFactory({
      mode: 'dbclick',
      blurToSave: true,
      // eslint-disable-next-line no-unused-vars
      beforeSaveCell: (oldValue, newValue, row, column, done) => {
        if (oldValue !== newValue) {
          this.saveTopic(row.key, column.dataField, newValue)
            .then((ret) => { done(ret); });
        }
        return { async: true };
      },
    });

    return (
      <div>
        <Head
          title="Doqboard topics"
          subTitle="Find here Doqboard topics."
          admin
          {...this.props}
        />
        <div className="dashboard-content">
          <Form>
            <FormGroup>
              <Label>Merge topics (5 max)</Label>
              <div className="row mb-5">
                <div className="col-12 col-md-6">
                  <TopicSelect
                    values={selectedTopics}
                    onChange={(value) => {
                      const topicsValues = value.map((topic) => topic.value);
                      this.handleTopicsSelectionChange(topicsValues);
                    }}
                    admin
                    maxSelectedTopics={5}
                  />
                </div>
                <div className="col-12 col-md-3 pt-2">
                  <Button
                    color="primary"
                    disabled={!mergeEnabled}
                    onClick={this.mergeSelectedTopics}
                  >
                    Merge
                  </Button>
                </div>
              </div>
            </FormGroup>
            <FormGroup>
              <Label>Search topics</Label>
              <div className="row mb-5">
                <div className="col-12 col-md-6 contains-loader">
                  <Input
                    type="text"
                    id="projectse-input"
                    className="d-block"
                    placeholder="Enter your pattern..."
                    value={searchValue}
                    onInput={this.handleSearch}
                    onChange={() => {}}
                  />
                  {
                    searchLoading ? (
                      <div className="d-none d-md-block">
                        <InputLoader />
                      </div>
                    ) : null
                  }
                </div>
              </div>
            </FormGroup>
          </Form>
          <div className="contains-loader">
            {
              dataLoading ? (
                <CardLoader />
              ) : null
            }
            <BootstrapTable
              keyField="key"
              data={currentPageTopics}
              columns={columns}
              cellEdit={cellEdit}
              headerClasses="admin_table"
              wrapperClasses="admin_table"
              bootstrap4
              hover
            />
          </div>
        </div>
        <nav className="my-5">
          <Pagination
            page={this.currentPage}
            count={topicsCount}
            pageSize={PAGE_SIZE}
            action={this.getTopics}
          />
        </nav>
      </div>
    );
  }
}


export default Topics;
