export const deObfuscateEmail = (name, provider) => `${name}@${provider}`;

export const emailListToArray = (emailList) => (
  emailList.split(/[;,|\s]+/).map((email) => email.trim()).filter((email) => email)
);

export const formatPasteEmailList = (e) => {
  e.preventDefault();
  const pasteValue = e.clipboardData.getData('Text').trim();
  const currentValue = e.target.value;
  const start = e.target.selectionStart;
  const end = e.target.selectionEnd;
  e.target.value = `${currentValue.slice(0, start)}${pasteValue}${currentValue.slice(end)}`;
};

export default null;
