import React, {
  useEffect, useState, useRef, useMemo,
} from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';
import { Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import memoize from 'memoize-one';
import { Mutex } from 'async-mutex';
import {
  teamLicenseProductsActions, teamLicensesActions, teamsActions, usersActions,
} from '../redux/actions';
import Page from '../components/Page';
import Pagination from '../components/Pagination';
import DashboardHead from '../components/DashboardHead';
import {
  Table, TableHead, TableRow, TableBody, TableCell,
} from '../components/CustomTable';
import { CardLoader } from '../components/Loader';
import DatePicker from '../components/DatePicker';
import LabeledSelect from '../components/LabeledSelect';
import LabeledInput from '../components/LabeledInput';
import IconConfirm from '../components/IconConfirm';
import BrowserView from '../components/BrowserView';
import MobileUnsupported from '../components/MobileUnsupported';
import TabletUnsupported from '../components/TabletUnsupported';
import useError from '../utils/HookErrorUtil';
import TimeoutHandler from '../utils/TimeoutHandler';
import useToast from '../utils/HookToast';
import usePrevious from '../utils/CustomHooks';
import { UNLIMITED_NUMBER } from '../constants';
import Help from '../components/Help';

const ACTIVE_LICENSE = 1;
const DISABLED_LICENSE = 0;


const getTeamOptions = (teamLicenseProducts, teams) => {
  const assignedTeamIds = teamLicenseProducts
    .filter((tmLicenseProduct) => tmLicenseProduct.team)
    .map((tmLicenseProduct) => tmLicenseProduct.team);
  return teams.map((tm) => ({
    label: tm.name,
    value: tm.id,
    isDisabled: assignedTeamIds.includes(tm.id),
  }));
};

const defaultSelectSyles = {
  singleValue: (provided) => ({
    ...provided,
    fontWeight: 600,
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    color: 'hsl(0, 0%, 80%) !important',
  }),
};

const emptySelectStyles = {
  ...defaultSelectSyles,
  control: (provided) => ({
    ...provided,
    borderColor: 'var(--newyellow-3) !important',
  }),
  placeholder: (provided) => ({
    ...provided,
    color: 'var(--newyellow-3) !important',
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    color: 'var(--newyellow-3) !important',
  }),
};

function TeamLicenseProductItem(props) {
  const {
    admin, teamLicenses, teamLicenseProduct, saveInput, teamOptions, deleteItem,
  } = props;
  const { t } = useTranslation();

  const license = teamLicenses[teamLicenseProduct.product];
  const previousLicense = usePrevious(license);

  let maxInvestigators = teamLicenseProduct.product_options.max_investigators_per_project;
  if (!maxInvestigators) {
    maxInvestigators = license ? license.max_investigators_per_project : 0;
  }

  let maxProjectCreations = teamLicenseProduct.product_options.max_project_creations;
  if (!maxProjectCreations) {
    maxProjectCreations = license ? license.max_project_creations : 0;
  }

  const [currentMaxInvestigators, setCurrentMaxInvestigators] = useState(maxInvestigators);
  const [currentMaxProjectCreations, setCurrentMaxProjectCreations] = useState(maxProjectCreations);

  useEffect(() => {
    // After a license change, the default maxInvestigators and maxProjectCreations
    // might have changed and need update
    if (previousLicense && license && previousLicense.id !== license.id) {
      setCurrentMaxInvestigators(maxInvestigators);
      setCurrentMaxProjectCreations(maxProjectCreations);
    }
  }, [license]);

  const getFormattedLimitation = (lim) => {
    if (admin || Number(lim) < UNLIMITED_NUMBER) return lim;
    return t('common:unlimited');
  };

  const renderLicenseType = (licenseProduct) => {
    const currentLicense = teamLicenses[licenseProduct.product];

    if (admin) {
      return (
        <LabeledSelect
          type="text"
          hideOptionalLabel
          name="license-product-select"
          value={currentLicense.id}
          onChange={(e) => saveInput(licenseProduct, 'product', Number(e.target.value), admin)}
          className="mb-1"
        >
          {Object.values(teamLicenses).map((lic) => (
            <option key={lic.key} value={lic.id}>
              {t(`user:licenses.team-${lic.key}`)}
            </option>
          ))}
        </LabeledSelect>
      );
    }

    return (
      <span className="font-weight-semibold">
        {currentLicense ? t(`user:licenses.team-${currentLicense.key}`) : ''}
      </span>
    );
  };

  const renderExpiryDate = (licenseProduct) => {
    const expiryDate = licenseProduct.expiry_date ? moment(licenseProduct.expiry_date) : null;

    if (admin) {
      return (
        <DatePicker
          defaultValue={expiryDate}
          onChange={(value) => {
            const formattedValue = value ? moment(value).format('YYYY-MM-DD') : null;
            saveInput(licenseProduct, 'expiry_date', formattedValue);
          }}
          customInputClassName="license-expiry-datepicker-input"
        />
      );
    }

    if (!expiryDate) return '';

    return moment(expiryDate).format('L');
  };

  const renderExternalInvestigators = (licenseProduct) => {
    if (admin) {
      return (
        <LabeledInput
          name="license-product-labeled-input"
          value={currentMaxInvestigators.toString()}
          hideOptionalLabel
          type="number"
          onChange={async (e) => {
            const newValue = Number(e.target.value);
            setCurrentMaxInvestigators(newValue);
            const newProductOptions = {
              ...licenseProduct.product_options,
              max_investigators_per_project: newValue,
            };
            await saveInput(licenseProduct, 'product_options', newProductOptions, admin);
          }}
          placeholder="Enter the number of extra investigators."
          className="mb-0"
        />
      );
    }

    return (
      <span>{getFormattedLimitation(maxInvestigators)}</span>
    );
  };

  const renderMaxProjectCreations = (licenseProduct) => {
    if (admin) {
      return (
        <LabeledInput
          name="license-product-labeled-input"
          value={currentMaxProjectCreations.toString()}
          hideOptionalLabel
          type="number"
          onChange={(e) => {
            const newValue = Number(e.target.value);
            setCurrentMaxProjectCreations(newValue);
            const newProductOptions = {
              ...licenseProduct.product_options,
              max_project_creations: newValue,
            };
            saveInput(licenseProduct, 'product_options', newProductOptions, admin);
          }}
          placeholder="Enter the number of extra investigators."
          className="mb-0"
        />
      );
    }

    return (
      <span>{getFormattedLimitation(maxProjectCreations)}</span>
    );
  };

  const renderTeam = (licenseProduct) => {
    const currentTeam = licenseProduct.team;

    const defaultValue = currentTeam
      ? teamOptions.find((option) => option.value === currentTeam) : null;

    return (
      <div className="text-left">
        <Select
          className="react-select team-react-select"
          classNamePrefix="react-select"
          onChange={(e) => {
            const value = e ? Number(e.value) : null;
            saveInput(licenseProduct, 'team', value, admin, true);
          }}
          options={teamOptions}
          placeholder={t('user:team.no-team-selected').toUpperCase()}
          isClearable
          defaultValue={defaultValue}
          styles={defaultValue ? defaultSelectSyles : emptySelectStyles}
        />
      </div>
    );
  };

  const renderDelete = (licenseProduct) => (
    <IconConfirm
      tooltipContent={t('user:license-products.delete')}
      onClick={() => deleteItem(licenseProduct)}
    >
      <button
        disabled={!admin}
        className="no-button-style text-gray-light"
      >
        <FontAwesomeIcon
          icon="trash-alt"
          transform="grow-5"
        />
      </button>
    </IconConfirm>
  );

  const renderStatus = (licenseProduct) => {
    const { active } = licenseProduct;

    if (admin) {
      return (
        <LabeledSelect
          className="mb-1"
          hideOptionalLabel
          name="status-license-product-select"
          value={active ? ACTIVE_LICENSE : DISABLED_LICENSE}
          onChange={(e) => saveInput(licenseProduct, 'active', Boolean(Number(e.target.value)), false, true)}
        >
          <option
            key={ACTIVE_LICENSE}
            value={ACTIVE_LICENSE}
          >
            {t('user:license-products.active')}
          </option>
          <option
            key={DISABLED_LICENSE}
            value={DISABLED_LICENSE}
          >
            {t('user:license-products.active', { context: 'not' })}
          </option>
        </LabeledSelect>
      );
    }

    return (
      <span className={active ? 'text-green' : 'text-red'}>
        {t('user:license-products.active', { context: active ? '' : 'not' })}
      </span>
    );
  };

  return (
    <TableRow key={teamLicenseProduct.id}>
      <TableCell>
        {renderLicenseType(teamLicenseProduct)}
      </TableCell>
      <TableCell>
        {renderStatus(teamLicenseProduct)}
      </TableCell>
      <TableCell>
        {renderTeam(teamLicenseProduct)}
      </TableCell>
      <TableCell>
        {renderExpiryDate(teamLicenseProduct)}
      </TableCell>
      <TableCell className={`text-center align-middle ${admin ? '' : 'small-cell'}`}>
        {renderExternalInvestigators(teamLicenseProduct)}
      </TableCell>
      {admin && (
        <>
          <TableCell>
            {renderMaxProjectCreations(teamLicenseProduct)}
          </TableCell>
          <TableCell>
            {renderDelete(teamLicenseProduct)}
          </TableCell>
        </>
      )}
    </TableRow>
  );
}

TeamLicenseProductItem.propTypes = {
  admin: PropTypes.bool,
  teamLicenseProduct: PropTypes.shape().isRequired,
  teamLicenses: PropTypes.shape().isRequired,
  teamOptions: PropTypes.arrayOf(PropTypes.shape()),
  saveInput: PropTypes.func,
  deleteItem: PropTypes.func,
};

TeamLicenseProductItem.defaultProps = {
  admin: false,
  teamOptions: [],
  saveInput: () => {},
  deleteItem: () => {},
};

export function TeamLicenseProductsTable(props) {
  const {
    admin, owner, className, tableClassName,
  } = props;
  const { handleCatched } = useError();
  const { t } = useTranslation();
  const { success } = useToast();

  const timeoutHandler = useRef(null);
  const mutex = useRef(null);

  const [ready, setReady] = useState(false);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [teamLicenseProductsCount, setTeamLicenseProductCount] = useState(0);
  const [pageSize, setPageSize] = useState(1);

  const tmLicensePdts = useSelector((state) => state.teamLicenseProducts);
  const teamLicenses = useSelector((state) => state.teamLicenses);
  const tms = useSelector((state) => state.teams);

  const teamLicenseProducts = useMemo(() => (
    Object.values(tmLicensePdts).sort((a, b) => (a.product > b.product ? -1 : 1))
  ), [tmLicensePdts]);
  const teams = useMemo(() => Object.values(tms).filter((tm) => tm.owner.id === owner.id), [tms]);

  const dispatch = useDispatch();

  // Owner
  const resyncOwner = async (id) => dispatch(usersActions.read(id, { admin }));

  // TeamLicenseProducts
  const fetchTeamLicenseProducts = async (data) => dispatch(teamLicenseProductsActions.list(
    data, { pagination: 'short' },
  ));
  const patchTeamLicenseProduct = async (id, data) => dispatch(teamLicenseProductsActions.patch(
    id, data, { admin },
  ));
  const addTeamLicenseProduct = async (data) => dispatch(teamLicenseProductsActions.create(
    data, { admin },
  ));
  const removeTeamLicenseProduct = async (id) => dispatch(teamLicenseProductsActions.remove(
    id, { admin },
  ));

  // TeamLicenses
  const fetchTeamLicenses = async () => dispatch(teamLicensesActions.list());

  // Teams
  const fetchTeams = async (data) => dispatch(teamsActions.list(
    data, { pagination: 'no' },
  ));
  const resyncTeams = async () => dispatch(teamsActions.resync({ admin }));

  // Data fetching
  const fetchPaginatedTeamLicenseProducts = async (currentPage) => {
    try {
      setLoading(true);
      const res = await fetchTeamLicenseProducts({
        page: currentPage,
        ordering: '-product__pk',
        owner: owner.id,
        admin,
      });

      setPage(currentPage);
      setTeamLicenseProductCount(res.count);
      setPageSize(Math.max(res.results.length, pageSize));
    } catch (error) {
      handleCatched(props, error);
    } finally {
      setLoading(false);
    }
  };

  const fetchData = async () => {
    try {
      const extraCalls = [];
      if (!admin) {
        extraCalls.push(fetchTeams({ owner: owner.id, admin }));
      }
      await Promise.all([
        fetchTeamLicenses(),
        fetchPaginatedTeamLicenseProducts(1),
        ...extraCalls,
      ]);
      setReady(true);
    } catch (error) {
      handleCatched(props, error);
    }
  };

  const createTeamLicenseProduct = async () => {
    if (!mutex.current.isLocked()) {
      const release = await mutex.current.acquire();
      try {
        setLoading(true);
        await addTeamLicenseProduct({ owner: owner.id });
        success('error:valid.success');
        await Promise.all([
          fetchPaginatedTeamLicenseProducts(1),
          resyncOwner(owner.id),
        ]);
      } catch (error) {
        handleCatched(props, error);
      } finally {
        release();
        setLoading(false);
      }
    }
  };

  const deleteTeamLicenseProduct = async (product) => {
    if (!mutex.current.isLocked()) {
      const release = await mutex.current.acquire();
      try {
        setLoading(true);
        const mustResyncTeams = Boolean(product.team);
        await removeTeamLicenseProduct(product.id);
        success('error:valid.deleted');
        await Promise.all([
          fetchPaginatedTeamLicenseProducts(1),
          resyncOwner(owner.id),
        ]);
        if (mustResyncTeams) {
          await resyncTeams();
        }
      } catch (error) {
        handleCatched(props, error);
      } finally {
        release();
        setLoading(false);
      }
    }
  };

  useEffect(async () => {
    timeoutHandler.current = new TimeoutHandler();
    mutex.current = new Mutex();
    await fetchData();
  }, []);

  const saveInput = async (
    product, key, value, mustResyncTeams = false, mustResyncOwner = false,
  ) => (
    new Promise((resolve) => {
      timeoutHandler.current.doAfterTimeout(async () => {
        try {
          const data = {};
          data[key] = value;
          await patchTeamLicenseProduct(product.id, data);
          if (mustResyncTeams) {
            await resyncTeams();
          }
          if (mustResyncOwner) {
            await resyncOwner(owner.id);
          }
          success('error:valid.success');
        } catch (error) {
          handleCatched(props, error);
          resolve();
        }
      });
    })
  );

  const memoizedGetTeamOptions = memoize(getTeamOptions);

  const teamOptions = memoizedGetTeamOptions(teamLicenseProducts, teams);

  const activeLicensesCount = Number(owner.active_licences_count);
  const disabledLicensesCount = Number(owner.disabled_licences_count);
  const unusedLicensesCount = Number(owner.unused_licences_count);

  return (
    <div className={className}>
      <div>
        <span className="font-weight-semibold">
          {t('user:license-products.active-license', { count: activeLicensesCount })}
        </span>
        {disabledLicensesCount > 0 && (
          <>
            &nbsp;-&nbsp;
            <span className="font-weight-semibold text-red">
              {t('user:license-products.disabled-license', { count: disabledLicensesCount })}
            </span>
          </>
        )}
        {unusedLicensesCount > 0 && (
          <>
            &nbsp;/&nbsp;
            <span className="font-weight-semibold text-newyellow-3">
              {t('user:license-products.unused-license', { count: unusedLicensesCount })}
            </span>
          </>
        )}
      </div>
      <Table extraClassName={`${tableClassName} license-products-table`}>
        <TableHead>
          <TableRow>
            <TableCell>
              {t('user:license-products.type')}
            </TableCell>
            <TableCell>
              {t('user:license-products.status')}
            </TableCell>
            <TableCell>
              {t('user:license-products.attribution')}
            </TableCell>
            <TableCell>
              {t('user:license-products.expiry-date')}
            </TableCell>
            <TableCell>
              {t('user:license-products.external-investing-centers')}
              <Help iconClassName="ml-2">
                {t('user:license-products.external-investing-centers-help')}
              </Help>
            </TableCell>
            {admin && (
              <>
                <TableCell>
                  {t('user:license-products.max-projects')}
                </TableCell>
                <TableCell>
                  &nbsp;
                </TableCell>
              </>
            )}
          </TableRow>
        </TableHead>
        <TableBody>
          {ready && !loading && teamLicenseProducts.length > 0 ? (
            teamLicenseProducts.map((teamLicenseProduct) => (
              <TeamLicenseProductItem
                key={teamLicenseProduct.id}
                teamLicenseProduct={teamLicenseProduct}
                admin={admin}
                teamLicenses={teamLicenses}
                saveInput={saveInput}
                teamOptions={teamOptions}
                deleteItem={deleteTeamLicenseProduct}
              />
            ))
          ) : (
            <TableRow>
              <TableCell colSpan={admin ? '7' : '6'}>
                {loading || !ready ? (
                  <CardLoader />
                ) : (
                  <span>
                    {t('user:license-products.no-license-products')}
                  </span>
                )}
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
      {teamLicenseProductsCount > 10 && (
        <div className="mt-2">
          <Pagination
            page={page}
            count={teamLicenseProductsCount}
            pageSize={pageSize}
            action={fetchPaginatedTeamLicenseProducts}
          />
        </div>
      )}
      {admin && (
        <Button
          onClick={() => {
            createTeamLicenseProduct();
          }}
          className="mt-3"
          disabled={!admin}
        >
          {t('user:license-products.add')}
        </Button>
      )}
    </div>
  );
}

TeamLicenseProductsTable.propTypes = {
  className: PropTypes.string,
  tableClassName: PropTypes.string,
  owner: PropTypes.shape().isRequired,
  admin: PropTypes.bool,
};

TeamLicenseProductsTable.defaultProps = {
  className: 'mt-3',
  tableClassName: 'no-pointer mt-2 mb-0',
  admin: false,
};

function LicenseProducts(props) {
  const authUser = useSelector((state) => state.auth.authUser);

  const { t } = useTranslation();

  return (
    <Page
      {...props}
      title={t('common:nav.licenses')}
    >
      <DashboardHead
        {...props}
        title={t('common:nav.licenses')}
        breadcrumdPrevious={[]}
      />
      <div className="dashboard-content mb-2">
        <div className="card bg-white table-responsive contains-loader card-shadow p-4 mt-3 mobile-card overflow-x-visible">
          <BrowserView>
            <h4 className="font-weight-normal mb-3">
              {t('user:license-products.list-title')}
            </h4>
            <div className="py-3 licenses-page-subtitle">
              {t('user:license-products.list-subtitle')}
            </div>
            <TeamLicenseProductsTable
              owner={authUser}
            />
          </BrowserView>
          <TabletUnsupported />
          <MobileUnsupported>
            common:mobile-and-tablet-unsupported
          </MobileUnsupported>
        </div>
      </div>
    </Page>
  );
}

export default LicenseProducts;
