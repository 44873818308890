import { createReducer } from 'redux-starter-kit';
import { projectEntriesActions } from '../actions';
import getStdDbModelReduxMapping from './std-db-model-reducers';

export const projectEntriesInitialState = {};

const projectEntriesReducer = createReducer(
  projectEntriesInitialState,
  getStdDbModelReduxMapping(projectEntriesActions),
);

export default projectEntriesReducer;
