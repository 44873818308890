import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  ELEMENT_DIRECT_IDENTIFIER,
  ELEMENT_INDIRECT_IDENTIFIER,
  ELEMENT_NOT_IDENTIFIER,
} from '../constants';

const getIdentifierIcon = (identificationLevel) => {
  switch (identificationLevel) {
    case ELEMENT_INDIRECT_IDENTIFIER:
      return ['fad', 'user-shield'];
    case ELEMENT_DIRECT_IDENTIFIER:
      return ['fas', 'user-shield'];
    default:
      return ['far', 'user-shield'];
  }
};

const getIconStyle = (identificationLevel) => {
  switch (identificationLevel) {
    case ELEMENT_INDIRECT_IDENTIFIER:
      return { '--fa-primary-opacity': '0.4', '--fa-secondary-opacity': '1' };
    default:
      return undefined;
  }
};

function IdentifierIcon(props) {
  const { className, identificationLevel, transform } = props;
  return (
    <FontAwesomeIcon
      className={className}
      icon={getIdentifierIcon(identificationLevel)}
      style={getIconStyle(identificationLevel)}
      transform={transform}
    />
  );
}

IdentifierIcon.propTypes = {
  className: PropTypes.string,
  identificationLevel: PropTypes.oneOf([
    ELEMENT_NOT_IDENTIFIER,
    ELEMENT_INDIRECT_IDENTIFIER,
    ELEMENT_DIRECT_IDENTIFIER,
  ]).isRequired,
  transform: PropTypes.string,
};

IdentifierIcon.defaultProps = {
  className: undefined,
  transform: '',
};

export default IdentifierIcon;
