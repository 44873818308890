import { createReducer } from 'redux-starter-kit';
import { elementLinksActions } from '../actions';
import getStdDbModelReduxMapping from './std-db-model-reducers';

export const elementLinksInitialState = {};

const elementLinksReducer = createReducer(
  elementLinksInitialState,
  getStdDbModelReduxMapping(elementLinksActions),
);

export default elementLinksReducer;
