import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Label, Button } from 'reactstrap';
import { Mutex } from 'async-mutex';
import history from '../history';
import LicenseChecker from './LicenseChecker';
import NewModal from './NewModal';
import LabeledInput from './LabeledInput';
import SpecialtySelect from './SpecialtySelect';
import UserSelect from './UserSelect';
import Validator, { GUI_EFFECT_FULL } from './Validator';
import { formatPasteEmailList } from '../utils/email';
import useToast from '../utils/HookToast';
import useError from '../utils/HookErrorUtil';
import { childrenPropTypes } from '../utils/generic-prop-types';
import { teamMembersActions, teamsActions } from '../redux/actions';


function TeamCreateModal(props) {
  const { children, admin } = props;
  const { t } = useTranslation();
  const { success } = useToast();
  const { handleCatched } = useError();

  const initialState = {
    name: '',
    leaderEmail: '',
    specialty: null,
  };

  const [name, setName] = useState(initialState.name);
  const [leaderEmail, setLeaderEmail] = useState(initialState.leaderEmail);
  const [specialty, setSpecialty] = useState(initialState.specialty);
  const [newOwnerId, setNewOwnerId] = useState(null);

  const emailRef = useRef();
  const nameRef = useRef();
  const specialtyRef = useRef();
  const ownerRef = useRef();
  const mutex = new Mutex();
  const modal = useRef();

  const user = useSelector((state) => state.auth.authUser);

  const canBeTeamLeader = admin || user.limitations.can_be_team_leader;

  const dispatch = useDispatch();

  const addTeam = async (data) => dispatch(teamsActions.create(data, { admin }));
  const addTeamMember = async (data) => dispatch(teamMembersActions.create(data, { admin }));

  const reset = () => {
    setName(initialState.name);
    setLeaderEmail(initialState.leaderEmail);
    setSpecialty(initialState.specialty);
  };

  const createTeam = async () => {
    if (!mutex.isLocked()) {
      const release = await mutex.acquire();
      const params = {
        owner: admin ? newOwnerId : user.id,
        name,
      };
      if (specialty) {
        params.specialties = [specialty];
      }
      try {
        const team = await addTeam(params);
        if (leaderEmail) {
          await addTeamMember({
            team: team.id,
            email: leaderEmail,
            leader: true,
          });
        }
        success('error:valid.success');
        const baseUrl = admin ? 'admin' : 'dashboard';
        history.push(`/${baseUrl}/team/${team.id}`);
        if (modal.current) {
          modal.current.hide();
          reset();
        }
      } catch (error) {
        handleCatched(props, error);
      } finally {
        release();
      }
    }
  };

  const onCreateTeam = async () => {
    const nameOk = nameRef.current.validate(name);
    const leaderEmailOk = canBeTeamLeader || (emailRef && emailRef.current.validate(leaderEmail));
    const specialtyOk = canBeTeamLeader
      || (specialtyRef && specialtyRef.current.validate(specialty));
    const ownerOk = !admin || (ownerRef && ownerRef.current.validate(newOwnerId, GUI_EFFECT_FULL));
    if (nameOk && leaderEmailOk && specialtyOk && ownerOk) {
      await createTeam();
    }
  };

  const onLeaderEmailChange = (event) => {
    const value = event.target ? event.target.value : '';
    setLeaderEmail(value);
    if (emailRef) {
      emailRef.current.validate(value);
    }
  };
  const onLeaderEmailPaste = (event) => {
    formatPasteEmailList(event);
    onLeaderEmailChange(event);
  };

  return (
    <LicenseChecker
      limName="can_create_team"
      limitations={user.limitations}
      licMsgModalChildren={children}
    >
      <NewModal
        title={t('user:team.add')}
        size="sm"
        trigger={children}
        onClosed={reset}
        footer={(
          <div>
            <Button
              id="team-validate"
              color="newturquoise-1"
              className="px-4 contains-loader font-weight-semibold"
              onClick={onCreateTeam}
            >
              {t('common:button.create')}
            </Button>
          </div>
        )}
        ref={modal}
      >
        <form
          className="team-create-modal"
          noValidate
          onSubmit={(e) => e.preventDefault()}
        >
          <LabeledInput
            type="text"
            label={t('user:team.title.name')}
            className="mb-3"
            name="team-name"
            placeholder={t('user:team.placeholder.name')}
            required
            onChange={(e) => setName(e.target.value)}
            value={name}
            ref={nameRef}
          />
          {!canBeTeamLeader && (
            <>
              <div className="mb-3">
                <Label className="mb-0">
                  {t('user:team.team-specialty')}
                </Label>
                <SpecialtySelect
                  onChange={(values) => setSpecialty(values[0].value)}
                  required
                  placeholder={t('user:team.select-team-specialty')}
                  ref={specialtyRef}
                  values={specialty ? [specialty] : []}
                />
              </div>
              <LabeledInput
                type="text"
                label={t('user:team.invite-team-leader')}
                name="leader-email"
                placeholder={t('error:placeholder.email')}
                validation="email"
                required
                value={leaderEmail}
                onChange={onLeaderEmailChange}
                onPaste={onLeaderEmailPaste}
                ref={emailRef}
                autoComplete="off"
                className="mb-0"
              />
              <div className="help-text">
                {t('user:team.invite-team-leader-help')}
              </div>
            </>
          )}
          {admin && (
            <>
              <Validator
                required
                ref={ownerRef}
              >
                <UserSelect
                  admin
                  value={newOwnerId}
                  onChange={(e) => setNewOwnerId(e.value)}
                  placeholder="Select the team owner..."
                />
              </Validator>
              <div className="help-text">
                ONLY practitioners and institutions can own teams.
              </div>
            </>
          )}
        </form>
      </NewModal>
    </LicenseChecker>
  );
}

TeamCreateModal.propTypes = {
  children: childrenPropTypes().isRequired,
  admin: PropTypes.bool,
};

TeamCreateModal.defaultProps = {
  admin: false,
};

export default TeamCreateModal;
