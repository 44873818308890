import buildStdDbModelActions from './std-db-model-actions';
import api from '../../api';

const stdDbModelActions = buildStdDbModelActions('specialties');

const list = (params = {}, options = {}) => async (dispatch) => {
  const res = await api.list('specialties', params, options);
  dispatch(stdDbModelActions.listSuccess(res));
  return res;
};

export default {
  ...stdDbModelActions,
  list,
};
