export const USER_LICENSE_ASSISTANT_KEY = 'assistant';
export const USER_LICENSE_INTERN_KEY = 'intern';
export const USER_LICENSE_PRO_KEY = 'pro';
export const USER_LICENSE_ADVANCED_KEY = 'advanced';
export const USER_LICENSE_EXPERT_KEY = 'expert';
export const USER_LICENSE_ASSOCIATION_KEY = 'association';
export const USER_LICENSE_INDUSTRIALIST_KEY = 'industrialist';
export const USER_LICENSE_HEALTHCARE_FACILITY_KEY = 'healthcare-facility';
export const TEAM_LICENSE_PRO_KEY = 'pro';
export const TEAM_LICENSE_ADVANCED_KEY = 'advanced';
export const TEAM_LICENSE_EXPERT_KEY = 'expert';
export const TEAM_LICENSE_RESIDENTS_KEY = 'residents';

/**
 * Merge limitations objects (union)
 * @param {Object} limitations1
 * @param {Object} limitations2
 */
export const mergeLimitations = (limitations1, limitations2) => {
  const limitations1Keys = Object.keys(limitations1);
  const limitations2Keys = Object.keys(limitations2);
  const commonKeys = limitations1Keys.filter((key) => limitations2Keys.includes(key));
  const commonLimitations = {};

  commonKeys.forEach((key) => {
    const value1 = limitations1[key];
    const value2 = limitations2[key];
    let mergedValue = false;

    if (Number.isInteger(value1) && Number.isInteger(value2)) {
      mergedValue = Math.max(value1, value2);
    } else {
      mergedValue = value1 || value2;
    }

    commonLimitations[key] = mergedValue;
  });

  return { ...limitations1, ...limitations2, ...commonLimitations };
};

/**
 * Returns false if the limitation is reached, true else
 * @param {Array} limitations
 * @param {String} limName
 * @param {Number} currentCount
 */
export const checkLimitation = (limitations, limName, currentCount = undefined) => {
  const limValue = limitations[limName];

  if (limValue) {
    if (currentCount) {
      // Numeric limitation
      return currentCount < limValue;
    }
    // Boolean limitation
    return limValue;
  }

  return false;
};

/**
 * Returns whether limitations are all disabled or not
 * @param {Object} limitations
 */
export const areLimitationsAllDisabled = (limitations) => (
  !Object.values(limitations).some((lim) => lim !== false && lim !== 0)
);

/**
 * Returns the color associated to the license
 * @param {String} licenseKey
 */
export const getLicenseColor = (licenseKey) => {
  switch (licenseKey) {
    case USER_LICENSE_ADVANCED_KEY: return 'newblue';
    case USER_LICENSE_EXPERT_KEY: return 'newturquoise';
    case TEAM_LICENSE_ADVANCED_KEY: return 'newblue';
    case TEAM_LICENSE_EXPERT_KEY: return 'newturquoise';
    case USER_LICENSE_ASSISTANT_KEY:
    case USER_LICENSE_INTERN_KEY:
    case USER_LICENSE_PRO_KEY:
    case TEAM_LICENSE_PRO_KEY:
    case TEAM_LICENSE_RESIDENTS_KEY:
    default:
      return 'newgreen';
  }
};

/**
 * Gets the next available license upgrade (first one if multiple)
 * @param {Object} currentLicense
 * @param {Array} licenses
 */
export const getLicenseNextUpgrade = (currentLicense, licenses) => (
  licenses.find((license) => currentLicense.next_upgrades.includes(license.id))
);

/**
 * Gets the list of available license upgrades
 * @param {Object} currentLicense
 * @param {Array} licenses
 */
export const getLicenseUpgradesList = (currentLicense, licenses) => {
  const list = [];
  const nextUpgrades = currentLicense.next_upgrades;
  const getLicenseFromId = (id) => licenses.find((license) => license.id === id);

  if (nextUpgrades.length) {
    const licenseUpgrades = nextUpgrades.map((upgrade) => getLicenseFromId(upgrade));
    list.push(...licenseUpgrades);
    licenseUpgrades.forEach((upgrade) => {
      list.push(...getLicenseUpgradesList(upgrade, licenses));
    });
  }

  return list;
};
