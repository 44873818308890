import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { projectEntriesActions } from '../redux/actions';
import { nsOptions } from '../i18n';
import ElementBase from './ElementBase';
import ElementCalculationResult from './ElementCalculationResult';
import ElementCalculationInfo from './ElementCalculationInfo';
import ElementCalculationManager from './ElementCalculationManager';
import { ENTRY_TYPE_MEASUREMENT } from '../constants';


const mapStateToProps = (state, ownProps) => ({
  element: state.elements[ownProps.elementId],
  inclusion: state.inclusions[ownProps.inclusionId],
  projectElement: state.projectElements[ownProps.projectElementId],
});

const mapDispatchToProps = (dispatch) => ({
  addEntry: async (data) => dispatch(projectEntriesActions.create(
    { type: ENTRY_TYPE_MEASUREMENT, ...data },
  )),
  patchEntry: async (id, data) => dispatch(projectEntriesActions.patch(id, data)),
});


@connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })
@withTranslation('', nsOptions)
class ElementCalculation extends Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
    elementId: PropTypes.number,
    projectElementId: PropTypes.number,
    projectElement: PropTypes.shape().isRequired,
    project: PropTypes.shape().isRequired,
    moduleInstanceId: PropTypes.number,
    inclusion: PropTypes.shape(),
    inclusionId: PropTypes.number,
    parent: PropTypes.shape({
      content: PropTypes.shape(),
    }),
    methods: PropTypes.shape({
      move: PropTypes.func,
      reload: PropTypes.func.isRequired,
      remove: PropTypes.func,
    }).isRequired,
    isEditMode: PropTypes.bool,
    isEntryMode: PropTypes.bool,
    admin: PropTypes.bool,
    isModule: PropTypes.bool,
  };

  static defaultProps = {
    elementId: null,
    projectElementId: null,
    moduleInstanceId: null,
    inclusion: null,
    inclusionId: null,
    parent: null,
    isEditMode: false,
    isEntryMode: false,
    admin: false,
    isModule: false,
  };

  checkLinks = () => {
    if (this.elBaseRef) this.elBaseRef.checkLinks();
  };

  render() {
    const {
      t, isEntryMode, isEditMode, projectElement, inclusion, admin, moduleInstanceId,
    } = this.props;
    return (
      <ElementBase
        {...this.props}
        elementCategory="editable"
        ref={(ref) => { this.elBaseRef = ref; }}
      >
        <div className="col-12 d-flex d-flex-row">
          {
            projectElement ? (
              <div className="">
                {
                  !isEditMode && !admin && isEntryMode && inclusion ? (
                    <ElementCalculationResult
                      projectElementId={projectElement.id}
                      inclusionId={inclusion.id}
                      moduleInstanceId={moduleInstanceId}
                    />
                  ) : (
                    <ElementCalculationInfo
                      projectElement={projectElement}
                    />
                  )
                }
              </div>
            ) : null
          }
          <div className="">
            {
              isEditMode && !isEntryMode ? (
                <ElementCalculationManager
                  {...this.props}
                >
                  <button className="btn btn-lg btn-primary btn-calculations mb-2">
                    {t('project:form.edit-calculations')}
                  </button>
                </ElementCalculationManager>
              ) : null
            }
          </div>
        </div>
      </ElementBase>
    );
  }
}


export default ElementCalculation;
