import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core';


const useStyles = makeStyles((theme) => ({
  sectionTitle: (level) => ({
    textAlign: 'center',
    paddingBottom: theme.spacing(1),
    borderBottom: `1px solid ${theme.palette.grey[400]}`,
    marginBottom: 8,
    fontSize: level === 1 ? '1.36rem' : '1.11rem',
    fontWeight: 600,
  }),
}));


function TitleCard(props) {
  const { name, level } = props;
  const classes = useStyles(level);

  return (
    <Typography variant="h6" align="center" className={classes.sectionTitle}>
      {name}
    </Typography>
  );
}

TitleCard.propTypes = {
  name: PropTypes.string.isRequired,
  level: PropTypes.number,
};

TitleCard.defaultProps = {
  level: 1,
};

export default TitleCard;
