import { createAction } from 'redux-starter-kit';
import buildStdDbModelActions, { resyncWithoutDispatch } from './std-db-model-actions';
import api from '../../api';

const endpoint = 'project-elements';
const stdDbModelActions = buildStdDbModelActions(endpoint);
const moveSuccess = createAction(`${endpoint}/move_success`);

const move = (pElementId, currentTabId, newPos, newTabId = null, params = {}) => async (dispatch,
  getState) => {
  const patch = { sorting: newPos };
  if (newTabId) {
    patch.project_page = newTabId;
    patch.module = null;
  }
  await api.partial_update(endpoint, pElementId, patch, params);

  const getQueryOnProjectPage = () => ({
    project_page__in: Object.values(getState().projectPages).map((item) => item.id),
  });
  const res = await resyncWithoutDispatch(
    endpoint, getState, getQueryOnProjectPage, params,
  );

  dispatch(moveSuccess(res));

  return res;
};

export default {
  ...stdDbModelActions,
  moveSuccess,
  move,
};
