import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import HttpApi from 'i18next-http-backend';
import api from './api';
import locales from './locales';

export const languages = ['en', 'fr'];

export const nsOptions = {
  withRef: true,
};

const remoteNS = ['country', 'specialty', 'topic', 'locale'];

i18n
  .use(HttpApi)
  .use(LanguageDetector)
  .on('languageChanged', (lng) => { document.documentElement.lang = lng; })
  .init({
    fallbackLng: 'en',
    debug: false,
    whitelist: [...languages],
    returnNull: false,
    returnEmptyString: false,
    appendNamespaceToCIMode: true,

    ns: [
      // Local
      'common',
      'error',
      'project',
      'inclusion',
      'user',
      'auth',
      'stats',
      'community',
      // Remote
      ...remoteNS,
    ],
    defaultNS: 'common',

    detection: {
      order: ['querystring', 'navigator', 'localStorage'],
      lookupQuerystring: 'lng',
      lookupCookie: 'i18next',
      lookupLocalStorage: 'i18nextLng',
      lookupFromPathIndex: 0,
      lookupFromSubdomainIndex: 0,
      caches: ['localStorage', 'cookie'],
      excludeCacheFor: ['cimode'],
    },

    backend: {
      loadPath: '{{lng}}/{{ns}}',
      addPath: null,
      parse: (data) => data,
      request: async (options, url, payload, callback) => {
        const [lang, namespace] = url.split('/');
        try {
          if (remoteNS.includes(namespace)) {
            const data = await api.list('locales', {
              namespace,
              lang,
            });
            callback(null, { data, status: 200 });
          } else {
            const data = locales[lang][namespace];
            callback(null, { data, status: 200 });
          }
        } catch (e) {
          console.error(e);
          callback(null, { status: 404 });
        }
      },
      crossDomain: true,
    },

    interpolation: {
      escapeValue: false,
      format(value, format) {
        const localValue = value;
        if (format === 'uppercase') return localValue.toUpperCase();
        if (format === 'lowercase') return localValue.toLowerCase();
        if (format === 'capitalize') {
          localValue[0] = localValue[0].toUpperCase();
        }
        return localValue;
      },
    },

    react: {
      bindI18n: 'languageChanged',
      bindI18nStore: '',
      useSuspense: false,
    },
  });

export default i18n;
