import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { FormGroup, Input, Label } from 'reactstrap';
import FieldLabel from './FieldLabel';
import DbUtil from '../utils/DbUtil';
import Validator, { GUI_EFFECT_FULL } from './Validator';


export const eventToMultipleChoicesValue = (e, prevValue) => {
  const { checked, value } = e.target;
  if (checked) {
    if (!prevValue) return [value];
    return [...new Set([...prevValue, value])];
  }
  return prevValue.filter((val) => val !== value);
};

class LabeledChoice extends Component {
  static propTypes = {
    multipleChoices: PropTypes.bool,
    name: PropTypes.string.isRequired,
    label: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.shape(),
    ]),
    choices: PropTypes.arrayOf(PropTypes.shape()).isRequired,
    onChange: PropTypes.func.isRequired,
    smallInformation: PropTypes.oneOfType([PropTypes.element, PropTypes.node]),
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.bool,
      PropTypes.number,
      PropTypes.arrayOf(PropTypes.string),
    ]),
    className: PropTypes.string,
    rowClassName: PropTypes.string,
    colLabelClassName: PropTypes.string,
    labelClassName: PropTypes.string,
    colInputClassName: PropTypes.string,
    inputClassName: PropTypes.string,
    choiceClassName: PropTypes.string,
    validatorClassName: PropTypes.string,
    disabled: PropTypes.bool,
    disabledChoices: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string])),
    required: PropTypes.bool,
    orientation: PropTypes.oneOf(['vertical', 'horizontal']),
    hideOptionalLabel: PropTypes.bool,
    validatorGuiEffect: PropTypes.string,
  };

  static defaultProps = {
    multipleChoices: false,
    smallInformation: null,
    value: undefined,
    className: '',
    rowClassName: 'row',
    colLabelClassName: 'col-12',
    labelClassName: 'mb-1',
    colInputClassName: 'col-12',
    inputClassName: 'custom-control-input',
    choiceClassName: 'custom-control-label',
    validatorClassName: '',
    disabled: false,
    disabledChoices: [],
    required: false,
    orientation: 'horizontal',
    label: undefined,
    hideOptionalLabel: false,
    validatorGuiEffect: GUI_EFFECT_FULL,
  };

  constructor(props) {
    super(props);
    this.id = `LabeledChoice_${DbUtil.generateId()}`;
  }

  handleInput = (e) => {
    const { validatorGuiEffect, onChange } = this.props;
    const isValid = this.validator.validate(e.target.value, validatorGuiEffect);
    if (isValid) onChange(e);
  };

  validate = (value, guiEffect = GUI_EFFECT_FULL) => this.validator.validate(value, guiEffect);

  render() {
    const {
      orientation, className, label, required, choices, name, value, disabled, smallInformation,
      labelClassName, rowClassName, colLabelClassName, colInputClassName, inputClassName,
      choiceClassName, hideOptionalLabel, multipleChoices, validatorClassName, disabledChoices,
    } = this.props;
    const formGroupClassName = `custom-control ${multipleChoices ? 'custom-checkbox' : 'custom-radio'} form-choice ${orientation === 'vertical' ? 'py-1' : 'pr-3 d-inline-block mt-2'}`;

    return (
      <FormGroup className={className} tag="fieldset">
        <div className={`${rowClassName}`}>
          {
            label && (
              <div className={colLabelClassName}>
                <FieldLabel
                  className={labelClassName}
                  required={required || hideOptionalLabel}
                >
                  {label}
                </FieldLabel>
              </div>
            )
          }
          <Validator
            className={validatorClassName}
            required={required}
            ref={(validator) => { this.validator = validator; }}
          >
            <div className={colInputClassName}>
              {
                choices.map((choice) => {
                  const ChoiceComponent = choice.component || FormGroup;
                  return (
                    <ChoiceComponent
                      key={choice.value}
                      className={formGroupClassName}
                      check
                    >
                      <Input
                        id={`${this.id}_${choice.value}`}
                        type={multipleChoices ? 'checkbox' : 'radio'}
                        className={inputClassName}
                        name={name}
                        value={choice.value}
                        onChange={this.handleInput}
                        checked={multipleChoices ? Boolean(value && value.findIndex((val) => (
                          String(val) === String(choice.value)
                        )) >= 0) : value === choice.value}
                        disabled={disabled || disabledChoices.includes(choice.value)
                          ? true : choice.disabled}
                      />
                      <Label for={`${this.id}_${choice.value}`} className={choiceClassName} check>
                        {choice.text}
                      </Label>
                    </ChoiceComponent>
                  );
                })
              }
            </div>
          </Validator>
          {
            smallInformation ? (
              <div className="authentication-small-info">
                {smallInformation}
              </div>
            ) : null
          }
        </div>
      </FormGroup>
    );
  }
}


export default LabeledChoice;
