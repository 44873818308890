const CENTER_ID_SEP = '-';

export const TEAM_CENTER_TYPE = 'team';
export const USER_CENTER_TYPE = 'user';
export const DELETED_USER_CENTER_TYPE = 'deleted_user';
export const MEMBER_CENTER_TYPE = 'member';
export const DELETED_MEMBER_CENTER_TYPE = 'deleted_member';
export const MISC_CENTER_TYPE = 'misc';

export const getCenterId = (prefix, rawId, suffix = undefined) => `${prefix}${CENTER_ID_SEP}${rawId}${suffix ? `${CENTER_ID_SEP}${suffix}` : ''}`;
export const getRawIdFromCenterId = (centerId, idType = Number) => {
  const rawId = centerId.split(CENTER_ID_SEP)[1];
  if (idType === Number) return Number(rawId);
  return rawId;
};

export const getSuffixFromCenterId = (centerId, suffixType = Number) => {
  const suffix = centerId.split(CENTER_ID_SEP)[2];
  if (suffixType === Number) return Number(suffix);
  return suffix;
};

export const getRawIdAndSuffix = (rawId, suffix) => `${rawId}${CENTER_ID_SEP}${suffix}`;

const isCenterIdOfType = (centerId, type) => centerId.split(CENTER_ID_SEP)[0] === type;

export const isTeamCenterId = (centerId) => isCenterIdOfType(centerId, TEAM_CENTER_TYPE);
export const isUserCenterId = (centerId) => isCenterIdOfType(centerId, USER_CENTER_TYPE);
export const isDeletedUserCenterId = (centerId) => isCenterIdOfType(
  centerId, DELETED_USER_CENTER_TYPE,
);
export const isMemberCenterId = (centerId) => isCenterIdOfType(centerId, MEMBER_CENTER_TYPE);
export const isDeletedMemberCenterId = (centerId) => isCenterIdOfType(
  centerId, DELETED_MEMBER_CENTER_TYPE,
);
export const isMiscCenterId = (centerId) => isCenterIdOfType(centerId, MISC_CENTER_TYPE);
