import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import Badge from '@material-ui/core/Badge/Badge';
import withStyles from '@material-ui/core/styles/withStyles';
import { nsOptions } from '../i18n';
import '../assets/css/badges.css';


const stylesBase = (theme, backgroundColor, fontSize = 10, borderRadius = undefined) => ({
  '& .MuiBadge-badge': {
    backgroundColor,
    userSelect: 'none',
    fontSize: theme.typography.pxToRem(fontSize),
    borderRadius,
  },
});

const styles = (theme) => ({
  soonBadge: {
    ...stylesBase(theme, theme.palette.error.light),
  },
  newBadge: {
    ...stylesBase(theme, theme.palette.primary.light),
  },
  updatedBadge: {
    ...stylesBase(theme, 'var(--newturquoise-1)'),
  },

});

// eslint-disable-next-line react/prefer-stateless-function
class CustomizableBadge extends Component {
  static propTypes = {
    children: PropTypes.shape(),
    content: PropTypes.string.isRequired,
    className: PropTypes.string,
    muiClassName: PropTypes.string.isRequired,
  };

  static defaultProps = {
    children: undefined,
    className: '',
  };

  render() {
    const {
      children, className, content, muiClassName,
    } = this.props;

    let finalClassName = muiClassName;
    if (className) finalClassName += ` ${className}`;

    return (
      <Badge
        badgeContent={content}
        color="primary"
        className={finalClassName}
      >
        {children}
      </Badge>
    );
  }
}


@withTranslation('', nsOptions)
@withStyles(styles)
class SoonBadge extends Component {
  static propTypes = {
    children: PropTypes.shape(),
    t: PropTypes.func.isRequired,
    classes: PropTypes.shape().isRequired,
  };

  static defaultProps = {
    children: undefined,
  };

  render() {
    const {
      children, t, classes,
    } = this.props;

    return (
      <CustomizableBadge
        {...this.props}
        content={t('common:soon')}
        muiClassName={classes.soonBadge}
        classes={classes}
      >
        { children }
      </CustomizableBadge>
    );
  }
}


@withTranslation('', nsOptions)
@withStyles(styles)
class NewBadge extends Component {
  static propTypes = {
    children: PropTypes.shape(),
    t: PropTypes.func.isRequired,
    classes: PropTypes.shape().isRequired,
  };

  static defaultProps = {
    children: undefined,
  };

  render() {
    const {
      children, t, classes,
    } = this.props;

    return (
      <CustomizableBadge
        {...this.props}
        content={t('common:new')}
        muiClassName={classes.newBadge}
        classes={classes}
      >
        { children }
      </CustomizableBadge>
    );
  }
}

@withTranslation('', nsOptions)
@withStyles(styles)
class UpdatedBadge extends Component {
  static propTypes = {
    children: PropTypes.shape(),
    t: PropTypes.func.isRequired,
    classes: PropTypes.shape().isRequired,
  };

  static defaultProps = {
    children: undefined,
  };

  render() {
    const {
      children, t, classes,
    } = this.props;

    return (
      <CustomizableBadge
        {...this.props}
        content={t('common:updated')}
        muiClassName={classes.updatedBadge}
        classes={classes}
      >
        { children }
      </CustomizableBadge>
    );
  }
}

export {
  SoonBadge,
  NewBadge,
  UpdatedBadge,
};
