import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { withRouter } from 'react-router';
import { Route, Switch } from 'react-router-dom';
import OrganizationCommunity from '../components/OrganizationCommunity';
import UserCommunity from '../components/UserCommunity';
import OrganizationProfile from '../components/OrganizationProfile';

function Community(props) {
  const { match } = props;
  const { url } = match;

  const user = useSelector((state) => state.auth.authUser);

  const { can_have_a_public_profile: canHavePublicProfile } = user.limitations;
  const { public_profile: publicProfile } = user;

  return (
    <div className="dashboard-content mb-2">
      {canHavePublicProfile ? (
        <OrganizationCommunity
          publicProfile={publicProfile}
          organization={user}
        />
      ) : (
        <Switch>
          <Route
            {...props}
            path={`${url}/profiles`}
          >
            <UserCommunity />
          </Route>
          <Route
            {...props}
            path={`${url}/profile/:id`}
          >
            <OrganizationProfile />
          </Route>
        </Switch>
      )}
    </div>
  );
}

Community.propTypes = {
  match: PropTypes.shape().isRequired,
};

export default withRouter(Community);
