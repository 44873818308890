import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { nsOptions } from '../i18n';


@withTranslation('', nsOptions)
class ViewMoreTableFooter extends React.Component {
  static propTypes = {
    url: PropTypes.string.isRequired,
    t: PropTypes.func.isRequired,
  };

  render() {
    const { url, t } = this.props;
    return (
      <tfoot>
        <tr>
          <td colSpan="6" className="text-center">
            <Link to={url} className="text-newblue-1">
              {t('common:table.view-more')}
            </Link>
          </td>
        </tr>
      </tfoot>
    );
  }
}


export default ViewMoreTableFooter;
