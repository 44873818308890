import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { Label } from 'reactstrap';
import { nsOptions } from '../i18n';
import { childrenPropTypes } from '../utils/generic-prop-types';


@withTranslation('', nsOptions)
class FieldLabel extends Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
    children: childrenPropTypes(),
    className: PropTypes.string,
    for: PropTypes.string,
    required: PropTypes.bool,
  };

  static defaultProps = {
    children: null,
    className: null,
    for: null,
    required: false,
  };

  constructor(props) {
    super(props);
    this.props = props;
  }

  render() {
    const {
      t, children, required, className, for: labelFor,
    } = this.props;
    return (
      <Label className={className} for={labelFor}>
        <div className={`d-inline${required ? '' : ' font-italic'}`}>
          {children}
        </div>
        {!required && (
          <small className="text-gray font-italic">
            {' '}
            {t('common:form.optional-field')}
          </small>
        )}
      </Label>
    );
  }
}


export default FieldLabel;
