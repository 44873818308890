import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import NewTooltip from './NewTooltip';


// eslint-disable-next-line react/prefer-stateless-function
class AdminBadge extends Component {
  static propTypes = {
    className: PropTypes.string,
  };

  static defaultProps = {
    className: '',
  };

  render() {
    return (
      <NewTooltip content="Doqboard Administrator">
        <span className={`text-red-dark ${this.props.className}`}>
          <FontAwesomeIcon icon="user-tie" />
        </span>
      </NewTooltip>
    );
  }
}


export default AdminBadge;
