import { Component } from 'react';
import { isMobileOnly } from 'react-device-detect';
import { childrenPropTypes } from '../utils/generic-prop-types';

export const isMobileView = () => isMobileOnly;

class MobileView extends Component {
  render() {
    return isMobileView() ? this.props.children : null;
  }
}

MobileView.propTypes = {
  children: childrenPropTypes().isRequired,
};

export default MobileView;
