import moment from 'moment';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { nsOptions } from '../i18n';
import { getCgsuUrl, getLegalUrl } from '../utils/urls';


@withTranslation('', nsOptions)
class Footer extends Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
    i18n: PropTypes.shape().isRequired,
  };

  render() {
    const { t, i18n } = this.props;
    return (
      <div id="footer" className="row justify-content-center mx-0">
        <div className="col-auto text-center text-gray small">
          <p className="text-center mt-1">
            &copy;
            &nbsp;
            {moment().format('YYYY')}
            &nbsp;
            Doqboard
            &nbsp;&mdash;&nbsp;
            <a
              href={getCgsuUrl(i18n.language)}
              target="_blank"
              className="text-gray"
              rel="noopener noreferrer"
            >
              {t('common:footer.gcsu')}
            </a>
            &nbsp;
            {t('common:footer.and')}
            &nbsp;
            <a
              href={getLegalUrl(i18n.language)}
              target="_blank"
              className="text-gray"
              rel="noopener noreferrer"
            >
              {t('common:footer.legal')}
            </a>
          </p>
        </div>
      </div>
    );
  }
}


export default Footer;
