import React, {
  useState, useRef, useEffect, useMemo,
} from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  ENTRY_LATENCY_TIMEOUT_MS, getMutex, retrieveProjectEntries,
} from './ElementBase';
import NewTooltip from './NewTooltip';
import { projectEntriesActions } from '../redux/actions';
import useToast from '../utils/HookToast';
import useError from '../utils/HookErrorUtil';
import TimeoutHandler from '../utils/TimeoutHandler';
import {
  ELEMENT_TYPE_DATE, ELEMENT_TYPE_TIME, ELEMENT_TYPE_MULTIPLE_CHOICES,
  ELEMENT_TYPE_UNIQUE_CHOICE, ELEMENT_TYPE_MEASUREMENT, ELEMENT_TYPE_TEXT,
  ENTRY_TYPE_DATE, ENTRY_TYPE_TIME, ENTRY_TYPE_MULTIPLE_CHOICES, ENTRY_TYPE_UNIQUE_CHOICE,
  ENTRY_TYPE_MEASUREMENT, ENTRY_TYPE_TEXT,
} from '../constants';

const NOT_APPLICABLE = 'not_applicable';
const NOT_AVAILABLE = 'not_available';

const getProjectEntryType = (element) => {
  if (!element) return null;
  const correspondingTypes = {
    [ELEMENT_TYPE_DATE]: ENTRY_TYPE_DATE,
    [ELEMENT_TYPE_TIME]: ENTRY_TYPE_TIME,
    [ELEMENT_TYPE_MULTIPLE_CHOICES]: ENTRY_TYPE_MULTIPLE_CHOICES,
    [ELEMENT_TYPE_UNIQUE_CHOICE]: ENTRY_TYPE_UNIQUE_CHOICE,
    [ELEMENT_TYPE_MEASUREMENT]: ENTRY_TYPE_MEASUREMENT,
    [ELEMENT_TYPE_TEXT]: ENTRY_TYPE_TEXT,
  };
  return correspondingTypes[element.type];
};

function QualifyMissingData(props) {
  const {
    projectElementId, isDisabled, moduleInstanceId, onClick, inclusion,
  } = props;
  const timeoutHandler = useRef(null);
  const { success } = useToast();
  const { handleCatched } = useError();
  const { t } = useTranslation();

  const pEntries = useSelector((state) => state.projectEntries);

  const projectEntries = useMemo(() => retrieveProjectEntries(
    Object.values(pEntries),
    inclusion.id,
    projectElementId,
    moduleInstanceId,
  ), [pEntries, inclusion, projectElementId, moduleInstanceId]);

  const projectEntry = projectEntries.find((pEntry) => pEntry.missing);
  const [reason, setReason] = useState(projectEntry ? projectEntry.missing : null);

  const dispatch = useDispatch();

  const addEntry = async (data) => dispatch(projectEntriesActions.create(data));
  const removeEntry = async (id) => dispatch(projectEntriesActions.remove(id));
  const patchEntry = async (id, data) => dispatch(projectEntriesActions.patch(id, data));

  useEffect(() => {
    timeoutHandler.current = new TimeoutHandler();
  }, []);

  useEffect(() => {
    if ((!projectEntry && reason) || (projectEntry && projectEntry.missing !== reason)) {
      let newReason = null;
      if (projectEntry) {
        newReason = projectEntry.missing;
      }
      setReason(newReason);
    }
  }, [projectEntry]);

  const choices = [
    {
      chosen_text: (
        <span>
          [
          {t('inclusion:qualified-missing-data.not-available-code')}
          ]
          &nbsp;
          {t('inclusion:qualify-missing-data.declared-not-available')}
        </span>
      ),
      text: (
        <span>
          [
          {t('inclusion:qualified-missing-data.not-available-code')}
          ]
          &nbsp;
          {t('inclusion:qualify-missing-data.declare-not-available')}
        </span>
      ),
      value: NOT_AVAILABLE,
    },
    {
      chosen_text: (
        <span>
          [
          {t('inclusion:qualified-missing-data.not-applicable-code')}
          ]
          &nbsp;
          {t('inclusion:qualify-missing-data.declared-not-applicable')}
        </span>
      ),
      text: (
        <span>
          [
          {t('inclusion:qualified-missing-data.not-applicable-code')}
          ]
          &nbsp;
          {t('inclusion:qualify-missing-data.declare-not-applicable')}
        </span>
      ),
      value: NOT_APPLICABLE,
    },
  ];

  async function updateEntry(newReason) {
    timeoutHandler.current.doAfterTimeout(async () => {
      const mutex = getMutex(props);
      if (!mutex.isLocked()) {
        const release = await mutex.acquire();
        const {
          projectElement, element, showLoader, checkForCorruptedMissingDataCount,
        } = props;

        try {
          showLoader();
          if (projectEntry) {
            if (reason !== newReason) {
              await patchEntry(projectEntry.id, { missing: newReason });
              setReason(newReason);
            } else {
              await removeEntry(projectEntry.id);
              setReason(null);
            }
          } else if (newReason) {
            const type = getProjectEntryType(element);
            await addEntry({
              inclusion: inclusion.id,
              project_element: projectElement.id,
              module: moduleInstanceId,
              missing: newReason,
              type,
            });
            setReason(newReason);
          }
          success('error:valid.success');
          checkForCorruptedMissingDataCount();
        } catch (err) {
          handleCatched(props, err);
        } finally {
          release();
          showLoader(false);
        }
      }
    }, 0, ENTRY_LATENCY_TIMEOUT_MS);
  }

  return (
    choices.map((choice) => (
      <NewTooltip
        key={`${choice.value}-${projectElementId}-${moduleInstanceId}`}
        content={t('inclusion:qualify-missing-data.click-to-cancel')}
        disabled={reason !== choice.value}
      >
        <a
          className={`dropdown-item ${isDisabled ? 'disabled' : ''} ${reason === choice.value ? 'text-newblue-1 font-weight-semibold' : ''}`}
          role="button"
          tabIndex="-1"
          onClick={() => {
            updateEntry(choice.value);
            if (onClick) {
              onClick();
            }
          }}
          onKeyPress={() => {}}
        >
          {reason === choice.value ? (
            <>
              {choice.chosen_text}
            </>
          ) : (
            <>
              {choice.text}
            </>
          )}
        </a>
      </NewTooltip>
    ))
  );
}

QualifyMissingData.propTypes = {
  projectElement: PropTypes.shape(),
  inclusion: PropTypes.shape(),
  projectElementId: PropTypes.number,
  moduleInstanceId: PropTypes.number,
  isDisabled: PropTypes.bool,
  isEntryMode: PropTypes.bool,
  onClick: PropTypes.func,
  showLoader: PropTypes.func,
  checkForCorruptedMissingDataCount: PropTypes.func,
};

QualifyMissingData.defaultProps = {
  projectElement: null,
  inclusion: null,
  projectElementId: null,
  moduleInstanceId: null,
  projectEntries: null,
  isDisabled: false,
  isEntryMode: false,
  onClick: () => {},
  showLoader: () => {},
  checkForCorruptedMissingDataCount: async () => {},
};

export default QualifyMissingData;
