import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import '../assets/css/bigmenu.css';


// eslint-disable-next-line react/prefer-stateless-function
class BigMenuItem extends Component {
  static propTypes = {
    location: PropTypes.shape({
      pathname: PropTypes.string.isRequired,
    }).isRequired,
    to: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.func,
    ]).isRequired,
    children: PropTypes.node.isRequired,
    className: PropTypes.string,
    soon: PropTypes.bool,
  };

  static defaultProps = {
    className: '',
    soon: false,
  };

  render() {
    const isActive = new RegExp(`^${this.props.to}`, 'i').test(this.props.location.pathname);
    return this.props.to.constructor === String && !isActive ? (
      <Link
        className={`big-menu-item ${this.props.className}`}
        to={this.props.to}
      >
        <span className="big-menu-item-text">
          {this.props.children}
        </span>
      </Link>
    ) : (
      <a
        className={`big-menu-item${isActive ? ' active' : ''} ${this.props.className}`}
        role="button"
        tabIndex="-1"
      >
        <span className="big-menu-item-text">
          {this.props.children}
        </span>
        {
          this.props.soon ? (
            <span className="soon-badge ml-1">
              Soon
            </span>
          ) : null
        }
      </a>
    );
  }
}


export default BigMenuItem;
