import React, { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import api from '../api';
import { teamsActions, usersActions } from '../redux/actions';
import NewModal from './NewModal';
import NewTooltip from './NewTooltip';
import { childrenPropTypes } from '../utils/generic-prop-types';
import useError from '../utils/HookErrorUtil';
import { TEAM_LICENSE_TRIAL, USER_LICENSE_TRIAL } from '../constants';
import canRequestFreeTrial from '../utils/free-trial';

function FreeTrialModal(props) {
  const { children } = props;
  const modal = useRef(null);
  const { handleCatched } = useError();
  const { t } = useTranslation();

  const [trialType, setTrialType] = useState(TEAM_LICENSE_TRIAL);
  const [trialRequested, setTrialRequested] = useState(false);
  const [userTeams, setUserTeams] = useState(undefined);
  const [canRequestTrial, setCanRequestTrial] = useState(false);
  const [ready, setReady] = useState(false);

  const authUser = useSelector((state) => state.auth.authUser);

  const dispatch = useDispatch();

  const resyncTeams = async (teamId) => dispatch(teamsActions.read(teamId));
  const resyncUser = async (userId) => dispatch(usersActions.read(userId));

  const fetchTeams = async (user) => {
    try {
      const res = await api.list('teams', {
        members__user: user.id,
      }, { pagination: 'no' });
      setUserTeams(res.filter((tm) => tm.owner.id === user.id));
      setCanRequestTrial(canRequestFreeTrial(user, res));
      setReady(true);
    } catch (error) {
      handleCatched(props, error);
    }
  };

  const onLoad = async () => {
    if (authUser) {
      try {
        await fetchTeams(authUser);
      } catch (error) {
        handleCatched(props, error);
        modal.current.hide();
      }
    }
  };

  const onValidate = async () => {
    try {
      const res = await api.create('request-free-trial', {
        trial_type: trialType,
      });
      const { new_team: newTeam } = res;
      if (newTeam) {
        await resyncTeams(newTeam);
      }
      setTrialRequested(true);
    } catch (error) {
      handleCatched(props, error);
    }
  };

  const onClosed = async () => {
    if (trialRequested) {
      try {
        await resyncUser(authUser.id);
      } catch (error) {
        handleCatched(props, error);
      }
    }
    setReady(false);
    setUserTeams(undefined);
    setCanRequestTrial(false);
    setTrialType(TEAM_LICENSE_TRIAL);
  };

  const getButtonClassName = (active, disabled) => (
    `btn btn-${active ? '' : 'outline-'}newblue-2 free-trial-type ${disabled && 'disabled'}`
  );

  const noTeamInfo = trialType === TEAM_LICENSE_TRIAL && Array.isArray(userTeams)
    && !userTeams.length;

  return (
    <NewModal
      {...props}
      title={(
        <div className="d-flex justify-content-center align-items-center pb-3">
          {canRequestTrial ? (
            <>
              <FontAwesomeIcon
                icon={['fad', 'gift']}
                className="mr-4 text-newblue-2"
                transform="grow-5"
              />
              <div
                style={{ paddingTop: '0.3rem' }}
              >
                {t('common:free-trial.modal-title')}
              </div>
            </>
          ) : (
            <>
              <FontAwesomeIcon
                icon={['fal', 'meh']}
                className="mr-3"
                transform="grow-3"
              />
              {t('common:free-trial.modal-title-no-trial')}
            </>
          )}
        </div>
      )}
      subTitle="title"
      size="lg"
      trigger={children}
      onLoad={onLoad}
      onClosed={onClosed}
      ref={modal}
      footer={canRequestTrial ? (
        <div className="pt-2">
          {trialRequested ? (
            <div className="font-weight-semibold text-newgreen-2">
              {t('common:free-trial.request-sent')}
            </div>
          ) : (
            <button
              type="button"
              className="btn btn-newyellow-1 send-request-button px-3 d-flex align-items-center"
              onClick={onValidate}
            >
              <FontAwesomeIcon
                icon={['far', 'chevron-right']}
                className="mr-2"
                transform="shrink-2"
              />
              {t('common:free-trial.send-request')}
            </button>
          )}
        </div>
      ) : null}
    >
      {canRequestTrial ? (
        <div className="text-center">
          <div
            className="font-weight-semibold pb-4"
            style={{ fontSize: '1.2rem' }}
          >
            {t('common:free-trial.which-trial')}
          </div>
          <div
            className="d-flex btn-group-toggle align-items-center justify-content-center py-3"
            onChange={(e) => setTrialType(e.target.value)}
          >
            <NewTooltip
              content={t('common:free-trial.individual-tooltip')}
              contentClassName="trial-type-button"
            >
              <label
                className={getButtonClassName(trialType === USER_LICENSE_TRIAL, trialRequested)}
              >
                <input
                  type="radio"
                  name="free-trial-type"
                  value={USER_LICENSE_TRIAL}
                  disabled={trialRequested}
                />
                {t('common:free-trial.individual')}
              </label>
            </NewTooltip>
            <NewTooltip
              content={t('common:free-trial.collaborative-tooltip')}
              contentClassName="trial-type-button"
            >
              <label
                className={getButtonClassName(trialType === TEAM_LICENSE_TRIAL, trialRequested)}
              >
                <input
                  type="radio"
                  name="free-trial-type"
                  value={TEAM_LICENSE_TRIAL}
                  disabled={trialRequested}
                />
                {t('common:free-trial.collaborative')}
              </label>
            </NewTooltip>
          </div>
          <div className="pt-4 font-italic text-gray-dark">
            {t(`common:free-trial.${trialType.replace(/_/g, '-')}-${noTeamInfo ? 'no-team-' : ''}info`)}
          </div>
        </div>
      ) : (
        <>
          {ready ? (
            <div className="text-center">
              <div
                className="font-weight-semibold pb-4"
                style={{ fontSize: '1.2rem' }}
              >
                {t('common:free-trial.cannot-activate-trial')}
              </div>
              <div className="pt-2 pb-5 text-gray-dark">
                {t('common:free-trial.contact-us')}
              </div>
            </div>
          ) : <span />}
        </>
      )}
    </NewModal>
  );
}

FreeTrialModal.propTypes = {
  children: childrenPropTypes().isRequired,
};

export default FreeTrialModal;
