import PropTypes from 'prop-types';
import React, { useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import Cropper from 'react-easy-crop';
import useError from '../utils/HookErrorUtil';

const LOGO_HEIGHT = 150;
const LOGO_WIDTH = 150;

function ImageCropper(props) {
  const { showPreview, showScale, saveImage } = props;
  const [src, setSrc] = useState('');
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [croppedImage, setCroppedImage] = useState(null);
  const { handleCatched } = useError();
  const { t } = useTranslation();

  const readImgFile = (file) => new Promise((resolve) => {
    try {
      const allowedType = (/\/(jpe?g|png)$/i).test(file.type);
      if (!allowedType) throw new Error(t('error:error.image-type-not-supported'));
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result);
      reader.readAsDataURL(file);
    } catch (error) {
      handleCatched(props, error);
      resolve('');
    }
  });

  const onImgChange = async (e) => {
    if (e.target.files && e.target.files.length > 0) {
      setZoom(1);
      const file = e.target.files[0];
      const imageDataUrl = await readImgFile(file);
      setSrc(imageDataUrl);
    }
  };

  const createImage = async (url) => new Promise((resolve, reject) => {
    const image = new Image();
    image.addEventListener('load', () => resolve(image));
    image.addEventListener('error', (error) => reject(error));
    image.setAttribute('crossOrigin', 'anonymous');
    image.src = url;
  });

  const getCroppedImage = async (imageSrc, pixelCrop) => {
    const image = await createImage(imageSrc);
    const canvas = document.createElement('canvas');

    const ctx = canvas.getContext('2d');

    if (!ctx) return null;

    const maxSize = Math.max(image.width, image.height);
    const safeArea = 2 * ((maxSize / 2) * Math.sqrt(2));

    canvas.width = safeArea;
    canvas.height = safeArea;

    ctx.drawImage(
      image,
      safeArea / 2 - image.width * 0.5,
      safeArea / 2 - image.height * 0.5,
    );

    const data = ctx.getImageData(0, 0, safeArea, safeArea);

    canvas.width = pixelCrop.width;
    canvas.height = pixelCrop.height;

    ctx.putImageData(
      data,
      0 - safeArea / 2 + image.width * 0.5 - pixelCrop.x,
      0 - safeArea / 2 + image.height * 0.5 - pixelCrop.y,
    );

    // Resize the image
    const oc = document.createElement('canvas');
    const octx = oc.getContext('2d');

    oc.width = LOGO_WIDTH;
    oc.height = LOGO_HEIGHT;

    octx.drawImage(
      canvas,
      0,
      0,
      canvas.width,
      canvas.height,
      0,
      0,
      LOGO_WIDTH,
      LOGO_HEIGHT,
    );

    // Draw a white rectangle behind the cropped image
    // Usefull for zoomed out images
    octx.globalCompositeOperation = 'destination-over';
    octx.fillStyle = '#ffffff';
    octx.fillRect(0, 0, oc.width, oc.height);

    return new Promise((resolve) => {
      oc.toBlob((file) => {
        resolve(file);
      }, 'image/png');
    });
  };

  const onCropComplete = useCallback((croppedArea, newCroppedAreaPixels) => {
    setCroppedAreaPixels(newCroppedAreaPixels);
  }, []);

  const saveCroppedImage = useCallback(async () => {
    try {
      const croppedImg = await getCroppedImage(src, croppedAreaPixels);
      const base64Img = await readImgFile(croppedImg);
      saveImage(base64Img);
      setCroppedImage(URL.createObjectURL(croppedImg));
    } catch (error) {
      handleCatched(props, error);
    }
  }, [src, croppedAreaPixels]);

  return (
    <div>
      <div className="row align-items-center">
        <div className="my-2 col-auto">
          <input
            type="file"
            accept="image/jpeg, image/png"
            onChange={onImgChange}
          />
        </div>
        {src && (
          <div
            className="col-auto"
            style={{ width: '100%', height: '200px' }}
          >
            <div>
              <Cropper
                image={src}
                crop={crop}
                zoom={zoom}
                aspect={1}
                onCropChange={setCrop}
                restrictPosition={false}
                onCropComplete={onCropComplete}
                zoomWithScroll={false}
                minZoom={0.1}
                maxZoom={3}
              />
            </div>
          </div>
        )}
      </div>
      {src && (
        <div className="row align-items-center justify-content-center my-2">
          {showScale && (
            <div className="col-auto px-1">
              <label
                className="m-0"
                htmlFor="scale_input"
              >
                Zoom
              </label>
              <input
                type="range"
                id="scale_input"
                min="0.1"
                max="3"
                step="0.1"
                value={zoom}
                onChange={(e) => setZoom(Number(e.target.value))}
                className="align-middle ml-2"
              />
            </div>
          )}
          {crop && (
            <div className="col-auto px-1">
              <button
                onClick={saveCroppedImage}
              >
                Save
              </button>
            </div>
          )}
        </div>
      )}
      {showPreview && croppedImage && (
        <div>
          <img src={croppedImage} alt="" />
        </div>
      )}
    </div>
  );
}

ImageCropper.propTypes = {
  saveImage: PropTypes.func,
  showPreview: PropTypes.bool,
  showScale: PropTypes.bool,
};

ImageCropper.defaultProps = {
  saveImage: () => {},
  showPreview: false,
  showScale: false,
};

export default ImageCropper;
