import { createReducer } from 'redux-starter-kit';
import { languagesActions } from '../actions';
import getStdDbModelReduxMapping from './std-db-model-reducers';

export const languagesInitialState = {};

const languagesReducer = createReducer(
  languagesInitialState,
  getStdDbModelReduxMapping(languagesActions, 'key'),
);

export default languagesReducer;
