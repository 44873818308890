import PropTypes from 'prop-types';
import React, { Component } from 'react';
import memoize from 'memoize-one';
import { withTranslation } from 'react-i18next';
import { withToastManager } from 'react-toast-notifications';
import { connect } from 'react-redux';
import { nsOptions } from '../i18n';
import ErrorUtil from '../utils/ErrorUtil';
import ElementUtil from '../utils/ElementUtil';
import SortUtil from '../utils/SortUtil';
import { ELEMENT_TYPE_MULTIPLE_CHOICES, ELEMENT_TYPE_UNIQUE_CHOICE } from '../constants';
import ElementBaseLink from './ElementBaseLink';
import LabeledSelect from './LabeledSelect';


const mapStateToProps = (state) => ({
  projectElements: state.projectElements,
  elements: state.elements,
  elementModalities: state.elementModalities,
});


@withToastManager
@connect(mapStateToProps)
@withTranslation('', nsOptions)
class ElementQualitativeLink extends Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
    id: PropTypes.number.isRequired,
    identifier: PropTypes.node.isRequired,
    elementOptions: PropTypes.arrayOf(PropTypes.shape()).isRequired,
    choosenChoice: PropTypes.number,
    choosenElement: PropTypes.number,
    excludedChoices: PropTypes.arrayOf(PropTypes.number),
    highlightMissing: PropTypes.bool,
    remove: PropTypes.func.isRequired,
    onChange: PropTypes.func,
    projectElements: PropTypes.shape().isRequired,
    elements: PropTypes.shape().isRequired,
    elementModalities: PropTypes.shape().isRequired,
  };

  static defaultProps = {
    choosenChoice: undefined,
    choosenElement: undefined,
    excludedChoices: [],
    highlightMissing: false,
    onChange: null,
  };

  constructor(props) {
    super(props);
    this.state = {
      choice: this.props.choosenChoice,
      source: this.props.choosenElement,
      choices: [],
    };
    this.memoizeValidation = memoize((highlightMissing, ref, value) => {
      if (ref && highlightMissing) {
        ref.validate(value === 'none' ? null : value);
      }
    });
    this.memoizeBuildChoices = memoize(this.buildChoices);
  }

  componentDidMount() {
    this.memoizeBuildChoices(this.props.excludedChoices);
  }

  componentDidUpdate() {
    const { highlightMissing, excludedChoices } = this.props;
    const { choice } = this.state;
    this.memoizeValidation(highlightMissing, this.ref, choice);
    this.memoizeBuildChoices(excludedChoices);
  }

  buildChoices = (excludedChoices) => {
    try {
      const {
        t, projectElements, elements, elementModalities, choosenChoice,
      } = this.props;
      const projectElement = projectElements[this.state.source];
      const element = elements[projectElement.element];
      const choice = element.values.includes(choosenChoice) ? choosenChoice : 'none';
      if (![ELEMENT_TYPE_UNIQUE_CHOICE, ELEMENT_TYPE_MULTIPLE_CHOICES].includes(element.type)) {
        return;
      }
      const choices = Object.values(elementModalities).filter((modality) => (
        element.values.includes(modality.id) && (
          !excludedChoices.includes(modality.id)
            || modality.id === choice // Do not exclude selected choice
        )
      )).sort(SortUtil.sortArray).map((modality) => (
        <option key={modality.id} value={modality.id}>
          {ElementUtil.formatModalityName(modality, t)}
        </option>
      ));
      this.setState({ choices, choice });
    } catch (error) {
      ErrorUtil.handleCatched(this.props, error, false);
    }
  };

  callParentHandler = () => {
    if (this.props.onChange) {
      this.props.onChange({
        id: this.props.id,
        choice: Number(this.state.choice),
        source: Number(this.state.source),
      });
    }
  };

  handleChoiceChange = (e) => {
    this.setState({ choice: e.target.value }, this.callParentHandler);
  };

  handleSourceChange = ({ source }) => {
    this.setState({ source, choice: 'none' }, () => {
      this.callParentHandler();
    });
  };

  render() {
    const {
      t, elementOptions, highlightMissing, id, identifier, remove, choosenElement,
    } = this.props;
    const { choice, choices } = this.state;

    return (
      <ElementBaseLink
        className="element-link element-qualitative-link"
        divSelectClassName="col-auto"
        selectClassName="custom-control custom-select"
        defaultElementChoice="common:form.select-variable"
        id={id}
        identifier={identifier}
        elementOptions={elementOptions}
        highlightMissing={highlightMissing}
        source={choosenElement}
        remove={remove}
        onChange={this.handleSourceChange}
      >
        <div className="col-auto form-inline font-italic">
          {t('project:modal.link.is')}
        </div>
        <div className="col">
          <LabeledSelect
            name="choice"
            required
            className="custom-control custom-select"
            value={choice}
            onChange={this.handleChoiceChange}
            ref={(ref) => { this.ref = ref; }}
          >
            <option value="none" disabled>
              {t('common:form.select')}
            </option>
            {choices}
          </LabeledSelect>
        </div>
      </ElementBaseLink>
    );
  }
}


export default ElementQualitativeLink;
