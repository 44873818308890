import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import React from 'react';
import NewTooltip from './NewTooltip';

const coloredStyle = {
  '--fa-primary-color': 'var(--primary)',
  '--fa-secondary-color': 'var(--newyellow-1)',
  '--fa-primary-opacity': 1,
  '--fa-secondary-opacity': 1,
};

const Warning = (props) => (
  <NewTooltip
    content={props.children}
    containerClassName={props.className}
    placement={props.placement}
    theme={props.tooltipTheme}
  >
    <FontAwesomeIcon
      icon={['fas', 'exclamation-triangle']}
      className={`warning ${props.iconClassName}`}
      transform={props.transform}
      style={props.style}
    />
  </NewTooltip>
);

Warning.propTypes = {
  className: PropTypes.string,
  iconClassName: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  transform: PropTypes.string,
  style: PropTypes.shape(),
  placement: PropTypes.string,
  tooltipTheme: PropTypes.string,
};

Warning.defaultProps = {
  className: '',
  iconClassName: '',
  transform: undefined,
  style: coloredStyle,
  placement: 'right',
  tooltipTheme: 'light',
};

export default Warning;
