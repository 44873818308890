import moment from 'moment';
import i18n from '../i18n';
import {
  isFullDate, isMonthYearDate, isTime, isYearDate,
} from './stats';
import { TIME_FORMAT } from './date';
import store from '../redux/store';

export const getDateFormat = (format) => {
  if (isFullDate(format)) {
    return i18n.t('common:elements.date-full-format');
  }
  if (isMonthYearDate(format)) {
    return i18n.t('common:elements.date-month-year-format');
  }
  if (isYearDate(format)) {
    return i18n.t('common:elements.date-year-format');
  }
  if (isTime(format)) {
    return TIME_FORMAT;
  }
  return i18n.t('common:elements.date-time-format');
};


class Formatter {
  constructor(
    type = null, unit = null, precision = null,
    isRatio = false, closedLower = true,
    closedUpper = false, emptyComponent = '–',
  ) {
    this.type = type;
    this.unit = unit;
    this.precision = precision === null ? 2 : precision;
    this.isRatio = isRatio;
    this.closedLower = closedLower;
    this.closedUpper = closedUpper;
    this.emptyComponent = emptyComponent;
    const { authUser: user } = store.getState().auth;
    this.numberFormatter = new Intl.NumberFormat(user.language, {
      style: 'decimal',
      minimumFractionDigits: 0,
      maximumFractionDigits: 20,
    });
  }

  getPrecisionFromInterval = (lower, upper, precision) => {
    if (lower === -Infinity || upper === Infinity) return precision;
    // Let x.10^-y where 1 <= x <= 9 and y integer >= 1
    // log(x.10^-y) = log(x) + log(10^-y) = log(x) - y
    // log(1) <= log(x) <= log(9)
    // 0 <= log(x) <= 0.96
    // -y <= log(x) - y <= 0.96 - y
    // |0.96 - y| <= |log(x) - y| < |-y|
    // y - 0.96 <= |log(x) - y| <= y
    // ceil(y - 0.96) <= ceil(|log(x) - y|) <= ceil(y)
    // y <= ceil(|log(x) - y|) <= y
    const minPrecision = Math.ceil((Math.abs(Math.log10(upper - lower))));
    return Math.max(minPrecision, precision);
  }

  renderNumber = (value) => {
    const { precision, isRatio, emptyComponent } = this;
    let { unit } = this;
    let newValue = value;

    if (newValue === -Infinity) {
      newValue = '-∞';
    } else if (newValue === Infinity) {
      newValue = '+∞';
    } else if (!Number.isFinite(newValue)) {
      newValue = emptyComponent;
    } else if (newValue === 0) {
      // Converts -0 to 0.
      newValue = Math.abs(newValue);
    }

    if (isRatio) {
      newValue *= 100;
      unit = unit || '%';
    }

    if (typeof newValue === 'number' && !Number.isInteger(newValue)) {
      newValue = this.numberFormatter.format(newValue.toFixed(precision));
    }

    if (unit) {
      // eslint-disable-next-line no-irregular-whitespace
      return `${newValue} ${unit}`;
    }

    return newValue;
  };

  renderInterval = (value) => {
    const { type, precision, isRatio } = this;
    let { unit, closedLower, closedUpper } = this;
    let effectivePrecision = precision;
    let [lower, upper] = value;
    if (lower === null || lower === '') {
      lower = -Infinity;
    }
    if (lower === -Infinity) {
      closedLower = false;
    }
    if (upper === null || upper === '') {
      upper = Infinity;
    }
    if (upper === Infinity) {
      closedUpper = false;
    }
    if (typeof lower === 'number' && typeof upper === 'number') {
      effectivePrecision = this.getPrecisionFromInterval(lower, upper, precision);
    }
    lower = (new this.constructor(type, null, effectivePrecision, isRatio)).format(lower);
    upper = (new this.constructor(type, null, effectivePrecision, isRatio)).format(upper);
    const lowerBound = closedLower ? '[' : ']';
    const separator = i18n.t('stats:interval-sep');
    const upperBound = closedUpper ? ']' : '[';
    unit = unit ? ` ${unit}` : '';
    return `${lowerBound}${lower}${separator} ${upper}${upperBound}${unit}`;
  };

  formatDateTime = (value) => {
    const { type } = this;
    return value.format(getDateFormat(type));
  };

  format(value) {
    const { unit, emptyComponent } = this;
    if (value === undefined || value === null) {
      return emptyComponent;
    }
    if (typeof value === 'number') {
      return this.renderNumber(value);
    }
    if (Array.isArray(value) && value.length === 2) {
      return this.renderInterval(value);
    }
    if (value instanceof moment) {
      return this.formatDateTime(value);
    }
    if (unit) {
      return `${value} ${unit}`;
    }
    return `${value}`;
  }
}

export default Formatter;
