import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withTranslation, useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { Button } from 'reactstrap';
import { projectPagesActions } from '../redux/actions';
import { getSortingHandlerId, getSortingItemId, extractSortingItemDigitalId } from '../redux/actions/sorting-handler';
import { nsOptions } from '../i18n';
import NewTooltip from './NewTooltip';
import ElementContentEditable from './ElementContentEditable';
import DropdownMenu from './NewDropdownMenu';
import ButtonConfirm from './ButtonConfirm';
import { formatPageTitle } from '../utils/data-util';
import DraggableComponent from './DraggableComponent';
import DroppableComponent from './DroppableComponent';
import { isMobileView } from './MobileView';
import ElementLinkManager from './ElementLinkManager';
import ElementLinksInfo, { LinkedIcon } from './ElementLinksInfo';
import { TARGET_TYPE_PAGE } from '../constants';
import { NewBadge } from './Badges';
import fromReduxState from '../utils/redux';

const DropdownItem = React.forwardRef((props, ref) => {
  const { t } = useTranslation();
  const {
    buttonClassName, labelClassName, iconClassName, icon, iconComponent, style, label, onClick,
    iconContainerProps,
  } = props;
  const renderIcon = () => iconComponent || (
    <FontAwesomeIcon
      icon={icon}
      className={iconClassName}
      style={style}
      transform="grow-2"
    />
  );

  return (
    <button
      type="button"
      className={`dropdown-item${buttonClassName ? ` ${buttonClassName}` : ''}`}
      onClick={onClick}
      ref={ref}
    >
      <span {...iconContainerProps}>
        {renderIcon()}
      </span>
      <span className={labelClassName}>
        {t(label)}
      </span>
    </button>
  );
});

DropdownItem.propTypes = {
  buttonClassName: PropTypes.string,
  labelClassName: PropTypes.string,
  iconClassName: PropTypes.string,
  style: PropTypes.shape(),
  icon: PropTypes.arrayOf(PropTypes.string),
  iconComponent: PropTypes.shape(),
  label: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  iconContainerProps: PropTypes.shape(),
};

DropdownItem.defaultProps = {
  buttonClassName: undefined,
  labelClassName: undefined,
  iconClassName: undefined,
  style: undefined,
  icon: undefined,
  iconComponent: undefined,
  iconContainerProps: {},
};

const mapStateToProps = (state, ownProps) => ({
  page: state.projectPages[ownProps.currentPageId],
  modalOpened: state.dragAndDrop.modalsOpened > 0,
  sortedItems: state.sortingHandler,
  elementLinks: state.elementLinks,
  projectPages: state.projectPages,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  fetchPage: async () => dispatch(projectPagesActions.read(ownProps.currentPageId, {
    admin: ownProps.admin,
  })),
});


@connect(mapStateToProps, mapDispatchToProps)
@withTranslation('', nsOptions)
class FormTitle extends Component {
  static propTypes = {
    i18n: PropTypes.shape().isRequired,
    addTab: PropTypes.func.isRequired,
    editLinks: PropTypes.func.isRequired,
    renameTab: PropTypes.func.isRequired,
    removeTab: PropTypes.func.isRequired,
    cloneTab: PropTypes.func.isRequired,
    editionMode: PropTypes.bool,
    showEditionBtn: PropTypes.bool,
    currentPageId: PropTypes.number.isRequired,
    page: PropTypes.shape().isRequired,
    pages: PropTypes.arrayOf(PropTypes.shape()).isRequired,
    match: PropTypes.shape({
      url: PropTypes.string.isRequired,
    }).isRequired,
    modalOpened: PropTypes.bool.isRequired,
    sortedItems: PropTypes.shape().isRequired,
    t: PropTypes.func.isRequired,
    onEditionToggle: PropTypes.func,
    project: PropTypes.shape().isRequired,
    admin: PropTypes.bool,
    elementLinks: PropTypes.shape().isRequired,
    projectPages: PropTypes.shape().isRequired,
  };

  static defaultProps = {
    editionMode: false,
    showEditionBtn: false,
    onEditionToggle: () => {},
    admin: false,
  };

  constructor(props) {
    super(props);
    this.state = {
      currentPageTitle: props.page.title,
    };
    this.projectPagesHandlerName = getSortingHandlerId('project-pages', props.page.project);
    this.toElLinksArray = fromReduxState((elementLinks, currentPageId) => (
      Object.values(elementLinks).filter((link) => (
        link.page_target && link.page_target === currentPageId
      ))
    ));
  }

  onTitleChange = (event) => {
    const { renameTab, currentPageId } = this.props;
    this.setState({ currentPageTitle: event.target.value });
    renameTab(event.target.value, currentPageId);
  };

  render() {
    const {
      t, match, editionMode, addTab, editLinks, removeTab, cloneTab, page, pages, currentPageId,
      modalOpened, sortedItems, showEditionBtn, onEditionToggle, project, admin,
      elementLinks: elLinks, i18n, projectPages,
    } = this.props;
    const { currentPageTitle } = this.state;
    const elementLinks = this.toElLinksArray(elLinks, page.id);
    const disableDrag = modalOpened || !editionMode;
    return (
      <h3 className={`text-center${editionMode ? ' page-editable' : ''}`}>
        {
          editionMode ? (
            <NewTooltip
              content={currentPageTitle}
            >
              <ElementContentEditable
                className="tab-name-edit d-inline"
                value={currentPageTitle}
                onChange={this.onTitleChange}
                placeholder={t('project:form.page-name-placeholder')}
              />
            </NewTooltip>
          ) : (
            <span>
              {formatPageTitle(page, t)}
            </span>
          )
        }
        {(pages.length > 1 || editionMode) && (
          <DropdownMenu
            type={isMobileView() ? 'dropleft' : 'dropdown'}
            mainClass="d-inline-block form-page-menu"
            triggerElement={(
              <NewTooltip
                content={t('common:form.show-pages')}
              >
                <span>
                  <FontAwesomeIcon
                    icon={['fal', 'angle-down']}
                    className="ml-3 mr-1 text-gray-dark cursor-pointer"
                  />
                </span>
              </NewTooltip>
            )}
          >
            {pages.length > 1 && (
              <DroppableComponent
                droppableId={this.projectPagesHandlerName}
                type="PAGES"
                isDropDisabled={disableDrag}
              >
                {
                  sortedItems[this.projectPagesHandlerName].map((itemId, index) => {
                    const pageId = extractSortingItemDigitalId(itemId);
                    const tab = pages.find((pg) => pg.key === pageId);
                    if (!tab) return null;
                    const sortingItemId = getSortingItemId(this.projectPagesHandlerName, tab.key);
                    const projectPage = projectPages[pageId];
                    return (
                      <DraggableComponent
                        key={sortingItemId}
                        draggableId={sortingItemId}
                        droppableId={this.projectPagesHandlerName}
                        index={index}
                        isDragDisabled={disableDrag}
                      >
                        <div id={sortingItemId} className={`custom-control dropdown-item d-flex justify-content-between pr-2${tab.key === currentPageId ? ' current-page' : ''}`}>
                          <div className="d-flex justify-content-start">
                            <ElementLinksInfo
                              target={projectPage}
                              targetType={TARGET_TYPE_PAGE}
                              className="pages-dropdown-linked-icon mr-2"
                            />
                            <NewTooltip
                              className="d-flex align-items-center"
                              content={tab.value}
                            >
                              <Link
                                key={tab.key}
                                to={`${match.url}/pages/${tab.key}`}
                                className="project-page-link"
                              >
                                {tab.value}
                              </Link>
                            </NewTooltip>
                          </div>
                          {!disableDrag && (
                            <NewTooltip
                              className="d-flex align-items-center"
                              content={t('project:form.change-position')}
                            >
                              <span
                                className="manage-visibility project-page-dnd value-drag-n-drop ml-2"
                              >
                                <FontAwesomeIcon
                                  icon={['fas', 'grip-vertical']}
                                  className="text-gray"
                                  transform="shrink-2"
                                />
                              </span>
                            </NewTooltip>
                          )}
                        </div>
                      </DraggableComponent>
                    );
                  })
                }
              </DroppableComponent>
            )}
            {editionMode && pages.length > 1 && (
              <div className="dropdown-divider" />
            )}
            {editionMode && (
              <>
                <DropdownItem
                  style={{ marginRight: '1rem' }}
                  icon={['fal', 'plus']}
                  label="project:form.add-a-page"
                  onClick={addTab}
                />
                <ElementLinkManager
                  title={formatPageTitle(page, t)}
                  project={project}
                  target={page}
                  targetType={TARGET_TYPE_PAGE}
                  admin={admin}
                >
                  <DropdownItem
                    iconComponent={(
                      <>
                        <LinkedIcon
                          className={elementLinks.length > 0 ? 'text-newyellow-1-dark' : ''}
                          iconStyle={elementLinks.length > 0 ? 'fas' : 'far'}
                        />
                        <NewBadge className={`page-link-badge-${i18n.language}`}>
                          <span />
                        </NewBadge>
                      </>
                    )}
                    iconContainerProps={{
                      style: { position: 'relative', left: '-3px' },
                    }}
                    label={`project:form.${elementLinks.length > 0 ? 'edit' : 'add'}-logic-node`}
                    labelClassName="ml-2"
                    onClick={editLinks}
                  />
                </ElementLinkManager>
                <DropdownItem
                  style={{ marginRight: '0.75rem' }}
                  icon={['fal', 'clone']}
                  label="project:form.duplicate-this-page"
                  onClick={() => cloneTab(currentPageId)}
                />
                {pages.length > 1 && (
                  <ButtonConfirm
                    onClick={() => removeTab(currentPageId)}
                  >
                    <DropdownItem
                      buttonClassName="text-red"
                      style={{ marginRight: '0.85rem' }}
                      icon={['fal', 'trash-alt']}
                      label="project:form.delete-this-page"
                      onClick={() => cloneTab(currentPageId)}
                    />
                  </ButtonConfirm>
                )}
              </>
            )}
          </DropdownMenu>
        )}
        <ElementLinksInfo
          className="ml-3"
          target={page}
          targetType={TARGET_TYPE_PAGE}
          tabId={page.id}
        />
        {showEditionBtn && (
          <NewTooltip content={t(`common:edition.${editionMode ? 'end' : 'start'}`)}>
            <Button
              className={`mb-2 ml-3 col-auto d-inline ${editionMode ? 'btn-newred-1 text-white' : 'btn-newyellow-1'}`}
              onClick={onEditionToggle}
            >
              <FontAwesomeIcon
                className="mr-1"
                icon={['far', 'edit']}
                transform="shrink-1"
              />
              {t(`project:form.edit.${editionMode ? 'end' : 'start'}`)}
            </Button>
          </NewTooltip>
        )}
      </h3>
    );
  }
}


export default FormTitle;
