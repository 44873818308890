import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import { withToastManager } from 'react-toast-notifications';
import { usersActions, teamsActions } from '../redux/actions';
import BigMenu from '../components/BigMenu';
import BigMenuDivider from '../components/BigMenuDivider';
import BigMenuItem from '../components/BigMenuItem';
import { isMobileView } from '../components/MobileView';
import Head from '../components/DashboardHead';
import AccountForm from '../components/AccountForm';
import Page, { checkAuth } from '../components/Page';
import TeamForms from '../components/TeamForms';
import LicenseManager from '../components/LicenseManager';
import PractitionerTeamForms from '../components/PractitionerTeamForms';
import { CardLoader } from '../components/Loader';
import Help from '../components/Help';
import UploadsManager from '../components/UploadsManager';
import BrowserView from '../components/BrowserView';
import { nsOptions } from '../i18n';
import { USER_TYPE_PRACTITIONER } from '../constants';
import Toast from '../utils/Toast';
import ErrorUtil from '../utils/ErrorUtil';
import TimeoutHandler from '../utils/TimeoutHandler';
import { setLanguage } from '../utils/language';
import { deObfuscateEmail } from '../utils/email';
import FAQLink from '../components/FAQLink';

const mapStateToProps = (state, ownProps) => ({
  userId: Number(ownProps.match.params.id) || state.auth.authUser.id,
  user: ownProps.match.params.id ? state.users[Number(ownProps.match.params.id)]
    : state.auth.authUser,
  teams: state.teams,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  patchUser: async (id, data) => dispatch(usersActions.patch(id, data, { admin: ownProps.admin })),
  fetchUser: async (id) => dispatch(usersActions.read(id, { admin: ownProps.admin })),
  fetchTeams: async (params) => dispatch(teamsActions.list(params, { pagination: 'no' })),
});


@withToastManager
@connect(mapStateToProps, mapDispatchToProps)
@withTranslation('', nsOptions)
class Settings extends Component {
  static propTypes = {
    admin: PropTypes.bool,
    match: PropTypes.shape({
      url: PropTypes.string.isRequired,
      params: PropTypes.shape().isRequired,
    }).isRequired,
    t: PropTypes.func.isRequired,
    i18n: PropTypes.shape().isRequired,
    userId: PropTypes.number.isRequired,
    user: PropTypes.shape(),
    patchUser: PropTypes.func.isRequired,
    fetchUser: PropTypes.func.isRequired,
    fetchTeams: PropTypes.func.isRequired,
    teams: PropTypes.shape(),
    location: PropTypes.shape({
      pathname: PropTypes.string.isRequired,
    }).isRequired,
  };

  static defaultProps = {
    admin: false,
    teams: {},
    user: undefined,
  };

  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      allTeams: [],
    };
    this.timeoutHandler = new TimeoutHandler();
  }

  componentDidMount() {
    const {
      user, userId, fetchUser, admin,
    } = this.props;
    if (!user) {
      fetchUser(userId);
    }
    if (!admin) {
      this.fetchTeamsData();
    }
  }

  componentDidUpdate(prevProps) {
    const { location } = this.props;
    const prevLocation = prevProps.location;
    if (prevLocation.pathname !== location.pathname
      && location.pathname.includes('/teams')
      && !prevLocation.pathname.includes('/teams')) {
      this.fetchTeamsData();
    }
  }

  updateUser = (patch) => {
    this.timeoutHandler.doAfterTimeout(async () => {
      const localUserPatch = patch;

      Object.keys(localUserPatch).forEach((key) => {
        if (localUserPatch[key] === undefined) {
          delete localUserPatch[key];
        }
      });

      const distantUserPatch = {
        ...localUserPatch,
      };

      const {
        user, patchUser, i18n, admin,
      } = this.props;

      try {
        await patchUser(user.id, distantUserPatch);
        Toast.success(this.props, 'error:valid.saved');
      } catch (error) {
        ErrorUtil.handleCatched(this.props, error);
      }

      const { language } = patch;
      if (language && language !== this.props.i18n.language && !admin) {
        setLanguage(i18n, language);
      }
    });
  };

  handleAccountFormValidChange = async (updatedData) => {
    const patch = {
      type: updatedData.typeInputValue,
      civility: updatedData.civilityInputValue,
      subtype: updatedData.subtypeInputValue,
      last_name: updatedData.lastNameInputValue,
      first_name: updatedData.firstNameInputValue,
      email: updatedData.emailInputValue,
      email_validated: updatedData.emailValidatedInputValue,
      specialty: updatedData.specialtyInputValue,
      institution: updatedData.institutionInputValue,
      city: updatedData.cityInputValue,
      country: updatedData.countryInputValue,
      professional_number: updatedData.proNumberInputValue,
      language: updatedData.languageInputValue,
      username: updatedData.acronymInputValue,
      address: updatedData.addressInputValue,
      zip_code: updatedData.zipCodeInputValue,
      phone_number: updatedData.phoneInputValue,
      website: updatedData.websiteInputValue,
      email_alt: updatedData.emailAltInputValue,
      phone_number_alt: updatedData.phoneAltInputValue,
    };

    await this.updateUser(patch);
  };

  renderAccountRoute = () => {
    const { t } = this.props;
    return (
      <div>
        <h4 className="font-weight-normal mb-3">
          {t('user:nav.account')}
        </h4>
        <AccountForm
          {...this.props}
          editionMode
          onFormValidChange={this.handleAccountFormValidChange}
        />
      </div>
    );
  };

  fetchTeamsData = async () => {
    const { userId, fetchTeams } = this.props;
    this.setState({ loading: true });
    try {
      const res = await fetchTeams({ members__user: userId });
      this.setState({ allTeams: res });
    } catch (error) {
      ErrorUtil.handleCatched(this.props, error, false);
    } finally {
      this.setState({ loading: false });
    }
  }

  renderTeamsRoute = (isPractitioner, ownTeams, otherTeams) => {
    const { t, teams: tms, admin } = this.props;
    const { loading } = this.state;

    const email = deObfuscateEmail('support', 'doqboard.com');
    const teams = Object.values(tms);
    const showSubtitle = teams.length > 0;

    return (
      <div>
        <div className="d-flex align-items-center mb-3">
          <h4 className="font-weight-normal teams mb-0">
            {t('user:nav.teams', { context: 'plural_unknown' })}
          </h4>
          <Help
            iconClassName="ml-2"
            interactive
            transform="down-2"
          >
            <FAQLink
              text={t('common:discover')}
            />
          </Help>
        </div>
        {showSubtitle && (
          <div
            className="py-3 teams-tab-subtitle"
            style={isMobileView() ? { padding: '1.5em' } : {}}
          >
            {t(`common:nav-subtitles.teams.${isPractitioner ? '' : 'non-'}practitioner`)}
          </div>
        )}
        {loading && <CardLoader />}
        {isPractitioner ? (
          <PractitionerTeamForms
            {...this.props}
            ownTeams={ownTeams}
            otherTeams={otherTeams}
          />
        ) : (
          <>
            {
              teams.length ? (
                <TeamForms
                  key="non-practitioner-team-forms"
                  admin={admin}
                  // {...this.props}
                />
              ) : (
                <div className="text-center">
                  <div>
                    <h6 className="non-practitioner-no-team">
                      {t('user:team.no-joined-team.non-practitioner')}
                    </h6>
                  </div>
                </div>
              )
            }
          </>
        )}
        <div className="text-center mt-3 text-gray-dark">
          {t('user:team.help.part-1')}
          <br />
          {t('user:team.help.part-2')}
          &nbsp;
          <FAQLink
            linkClassName="classical-link text-gray-dark"
            articleId={6}
            text={t('common:help-center')}
          />
          &nbsp;
          {t('user:team.help.part-3')}
          &nbsp;
          <span className="font-weight-semibold">
            {email}
          </span>
          .
        </div>
      </div>
    );
  };

  renderLicenseRoute = (isPractitioner) => {
    const { t } = this.props;
    const { allTeams } = this.state;

    return (
      <div>
        <div className="d-flex align-items-center mb-3">
          <h4 className="font-weight-normal licenses mb-0">
            {t(`user:nav.${isPractitioner ? 'licenses' : 'license'}`)}
          </h4>
          <Help
            iconClassName="ml-2"
            interactive
            transform="down-2"
          >
            <FAQLink
              articleId={1}
              text={t('user:license.help-tooltip')}
            />
          </Help>
        </div>
        <LicenseManager
          {...this.props}
          // Allows to know (in LicenseManager) if an intern / assistant joined at least one team
          nonPractitionerTeams={allTeams}
        />
      </div>
    );
  };

  renderUploadsRoute = () => {
    const { t } = this.props;
    return (
      <div>
        <h4 className="font-weight-normal mb-3">
          {t('user:nav.spreadsheets-import')}
        </h4>
        <UploadsManager {...this.props} />
      </div>
    );
  };

  render() {
    const {
      t, user, teams: tms, admin,
    } = this.props;
    const { url } = this.props.match;
    const teams = Object.values(tms);
    const showTeamsLink = !admin && user && user.verified
      && !user.limitations.can_own_licence_products
      && (user.limitations.can_create_team || user.limitations.can_join_team);
    const showLicenseLink = !admin && user && !user.limitations.can_own_licence_products;
    const showUploadsLink = admin || (user && user.verified);

    let ownTeams;
    let otherTeams;
    const isPractitioner = user && user.type === USER_TYPE_PRACTITIONER;
    if (isPractitioner) {
      ownTeams = teams.filter((team) => team.owner.id === user.id);
      otherTeams = teams.filter((team) => team.owner.id !== user.id);
    }
    const title = admin && user ? user.label : t('common:nav.my-account');

    return (
      <Page
        {...this.props}
        title={title}
        checkAuthorizations={[checkAuth]}
      >
        <Head
          {...this.props}
          title={title}
        />
        {
          user ? (
            <div className="dashboard-content">
              <div className="mb-3">
                <BigMenu divider="none" {...this.props}>
                  <BigMenuItem to={`${url}/account`} {...this.props}>
                    {t('user:nav.account')}
                  </BigMenuItem>
                  {
                    showTeamsLink && (
                      <>
                        <BigMenuDivider />
                        <BigMenuItem to={`${url}/teams`} {...this.props}>
                          {t('user:nav.teams', { context: 'plural_unknown' })}
                        </BigMenuItem>
                      </>
                    )
                  }
                  {showUploadsLink && (
                    <BrowserView>
                      <BigMenuDivider />
                      <BigMenuItem to={`${url}/uploads`} {...this.props}>
                        {t('user:nav.spreadsheets-import')}
                      </BigMenuItem>
                    </BrowserView>
                  )}
                  {
                    showLicenseLink && (
                      <>
                        <BigMenuDivider />
                        <BigMenuItem to={`${url}/license`} {...this.props}>
                          {t(`user:nav.${isPractitioner ? 'licenses' : 'license'}`)}
                        </BigMenuItem>
                      </>
                    )
                  }
                </BigMenu>
              </div>
              <div className={`card bg-white card-shadow p-4 mt-1 ${isMobileView ? 'mobile-card' : ''}`}>
                <Switch>
                  <Route
                    {...this.props}
                    path={`${url}/account`}
                  >
                    { this.renderAccountRoute() }
                  </Route>
                  {
                    showTeamsLink && !isPractitioner && (
                      <Route
                        {...this.props}
                        path={`${url}/teams/:id`}
                      >
                        { this.renderTeamsRoute(isPractitioner, ownTeams, otherTeams) }
                      </Route>
                    )
                  }
                  {
                    showTeamsLink && (
                      <Route
                        {...this.props}
                        path={`${url}/teams`}
                      >
                        { this.renderTeamsRoute(isPractitioner, ownTeams, otherTeams) }
                      </Route>
                    )
                  }
                  {
                    showLicenseLink && (
                      <Route
                        {...this.props}
                        path={`${url}/license`}
                      >
                        { this.renderLicenseRoute(isPractitioner) }
                      </Route>
                    )
                  }
                  <BrowserView>
                    <Route
                      {...this.props}
                      path={`${url}/uploads`}
                    >
                      { this.renderUploadsRoute() }
                    </Route>
                  </BrowserView>
                </Switch>
              </div>
            </div>
          ) : <CardLoader />
        }
      </Page>
    );
  }
}

export default Settings;
