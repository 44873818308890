import { useToasts } from 'react-toast-notifications';
import { useTranslation } from 'react-i18next';
import {
  baseShow, baseSuccess, baseInfo, baseWarning, baseError,
} from './Toast';

/**
 * Call { function } = useToast(); in your React component to use this custom hook
*/

export default function useToast() {
  const { addToast } = useToasts();
  const { t } = useTranslation();

  /**
   * Show Toast with options
   * @param {Object} props (dummy)
   * @param {String} text
   * @param {Object} options
   * @param {any} overrideTimeout
   * @param {Boolean} noT
   */
  function show(text, options, overrideTimeout = null, noT = false) {
    baseShow(addToast, t, text, options, overrideTimeout, noT);
  }

  /**
   * Show Toast success
   * @param {String} text
   * @param {any} overrideTimeout
   * @param {Boolean} noT
   */
  function success(text, overrideTimeout, noT) {
    baseSuccess(addToast, t, text, overrideTimeout, noT);
  }

  /**
   * Show Toast info
   * @param {String} text
   * @param {any} overrideTimeout
   * @param {Boolean} noT
   */
  function info(text, overrideTimeout, noT) {
    baseInfo(addToast, t, text, overrideTimeout, noT);
  }

  /**
   * Show Toast warning
   * @param {String} text
   * @param {any} overrideTimeout
   * @param {Boolean} noT
   */
  function warning(text, overrideTimeout, noT) {
    baseWarning(addToast, t, text, overrideTimeout, noT);
  }

  /**
   * Show Toast error
   * @param {String} text
   * @param {any} overrideTimeout
   * @param {Boolean} noT
   */
  function error(text, overrideTimeout, noT) {
    baseError(addToast, t, text, overrideTimeout, noT);
  }

  return {
    show,
    success,
    info,
    warning,
    error,
  };
}
