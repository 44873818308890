import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

// eslint-disable-next-line react/prefer-stateless-function
class Pagination extends Component {
  get pageCount() {
    const { count, pageSize } = this.props;
    return Math.ceil(count / pageSize);
  }

  get currentPage() {
    return Math.max(Math.min(this.props.page, this.pageCount), 1);
  }

  render() {
    const { pageCount, currentPage } = this;
    if (pageCount === 0) {
      return null;
    }
    const { action, color } = this.props;

    return (
      <ul className="pagination justify-content-center my-3">
        {
          currentPage > 1 ? [
            (
              <li
                key="0"
                className={`page-item${currentPage === 1 ? ' disabled' : ''}`}
              >
                {
                  currentPage !== 1 ? (
                    <button
                      type="button"
                      className="page-link"
                      onClick={() => action(currentPage - 1)}
                    >
                      <FontAwesomeIcon icon={['fal', 'chevron-left']} transform="grow-3" />
                    </button>
                  ) : (
                    <button type="button" className="page-link">
                      <FontAwesomeIcon icon={['fal', 'chevron-left']} transform="grow-3" />
                    </button>
                  )
                }
              </li>
            ), (
              <li key="1" className="page-item">
                <button type="button" className="page-link" onClick={() => action(1)}>
                  1
                </button>
              </li>
            ),
          ] : null
        }

        {
          currentPage > 3 ? (
            <li key="2" className="page-item disabled">
              <span className="page-link">...</span>
            </li>
          ) : null
        }

        {
          currentPage > 2 ? (
            <li key="3" className="page-item">
              <button
                type="button"
                className="page-link"
                onClick={() => action(currentPage - 1)}
              >
                {currentPage - 1}
              </button>
            </li>
          ) : null
        }

        <li key="4" className="page-item active">
          <span className={`page-link bg-${color} border-${color}`}>
            {currentPage}
          </span>
        </li>

        {
          (pageCount - currentPage) > 1 ? (
            <li key="5" className="page-item">
              <button
                type="button"
                className="page-link"
                onClick={() => action(currentPage + 1)}
              >
                {currentPage + 1}
              </button>
            </li>
          ) : null
        }

        {
          (pageCount - currentPage) > 2 ? (
            <li key="6" className="page-item disabled">
              <span className="page-link">...</span>
            </li>
          ) : null
        }

        {
          (pageCount - currentPage) > 0 ? [
            (
              <li key="7" className="page-item">
                <button
                  type="button"
                  className="page-link"
                  onClick={() => action(pageCount)}
                >
                  {pageCount}
                </button>
              </li>
            ), (
              <li
                key="8"
                className={`page-item${currentPage === pageCount
                  ? ' disabled'
                  : ''}`}
              >
                {
                  currentPage !== pageCount ? (

                    <button
                      type="button"
                      className="page-link"
                      onClick={() => action(currentPage + 1)}
                    >
                      <FontAwesomeIcon icon={['fal', 'chevron-right']} transform="grow-3" />
                    </button>
                  ) : (
                    <button type="button" className="page-link">
                      <FontAwesomeIcon icon={['fal', 'chevron-right']} transform="grow-3" />
                    </button>
                  )
                }
              </li>
            ),
          ] : null
        }
      </ul>
    );
  }
}

Pagination.propTypes = {
  page: PropTypes.number.isRequired,
  count: PropTypes.number.isRequired,
  pageSize: PropTypes.number.isRequired,
  action: PropTypes.func.isRequired,
  color: PropTypes.string,
};

Pagination.defaultProps = {
  color: 'newblue-2',
};

export default Pagination;
