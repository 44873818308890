import { createReducer } from 'redux-starter-kit';
import { projectPagesActions } from '../actions';
import getStdDbModelReduxMapping from './std-db-model-reducers';

export const projectPagesInitialState = {};

const mapping = getStdDbModelReduxMapping(projectPagesActions);
mapping[projectPagesActions.moveSuccess] = mapping[projectPagesActions.resyncSuccess];

const projectPagesReducer = createReducer(projectPagesInitialState, mapping);

export default projectPagesReducer;
