import { createReducer } from 'redux-starter-kit';
import { dragAndDropActions } from '../actions';

const {
  start, end, modalOpened, modalClosed,
} = dragAndDropActions;

export const dragAndDropInitialState = {
  dragInProgress: false,
  draggableId: null,
  droppableId: null,
  modalsOpened: 0,
};

const dragAndDropReducer = createReducer(dragAndDropInitialState, {
  [start]: (state, action) => ({
    ...state,
    dragInProgress: true,
    draggableId: action.payload.draggableId,
    droppableId: action.payload.droppableId,
  }),
  [end]: (state) => ({
    ...state,
    dragInProgress: false,
    draggableId: null,
    droppableId: null,
  }),
  [modalOpened]: (state) => ({
    ...state,
    modalsOpened: state.modalsOpened + 1,
  }),
  [modalClosed]: (state) => ({
    ...state,
    modalsOpened: state.modalsOpened <= 0 ? 0 : state.modalsOpened - 1,
  }),
});

export default dragAndDropReducer;
