import buildStdDbModelActions from './std-db-model-actions';
import { elementsActions, elementLinksActions } from './index';
import api from '../../api';

const endpoint = 'element-modalities';
const stdDbModelActions = buildStdDbModelActions(endpoint);

const resynchElement = async (dispatch, id, params) => dispatch(elementsActions.read(id, params));
const resynchElementLinks = async (dispatch) => dispatch(elementLinksActions.resync(
  {},
  { removeAllBefore: true },
));

const create = (data, params = {}) => async (dispatch) => {
  const res = await api.create(endpoint, data, params);
  await resynchElement(dispatch, res.element, params);
  dispatch(stdDbModelActions.createSuccess(res));
  return res;
};

const patch = (id, data = {}, params = {}) => async (dispatch) => {
  const res = await api.partial_update(endpoint, id, data, params);
  await resynchElement(dispatch, res.element, params);
  dispatch(stdDbModelActions.patchSuccess(res));
  return res;
};

const remove = (id, data = {}, params = {}) => async (dispatch, getState) => {
  const modality = getState().elementModalities[id];
  await api.delete(endpoint, id, data, params);
  await Promise.all([
    resynchElement(dispatch, modality.element, params),
    resynchElementLinks(dispatch),
  ]);
  dispatch(stdDbModelActions.removeSuccess(id));
};

export default {
  ...stdDbModelActions,
  create,
  patch,
  remove,
};
