import moment from 'moment';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Trans, withTranslation } from 'react-i18next';
import { withToastManager } from 'react-toast-notifications';
import { connect } from 'react-redux';
import { projectEntriesActions } from '../redux/actions';
import { nsOptions } from '../i18n';
import {
  DATE_TIME, DATE_FULL, DATE_MONTH_YEAR, DATE_YEAR, ENTRY_TYPE_DATE, ELEMENT_NOT_IDENTIFIER,
} from '../constants';
import DatePicker from './DatePicker';
import ElementBase, { ENTRY_LATENCY_TIMEOUT_MS, retrieveProjectEntry, getMutex } from './ElementBase';
import Toast from '../utils/Toast';
import ErrorUtil from '../utils/ErrorUtil';
import { API_DATE_TIME_FORMAT, toMoment } from '../utils/date';
import TimeoutHandler from '../utils/TimeoutHandler';

export const getDateInputPlaceholder = (t, format) => {
  switch (format) {
    case DATE_TIME:
      return t('error:placeholder.date-time');
    case DATE_MONTH_YEAR:
      return t('error:placeholder.date-month-year');
    case DATE_YEAR:
      return t('error:placeholder.date-year');
    case DATE_FULL:
    default:
      return t('error:placeholder.date-full');
  }
};

const mapStateToProps = (state, ownProps) => ({
  element: state.elements[ownProps.elementId],
  inclusion: state.inclusions[ownProps.inclusionId],
  projectElement: state.projectElements[ownProps.projectElementId],
  projectEntry: retrieveProjectEntry(
    Object.values(state.projectEntries),
    ownProps.inclusionId,
    ownProps.projectElementId,
    ownProps.moduleInstanceId,
  ),
});

const mapDispatchToProps = (dispatch) => ({
  addEntry: async (data) => dispatch(projectEntriesActions.create(
    { type: ENTRY_TYPE_DATE, ...data },
  )),
  removeEntry: async (id) => dispatch(projectEntriesActions.remove(id)),
  patchEntry: async (id, data) => dispatch(projectEntriesActions.patch(id, data)),
});


@withToastManager
@connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })
@withTranslation('', nsOptions)
class ElementDate extends Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
    elementId: PropTypes.number.isRequired,
    element: PropTypes.shape().isRequired,
    projectElementId: PropTypes.number,
    moduleInstanceId: PropTypes.number,
    inclusion: PropTypes.shape(),
    inclusionId: PropTypes.number,
    parent: PropTypes.shape({
      content: PropTypes.shape(),
    }),
    methods: PropTypes.shape({
      move: PropTypes.func,
      reload: PropTypes.func.isRequired,
      remove: PropTypes.func,
      checkLinks: PropTypes.func,
    }).isRequired,
    isEditMode: PropTypes.bool,
    isEntryMode: PropTypes.bool,
    admin: PropTypes.bool,
    isModule: PropTypes.bool,
    isReadOnly: PropTypes.bool,
    isAnonymized: PropTypes.bool,
    projectEntry: PropTypes.shape(),
    projectElement: PropTypes.shape().isRequired,
    patchEntry: PropTypes.func.isRequired,
    addEntry: PropTypes.func.isRequired,
    removeEntry: PropTypes.func.isRequired,
  };

  static defaultProps = {
    projectElementId: null,
    moduleInstanceId: null,
    inclusion: null,
    inclusionId: null,
    parent: null,
    isEditMode: false,
    isEntryMode: false,
    isAnonymized: false,
    admin: false,
    isModule: false,
    isReadOnly: false,
    projectEntry: null,
  };

  constructor(props) {
    super(props);
    this.timeoutHandler = new TimeoutHandler();
  }

  checkLinks = () => {
    if (this.elBaseRef) this.elBaseRef.checkLinks();
  };

  showLoader = (show = true) => {
    if (this.elBaseRef) this.elBaseRef.showLoader(show);
  };

  updateEntry = (value) => {
    this.timeoutHandler.doAfterTimeout(async () => {
      const mutex = getMutex(this.props);
      const release = await mutex.acquire();
      const date = value === null ? value : moment(value);
      const {
        projectEntry, inclusion, projectElement, moduleInstanceId, patchEntry,
        removeEntry, addEntry, methods, t,
      } = this.props;
      try {
        this.showLoader();
        if (projectEntry) {
          if (date) {
            if (date.isValid()) {
              await patchEntry(projectEntry.id, {
                value: date.format(API_DATE_TIME_FORMAT),
              });
            } else {
              throw new Error(t('error:error.invalid-date'));
            }
          } else {
            await removeEntry(projectEntry.id);
          }
        } else if (date && date.isValid()) {
          await addEntry({
            type: ENTRY_TYPE_DATE,
            inclusion: inclusion.id,
            project_element: projectElement.id,
            module: moduleInstanceId,
            value: date.format(API_DATE_TIME_FORMAT),
          });
        } else {
          throw new Error(t('error:error.invalid-date'));
        }
        Toast.success(this.props, 'error:valid.saved');
        if (methods.checkLinks) methods.checkLinks();
        if (this.elBaseRef) this.elBaseRef.checkForCorruptedMissingDataCount();
      } catch (error) {
        ErrorUtil.handleCatched(this.props, error);
      } finally {
        release();
        this.showLoader(false);
      }
    }, 0, ENTRY_LATENCY_TIMEOUT_MS);
  };

  render() {
    const {
      t, isEntryMode, isEditMode, isReadOnly, projectEntry, element, isAnonymized,
    } = this.props;
    const { format, identification_level: identificationLevel } = element;
    const options = {};

    if (isEditMode || isReadOnly) {
      options.disabled = true;
    }

    if (isEntryMode) {
      options.onChange = this.updateEntry;
      if (projectEntry && !isAnonymized) {
        options.defaultValue = null;
        if (projectEntry.value) {
          options.defaultValue = toMoment(projectEntry.value, format);
        }
      }
    }

    const inconsistentData = isEntryMode && projectEntry && !projectEntry.consistent ? 'inconsistent' : '';
    const missingData = isEntryMode && !projectEntry ? 'empty' : '';
    const qualifiedMissingData = isEntryMode && projectEntry && projectEntry.missing ? 'qualified-missing' : '';
    let warningMsg;
    let warningIconColor;

    if (!isEntryMode && identificationLevel !== ELEMENT_NOT_IDENTIFIER
        && [DATE_FULL, DATE_TIME].includes(format)) {
      warningMsg = <Trans i18nKey="error:warning.identifying-date" />;
      warningIconColor = 'text-red';
    }

    return (
      <ElementBase
        {...this.props}
        elementCategory="entry"
        className={format === DATE_TIME ? 'element-date-time' : 'element-date'}
        ref={(ref) => { this.elBaseRef = ref; }}
        warningMsg={warningMsg}
        warningIconColor={warningIconColor}
      >
        <div className={`col-auto ${inconsistentData}${missingData}${qualifiedMissingData}`}>
          <DatePicker
            placeholder={getDateInputPlaceholder(t, format)}
            format={format}
            {...options}
          />
        </div>
      </ElementBase>
    );
  }
}

export default ElementDate;
