import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { Router, Route, Switch } from 'react-router-dom';
import './App.scss';
import './assets/css/_all.css';
import 'moment/locale/fr';
import { withToastManager } from 'react-toast-notifications';
import { DragDropContext } from 'react-beautiful-dnd';
import { connect } from 'react-redux';
import IdleTimer from 'react-idle-timer';
import CssBaseline from '@material-ui/core/CssBaseline';
import createMuiTheme from '@material-ui/core/styles/createMuiTheme';
import ThemeProvider from '@material-ui/styles/ThemeProvider';
import { lighten } from '@material-ui/core/styles';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/moment';
import {
  sortingHandlerActions, projectElementsActions, elementModalitiesActions, projectPagesActions,
  dragAndDropActions, authActions, miscActions,
} from './redux/actions';
import {
  extractSortingHandlerType, extractSortingHandlerDigitalId, extractSortingItemDigitalId,
} from './redux/actions/sorting-handler';
import { nsOptions } from './i18n';
import AdminLayout from './pages/AdminLayout';
import AuthenticationLayout from './pages/AuthLayout';
import DashboardLayout from './pages/DashboardLayout';
import NotFound from './pages/NotFound';
import Home from './pages/Home';
import Toast from './utils/Toast';
import ErrorUtil from './utils/ErrorUtil';
import { isAuthenticated } from './utils/data-util';
import history from './history';

const defaultTheme = createMuiTheme({});

const theme = createMuiTheme({
  palette: {
    action: {
      disabledBackground: lighten(defaultTheme.palette.action.disabledBackground, 0.5),
    },
    primary: {
      light: '#0051FF',
      main: '#0033BE',
      dark: '#04112F',
      contrastText: '#FFFFFF',
    },
    secondary: {
      light: '#B3A5EF',
      main: '#0E28A6',
      contrastText: '#FFFFFF',
    },
    text: {
      primary: '#4d4f5c',
    },
    turquoise: {
      light: '#33DFB4',
      main: '#35BB9B',
    },
  },
  typography: {
    fontSize: 15.2,
  },
  overrides: {
    MuiInputBase: {
      input: {
        // Removes clashes with external CSS.
        padding: '6px 0 7px !important',
        borderBottom: 'unset !important',
        boxSizing: 'content-box !important',
      },
    },
    MuiTooltip: {
      tooltip: {
        fontSize: '0.85rem',
        fontWeight: 400,
        backgroundColor: '#2f1e1e',
      },
    },
    MuiRadio: {
      root: {
        paddingBottom: '0px',
        paddingTop: '0px',
      },
    },
    MuiFormControlLabel: {
      root: {
        marginBottom: '0.2rem',
      },
    },
  },
});


const mapStateToProps = (state) => ({
  user: state.auth.authUser,
});

const mapDispatchToProps = (dispatch) => ({
  moveProjectElement: async (pElementId, currentTabId, newPos, newTabId, admin) => dispatch(
    projectElementsActions.move(
      pElementId,
      currentTabId,
      newPos,
      newTabId,
      { admin },
    ),
  ),
  moveModality: async (modalityId, newPos, admin) => dispatch(
    elementModalitiesActions.patch(modalityId, { sorting: newPos }, { admin }),
  ),
  moveProjectPage: async (pageId, newPos, admin) => dispatch(
    projectPagesActions.move(
      pageId,
      newPos,
      { admin },
    ),
  ),
  moveItem: (sourceIndex, sourceHandlerId, destinationIndex, destinationHandlerId) => dispatch(
    sortingHandlerActions.moveItem({
      sourceIndex,
      sourceHandlerId,
      destinationIndex,
      destinationHandlerId,
    }),
  ),
  startDnd: (draggableId, droppableId) => dispatch(dragAndDropActions.start(
    {
      draggableId,
      droppableId,
    },
  )),
  endDnd: () => dispatch(dragAndDropActions.end()),
  logout: async () => dispatch(authActions.logout()),
  setPendingUrl: (url) => dispatch(miscActions.setPendingUrl(url)),
});


@withToastManager
@connect(mapStateToProps, mapDispatchToProps)
@withTranslation('', nsOptions)
class App extends Component {
  static propTypes = {
    user: PropTypes.shape(),
    moveProjectElement: PropTypes.func.isRequired,
    moveModality: PropTypes.func.isRequired,
    moveProjectPage: PropTypes.func.isRequired,
    moveItem: PropTypes.func.isRequired,
    startDnd: PropTypes.func.isRequired,
    endDnd: PropTypes.func.isRequired,
    logout: PropTypes.func.isRequired,
    setPendingUrl: PropTypes.func.isRequired,
  };

  static defaultProps = {
    user: undefined,
  };

  constructor(props) {
    super(props);
    this.savedStyleBeforeDnd = {};
  }

  onIdle = async () => {
    const {
      user, logout, setPendingUrl,
    } = this.props;

    if (isAuthenticated(user)) {
      try {
        const pendingUrl = document.location.pathname;
        await logout();
        setPendingUrl(pendingUrl);
        history.push('/');
      } catch (error) {
        console.error(error);
      }
    }
  };

  handleBeforeDragStart = (start) => {
    const { draggableId, source } = start;

    switch (extractSortingHandlerType(source.droppableId)) {
      case 'project-pages':
        this.savedStyleBeforeDnd = { ...document.getElementById(draggableId).style };
        document.getElementById(draggableId).style.background = 'transparent';
        break;

      default:
        break;
    }
  };

  handleDragStart = (start) => {
    this.props.startDnd(start.draggableId, start.source.droppableId);
  };

  handleDragEnd = (result) => {
    const {
      moveItem, moveProjectElement, moveModality, moveProjectPage, endDnd,
    } = this.props;
    const { source, destination, draggableId } = result;

    endDnd();

    if (!destination || source.index === destination.index) return;

    // Move the item in the sorting handler (front)
    moveItem(
      source.index,
      source.droppableId,
      destination.index,
      destination.droppableId,
    );

    // Restore style if needed
    const sourceSortingHandlerType = extractSortingHandlerType(source.droppableId);

    switch (sourceSortingHandlerType) {
      case 'project-pages':
        document.getElementById(draggableId).style = this.savedStyleBeforeDnd;
        break;

      default:
        break;
    }

    // Update the database (async calls to the api)
    const admin = /^\/admin\/.*$/.test(window.location.pathname);
    let move = async () => {};
    const sourceSortingHandlerDigitalId = extractSortingHandlerDigitalId(source.droppableId);
    const sortingItemDigitalId = extractSortingItemDigitalId(draggableId);
    const newPos = destination.index + 1; // Api sorting starts at 1

    switch (sourceSortingHandlerType) {
      case 'project-form':
      case 'module':
        move = async () => moveProjectElement(
          sortingItemDigitalId,
          sourceSortingHandlerDigitalId,
          newPos,
          null,
          admin,
        );
        break;

      case 'element':
        move = async () => moveModality(sortingItemDigitalId, newPos, admin);
        break;

      case 'project-pages':
        move = async () => moveProjectPage(sortingItemDigitalId, newPos, admin);
        break;

      default:
        console.error(`Unexpected sorting handler type: ${sourceSortingHandlerType}`);
        break;
    }

    move().then(() => {
      Toast.success(this.props, 'error:valid.saved');
    }).catch((error) => {
      ErrorUtil.handleCatched(this.props, error);
    });
  };

  render() {
    return (
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <IdleTimer
            onIdle={this.onIdle}
            timeout={900000} // ms -> 15 min
          />
          <DragDropContext
            key="app-1"
            onBeforeDragStart={this.handleBeforeDragStart}
            onDragStart={this.handleDragStart}
            onDragEnd={this.handleDragEnd}
          >
            <Router history={history}>
              <Switch>
                <Route exact path="/" component={Home} {...this.props} />
                <Route
                  path="/auth"
                  component={AuthenticationLayout}
                  {...this.props}
                />
                <Route
                  path="/dashboard"
                  component={DashboardLayout}
                  {...this.props}
                />
                <Route
                  path="/admin"
                  component={AdminLayout}
                  {...this.props}
                />
                <Route component={NotFound} {...this.props} />
              </Switch>
            </Router>
          </DragDropContext>
        </ThemeProvider>
      </MuiPickersUtilsProvider>
    );
  }
}

export default App;
