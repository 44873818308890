import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { withToastManager } from 'react-toast-notifications';
import { connect } from 'react-redux';
import { Button, FormGroup } from 'reactstrap';
import { nsOptions } from '../i18n';
import { usersActions } from '../redux/actions';
import { isFormValid } from '../utils/form-validation';
import Toast from '../utils/Toast';
import ErrorUtil from '../utils/ErrorUtil';
import PasswordInput from './PasswordInput';


const mapStateToProps = (state) => ({ user: state.auth.authUser });

const mapDispatchToProps = (dispatch) => ({
  patchUser: async (id, data) => dispatch(usersActions.patch(id, data)),
});


@withToastManager
@connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })
@withTranslation('', nsOptions)
class PasswordForm extends Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
    user: PropTypes.shape(),
    editionMode: PropTypes.bool,
    onFormChange: PropTypes.func,
    onFormValidityChange: PropTypes.func,
    patchUser: PropTypes.func.isRequired,
  };

  static defaultProps = {
    user: undefined,
    editionMode: false,
    onFormChange: () => {},
    onFormValidityChange: () => {},
  };

  static getInitialState() {
    return {
      editionInProgress: false,
      formValid: false,
      values: {
        passwordInputValue: '',
        oldPasswordInputValue: '',
      },
      valuesValidity: {
        passwordInputValid: false,
        oldPasswordInputValid: false,
      },
    };
  }

  constructor(props) {
    super(props);
    this.state = PasswordForm.getInitialState();
    this.inputRefs = {};
  }

  isThisFormValid = () => {
    const { ...fields } = this.state.valuesValidity;
    if (!this.props.editionMode) {
      delete fields.oldPasswordInputValid;
    }
    const valid = isFormValid(fields);
    this.setState({ formValid: valid });
    return valid;
  };

  handleFormChange = (field, value) => {
    this.setState(
      (prevState) => ({
        values: {
          ...prevState.values,
          [field]: value,
        },
      }),
      () => {
        if (field === 'passwordInputValue') {
          this.props.onFormChange(value);
        }
      },
    );
  };

  handleFormValidity = (field, valid) => {
    this.setState(
      (prevState) => ({
        valuesValidity: {
          ...prevState.valuesValidity,
          [field]: valid,
        },
      }),
      () => {
        const wasValid = this.state.formValid;
        const isValid = this.isThisFormValid();
        if (wasValid !== isValid) this.props.onFormValidityChange(isValid);
      },
    );
  };

  handleFormSubmit = async () => {
    try {
      const { user, patchUser } = this.props;
      await patchUser(user.id, {
        old_password: this.state.values.oldPasswordInputValue,
        password: this.state.values.passwordInputValue,
      });
      Toast.success(this.props, 'error:valid.saved');
      Object.values(this.inputRefs).forEach((input) => { input.reset(); });
      this.setState(PasswordForm.getInitialState());
    } catch (error) {
      ErrorUtil.handleCatched(this.props, error);
    }
  };

  render() {
    const { t, editionMode } = this.props;
    const { editionInProgress, values, formValid } = this.state;

    return (
      <FormGroup>
        {
          editionMode && (
            <div>
              <div className="mb-3">
                <Button
                  color="primary"
                  disabled={editionInProgress}
                  onClick={() => this.setState({ editionInProgress: true })}
                >
                  {t('user:button.change-password')}
                </Button>
              </div>
              <div className="row">
                <div className={`pt-3 col-12 col-md-6 ${editionInProgress ? '' : 'd-none'}`}>
                  <PasswordInput
                    label={t('user:current-password')}
                    name="old-password"
                    placeholder={t('error:placeholder.password')}
                    value={values.oldPasswordInputValue}
                    onValidityChange={(validity) => this.handleFormValidity('oldPasswordInputValid', validity)}
                    onChange={(e) => {
                      this.handleFormChange('oldPasswordInputValue', e.target.value);
                    }}
                    onValidChange={this.handleFormValidChange}
                    ref={(input) => { this.inputRefs.oldPassword = input; }}
                    needValidation={false}
                  />
                </div>
              </div>
            </div>
          )
        }
        <div
          className={`row ${editionMode && !editionInProgress ? 'd-none' : ''}`}
        >
          <div className={`pt-3 col-12 ${editionMode ? 'col-md-6' : ''}`}>
            <PasswordInput
              label={editionMode ? t('user:new-password') : t('user:password')}
              name="password"
              placeholder={t('error:placeholder.password')}
              value={values.passwordInputValue}
              onValidityChange={(validity) => this.handleFormValidity('passwordInputValid', validity)}
              onChange={(e) => {
                this.handleFormChange('passwordInputValue', e.target.value);
              }}
              onValidChange={this.handleFormValidChange}
              ref={(input) => { this.inputRefs.newPassword = input; }}
            />
          </div>
        </div>
        <div className={`row pt-4 ${!editionInProgress ? 'd-none' : ''}`}>
          <div className="col-12 col-md-auto">
            <Button
              color="primary"
              disabled={!formValid}
              onClick={this.handleFormSubmit}
            >
              {t('user:button.validate-password')}
            </Button>
          </div>
          <div className="col">
            <button
              className="btn btn-link"
              type="button"
              onClick={() => {
                Object.values(this.inputRefs).forEach((input) => { input.reset(); });
                this.setState(PasswordForm.getInitialState());
              }}
            >
              {t('common:button.cancel')}
            </button>
          </div>
        </div>
      </FormGroup>
    );
  }
}


export default PasswordForm;
