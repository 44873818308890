/**
 * Get a nested attribute value of an object
 * @param {obj} The object
 * @returns {boolean}
 */
export const isObject = (obj) => obj === Object(obj);

/**
 * Get a nested attribute value of an object
 * @param {object} object
 * @param {...keys} Path as a list of keys
 * @returns {any} The nested value
 */
export const getNestedValue = (object, ...keys) => {
  let res = object;
  let index = 0;
  while (index < keys.length) {
    if (!isObject(res)) {
      return undefined;
    }
    res = res[keys[index]];
    index += 1;
  }
  return res;
};

/**
 * Get a nested attribute value of an object from a string path
 * @param {object} object
 * @param {string} nestedAttrPath
 * @returns {any} The nested value
 */
export const getNestedValueFromStr = (object, nestedAttrPath) => (
  getNestedValue(object, ...nestedAttrPath.split('.'))
);

/**
 * Set the nested attribute value of an object from a string path
 * @param {object} object
 * @param {string} nestedAttrPath
 * @param {any} value
 * @returns {any} The modified object
 */
export const setNestedValueFromStr = (object, nestedAttrPath, value) => {
  let key = object || {};
  nestedAttrPath.split('.').forEach((attr, index, array) => {
    if (!Object.prototype.hasOwnProperty.call(key)) key[attr] = {};
    if (index !== array.length - 1) {
      key = object[attr];
    } else {
      key[attr] = value;
    }
  });
  return key;
};
