import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { isMobileOnly } from 'react-device-detect';
import { withTranslation } from 'react-i18next';
import { withToastManager } from 'react-toast-notifications';
import { connect } from 'react-redux';
import api from '../api';
import Page from '../components/Page';
import withLicenseMsgModal from '../components/withLicenseMsgModal';
import InvitationCardBase from '../components/InvitationCardBase';
import history from '../history';
import { nsOptions } from '../i18n';
import Toast from '../utils/Toast';
import ErrorUtil from '../utils/ErrorUtil';
import UserUtil from '../utils/UserUtil';

const mapStateToProps = (state) => ({
  user: state.auth.authUser,
});

@withToastManager
@connect(mapStateToProps)
@withTranslation('', nsOptions)
@withLicenseMsgModal()
class JoinProject extends Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
    match: PropTypes.shape().isRequired,
    user: PropTypes.shape().isRequired,
  };

  constructor(props) {
    super(props, props.t('common:nav.join-project'));
    this.state = { invitation: null, errorMessage: '', ready: false };
  }

  componentDidMount() {
    this.fetchInvitation();
  }

  fetchInvitation = async () => {
    const { token } = this.props.match.params;
    try {
      const invitation = await api.read('confirm-project-invitation', token);
      this.setState({ invitation });
    } catch (error) {
      ErrorUtil.handleCatched(this.props, error, false);
      const errorMessage = ErrorUtil.makeApiErrorUserFriendly(this.props, error.message);
      this.setState({ invitation: null, errorMessage });
    } finally {
      this.setState({ ready: true });
    }
  };

  join = async () => {
    try {
      const { token } = this.props.match.params;
      await api.create(`confirm-project-invitation/${token}`);
      const page = isMobileOnly ? 'inclusions' : 'details';
      history.push(`/dashboard/project/${this.state.invitation.project.id}/${page}`);
      Toast.success(this.props, 'error:valid.success');
    } catch (error) {
      ErrorUtil.handleCatched(this.props, error);
    }
  };

  cancel = async () => {
    try {
      const { token } = this.props.match.params;
      await api.create(`dismiss-project-invitation/${token}`);
    } catch (error) {
      ErrorUtil.handleCatched(this.props, error);
    }

    const { user } = this.props;
    const homeLink = UserUtil.getUserHome(user);
    history.push(`/dashboard/${homeLink}`);
  };

  render() {
    const { t } = this.props;
    const { invitation, errorMessage, ready } = this.state;
    const user = invitation ? invitation.creator : null;
    const project = invitation ? {
      name: invitation.project.name,
      language: t(`locale:${invitation.project.language}`),
      topics: invitation.project.topics.map((topic) => (
        <span className="badge badge-turquoise mr-1" key={topic}>
          {t(`topic:${topic}`)}
        </span>
      )),
      description: invitation.project.description,
    } : null;
    return (
      <Page
        {...this.props}
        className="dashboard-content"
        title={t('common:nav.join-project')}
      >
        <InvitationCardBase
          invitation={invitation}
          errorMessage={errorMessage}
          ready={ready}
          label={user ? user.label : ''}
          join={this.join}
          cancel={this.cancel}
          title={invitation ? 'project:invitations.invitation' : 'project:invitations.no-invitation'}
          subtitle="project:invitations.title"
          joinLabel="project:button.join-this-project"
          invalidInvitationMsg="project:invitations.invalid-invitation"
        >
          {
            project && (
              <>
                <div>
                  <span className="mr-1 font-weight-semibold">{t('project:invitations.project-name')}</span>
                  <span>{project.name}</span>
                </div>
                <div className="mt-3 d-none">
                  <span className="mr-1 font-weight-semibold">{t('project:invitations.project-language')}</span>
                  <span>{project.language}</span>
                </div>
                <div className="mt-3">
                  <span className="mr-1 font-weight-semibold">{t('project:invitations.project-topics')}</span>
                  <span>{project.topics}</span>
                </div>
                {
                  project.description && project.description !== '<p>&nbsp;</p>' ? (
                    <div className="mt-3">
                      <div className="mr-1 font-weight-semibold">{t('project:invitations.project-description')}</div>
                      <div
                        className="join-project-description"
                        dangerouslySetInnerHTML={{
                          __html: project.description,
                        }}
                      />
                    </div>
                  ) : null
                }
              </>
            )
          }
        </InvitationCardBase>
      </Page>
    );
  }
}

export default JoinProject;
