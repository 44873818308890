import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { withToastManager } from 'react-toast-notifications';
import { connect } from 'react-redux';
import {
  notificationsActions, projectUsersActions, notificationItemsActions,
  projectElementsActions, elementsActions, projectPagesActions,
} from '../redux/actions';
import {
  CAN_EDIT_FORM_AND_DOCUMENTATIONS,
  PROJECT_USER_USUAL, PROJECT_USER_OWNER_ONLY,
} from '../constants';
import '../assets/css/loader.css';
import { nsOptions } from '../i18n';
import Toast from '../utils/Toast';
import ErrorUtil from '../utils/ErrorUtil';
import { hasPermission, isProjectDisabled } from '../utils/data-util';
import { CardLoader } from './Loader';
import { isMobileView } from './MobileView';
import NotificationsTable from './NotificationsTable';
import NewModal from './NewModal';
import NotificationForm from './NotificationForm';
import BigMenuDivider from './BigMenuDivider';
import BigMenu from './BigMenu';
import BigMenuItem from './BigMenuItem';
import NotificationsRecipientsTable from './NotificationsRecipientsTable';
import MixedView from './MixedView';

let modal = null;

const mapStateToProps = (state) => ({
  user: state.auth.authUser,
  projectUser: Object.values(state.projectUsers).find((pUser) => (
    pUser.user.id === state.auth.authUser.id
  )),
  projectUsers: Object.values(state.projectUsers).filter((pUser) => (
    pUser.confirmed && pUser.type === PROJECT_USER_USUAL)),
  teams: Object.values(state.teams),
  notifications: Object.values(state.notifications),
  notificationItems: Object.values(state.notificationItems) || [],
  projectPages: Object.values(state.projectPages),
  projectElements: Object.values(state.projectElements),
  elements: Object.values(state.elements),
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  fetchNotifications: async (params) => (
    dispatch(notificationsActions.list({
      ...(ownProps.project ? { project: ownProps.project.id } : {}),
      ...params,
      admin: ownProps.admin,
    }))
  ),
  fetchNotificationItems: async (notificationId) => (
    dispatch(notificationItemsActions.list({
      notification: notificationId,
      limit: 1000,
      admin: ownProps.admin,
    }))
  ),
  addNotification: async (data) => dispatch(notificationsActions.create(
    data,
    { admin: ownProps.admin },
  )),
  patchNotification: async (id, data) => dispatch(notificationsActions.patch(
    id,
    data,
    { admin: ownProps.admin },
  )),
  deleteNotification: async (id) => dispatch(notificationsActions.remove(
    id,
    { admin: ownProps.admin },
  )),
  fetchProjectUsers: async () => dispatch(projectUsersActions.list({
    ...(ownProps.project ? { project: ownProps.project.id } : {}),
    confirmed: true,
    type__in: [PROJECT_USER_USUAL, PROJECT_USER_OWNER_ONLY],
    admin: ownProps.admin,
  })),
  fetchProjectPages: async () => dispatch(projectPagesActions.list({
    project: ownProps.project.id,
    admin: ownProps.admin,
  }, { pagination: 'no' })),
  fetchProjectElements: async (pages) => dispatch(projectElementsActions.list({
    project_page__in: Object.values(pages).map((p) => p.id),
    admin: ownProps.admin,
  }, { pagination: 'no' })),
  fetchElements: async (projectElements) => dispatch(elementsActions.list({
    id__in: Object.values(projectElements).map((pEl) => pEl.element),
    admin: ownProps.admin,
  }, { pagination: 'no' })),
});


@withToastManager
@connect(mapStateToProps, mapDispatchToProps)
@withTranslation('', nsOptions)
class ProjectNotifications extends Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
    admin: PropTypes.bool.isRequired,
    project: PropTypes.shape(),
    user: PropTypes.shape().isRequired,
    projectUser: PropTypes.shape(),
    projectUsers: PropTypes.arrayOf(PropTypes.shape()).isRequired,
    teams: PropTypes.arrayOf(PropTypes.shape()).isRequired,
    fetchNotifications: PropTypes.func.isRequired,
    fetchNotificationItems: PropTypes.func.isRequired,
    fetchProjectUsers: PropTypes.func.isRequired,
    fetchTeams: PropTypes.func.isRequired,
    addNotification: PropTypes.func.isRequired,
    patchNotification: PropTypes.func.isRequired,
    deleteNotification: PropTypes.func.isRequired,
    notifications: PropTypes.arrayOf(PropTypes.shape()),
    notificationItems: PropTypes.arrayOf(PropTypes.shape()),
    location: PropTypes.shape().isRequired,
    history: PropTypes.shape().isRequired,
    fetchProjectElements: PropTypes.func.isRequired,
    fetchProjectPages: PropTypes.func.isRequired,
    fetchElements: PropTypes.func.isRequired,
    projectPages: PropTypes.arrayOf(PropTypes.shape()),
    projectElements: PropTypes.arrayOf(PropTypes.shape()),
    elements: PropTypes.arrayOf(PropTypes.shape()),
  };

  static defaultProps = {
    project: null,
    projectUser: null,
    notifications: [],
    notificationItems: [],
    projectElements: null,
    projectPages: null,
    elements: null,
  };

  constructor(props) {
    super(props);
    this.state = {
      dataLoading: true,
      scrollType: 'down',
    };
  }

  componentDidMount() {
    this.fetchData();
  }

  fetchData = async () => {
    this.setState({ dataLoading: true });
    const {
      projectUser, fetchProjectUsers, fetchNotifications,
      projectPages, fetchProjectPages,
      fetchProjectElements, projectElements,
      fetchElements, elements,
    } = this.props;
    try {
      await fetchNotifications();
      // fetch if needed (on page reload)
      if (!projectUser) await fetchProjectUsers();
      if (!projectPages || Object.keys(projectPages).length === 0) await fetchProjectPages();
      if (!projectElements || Object.keys(projectElements).length === 0) {
        await fetchProjectElements(this.props.projectPages);
      }
      if (!elements || Object.keys(elements).length === 0) {
        if (Object.keys(this.props.projectElements).length > 0) {
          await Promise.all([
            fetchElements(this.props.projectElements),
          ]);
        }
      }
    } catch (error) {
      console.error(error);
      ErrorUtil.handleCatched(this.props, error, false);
    } finally {
      this.setState({ dataLoading: false });
    }
  }

  // Allow other team members to add notification if they can edit form and docs
  getTeamMembersWhichCanInclude = () => {
    const { projectUser, teams } = this.props;
    if (projectUser && teams.length) {
      return projectUser.team_members.filter((member) => {
        const team = teams.find((tm) => tm.members.includes(member.id));
        return hasPermission(projectUser, CAN_EDIT_FORM_AND_DOCUMENTATIONS, team.id);
      }).map((member) => member.id);
    }
    return [];
  }

  canAddNotification = () => {
    const { projectUser, project, admin } = this.props;
    if ((!admin && !projectUser) || !project || isProjectDisabled(project) || project.is_paused) {
      return false;
    }
    return true;
  }

  addNotification = async (newNotification) => {
    const { addNotification } = this.props;
    try {
      await addNotification(newNotification);
      Toast.success(this.props, 'error:valid.saved');
    } catch (error) {
      ErrorUtil.handleCatched(this.props, error);
    }
  };

  updateNotification = async (id, newNotification) => {
    const { patchNotification } = this.props;
    try {
      await patchNotification(id, newNotification);
      Toast.success(this.props, 'error:valid.saved');
    } catch (error) {
      ErrorUtil.handleCatched(this.props, error);
    }
  };

  addOrUpdateNotification = async (notification) => {
    const { fetchNotifications } = this.props;
    this.setState({ dataLoading: true });
    modal.hide();
    if (notification.id) { // Update
      await this.updateNotification(notification.id, notification);
    } else { // Add
      await this.addNotification(notification);
    }
    await fetchNotifications();
    this.setState({
      dataLoading: false,
      notificationToDisplay: null,
    });
  };

  deleteNotification = async (id) => {
    const { deleteNotification } = this.props;
    modal.hide();
    this.setState({ notificationToDisplay: null });
    if (!id) return;
    try {
      await deleteNotification(id);
      Toast.success(this.props, 'error:valid.deleted');
    } catch (error) {
      ErrorUtil.handleCatched(this.props, error);
    }
  }

  scrollContent = () => {
    const duration = 500;
    if (this.state.scrollType === 'up') {
      this.scrollTop(duration);
    } else {
      this.scrollBottom(duration);
    }
  };

  showModal() {
    try {
      modal.show();
    } catch (error) {
      ErrorUtil.handleCatched(this.props, error, false);
    }
  }

  openNotification(notification) {
    const { history, fetchNotificationItems } = this.props;
    this.setState({ notificationToDisplay: notification });
    history.replace(`${this.clearPath()}/edit`);
    fetchNotificationItems(notification.id);
    this.showModal();
  }

  clearPath() {
    const { location } = this.props;
    let newPath = location.pathname;
    newPath = newPath.replace('edit', '');
    newPath = newPath.replace('recipient', '');
    newPath = newPath.endsWith('/') ? newPath.slice(0, -1) : newPath;
    return newPath;
  }

  render() {
    const {
      t, project, projectUser, notifications, notificationItems, history, location, admin,
    } = this.props;
    const { dataLoading } = this.state;

    const canEditFormAndDocs = admin || hasPermission(
      projectUser,
      CAN_EDIT_FORM_AND_DOCUMENTATIONS,
    );
    const cannotSeeNotificationTable = !canEditFormAndDocs && !admin;

    const notificationCountInfo = (
      <div className="text-bigger font-weight-semibold">
        { notifications && <span>{notifications.length}</span> }
        {' '}
        {t('common:dashboard.notifications')}
      </div>
    );

    return (
      <>
        {dataLoading && <CardLoader />}
        {cannotSeeNotificationTable ? (
          <div className="text-muted text-center my-5">
            <h6>
              {t('project:notification.no-access-right')}
            </h6>
          </div>
        ) : (
          <>
            <div className={`row ${isMobileView() ? 'justify-content-center' : 'justify-content-between'} mt-3`}>
              <MixedView>
                <div className="col-12 col-xl-auto col-md-12 mb-2">
                  <div className="row align-items-end">
                    <div className="col-12 col-md-auto pt-2">
                      {notificationCountInfo}
                    </div>
                  </div>
                </div>
              </MixedView>
              {
                project !== null && (canEditFormAndDocs) && (
                  <div className="col-12 col-md-auto py-4 py-md-0">
                    <div className="row">
                      <div className={`col-12 col-md-auto ${isMobileView() ? 'text-center' : ''}`}>
                        <button
                          className={`btn text-white btn-newturquoise-1 ${isMobileView() ? 'rounded btn-notification-search' : ''}`}
                          onClick={() => { this.showModal(); }}
                          disabled={!this.canAddNotification()}
                        >
                          <FontAwesomeIcon
                            icon={['fal', 'plus']}
                            transform="grow-3"
                            className="mr-2"
                            {...(isMobileView() ? { id: 'plus-icon-notification-search' } : {})}
                          />
                          { !isMobileView() && (
                            <span className="ml-1">
                              {t('project:button.include-notification')}
                            </span>
                          )}
                        </button>
                      </div>
                    </div>
                  </div>
                )
              }
            </div>
            <NotificationsTable
              notifications={notifications}
              onClick={(notif) => this.openNotification(notif)}
              ref={(ref) => { this.notificationTable = ref; }}
            />
          </>
        )}
        <NewModal
          {...this.props}
          xlHeader={
            this.state.notificationToDisplay ? this.state.notificationToDisplay.name : t('project:notification.form.title')
          }
          size="lg"
          dialogClass="contains-form"
          trigger={(<div />)}
          onClosed={() => {
            this.setState({
              notificationToDisplay: null,
            });
            history.replace(this.clearPath());
          }}
          ref={(modalRef) => {
            modal = modalRef;
          }}
        >
          {this.state.notificationToDisplay && (
            <div className="with-time-select mx-auto mb-5">
              <BigMenu divider="none" {...this.props}>
                <BigMenuItem to={`${this.clearPath()}/edit`} {...this.props}>
                  {t('project:form.edition')}
                </BigMenuItem>
                <BigMenuDivider />
                <BigMenuItem to={`${this.clearPath()}/recipient`} {...this.props}>
                  {t('project:notification.table.history')}
                </BigMenuItem>
              </BigMenu>
            </div>
          )}
          {location.pathname.endsWith('/recipient') ? (
            <NotificationsRecipientsTable
              {...this.props}
              notificationItems={notificationItems}
              notificationToDisplay={this.state.notificationToDisplay}
            />
          ) : (
            <NotificationForm
              {...this.props}
              notificationToDisplay={this.state.notificationToDisplay}
              dateElements={this.props.elements.filter((e) => e.type === 'date')}
              callback={this.addOrUpdateNotification}
              callbackDelete={this.deleteNotification}
              project={project}
            />
          )}
        </NewModal>
      </>
    );
  }
}

export default ProjectNotifications;
