import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { withToastManager } from 'react-toast-notifications';
import { connect } from 'react-redux';
import { nsOptions } from '../i18n';
import { childrenPropTypes } from '../utils/generic-prop-types';
import TextUtil from '../utils/TextUtil';
import NewTooltip from './NewTooltip';
import ElementUtil from '../utils/ElementUtil';
import SortUtil from '../utils/SortUtil';

const NAME_MAX_LENGTH = 40;

@withTranslation('', nsOptions)
class DataItem extends Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
    pageId: PropTypes.number.isRequired,
    element: PropTypes.shape().isRequired,
    projectElement: PropTypes.shape().isRequired,
    moduleId: PropTypes.number,
    moduleInstanceId: PropTypes.number,
    handleClick: PropTypes.func.isRequired,
    children: childrenPropTypes(),
    extraInfo: PropTypes.string,
  };

  static defaultProps = {
    moduleId: null,
    moduleInstanceId: null,
    children: undefined,
    extraInfo: null,
  }

  render() {
    const {
      t, element, projectElement, pageId, handleClick, children, moduleId,
      moduleInstanceId, extraInfo,
    } = this.props;

    const elementName = ElementUtil.formatElementName(element, t);

    return (
      <li key={`${moduleInstanceId ? `entry-${moduleInstanceId}-` : ''}list-item-${projectElement.id}`}>
        <NewTooltip
          contentClassName="small"
          content={elementName}
          disabled={!(elementName.length > NAME_MAX_LENGTH)}
        >
          <a
            key={`${moduleInstanceId ? `entry-${moduleInstanceId}-` : ''}link-${projectElement.id}`}
            role="button"
            tabIndex="-1"
            onClick={() => handleClick(pageId, projectElement.id, moduleId, moduleInstanceId)}
            onKeyPress={() => {}}
      // Next line allows to handle link hover (otherwise bootstrap overwrites the hover
      // behaviour)
            className=""
          >
            {TextUtil.truncate(elementName, NAME_MAX_LENGTH)}
          </a>
        </NewTooltip>
        {extraInfo && (
          <NewTooltip
            contentClassName="small"
            content={t(`inclusion:qualify-missing-data.${extraInfo.replace('_', '-')}`)}
          >
            <span className="text-gray-dark">
              &nbsp;
              [
              {t(`inclusion:qualified-missing-data.${extraInfo.replace('_', '-')}-code`)}
              ]
            </span>
          </NewTooltip>
        )}
        {children}
      </li>
    );
  }
}

const mapStateToProps = (state) => ({
  projectPages: state.projectPages,
});

const mapDispatchToProps = () => ({
});

@withToastManager
@connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })
@withTranslation('', nsOptions)
class DataMonitoringTooltip extends Component {
  static propTypes = {
    projectPages: PropTypes.shape().isRequired,
    projectElements: PropTypes.arrayOf(PropTypes.shape()).isRequired,
    elements: PropTypes.arrayOf(PropTypes.shape()).isRequired,
    projectEntries: PropTypes.arrayOf(PropTypes.shape()).isRequired,
    formattedResult: PropTypes.shape().isRequired,
    formattedOptionalResult: PropTypes.shape(),
    handleClick: PropTypes.func.isRequired,
    totalPrjElements: PropTypes.number.isRequired,
    displayOptionalResult: PropTypes.bool,
    title: PropTypes.string.isRequired,
    nothingToDisplayMessage: PropTypes.string.isRequired,
    extraInfo: PropTypes.string,
    mainTabName: PropTypes.string,
    secondTabName: PropTypes.string,
    secondTabNote: PropTypes.string,
    footer: PropTypes.shape(),
  };

  static defaultProps = {
    formattedOptionalResult: null,
    displayOptionalResult: false,
    extraInfo: null,
    mainTabName: null,
    secondTabName: null,
    secondTabNote: null,
    footer: undefined,
  };

  constructor(props) {
    super(props);
    this.state = {
      showRequiredVariables: true,
    };
  }

  getFormattedContent = (pageId, contentToDisplay) => {
    const {
      projectElements, elements, projectEntries, handleClick, extraInfo,
    } = this.props;
    const pageData = contentToDisplay[pageId];
    const res = Object.values(pageData).map((data) => {
      if (data.type === 'variable') {
        const prjElement = projectElements.find((pEl) => pEl.id === data.id);
        const element = elements.find((el) => el.id === prjElement.element);
        return (
          <DataItem
            pageId={pageId}
            projectElement={prjElement}
            element={element}
            handleClick={handleClick}
            key={prjElement.id}
            extraInfo={data[extraInfo]}
          />
        );
      }
      const moduleId = data.id;
      const modulePrjElement = projectElements.find((pEl) => pEl.id === moduleId);
      const element = elements.find((el) => el.id === modulePrjElement.element);
      return (
        <span key={`entries-module-${moduleId}`}>
          <DataItem
            pageId={pageId}
            projectElement={modulePrjElement}
            element={element}
            handleClick={handleClick}
            key={modulePrjElement.id}
            extraInfo={data[extraInfo]}
          >
            <ul
              className="data-monitoring-list"
              key={`entries-list-module-${modulePrjElement.id}`}
            >
              {
                Object.values(data.entries).map((entry) => {
                  const projectEntry = projectEntries.find((pEntry) => pEntry.id === entry.id);
                  return (
                    <span key={`content-entry-${entry.id}`}>
                      <NewTooltip
                        contentClassName="small"
                        content={projectEntry.value}
                        disabled={!(projectEntry.value.length > NAME_MAX_LENGTH)}
                      >
                        <li
                          key={`entry-${entry.id}`}
                        >
                          <a
                            role="button"
                            tabIndex="-1"
                            onClick={() => handleClick(pageId, null, moduleId, entry.id)}
                            onKeyPress={() => {}}
                            className=""
                          >
                            {TextUtil.truncate(projectEntry.value, NAME_MAX_LENGTH)}
                          </a>
                        </li>
                      </NewTooltip>
                      <ul
                        className="data-monitoring-list"
                        key={`list-entry-${entry.id}`}
                      >
                        {
                          Object.values(entry.childs).map((pElement) => {
                            const projectElement = projectElements.find((pEl) => (
                              pEl.id === pElement.id));
                            const elementInModule = elements.find((el) => (
                              el.id === projectElement.element));
                            return (
                              <DataItem
                                pageId={pageId}
                                projectElement={projectElement}
                                element={elementInModule}
                                moduleId={modulePrjElement.id}
                                moduleInstanceId={entry.id}
                                handleClick={handleClick}
                                key={`${entry}-${projectElement.id}`}
                                extraInfo={pElement[extraInfo]}
                              />
                            );
                          })
                        }
                      </ul>
                    </span>
                  );
                })
              }
            </ul>
          </DataItem>
        </span>
      );
    });
    return res;
  }

  render() {
    const {
      projectPages, handleClick, displayOptionalResult, formattedResult,
      formattedOptionalResult, totalPrjElements, title, nothingToDisplayMessage,
      mainTabName, secondTabName, secondTabNote, footer,
    } = this.props;
    const { showRequiredVariables } = this.state;

    const contentToDisplay = showRequiredVariables ? formattedResult
      : formattedOptionalResult;

    let totalPrjElementsToDisplay = totalPrjElements;
    if (!showRequiredVariables) {
      totalPrjElementsToDisplay = 0;
      Object.keys(formattedOptionalResult).forEach((page) => {
        Object.values(formattedOptionalResult[page]).forEach((item) => {
          if (item.type === 'variable') {
            totalPrjElementsToDisplay += 1;
          } else {
            Object.values(item).forEach((entry) => {
              Object.values(entry).forEach((elements) => {
                totalPrjElementsToDisplay += elements.length;
              });
            });
          }
        });
      });
    }

    const pagesIds = Object.keys(contentToDisplay).map((pageId) => (
      Number.parseFloat(pageId)));
    const pages = Object.values(projectPages).filter((projectPage) => (
      pagesIds.includes(projectPage.id))).sort(SortUtil.sortArray);
    return (
      <div>
        <div className="row mx-0 pb-2">
          <div className="col-12 px-1">
            <h5 className="text-center">
              {title}
            </h5>
          </div>
        </div>
        {
          displayOptionalResult && mainTabName && secondTabName && (
            <div className="row mb-3 mx-0">
              <div className="col px-1 text-center">
                <a
                  className={`small ${showRequiredVariables ? 'font-weight-bold' : ''}`}
                  key="main-tab"
                  role="button"
                  onClick={() => {
                    if (!showRequiredVariables) {
                      this.setState({ showRequiredVariables: true });
                    }
                  }}
                  onKeyPress={() => {}}
                  tabIndex={0}
                  style={{ textDecoration: `${showRequiredVariables ? 'underline' : 'none'}` }}
                >
                  {mainTabName}
                </a>
                <a
                  className={`ml-4 small ${!showRequiredVariables ? 'font-weight-bold' : ''}`}
                  key="second-tab"
                  role="button"
                  onClick={() => {
                    if (showRequiredVariables) {
                      this.setState({ showRequiredVariables: false });
                    }
                  }}
                  onKeyPress={() => {}}
                  tabIndex={0}
                  style={{ textDecoration: `${!showRequiredVariables ? 'underline' : 'none'}` }}
                >
                  {secondTabName}
                </a>
              </div>
            </div>
          )
        }
        {
          !showRequiredVariables && secondTabNote && (
            <div className="row mx-0 mb-3">
              <div className="col px-1">
                <span className="font-italic text-gray small">
                  {secondTabNote}
                </span>
              </div>
            </div>
          )
        }
        {
          totalPrjElementsToDisplay ? (
            <>
              <div className="row mx-0">
                <div className="col px-1 pt-2">
                  {
                    pages.map((page) => (
                      <span key={`content-page-${page.id}`}>
                        <>
                          <NewTooltip
                            contentClassName="small"
                            content={page.title}
                            disabled={!(page.title.length > NAME_MAX_LENGTH)}
                          >
                            <h6 key={`title-page-${page.id}`}>
                              <a
                                key={`link-page-${page.id}`}
                                role="button"
                                tabIndex="-1"
                                onClick={() => handleClick(page.id)}
                                onKeyPress={() => {}}
                                className=""
                              >
                                <strong>
                                  {TextUtil.truncate(page.title, NAME_MAX_LENGTH)}
                                </strong>
                              </a>
                            </h6>
                          </NewTooltip>
                          <ul
                            className="data-monitoring-list"
                            key={`list-page-${page.id}`}
                          >
                            {this.getFormattedContent(page.id, contentToDisplay)}
                          </ul>
                        </>
                      </span>
                    ))
                  }
                </div>
              </div>
              {footer && (
                <div className="px-1 pt-2">
                  {footer}
                </div>
              )}
            </>
          ) : (
            <div className="row mx-0">
              <div className="col px-1 text-center pt-3">
                <p><strong>{nothingToDisplayMessage}</strong></p>
              </div>
            </div>
          )
        }
      </div>
    );
  }
}

export default DataMonitoringTooltip;
