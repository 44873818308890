import { createReducer } from 'redux-starter-kit';
import { notificationItemsActions } from '../actions';
import getStdDbModelReduxMapping from './std-db-model-reducers';

export const notificationsItemsInitialState = {};

const notificationsItemsReducer = createReducer(
  notificationsItemsInitialState,
  getStdDbModelReduxMapping(notificationItemsActions),
);

export default notificationsItemsReducer;
