import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Label } from 'reactstrap';
import { projectsActions } from '../redux/actions';
import { MessageModal } from './MessageModal';
import EditButton from './EditButton';
import { childrenPropTypes } from '../utils/generic-prop-types';
import {
  LOCK_MODEL_PROJECT, LOCK_SECTION, LOCK_SECTION_PAUSE_REASON, LOCK_TOAST_TIMEOUT_S,
} from '../constants';
import { DataLocker, isProjectDisabled } from '../utils/data-util';
import useError from '../utils/HookErrorUtil';
import useToast from '../utils/HookToast';
import TimeoutHandler from '../utils/TimeoutHandler';


function PauseMessageModal(props) {
  const {
    project, children, onValidate,
  } = props;
  const { t } = useTranslation();

  const [pauseReason, setPauseReason] = useState(project.pause_reason);

  const resetPauseReason = () => setPauseReason(project.pause_reason);

  useEffect(() => {
    resetPauseReason();
  }, [project.is_paused]);

  return (
    <MessageModal
      message={(
        <div>
          <b>
            {t('common:caution')}
            &nbsp;
          </b>
          <br />
          <br />
          <div>
            {t('project:pause.info')}
          </div>
          <div className="mt-4">
            <label
              htmlFor="pause-reason"
            >
              {t('project:pause.enter-reason')}
            </label>
            <textarea
              id="create-pause-reason"
              name="create-pause-reason"
              className="form-control border no-resize"
              rows="4"
              value={pauseReason}
              onChange={(e) => setPauseReason(e.target.value)}
              maxLength={500}
            />
            {project.owner.id !== project.manager.id && (
              <div className="help-text">
                {t('project:pause.reason-will-be-communicated-to-manager')}
              </div>
            )}
          </div>
        </div>
      )}
      showCancelButton
      onValidate={() => onValidate(!project.is_paused, pauseReason)}
      onCancel={() => resetPauseReason()}
      triggerType="checkbox"
      modalBodyClassName="text-center"
    >
      {children}
    </MessageModal>
  );
}

PauseMessageModal.propTypes = {
  children: childrenPropTypes().isRequired,
  project: PropTypes.shape().isRequired,
  onValidate: PropTypes.func,
};

PauseMessageModal.defaultProps = {
  onValidate: () => {},
};

function ProjectTeamZone(props) {
  const {
    admin, project, canPauseProject,
  } = props;
  const { t } = useTranslation();
  const { success, warning } = useToast();
  const { handleCatched } = useError();
  const pauseReasonLocker = useRef(null);
  const timeoutHandler = useRef(null);

  const [pauseReason, setPauseReason] = useState(project.pause_reason);
  const [pauseReasonEditing, setPauseReasonEditing] = useState(false);

  const resetPauseReason = () => setPauseReason(project.pause_reason);

  const unlockLocker = () => {
    if (pauseReasonLocker.current && pauseReasonLocker.current.isLocked()) {
      setPauseReasonEditing(false);
      pauseReasonLocker.current.unlock();
    }
  };

  useEffect(() => {
    // Component mounts
    pauseReasonLocker.current = new DataLocker(LOCK_MODEL_PROJECT, LOCK_SECTION, admin);
    timeoutHandler.current = new TimeoutHandler();

    // Component unmounts
    return () => {
      unlockLocker();
    };
  }, []);

  useEffect(() => {
    resetPauseReason();
    if (!project.is_paused) {
      unlockLocker();
    }
  }, [project.is_paused]);

  const dispatch = useDispatch();

  const patchProject = async (projectId, data, params = {}) => dispatch(
    projectsActions.patch(projectId, data, { annotate_investigators_count: true, ...params }),
  );

  const updateProject = async (data, extraParams = {}) => {
    try {
      await patchProject(
        project.id,
        data,
        { admin, ...extraParams },
      );
      success('error:valid.success');
    } catch (error) {
      handleCatched(props, error);
    }
  };

  const updateIsPaused = async (value, newPauseReason = undefined) => {
    const data = { is_paused: value };
    if (newPauseReason !== undefined) {
      data.pause_reason = newPauseReason;
    }

    // Cannot use saveInput because need to update several fields (is_paused and pause_reason)
    await updateProject(data);
  };

  const updateProjectPauseReason = async (e) => {
    e.preventDefault();
    const { value } = e.target;

    const currentPauseReason = project.pause_reason;

    setPauseReason(value);
    if (currentPauseReason || value.trim() !== '') {
      timeoutHandler.current.doAfterTimeout(() => updateProject({ pause_reason: value }));
    }
  };

  const onPauseReasonEditionToggle = async () => {
    try {
      const res = await pauseReasonLocker.current.toggleLock(project.id, LOCK_SECTION_PAUSE_REASON);
      const { locked, success: successful } = res;
      if (successful) {
        setPauseReasonEditing(locked);
      } else {
        warning(DataLocker.lockUserMessage(res, t), LOCK_TOAST_TIMEOUT_S);
      }
    } catch (error) {
      handleCatched(props, error);
    }
  };

  const projectPaused = project.is_paused;
  const projectDisabled = isProjectDisabled(project);

  return (canPauseProject || projectPaused) ? (
    <div className="mt-3">
      {
        canPauseProject ? (
          <PauseMessageModal
            project={project}
            onValidate={updateIsPaused}
          >
            <div
              className="custom-control custom-switch pause-project-switch"
          // NewModal in MessageModal tries to access props.trigger['onChange']
          // and the trigger props is this div (not the input it contains)
          // Triggered when reactivating the project
              onChange={() => updateIsPaused(false, '')}
            >
              <input
                className="custom-control-input"
                type="checkbox"
                id="project-pause-switch"
                checked={projectPaused}
                disabled={projectDisabled || (!admin && !canPauseProject)}
                onChange={() => {}}
              />
              <label
                className={`custom-control-label ${projectPaused ? 'text-red font-weight-semibold' : ''}`}
                htmlFor="project-pause-switch"
              >
                {t(`project:${projectPaused ? 'project-paused' : 'pause.do'}`)}
              </label>
            </div>
          </PauseMessageModal>
        ) : (
          <div className="text-red font-weight-semibold">
            {t('project:project-paused')}
          </div>
        )
      }
      <div className={`help-text ${projectPaused ? 'text-red' : ''}`}>
        {t('project:pause.info', { context: projectPaused ? 'is_paused' : '' })}
      </div>
      {projectPaused && (
        <div className="mt-3">
          <Label>
            {t('project:pause.reason')}
          </Label>
          {canPauseProject && (
            <EditButton
              className="ml-1"
              transform="grow-2"
              active={pauseReasonEditing}
              onClick={onPauseReasonEditionToggle}
            />
          )}
          <div className="my-2">
            {pauseReason || pauseReasonEditing ? (
              <textarea
                id="pause-reason-update"
                className="form-control border no-resize mt-1"
                value={pauseReason}
                maxLength={500}
                rows={4}
                cols={4}
                placeholder={t('project:pause.enter-reason', { context: 'placeholder' })}
                onChange={updateProjectPauseReason}
                disabled={!pauseReasonEditing}
              />
            ) : (
              <div className="text-gray font-italic">
                {t('project:pause.no-reason-provided')}
              </div>
            )}
            {pauseReasonEditing && project.owner.id !== project.manager.id && (
              <div className="help-text">
                {t('project:pause.reason-will-be-communicated-to-manager')}
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  ) : null;
}

ProjectTeamZone.propTypes = {
  project: PropTypes.shape().isRequired,
  admin: PropTypes.bool,
  canPauseProject: PropTypes.bool,
};

ProjectTeamZone.defaultProps = {
  admin: false,
  canPauseProject: false,
};

export default ProjectTeamZone;
