import { createReducer } from 'redux-starter-kit';
import fromEntries from 'fromentries';
import { statsFiltersActions } from '../actions';

const {
  addFilter, removeFilter, removeTargetFilters, removeAllFilters,
} = statsFiltersActions;

export const statsFiltersInitialState = {};

const statsFiltersReducer = createReducer(statsFiltersInitialState, {
  [addFilter]: (state, action) => ({
    ...state,
    [action.payload.filterId]: { ...action.payload.filterData },
  }),
  [removeFilter]: (state, action) => {
    const { payload } = action;
    return fromEntries(Object.entries(state).filter(([key]) => key !== String(payload)));
  },
  [removeTargetFilters]: (state, action) => {
    const { payload } = action;
    const newState = {};
    Object.entries(state).forEach(([filterId, filterData]) => {
      if (filterData.targetId !== String(payload)) newState[filterId] = filterData;
    });
    return newState;
  },
  [removeAllFilters]: () => statsFiltersInitialState,
});

export default statsFiltersReducer;
