import moment from 'moment';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { withTranslation } from 'react-i18next';
import { nsOptions } from '../i18n';
import { childrenPropTypes } from '../utils/generic-prop-types';
import NewTooltip from './NewTooltip';
import { DATE_FORMAT } from '../utils/date';

export const validators = {
  human_name: (v) => {
    if (!v || v.constructor !== String) return 'incorrect-field';
    if (v.length < 1) return ['too-short', { count: 1 }];
    if (v.length > 25) return ['too-long', { count: 25 }];
    return true;
  },
  human_fullname: (v) => {
    if (!v || v.constructor !== String) return 'incorrect-field';
    if (v.length < 2) return ['too-short', { count: 2 }];
    if (v.length > 50) return ['too-long', { count: 50 }];
    return true;
  },
  project_name: (v) => {
    if (!v || v.constructor !== String) return 'incorrect-field';
    if (v.length < 2) return ['too-short', { count: 2 }];
    if (v.length > 150) return ['too-long', { count: 150 }];
    return true;
  },
  email: (v) => {
    if (!v || v.constructor !== String) return 'incorrect-field';
    if (!/^[a-zA-Z0-9_!#$%&'*+/=?`{|}~^-]+(?:\.[a-zA-Z0-9_!#$%&'*+/=?`{|}~^-]+)*@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)+$/gi.test(v)) return 'incorrect-email';
    return true;
  },
  email_list: (v) => {
    if (!v || v.constructor !== String) return 'incorrect-field';
    if (!/^ *?([a-zA-Z0-9_!#$%&'*+/=?`{|}~^-]+(?:\.[a-zA-Z0-9_!#$%&'*+/=?`{|}~^-]+)*@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)+)+([;,|\s]+[a-zA-Z0-9_!#$%&'*+/=?`{|}~^-]+(?:\.[a-zA-Z0-9_!#$%&'*+/=?`{|}~^-]+)*@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)+)* *?$/gim.test(v)) return 'incorrect-email-list';
    return true;
  },
  address: (v) => {
    if (!v || v.constructor !== String) return 'incorrect-field';
    if (v.length < 2) return ['too-short', { count: 2 }];
    return true;
  },
  institution: (v) => {
    if (!v || v.constructor !== String) return 'incorrect-field';
    if (v.length < 2) return ['too-short', { count: 2 }];
    if (v.length > 150) return ['too-long', { count: 150 }];
    return true;
  },
  city: (v) => {
    if (!v || v.constructor !== String) return 'incorrect-field';
    if (v.length < 2) return ['too-short', { count: 2 }];
    if (v.length > 50) return ['too-long', { count: 50 }];
    return true;
  },
  state: (v) => {
    if (!v || v.constructor !== String) return 'incorrect-field';
    if (v.length < 2) return ['too-short', { count: 2 }];
    if (v.length > 50) return ['too-long', { count: 50 }];
    return true;
  },
  country: (v) => {
    if (!v || v.constructor !== String) return 'incorrect-field';
    if (v.length < 2) return ['too-short', { count: 2 }];
    if (v.length > 50) return ['too-long', { count: 50 }];
    return true;
  },
  zip: (v) => {
    if (!v || v.constructor !== String) return 'incorrect-field';
    if (v.length < 2) return ['too-short', { count: 2 }];
    if (v.length > 15) return ['too-long', { count: 15 }];
    if (!/^([\d\s]*)$/gi.test(v)) {
      return ['invalid-characters-only', { allowed: 'zip' }];
    }
    return true;
  },
  phone: (v) => {
    if (!v || v.constructor !== String) return 'incorrect-field';
    if (v.length < 2) return ['too-short', { count: 2 }];
    if (v.length > 20) return ['too-long', { count: 20 }];
    if (!/^([+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s./0-9]*)$/gi.test(v)) {
      return ['invalid-characters-only', { allowed: 'phone' }];
    }
    return true;
  },
  vat: (v) => {
    if (!v || v.constructor !== String) return 'incorrect-field';
    if (v.length < 1) return ['too-short', { count: 1 }];
    if (v.length > 20) return ['too-long', { count: 20 }];
    if (!/^(\d+)$/gi.test(v)) return 'incorrect-field';
    return true;
  },
  password: (v) => {
    if (!v || v.constructor !== String) return 'incorrect-field';
    if (v.length < 10) return ['too-short', { count: 10 }];
    if (v.length > 100) return ['too-long', { count: 100 }];
    if (!/^((?=.*\d)(?=.*[A-Z])(?=.*[a-z])(?=.*[!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~]).{8,})$/g.test(v)) {
      return [
        'invalid-characters-at-least',
        { required: 'password' },
      ];
    }
    return true;
  },
  presentation: (v) => {
    if (!v || v.constructor !== String) return 'incorrect-field';
    if (v.length > 200) return ['too-long', { count: 200 }];
    return true;
  },
  professional_number: (v) => {
    if (!v || v.constructor !== String) return 'incorrect-field';
    if (v.length > 20) return ['too-long', { count: 20 }];
    return true;
  },
  date: (v) => {
    if (!v || v.constructor !== String) return 'incorrect-field';
    if (!/^(\d{2})\/(\d{2})\/(\d{4})$/gi.test(v)) return 'incorrect-date';
    if (!moment(v, DATE_FORMAT).isValid()) return 'incorrect-date';
    return true;
  },
  url: (v) => {
    if (!v || v.constructor !== String) return 'incorrect-field';
    if (!/^(?:(?:https?|ftp):\/\/)(?:\S+(?::\S*)?@)?(?:(?!10(?:\.\d{1,3}){3})(?!127(?:\.\d{1,3}){3})(?!169\.254(?:\.\d{1,3}){2})(?!192\.168(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]+-?)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]+-?)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/[^\s]*)?$/gi.test(v)) return 'incorrect-url';
    return true;
  },
};


export const GUI_EFFECT_NONE = 'none';
export const GUI_EFFECT_PARTIAL = 'partial';
export const GUI_EFFECT_FULL = 'full';

@withTranslation('', nsOptions)
class Validator extends Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
    children: childrenPropTypes().isRequired,
    validation: PropTypes.string,
    required: PropTypes.bool,
    confirmValue: PropTypes.func,
    className: PropTypes.string,
    tooltipDistance: PropTypes.number,
  };

  static defaultProps = {
    validation: undefined,
    required: false,
    confirmValue: undefined,
    className: '',
    tooltipDistance: undefined,
  };

  constructor(props) {
    super(props);
    this.state = { valid: true, content: null, tooltipVisible: false };
    this.validator = this.props.validation ? validators[this.props.validation] : null;
  }

  validate = (v, guiEffect = GUI_EFFECT_FULL) => {
    const value = v && v !== '' ? v : null;
    const { t, confirmValue } = this.props;
    if (!value && !this.props.required) return this.valid();
    if (!value || (value.constructor === Array && value.length < 1)) {
      return this.invalid(t('error:validation.required'), guiEffect);
    }
    if (!this.validator && confirmValue) {
      if (confirmValue() === value) {
        return this.valid();
      }
      return this.invalid(t('error:validation.does-not-match'), guiEffect);
    }
    if (!this.validator) return this.valid();
    const result = this.validator(value);
    if (result === true) return this.valid();
    if (result.constructor === String) return this.invalid(t(`error:validation.${result}`), guiEffect);
    Object.entries(result[1]).forEach((entry) => {
      if (entry[1].constructor === String) {
        result[1][entry[0]] = t(`error:field.${entry[1]}`);
      }
    });
    return this.invalid(t(`error:validation.${result[0]}`, result[1]), guiEffect);
  };

  reset = () => {
    this.setState({ valid: true, content: null, tooltipVisible: false });
  };

  valid = () => {
    this.setState({ valid: true, content: null, tooltipVisible: false });
    return true;
  };

  invalid = (content, guiEffect = GUI_EFFECT_FULL) => {
    if (guiEffect !== GUI_EFFECT_NONE) {
      this.setState({ valid: false, content });
      if (guiEffect === GUI_EFFECT_FULL && content) {
        this.setState({ tooltipVisible: true });
      }
    }
    return false;
  };

  render() {
    const {
      className, validation, required, tooltipDistance, children, confirmValue,
    } = this.props;
    const { tooltipVisible, valid } = this.state;
    const content = (
      <div className="row">
        <div className="col pr-1">
          {this.state.content}
        </div>
        <div className="col-auto pl-1">
          <FontAwesomeIcon
            className="ml-2"
            icon={['far', 'times']}
            transform="shrink-4"
            onClick={() => { this.setState({ tooltipVisible: false }); }}
          />
        </div>
      </div>
    );

    return required || validation || confirmValue ? (
      <NewTooltip
        content={content}
        interactive
        theme="red"
        visible={tooltipVisible}
        placement="top"
        distance={tooltipDistance}
        arrow={false}
        showTooltipEvenIfEmpty
        disabled={!required && !validation}
      >
        <div className={classnames(className, { 'invalid-field': !valid })}>
          {children}
        </div>
      </NewTooltip>
    ) : (
      <div className={className}>
        {children}
      </div>
    );
  }
}


export default Validator;
