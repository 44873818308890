import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { withToastManager } from 'react-toast-notifications';
import { connect } from 'react-redux';
import { elementsActions, projectEntriesActions } from '../redux/actions';
import { nsOptions } from '../i18n';
import TextUtil from '../utils/TextUtil';
import ErrorUtil from '../utils/ErrorUtil';
import Toast from '../utils/Toast';
import TimeoutHandler from '../utils/TimeoutHandler';
import ElementBase, { retrieveProjectEntry, getMutex } from './ElementBase';
import ElementContentEditable from './ElementContentEditable';
import { ENTRY_TYPE_MEASUREMENT } from '../constants';

const mapStateToProps = (state, ownProps) => ({
  element: state.elements[ownProps.elementId],
  inclusion: state.inclusions[ownProps.inclusionId],
  projectElement: state.projectElements[ownProps.projectElementId],
  user: state.auth.authUser,
  projectEntry: retrieveProjectEntry(
    Object.values(state.projectEntries),
    ownProps.inclusionId,
    ownProps.projectElementId,
    ownProps.moduleInstanceId,
  ),
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  patchElement: async (id, data) => dispatch(elementsActions.patch(id, data, {
    admin: ownProps.admin,
  })),
  addEntry: async (data) => dispatch(projectEntriesActions.create(
    { type: ENTRY_TYPE_MEASUREMENT, ...data },
  )),
  removeEntry: async (id) => dispatch(projectEntriesActions.remove(id)),
  patchEntry: async (id, data) => dispatch(projectEntriesActions.patch(id, data)),
});


@withToastManager
@connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })
@withTranslation('', nsOptions)
class ElementMeasurement extends Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
    elementId: PropTypes.number.isRequired,
    element: PropTypes.shape().isRequired,
    projectElementId: PropTypes.number,
    moduleInstanceId: PropTypes.number,
    inclusion: PropTypes.shape(),
    inclusionId: PropTypes.number,
    parent: PropTypes.shape({
      content: PropTypes.shape(),
    }),
    methods: PropTypes.shape({
      move: PropTypes.func,
      reload: PropTypes.func.isRequired,
      remove: PropTypes.func,
      checkLinks: PropTypes.func,
    }).isRequired,
    isEditMode: PropTypes.bool,
    isEntryMode: PropTypes.bool,
    admin: PropTypes.bool,
    isModule: PropTypes.bool,
    isReadOnly: PropTypes.bool,
    isAnonymized: PropTypes.bool,
    user: PropTypes.shape().isRequired,
    projectEntry: PropTypes.shape(),
    projectElement: PropTypes.shape().isRequired,
    patchElement: PropTypes.func.isRequired,
    patchEntry: PropTypes.func.isRequired,
    addEntry: PropTypes.func.isRequired,
    removeEntry: PropTypes.func.isRequired,
  };

  static defaultProps = {
    projectElementId: null,
    moduleInstanceId: null,
    inclusion: null,
    inclusionId: null,
    parent: null,
    isEditMode: false,
    isEntryMode: false,
    isAnonymized: false,
    admin: false,
    isModule: false,
    isReadOnly: false,
    projectEntry: null,
  };

  constructor(props) {
    super(props);
    this.timeoutHandler = new TimeoutHandler();
  }

  checkLinks = () => {
    if (this.elBaseRef) this.elBaseRef.checkLinks();
  };

  showLoader = (show = true) => {
    if (this.elBaseRef) this.elBaseRef.showLoader(show);
  };

  updateElementUnit = (e) => {
    const text = e.target.value;
    this.timeoutHandler.doAfterTimeout(async () => {
      try {
        await this.props.patchElement(this.props.elementId, { unit: text });
        Toast.success(this.props, 'error:valid.saved');
      } catch (error) {
        ErrorUtil.handleCatched(this.props, error);
      }
    });
  };

  updateEntry = async (e) => {
    const { value: originalValue } = e.target;
    const value = originalValue.replace(',', '.');
    try {
      this.timeoutHandler.doAfterTimeout(async () => {
        const mutex = getMutex(this.props);
        mutex.cancel();
        let release;
        try {
          release = await mutex.acquire();
        } catch (error) {
          // Acquire has been canceled.
          return;
        }
        const {
          projectEntry, inclusion, projectElement, moduleInstanceId, addEntry, patchEntry,
          removeEntry, methods,
        } = this.props;
        try {
          this.showLoader();
          if (projectEntry) {
            if (value) {
              if (!Number.isNaN(value)) {
                await patchEntry(projectEntry.id, { value });
              } else {
                throw new Error();
              }
            } else {
              await removeEntry(projectEntry.id);
            }
          } else if (value) {
            if (!Number.isNaN(value)) {
              await addEntry({
                inclusion: inclusion.id,
                project_element: projectElement.id,
                module: moduleInstanceId,
                value,
              });
            } else {
              throw new Error();
            }
          }
          Toast.success(this.props, 'error:valid.saved');
          if (methods.checkLinks) methods.checkLinks();
          if (this.elBaseRef) this.elBaseRef.checkForCorruptedMissingDataCount();
        } catch (error) {
          ErrorUtil.handleCatched(this.props, error);
        } finally {
          release();
          this.showLoader(false);
        }
      }, 0, 1000);
    } catch (error) {
      ErrorUtil.handleCatched(this.props, error);
    }
  };

  preventIncorrectChars = (e) => {
    if (!['1', '2', '3', '4', '5', '6', '7', '8', '9', '0', '.', ',', '-'].includes(e.key)) {
      e.preventDefault();
      e.stopPropagation();
      return false;
    }

    // Workaround for dot issue in french locale in Firefox (only comma is
    // working so don't add it and inform the user)
    // See https://bugzilla.mozilla.org/show_bug.cgi?id=1253606
    if (this.props.user.language === 'fr' && e.key === '.') {
      // Try to detect Firefox to not alter right behaviour of others browers
      // (Safari and Chrome)
      if (navigator.userAgent.toLowerCase().indexOf('firefox') > -1) {
        e.preventDefault();
        e.stopPropagation();
        Toast.error(this.props, 'error:error.use-comma-only-for-float');
        return false;
      }
    }

    return true;
  };

  render() {
    const {
      t, isReadOnly, projectEntry, isEditMode, isEntryMode, methods, element, isAnonymized,
    } = this.props;
    const options = {};

    if (isEditMode && !isEntryMode) {
      options.disabled = true;
      if (this.input) this.input.value = '';
    }

    if (isEntryMode) {
      options.onChange = this.updateEntry;
      options.onKeyPress = this.preventIncorrectChars;
      if (projectEntry && !isAnonymized) {
        options.defaultValue = projectEntry.value;
      }
    }

    const missingData = isEntryMode && !projectEntry ? 'empty' : '';
    const qualifiedMissingData = isEntryMode && projectEntry && projectEntry.missing ? 'qualified-missing' : '';
    const inconsistentData = isEntryMode && projectEntry && !projectEntry.consistent ? 'inconsistent' : '';

    return (
      <ElementBase
        {...this.props}
        elementCategory="entry"
        methods={{ ...methods }}
        ref={(ref) => { this.elBaseRef = ref; }}
      >
        <div className="col-12 d-flex d-flex-row align-items-center">
          <div>
            <input
              type="number"
              title=""
              className={`form-control measurement ${isEditMode
                ? 'bg-gray-200'
                : 'bg-transparent'} ${missingData}${inconsistentData}${qualifiedMissingData}`}
              placeholder={t('error:placeholder.element-measurement')}
              step="any"
              disabled={isReadOnly}
              onWheel={(e) => { e.target.blur(); }} // Prevent from changing value accidentally
      // with mouse scroll
              ref={(input) => {
                this.input = input;
              }}
              {...options}
            />
          </div>
          <div className="border-0 bg-transparent">
            {
              isEditMode ? (
                <ElementContentEditable
                  defaultValue={element.unit || ''}
                  className="element-edit-input unit-edit-input"
                  onChange={this.updateElementUnit}
                  placeholder={t('error:placeholder.element-measurement-unit')}
                />
              ) : (
                <span
                  dangerouslySetInnerHTML={{
                    __html: TextUtil.escape(element.unit || ''),
                  }}
                />
              )
            }
          </div>
        </div>
      </ElementBase>
    );
  }
}

export default ElementMeasurement;
