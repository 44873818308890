import PropTypes from 'prop-types';
import React, { Component, useState, useEffect } from 'react';
import { withTranslation } from 'react-i18next';
import { withToastManager } from 'react-toast-notifications';
import { connect, useDispatch, useSelector } from 'react-redux';
import { Link, Route, Switch } from 'react-router-dom';
import {
  Collapse, Nav, Navbar, NavbarToggler, NavItem,
} from 'reactstrap';
import history from '../history';
import { usersActions } from '../redux/actions';
import '../assets/css/dashboard.css';
import logo from '../assets/img/light_logo.png';
import Footer from '../components/Footer';
import NewDropdownMenu from '../components/NewDropdownMenu';
import Page, { checkAuth, checkAdmin } from '../components/Page';
import { UserAvatar } from '../components/avatar';
import { nsOptions } from '../i18n';
import AdminTopics from './AdminTopics';
import AdminSpecialties from './AdminSpecialties';
import AdminUsers from './AdminUsers';
import AdminLicences from './AdminLicences';
import ProjectList from './ProjectList';
import ProjectView from './ProjectView';
import Settings from './Settings';
import NotFound from './NotFound';
import Teams, { TeamPage } from './Teams';
import { isMobileView } from '../components/MobileView';
import UserCommunity from '../components/UserCommunity';
import OrganizationCommunity from '../components/OrganizationCommunity';
import { logout } from '../utils/connection';
import useError from '../utils/HookErrorUtil';


const AdminOrganizations = () => (
  <div className="dashboard-content mb-2">
    <UserCommunity admin />
  </div>
);

const AdminOrganization = (props) => {
  const { match } = props;

  const [ready, setReady] = useState(false);

  const organization = match.params.id ? useSelector(
    (state) => state.users[Number(match.params.id)],
  ) : undefined;

  const { handleCatched } = useError();
  const dispatch = useDispatch();

  const fetchUser = async (id) => dispatch(usersActions.read(id, { admin: true }));

  const fetchData = async () => {
    try {
      await fetchUser(Number(match.params.id));
    } catch (error) {
      handleCatched(props, error);
      history.push('/admin/organizations');
    } finally {
      setReady(true);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return ready && organization ? (
    <div className="dashboard-content mb-2">
      <OrganizationCommunity
        admin
        publicProfile={organization.public_profile}
        organization={organization}
      />
    </div>
  ) : null;
};

AdminOrganization.propTypes = {
  match: PropTypes.shape().isRequired,
};

const mapStateToProps = (state) => ({ user: state.auth.authUser });


@withToastManager
@connect(mapStateToProps)
@withTranslation('', nsOptions)
class AdminLayout extends Component {
  static propTypes = {
    match: PropTypes.shape({
      url: PropTypes.string.isRequired,
    }).isRequired,
    i18n: PropTypes.shape().isRequired,
    t: PropTypes.func.isRequired,
    location: PropTypes.shape({
      pathname: PropTypes.string,
    }).isRequired,
    user: PropTypes.shape(),
  };

  static defaultProps = {
    user: undefined,
  };

  static handleOutsideClick(e) {
    if (e.persist) e.persist();
    NewDropdownMenu.handleOutsideClick(e);
    // NewTooltip.handleOutsideClick(e);
  }

  constructor(props) {
    super(props);
    this.state = { isOpen: false };
  }

  componentDidMount() {
    document.getElementById('body').className = 'admin-body';
    document.addEventListener('mousedown', AdminLayout.handleOutsideClick);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', AdminLayout.handleOutsideClick);
  }

  activeState = (originalUrl) => {
    const url = `${this.props.match.url}/${originalUrl}`;
    if (this.props.location.pathname.match(new RegExp(url))) {
      return ' active';
    }
    return '';
  };

  toggleNavbar = () => {
    this.setState({
      isOpen: !this.state.isOpen,
    });
  };

  render() {
    const { user } = this.props;
    const { url } = this.props.match;

    return [
      (
        <Page
          {...this.props}
          key="admin-1"
          className="admin"
          id="page-content"
          title="Admin Layout"
          checkAuthorizations={[checkAuth, checkAdmin]}
        >
          { user ? [
            (
              <Navbar light expand="xl" color="red-dark" key="admin-11">
                <Link className="navbar-brand" to={`${url}/home`}>
                  <img src={logo} className="" alt="" height="28" />
                </Link>
                <NavbarToggler onClick={this.toggleNavbar} />
                <Collapse isOpen={this.state.isOpen} navbar>
                  <Nav id="left-admin-navbar" className="mr-auto">
                    <NavItem className={`mr-4${this.activeState('users')}`}>
                      <Link className="nav-link text-white" to={`${url}/users`}>
                        Users
                      </Link>
                    </NavItem>
                    <NavItem className={`mr-4${this.activeState('teams')}`}>
                      <Link className="nav-link text-white" to={`${url}/teams`}>
                        Teams
                      </Link>
                    </NavItem>
                    <NavItem className={`mr-4${this.activeState('licenses')}`}>
                      <Link className="nav-link text-white" to={`${url}/licenses`}>
                        Licenses
                      </Link>
                    </NavItem>
                    <NavItem className={`mr-4${this.activeState('project(s?/?.*)')}`}>
                      <Link className="nav-link text-white" to={`${url}/projects`}>
                        Projects
                      </Link>
                    </NavItem>
                    <NavItem className={`mr-4${this.activeState('topics')}`}>
                      <Link className="nav-link text-white" to={`${url}/topics`}>
                        Topics
                      </Link>
                    </NavItem>
                    <NavItem className={`mr-4${this.activeState('specialties')}`}>
                      <Link className="nav-link text-white" to={`${url}/specialties`}>
                        Specialties
                      </Link>
                    </NavItem>
                    <NavItem className={`mr-4${this.activeState('organizations')}`}>
                      <Link className="nav-link text-white" to={`${url}/organizations`}>
                        Organizations
                      </Link>
                    </NavItem>
                  </Nav>
                  <Nav className="ml-auto">
                    <li className="nav-item dropdown">
                      <NewDropdownMenu
                        type={isMobileView() ? 'dropleft' : 'dropdown'}
                        menuClass="dropdown-menu-right"
                        triggerElement={(
                          <a
                            className="nav-link text-light dropdown-toggle"
                            id="navbarDropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                            href="#test"
                          >
                            <div className="d-inline-block mt-1">
                              {user.first_name}
                              {/* &nbsp;{user.last_name} */}
                            </div>
                            <UserAvatar
                              user={user}
                              className="ml-2 mt-0"
                            />
                          </a>
                    )}
                      >
                        <Link
                          className="dropdown-item bg-dark-dark text-white"
                          to="/"
                        >
                          Go back to site
                        </Link>
                        <div className="dropdown-divider" />
                        <button
                          className="dropdown-item"
                          onClick={() => logout(this.props)}
                        >
                          Logout
                        </button>
                      </NewDropdownMenu>
                    </li>
                  </Nav>
                </Collapse>
              </Navbar>
            ),
            (
              <div key="admin-12">
                <Switch>
                  <Route
                    {...this.props}
                    path={`${url}/home`}
                    component={AdminUsers}
                  />
                  <Route
                    {...this.props}
                    path={`${url}/projects`}
                    render={(props) => <ProjectList {...props} admin />}
                  />
                  <Route
                    {...this.props}
                    path={`${url}/project/:id`}
                    render={(props) => <ProjectView {...props} admin />}
                  />
                  <Route
                    {...this.props}
                    path={`${url}/topics`}
                    component={AdminTopics}
                  />
                  <Route
                    {...this.props}
                    path={`${url}/specialties`}
                    component={AdminSpecialties}
                  />
                  <Route
                    {...this.props}
                    path={`${url}/team/:id`}
                    render={(props) => <TeamPage {...props} admin />}
                  />
                  <Route
                    {...this.props}
                    path={`${url}/teams`}
                    render={(props) => <Teams {...props} admin />}
                  />
                  <Route
                    {...this.props}
                    path={`${url}/users`}
                    component={AdminUsers}
                  />
                  <Route
                    {...this.props}
                    path={`${url}/licenses`}
                    component={AdminLicences}
                  />
                  <Route
                    {...this.props}
                    path={`${url}/organizations`}
                    component={AdminOrganizations}
                  />
                  <Route
                    {...this.props}
                    path={`${url}/organization/:id`}
                    component={AdminOrganization}
                  />
                  <Route
                    {...this.props}
                    path={`${url}/user/:id`}
                    render={(props) => <Settings {...props} admin />}
                  />
                  <Route component={NotFound} {...this.props} />
                </Switch>
              </div>
            )] : null }
        </Page>
      ), (
        <Footer key="admin-2" />
      ),
    ];
  }
}


export default AdminLayout;
